import React from 'react'
import PropTypes from 'prop-types'
import RoleRenderer from '../RoleRenderer/RoleRenderer'

const RoleDashboardRenderer = props => {
  return (
    <RoleRenderer inDashboard {...props} />
  )
}

RoleDashboardRenderer.propTypes = {
    children: PropTypes.node,
}

export default RoleDashboardRenderer
