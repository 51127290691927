import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import ReactDatePicker from "react-datepicker";
import {
  DateFieldContainer,
  DateTextField,
  SingleInputTitle,
} from "./StartDateField.styled";
import { licenseFormikConstants } from "../../../../initialValues/licenseInitialValues";
const StartDateField = (props) => {
  const formik = props?.formik;
  const { t } = useTranslation();

  const handleChange = (date) => {
    formik?.setFieldValue?.(licenseFormikConstants.startDate, date?.toString?.());
    if (
      !formik?.values?.[licenseFormikConstants?.expirationDate] ||
      new Date(formik?.values?.[licenseFormikConstants.expirationDate]) <
        new Date(date)
    ) {
      let expirationDate = new Date(date);
      expirationDate.setFullYear(expirationDate.getFullYear() + 1);
      formik?.setFieldValue?.(
        licenseFormikConstants.expirationDate,
        expirationDate?.toString?.()
      );
    }
  };

  return (
    <DateFieldContainer>
      <SingleInputTitle>
        {t("licenses.modal.fields.startDate.label")}
      </SingleInputTitle>
      <ReactDatePicker
        placeholderText={t("licenses.modal.fields.startDate.placeholder")}
        disabled={props?.disabled}
        selected={
          formik?.values?.[licenseFormikConstants.startDate]
            ? new Date(formik?.values?.[licenseFormikConstants.startDate])
            : null
        }
        dateFormat="dd.MM.yyyy"
        onChange={handleChange}
        customInput={
          <DateTextField
            date
            disabledField={props?.disabled}
            requiredField={false}
            name={licenseFormikConstants.startDate}
            placeholder={t("licenses.modal.fields.startDate.placeholder")}
            value={formik?.values?.[licenseFormikConstants.startDate]}
            onChange={formik?.handleChange}
            error={
              formik?.touched?.[licenseFormikConstants.startDate] &&
              formik?.errors?.[licenseFormikConstants.startDate]
            }
            helperText={
              formik?.errors?.[licenseFormikConstants.startDate] &&
              formik?.touched?.[licenseFormikConstants.startDate]
            }
            fullWidth
          />
        }
      />
    </DateFieldContainer>
  );
};

StartDateField.propTypes = {
  formik: PropTypes.object,
  disabled: PropTypes.bool,
};

export default StartDateField;
