import LastChangesRenderer from "../../components/Clients/ClientsTable/Renderers/LastChangesRenderer/LastChangesRenderer";
import ActionRenderer from "../../components/Documents/DocumentsTable/Renderers/ActionRenderer/ActionRenderer";
import LinkRenderer from "../../components/Documents/DocumentsTable/Renderers/LinkRenderer/LinkRenderer";
import UserRenderer from "../../components/Users/UsersTable/ActiveUsersTable/Renderers/UserRenderer/UserRenderer";

export default {
  documentNumber: {
    id: 1,
    i18key: "documents.table.documentNumber.columnTitle",
    redirect: true,
    renderer: UserRenderer,
    style: {
      flex: 2,
    },
    backendProperty: "documentNumber",
  },
  client: {
    id: 2,
    i18key: "documents.table.client.columnTitle",
    redirect: true,
    style: {
      flex: 2,
    },
  },
  documentType: {
    id: 3,
    i18key: "documents.table.documentType.columnTitle",
    redirect: true,
    style: {
      flex: 2,
    },
  },
  documentDate: {
    id: 4,
    i18key: "documents.table.documentDate.columnTitle",
    redirect: true,
    style: {
      flex: 2,
    },
    backendProperty: "documentDate",
  },
  link: {
    id: 5,
    i18key: "documents.table.link.columnTitle",
    redirect: true,
    renderer: LinkRenderer,
    style: {
      flex: 2,
    },
    backendProperty: "link",
  },
  lastChanges: {
    id: 6,
    i18key: "documents.table.lastChanges.columnTitle",
    renderer: LastChangesRenderer,
    style: {
      textAlign: "center",
      justifyContent: "center",
      flex: 1,
    },
    backendProperty: "updatedAtUtc",
  },
  finding: {
    id: 7,
    i18key: "documents.table.finding.columnTitle",
    redirect: true,
    style: {
      flex: 2,
    },
    backendProperty: "findingNumber",
  },
  actions: {
    id: 8,
    i18key: "",
    disableSort: true,
    renderer: ActionRenderer,
    style: {
      minWidth: "132px",
      maxWidth: "132px",
      paddingRight: "24px",
      justifyContent: "center",
      textAlign: "center",
    },
  },
  header: {
    id: 9,
    i18key: "documents.table.header.columnTitle",
    mobile: true,
    disableSort: true,
    style: {},
  }
};
