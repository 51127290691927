import {
  createClearType,
  createDeleteType,
  createErrorType,
  createFetchType,
  createSetType,
  createSuccessType,
} from "../actionHelpers";

const SINGLE_STANDARD_SCOPE = "SINGLE_STANDARD_SCOPE";
export const SINGLE_STANDARD_FETCH = createFetchType(SINGLE_STANDARD_SCOPE);
export const SINGLE_STANDARD_SUCCESS = createSuccessType(SINGLE_STANDARD_SCOPE);
export const SINGLE_STANDARD_ERROR = createErrorType(SINGLE_STANDARD_SCOPE);
export const SINGLE_STANDARD_SET = createSetType(SINGLE_STANDARD_SCOPE);

const STANDARDS_CREATE_SCOPE = "STANDARDS_CREATE_SCOPE";
export const STANDARDS_CREATE_FETCH = createFetchType(STANDARDS_CREATE_SCOPE);
export const STANDARDS_CREATE_SUCCESS = createSuccessType(
  STANDARDS_CREATE_SCOPE
);
export const STANDARDS_CREATE_ERROR = createErrorType(STANDARDS_CREATE_SCOPE);

const STANDARDS_EDIT_SCOPE = "STANDARDS_EDIT_SCOPE";
export const STANDARDS_EDIT_FETCH = createFetchType(STANDARDS_EDIT_SCOPE);
export const STANDARDS_EDIT_SUCCESS = createSuccessType(STANDARDS_EDIT_SCOPE);
export const STANDARDS_EDIT_ERROR = createErrorType(STANDARDS_EDIT_SCOPE);

const STANDARDS_DELETE_SCOPE = "STANDARDS_DELETE_SCOPE";
export const STANDARDS_DELETE_FETCH = createDeleteType(STANDARDS_DELETE_SCOPE);
export const STANDARDS_DELETE_SUCCESS = createSuccessType(
  STANDARDS_DELETE_SCOPE
);
export const STANDARDS_DELETE_ERROR = createErrorType(STANDARDS_DELETE_SCOPE);

export const STANDARDS_SCOPE = "STANDARDS_SCOPE";
export const STANDARDS_FETCH = createFetchType(STANDARDS_SCOPE);
export const STANDARDS_SUCCESS = createSuccessType(STANDARDS_SCOPE);
export const STANDARDS_ERROR = createErrorType(STANDARDS_SCOPE);
export const STANDARDS_SET = createSetType(STANDARDS_SCOPE);
export const STANDARDS_CLEAR = createClearType(STANDARDS_SCOPE);

export const STANDARDS_SET_TOTAL = createSetType("STANDARDS_TOTAL_SCOPE");
