import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import TextInputField from "../../../Field/TextInputField/TextInputField";

const DescriptionField = (props) => {
  const { t } = useTranslation();
  const formik = props?.formik;
  return (
    <>
      <TextInputField
        name="description"
        label={t("companySettings.description")}
        value={formik?.values?.description}
        onChange={formik?.handleChange}
        error={
          formik?.touched?.description &&
          formik?.errors?.description
        }
        helperText={
          formik?.touched?.description &&
          formik?.errors?.description
        }
      />
    </>
  );
};

DescriptionField.propTypes = {
  formik: PropTypes.object,
  isRequired: PropTypes.bool,
};

export default DescriptionField;
