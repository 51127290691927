import { Box } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../../../themes";

export const TypeContainer = styled(Box)`
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
  width: fit-content;
  border-radius: 24px;
  padding-left: 8px;
  padding-right: 8px;
  background: ${selectedTheme.colors.findingTypeColor};
  font-family: ${selectedTheme.fonts.mainText};
  color: ${selectedTheme.colors.findingTypeTextColor};
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
`;
