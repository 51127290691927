import React from "react";
// import PropTypes from 'prop-types'
import { ReactComponent as DirectBoxIconSvg } from "../../../assets/images/svg/directbox.svg";

const DirectBoxIcon = (props) => {
  return <DirectBoxIconSvg {...props} />;
};

DirectBoxIcon.propTypes = {};

export default DirectBoxIcon;
