import LastChangesRenderer from "../../components/Clients/ClientsTable/Renderers/LastChangesRenderer/LastChangesRenderer";
import ActionRenderer from "../../components/Users/UsersTable/ActiveUsersTable/Renderers/ActionRenderer/ActionRenderer";
import RoleRenderer from "../../components/Users/UsersTable/ActiveUsersTable/Renderers/RoleRenderer/RoleRenderer";
import UserRenderer from "../../components/Users/UsersTable/ActiveUsersTable/Renderers/UserRenderer/UserRenderer";

export default {
  users: {
    id: 0,
    i18key: "users.table.users.columnTitle",
    renderer: UserRenderer,
    backendProperty: "firstName",
    redirect: true,
    style: {
      minWidth: "calc(100% / 6)",
    },
  },
  header: {
    id: 8,
    i18key: "users.table.header.columnTitle",
    mobile: true,
    disableSort: true,
    style: {},
  },
  email: {
    id: 1,
    i18key: "users.table.email.columnTitle",
    backendProperty: "email",
    style: {
      minWidth: "calc(100% / 6)",
    },
  },
  lastLogin: {
    id: 2,
    i18key: "users.table.lastLogin.columnTitle",
    backendProperty: "lastLoginDate",
    style: {
      justifyContent: "center",
      minWidth: "calc(100% / 6)",
    },
  },
  role: {
    id: 3,
    i18key: "users.table.role.columnTitle",
    renderer: RoleRenderer,
    backendProperty: "roles",
    disableSort: true,
    style: {
      minWidth: "calc(100% / 6)",
      justifyContent: "center",
    },
  },
  lastChanges: {
    id: 4,
    i18key: "users.table.lastChanges.columnTitle",
    renderer: LastChangesRenderer,
    style: {
      textAlign: "center",
      justifyContent: "center",
      minWidth: "calc(100% / 6)",
    },
    backendProperty: "updatedAtUtc",
  },
  actions: {
    id: 5,
    i18key: "",
    renderer: ActionRenderer,
    disableSort: true,
    style: {
      minWidth: "138px",
      paddingRight: "24px",
      justifyContent: "center",
      textAlign: "center",
      maxWidth: "138px",
    },
  },
};
