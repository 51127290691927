import React from "react";
import PropTypes from "prop-types";
import {
  ActionRendererContainer,
  ActionRendererEditIcon,
  ActionRendererClickIconContainer,
  ActionRendererDeactivateIcon,
  ActionRendererActivateIcon,
  ActionRendererEditIconContainer,
} from "./ActionRenderer.styled";
import { replaceInRoute } from "../../../../../util/helpers/routeHelpers";
import Tooltip from "../../../../Tooltip/Tooltip";
import i18n from "../../../../../i18n";
import { COMPANIES_EDIT_PAGE } from "../../../../../constants/pages";
import {
  fetchActivateCompany,
  fetchDeactivateCompany,
} from "../../../../../store/actions/companies/companiesActions";
import { makeToastMessage } from "../../../../../store/utils/makeToastMessage";
import { APPLICATION_TOAST_CONTAINER_ID } from "../../../../../constants/toastConstants";
import {
  closeModal,
  toggleSetDeleteModal,
  toggleSetLoadingModal,
} from "../../../../../store/actions/modal/modalActions";

const ActionRenderer = (props) => {
  const handleApiResponseSuccess = (isActivating) => {
    props?.dispatch(closeModal());
    let titleKey = "companies.toast.deactivated.title";
    let descriptionKey = "companies.toast.deactivated.description";
    if (isActivating) {
      titleKey = "companies.toast.activated.title";
      descriptionKey = "companies.toast.activated.description";
    }
    makeToastMessage(
      {
        title: props?.value?.t?.(titleKey, {
          typeOfData: props?.value?.t?.("companies.typeOfData"),
        }),
        description: props?.value?.t?.(descriptionKey, {
          typeOfData: props?.value
            ?.t?.("companies.typeOfDataPlural")
            .toLowerCase(),
        }),
      },
      {
        containerId: APPLICATION_TOAST_CONTAINER_ID,
      }
    );
    props?.value?.tableRef?.current?.forceRefetch?.();
  };

  const handleDeactivateCompany = () => {
    props?.dispatch(
      toggleSetDeleteModal({
        title: i18n.t("companies.modal.deactivate.title"),
        description: {
          first: i18n.t("companies.modal.deactivate.description"),
        },
        removeText: i18n.t("common.deactivate"),
        onSubmit: () => {
          props?.dispatch(toggleSetLoadingModal({ rank: 2 }));
          props?.dispatch(
            fetchDeactivateCompany({
              companyId: props?.value?.companyId,
              handleApiResponseSuccess: () => handleApiResponseSuccess(false),
              handleApiResponseError: () => props?.dispatch(closeModal()),
            })
          );
        },
        rank: 1,
      })
    );
  };
  const handleActivateCompany = () => {
    props?.dispatch(
      toggleSetDeleteModal({
        title: i18n.t("companies.modal.activate.title"),
        description: {
          first: i18n.t("companies.modal.activate.description"),
        },
        removeText: i18n.t("common.activate"),
        onSubmit: () => {
          props?.dispatch(toggleSetLoadingModal(true));
          props?.dispatch(
            fetchActivateCompany({
              companyId: props?.value?.companyId,
              handleApiResponseSuccess: () => handleApiResponseSuccess(true),
              handleApiResponseError: () => props?.dispatch(closeModal()),
            })
          );
        },
        rank: 1,
      })
    );
  };
  return (
    <ActionRendererContainer>
      <Tooltip title={i18n.t("companies.tooltip.edit")}>
        <ActionRendererEditIconContainer
          to={replaceInRoute(COMPANIES_EDIT_PAGE, {
            companyId: props?.value?.companyId,
          })}
        >
          <ActionRendererEditIcon />
        </ActionRendererEditIconContainer>
      </Tooltip>
      {/* <Tooltip title={i18n.t("companies.tooltip.delete")}>
        <ActionRendererDeleteIconContainer onClick={handleDeleteCompany}>
          <ActionRendererDeleteIcon />
        </ActionRendererDeleteIconContainer>
      </Tooltip> */}
      {!props?.value?.deactivatedAtUtc && (
        <Tooltip title={i18n.t("companies.tooltip.deactivate")}>
          <ActionRendererClickIconContainer onClick={handleDeactivateCompany}>
            <ActionRendererDeactivateIcon />
          </ActionRendererClickIconContainer>
        </Tooltip>
      )}
      {props?.value?.deactivatedAtUtc && (
        <Tooltip title={i18n.t("companies.tooltip.activate")}>
          <ActionRendererClickIconContainer onClick={handleActivateCompany}>
            <ActionRendererActivateIcon />
          </ActionRendererClickIconContainer>
        </Tooltip>
      )}
    </ActionRendererContainer>
  );
};

ActionRenderer.propTypes = {
  children: PropTypes.node,
  dispatch: PropTypes.func,
  value: PropTypes.object,
  rendererProps: PropTypes.any,
};

export default ActionRenderer;
