import { Box, Typography } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../../../../themes";
import { ReactComponent as LeftArrowIcon } from "../../../../../../assets/images/svg/left-arrow-icon.svg";

export const FindingScanningQuestionsHeader = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: -32px;
`;

export const FindingScanningQuestionsHeaderTitleAndFindingModalButtons = styled(Box)`
  display: flex;
  justify-content: space-between;
`;

export const FindingScanningQuestionsHeaderTitle = styled(Box)`
  display: flex;
  align-items: center;
  gap: 8px;
  font-family: ${selectedTheme.fonts.mainText};
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: -0.02em;
  font-feature-settings: "salt" on;
  color: ${selectedTheme.colors.pageTitleColor};
`;
export const FindingQuestionContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export const LeftArrowButton = styled(LeftArrowIcon)`
  cursor: pointer;
`;

export const RightArrowButton = styled(LeftArrowIcon)`
  transform: rotate(180deg);
  cursor: pointer;
`;

export const SectionTitle = styled(Typography)`
  font-family: 'Inter';
  font-size: 20px;
  font-weight: 500;
  letter-spacing: -0.02em;
  color: ${selectedTheme.colors.orangeBorder};
`;

export const ArrowButton = styled.button`
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
`;

export const FindingScanningHeaderTitle = styled(Box)`
  font-family: ${selectedTheme.fonts.mainText};
  font-weight: 500;
  font-size: 24px;
  line-height: 44px;
 
  font-feature-settings: "salt" on;
  color: ${selectedTheme.colors.pageTitleColor};
`;