import { Box, Table } from "@mui/material";
import styled from "styled-components";
import { hexToRGB } from "../../../util/helpers/colorHelper";
import selectedTheme from "../../../themes";

export const TablePreviewModalContainer = styled(Box)`
  padding: 40px;
  position: absolute;
  background: white;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  z-index: ${(props) => "15" + props?.rank || "151"};
  max-height: 70vh !important;
  overflow: auto;
  @media (max-width: 600px) {
    width: 90vw;
    max-height: 100vh;
  }
`;
export const TablePreviewContainer = styled(Table)`
  max-width: 700px;
  min-width: 700px;
  width: 700px;
  position: relative;
  border-collapse: separate;
  border-radius: 12px;
  box-shadow: 0px 1px 2px 0px
      ${hexToRGB(selectedTheme.colors.shadowColor, 0.06)},
    0px 1px 3px 0px ${hexToRGB(selectedTheme.colors.shadowColor, 0.1)};
  @media (max-width: 600px) {
    width: 100%;
    overflow: auto;
  }
`;
export const ButtonsContainer = styled(Box)`
display: flex;
flex-direction: row;
gap: 8px;
justify-content: center;
`;

export const CancelButton = styled(Box)`
  background-color: ${selectedTheme.colors.white};
  color: ${selectedTheme.colors.primaryButtonTextColor};
  text-transform: none;
  border-radius: 8px;
  font-family: ${selectedTheme.fonts.mainText};
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  width: 200px;
  white-space: nowrap;
  border: 1px solid ${selectedTheme.fonts.tableBorder};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 18px;
  gap: 8px;
  cursor: pointer;
`;