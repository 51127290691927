import React from "react";
import PropTypes from "prop-types";
import {
  ArrowIcon,
  ButtonsContainer,
  CloseButton,
  CloseButtonContainer,
  FilterButtonContainer,
  FilterButtonTitle,
  FilterComponentContainer,
  FilterDownIconContainer,
  FilterIconContainer,
  LeftContaier,
  MainContainer,
  ResetButton,
  ResetButtonContainer,
  RightContaier,
} from "./FilterButton.styled";
import FilterIcon from "../../Icon/Icons/FilterIcon";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useCallback } from "react";
import { useRef } from "react";
import Tooltip from "../../Tooltip/Tooltip";
import PrimaryButton from "../PrimaryButton/PrimaryButton";

const FilterButton = (props) => {
  const [areFiltersShown, setAreFiltersShown] = useState(false);
  const { t } = useTranslation();
  const containerRef = useRef(null);

  const listener = useCallback(
    (event) => {
      event.stopPropagation();
      if (
        !containerRef?.current?.contains?.(event?.target) &&
        !event?.target?.className?.includes?.("datepicker") &&
        event?.target?.className?.length > 0
      ) {
        setAreFiltersShown(false);
        window.removeEventListener("click", listener);
      }
    },
    [props?.filterComponents, areFiltersShown, containerRef.current]
  );

  const showFilters = (event) => {
    event.stopPropagation();
    if (!event?.target?.className?.includes?.("tooltip"))
      setAreFiltersShown((prevState) => !prevState);
    window.addEventListener("click", listener);
  };

  const closeFilterHandler = () => {
    setAreFiltersShown(false);
  };

  const applyFilters = () => {
    setAreFiltersShown(false);
    props?.applyFiltersFunction?.();
  };

  return (
    <FilterButtonContainer onClick={showFilters}>
      <FilterIconContainer>
        <FilterIcon />
      </FilterIconContainer>
      <FilterButtonTitle>{t("table.filter")}</FilterButtonTitle>
      <FilterDownIconContainer flip={areFiltersShown}>
        <ArrowIcon flip={areFiltersShown} />
      </FilterDownIconContainer>
      {areFiltersShown && (
        <>
          <FilterComponentContainer
            onClick={(event) => event.stopPropagation()}
            ref={containerRef}
          >
            <CloseButtonContainer onClick={closeFilterHandler}>
              <Tooltip title={t("common.close")}>
                <CloseButton />
              </Tooltip>
            </CloseButtonContainer>
            <ResetButtonContainer onClick={props?.onReset}>
              <Tooltip title={t("findings.filter.reset")}>
                <ResetButton />
              </Tooltip>
            </ResetButtonContainer>
            <MainContainer>
              <LeftContaier>
                {props?.filterComponents?.slice?.(
                  0,
                  Math.ceil(props?.filterComponents?.length / 2)
                )}
              </LeftContaier>
              <RightContaier>
                {props?.filterComponents?.slice?.(
                  Math.ceil(props?.filterComponents?.length / 2)
                )}
                <ButtonsContainer>
                  <PrimaryButton onClick={closeFilterHandler}>
                    {t("common.giveUp")}
                  </PrimaryButton>
                  <PrimaryButton variant="contained" onClick={applyFilters}>
                    {t("common.apply")}
                  </PrimaryButton>
                </ButtonsContainer>
              </RightContaier>
            </MainContainer>
          </FilterComponentContainer>
        </>
      )}
    </FilterButtonContainer>
  );
};

FilterButton.propTypes = {
  children: PropTypes.node,
  filterComponents: PropTypes.node,
  onReset: PropTypes.func,
  applyFiltersFunction: PropTypes.func,
};

export default FilterButton;
