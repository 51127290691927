import * as Yup from "yup";
import { setPasswordFormikConstants } from "../initialValues/setPasswordInitialValues";

export default () =>
  Yup.object().shape({
    [setPasswordFormikConstants.password]: Yup.string()
      .max(255, "changePassword.passwordNumberOfCharacters")
      .required("changePassword.blankPasswordError"),
    [setPasswordFormikConstants.confirmPassword]: Yup.string()
      .max(255, "changePassword.passwordNumberOfCharacters")
      .oneOf(
        [Yup.ref(setPasswordFormikConstants.password), null],
        "changePassword.differentPasswordsError"
      )
      .required("changePassword.blankPasswordError")
      .matches(
        /^(?=.{6,})/, //eslint-disable-line
        "changePassword.passwordLength"
      )
      .matches(
        /^(?=.*[0-9])/, //eslint-disable-line
        "changePassword.numbersRequred"
      )
      .matches(
        /^(?=.*[!@#\$%\^&\*])/, //eslint-disable-line
        "changePassword.specialCharactersRequired"
      )
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])/, //eslint-disable-line
        "changePassword.lettersRequred"
      ),
  });
