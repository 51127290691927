import React from "react";
import DashboardContent from "../../components/Dashboard/DashboardContent";
import useAuth from "../../hooks/useAuth";
import { SUPERADMIN_ROLE } from "../../constants/rolesConstants";
import { Redirect } from "react-router-dom";
import { COMPANIES_PAGE } from "../../constants/pages";
const HomePage = () => {
  const { hasRole } = useAuth();
  if (hasRole(SUPERADMIN_ROLE)) return <Redirect to={COMPANIES_PAGE} />;
  return <DashboardContent />;
};

export default HomePage;
