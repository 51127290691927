import { Box } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../themes";

export const SetLoadingModalContainer = styled(Box)`
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 120px 81px;
  width: 245px;
  height: 313px;
  z-index: ${(props) => "15" + props?.rank || "151"};
  background-color: ${selectedTheme.colors.white};
  border-radius 4px;
`;
