import { createSelector } from "reselect";

const usersSelector = (state) => state.users;

export const selectUsers = createSelector(
  usersSelector,
  (state) => state.users
);

export const selectBlockedUsers = createSelector(
  usersSelector,
  (state) => state.blockedUsers
);

export const selectActiveUsers = createSelector(
  usersSelector,
  (state) => state.activeUsers
);

export const selectTotalActiveUsers = createSelector(
  usersSelector,
  (state) => state.totalActiveUsers
);

export const selectTotalBlockedUsers = createSelector(
  usersSelector,
  (state) => state.totalBlockedUsers
);

export const selectTotalUsers = createSelector(
  usersSelector,
  (state) => state.totalUsers
);
export const selectSingleUser = createSelector(
  usersSelector,
  (state) => state.singleUser
);
export const selectMyUser = createSelector(
  usersSelector,
  (state) => state.myUser
);
export const selectUsersData = createSelector(
  usersSelector,
  (state) => state.users.data
);
export const selectUsersErrorMessage = createSelector(
  usersSelector,
  (state) => state.errorMessage
);
export const selectInvitationData = createSelector(
  usersSelector,
  (state) => state.invitation
);
export const selectInvitationToken = createSelector(
  usersSelector,
  (state) => state.invitation?.token
);
