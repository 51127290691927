import {
  createClearType,
  createDeleteType,
  createErrorType,
  createFetchType,
  createSetType,
  createSuccessType,
} from "../actionHelpers";

export const SET_STUFF = createSetType("SET_STUFF");
export const SET_STUFF_ERROR = "SET_STUFF_ERROR";

export const GET_STUFFS_SCOPE = "GET_STUFFS_SCOPE";
export const GET_STUFFS_FETCH = createFetchType(GET_STUFFS_SCOPE);
export const GET_STUFFS_SUCCESS = createSuccessType(GET_STUFFS_SCOPE);
export const GET_STUFFS_ERROR = createErrorType(GET_STUFFS_SCOPE);
export const GET_STUFFS_CLEAR = createClearType(GET_STUFFS_SCOPE);

export const SINGLE_STUFF_SCOPE = "SINGLE_STUFF_SCOPE";
export const SINGLE_STUFF_FETCH = createFetchType(SINGLE_STUFF_SCOPE);
export const SINGLE_STUFF_SUCCESS = createSuccessType(SINGLE_STUFF_SCOPE);
export const SINGLE_STUFF_ERROR = createErrorType(SINGLE_STUFF_SCOPE);
export const SINGLE_STUFF_SET = createSetType(SINGLE_STUFF_SCOPE);
export const SINGLE_STUFF_CLEAR = createClearType(SINGLE_STUFF_SCOPE);

const CREATE_STUFF_SCOPE = "CREATE_STUFF_SCOPE";
export const CREATE_STUFF_FETCH = createFetchType(CREATE_STUFF_SCOPE);
export const CREATE_STUFF_SUCCESS = createSuccessType(CREATE_STUFF_SCOPE);
export const CREATE_STUFF_ERROR = createErrorType(CREATE_STUFF_SCOPE);

const EDIT_STUFF_SCOPE = "EDIT_STUFF_SCOPE";
export const EDIT_STUFF_FETCH = createFetchType(EDIT_STUFF_SCOPE);
export const EDIT_STUFF_SUCCESS = createSuccessType(EDIT_STUFF_SCOPE);
export const EDIT_STUFF_ERROR = createErrorType(EDIT_STUFF_SCOPE);

const DELETE_STUFF_SCOPE = "DELETE_STUFF_SCOPE";
export const DELETE_STUFF_FETCH = createDeleteType(DELETE_STUFF_SCOPE);
export const DELETE_STUFF_SUCCESS = createSuccessType(DELETE_STUFF_SCOPE);
export const DELETE_STUFF_ERROR = createErrorType(DELETE_STUFF_SCOPE);

export const SET_STUFFS = createSetType("SET_STUFFS");
export const SET_TOTAL_STUFFS = createSetType("SET_TOTAL_STUFFS");
export const CLEAR_STUFFS = createClearType("CLEAR_STUFFS");
export const REMOVE_STUFF = createSetType("REMOVE_STUFF");
