import { IS_ONLINE_SET, OPEN_ONLINE_POPOVER } from "../../actions/app/appActionConstants";
import createReducer from "../../utils/createReducer";

const initialState = {
  isOnline: true,
  openOnlinePopover: false,
};

export default createReducer(
  {
    [IS_ONLINE_SET]: setIsOnline,
    [OPEN_ONLINE_POPOVER]: openOnlinePopover,
  },
  initialState
);
function setIsOnline(state, { payload }) {
  return {
    ...state,
    isOnline: payload,
  };
}
function openOnlinePopover(state, { payload }) {
  return {
    ...state,
    openOnlinePopover: payload != null,
  };
}
