import React from "react";
import PropTypes from "prop-types";
import {
  ButtonsContainer,
  CancelButton,
  CloseButton,
  ModalTitle,
  SubmitButton,
} from "../Modal.styled";
import {
  InviteUserModalContainer,
  RolePickerContainer,
} from "./InviteUserModal.styled";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  closeLoadingModal,
  closeModal,
  toggleSetLoadingModal,
} from "../../../store/actions/modal/modalActions";
import EmailField from "./EmailField/EmailField";
import { useFormik } from "formik";
import { inviteUserInitialValues } from "../../../initialValues/inviteUserInitialValues";
import inviteUserValidationSchema from "../../../validations/inviteUserValidationSchema";
import { fetchInviteUser } from "../../../store/actions/user/userActions";
import RolePicker from "../../Users/NewUser/RolePicker/RolePicker";
import { makeToastMessage } from "../../../store/utils/makeToastMessage";
import { APPLICATION_TOAST_CONTAINER_ID } from "../../../constants/toastConstants";

const InviteUserModal = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  const handleApiResponseSuccess = () => {
    handleCloseModal();
    makeToastMessage(
      {
        title: t("toast.success.userInvitedTitle"),
        description: t("toast.success.userInvitedDescription"),
      },
      {
        containerId: APPLICATION_TOAST_CONTAINER_ID,
      }
    );
    props?.handleApiResponseSuccess?.();
  };

  const handleApiResponseError = () => {
    dispatch(closeLoadingModal());
  };

  const handleSubmit = (values) => {
    dispatch(toggleSetLoadingModal({ rank: 2 }));
    dispatch(
      fetchInviteUser({
        userData: {
          mail: values?.mail,
          roles: values?.userRole,
          companyId: props?.companyId,
        },
        handleApiResponseSuccess,
        handleApiResponseError,
      })
    );
  };

  const formik = useFormik({
    initialValues: inviteUserInitialValues(),
    validationSchema: inviteUserValidationSchema(),
    onSubmit: handleSubmit,
  });

  return (
    <InviteUserModalContainer rank={props?.rank}>
      <CloseButton onClick={handleCloseModal} />
      <ModalTitle>{t(props?.title)}</ModalTitle>
      <EmailField formik={formik} />
      <RolePickerContainer>
        <RolePicker formik={formik} />
      </RolePickerContainer>
      <ButtonsContainer>
        <CancelButton onClick={handleCloseModal}>
          {t("common.cancel")}
        </CancelButton>
        <SubmitButton
          onClick={formik?.handleSubmit}
          //   disabled={isSavingEnabled}
        >
          {t("users.invite")}
        </SubmitButton>
      </ButtonsContainer>
    </InviteUserModalContainer>
  );
};

InviteUserModal.propTypes = {
  children: PropTypes.node,
  rank: PropTypes.number,
  title: PropTypes.string,
  handleApiResponseSuccess: PropTypes.func,
  companyId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default InviteUserModal;
