import LastChangesRenderer from "../../components/Clients/ClientsTable/Renderers/LastChangesRenderer/LastChangesRenderer";
import ActionRenderer from "../../components/Users/UsersTable/InvitedUsersTable/ActionRenderer/ActionRenderer";

export default {
  mail: {
    id: 0,
    i18key: "invitedUsers.table.mail.columnTitle",
    backendProperty: "mail",
  },
  header: {
    id: 1,
    i18key: "invitedUsers.table.header.columnTitle",
    mobile: true,
    disableSort: true,
    style: {},
  },
  lastChanges: {
    id: 5,
    i18key: "invitedUsers.table.lastChanges.columnTitle",
    renderer: LastChangesRenderer,
    style: {
      textAlign: "center",
      justifyContent: "center",
      minWidth: "calc(100% / 7)",
    },
    backendProperty: "updatedAtUtc",
  },
  actions: {
    id: 2,
    i18key: "",
    renderer: ActionRenderer,
    disableSort: true,
    style: {
      minWidth: "100px",
      paddingRight: "24px",
      justifyContent: "center",
      textAlign: "center",
      maxWidth: "100px",
    },
  },
};
