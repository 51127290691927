import React from "react";
import PropTypes from "prop-types";
import TemplateCard from "../../Cards/TemplateCard/TemplateCard";
import { TemplateCardsContainer } from "./AllTemplatesEditor.styled";
import { useDispatch, useSelector } from "react-redux";
import { selectChecklistTemplates } from "../../../store/selectors/scanningObjectsSelectors";
import EmptyScanningObjectPicker from "../../ScanningObjects/NewScanningObject/ScanningObjectPicker/EmptyScanningObjectPicker/EmptyScanningObjectPicker";
import { ACTIVE_TEMPLATE_TAB } from "../../../constants/templateEditorTabs";
import { addScanningObjectSelectedChecklistTemplate } from "../../../store/actions/scanningObjects/scanningObjectsActions";

const AllTemplatesEditor = (props) => {
  const templates = useSelector(selectChecklistTemplates);
  const dispatch = useDispatch();

  const handleAddTemplate = () => {
    props?.handleChangeTab(ACTIVE_TEMPLATE_TAB);
    dispatch(
      addScanningObjectSelectedChecklistTemplate({
        isDefault: true,
        changed: true,
        added: true,
        id: Math.random(),
        measuringInstruments: [],
        standards: [],
        sections: [],
      })
    );
  };

  if (templates.length === 0) {
    return !props?.isReadOnly ? (
      <EmptyScanningObjectPicker
        activeTemplates={false}
        handleAddTemplate={handleAddTemplate}
        isEditing={true}
      />
    ) : (
      <></>
    );
  }

  return (
    <TemplateCardsContainer>
      {templates?.map((singleTemplate) => (
        <TemplateCard
          key={singleTemplate?.id}
          createdDate={new Date(
            singleTemplate?.createdAtUtc || new Date().toISOString()
          ).toISOString()}
          username={singleTemplate?.createdByUser}
          updatedDate={new Date(
            singleTemplate?.updatedAtUtc || new Date().toISOString()
          ).toISOString()}
          template={singleTemplate}
          isActive={singleTemplate?.isDefault}
          isReadOnly={props?.isReadOnly}
        />
      ))}
    </TemplateCardsContainer>
  );
};

AllTemplatesEditor.propTypes = {
  children: PropTypes.node,
  templates: PropTypes.array,
  handleChangeTab: PropTypes.func,
  isReadOnly: PropTypes.bool,
};

AllTemplatesEditor.defaultProps = {
  templates: [],
};

export default AllTemplatesEditor;
