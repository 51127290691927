import {
  getRequest,
  postRequest,
  putRequest,
  replaceInUrl,
  patchRequest,
  deleteRequest,
} from ".";
import apiEndpoints from "./apiEndpoints";

export const attemptFetchUsers = (payload) =>
  getRequest(apiEndpoints.users.getUsers, payload);

export const attemptCreateUser = (payload) =>
  postRequest(apiEndpoints.users.createUser, payload);

export const attemptEditUser = (payload) =>
  putRequest(
    replaceInUrl(apiEndpoints.users.editUser, { userId: payload?.userId }),
    payload?.data
  );

export const attemptChangeUserPassword = (payload) =>
  putRequest(
    replaceInUrl(apiEndpoints.users.changeUserPassword, {
      userId: payload?.userId,
    }),
    payload?.data
  );

export const attemptFetchSingleUser = (payload) =>
  getRequest(
    replaceInUrl(apiEndpoints.users.getSingleUser, { userId: payload?.userId }),
    payload?.query
  );

export const attemptBlockUser = (payload) =>
  patchRequest(
    replaceInUrl(apiEndpoints.users.blockUser, { userId: payload?.userId })
  );

export const attemptUnblockUser = (payload) =>
  patchRequest(
    replaceInUrl(apiEndpoints.users.unblockUser, { userId: payload?.userId })
  );
export const attemptInviteUser = (payload) =>
  postRequest(apiEndpoints.users.inviteUser, payload);
export const attemptReinviteUser = (payload) =>
  patchRequest(
    replaceInUrl(apiEndpoints.users.reinviteUser, { userId: payload })
  );
export const attemptValidateInvitationToken = (payload) =>
  getRequest(apiEndpoints.users.validateInvitationToken + payload);
export const attemptAcceptInvitation = (payload) =>
  postRequest(apiEndpoints.users.acceptInvitation, payload);
export const attemptDeleteInvitation = (payload) =>
  deleteRequest(
    replaceInUrl(apiEndpoints.users.deleteInvitation, { userId: payload })
  );
