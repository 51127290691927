import PropTypes from "prop-types";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  DOCUMENTS_EDIT_PAGE,
  DOCUMENTS_NEW_PAGE,
} from "../../../constants/pages";
import documentsTableConstants from "../../../constants/tableConstants/documentsTableConstants";
import { APPLICATION_TOAST_CONTAINER_ID } from "../../../constants/toastConstants";
import {
  clearDocuments,
  fetchDocuments,
} from "../../../store/actions/documents/documentsActions";
import {
  closeLoadingModal,
  closeModal,
} from "../../../store/actions/modal/modalActions";
import { selectClientsData } from "../../../store/selectors/clientsSelector";
import { selectDocumentTypesData } from "../../../store/selectors/documentTypesSelectors";
import {
  selectDocumentsData,
  selectTotalDocuments,
} from "../../../store/selectors/documentsSelector";
import { makeToastMessage } from "../../../store/utils/makeToastMessage";
import { formatDate, formatDateTime } from "../../../util/helpers/dateHelpers";
import { replaceInRoute } from "../../../util/helpers/routeHelpers";
import DropdownFilterComponent from "../../Findings/FindingsTable/FilterComponents/DropdownFilterComponent/DropdownFilterComponent";
import TextRenderer from "../../Table/Renderers/TextRenderer/TextRenderer";
import Table from "../../Table/Table";
import DateFilterComponent from "./FilterComponents/DateFilterComponent/DateFilterComponent";

const DocumentsTable = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const tableRef = useRef(null);
  const clients = useSelector(selectClientsData);
  const documentTypes = useSelector(selectDocumentTypesData);
  const [selectedClient, setSelectedClient] = useState();
  const [selectedDocumentType, setSelectedDocumentType] = useState();
  const [date, setDate] = useState();

  const tableColumns = useMemo(() => {
    let columnsObject = Object.keys(documentsTableConstants).map((property) => {
      return {
        ...documentsTableConstants[property],
        field: t(documentsTableConstants[property].i18key),
        propertyName: property,
        backendProperty:
          documentsTableConstants[property]?.backendProperty || property,
        style: documentsTableConstants[property]?.style,
        renderer: documentsTableConstants[property]?.renderer || TextRenderer,
        rendererProps: { hideChangePassword: true },
      };
    });
    return columnsObject;
  }, [documentsTableConstants, t]);

  const mapDataFunction = useCallback((newData) => {
    return newData.map((singleData) => {
      let mappedDataObject = {
        documentNumber: singleData.documentNumber,
        client: singleData.client?.name,
        documentType: singleData?.documentType?.caption,
        documentDate: formatDate(singleData.documentDate),
        link: {
          t,
          link: singleData?.link,
        },
        lastChanges: {
          date: formatDateTime(
            singleData?.updatedAtUtc || new Date().toString()
          ),
          author: `${singleData?.updatedByUser?.firstName || ""} ${
            singleData?.updatedByUser?.lastName || ""
          }`,
        },
        id: singleData?.id,
        finding: singleData?.finding?.findingNumber,
        actions: {
          id: singleData?.id,
          documentNumber: singleData.documentNumber,
          handleApiResponseSuccess,
          refetchTable,
        },
      };
      return mappedDataObject;
    });
  }, []);

  const refetchTable = () => {
    tableRef?.current?.forceRefetch();
  };

  useEffect(() => {
    setDate(history?.location?.state?.filterPayload?.date);
    setSelectedClient(history?.location?.state?.filterPayload?.client);
    setSelectedDocumentType(
      history?.location?.state?.filterPayload?.documentType
    ); 
  }, []);

  const handleApiResponseSuccess = (data, isEditing = false) => {
    refetchTable();
    const titleI18Key = data?.isDeleted
      ? "toast.success.deletedTitle"
      : isEditing
      ? "toast.success.editedTitle"
      : "toast.success.createdTitle";
    const descriptionI18Key = data?.isDeleted
      ? "toast.success.deletedDescription"
      : isEditing
      ? "toast.success.editedDescription"
      : "toast.success.createdDescription";
    dispatch(closeModal());
    dispatch(closeLoadingModal());
    makeToastMessage(
      {
        title: t(titleI18Key, {
          typeOfData: t("documents.typeOfData"),
        }),
        description: t(descriptionI18Key, {
          name: data.name,
          typeOfData: t("documents.typeOfDataPlural").toLowerCase(),
        }),
      },
      {
        containerId: APPLICATION_TOAST_CONTAINER_ID,
      }
    );
  };

  const redirectHrefFunction = (id) => {
    return replaceInRoute(DOCUMENTS_EDIT_PAGE, { documentId: id });
  };
  console.log("Sad gledaj");
  console.log(documentTypes);

  const filterComponents = [
    <DropdownFilterComponent
      key={0}
      data={clients}
      label={t("documents.filter.client.label")}
      placeholder={t("documents.filter.client.placeholder")}
      selectedData={selectedClient}
      setSelectedData={setSelectedClient}
      dropdownLabel={["name"]}
    />,
    <DropdownFilterComponent
      key={1}
      data={documentTypes}
      label={t("documents.filter.documentType.label")}
      placeholder={t("documents.filter.documentType.placeholder")}
      selectedData={selectedDocumentType}
      setSelectedData={setSelectedDocumentType}
      dropdownLabel={["caption"]}
    />,
    <DateFilterComponent date={date} setDate={setDate} key={2} />,
  ];

  const applyFiltersFunction = () =>
    history?.replace?.({
      state: {
        ...history?.location?.state,
        filterPayload: {
          client: selectedClient,
          documentType: selectedDocumentType,
          date,
        },
      },
    });

  const handleResetFilter = () => {
    setDate();
    setSelectedClient();
    setSelectedDocumentType();
    history?.replace?.({
      state: {
        ...history?.location?.state,
        filterPayload: {},
      },
    });
  };

  return (
    <Table
      ref={tableRef}
      tableColumns={tableColumns}
      redirectHrefFunction={redirectHrefFunction}
      hideSearch
      showFilter
      filterComponents={filterComponents}
      customPayload={history?.location?.state?.filterPayload}
      filterResetFunc={handleResetFilter}
      applyFiltersFunction={applyFiltersFunction}
      searchPlaceholder={t("documents.table.searchPlaceholder")}
      dataSelector={selectDocumentsData}
      totalDataSelector={selectTotalDocuments}
      dispatchFunction={fetchDocuments}
      mapDataFunction={mapDataFunction}
      addButtonHref={DOCUMENTS_NEW_PAGE}
      clearDispatchFunction={clearDocuments}
    />
  );
};

DocumentsTable.propTypes = {
  children: PropTypes.node,
};

export default DocumentsTable;
