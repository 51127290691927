import { Box, Grid } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../themes";
import { TableColumnsContainer } from "../Table/TableContent/TableColumns/TableColumns.styled";
import { TableSingleColumnContainer } from "../Table/TableContent/TableColumns/TableSingleColumn/TableSingleColumn.styled";
import { TableDataRowContainer } from "../Table/TableContent/TableData/TableData.styled";
import { UserRendererContainer } from "../Users/UsersTable/ActiveUsersTable/Renderers/UserRenderer/UserRenderer.styled";

export const DashboardContainer = styled(Box)`
  padding-bottom: 20px;
  & ${TableSingleColumnContainer} {
    color: ${selectedTheme.colors.dashboardTitleColor};
  }
  & ${TableColumnsContainer} {
    background-color: ${selectedTheme.colors.dashboardHeaderColor};
  }
  & ${TableDataRowContainer} {
    background-color: ${selectedTheme.colors.dashboardRowColor};
    &:hover {
      background-color: ${selectedTheme.colors.tableRowSelectedBackgroundColor};
    }
  }
  & ${UserRendererContainer} {
    &:hover {
      cursor: initial;
    }
  }
`;

export const TablesContainer = styled(Grid)`
  margin-top: -15px;
`;


