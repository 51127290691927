import React from "react";
import PropTypes from "prop-types";
import {
  TemplateCardDetailContainer,
  TemplateCardDetailIcon,
  TemplateCardDetailValue,
} from "./TemplateCardDetail.styled";

const TemplateCardDetail = (props) => {
  return (
    <TemplateCardDetailContainer>
      <TemplateCardDetailIcon>{props?.icon}</TemplateCardDetailIcon>
      <TemplateCardDetailValue>{props?.value}</TemplateCardDetailValue>
    </TemplateCardDetailContainer>
  );
};

TemplateCardDetail.propTypes = {
  icon: PropTypes.node,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default TemplateCardDetail;
