import React from "react";
import PropTypes from "prop-types";
import { FindingItemTableRowContainer } from "./FindingItemTableRow.styled";
import FindingItemTableCell from "../FindingItemTableCell/FindingItemTableCell";

const FindingItemTableRow = (props) => {
  return (
    <FindingItemTableRowContainer>
      {props?.data?.map?.((singleData, index) => (
        <FindingItemTableCell
          useForScanningSections={props.useForScanningSections}
          value= {singleData?.answer}
          textAlignment={singleData?.textAlignment}
          key={index}
          readonly={props?.readonly}
          questionId={props?.questionId}
          rowIndex={props?.rowIndex}
          columnIndex={singleData?.columnIndex}
          answerId={singleData?.id}
          firstInLastRow={index === 0 && props?.lastRow}
          lastInLastRow={index === props?.data?.length - 1 && props?.lastRow}
          onCellChange={props.onCellChange}
          removeRow={props.removeRow}
        />
      ))}
    </FindingItemTableRowContainer>
  );
};

FindingItemTableRow.propTypes = {
  data: PropTypes.array,
  firstRow: PropTypes.bool,
  readonly: PropTypes.bool,
  rowIndex: PropTypes.number,
  questionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  columnId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  lastRow: PropTypes.bool,
  useForScanningSections: PropTypes.bool,
  onCellChange: PropTypes.func,
  removeRow: PropTypes.func
};

export default FindingItemTableRow;
