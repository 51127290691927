import { Box } from "@mui/material";
import styled, { css } from "styled-components";
import selectedTheme from "../../../../../../themes";
import { hexToRGB } from "../../../../../../util/helpers/colorHelper";

export const AlignmentPickerContainer = styled(Box)`
  display: flex;
`;
export const IconContainer = styled(Box)`
  padding: 2px 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  cursor: pointer;
  border: 2px solid ${selectedTheme.colors.borderColorSecond};
  height: 36px;
  background-color: ${(props) =>
    props?.$selected && selectedTheme.colors.orange};
  box-shadow: 0px 1px 2px ${hexToRGB(selectedTheme.colors.shadowColor, 0.05)};
  &:nth-child(1) {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }
  &:nth-child(3) {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }
  &:nth-child(2) {
    border-left: 0;
    border-right: 0;
  }
  ${(props) =>
    props?.$selected &&
    css`
      background-color: ${selectedTheme.colors.orange};
      & svg {
        fill: white;
      }
    `}
  & svg {
    width: 24px;
    height: 24px;
  }
`;
