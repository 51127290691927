import React, { useEffect, useImperativeHandle, useRef } from "react";
import PropTypes from "prop-types";
import {
  TextInputFieldContainer,
  InputField,
  InputLabel,
  ErrorMessage,
  CalendarAdornment,
  InputFieldContainer,
  InputRightElement,
} from "./TextInputField.styled";
import { forwardRef } from "react";
import ErrorIcon from "../../Icon/Icons/ErrorIcon";
import { useTranslation } from "react-i18next";

const TextInputField = forwardRef((props, ref) => {
  const inputRef = useRef(null);
  const { t } = useTranslation();

  useImperativeHandle(
    ref,
    () => ({
      resetInput: () => {
        inputRef.current.value = null;
      },
    }),
    []
  );

  const onChange = (event) => {
    if (!props?.disabledField) {
      var regExp = /[a-zA-Z]/g;
      if (regExp.test(event.target.value) && props?.number) {
        event.target.value = event.target.value.slice(0, -1);
      }
      props?.onChange(event);
    }
  };

  useEffect(() => {
    if (props?.date) {
      if (inputRef?.current)
        inputRef.current.onfocus = () => inputRef.current?.blur?.();
    }
  }, [props?.date, inputRef.current]);

  return (
    <TextInputFieldContainer
      className={props?.className}
      onClick={props?.onClick}
      disabled={props?.disabledField}
      $isErrorShown={props?.error}
    >
      {props?.label && !props?.customLabelComponent && (
        <InputLabel $required={props?.requiredField && !props?.value} maxLabelWidth={props?.maxLabelWidth}>
          {props?.label}
        </InputLabel>
      )}
      {props?.customLabelComponent && <>{props?.customLabelComponent}</>}
      <InputFieldContainer>
        <InputField
          onFocus={props?.onFocus}
          inputRef={inputRef}
          variant="standard"
          disabled={props?.disabledField}
          $isErrorShown={props?.error}
          InputProps={{ disableUnderline: true }}
          type={props?.type}
          name={props?.name}
          onChange={onChange}
          onBlur={props?.onBlur}
          placeholder={props?.placeholder}
          value={props?.value}
          {...props?.inputProps}
        />
        {props?.icon && !props?.error ? (
          <InputRightElement>{props?.icon}</InputRightElement>
        ) : (
          props?.error && (
            <InputRightElement
              fullWidth={props?.fullWidth}
              isSmall={props?.inputProps?.isSmall}
            >
              <ErrorIcon />
            </InputRightElement>
          )
        )}
        {props?.date && !props?.error && <CalendarAdornment />}
      </InputFieldContainer>
      {props?.error && <ErrorMessage>{t(props?.error)}</ErrorMessage>}
    </TextInputFieldContainer>
  );
});

TextInputField.displayName = "TextInputField";

TextInputField.propTypes = {
  label: PropTypes.string,
  error: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onClick: PropTypes.func,
  date: PropTypes.bool,
  disabledField: PropTypes.bool,
  value: PropTypes.any,
  name: PropTypes.any,
  number: PropTypes.bool,
  requiredField: PropTypes.bool,
  inputProps: PropTypes.any,
  placeholder: PropTypes.string,
  icon: PropTypes.node,
  fullWidth: PropTypes.bool,
  isSmall: PropTypes.bool,
  maxLabelWidth: PropTypes.string, 
  customLabelComponent: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
  ]),
};

TextInputField.defaultProps = {
  onChange: () => {},
  onFocus: () => {},
  maxLabelWidth: 'auto',
};

export default TextInputField;
