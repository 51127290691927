import React from "react";
import PropTypes from "prop-types";
import {
  EmptyStandardsTableContainer,
  EmptyStandardsText,
} from "./EmptyStandardsTable.styled";
import { useTranslation } from "react-i18next";

const EmptyStandardsTable = () => {
  const { t } = useTranslation();
  return (
    <EmptyStandardsTableContainer>
      <EmptyStandardsText>
        {t("scanningObjects.newScanningObject.templateEditor.noStandardsText")}
      </EmptyStandardsText>
    </EmptyStandardsTableContainer>
  );
};

EmptyStandardsTable.propTypes = {
  children: PropTypes.node,
};

export default EmptyStandardsTable;
