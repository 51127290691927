import {
  deleteRequest,
  getRequest,
  patchRequest,
  postRequest,
  putRequest,
  replaceInUrl,
} from ".";
import apiEndpoints from "./apiEndpoints";

export const attemptFetchLicenses = (payload) =>
  getRequest(
    replaceInUrl(apiEndpoints.licenses.getLicenses, {
      companyId: payload?.companyId,
    }),
    payload?.query
  );

export const attemptCreateLicense = (payload) =>
  postRequest(
    replaceInUrl(apiEndpoints.licenses.createLicense, {
      companyId: payload?.companyId,
    }),
    payload?.data
  );

export const attemptFetchSingleLicense = (payload) =>
  getRequest(
    replaceInUrl(apiEndpoints.licenses.getSingleLicense, {
      companyId: payload?.companyId,
      licenseId: payload?.licenseId,
    })
  );

export const attemptEditSingleLicense = (payload) =>
  putRequest(
    replaceInUrl(apiEndpoints.licenses.editLicense, {
      licenseId: payload?.licenseId,
    }),
    payload?.data
  );

export const attemptDeleteLicense = (payload) =>
  deleteRequest(
    replaceInUrl(apiEndpoints.licenses.deleteLicense, {
      licenseId: payload?.licenseId,
    })
  );
export const attemptDeactivateLicense = (payload) =>
  patchRequest(
    replaceInUrl(apiEndpoints.licenses.deactivateLicense, {
      companyId: payload?.companyId,
      licenseId: payload?.licenseId,
    })
  );
export const attemptActivateLicense = (payload) =>
  patchRequest(
    replaceInUrl(apiEndpoints.licenses.activateLicense, {
      companyId: payload?.companyId,
      licenseId: payload?.licenseId,
    })
  );
