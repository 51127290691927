import React from "react";
// import PropTypes from 'prop-types'
import { ReactComponent as UndoIconSvg } from "../../../assets/images/svg/undo.svg";

const UndoIcon = (props) => {
  return <UndoIconSvg {...props} />;
};

UndoIcon.propTypes = {};

export default UndoIcon;
