import styled from "styled-components";
import { Box } from "@mui/material";

export const CopyFindingModalContainer = styled(Box)`
  padding: 32px;
  position: relative;
  background: white;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  z-index: ${(props) => "15" + props?.rank || "151"};
`;

export const InputsContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  & * {
    text-align: left;
  }
  .react-datepicker__header * {
    text-align: center !important;
  }
`;
