import React from "react";
import PropTypes from "prop-types";
import TextInputField from "../../../Field/TextInputField/TextInputField";
import { useTranslation } from "react-i18next";

const PersonInChanrgeField = (props) => {
  const { t } = useTranslation();
  const formik = props?.formik;
  return (
    <TextInputField
      name="personInCharge"
      requiredField={formik?.values?.personInCharge?.length === 0}
      label={t("findingTypes.modal.personInCharge")}
      onChange={formik?.handleChange}
      onBlur={formik?.handleBlur}
      disabledField={props?.disabled}
      error={formik?.touched?.personInCharge && formik?.errors?.personInCharge}
      value={formik?.values?.personInCharge}
      maxLabelWidth="252px"  
    />
  );
};

PersonInChanrgeField.propTypes = {
  children: PropTypes.node,
  disabled: PropTypes.bool,
  formik: PropTypes.object,
};

export default PersonInChanrgeField;
