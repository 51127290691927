import React from 'react'
// import PropTypes from 'prop-types'

const ImageTypeInputs = () => {
  return (
    <></>
  )
}

ImageTypeInputs.propTypes = {

}

export default ImageTypeInputs
