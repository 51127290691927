/*eslint-disable*/
import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import {
  CopyRowIcon,
  FindingItemTableCellContainer,
  IconContainer,
  RemoveRowIcon,
  TableCellInput,
  ToolsContainer,
} from "./FindingItemTableCell.styled";
import { useMemo } from "react";
import { useState } from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import {
  copyFindingColumnAnswer,
  removeFindingColumnAnswer,
  setFindingColumn,
  setFindingColumnAnswer,
  setSingleFindingColumnsFromEdited,
} from "../../../../../../store/actions/findings/findingsActions";
import { selectMyProfileId } from "../../../../../../store/selectors/loginSelectors";
import { selectColumnFromAnswerId } from "../../../../../../store/selectors/findingsSelectors";
import { useTranslation } from "react-i18next";

const FindingItemTableCell = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userId = useSelector(selectMyProfileId);
  const [isFocused, setIsFocused] = useState(false);
  const inputRef = useRef(null);
  const [value, setValue] = useState("");
  const column = useSelector(selectColumnFromAnswerId(props?.answerId));

  useEffect(() => {
    if (value !== props?.value) setValue(props?.value);
  }, [props?.value]);

  const handleFocusInputField = () => {
    inputRef?.current?.focus?.();
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = (newValue) => {
    setIsFocused(false);
    if (props?.titleCell) {
      return dispatch(
        setFindingColumn({
          column: {
            columnId: props?.columnId,
            columnTitle: newValue,
            isEdited: props?.value !== newValue,
          },
        })
      );
    }
    if (props?.subtitleCell) {
      return dispatch(
        setFindingColumn({
          column: {
            columnId: props?.columnId,
            columnSubtitle: newValue,
            isEdited: props?.value !== newValue,
          },
        })
      );
    }
    dispatch(
      setFindingColumnAnswer({
        rowIndex: props?.rowIndex,
        columnId: column?.id,
        questionId: props?.questionId,
        answer: newValue,
        answerId: props?.answerId,
        userId,
        isEdited: props?.value !== newValue,
        // edited: answerObject.currentAnswer !== answerObject.oldAnswer
      })
    );
    if (props.useForScanningSections) {
      props.onCellChange(props.rowIndex, props.columnIndex, newValue);
    }
  };

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  const content = useMemo(() => {
    if (props?.readonly) return props?.value;
    return (
      <TableCellInput
        spellCheck={false}
        value={value}
        disabled={props?.readonly}
        $textAlignment={props?.textAlignment || column?.textAlignment}
        ref={inputRef}
        onChange={(event) => handleChange(event.target.value)}
        onFocus={handleFocus}
        onBlur={(event) => handleBlur(event.target.value)}
      />
    );
  }, [
    props?.readonly,
    props?.value,
    handleBlur,
    handleFocus,
    inputRef.current,
  ]);

  const handleCopyRow = () => {
    handleBlur(value);
    batch(() => {
      dispatch(
        setSingleFindingColumnsFromEdited({ itemId: props?.questionId })
      );
      dispatch(
        copyFindingColumnAnswer({
          questionId: props?.questionId,
          answerIndex: props?.rowIndex,
        })
      );
    });
  };

  const handleRemoveRow = () => {
    if (props.useForScanningSections) {
      props.removeRow(props?.rowIndex)
    }
    else {
      batch(() => {
        dispatch(
          setSingleFindingColumnsFromEdited({ itemId: props?.questionId })
        );
        dispatch(
          removeFindingColumnAnswer({
            questionId: props?.questionId,
            answerIndex: props?.rowIndex,
          })
        );
      });
    }
  };

  return (
    <FindingItemTableCellContainer
      rowSpan={props?.rowSpan}
      colSpan={props?.colSpan}
      onClick={handleFocusInputField}
      $isHeaderCell={props?.isHeaderCell}
      $isFocused={isFocused}
      $textAlignment={props?.textAlignment || column?.textAlignment}
      $firstInFirstRow={props?.firstInFirstRow}
      $lastInFirstRow={props?.lastInFirstRow}
      $firstInLastRow={props?.firstInLastRow}
      $lastInLastRow={props?.lastInLastRow}
    >
      <div>
        {content}
        {isFocused && !props?.isHeaderCell && (
          <ToolsContainer $isFocused={true}>
            {!props.useForScanningSections && (
            <IconContainer
              title={t("table.copyRow")}
              innerContainerProps={{ onMouseDown: handleCopyRow }}
            >
              <CopyRowIcon />
            </IconContainer>
            )}
            <IconContainer
              title={t("table.removeRow")}
              innerContainerProps={{ onMouseDown: handleRemoveRow }}
            >
              <RemoveRowIcon />
            </IconContainer>
          </ToolsContainer>
        )}
      </div>
    </FindingItemTableCellContainer>
  );
};

FindingItemTableCell.propTypes = {
  value: PropTypes.string,
  isHeaderCell: PropTypes.bool,
  firstInFirstRow: PropTypes.bool,
  lastInFirstRow: PropTypes.bool,
  firstInLastRow: PropTypes.bool,
  lastInLastRow: PropTypes.bool,
  titleCell: PropTypes.bool,
  subtitleCell: PropTypes.bool,
  columnId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  questionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  answerId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  rowIndex: PropTypes.number,
  readonly: PropTypes.bool,
  colSpan: PropTypes.number,
  rowSpan: PropTypes.number,
  textAlignment: PropTypes.string,
  useForScanningSections: PropTypes.bool,
  onCellChange: PropTypes.func,
  removeRow: PropTypes.func
};

FindingItemTableCell.defaultProps = {
  rowSpan: 1,
  colSpan: 1,
};

export default FindingItemTableCell;
