import React from "react";
import PropTypes from "prop-types";
import FindingTypesContent from "../../../components/FindingTypes/FindingTypesContent";

const FindingTypesPage = () => {
  return <FindingTypesContent />
};

FindingTypesPage.propTypes = {
  children: PropTypes.node,
};

export default FindingTypesPage;
