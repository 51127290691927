import React from "react";
import PropTypes from "prop-types";
import { TitleTypography } from "./Title.styled";

const Title = (props) => {
  return (
    <TitleTypography className={props?.className} {...props}>
      {props?.title || props?.children}
    </TitleTypography>
  );
};

Title.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.any,
};

export default Title;
