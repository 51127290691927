import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import useLexicalEditable from "@lexical/react/useLexicalEditable";
import React, { useMemo } from "react";
import { createPortal } from "react-dom";
import TableCellResizer from "./TableCellResizer/TableCellResizer";

const TableCellResizerPlugin = () => {
  const [editor] = useLexicalComposerContext();
  const isEditable = useLexicalEditable();

  return useMemo(
    () =>
      isEditable
        ? createPortal(<TableCellResizer editor={editor} />, document.body)
        : null,
    [editor, isEditable]
  );
};

export default TableCellResizerPlugin;
