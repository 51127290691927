import {
  createClearType,
  createErrorType,
  createFetchType,
  createSetType,
  createSuccessType,
} from "../actionHelpers";

export const SINGLE_SCANNING_OBJECT_SCOPE = "SINGLE_SCANNING_OBJECT_SCOPE";
export const SINGLE_SCANNING_OBJECT_FETCH = createFetchType(
  SINGLE_SCANNING_OBJECT_SCOPE
);
export const SINGLE_SCANNING_OBJECT_SUCCESS = createSuccessType(
  SINGLE_SCANNING_OBJECT_SCOPE
);
export const SINGLE_SCANNING_OBJECT_ERROR = createErrorType(
  SINGLE_SCANNING_OBJECT_SCOPE
);
export const SINGLE_SCANNING_OBJECT_SET = createSetType(
  SINGLE_SCANNING_OBJECT_SCOPE
);
export const SINGLE_SCANNING_OBJECT_CLEAR = createClearType(
  SINGLE_SCANNING_OBJECT_SCOPE
);

export const SINGLE_SCANNING_OBJECT_CHECKLIST_TEMPLATES_SET = createSetType(
  "SINGLE_SCANNING_OBJECT_CHECKLIST_TEMPLATES_SET"
);

export const SINGLE_SCANNING_OBJECT_SINGLE_CHECKLIST_TEMPLATE_SET =
  createSetType("SINGLE_SCANNING_OBJECT_SINGLE_CHECKLIST_TEMPLATE_SET");
export const SINGLE_SCANNING_OBJECT_SINGLE_CHECKLIST_TEMPLATE_ADD =
  createSetType("SINGLE_SCANNING_OBJECT_SINGLE_CHECKLIST_TEMPLATE_ADD");
export const SINGLE_SCANNING_OBJECT_SINGLE_CHECKLIST_TEMPLATE_EDIT =
  createSetType("SINGLE_SCANNING_OBJECT_SINGLE_CHECKLIST_TEMPLATE_EDIT");
export const SINGLE_SCANNING_OBJECT_SINGLE_CHECKLIST_TEMPLATE_TOGGLE =
  createSetType("SINGLE_SCANNING_OBJECT_SINGLE_CHECKLIST_TEMPLATE_TOGGLE");

export const SINGLE_SCANNING_OBJECT_SECTIONS_SET = createSetType(
  "SINGLE_SCANNING_OBJECT_SECTIONS_SET"
);
export const SINGLE_SCANNING_OBJECT_INSTRUMENTS_ADD = createSetType(
  "SINGLE_SCANNING_OBJECT_INSTRUMENTS_ADD"
);
export const SINGLE_SCANNING_OBJECT_INSTRUMENTS_DELETE = createSetType(
  "SINGLE_SCANNING_OBJECT_INSTRUMENTS_DELETE"
);
export const SINGLE_SCANNING_OBJECT_DEFAULT_STANDARDS_ADD = createSetType(
  "SINGLE_SCANNING_OBJECT_DEFAULT_STANDARDS_ADD"
);
export const SINGLE_SCANNING_OBJECT_STANDARDS_ADD = createSetType(
  "SINGLE_SCANNING_OBJECT_STANDARDS_ADD"
);
export const SINGLE_SCANNING_OBJECT_STANDARDS_DELETE = createSetType(
  "SINGLE_SCANNING_OBJECT_STANDARDS_DELETE"
);

export const SINGLE_SCANNING_OBJECT_SINGLE_SECTION_SET = createSetType(
  "SINGLE_SCANNING_OBJECT_SINGLE_SECTION_SET"
);
export const SINGLE_SCANNING_OBJECT_SINGLE_SECTION_ADD = createSetType(
  "SINGLE_SCANNING_OBJECT_SINGLE_SECTION_ADD"
);
export const SINGLE_SCANNING_OBJECT_SINGLE_SECTION_EDIT = createSetType(
  "SINGLE_SCANNING_OBJECT_SINGLE_SECTION_EDIT"
);
export const SINGLE_SCANNING_OBJECT_SINGLE_SECTION_DELETE = createSetType(
  "SINGLE_SCANNING_OBJECT_SINGLE_SECTION_DELETE"
);
export const SINGLE_SCANNING_OBJECT_SINGLE_SECTION_MOVE = createSetType(
  "SINGLE_SCANNING_OBJECT_SINGLE_SECTION_MOVE"
);

export const SINGLE_SCANNING_OBJECT_SECTION_QUESTIONS_SET = createSetType(
  "SINGLE_SCANNING_OBJECT_SECTION_QUESTIONS_SET"
);

export const SINGLE_SCANNING_OBJECT_SECTION_SINGLE_QUESTION_SET = createSetType(
  "SINGLE_SCANNING_OBJECT_SECTION_SINGLE_QUESTION_SET"
);
export const SINGLE_SCANNING_OBJECT_TYPE_SET = createSetType(
  "SINGLE_SCANNING_OBJECT_TYPE_SET"
);
export const SINGLE_SCANNING_OBJECT_SECTION_SINGLE_QUESTION_ADD = createSetType(
  "SINGLE_SCANNING_OBJECT_SECTION_SINGLE_QUESTION_ADD"
);
export const SINGLE_SCANNING_OBJECT_SECTION_SINGLE_QUESTION_EDIT =
  createSetType("SINGLE_SCANNING_OBJECT_SECTION_SINGLE_QUESTION_EDIT");
export const SINGLE_SCANNING_OBJECT_SECTION_SINGLE_QUESTION_DELETE =
  createSetType("SINGLE_SCANNING_OBJECT_SECTION_SINGLE_QUESTION_DELETE");
export const SINGLE_SCANNING_OBJECT_SECTION_SINGLE_QUESTION_MOVE =
  createSetType("SINGLE_SCANNING_OBJECT_SECTION_SINGLE_QUESTION_MOVE");

export const SINGLE_QUESTION_MOVE_ON_DRAG_AND_DROP = createSetType(
  "SINGLE_QUESTION_MOVE_ON_DRAG_AND_DROP"
);

const SCANNING_OBJECTS_CREATE_SCOPE = "SCANNING_OBJECTS_CREATE_SCOPE";
export const SCANNING_OBJECTS_CREATE_FETCH = createFetchType(
  SCANNING_OBJECTS_CREATE_SCOPE
);
export const SCANNING_OBJECTS_CREATE_SUCCESS = createSuccessType(
  SCANNING_OBJECTS_CREATE_SCOPE
);
export const SCANNING_OBJECTS_CREATE_ERROR = createErrorType(
  SCANNING_OBJECTS_CREATE_SCOPE
);

const SCANNING_OBJECTS_EDIT_SCOPE = "SCANNING_OBJECTS_EDIT_SCOPE";
export const SCANNING_OBJECTS_EDIT_FETCH = createFetchType(
  SCANNING_OBJECTS_EDIT_SCOPE
);
export const SCANNING_OBJECTS_EDIT_SUCCESS = createSuccessType(
  SCANNING_OBJECTS_EDIT_SCOPE
);
export const SCANNING_OBJECTS_EDIT_ERROR = createErrorType(
  SCANNING_OBJECTS_EDIT_SCOPE
);

const SCANNING_OBJECTS_DELETE_SCOPE = "SCANNING_OBJECTS_DELETE_SCOPE";
export const SCANNING_OBJECTS_DELETE_FETCH = createFetchType(
  SCANNING_OBJECTS_DELETE_SCOPE
);
export const SCANNING_OBJECTS_DELETE_SUCCESS = createSuccessType(
  SCANNING_OBJECTS_DELETE_SCOPE
);
export const SCANNING_OBJECTS_DELETE_ERROR = createErrorType(
  SCANNING_OBJECTS_DELETE_SCOPE
);

export const SCANNING_OBJECTS_SCOPE = "SCANNING_OBJECTS_SCOPE";
export const SCANNING_OBJECTS_FETCH = createFetchType(SCANNING_OBJECTS_SCOPE);
export const SCANNING_OBJECTS_SUCCESS = createSuccessType(
  SCANNING_OBJECTS_SCOPE
);
export const SCANNING_OBJECTS_ERROR = createErrorType(SCANNING_OBJECTS_SCOPE);
export const SCANNING_OBJECTS_SET = createSetType(SCANNING_OBJECTS_SCOPE);
export const SCANNING_OBJECTS_CLEAR = createClearType(SCANNING_OBJECTS_SCOPE);

export const SCANNING_OBJECTS_CHANGED_SET = createSetType(
  "SCANNING_OBJECTS_CHANGED_SET"
);

export const SCANNING_OBJECTS_SET_TOTAL = createSetType(
  "SCANNING_OBJECTS_TOTAL_SCOPE"
);

export const SCANNING_OBJECTS_SET_CONCLUSION = createSetType(
  "SCANNING_OBJECTS_SET_CONCLUSION"
);

const SCANNING_OBJECTS_COPY_SCOPE = "SCANNING_OBJECTS_COPY_SCOPE";
export const SCANNING_OBJECTS_COPY_FETCH = createFetchType(
  SCANNING_OBJECTS_COPY_SCOPE
);
export const SCANNING_OBJECTS_COPY_SUCCESS = createSuccessType(
  SCANNING_OBJECTS_COPY_SCOPE
);
export const SCANNING_OBJECTS_COPY_ERROR = createErrorType(
  SCANNING_OBJECTS_COPY_SCOPE
);
