export const lowPriority = 1;

export const fontSizes = [
  "10pt",
  "11pt",
  "12pt",
  "13pt",
  "14pt",
  "15pt",
  "16pt",
  "17pt",
  "18pt",
  "19pt",
  "20pt",
];

export const BOLD = "bold";
export const ITALIC = "italic";
export const UNDERLINE = "underline";
export const LEFT_ALIGN = "left";
export const CENTER_ALIGN = "center";
export const RIGHT_ALIGN = "right";
export const JUSTIFY = "justify";
export const FONT_SIZE = "font-size";
export const ORDERED_LIST = "ol";
export const UNORDERED_LIST = "ul";
export const PARAGRAPH = "paragraph";
