import {
  createClearType,
  createErrorType,
  createFetchType,
  createSetType,
  createSuccessType,
} from "../actionHelpers";

export const SET_LICENSE = createSetType("SET_LICENSE");
export const SET_LICENSE_ERROR = "SET_LICENSE_ERROR";

export const GET_LICENSES_SCOPE = "GET_LICENSES_SCOPE";
export const GET_LICENSES_FETCH = createFetchType(GET_LICENSES_SCOPE);
export const GET_LICENSES_SUCCESS = createSuccessType(GET_LICENSES_SCOPE);
export const GET_LICENSES_ERROR = createErrorType(GET_LICENSES_SCOPE);
export const GET_LICENSES_CLEAR = createClearType(GET_LICENSES_SCOPE);

export const SINGLE_LICENSE_SCOPE = "SINGLE_LICENSE_SCOPE";
export const SINGLE_LICENSE_FETCH = createFetchType(SINGLE_LICENSE_SCOPE);
export const SINGLE_LICENSE_SUCCESS = createSuccessType(SINGLE_LICENSE_SCOPE);
export const SINGLE_LICENSE_ERROR = createErrorType(SINGLE_LICENSE_SCOPE);
export const SINGLE_LICENSE_SET = createSetType(SINGLE_LICENSE_SCOPE);

const CREATE_LICENSE_SCOPE = "CREATE_LICENSE_SCOPE";
export const CREATE_LICENSE_FETCH = createFetchType(CREATE_LICENSE_SCOPE);
export const CREATE_LICENSE_SUCCESS = createSuccessType(CREATE_LICENSE_SCOPE);
export const CREATE_LICENSE_ERROR = createErrorType(CREATE_LICENSE_SCOPE);

const EDIT_LICENSE_SCOPE = "EDIT_LICENSE_SCOPE";
export const EDIT_LICENSE_FETCH = createFetchType(EDIT_LICENSE_SCOPE);
export const EDIT_LICENSE_SUCCESS = createSuccessType(EDIT_LICENSE_SCOPE);
export const EDIT_LICENSE_ERROR = createErrorType(EDIT_LICENSE_SCOPE);

const DELETE_LICENSE_SCOPE = "DELETE_LICENSE_SCOPE";
export const DELETE_LICENSE_FETCH = createFetchType(DELETE_LICENSE_SCOPE);
export const DELETE_LICENSE_SUCCESS = createSuccessType(DELETE_LICENSE_SCOPE);
export const DELETE_LICENSE_ERROR = createErrorType(DELETE_LICENSE_SCOPE);

const DEACTIVATE_LICENSE_SCOPE = "DEACTIVATE_LICENSE_SCOPE";
export const DEACTIVATE_LICENSE_FETCH = createFetchType(
  DEACTIVATE_LICENSE_SCOPE
);
export const DEACTIVATE_LICENSE_SUCCESS = createSuccessType(
  DEACTIVATE_LICENSE_SCOPE
);
export const DEACTIVATE_LICENSE_ERROR = createErrorType(
  DEACTIVATE_LICENSE_SCOPE
);

const ACTIVATE_LICENSE_SCOPE = "ACTIVATE_LICENSE_SCOPE";
export const ACTIVATE_LICENSE_FETCH = createFetchType(ACTIVATE_LICENSE_SCOPE);
export const ACTIVATE_LICENSE_SUCCESS = createSuccessType(
  ACTIVATE_LICENSE_SCOPE
);
export const ACTIVATE_LICENSE_ERROR = createErrorType(ACTIVATE_LICENSE_SCOPE);

export const SET_LICENSES = createSetType("SET_LICENSES");
export const SET_TOTAL_LICENSES = createSetType("SET_TOTAL_LICENSES");
export const CLEAR_LICENSES = createClearType("CLEAR_LICENSES");
