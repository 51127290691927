import React from "react";
import PropTypes from "prop-types";
import TextInputField from "../../../Field/TextInputField/TextInputField";
import { useTranslation } from "react-i18next";
import newClientValidationSchema from "../../../../validations/newClientValidationSchema";
import Tooltip from "../../../Tooltip/Tooltip";

const ClientNameField = (props) => {
  const { t } = useTranslation();
  const formik = props?.formik;
  return (
    <Tooltip
      title={
        formik?.values?.clientName?.length > 41
          ? formik?.values?.clientName
          : ""
      }
    >
      <TextInputField
        name="clientName"
        placeholder={t("clients.newClientPlaceholders.clientName")}
        label={t("clients.clientName")}
        value={formik?.values?.clientName}
        onChange={formik?.handleChange}
        requiredField={
          newClientValidationSchema?.()?.fields?.clientName?.exclusiveTests
            ?.required
        }
        inputProps={{
          sx: {
            input: {
              paddingRight:
                formik?.errors?.clientName &&
                formik?.touched?.clientName &&
                "40px !important",
              width:
                formik?.errors?.clientName &&
                formik?.touched?.clientName &&
                "314px !important",
            },
          },
        }}
        error={formik?.touched?.clientName && formik?.errors?.clientName}
        helperText={formik?.errors?.clientName && formik?.touched?.clientName}
        disabledField={props?.isReadOnly}
      />
    </Tooltip>
  );
};

ClientNameField.propTypes = {
  formik: PropTypes.object,
  isReadOnly: PropTypes.bool,
};

export default ClientNameField;
