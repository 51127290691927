import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { findingFormikConstants } from "../../../../../../initialValues/findingInitialValues";
import { FindingTextField } from "../FindingSettingsForm.styled";
import { selectSingleFindingSettingsOld } from "../../../../../../store/selectors/findingsSelectors";
import { useDispatch, useSelector } from "react-redux";
import { setSingleFindingLocationToEdit } from "../../../../../../store/actions/findings/findingsActions";

const LocationField = (props) => {
  const formik = props?.formik;
  const { t } = useTranslation();
  const findingSettingsOld = useSelector(selectSingleFindingSettingsOld);
  const dispatch = useDispatch();

  const handleChange = (e) => {
    dispatch(
      setSingleFindingLocationToEdit({
        value: e?.target?.value,
      })
    );
    formik?.setFieldValue?.(findingFormikConstants.location, {
      edited: e?.target?.value !== findingSettingsOld?.location?.value,
      value: e.target.value,
    });
  };

  return (
    <FindingTextField
      name={findingFormikConstants.location}
      requiredField
      disabledField={props?.disabled}
      label={t("findings.form.location.title")}
      placeholder={t("findings.form.location.placeholder")}
      value={formik?.values?.[findingFormikConstants.location].value}
      onChange={handleChange}
      error={
        formik?.touched?.[findingFormikConstants.location]?.value &&
        formik?.errors?.[findingFormikConstants.location]?.value
      }
      helperText={
        formik?.errors?.[findingFormikConstants.location]?.value &&
        formik?.touched?.[findingFormikConstants.location]?.value
      }
      fullWidth
    />
  );
};

LocationField.propTypes = {
  formik: PropTypes.object,
  disabled: PropTypes.bool,
};

export default LocationField;
