import { Box, TextField } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../themes";
import SearchIcon from "../../Icon/Icons/SearchIcon";

export const SearchInputContainer = styled(TextField)`
  max-height: 36px;
  border-radius: 8px;
  padding-left: 2px;
  flex: 1;
  width: 100%;
  background: ${selectedTheme.colors.sidebar.hoveredIcon};
  & * {
    max-height: 36px;
  }
  & fieldset {
    border: 0;
  }
  & input {
    font-family: ${selectedTheme.fonts.mainText};
    color: ${selectedTheme.colors.labelTextColor};
    font-weight: 300;
    font-size: 12px;
    line-height: 15px;
  }
`;
export const SearchEndIcon = styled(SearchIcon)`
  cursor: pointer;
`;
export const FormContainer = styled(Box)``;
export const SubmitButton = styled(Box)`
  padding: 0;
  background-color: transparent;
  border: 0;
`;
