import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  HeaderIsOnlineContainer,
  HeaderOnlineIcon,
  HeaderOnlineIconCircle,
  NotOnlinePopoverComponent,
} from "./HeaderIsOnline.styled";
import NotOnlinePopover from "../../Popovers/NotOnlinePopover/NotOnlinePopover";
import { useRef } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectOpenOnlinePopover } from "../../../store/selectors/appSelectors";
import {
  openOnlinePopover,
  setIsOnline,
} from "../../../store/actions/app/appActions";
import { useCallback } from "react";

const HeaderIsOnline = () => {
  const containerRef = useRef(null);
  const dispatch = useDispatch();
  const shouldOpenOnlinePopover = useSelector(selectOpenOnlinePopover);
  const [iconAnchor, setIconAnchor] = useState(null);
  const [isPopoverOpened, setIsPopoverOpened] = useState(false);

  const listener = useCallback(() => {
    dispatch(setIsOnline(true));
  }, [dispatch]);

  useEffect(() => {
    window.addEventListener("online", listener);
    return () => {
      window.removeEventListener("online", listener);
    };
  }, []);

  useEffect(() => {
    if (shouldOpenOnlinePopover) {
      dispatch(openOnlinePopover());
      setIsPopoverOpened(true);
    }
  }, [shouldOpenOnlinePopover]);

  useEffect(() => {
    setIconAnchor(containerRef.current);
    setIsPopoverOpened(true);
  }, [containerRef.current]);

  const closePopover = () => {
    setIsPopoverOpened(false);
  };
  return (
    <>
      <HeaderIsOnlineContainer
        ref={containerRef}
        onClick={() => {
          setIsPopoverOpened(true);
        }}
      >
        <HeaderOnlineIconCircle />
        <HeaderOnlineIcon />
      </HeaderIsOnlineContainer>
      <NotOnlinePopoverComponent
        anchorEl={iconAnchor}
        open={isPopoverOpened}
        onClose={closePopover}
        content={<NotOnlinePopover closePopover={closePopover} />}
      />
    </>
  );
};

HeaderIsOnline.propTypes = {
  children: PropTypes.node,
};

export default HeaderIsOnline;
