import {
  STANDARDS_CLEAR,
  STANDARDS_DELETE_ERROR,
  STANDARDS_DELETE_FETCH,
  STANDARDS_DELETE_SUCCESS,
  STANDARDS_SET_TOTAL,
} from "./standardsActionConstants";
import {
  STANDARDS_ERROR,
  STANDARDS_FETCH,
  STANDARDS_SUCCESS,
  STANDARDS_SET,
  SINGLE_STANDARD_ERROR,
  SINGLE_STANDARD_FETCH,
  SINGLE_STANDARD_SUCCESS,
  SINGLE_STANDARD_SET,
  STANDARDS_CREATE_ERROR,
  STANDARDS_CREATE_FETCH,
  STANDARDS_CREATE_SUCCESS,
  STANDARDS_EDIT_ERROR,
  STANDARDS_EDIT_FETCH,
  STANDARDS_EDIT_SUCCESS,
} from "./standardsActionConstants";

export const fetchStandards = (payload) => ({
  type: STANDARDS_FETCH,
  payload,
});
export const fetchStandardsSuccess = (payload) => ({
  type: STANDARDS_SUCCESS,
  payload,
});
export const fetchStandardsError = (payload) => ({
  type: STANDARDS_ERROR,
  payload,
});
export const fetchCreateStandard = (payload) => ({
  type: STANDARDS_CREATE_FETCH,
  payload,
});
export const fetchCreateStandardSuccess = (payload) => ({
  type: STANDARDS_CREATE_SUCCESS,
  payload,
});
export const fetchCreateStandardError = (payload) => ({
  type: STANDARDS_CREATE_ERROR,
  payload,
});
export const fetchEditStandard = (payload) => ({
  type: STANDARDS_EDIT_FETCH,
  payload,
});
export const fetchEditStandardSuccess = (payload) => ({
  type: STANDARDS_EDIT_SUCCESS,
  payload,
});
export const fetchEditStandardError = (payload) => ({
  type: STANDARDS_EDIT_ERROR,
  payload,
});

export const fetchSingleStandard = (payload) => ({
  type: SINGLE_STANDARD_FETCH,
  payload,
});
export const fetchSingleStandardSuccess = (payload) => ({
  type: SINGLE_STANDARD_SUCCESS,
  payload,
});
export const fetchSingleStandardError = (payload) => ({
  type: SINGLE_STANDARD_ERROR,
  payload,
});
export const setStandards = (payload) => ({
  type: STANDARDS_SET,
  payload,
});
export const setStandardsTotal = (payload) => ({
  type: STANDARDS_SET_TOTAL,
  payload,
});
export const clearStandards = () => ({
  type: STANDARDS_CLEAR,
});

export const setSingleStandard = (payload) => ({
  type: SINGLE_STANDARD_SET,
  payload,
});

export const fetchDeleteStandard = (payload) => ({
  type: STANDARDS_DELETE_FETCH,
  payload,
});
export const fetchDeleteStandardSuccess = (payload) => ({
  type: STANDARDS_DELETE_SUCCESS,
  payload,
});
export const fetchDeleteStandardError = (payload) => ({
  type: STANDARDS_DELETE_ERROR,
  payload,
});
