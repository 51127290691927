import {
  CLEAR_LICENSES,
  SET_LICENSES,
  SET_TOTAL_LICENSES,
  SINGLE_LICENSE_SET,
} from "../../actions/licenses/licensesActionConstants";
import createReducer from "../../utils/createReducer";

const initialState = {
  licenses: {
    loaded: false,
    data: [],
  },
  totalLicenses: 0,
  singleLicense: {},
};

export default createReducer(
  {
    [SET_LICENSES]: setLicenses,
    [SINGLE_LICENSE_SET]: setSingleLicense,
    [SET_TOTAL_LICENSES]: setTotalLicenses,
    [CLEAR_LICENSES]: clearLicenses,
  },
  initialState
);
function setLicenses(state, { payload }) {
  return {
    ...state,
    licenses: payload,
  };
}
function setTotalLicenses(state, { payload }) {
  return {
    ...state,
    totalLicenses: payload,
  };
}
function clearLicenses() {
  return initialState;
}
function setSingleLicense(state, { payload }) {
  return {
    ...state,
    singleLicense: payload,
  };
}
