import React from "react";
import {
  COMPANIES_EDIT_PAGE,
  COMPANIES_NEW_PAGE,
  COMPANIES_PAGE,
  EDIT_COMPANY_USER,
  NEW_COMPANY_USER,
} from "../../constants/pages";
import { Switch } from "react-router";
import CompaniesIndexPage from "./CompaniesIndexPage/CompaniesIndexPage";
import SuperAdminRoute from "../../components/Router/SuperAdminRoute";
import CompaniesNewPage from "./CompaniesNewPage/CompaniesNewPage";
import CompaniesEditPage from "./CompaniesEditPage/CompaniesEditPage";
import UserCompaniesNewPage from "./UserCompaniesNewPage/UserCompaniesNewPage";
import CompaniesEditUserPage from "./CompaniesEditUserPage/CompaniesEditUserPage";
// import AuthRoute from "../../components/Router/AuthRoute";

const CompaniesPage = () => {
  return (
    <Switch>
      <SuperAdminRoute
        path={NEW_COMPANY_USER}
        component={UserCompaniesNewPage}
      />
      <SuperAdminRoute
        path={EDIT_COMPANY_USER}
        component={CompaniesEditUserPage}
      />
      <SuperAdminRoute path={COMPANIES_NEW_PAGE} component={CompaniesNewPage} />
      <SuperAdminRoute
        exact
        path={COMPANIES_PAGE}
        component={CompaniesIndexPage}
      />
      <SuperAdminRoute
        path={COMPANIES_EDIT_PAGE}
        component={CompaniesEditPage}
      />
    </Switch>
  );
};

export default CompaniesPage;
