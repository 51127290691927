import React from "react";
import PropTypes from "prop-types";
import { FindingSeparatorLine } from "./FindingSeparator.styled";

const FindingSeparator = (props) => {
  let propsObject = { ...props };
  if (propsObject?.full) delete propsObject.full;
  return <FindingSeparatorLine {...propsObject} $full={props?.full} />;
};

FindingSeparator.propTypes = {
  full: PropTypes.bool,
};

export default FindingSeparator;
