import { Box } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../themes";

export const FindingSeparatorLine = styled(Box)`
  width: ${(props) => (props?.$full ? "100%" : "calc(100% - 64px)")};
  height: 1px;
  align-self: center;
  margin: 0;
  /* margin-bottom: ${(props) => (props?.full ? "0" : "32px")}; */
  background-color: ${(props) =>
    props?.$full
      ? selectedTheme.colors.orangeBorder
      : selectedTheme.colors.inputBorder};
`;
