import React from "react";
import PropTypes from "prop-types";
import TableColumns from "./TableColumns/TableColumns";
import {
  MainTableContainer,
  StickyTableContainer,
  TableContentContainer,
} from "./TableContent.styled";
import TableData from "./TableData/TableData";
import { ToastContainer } from "react-toastify";
import { TABLE_TOAST_CONTAINER_ID } from "../../../constants/toastConstants";
import { selectIsSidebarOpened } from "../../../store/selectors/sidebarSelector";
import { useSelector } from "react-redux";
import { useState } from "react";

const TableContent = (props) => {
  const isSidebarOpened = useSelector(selectIsSidebarOpened);
  const [hoveredItem, setHoveredItem] = useState(-1);
  return (
    <TableContentContainer $isSticky={props?.stickyActions && !props?.isMobile}>
      <ToastContainer
        enableMultiContainer
        containerId={TABLE_TOAST_CONTAINER_ID}
      />
      <MainTableContainer
        $isSticky={props?.stickyActions && !props?.isMobile}
        $isSidebarOpened={isSidebarOpened}
      >
        <TableColumns
          columns={
            props?.stickyActions && !props?.isMobile
              ? props?.tableColumns?.filter?.(
                  (item) => item?.propertyName !== "actions"
                )
              : props?.tableColumns
          }
          onChangeSorting={props?.onChangeSorting}
          sorting={props?.sorting}
        />
        <TableData
          clickable={props?.clickable}
          hoveredItem={hoveredItem}
          setHoveredItem={setHoveredItem}
          onRowClick={props?.onRowClick}
          selectFirstRow={props?.selectFirstRow}
          data={props?.tableData}
          columns={
            props?.stickyActions && !props?.isMobile
              ? props?.tableColumns?.filter?.(
                  (item) => item?.propertyName !== "actions"
                )
              : props?.tableColumns
          }
          redirectHref={props?.redirectHref}
          redirectFunction={props?.redirectFunction}
          redirectHrefFunction={props?.redirectHrefFunction}
          smallerTextForMobile={props?.smallerTextForMobile}
          stickyActions={props?.stickyActions && !props?.isMobile}
        />
      </MainTableContainer>
      {props?.stickyActions && !props?.isMobile && (
        <StickyTableContainer>
          <TableColumns
            columns={[
              props?.tableColumns?.find?.(
                (item) => item?.propertyName === "actions"
              ),
            ]}
            onChangeSorting={props?.onChangeSorting}
            sorting={props?.sorting}
          />
          <TableData
            clickable={props?.clickable}
            hoveredItem={hoveredItem}
            setHoveredItem={setHoveredItem}
            onRowClick={props?.onRowClick}
            selectFirstRow={props?.selectFirstRow}
            data={props?.tableData}
            columns={[
              props?.tableColumns?.find?.(
                (item) => item?.propertyName === "actions"
              ),
            ]}
            redirectFunction={props?.redirectFunction}
            smallerTextForMobile={props?.smallerTextForMobile}
            stickyTable
          />
        </StickyTableContainer>
      )}
    </TableContentContainer>
  );
};

TableContent.propTypes = {
  children: PropTypes.node,
  tableColumns: PropTypes.array,
  tableData: PropTypes.array,
  onRowClick: PropTypes.func,
  onChangeSorting: PropTypes.func,
  sorting: PropTypes.object,
  smallerTextForMobile: PropTypes.bool,
  redirectFunction: PropTypes.func,
  redirectHrefFunction: PropTypes.func,
  stickyActions: PropTypes.bool,
  clickable: PropTypes.bool,
  isMobile: PropTypes.bool,
  redirectHref: PropTypes.string,
  selectFirstRow: PropTypes.bool
};

TableContent.defaultProps = {
  stickyActions: false,
};

export default TableContent;
