import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import {
  DOCUMENT_TYPES_PAGE,
  EDIT_FINDING_TYPE_PAGE,
  FINDING_TYPES_PAGE,
  MEASURING_INSTRUMENTS_PAGE,
  NEW_FINDING_TYPE_PAGE,
  SCANNING_OBJECTS_PAGE,
  STANDARDS_PAGE,
} from "../../constants/pages";
// import SidebarLayout from "../../layouts/SidebarLayout/SidebarLayout";
import MeasuringInstrumentsPage from "./MeasuringInstrumentsPage/MeasuringInstrumentsPage";
import ScanningObjects from "./ScanningObjects/ScanningObjects";
import StandardsPage from "./StandardsPage/StandardsPage";
import FindingTypesPage from "./FindingTypesPage/FindingTypesPage";
import DocumentTypesPage from "./DocumentTypesPage/DocumentTypesPage";
import AddFindingTypePage from "./FindingTypesPage/AddFindingTypePage/AddFindingTypePage";
import EditFindingTypePage from "./FindingTypesPage/EditFindingTypePage/EditFindingTypePage";

const ResearchPage = () => {
  return (
    <Switch>
      <Route exact path={FINDING_TYPES_PAGE} component={FindingTypesPage} />
      <Route exact path={STANDARDS_PAGE} component={StandardsPage} />
      <Route
        exact
        path={MEASURING_INSTRUMENTS_PAGE}
        component={MeasuringInstrumentsPage}
      />
      <Route path={SCANNING_OBJECTS_PAGE} component={ScanningObjects} />
      <Route path={DOCUMENT_TYPES_PAGE} component={DocumentTypesPage} />
      <Route exact path={NEW_FINDING_TYPE_PAGE} component={AddFindingTypePage} />
      <Route path={EDIT_FINDING_TYPE_PAGE} component={EditFindingTypePage} />
      <Redirect to={FINDING_TYPES_PAGE} />
    </Switch>
  );
};

export default ResearchPage;
