import { Typography } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../themes";

export const TitleTypography = styled(Typography)`
  font-family: ${selectedTheme.fonts.mainText};
  font-weight: 600;
  font-size: 30px;
  line-height: 36px;
  color: ${selectedTheme.colors.pageTitleColor};
  @media (max-width: 600px) {
    font-size: 18px;
    line-height: 24px;
  }
`;
