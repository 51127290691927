import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { AddButton, EntityInputsContainer } from "./EntityPicker.styled";
import { useMemo } from "react";
import AutocompleteField from "../../Field/AutocompleteField/AutocompleteField";

const EntityPicker = (props) => {
  const [selectedEntity, setSelectedEntity] = useState(null);
  const entityData = useSelector(props?.entityDataSelector);
  const dispatch = useDispatch();

  const handleAddEntity = () => {
    if (selectedEntity) {
      props?.handleAddEntity(selectedEntity);
      setSelectedEntity(null);
    }
  };
  const availableOptions = useMemo(() => {
    let dataToReturn = entityData;
    if (!props?.availableMultipleEntities) {
      dataToReturn = dataToReturn?.filter?.(
        (singleEntityData) =>
          props?.data?.find(
            (singleEntityDataToFind) =>
              singleEntityDataToFind?.id === singleEntityData?.id
          ) === undefined
      );
    }
    return dataToReturn;
  });

  useEffect(() => {
    if (!props?.fetchedData) {
      dispatch(
        props?.fetchEntitiesDispatchFunction({
          page: 1,
          size: 200,
        })
      );
    }
    setSelectedEntity(null);
  }, []);

  return (
    <EntityInputsContainer>
      <AutocompleteField
        options={availableOptions}
        label={props?.selectPlaceholder}
        onChange={(value) => setSelectedEntity(value)}
        dropdownLabel={props?.dropdownLabel}
        value={selectedEntity}
      />
      <AddButton onClick={handleAddEntity} />
    </EntityInputsContainer>
  );
};

EntityPicker.propTypes = {
  handleAddEntity: PropTypes.func,
  fetchEntitiesDispatchFunction: PropTypes.func,
  fetchedData: PropTypes.bool,
  entityDataSelector: PropTypes.any,
  selectPlaceholder: PropTypes.string,
  namePropertyOfEntity: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]),
  data: PropTypes.array,
  availableMultipleEntities: PropTypes.bool,
  dropdownLabel: PropTypes.array,
};

EntityPicker.defaultProps = {
  data: [],
};

export default EntityPicker;
