import { createSelector } from "reselect";

const documentTypesSelector = (state) => state.documentTypes;

export const selectDocumentTypes = createSelector(
  documentTypesSelector,
  (state) => state.documentTypes
);
export const selectTotalDocumentTypes = createSelector(
  documentTypesSelector,
  (state) => state.totalDocumentTypes
);
export const selectSingleDocumentType = createSelector(
  documentTypesSelector,
  (state) => state.singleDocumentType
);
export const selectDocumentTypesData = createSelector(
  documentTypesSelector,
  (state) => state.documentTypes.data
);
