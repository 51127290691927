import styled, { css } from "styled-components";
import { Box, TextareaAutosize, Typography } from "@mui/material";
import selectedTheme from "../../../themes";

export const AddFindingStaffModalContainer = styled(Box)`
  padding: 32px;
  position: relative;
  background: white;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow: hidden;
  z-index: ${(props) => "15" + props?.rank || "151"};
  @media (max-width: 600px) {
    width: 100%;
  }
`;

export const InputsContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 12px;
  & * {
    text-align: left;
  }
`;

export const AutocompleteFieldLabel = styled(Box)`
  font-family: ${selectedTheme.fonts.mainText};
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: ${selectedTheme.colors.deviceColor};
  margin-top: 12px;
  ${(props) =>
    props?.$required &&
    css`
      &::after {
        content: " *" !important;
        color: ${selectedTheme.colors.errorColor} !important;
      }
    `}
`;

export const TextareaLabel = styled(Typography)`
  font-family: ${selectedTheme.fonts.mainText};
  font-style: normal;
  color: ${selectedTheme.colors.gray};
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  ${(props) =>
    props?.$required &&
    css`
      &::after {
        content: " *" !important;
        color: ${selectedTheme.colors.errorColor} !important;
      }
    `}
`;
export const TextareaInput = styled(TextareaAutosize)`
  width: 412px;
  border-radius: 6px;
  border: 1px solid ${selectedTheme.colors.inputBorder};
  font-family: ${selectedTheme.fonts.mainText};
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  font-feature-settings: "salt" on;
  resize: vertical;
  padding: 8px 12px;
  ${(props) =>
    props?.disabled &&
    css`
      background-color: ${selectedTheme.colors.disabledFieldBackground};
      color: ${selectedTheme.colors.disabledFieldText};
    `}
  @media (max-width: 600px) {
    width: 100%;
  }
`;

export const ErrorMessage = styled(Typography)`
  font-family: ${selectedTheme.fonts.mainText};
  font-size: 12px;
  font-weight: 400;
  color: ${selectedTheme.colors.errorColor};
`;
