import { createSelector } from "reselect";

const stuffsSelector = (state) => state.stuffs;

export const selectStuffs = createSelector(
  stuffsSelector,
  (state) => state.stuffs
);
export const selectTotalStuffs = createSelector(
  stuffsSelector,
  (state) => state.totalStuffs
);
export const selectSingleStuff = createSelector(
  stuffsSelector,
  (state) => state.singleStuff
);
export const selectStuffsData = createSelector(
  stuffsSelector,
  (state) => state.stuffs.data
);
