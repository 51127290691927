import { Box, Typography } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../../../themes";
import ClipboardIcon from "../../../../Icon/Icons/ClipboardIcon";

export const EmailsListItemContainer = styled(Box)`
  display: flex;
  gap: 16px;
  height: 22px;
`;
export const EmailsListItemText = styled(Typography)`
  font-family: "Inter";
  font-size: 14px;
  line-height: 20px;
  padding-left: 16px;
  color: ${selectedTheme.colors.distributionEmailsMail};
`;
export const Clipboard = styled(ClipboardIcon)`
  cursor: pointer;
`;
