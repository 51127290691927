import React from "react";
import PropTypes from "prop-types";
import {
  EmptyMeasuringInstrumentsTableContainer,
  EmptyMeasuringInstrumentsText,
} from "./EmptyMeasuringInstrumentsTable.styled";
import { useTranslation } from "react-i18next";

const EmptyMeasuringInstrumentsTable = () => {
  const { t } = useTranslation();
  return (
    <EmptyMeasuringInstrumentsTableContainer>
      <EmptyMeasuringInstrumentsText>
        {t(
          "scanningObjects.newScanningObject.templateEditor.noInstrumentsText"
        )}
      </EmptyMeasuringInstrumentsText>
    </EmptyMeasuringInstrumentsTableContainer>
  );
};

EmptyMeasuringInstrumentsTable.propTypes = {
  children: PropTypes.node,
};

export default EmptyMeasuringInstrumentsTable;
