import axios from "axios";
import jwt from "jsonwebtoken";
import { JWT_REFRESH_TOKEN, JWT_TOKEN } from "../../constants/localStorage";
import {
  attachBeforeRequestListener,
  // addHeaderToken,
  // attachPostRequestListener,
} from "../../request/index";
import {
  authScopeSetHelper,
  authScopeStringGetHelper,
  // authScopeSetHelper,
} from "../../util/helpers/authScopeHelpers";
import { logoutUser } from "../actions/login/loginActions";
// import { FETCH } from "../actions/actionHelpers";
// import { setUserAccessToken } from "../actions/user/userActions";

//Change URL with .env
// const baseURL = "http://192.168.88.143:3001/"; // DULE
// const baseURL = "http://192.168.88.175:3005/";
// const baseURL = "https://trampa-api.dilig.net/";
// const baseURL = "https://trampa-api-test.dilig.net/";
// const baseURL = "http://192.168.88.150:3001/"; // DJOLE
// const baseURL = "http://localhost:26081/";
//const baseURL = "http://localhost:5116/";
// const baseURL = "https://api-equipment-checker-test.dilig.net/";
 const baseURL = process.env.REACT_APP_BASE_API_URL;

let getRefreshToken = (jwtToken, refresh) =>
  axios.post(
    `${baseURL}v1/Users/authenticate/refresh`,
    {
      refreshToken: refresh,
    },
    {
      headers: {
        Authorization: `Bearer ${jwtToken}`,
      },
    }
  );

let refreshTokenPromise;

//Interceptor unique name
export const accessTokensMiddlewareInterceptorName = "ACCESS_TOKEN_INTERCEPTOR";

export default ({ dispatch }) =>
  (next) =>
  (action) => {
    attachBeforeRequestListener((response) => {
      const jwtToken = authScopeStringGetHelper(JWT_TOKEN);
      const refresh = authScopeStringGetHelper(JWT_REFRESH_TOKEN);
      const jwtTokenDecoded = jwt.decode(jwtToken);
      if (!jwtToken || !refresh) return Promise.resolve(response);
      if (response?.params?.noAuth) {
        delete response?.params?.noAuth;
        return Promise.resolve(response);
      }
      if (new Date() <= new Date(jwtTokenDecoded.exp * 1000)) {
        response.headers.Authorization = `Bearer ${jwtToken}`;
      }
      if (new Date() > new Date(jwtTokenDecoded.exp * 1000)) {
        try {
          if (!refreshTokenPromise) {
            refreshTokenPromise = getRefreshToken(jwtToken, refresh).then(
              (token) => {
                refreshTokenPromise = null;
                return token;
              }
            );
          }

          return refreshTokenPromise
            .then((res) => {
              const newToken = res.data;
              authScopeSetHelper(JWT_TOKEN, newToken?.token);
              authScopeSetHelper(JWT_REFRESH_TOKEN, newToken?.refreshToken);
              //dispatch(refreshUserToken(newToken));
              response.headers.Authorization = `Bearer ${newToken?.token}`;
              refreshTokenPromise = null;
              return Promise.resolve(response);
            })
            .catch((e) => {
              refreshTokenPromise = null;
              dispatch(logoutUser());
              return Promise.reject(e);
            });
        } catch (e) {
          refreshTokenPromise = null;
          dispatch(logoutUser());
          return Promise.reject(e);
        }
      }
      return Promise.resolve(response);
    });
    next(action);
  };
