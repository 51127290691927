// import { useMemo } from "react";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import {
  ADMIN_ROLE,
  GUEST_ROLE,
  MANAGER_ROLE,
  OPERATOR_ROLE,
  SUPERADMIN_ROLE,
} from "../constants/rolesConstants";
import { selectJWTToken, selectRoles } from "../store/selectors/loginSelectors";

const useAuth = () => {
  const jwtToken = useSelector(selectJWTToken);
  const roles = useSelector(selectRoles);

  const isLoggedIn = useMemo(() => {
    if (jwtToken.jwtToken?.token) return true;
    return false;
  }, [jwtToken]);

  const isAdmin = useMemo(() => {
    if (roles?.includes(ADMIN_ROLE)) return true;
    return false;
  }, [roles]);

  const isSuperAdmin = useMemo(() => {
    if (roles?.includes(SUPERADMIN_ROLE)) return true;
    return false;
  }, [roles]);

  const isOperator = useMemo(() => {
    if (roles?.includes(OPERATOR_ROLE)) return true;
    return false;
  }, [roles]);

  const isManager = useMemo(() => {
    if (roles?.includes(MANAGER_ROLE)) return true;
    return false;
  }, [roles]);

  const isGuest = useMemo(() => {
    if (roles?.includes(GUEST_ROLE)) return true;
    return false;
  }, [roles]);

  const hasRole = (roleName) => roles?.includes(roleName);

  const isLoaded = useMemo(() => {
    if (isLoggedIn !== undefined) return true;
    return false;
  }, [isLoggedIn]);

  return {
    isLoggedIn,
    isLoaded,
    isAdmin,
    isOperator,
    isManager,
    isGuest,
    isSuperAdmin,
    hasRole,
    roles,
  };
};

export default useAuth;
