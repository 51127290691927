import { Box } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../../themes";

export const StandardsInputsLabel = styled(Box)`
  font-family: ${selectedTheme.fonts.mainText};
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: ${selectedTheme.colors.pageTitleColor};
  margin-bottom: 8px;
  margin-top: 12px;
`;
export const AddStandardsContainer = styled(Box)`
  margin-top: 32px;
`;
