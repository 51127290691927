import React from "react";
import PropTypes from "prop-types";
import {
  SchemaImagePickersContainer,
  SchemaImagePickersListContainer,
  SchemaSingleImagePicker,
} from "./FindingSchema.styled";
import { useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import { statusTableConstants } from "../../../../constants/findingStatusConstants";
import { useDispatch, useSelector } from "react-redux";
import {
  setFindingsChanged,
  setSingleFindingSchemas,
} from "../../../../store/actions/findings/findingsActions";
import { selectSingleFindingSchemas } from "../../../../store/selectors/findingsSelectors";
import { addCRUDInfo } from "../../../../store/utils/deleteCRUDInfo";
import { selectMyProfileId } from "../../../../store/selectors/loginSelectors";

const FindingSchema = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const workspaceSchemas = useSelector(selectSingleFindingSchemas);
  const userId = useSelector(selectMyProfileId);

  const hasImages = useMemo(
    () =>
      workspaceSchemas?.filter?.((singleSchema) => !singleSchema?.removed)
        ?.length !== 0,
    [workspaceSchemas]
  );

  const handleChangeSchemaImage = (newImage, indexOfImage) => {
    let newSchemas = [...workspaceSchemas];
    if (newImage !== "") {
      newSchemas[indexOfImage] = {
        ...newSchemas[indexOfImage],
        edited: false,
        value: {
          ...newSchemas[indexOfImage]?.value,
          imageUrl: {
            edited: true,
            value: newImage,
          },
        },
      };
    } else {
      newSchemas?.splice?.(indexOfImage, 1, {
        ...newSchemas[indexOfImage],
        removed: true,
      });
    }
    dispatch(setSingleFindingSchemas(newSchemas));
    dispatch(setFindingsChanged(true));
  };
  const handleAddSchemaImage = (newImage) => {
    let newSchemas = [...workspaceSchemas];
    newSchemas?.push?.({
      added: true,
      value: addCRUDInfo(
        {
          imageUrl: {
            edited: false,
            value: newImage,
          },
          orderNumber: newSchemas?.filter(
            (singleSchema) => !singleSchema?.removed
          )?.length,
          // createdByUserId: { edited: false, value: parseInt(userId, 10) },
          // updatedByUserId: { edited: false, value: parseInt(userId, 10) },
        },
        userId
      ),
    });
    dispatch(setSingleFindingSchemas(newSchemas));
    dispatch(setFindingsChanged(true));
  };

  return (
    <SchemaImagePickersContainer>
      <SchemaImagePickersListContainer $hasBorders={hasImages}>
        {workspaceSchemas?.map((singleSchemaImage, index) => {
          if (singleSchemaImage?.value?.imageUrl?.value)
            return singleSchemaImage?.removed ? (
              <></>
            ) : (
              <SchemaSingleImagePicker
                key={index}
                smallerButtons
                width={295}
                height={170}
                minWidth={295}
                minHeight={170}
                $hasImages={hasImages}
                disabled={props?.stateId !== statusTableConstants.inProgress.id}
                image={singleSchemaImage?.value?.imageUrl?.value}
                setImage={(newImage) =>
                  handleChangeSchemaImage(newImage, index)
                }
                modalTitle={t("findings.imagePicker.modalTitle")}
              />
            );
        })}
        <SchemaSingleImagePicker
          $hasImages={hasImages}
          console
          key={workspaceSchemas?.length}
          smallerButtons
          setImage={handleAddSchemaImage}
          width={295}
          height={170}
          minWidth={295}
          minHeight={170}
          disabled={props?.stateId !== statusTableConstants.inProgress.id}
          additionalDescriptionText={hasImages && t("imagePicker.moreImages")}
          descriptionText={<Trans i18nKey="findings.imagePicker.addSchema" />}
        />
      </SchemaImagePickersListContainer>
    </SchemaImagePickersContainer>
  );
};

FindingSchema.propTypes = {
  stateId: PropTypes.number,
  settingsFormik: PropTypes.func,
};

export default FindingSchema;
