import React from "react";
import { Route, Switch } from "react-router-dom";
import StuffContent from "../../components/Stuffs/StuffsContent";
// import SidebarNavigation from "../../components/SidebarNavigation/SidebarNavigation";
import {
  EDIT_STUFF_PAGE,
  NEW_STUFF_PAGE,
  STUFFS_PAGE,
} from "../../constants/pages";
// import SidebarLayout from "../../layouts/SidebarLayout/SidebarLayout";
import NewStuffPage from "./NewStuffPage/NewStuffPage";
import EditStuffPage from "./EditStuffPage/EditStuffPage";
import AuthRoute from "../../components/Router/AuthRoute";
import { MANAGER_ROLE, OPERATOR_ROLE } from "../../constants/rolesConstants";

const StuffsPage = () => {
  return (
    <Switch>
      <Route exact path={STUFFS_PAGE} component={StuffContent} />
      <AuthRoute
        roles={[MANAGER_ROLE]}
        exact
        path={NEW_STUFF_PAGE}
        component={NewStuffPage}
      />
      <AuthRoute
        roles={[MANAGER_ROLE, OPERATOR_ROLE]}
        exact
        path={EDIT_STUFF_PAGE}
        component={EditStuffPage}
      />
    </Switch>
  );
};

export default StuffsPage;
