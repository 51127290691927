import React, { useCallback, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import Table from "../../Table/Table";
import { useTranslation } from "react-i18next";
import { formatDateTime } from "../../../util/helpers/dateHelpers";
import useAuth from "../../../hooks/useAuth";
import TextRenderer from "../../Table/Renderers/TextRenderer/TextRenderer";
import { replaceInRoute } from "../../../util/helpers/routeHelpers";
import {
  COMPANIES_EDIT_PAGE,
  COMPANIES_NEW_PAGE,
} from "../../../constants/pages";
import companiesTableConstants from "../../../constants/tableConstants/companiesTableConstants";
import {
  selectCompaniesData,
  selectTotalCompanies,
} from "../../../store/selectors/companiesSelector";
import {
  clearCompanies,
  fetchCompanies,
} from "../../../store/actions/companies/companiesActions";
import TabChooser from "../../TabChooser/TabChooser";
import companiesTabConstants from "../../../constants/companiesTabConstants";
import { GET_COMPANIES_SCOPE } from "../../../store/actions/companies/companiesActionConstants";

const CompaniesTable = () => {
  const tableRef = useRef();
  const { t } = useTranslation();
  const { hasRole } = useAuth();
  const [selectedTab, setSelectedTab] = useState(companiesTabConstants[0]?.id);

  const handleRedirect = (companyId) =>
    replaceInRoute(COMPANIES_EDIT_PAGE, {
      companyId,
    });

  const refetch = useCallback(() => {
    if (tableRef?.current) {
      tableRef?.current?.forceRefetch();
    }
  }, []);

  const tableColumns = useMemo(() => {
    let tableConstants = companiesTableConstants(
      selectedTab === companiesTabConstants[0]?.id
    );
    let columnsObject = Object.keys(tableConstants).map((property) => ({
      ...tableConstants[property],
      field: t(tableConstants[property].i18key),
      propertyName: property,
      backendProperty: tableConstants[property].backendProperty || property,
      style: tableConstants[property]?.style,
      renderer: tableConstants[property]?.renderer || TextRenderer,
      rendererProps: {
        refetch,
      },
    }));
    return columnsObject;
  }, [companiesTableConstants, t, hasRole, selectedTab]);

  const mapDataFunction = useCallback(
    (newData) => {
      let isActive = selectedTab === companiesTabConstants[0]?.id;
      return newData?.map?.((singleData) => {
        let mappedDataObject = {
          name: singleData?.name,
          pib: singleData?.tin,
          idNumber: singleData?.identificationNumber,
          address: singleData?.address,
          email: singleData?.email,
          lastChanges: {
            date: formatDateTime(
              (isActive
                ? singleData?.updatedAtUtc
                : singleData?.deactivatedAtUtc) || new Date().toString()
            ),
            author: `${singleData?.updatedByUser?.firstName || ""} ${
              singleData?.updatedByUser?.lastName || ""
            }`,
          },
          id: singleData?.id,
          actions: {
            companyId: singleData?.id,
            name: singleData?.name,
            tin: singleData?.tin,
            idNumber: singleData?.idNumber,
            address: singleData?.address,
            email: singleData?.email,
            deactivatedAtUtc: singleData?.deactivatedAtUtc,
            lastChanges: {
              date: formatDateTime(
                singleData?.updatedAtUtc || new Date().toString()
              ),
              author: `${singleData?.updatedByUser?.firstName || ""} ${
                singleData?.updatedByUser?.lastName || ""
              }`,
            },
            t,
            tableRef,
          },
        };
        return mappedDataObject;
      });
    },
    [hasRole, selectedTab]
  );

  const customPayload = useMemo(() => {
    return { isActive: selectedTab === companiesTabConstants[0]?.id };
  }, [selectedTab]);

  return (
    <>
      <TabChooser
        tabs={companiesTabConstants}
        selectedTab={selectedTab}
        handleChangeTab={setSelectedTab}
      />
      <Table
        sortDesc
        tableColumns={tableColumns}
        searchPlaceholder={t("companies.table.searchPlaceholder")}
        dataSelector={selectCompaniesData}
        totalDataSelector={selectTotalCompanies}
        dispatchFunction={fetchCompanies}
        mapDataFunction={mapDataFunction}
        loadingActionType={GET_COMPANIES_SCOPE}
        redirectHrefFunction={handleRedirect}
        clearDispatchFunction={clearCompanies}
        customPayload={customPayload}
        hideAddButton={selectedTab !== companiesTabConstants[0]?.id}
        isStatic
        ref={tableRef}
        addButtonHref={{
          pathname: COMPANIES_NEW_PAGE,
        }}
        stickyActions
      />
    </>
  );
};

CompaniesTable.propTypes = {
  children: PropTypes.node,
  isLoading: PropTypes.bool,
};

export default CompaniesTable;
