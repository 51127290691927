import { Box, Typography } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../themes";
import ExportIcon2 from "../../Icon/Icons/ExportIcon2";
import Link from "../../Link/Link";

export const TableTitle = styled(Typography)`
  font-family: ${selectedTheme.fonts.mainText};
  color: ${selectedTheme.colors.pageTitleColor};
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
`;

export const TableTitleContainer = styled(Box)`
  display: flex;
  margin-bottom: 10px;
  justify-content: space-between;
  align-items: center;
`;

export const RedirectIcon = styled(ExportIcon2)`
  cursor: pointer;
  min-width: 20px;
`;
export const RedirectIconContainer = styled(Link)``