import React from "react";
// import PropTypes from 'prop-types'
import { ReactComponent as JustifyIconSvg } from "../../../assets/images/lexical/justify.svg";

const JustifyIcon = (props) => {
  return <JustifyIconSvg {...props} />;
};

JustifyIcon.propTypes = {};

export default JustifyIcon;
