import { createSelector } from "reselect";

const scanningObjectsSelector = (state) => state.scanningObjects;

export const selectScanningObjects = createSelector(
  scanningObjectsSelector,
  (state) => state.scanningObjects
);
export const selectTotalScanningObjects = createSelector(
  scanningObjectsSelector,
  (state) => state.totalScanningObjects
);
export const selectSingleScanningObject = createSelector(
  scanningObjectsSelector,
  (state) => state.singleScanningObject
);
export const selectChecklistTemplates = createSelector(
  scanningObjectsSelector,
  (state) => state.singleScanningObject.checklistTemplates
);
export const selectSelectedChecklistTemplate = createSelector(
  scanningObjectsSelector,
  (state) =>
    state.singleScanningObject.checklistTemplates.find(
      (singleTemplate) => singleTemplate?.isDefault
    )
);
export const selectChecklistTemplate = (templateId) =>
  createSelector(scanningObjectsSelector, (state) =>
    state.singleScanningObject.checklistTemplates.find(
      (singleTemplate) => singleTemplate?.id === templateId
    )
  );
export const selectChecklistTemplateSections = createSelector(
  scanningObjectsSelector,
  (state) =>
    state.singleScanningObject.checklistTemplates.find(
      (singleTemplate) => singleTemplate?.isDefault
    )?.sections
);
export const selectChecklistTemplatePositiveConclusion = createSelector(
  scanningObjectsSelector,
  (state) =>
    state.singleScanningObject.checklistTemplates.find(
      (singleTemplate) => singleTemplate?.isDefault
    )?.positiveConclusionTemplate
);
export const selectChecklistTemplateNegativeConclusion = createSelector(
  scanningObjectsSelector,
  (state) =>
    state.singleScanningObject.checklistTemplates.find(
      (singleTemplate) => singleTemplate?.isDefault
    )?.negativeConclusionTemplate
);
export const selectSingleScanningObjectSection = (sectionId) =>
  createSelector(scanningObjectsSelector, (state) =>
    state.singleScanningObject.checklistTemplates
      .find((singleTemplate) => singleTemplate?.isDefault)
      ?.sections?.find((singleSection) => singleSection?.id === sectionId)
  );
export const selectSingleScanningObjectSectionQuestions = (sectionId) =>
  createSelector(
    scanningObjectsSelector,
    (state) =>
      state.singleScanningObject.checklistTemplates
        .find((singleTemplate) => singleTemplate?.isDefault)
        ?.sections?.find((singleSection) => singleSection?.id === sectionId)
        ?.questions
  );
export const selectSingleScanningObjectSectionSingleQuestion = (
  sectionId,
  questionId
) =>
  createSelector(scanningObjectsSelector, (state) =>
    state.singleScanningObject.checklistTemplates
      .find((singleTemplate) => singleTemplate?.isDefault)
      ?.sections?.find((singleSection) => singleSection?.id === sectionId)
      ?.questions?.find((singleItem) => singleItem?.id === questionId)
  );

export const selectScanningObjectsData = createSelector(
  scanningObjectsSelector,
  (state) => state.scanningObjects.data
);

export const selectScanningObjectType = createSelector(
  scanningObjectsSelector,
  state => state.singleScanningObject.findingType
)

export const selectActiveScanningSubjects = createSelector(
  scanningObjectsSelector,
  (state) =>
    state.scanningObjects.data?.filter((singleSubject) =>
      singleSubject?.checklistTemplates?.some(
        (singleTemplate) => singleTemplate?.isDefault
      )
    )
);
export const selectSingleScanningObjectAllSections = createSelector(
  scanningObjectsSelector,
  (state) =>
    state.singleScanningObject.checklistTemplates.find(
      (singleTemplate) => singleTemplate?.isDefault
    )?.sections
);
export const selectSingleScanningObjectMeasuringInstruments = createSelector(
  scanningObjectsSelector,
  (state) =>
    state.singleScanningObject.checklistTemplates.find(
      (singleTemplate) => singleTemplate?.isDefault
    )?.measuringInstruments
);
export const selectSingleScanningObjectStandards = createSelector(
  scanningObjectsSelector,
  (state) =>
    state.singleScanningObject.checklistTemplates.find(
      (singleTemplate) => singleTemplate?.isDefault
    )?.standards
);
export const selectIsScanningObjectChanged = createSelector(
  scanningObjectsSelector,
  (state) => state?.scanningObjectsChanged
);
