import i18n from "../../i18n";

export const rejectErrorCodeHelper = (error) => {
  if (error?.response?.data?.Errors) {
    const errorCode = error?.response?.status;
    const errorMessage = errorCode
      ? i18n.t(`apiErrors.${errorCode}`)
      : i18n.t('apiErrors.SomethingWentWrong');

    return errorMessage;
  }

  return i18n.t('apiErrors.SomethingWentWrong');
};
