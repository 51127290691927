import React, { useCallback, useMemo, useRef } from "react";
import PropTypes from "prop-types";
import Table from "../../Table/Table";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { formatDateTime, formatDate } from "../../../util/helpers/dateHelpers";
import useAuth from "../../../hooks/useAuth";
import { MANAGER_ROLE, OPERATOR_ROLE } from "../../../constants/rolesConstants";
import {
  FINDINGS_EDIT_PAGE,
  FINDINGS_NEW_PAGE,
} from "../../../constants/pages";
import findingsTableConstants from "../../../constants/tableConstants/findingsTableConstants";
import {
  selectFindingsData,
  selectTotalFindings,
} from "../../../store/selectors/findingsSelectors";
import {
  clearFindings,
  fetchFindings,
} from "../../../store/actions/findings/findingsActions";
import TextRenderer from "../../Table/Renderers/TextRenderer/TextRenderer";
import { replaceInRoute } from "../../../util/helpers/routeHelpers";
import FindingsFilterComponent from "./FilterComponents/FindingsFilterComponent";
import { useState } from "react";
import DropdownFilterComponent from "./FilterComponents/DropdownFilterComponent/DropdownFilterComponent";
import { useDispatch, useSelector } from "react-redux";
import { selectClientsData } from "../../../store/selectors/clientsSelector";
import { selectStuffsData } from "../../../store/selectors/stuffsSelector";
import PickerFilterComponent from "./FilterComponents/PickerFilterComponent/PickerFilterComponent";
import FindingBreadIcon from "../../Icon/Icons/FindingBreadIcon";
import SearchFilterComponent from "./FilterComponents/SearchFilterComponent/SearchFilterComponent";
import { statusTableConstantsAsArray } from "../../../constants/findingStatusConstants";
import {
  closeModal,
  toggleSetFindingTypesModal,
} from "../../../store/actions/modal/modalActions";
import { selectFindingTypesData } from "../../../store/selectors/findingTypesSelectors";
import { selectScanningObjectsData } from "../../../store/selectors/scanningObjectsSelectors";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

const FindingsTable = () => {
  const history = useHistory();
  const location = useLocation();
  const tableRef = useRef();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [startingDate, setStartingDate] = useState("");
  const [endingDate, setEndingDate] = useState("");
  const clients = useSelector(selectClientsData);
  const stuffs = useSelector(selectStuffsData);
  const scanningSubjects = useSelector(selectScanningObjectsData);
  const findingTypes = useSelector(selectFindingTypesData);
  const { hasRole } = useAuth();
  const [selectedClient, setSelectedClient] = useState(null);
  const [selectedStuff, setSelectedStuff] = useState(null);
  const [selectedScanningSubject, setSelectedScanningSubject] = useState(null);
  const [selectedFindingType, setSelectedFindingType] = useState(null);
  const [selectedCheckbox, setSelectedCheckbox] = useState({
    isSelectAll: false,
    selected: [],
  });
  const [searchNumber, setSearchNumber] = useState({
    value: null,
    isValid: false,
  });
  const [keys, setKeys] = useState(5);

  useEffect(() => {
    setStartingDate(
      history?.location?.state?.filterPayload?.date?.startingDate || ""
    );
    setEndingDate(
      history?.location?.state?.filterPayload?.date?.endingDate || ""
    );
    setSelectedClient(history?.location?.state?.filterPayload?.client);
    setSelectedStuff(history?.location?.state?.filterPayload?.stuff);
    setSelectedFindingType(
      history?.location?.state?.filterPayload?.findingType
    );
    setSelectedScanningSubject(
      history?.location?.state?.filterPayload?.scanningSubject
    );
    setSelectedCheckbox(
      history?.location?.state?.filterPayload?.status || {
        isSelectAll: false,
        selected: [],
      }
    );
    setSearchNumber(
      history?.location?.state?.filterPayload?.searchNumber || {
        value: null,
        isValid: false,
      }
    );
  }, []);

  const hideAddAndUpdate = useMemo(() => {
    return !(hasRole(OPERATOR_ROLE) || hasRole(MANAGER_ROLE));
  }, [hasRole]);

  const handleSubmit = (value) => {
    history?.push(FINDINGS_NEW_PAGE, {
      findingType: value,
    });
    dispatch(closeModal());
  };

  const handleClickAddButton = () => {
    dispatch(
      toggleSetFindingTypesModal({
        title: t("findings.createFindingTitle"),
        rank: 0,
        handleSubmit,
      })
    );
  };

  const handleRedirect = (findingId) =>
    replaceInRoute(FINDINGS_EDIT_PAGE, {
      findingId: findingId,
    });

  const refetch = useCallback(() => {
    if (tableRef?.current) {
      tableRef?.current?.forceRefetch();
    }
  }, []);

  const tableColumns = useMemo(() => {
    let columnsObject = Object.keys(findingsTableConstants).map((property) => ({
      ...findingsTableConstants[property],
      field: t(findingsTableConstants[property].i18key),
      propertyName: property,
      backendProperty:
        findingsTableConstants[property].backendProperty || property,
      style: findingsTableConstants[property]?.style,
      renderer: findingsTableConstants[property]?.renderer || TextRenderer,
      rendererProps: {
        refetch,
      },
    }));
    if (hideAddAndUpdate) return columnsObject?.slice?.(0, -1);
    return columnsObject;
  }, [findingsTableConstants, t, hasRole]);

  const mapDataFunction = useCallback(
    (newData) => {
      return newData?.map?.((singleData) => {
        let mappedDataObject = {
          number: `#${singleData?.findingNumber || singleData?.number}`,
          date: formatDate(singleData?.date),
          client: singleData?.clientName,
          location: singleData?.location,
          findingType: singleData?.findingType,
          status:
            singleData?.findingStateId === 0 ? 1 : singleData?.findingStateId,
          staff: `${singleData?.staff?.firstName} ${singleData?.staff?.lastName}`,
          lastChanges: {
            date: formatDateTime(
              singleData?.updatedAtUtc || new Date().toString()
            ),
            author: `${singleData?.updatedByUser?.firstName || ""} ${
              singleData?.updatedByUser?.lastName || ""
            }`,
          },
          id: singleData?.id,
          actions: {
            findingId: singleData?.id,
            date: formatDateTime(
              singleData?.createdAtUtc || new Date().toString()
            ),
            client: singleData?.client?.name,
            location: singleData?.location,
            findingNumber: singleData?.findingNumber,
            staff: `${singleData?.staff?.firstName} ${singleData?.staff?.lastName}`,
            lastChanges: {
              date: formatDateTime(
                singleData?.updatedAtUtc || new Date().toString()
              ),
              author: `${singleData?.updatedByUser?.firstName || ""} ${
                singleData?.updatedByUser?.lastName || ""
              }`,
            },
            status:
              singleData?.findingStateId === 0 ? 1 : singleData?.findingStateId,
          },
        };
        if (hideAddAndUpdate) delete mappedDataObject?.actions;
        return mappedDataObject;
      });
    },
    [hasRole]
  );

  const handleChangeCheckbox = (checked) => {
    if (selectedCheckbox?.isSelectAll) {
      setSelectedCheckbox({
        isSelectAll: false,
        selected: [checked],
      });
    } else {
      if (
        selectedCheckbox?.selected?.find?.((selected) => selected === checked)
      ) {
        setSelectedCheckbox((prevState) => {
          return {
            isSelectAll: false,
            selected: prevState?.selected?.filter?.(
              (oldSelected) => oldSelected !== checked
            ),
          };
        });
      } else {
        setSelectedCheckbox((prevState) => {
          return {
            isSelectAll: false,
            selected: [...prevState?.selected, checked],
          };
        });
      }
    }
  };

  const handleSelectAllCheckboxes = () => {
    setSelectedCheckbox({
      isSelectAll: true,
      selected: statusTableConstantsAsArray?.map?.((entity) => entity?.id),
    });
  };

  const handleResetFilter = () => {
    setStartingDate("");
    setEndingDate("");
    setSelectedClient(null);
    setSelectedStuff(null);
    setSelectedFindingType(null);
    setSelectedScanningSubject(null);
    setKeys((prevVal) => prevVal + 1);
    setSelectedCheckbox({
      isSelectAll: false,
      selected: [],
    });
    setSearchNumber({
      value: null,
      isValid: false,
    });
    history?.replace({
      state: {
        ...location?.state,
        filterPayload: {},
      },
    });
  };

  const applyFilters = () => {
    tableRef?.current?.resetPage?.();
    history?.replace?.({
      state: {
        ...location?.state,
        filterPayload: {
          searchNumber: searchNumber?.isValid ? searchNumber?.value : null,
          date: { start: startingDate, end: endingDate },
          client: selectedClient,
          stuff: selectedStuff,
          findingType: selectedFindingType,
          status: selectedCheckbox?.selected,
          scanningSubject: selectedScanningSubject,
        },
      },
    });
  };

  const filterComponents = [
    <SearchFilterComponent
      key={0}
      label={t("findings.filter.findingId")}
      placeholder={t("findings.filter.placeholder.input")}
      icon={<FindingBreadIcon />}
      value={searchNumber?.value}
      setSearch={setSearchNumber}
    />,
    <FindingsFilterComponent
      startingDate={startingDate}
      setStartingDate={setStartingDate}
      endingDate={endingDate}
      setEndingDate={setEndingDate}
      key={1}
    />,
    <DropdownFilterComponent
      key={keys}
      data={clients}
      label={t("findings.filter.client")}
      placeholder={t("findings.filter.placeholder.client")}
      selectedData={selectedClient}
      setSelectedData={setSelectedClient}
      dropdownLabel={["name"]}
    />,
    <DropdownFilterComponent
      key={keys + 1}
      data={stuffs}
      label={t("findings.filter.stuff")}
      placeholder={t("findings.filter.placeholder.stuff")}
      selectedData={selectedStuff}
      setSelectedData={setSelectedStuff}
      dropdownLabel={["firstName", "lastName"]}
    />,
    <DropdownFilterComponent
      key={keys + 2}
      data={findingTypes}
      label={t("findings.filter.findingType")}
      placeholder={t("findings.filter.placeholder.findingType")}
      selectedData={selectedFindingType}
      setSelectedData={setSelectedFindingType}
      dropdownLabel={["name"]}
    />,
    <DropdownFilterComponent
      key={keys + 1}
      data={scanningSubjects}
      label={t("findings.filter.scanningSubject")}
      placeholder={t("findings.filter.placeholder.scanningSubject")}
      selectedData={selectedScanningSubject}
      setSelectedData={setSelectedScanningSubject}
      dropdownLabel={["name"]}
    />,
    <PickerFilterComponent
      key={4}
      data={statusTableConstantsAsArray}
      label={t("findings.filter.state")}
      selected={selectedCheckbox?.selected}
      isSelectAll={selectedCheckbox?.isSelectAll}
      selectAll={handleSelectAllCheckboxes}
      setSelected={handleChangeCheckbox}
      icon={<FindingBreadIcon />}
    />,
  ];

  return (
    <Table
      showFilter
      filterResetFunc={handleResetFilter}
      hideSearch
      sortDesc
      tableColumns={tableColumns}
      hideAddButton={hideAddAndUpdate}
      searchPlaceholder={t("findings.table.searchPlaceholder")}
      dataSelector={selectFindingsData}
      totalDataSelector={selectTotalFindings}
      customPayload={location?.state?.filterPayload}
      dispatchFunction={fetchFindings}
      mapDataFunction={mapDataFunction}
      redirectHrefFunction={handleRedirect}
      handleClickAddButton={handleClickAddButton}
      clearDispatchFunction={clearFindings}
      filterComponents={filterComponents}
      applyFiltersFunction={applyFilters}
      ref={tableRef}
      stickyActions
    />
  );
};

FindingsTable.propTypes = {
  children: PropTypes.node,
  isLoading: PropTypes.bool,
};

export default FindingsTable;
