import styled from "styled-components";

export const TooltipInnerContainer = styled.div`

${(props) =>

props.mediaQuery && 
`
 @media (max-width:600px) {
    width:100%;
  }
`}
`;
