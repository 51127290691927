import React from "react";
// import PropTypes from 'prop-types'
import { ReactComponent as UnorderedListIconSvg } from "../../../assets/images/lexical/list-ul.svg";

const UnorderedListIcon = (props) => {
  return <UnorderedListIconSvg {...props} />;
};

UnorderedListIcon.propTypes = {};

export default UnorderedListIcon;
