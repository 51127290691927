import { Box } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../../themes";

export const TableColumnsContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: ${selectedTheme.colors.dashboardHeaderColor};
  @media (max-width: 600px) {
    flex-direction: column;
  }
`;
export const TableColumnSeparator = styled(Box)`
  width: 1px;
  height: 100%;
  border-left: 1px solid ${selectedTheme.colors.tableBorder};
  @media (max-width: 600px) {
    display: none;
  }
`;
