import LastChangesRenderer from "../../components/Clients/ClientsTable/Renderers/LastChangesRenderer/LastChangesRenderer";
import ActionRenderer from "../../components/Settings/Licenses/LicensesTable/Renderers/ActionRenderer/ActionRenderer";

export default {
  idString: {
    id: 0,
    i18key: "licenses.table.id.columnTitle",
    backendProperty: "id",
    redirect: true,
    style: {
      minWidth: "calc(100% / 6)",
    },
    disableSort: true,
  },
  header: {
    id: 4,
    i18key: "licenses.table.header.columnTitle",
    mobile: true,
    disableSort: true,
    style: {},
  },
  startDate: {
    id: 1,
    i18key: "licenses.table.startDate.columnTitle",
    backendProperty: "startDate",
    style: {
      minWidth: "calc(100% / 6)",
    },
  },
  expirationDate: {
    disableSort: true,
    id: 2,
    i18key: "licenses.table.expirationDate.columnTitle",
    backendProperty: "expirationDate",
    style: {
      minWidth: "calc(100% / 6)",
    },
  },
  status: {
    disableSort: true,
    id: 2,
    i18key: "licenses.table.status.columnTitle",
    backendProperty: "status",
    style: {
      minWidth: "calc(100% / 6)",
    },
  },
  lastChanges: {
    id: 3,
    i18key: "licenses.table.lastChanges.columnTitle",
    renderer: LastChangesRenderer,
    style: {
      minWidth: "calc(100% / 6)",
      textAlign: "center",
      justifyContent: "center",
    },
    backendProperty: "updatedAtUtc",
  },
  actions: {
    id: 5,
    i18key: "",
    renderer: ActionRenderer,
    disableSort: true,
    style: {
      minWidth: "60px",
      paddingRight: "24px",
      justifyContent: "center",
      textAlign: "center",
      maxWidth: "60px",
    },
  },
};
