import React from "react";
// import PropTypes from 'prop-types'
import { ReactComponent as DesktopIconSvg } from "../../../assets/images/svg/desktop.svg";

const DesktopIcon = (props) => {
  return <DesktopIconSvg {...props} />;
};

DesktopIcon.propTypes = {};

export default DesktopIcon;
