import { Box } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../themes";

export const SidebarNavigationItemContainer = styled(Box)`
  width: 100%;
  display: flex;
  gap: 8px;
  flex-direction: column;
  align-items: ${(props) =>
    props?.$isSidebarOpened ? "flex-start" : "center"};
  cursor: pointer;
  position: relative;
  border-radius: 12px;
  background-color: ${(props) =>
    props?.$isSidebarItemChosen &&
    selectedTheme.colors.sidebar.openedSubnavigationBackground};
  &:hover {
    background-color: ${(props) =>
      props?.$isSidebarOpened &&
      !props?.$isSidebarItemChosen &&
      selectedTheme.colors.sidebar.hoveredItem};
  }
`;
