import React from "react";
import PropTypes from "prop-types";
import { NewCompanyContentContainer } from "./NewCompanyContent.styled";
import SettingsContent from "../../Settings/SettingsContent";

const NewCompanyContent = (props) => {
  return (
    <NewCompanyContentContainer>
      <SettingsContent
        companyId={props?.companyId}
        title={props?.title}
        isEditing={props?.isEditing}
      />
    </NewCompanyContentContainer>
  );
};

NewCompanyContent.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  isEditing: PropTypes.bool,
  companyId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default NewCompanyContent;
