import React from "react";
import PropTypes from "prop-types";
import { TableColumnsContainer } from "./TableColumns.styled";
import TableSingleColumn from "./TableSingleColumn/TableSingleColumn";
import { NOT_SORTED } from "../../../../constants/queryConstants";
import useIsMobile from "../../../../hooks/useIsMobile";
import { useMemo } from "react";

const TableColumns = (props) => {
  const { isMobile } = useIsMobile();

  const columns = useMemo(() => {
    if (isMobile)
      return props?.columns?.filter?.((singleColumn) => singleColumn?.mobile);
    else
      return props?.columns?.filter?.((singleColumn) => !singleColumn?.mobile);
  }, [isMobile, props?.columns]);

  return (
    <TableColumnsContainer>
      {columns?.map?.((item, index) => (
        <TableSingleColumn
          minWidth={`calc(100% / ${
            columns?.map?.((singleColumn) => !singleColumn?.mobile)?.length
          })`}
          key={index}
          style={item?.style}
          column={item}
          sorting={
            props?.sorting?.column === item?.backendProperty
              ? props?.sorting?.sortDirection
              : NOT_SORTED
          }
          $onChangeSorting={props?.onChangeSorting}
        />
      ))}
    </TableColumnsContainer>
  );
};

TableColumns.propTypes = {
  columns: PropTypes.array,
  onChangeSorting: PropTypes.func,
  sorting: PropTypes.object,
};

export default TableColumns;
