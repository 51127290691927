export const USER_STATUS = {
  ACTIVE: {
    id: 0,
    label: "users.table.status.active",
  },
  INACTIVE: {
    id: 1,
    label: "users.table.status.inactive",
  },
  INVITED: {
    id: 2,
    label: "users.table.status.invited",
  },
  GUEST: {
    id: 3,
    label: "users.table.status.guest",
  },
};
export const findStatusByStatusId = (statusId) => {
  let statusToReturn = USER_STATUS.GUEST;
  Object.keys(USER_STATUS).forEach((singleStatus) => {
    if (USER_STATUS[singleStatus].id === statusId)
      statusToReturn = USER_STATUS[singleStatus];
  });
  return statusToReturn;
};
