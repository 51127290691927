import { format as formatDate } from "date-fns";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import placeholder from "./resources/placeholder";

i18n.use(initReactI18next).init({
  lng: "sr",
  fallbackLng: "placeholder",
  partialBundledLanguages: true,
  debug: false,
  supportedLngs: ["sr", "en", "de", "placeholder"],
  react: {
    bindI18n: "loaded languageChanged",
    bindI18nStore: "added",
    wait: true,
  },
  resources: {
    sr: {
      translation: {},
    },
    en: {
      translation: {},
    },
    de: {
      translation: {},
    },
    placeholder: {
      translation: placeholder,
    },
  },
  interpolation: {
    format: (value, format) => {
      if (value instanceof Date) {
        return formatDate(value, format);
      }

      return value;
    },
    escapeValue: false,
  },
});

export default i18n;
