import { Box, Button } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../../../../themes";

export const FindingNewScanningSubjectContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 0 12px;
  min-width: 290px;
  max-width: 355px;
`;
export const FindingNewScanningSubjectButtonContainer = styled(Button)`
  background-color: ${selectedTheme.colors.findingSubjectChosenBg};
  color: ${selectedTheme.colors.primaryButtonTextColor};
  text-transform: none;
  padding: 8px 14px;
  border-radius: 6px;
  font-family: ${selectedTheme.fonts.mainText};
  font-size: 14px;
  font-weight: 600;
  align-self: flex-end;
  white-space: nowrap;
  width: fit-content;
  box-shadow: none;
  
  & svg {
    width: 22px;
    height: 22px;
    margin-right: 5px;
  }
  & path {
    stroke: ${selectedTheme.colors.primaryButtonTextColor};
  }

  &:hover {
    background-color: ${selectedTheme.colors.findingSubjectChosenBg};
    color: ${selectedTheme.colors.primaryButtonTextColor};
    box-shadow: none;
  }
`;
