import { Box } from "@mui/material";
import styled from "styled-components";
import ArrowIcon from "../../Icon/Icons/ArrowIcon";

export const SortButtonContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  right: 0;
  position: absolute;
  @media (max-width: 600px) {
    right: 24px;
  }
`;
export const UpArrow = styled(ArrowIcon)`
  width: 10px;
  height: 11px;
`;
export const DownArrow = styled(ArrowIcon)`
  transform: rotate(180deg);
  width: 10px;
  height: 11px;
`;
