import { Box, Typography } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../../../themes";

export const TypesRendererContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  position: relative;
  gap: 2px 2px;
`;

export const TooltipContainer = styled(Box)``;

export const TypesRendererContentContainer = styled(Box)`
  display: flex;
  gap: 5px;
`;

export const TypesRendererContentTitle = styled(Typography)`
  font-family: ${selectedTheme.fonts.mainText};
  color: ${selectedTheme.colors.findingTypeTextColor};
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  padding: 0px 8px 0px 8px;
  height: 20px;
  border-radius: 24px;
  gap: 4px;
  background: ${selectedTheme.colors.findingTypeColor};
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: fit-content;
  word-break: break-all;
  margin-bottom: ${(props) => (props?.onTooltip ? "5px" : "0px")};
`;

export const InfoContainer = styled(Box)``;
