import { Box } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../themes";
import Switch from "../../Switch/Switch";
import Title from "../../Typography/Title/Title";

export const TemplateCardContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px 32px;
  gap: 16px;
  width: 100%;
  border: 1.46809px solid ${selectedTheme.colors.templateCardBorder};
  border-radius: 23.4894px;
  position: relative;
  cursor: pointer;
`;

export const TemplateCardTitle = styled(Title)`
  font-size: 26.4255px;
  line-height: 32px;
  color: ${selectedTheme.colors.templateCardTitle};
`;
export const TemplateCardCreatedBy = styled(Title)`
  font-weight: 500;
  font-size: 17.617px;
  line-height: 21px;
  color: ${selectedTheme.colors.filterColor};
`;
export const TemplateCardDetails = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 32px;
  align-items: center;
  @media (max-width: 600px) {
    margin-bottom: 40px;
    gap: 15px;
  }
`;
export const SwitchLabel = styled(Title)`
  font-size: 14px;
  line-height: 20px;
`;
export const IsActiveTemplateSwitch = styled(Switch)`
  position: absolute;
  bottom: 24px;
  right: 32px;
  @media (max-width: 600px) {
    bottom: 24px;
    right: 17px;
  }
`;
