import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  CancelEditIcon,
  FinishEditIcon,
  SectionTitleField,
  TemplateSectionsListItemEditContainer,
} from "./ListItemEdit.styled";
import { useTranslation } from "react-i18next";
import Tooltip from "../../../../../../Tooltip/Tooltip";
import i18n from "../../../../../../../i18n";

const TemplateSectionsListItemEdit = (props) => {
  const { t } = useTranslation();
  const [value, setValue] = useState(props?.oldValue);
  const handleEditSectionTitle = () => {
    if (value) {
      props?.handleEndEditing(value);
    }
  };
  const handleEndEditing = () => {
    props?.handleEndEditing();
  };

  const handleChangeValue = (event) => {
    setValue(event.target?.value);
  };

  return (
    <TemplateSectionsListItemEditContainer>
      <SectionTitleField
        placeholder={t(
          "scanningObjects.newScanningObject.templateEditor.newSection"
        )}
        value={value}
        onChange={handleChangeValue}
      />
      <Tooltip title={i18n.t("common.save")}>
        <FinishEditIcon onClick={handleEditSectionTitle} />
      </Tooltip>
      <Tooltip title={i18n.t("common.cancel")}>
        <CancelEditIcon onClick={handleEndEditing} />
      </Tooltip>
    </TemplateSectionsListItemEditContainer>
  );
};

TemplateSectionsListItemEdit.propTypes = {
  handleEndEditing: PropTypes.func,
  oldValue: PropTypes.string,
};

export default TemplateSectionsListItemEdit;
