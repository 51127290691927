import React from 'react'
import ScanningObjectsContent from '../../../../components/ScanningObjects/ScanningObjectsContent'

const ScanningObjectsPage = () => {
  return (
    <ScanningObjectsContent />
  )
}

export default ScanningObjectsPage
