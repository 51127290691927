import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import TextInputField from "../../../Field/TextInputField/TextInputField";
import Tooltip from "../../../Tooltip/Tooltip";

const CompanyDirectorField = (props) => {
  const { t } = useTranslation();
  const formik = props?.formik;
  return (
    <Tooltip
      title={
        formik?.values?.companyDirector?.length > 41
          ? formik?.values?.companyDirector
          : ""
      }
    >
      <TextInputField
        name="companyDirector"
        label={t("companySettings.companyDirector")}
        value={formik?.values?.companyDirector}
        onChange={formik?.handleChange}
        error={formik?.touched?.companyDirector && formik?.errors?.companyDirector}
        helperText={
          formik?.touched?.companyDirector && formik?.errors?.companyDirector
        }
      />
    </Tooltip>
  );
};

CompanyDirectorField.propTypes = {
  formik: PropTypes.object,
};

export default CompanyDirectorField;
