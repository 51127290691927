import React, { useMemo } from "react";
import PropTypes from "prop-types";
import {
  DownArrow,
  LeftArrow,
  OrdinalNumberButtonContainer,
  OrdinalNumberButtonItemsContainer,
  RightArrow,
  UpArrow,
} from "./OrdinalNumberButton.styled";

const OrdinalNumberButton = (props) => {
  const BelowArrow = useMemo(() => {
    const arrow = (belowArrowProps) =>
      props?.vertical ? (
        <DownArrow {...belowArrowProps} />
      ) : (
        <LeftArrow {...belowArrowProps} />
      );
    return arrow;
  }, [props]);

  const AboveArrow = useMemo(() => {
    const arrow = (belowArrowProps) =>
      props?.vertical ? (
        <UpArrow {...belowArrowProps} />
      ) : (
        <RightArrow {...belowArrowProps} />
      );
    return arrow;
  }, [props]);

  return (
    <OrdinalNumberButtonContainer
      className={props?.className}
      vertical={props?.vertical}
      separated={props?.separated}
      disabled={props?.disabled}
    >
      {props?.separated && (
        <OrdinalNumberButtonItemsContainer
          vertical={props?.vertical}
          separated={props?.separated}
        >
          {props?.number}
        </OrdinalNumberButtonItemsContainer>
      )}
      <OrdinalNumberButtonItemsContainer
        vertical={props?.vertical}
        separated={props?.separated}
      >
        {!props?.disabled && <BelowArrow onClick={props?.onBelowArrowClick} />}
        {!props?.separated && props?.number}{" "}
        {!props?.disabled && <AboveArrow onClick={props?.onAboveArrowClick} />}
      </OrdinalNumberButtonItemsContainer>
    </OrdinalNumberButtonContainer>
  );
};

OrdinalNumberButton.propTypes = {
  children: PropTypes.node,
  number: PropTypes.number,
  vertical: PropTypes.bool,
  onBelowArrowClick: PropTypes.func,
  onAboveArrowClick: PropTypes.func,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  separated: PropTypes.bool,
};

export default OrdinalNumberButton;
