import React from "react";
import PropTypes from "prop-types";
import {
  ViewRadioButtonContainer,
  ViewRadioContainer,
} from "./ViewRadio.styled";
import MobileIcon from "../../../../../Icon/Icons/MobileIcon";
import deviceConstants from "../../../../../../constants/deviceConstants";
import DesktopIcon from "../../../../../Icon/Icons/DesktopIcon";

const ViewRadio = (props) => {
  return (
    <ViewRadioContainer>
      <ViewRadioButtonContainer
        selected={props?.selected === deviceConstants.mobile}
        onClick={() => props?.changeSelectedDevice(deviceConstants.mobile)}
      >
        <MobileIcon />
      </ViewRadioButtonContainer>
      <ViewRadioButtonContainer
        onClick={() => props?.changeSelectedDevice(deviceConstants.desktop)}
        selected={props?.selected === deviceConstants.desktop}
      >
        <DesktopIcon />
      </ViewRadioButtonContainer>
    </ViewRadioContainer>
  );
};

ViewRadio.propTypes = {
  selected: PropTypes.number,
  changeSelectedDevice: PropTypes.func,
};

export default ViewRadio;
