import { Box, Typography } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../themes";
import DownArrowIcon from "../../Icon/Icons/DownArrowIcon";

export const GoBackButtonContainer = styled(Box)`
  display: flex;
  gap: 16px;
  align-items: center;
`;
export const ArrowIcon = styled(DownArrowIcon)`
  transform: rotate(90deg);
  width: 16px;
`;
export const ButtonLabel = styled(Typography)`
  font-family: "Inter";
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  font-feature-settings: "salt" on;
  color: ${selectedTheme.colors.pageTitleColor};
`;
