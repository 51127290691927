import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import {
  LOGIN_PAGE,
  HOME_PAGE,
  NOT_FOUND_PAGE,
  ERROR_PAGE,
  BASE_PAGE,
  USERS_PAGE,
  SETTINGS_PAGE,
  RESEARCH_PAGE,
  CLIENTS_PAGE,
  STUFFS_PAGE,
  FINDINGS_PAGE,
  PROFILE_PAGE,
  PERSONAL_PAGE,
  COMPANIES_PAGE,
  DOCUMENTS_PAGE,
  LOGOUT_PAGE,
  INVITE_PAGE,
  INVITE_TIMEOUT_PAGE,
} from "./constants/pages";
import LoginPage from "./pages/LoginPage/LoginPageMUI";
import HomePage from "./pages/HomePage/HomePage";
import NotFoundPage from "./pages/ErrorPages/NotFoundPage";
import ErrorPage from "./pages/ErrorPages/ErrorPage";
import PrivateRoute from "./components/Router/PrivateRoute";
import UnauthorizedRoute from "./components/Router/UnauthorizedRoute";
import SettingsPage from "./pages/SettingsPage/SettingsPage";
import UsersPage from "./pages/UsersPage/UsersPage";
import ResearchPage from "./pages/ResearchPages/ResearchPage";
import ClientsPage from "./pages/ClientsPage/ClientsPage";
import StuffsPage from "./pages/StuffsPage/StuffsPage";
import BasePage from "./pages/BasePage/BasePage";
import AuthRoute from "./components/Router/AuthRoute";
import ProfilePage from "./pages/ProfilePage/ProfilePage";
import {
  ADMIN_ROLE,
  MANAGER_ROLE,
  OPERATOR_ROLE,
} from "./constants/rolesConstants";
import FindingsPage from "./pages/FindingsPage/FindingsPage";
import PersonalPage from "./pages/PersonalPage/PersonalPage";
import SuperAdminRoute from "./components/Router/SuperAdminRoute";
import CompaniesPage from "./pages/CompaniesPage/CompaniesPage";
import DocumentsPage from "./pages/DocumentsPage/DocumentsPage";
import LogoutPage from "./pages/LogoutPage/LogoutPage";
import InvitePage from "./pages/InvitePage/InvitePage";
import InviteTimeoutPage from "./pages/InviteTimeoutPage/InviteTimeoutPage";

const AppRoutes = () => (
  <Switch>
    <Route path={LOGOUT_PAGE} component={LogoutPage} />
    <Route exact path={BASE_PAGE} component={BasePage} />
    <UnauthorizedRoute exact path={LOGIN_PAGE} component={LoginPage} />
    <UnauthorizedRoute path={INVITE_TIMEOUT_PAGE} component={InviteTimeoutPage} />
    <UnauthorizedRoute path={INVITE_PAGE} component={InvitePage} />
    <AuthRoute
      roles={[MANAGER_ROLE, ADMIN_ROLE]}
      exact
      path={SETTINGS_PAGE}
      component={SettingsPage}
    />
    <AuthRoute
      roles={[MANAGER_ROLE, OPERATOR_ROLE]}
      path={FINDINGS_PAGE}
      component={FindingsPage}
    />
    <AuthRoute
      roles={[MANAGER_ROLE, OPERATOR_ROLE]}
      path={RESEARCH_PAGE}
      component={ResearchPage}
    />
    <AuthRoute
      roles={[MANAGER_ROLE, OPERATOR_ROLE, ADMIN_ROLE]}
      path={PERSONAL_PAGE}
      component={PersonalPage}
    />
    <AuthRoute
      roles={[MANAGER_ROLE, OPERATOR_ROLE]}
      path={CLIENTS_PAGE}
      component={ClientsPage}
    />
    <AuthRoute
      roles={[ADMIN_ROLE, MANAGER_ROLE]}
      path={USERS_PAGE}
      component={UsersPage}
    />
    <AuthRoute
      roles={[MANAGER_ROLE, OPERATOR_ROLE]}
      path={STUFFS_PAGE}
      component={StuffsPage}
    />
    <AuthRoute roles={[]} path={DOCUMENTS_PAGE} component={DocumentsPage} />
    <Route path={PROFILE_PAGE} component={ProfilePage} />
    <Route path={NOT_FOUND_PAGE} component={NotFoundPage} />
    <Route path={ERROR_PAGE} component={ErrorPage} />
    <SuperAdminRoute path={COMPANIES_PAGE} component={CompaniesPage} />
    <PrivateRoute path={HOME_PAGE} component={HomePage} />
    <Redirect from="*" to={NOT_FOUND_PAGE} />
  </Switch>
);

export default AppRoutes;
