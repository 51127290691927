import React from "react";
// import PropTypes from 'prop-types'
import { ReactComponent as StandardIconSvg } from "../../../assets/images/svg/task-square.svg";

const StandardIcon = (props) => {
  return <StandardIconSvg {...props} />;
};

StandardIcon.propTypes = {};

export default StandardIcon;
