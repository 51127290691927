import React from "react";
import PropTypes from "prop-types";
import {
  EmptyScanningObjectAltText,
  EmptyScanningObjectAltTextContainer,
  EmptyScanningObjectAltTextLink,
  EmptyScanningObjectPickerContainer,
} from "./EmptyScanningObjectPicker.styled";
import AddButton from "../../../../Buttons/AddButton/AddButton";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setScanningObjectsChanged } from "../../../../../store/actions/scanningObjects/scanningObjectsActions";

const EmptyScanningObjectPicker = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const handleAddTemplate = () => {
    props?.handleAddTemplate();
    dispatch(setScanningObjectsChanged(true));
  };

  return (
    <EmptyScanningObjectPickerContainer
      isActiveTemplate={props?.activeTemplates}
    >
      {!props?.activeTemplates && props?.isEditing && (
        <EmptyScanningObjectAltText isActiveTemplate={props?.activeTemplates}>
          {t("scanningObjects.noTemplate")}
        </EmptyScanningObjectAltText>
      )}
      <AddButton
        style={{ backgroundColor: "black", alignItems: "right" }}
        title={t("scanningObjects.newScanningObject.addTemplate")}
        onClick={handleAddTemplate}
      />
      {props?.activeTemplates && (
        <EmptyScanningObjectAltTextContainer
          isActiveTemplate={props?.activeTemplates}
        >
          <EmptyScanningObjectAltText isActiveTemplate={props?.activeTemplates}>
            {t("scanningObjects.noTemplatev2")}
          </EmptyScanningObjectAltText>
          <EmptyScanningObjectAltTextLink
            onClick={props?.handleSwitchToAllTemplates}
          >
            {t("scanningObjects.noTemplateLink")}
          </EmptyScanningObjectAltTextLink>
        </EmptyScanningObjectAltTextContainer>
      )}
    </EmptyScanningObjectPickerContainer>
  );
};

EmptyScanningObjectPicker.propTypes = {
  children: PropTypes.node,
  handleAddTemplate: PropTypes.func,
  activeTemplates: PropTypes.bool,
  handleSwitchToAllTemplates: PropTypes.func,
  isEditing: PropTypes.bool,
};

export default EmptyScanningObjectPicker;
