import React from "react";
import PropTypes from "prop-types";
import { FooterContainer, LogoIcon } from "./Footer.styled";
import { useSelector } from "react-redux";
import { selectIsSidebarOpened } from "../../store/selectors/sidebarSelector";
import { Trans, useTranslation } from "react-i18next";
//import logo from "../../assets/images/png/Tremium_Icon.png";
import logo2 from "../../assets/images/svg/powered by tremium logo.svg"


const Footer = () => {
  useTranslation();
  const isSidebarOpened = useSelector(selectIsSidebarOpened);
  return (
    <FooterContainer $isSidebarOpened={isSidebarOpened}>
      <Trans i18nKey="footer.title" components={{ span: <span />, 0: <LogoIcon src={logo2} />, 1: <a href="https://tremium.net/" target="_blank" rel="noopener noreferrer" className="tremium-link"></a> }} />
    </FooterContainer>
  );
};

Footer.propTypes = {
  children: PropTypes.node,
};

export default Footer;
