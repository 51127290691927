import { getRequest, postRequest } from "./index";
import apiEndpoints from "./apiEndpoints";

export const getUsernames = (emailorusername) =>
  getRequest(apiEndpoints.authentications.getUsernames, {
    emailorusername,
  });

export const attemptLogin = (payload) =>
  postRequest(apiEndpoints.authentications.login, payload);

export const updateSecurityAnswer = (payload) =>
  postRequest(apiEndpoints.authentications.confirmSecurityQuestion, payload);

export const refreshTokenRequest = (payload) =>
  postRequest(apiEndpoints.authentications.refreshToken, payload);

export const logoutUserRequest = (payload) =>
  postRequest(apiEndpoints.authentications.logout, payload);

export const generateTokenRequest = (payload) =>
  postRequest(apiEndpoints.authentications.generateToken, payload);
