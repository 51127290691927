import React from "react";
import { FindingCheckboxesWrapper } from "./FindingCheckboxes.styled";
import { Checkbox, FormControlLabel, InputLabel } from "@mui/material";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const FindingCheckboxes = ({ disabled, checkedConclusion, setCheckedConclusion }) => {
  const { t } = useTranslation();

  const handleChangeCheckbox = (prop) => {
    setCheckedConclusion(prop);
  };

  return (
    <FindingCheckboxesWrapper>
      <FormControlLabel
        disabled={disabled}
        control={
          <Checkbox
            defaultChecked
            checked={checkedConclusion === 1}
            onChange={() => handleChangeCheckbox(1)}
          />
        }
        label={<InputLabel>{t("findings.positiveConclusion")}</InputLabel>}
      />
      <FormControlLabel
        disabled={disabled}
        control={
          <Checkbox
            defaultChecked
            checked={checkedConclusion === 2}
            onChange={() => handleChangeCheckbox(2)}
          />
        }
        label={<InputLabel>{t("findings.negativeConclusion")}</InputLabel>}
      />
    </FindingCheckboxesWrapper>
  );
};

FindingCheckboxes.propTypes = {
  disabled: PropTypes.bool,
  checkedConclusion: PropTypes.any,
  setCheckedConclusion: PropTypes.func
};

export default FindingCheckboxes;
