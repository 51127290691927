import UserRenderer from "../../components/Users/UsersTable/ActiveUsersTable/Renderers/UserRenderer/UserRenderer";
import DeleteRenderer from "../../components/TemplateEditor/ActiveTemplateEditor/TemplateStandardsEditor/TemplateStandardsTable/Renderers/DeleteRenderer/DeleteRenderer";

export default {
  name: {
    id: 0,
    i18key: "standards.table.caption.columnTitle",
    renderer: UserRenderer,
    style: {
      padding: "6px",
      flex: "2",
      whiteSpace: "wrap",
    },
    disableSort: true,
  },
  // header: {
  //   id: 8,
  //   i18key: "standards.table.name.columnTitle",
  //   mobile: true,
  //   disableSort: true,
  //   style: {},
  // },
  delete: {
    id: 3,
    i18key: "",
    renderer: DeleteRenderer,
    disableSort: true,
    style: {
      padding: "6px 24px 6px 6px",
      justifyContent: "center",
      textAlign: "center",
      width: "80px",
    },
  },
};
