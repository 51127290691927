import { Box, Typography } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../../themes";
import PrimaryButton from "../../../Buttons/PrimaryButton/PrimaryButton";
import PlusIcon from "../../../Icon/Icons/PlusIcon";

export const FindingTableInputContainer = styled(Box)`
  border-radius: 8px;
  border: 1px solid ${selectedTheme.colors.inputBorder};
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  & * {
    font-family: ${selectedTheme.fonts.mainText};
    font-size: 13.33px;
  }
`;
export const FindingRowsContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
export const AddRowButton = styled(PrimaryButton)`
  max-width: 64px;
  margin: 8px;
  & button {
    background-color: ${selectedTheme.colors.white} !important;
    border-radius: 8px;
    border: 1px solid ${selectedTheme.colors.inputBorderColor} !important;
    padding: 8px 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    line-height: 20px;
    color: ${selectedTheme.colors.addMoreText};
    font-size: 14px;
    font-weight: 600;
    letter-spacing: normal;
    font-family: ${selectedTheme.fonts.mainText};

    width: auto;
    &:hover {
      background-color: ${selectedTheme.colors
        .primaryButtonLightColor} !important;
    }
  }
`;
export const PlusIconThin = styled(PlusIcon)`
  width: 21px;
  height: 21px;
  min-width: 21px;
  min-height: 21px;
  & path {
    stroke-width: 1.25;
    stroke: ${selectedTheme.colors.gray};
  }
`;

export const FindingTableInputFooter = styled(Box)`
  display: flex;
  flex-direction: column;
`;

export const ErrorMessage = styled(Typography)`
  font-family: ${selectedTheme.fonts.mainText};
  font-size: 14px;
  text-align: center;
  font-weight: 400;
  color: ${selectedTheme.colors.errorColor};
`;
export const FindingItemTableRefContainer = styled(Box)``;
