export const findingFormikConstants = {
  clientId: "clientId",
  location: "location",
  facility: "facility",
  description: "description",
  staffId: "staffId",
  date: "date",
  closingDate: "closingDate",
  note: "note",
  images: "images",
};

export default (values) => ({
  [findingFormikConstants.clientId]: values?.clientId || {
    value: null,
    edited: false,
  },

  [findingFormikConstants.location]: values?.location || {
    value: "",
    edited: false,
  },

  [findingFormikConstants.description]: values?.description || {
    value: "",
    edited: false,
  },

  [findingFormikConstants.staffId]: values?.staffId || {
    value: null,
    edited: false,
  },

  [findingFormikConstants.closingDate]: {
    edited: false,
    value: values?.closingDate?.value
      ? new Date(values?.closingDate?.value)
      : null,
  },

  [findingFormikConstants.date]: {
    edited: false,
    value: values?.date?.value ? new Date(values?.date?.value) : new Date(),
  },

  [findingFormikConstants.facility]: values?.facility || {
    value: "",
    edited: false,
  },
});
