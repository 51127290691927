import React from "react";
// import PropTypes from 'prop-types'
import { ReactComponent as StaffIconSvg } from "../../../assets/images/svg/staff.svg";

const StaffIcon = (props) => {
  return <StaffIconSvg {...props} />;
};

StaffIcon.propTypes = {};

export default StaffIcon;
