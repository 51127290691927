import React from "react";
import PropTypes from "prop-types";
import Checkbox from "../../../Checkbox/Checkbox";
import { useTranslation } from "react-i18next";
import { newFindingTypeFormikConstants } from "../../../../initialValues/newFindingTypeInitialValues";

const AuthorizationField = (props) => {
  const { t } = useTranslation();
  const formik = props?.formik;
  return (
    <Checkbox
      label={t("findingTypes.aprStatemens")}
      checked={formik?.values?.[newFindingTypeFormikConstants?.authorization]}
      disabled={props?.disabled}
      onChange={(value) =>
        formik?.setFieldValue?.(newFindingTypeFormikConstants.authorization, value)
      }
    />
  );
};

AuthorizationField.propTypes = {
  formik: PropTypes.object,
  disabled: PropTypes.bool,
};

export default AuthorizationField;
