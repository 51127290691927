import React from "react";
// import PropTypes from 'prop-types'
import { ReactComponent as MeasuringInstrumentsSidebarIconSvg } from "../../../assets/images/svg/measuring-instruments-sidebar.svg";

const MeasuringInstrumentsSidebarIcon = (props) => {
  return <MeasuringInstrumentsSidebarIconSvg {...props} />;
};

MeasuringInstrumentsSidebarIcon.propTypes = {};

export default MeasuringInstrumentsSidebarIcon;
