import React from "react";
import PropTypes from "prop-types";
import {
  DistributionInputField,
  DistributionMailContainer,
  DistributionTextFieldContainer,
  InputRightElement,
  MinusIconContainer,
} from "./DistributionMailInput.styled";
import { ReactComponent as MinusIcon } from "../../../../assets/images/svg/minus-cirlce.svg";
import Tooltip from "../../../Tooltip/Tooltip";
import { useTranslation } from "react-i18next";
import ErrorIcon from "../../../Icon/Icons/ErrorIcon";
import selectedTheme from "../../../../themes";

const DistributionMailInput = (props) => {
  const { t } = useTranslation();
  return (
    <DistributionMailContainer>
      <DistributionTextFieldContainer>
        <DistributionInputField
          className={props?.className}
          variant="standard"
          InputProps={{ disableUnderline: true }}
          type={props?.type}
          name={props?.name}
          placeholder={props?.placeholder}
          onChange={props?.onChange}
          value={props?.value}
          $isErrorShown={props?.error}
          disabled={props?.isReadOnly}
          sx={{
            "> div > input": { paddingRight: props?.error && "40px" },
            "& .MuiInputBase-input.Mui-disabled": {
              backgroundColor: selectedTheme.colors.disabledFieldBackground,
              WebkitTextFillColor: selectedTheme.colors.disabledFieldText,
            },
          }}
        />
        {props?.error && (
          <InputRightElement>
            <Tooltip title={props?.error}>
              <ErrorIcon />
            </Tooltip>
          </InputRightElement>
        )}
      </DistributionTextFieldContainer>
      {!props?.isReadOnly && (
        <Tooltip title={t("clients.tooltip.removeDistributionMail")}>
          <MinusIconContainer>
            <MinusIcon onClick={props?.minusHandler} />
          </MinusIconContainer>
        </Tooltip>
      )}
    </DistributionMailContainer>
  );
};

DistributionMailInput.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.any,
  name: PropTypes.any,
  placeholder: PropTypes.any,
  minusHandler: PropTypes.func,
  formik: PropTypes.object,
  error: PropTypes.string,
  isReadOnly: PropTypes.bool,
};

export default DistributionMailInput;
