import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import ReactDatePicker from "react-datepicker";
import { documentFormikConstants } from "../../../../initialValues/documentInitialValues";
import { DocumentTextField } from "../NewDocumentContent.styled";

const DocumentDateField = (props) => {
  const formik = props?.formik;
  const { t } = useTranslation();
  const handleChangeDate = (date) => {
    formik?.setFieldValue?.(documentFormikConstants.documentDate, date);
  };

  return (
    <>
      <ReactDatePicker
        placeholderText={t("documents.newPage.form.documentDate.placeholder")}
        disabled={props?.disabled}
        selected={formik?.values?.[documentFormikConstants.documentDate]}
        dateFormat="dd/MM/yyyy"
        onChange={handleChangeDate}
        customInput={
          <DocumentTextField
            date
            disabledField={props?.disabled}
            requiredField={true}
            name={documentFormikConstants.documentDate}
            label={t("documents.newPage.form.documentDate.title")}
            placeholder={t("documents.newPage.form.documentDate.placeholder")}
            value={formik?.values?.[documentFormikConstants.documentDate]}
            onChange={formik?.handleChange}
            error={
              formik?.touched?.[documentFormikConstants.documentDate] &&
              formik?.errors?.[documentFormikConstants.documentDate]
            }
            helperText={
              formik?.errors?.[documentFormikConstants.documentDate] &&
              formik?.touched?.[documentFormikConstants.documentDate]
            }
            fullWidth
          />
        }
      />
    </>
  );
};

DocumentDateField.propTypes = {
  formik: PropTypes.object,
  disabled: PropTypes.bool,
  isEditing: PropTypes.bool,
};

export default DocumentDateField;
