import React from "react";
// import PropTypes from 'prop-types'
import { ReactComponent as CopmanyLogoDefaultSvg } from "../../../assets/images/svg/Im-logo-short.svg";

const CopmanyLogoDefault = (props) => {
  return <CopmanyLogoDefaultSvg {...props} />;
};

CopmanyLogoDefault.propTypes = {};

export default CopmanyLogoDefault;
