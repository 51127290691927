import React from "react";
import PropTypes from "prop-types";
import Checkbox from "../../../Checkbox/Checkbox";
import { useTranslation } from "react-i18next";
import { newFindingTypeFormikConstants } from "../../../../initialValues/newFindingTypeInitialValues";

const SummaryField = (props) => {
  const { t } = useTranslation();
  const formik = props?.formik;
  return (
    <Checkbox
      label={t("findingTypes.summary")}
      checked={formik?.values?.[newFindingTypeFormikConstants?.summary]}
      disabled={props?.disabled}
      onChange={(value) =>
        formik?.setFieldValue?.(newFindingTypeFormikConstants.summary, value)
      }
    />
  );
};

SummaryField.propTypes = {
  formik: PropTypes.object,
  disabled: PropTypes.bool,
};

export default SummaryField;
