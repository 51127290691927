import {
    createClearType,
    createErrorType,
    createFetchType,
    createSetType,
    createSuccessType,
  } from "../actionHelpers";

  export const SET_CATEGORIES = createSetType("SET_CATEGORIES");
  export const SET_CATEGORIES_ERROR = "SET_CATEGORY_ERROR";
  export const SET_CATEGORIES_TOTAL = createSetType("CATEGORIES_TOTAL_SCOPE");

  export const GET_CATEGORIES_SCOPE = "GET_CATEGORIES_SCOPE";
  export const GET_CATEGORIES_FETCH = createFetchType(GET_CATEGORIES_SCOPE);
  export const GET_CATEGORIES_SUCCESS = createSuccessType(GET_CATEGORIES_SCOPE);
  export const GET_CATEGORIES_ERROR = createErrorType(GET_CATEGORIES_SCOPE);
  export const GET_CATEGORIES_CLEAR = createClearType(GET_CATEGORIES_SCOPE);