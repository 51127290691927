import { Box } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../../../themes";

export const BenchmarkingRendererContainer = styled(Box)`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  max-height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: "Inter";
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  font-feature-settings: "salt" on;
  width: 100%;
`;

export const BenchmarkDate = styled(Box)`
  font-family: ${selectedTheme.fonts.mainText};
  width: 100%;
  font-size: 14px;
  line-height: 20px;
  font-feature-settings: "salt" on;
  color: ${selectedTheme.colors.titleColor};
`;

export const BenchmarkName = styled(Box)`
  width: 100%;
  font-family: ${selectedTheme.fonts.mainText};
  font-size: 14px;
  line-height: 20px;
  font-feature-settings: "salt" on;
  color: ${selectedTheme.colors.tableColumnText};
`;
