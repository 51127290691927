import React from "react";
import PropTypes from "prop-types";
import {
  ErrorMessage,
  SingleInputContainer,
  SingleInputTitle,
  TextareaInput,
} from "../SetMeasuringInstrumentModal.styled";
import { newMeasuringInstrumentFormikConstants } from "../../../../initialValues/newMeasuringInstrumentInitialValues";
import { useTranslation } from "react-i18next";

const NameField = (props) => {
  const formik = props?.formik;
  const { t } = useTranslation();
  return (
    <SingleInputContainer>
      <SingleInputTitle
        $required={
          !formik?.values?.[newMeasuringInstrumentFormikConstants.name]
        }
      >
        {t("measuringInstruments.modal.fields.name.label")}
      </SingleInputTitle>
      <TextareaInput
        minRows={3}
        id={newMeasuringInstrumentFormikConstants.name}
        onChange={formik?.handleChange}
        value={formik?.values?.[newMeasuringInstrumentFormikConstants.name]}
        placeholder={t("measuringInstruments.modal.fields.name.placeholder")}
        disabled={props?.isReadOnly}
      />
      {formik?.errors?.[newMeasuringInstrumentFormikConstants.name] &&
        formik?.touched?.[newMeasuringInstrumentFormikConstants.name] && (
          <ErrorMessage>
            {t(formik?.errors?.[newMeasuringInstrumentFormikConstants.name])}
          </ErrorMessage>
        )}
    </SingleInputContainer>
  );
};

NameField.propTypes = {
  formik: PropTypes.object,
  isReadOnly: PropTypes.bool,
};

export default NameField;
