import {
    CLEAR_FINDING_TYPES,
    SET_FINDING_TYPES,
    SET_TOTAL_FINDING_TYPES,
    SINGLE_FINDING_TYPE_SET,
  } from "../../actions/findingTypes/findingTypesActionConstants";
  import createReducer from "../../utils/createReducer";
  
  const initialState = {
    findingTypes: {
      loaded: false,
      data: [],
    },
    totalFindingTypes: 0,
    singleFindingType: {},
  };
  
  export default createReducer(
    {
      [SET_FINDING_TYPES]: setFindingTypes,
      [SINGLE_FINDING_TYPE_SET]: setSingleFindingType,
      [SET_TOTAL_FINDING_TYPES]: setTotalFindingTypes,
      [CLEAR_FINDING_TYPES]: clearFindingTypes,
    },
    initialState
  );
  function setFindingTypes(state, { payload }) {
    return {
      ...state,
      findingTypes: payload,
    };
  }
  function setTotalFindingTypes(state, { payload }) {
    return {
      ...state,
      totalFindingTypes: payload,
    };
  }
  function clearFindingTypes() {
    return initialState;
  }
  function setSingleFindingType(state, { payload }) {
    return {
      ...state,
      singleFindingType: payload,
    };
  }
  