import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import TextInputField from "../../../Field/TextInputField/TextInputField";
const EmailField = (props) => {
  const formik = props?.formik;

  const { t } = useTranslation();

  return (
    <>
      <TextInputField
        name="email"
        label={t("users.email")}
        placeholder={t("users.newUserPlaceholders.email")}
        value={formik?.values?.email}
        onChange={formik?.handleChange}
        error={formik?.touched?.email && formik?.errors?.email}
        helperText={formik?.touched?.email && formik?.errors?.email}
        requiredField={props?.isRequired}
        disabledField={props?.isReadOnly}
        inputProps={{
          sx: {
            input: {
              paddingRight:
                formik?.errors?.email &&
                formik?.touched?.email &&
                "40px !important",
              width:
                formik?.errors?.email &&
                formik?.touched?.email &&
                "314px !important",
            },
          },
        }}
      />
    </>
  );
};

EmailField.propTypes = {
  formik: PropTypes.object,
  isRequired: PropTypes.bool,
  isReadOnly: PropTypes.bool,
};

export default EmailField;
