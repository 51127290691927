import React from "react";
import PropTypes from "prop-types";
import {
  LastChangesAuthor,
  LastChangesDate,
  LastChangesRendererContainer,
} from "./LastChangesRenderer.styled";

const LastChangesRenderer = (props) => {
  return (
    <LastChangesRendererContainer>
      <LastChangesDate>{props?.value?.date}</LastChangesDate>
      <LastChangesAuthor>{props?.value?.author || ""}</LastChangesAuthor>
    </LastChangesRendererContainer>
  );
};

LastChangesRenderer.propTypes = {
  value: PropTypes.any,
};

export default LastChangesRenderer;
