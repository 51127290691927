import React from "react";
// import PropTypes from 'prop-types'
import { ReactComponent as OrdinalNumberArrowSvg } from "../../../assets/images/svg/ordinal-number-arrow.svg";

const OrdinalNumberArrow = (props) => {
  return <OrdinalNumberArrowSvg {...props} />;
};

OrdinalNumberArrow.propTypes = {};

export default OrdinalNumberArrow;
