import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import { SingleColumnGroupInputContainer } from "./FindingTableGroupInput.styled";
import FindingTableSingleInput from "../FindingTableSingleInput/FindingTableSingleInput";

const FindingTableGroupInput = forwardRef((props, ref) => {
  return (
    <SingleColumnGroupInputContainer ref={ref}>
      {props?.groupInputs?.map?.((singleColumnInput, index) => {
        return (
          <FindingTableSingleInput
            disabled={props?.disabled}
            clickListener={props?.clickListener}
            questionId={props?.questionId}
            title={singleColumnInput?.columnTitle}
            columnId={singleColumnInput?.columnId}
            subtitle={singleColumnInput?.columnSubtitle}
            answerId={
              singleColumnInput?.answers?.find(
                (singleAnswer) => singleAnswer?.rowIndex === props?.rowCount - 1
              )?.id
            }
            rowCount={props?.rowCount}
            key={index}
            immediateApply={props?.immediateApply}
            immediateApplyFunction={props?.immediateApplyFunction}
          />
        );
      })}
    </SingleColumnGroupInputContainer>
  );
});

FindingTableGroupInput.displayName = "FindingTableGroupInput";

FindingTableGroupInput.propTypes = {
  groupInputs: PropTypes.array,
  questionId: PropTypes.number,
  rowCount: PropTypes.number,
  immediateApply: PropTypes.bool,
  disabled: PropTypes.bool,
  immediateApplyFunction: PropTypes.func,
  clickListener: PropTypes.func,
};

export default FindingTableGroupInput;
