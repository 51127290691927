import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { STUFFS_PAGE, USERS_PAGE } from "../../constants/pages";
import StuffsPage from "../StuffsPage/StuffsPage";
import UsersPage from "../UsersPage/UsersPage";

const PersonalPage = () => {
  return (
    <Switch>
      <Route path={USERS_PAGE} component={UsersPage} />
      <Route path={STUFFS_PAGE} component={StuffsPage} />
      <Redirect to={USERS_PAGE} />
    </Switch>
  );
};

export default PersonalPage;
