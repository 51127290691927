import { createSelector } from "reselect";

const appSelector = (state) => state.app;

export const selectIsOnline = createSelector(
  appSelector,
  (state) => state.isOnline
);
export const selectOpenOnlinePopover = createSelector(
  appSelector,
  (state) => state.openOnlinePopover
);
