import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { FindingsContentContainer } from "./FindingsContent.styled";
import Title from "../Typography/Title/Title";
import { useTranslation } from "react-i18next";
import FindingsTable from "./FindingsTable/FindingsTable";
import { useDispatch, useSelector } from "react-redux";
import { selectIsLoadingByActionType } from "../../store/selectors/loadingSelectors";
import { FINDINGS_SCOPE } from "../../store/actions/findings/findingsActionConstants";
import { fetchClients } from "../../store/actions/clients/clientsActions";
import { fetchStuffs } from "../../store/actions/stuffs/stuffsActions";
import {
  ASC_KEY,
  FIRST_NAME_KEY,
  NAME_KEY,
} from "../../constants/queryConstants";
import { fetchFindingTypes } from "../../store/actions/findingTypes/findingTypesActions";
import { fetchScanningObjects } from "../../store/actions/scanningObjects/scanningObjectsActions";

const FindingsContent = () => {
  const { t } = useTranslation();
  const isLoading = useSelector(selectIsLoadingByActionType(FINDINGS_SCOPE));
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      fetchClients({
        page: 1,
        size: 200,
        sorting: {
          column: NAME_KEY,
          sortDirection: ASC_KEY,
        },
      })
    );
    dispatch(
      fetchStuffs({
        page: 1,
        size: 200,
        sorting: {
          column: FIRST_NAME_KEY,
          sortDirection: ASC_KEY,
        },
      })
    );
    dispatch(fetchFindingTypes());
    dispatch(
      fetchScanningObjects({
        page: 1,
        size: 1000,
        sorting: {
          column: NAME_KEY,
          sortDirection: ASC_KEY,
        },
      })
    );
  }, []);

  return (
    <FindingsContentContainer>
      <Title>{t("findings.title")}</Title>
      <FindingsTable isLoading={isLoading} />
    </FindingsContentContainer>
  );
};

FindingsContent.propTypes = {
  children: PropTypes.node,
};

export default FindingsContent;
