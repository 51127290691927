import React from "react";
import PropTypes from "prop-types";
import DocumentTypesContent from "../../../components/DocumentTypes/DocumentTypesContent";

const DocumentTypesPage = () => {
  return <DocumentTypesContent />
};

DocumentTypesPage.propTypes = {
  children: PropTypes.node,
};

export default DocumentTypesPage;
