import { Box } from "@mui/material";
import styled from "styled-components";
import Select from "../../../../Select/Select";

export const StandardsSelectInput = styled(Select)`
  width: 100%;
  height: 48px;
  & * {
    max-height: 48px;
  }
`;
export const StandardInputsContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  margin-bottom: 11px;
`;

export const AddButton = styled(Box)`
  cursor: pointer;
  margin-left: 10px;
`;
