import { Box } from "@mui/material";
import styled, { css } from "styled-components";
import DoubleArrowIcon from "../../Icon/Icons/DoubleArrowIcon";

export const SidebarToggleButtonContainer = styled(Box)`
  width: 48px;
  height: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 12px;
  border-radius: 12px;
  cursor: pointer;
  ${props => props?.$isOpened && css`
    position: absolute;
    bottom: 16px;
    right: 12px;
  `}
`;

export const ToggleIcon = styled(DoubleArrowIcon)`
    ${props => props?.$isOpened && css`
        transform: rotate(180deg);
    `}
`  
