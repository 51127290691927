import React, { useEffect } from "react";
import PropTypes from "prop-types";
import AutocompleteField from "../../../Field/AutocompleteField/AutocompleteField";
import { useSelector } from "react-redux";
import { selectDocumentTypesData } from "../../../../store/selectors/documentTypesSelectors";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";

const DocumentArchivingInfo = (props) => {
  const documentTypes = useSelector(selectDocumentTypesData);
  const { t } = useTranslation();
  const formik = props?.formik;

  const disabled = useMemo(
    () =>
      !formik?.values?.automaticallyUploadToDrive ||
      !formik?.values?.driveFolderId ||
      !formik?.values?.hasCredentialsFile,
    [
      formik?.values?.automaticallyUploadToDrive,
      formik?.values?.driveFolderId,
      formik?.values?.hasCredentialsFile,
    ]
  );

  useEffect(() => {
    if (disabled) formik?.setFieldValue?.("documentTypeIdForArchiving", null);
  }, [
    formik?.values?.automaticallyUploadToDrive,
    formik?.values?.driveFolderId,
    formik?.values?.hasCredentialsFile,
  ]);

  const value = useMemo(() => {
    let documentTypeToReturn = documentTypes.find(
      (documentType) =>
        documentType?.id == formik?.values?.documentTypeIdForArchiving
    );
    return documentTypeToReturn;
  }, [formik?.values, documentTypes]);

  const handleChange = (value) => {
    formik?.setFieldValue?.("documentTypeIdForArchiving", value?.id);
  };
  return (
    <AutocompleteField
      options={documentTypes}
      label={
        disabled ? "" : t("companySettings.archive.documentType.placeholder")
      }
      disabled={disabled}
      title={t("companySettings.archive.documentType.label")}
      onChange={handleChange}
      dropdownLabel={["caption"]}
      value={value}
    />
  );
};

DocumentArchivingInfo.propTypes = {
  disabled: PropTypes.bool,
  formik: PropTypes.object,
};

export default DocumentArchivingInfo;
