import * as Yup from "yup";

export default () =>
  Yup.object().shape({
    caption: Yup.string()
      .trim()
      .max(255, "documentTypes.modal.captionNoOfChars")
      .required("documentTypes.modal.captionRequired"),
    description: Yup.string()
      .trim()
      .max(255, "documentTypes.modal.descriptionNoOfChars")
      .required("documentTypes.modal.descriptionRequired"),
  });
