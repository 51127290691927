import React from "react";
import { ReactComponent as BaseBreadIconSvg } from "../../../assets/images/svg/home-bc.svg";

const BaseBreadIcon = (props) => {
  return <BaseBreadIconSvg {...props} />;
};

BaseBreadIcon.propTypes = {};

export default BaseBreadIcon;
