import * as Yup from "yup";
import { documentFormikConstants } from "../initialValues/documentInitialValues";

export default () =>
  Yup.object().shape({
    [documentFormikConstants.clientId]: Yup.number()
      .typeError("documents.newPage.form.client.required")
      .test(
        "required-check",
        "documents.newPage.form.client.required",
        (value) => typeof value === "number" && value > 0
      ),
    [documentFormikConstants.documentTypeId]: Yup.number()
      .typeError("documents.newPage.form.documentType.required")
      .test(
        "required-check",
        "documents.newPage.form.documentType.required",
        (value) => typeof value === "number" && value > 0
      ),
    [documentFormikConstants.documentNumber]: Yup.string().test(
      "required-check",
      "documents.newPage.form.documentNumber.required",
      (value) => value?.length > 0
    ),
    [documentFormikConstants.link]: Yup.string().test(
      "required-check",
      "documents.newPage.form.link.required",
      (value) => value?.length > 0
    ),
    [documentFormikConstants.documentDate]: Yup.string().test(
      "required-check",
      "documents.newPage.form.documentDate.required",
      (value) => value?.length > 0
    ),
  });
