import { Box, FormGroup, Typography } from "@mui/material";
import styled, { css } from "styled-components";
import selectedTheme from "../../../themes";
import { hexToRGB } from "../../../util/helpers/colorHelper";
import Icon from "../../Icon/Icon";

export const LoginFormContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 20px;
  width: 100%;
  margin-top: 40px;
`;
export const InputContainer = styled(Box)`
  width: 100%;
  /* min-height: 92px; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
  flex-wrap: wrap;
  padding: 0px;
  position: relative;
`;
export const InputLabel = styled(Typography)`
  font-family: ${selectedTheme.fonts.mainText};
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  font-feature-settings: "salt" on;
  margin-bottom: 6px;
  color: ${selectedTheme.colors.titleColor};
`;
export const InputField = styled.input`
  padding: 10px 12px;
  background: white;
  width: 100%;
  border: 1px solid ${selectedTheme.colors.inputBorder};
  box-shadow: 0px 1px 2px ${hexToRGB(selectedTheme.colors.shadowColor, 0.05)};
  border-radius: 6px;
  ${(props) =>
    props?.disabled &&
    css`
      background-color: ${selectedTheme.colors.disabledFieldBackground};
      color: ${selectedTheme.colors.disabledFieldText};
    `}
  padding-right: 40px;
`;
export const ErrorMessage = styled(Box)`
  font-family: "Inter";
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  font-feature-settings: "salt" on;
  color: ${selectedTheme.colors.errorMessage};
  margin-top: 10px;
  min-height: 20px;
`;
// export const InputRightImage = styled(Box)`
//   position: absolute;
//   right: 0;
//   top: 0;
//   bottom: 0;
//   margin-top: auto;
//   margin-bottom: auto;
// `
export const CheckboxContainer = styled(FormGroup)`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: end;
  margin-bottom: 20px;
  & span {
    padding-top: 0;
    padding-bottom: 0;
  }
`;
export const LoginButton = styled(Box)`
  cursor: pointer;
  width: 100%;
  background: ${selectedTheme.colors.loginButtonColor1}; //${selectedTheme.colors.loginButtonColor};
  color: white;
  font-family: "Inter";
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  font-feature-settings: "salt" on;
  text-align: center;
  border-radius: 10px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
`;
export const InputRightElement = styled(Icon)`
  margin-top: auto;
  margin-bottom: auto;
  cursor: pointer;
  width: 20px;
  height: 20px;
  position: absolute;
  top: ${(props) => (props?.isLogin ? "33px" : "8.5px")};
  right: 5.5px;
  & svg {
    width: 20px;
    height: 20px;
  }
`;
