import {
  GET_MAIL_INSTRUCTIONS_ERROR,
  GET_MAIL_INSTRUCTIONS_FETCH,
  GET_MAIL_INSTRUCTIONS_SUCCESS,
  GET_GDRIVE_INSTRUCTIONS_ERROR,
  GET_GDRIVE_INSTRUCTIONS_FETCH,
  GET_GDRIVE_INSTRUCTIONS_SUCCESS,
} from "./instructionsActionConstants";

export const fetchMailInstructions = (payload) => ({
  type: GET_MAIL_INSTRUCTIONS_FETCH,
  payload,
});
export const fetchMailInstructionsSuccess = (payload) => ({
  type: GET_MAIL_INSTRUCTIONS_SUCCESS,
  payload,
});

export const fetchMailInstructionsError = (payload) => ({
  type: GET_MAIL_INSTRUCTIONS_ERROR,
  payload,
});

export const fetchGDriveInstructions = (payload) => ({
  type: GET_GDRIVE_INSTRUCTIONS_FETCH,
  payload,
});
export const fetchGDriveInstructionsSuccess = (payload) => ({
  type: GET_GDRIVE_INSTRUCTIONS_SUCCESS,
  payload,
});

export const fetchGDriveInstructionsError = (payload) => ({
  type: GET_GDRIVE_INSTRUCTIONS_ERROR,
  payload,
});
