import React from "react";
// import PropTypes from 'prop-types'
import { ReactComponent as PersonalIconSvg } from "../../../assets/images/svg/personal.svg";

const PersonalIcon = (props) => {
  return <PersonalIconSvg {...props} />;
};

PersonalIcon.propTypes = {};

export default PersonalIcon;
