import {
  CLEAR_COMPANIES,
  MY_COMPANY_SET,
  SET_COMPANIES,
  SET_COMPANY_FROM_FORMIK,
  SET_COMPANY_SAVING_DISABLED,
  SET_IS_COMPANY_ACTIVATED,
  SET_TOTAL_COMPANIES,
  SINGLE_COMPANY_SET,
} from "../../actions/companies/companiesActionConstants";
import createReducer from "../../utils/createReducer";

const initialState = {
  companies: {
    loaded: false,
    data: [],
  },
  totalCompanies: 0,
  singleCompany: {},
  myCompany: {},
  isSavingDisabled: true,
};

export default createReducer(
  {
    [SET_COMPANIES]: setCompanies,
    [SINGLE_COMPANY_SET]: setSingleCompany,
    [SET_COMPANY_FROM_FORMIK]: setCompanyFromFormik,
    [MY_COMPANY_SET]: setMyCompany,
    [SET_TOTAL_COMPANIES]: setTotalCompanies,
    [CLEAR_COMPANIES]: clearCompanies,
    [SET_IS_COMPANY_ACTIVATED]: setIsCompanyActivated,
    [SET_COMPANY_SAVING_DISABLED]: setCompanySavingDisabled,
  },
  initialState
);

function setCompanyFromFormik(state, { payload }) {
  let companyData = {
    ...state?.singleCompany,
    name: payload?.companyName,
    tin: String(payload?.companyPIB),
    identificationNumber: String(payload?.identificationNumber),
    address: payload?.address,
    directorInfo: payload?.companyDirector,
    bankAccount: payload?.bankAccountNumber,
    phoneNumber: payload?.phoneNumber,
    email: payload?.email,
    logoUrl: payload?.logo,
    stampUrl: payload?.stamp,
    directorSignatureUrl: payload?.signature,
    licenseUrl: payload?.license,
    aprImages: payload?.APR,
    distributionEmails: payload?.distributionEmails.join(";"),
    userNameForMailService: payload?.userName,
    passwordForMailService: payload?.password ? payload?.password : null,
    hostForMailService: payload?.host,
    portForMailService: payload?.port?.length !== 0 ? payload?.port : 0,
    enableSSlForMailService: payload?.enableSSl,
    automaticallySendEmails: payload?.automaticallySendEmails,
    automaticallyUploadToDrive: payload?.automaticallyUploadToDrive,
    driveFolderId: payload?.driveFolderId,
    hasCredentialsFile: payload?.hasCredentialsFile,
    hasEmailPassword: payload?.hasEmailPassword,
    dirty: false,
  };
  return {
    ...state,
    singleCompany: { ...companyData },
  };
}
function setCompanies(state, { payload }) {
  return {
    ...state,
    companies: payload,
  };
}
function setCompanySavingDisabled(state, { payload }) {
  return {
    ...state,
    isSavingDisabled: payload,
  };
}
function setIsCompanyActivated(state, { payload }) {
  return {
    ...state,
    singleCompany: {
      ...state?.singleCompany,
      deactivatedAtUtc: payload ? null : new Date().toISOString(),
    },
  };
}
function setTotalCompanies(state, { payload }) {
  return {
    ...state,
    totalCompanies: payload,
  };
}
function clearCompanies() {
  return initialState;
}
function setSingleCompany(state, { payload }) {
  return {
    ...state,
    singleCompany: payload,
  };
}
function setMyCompany(state, { payload }) {
  return {
    ...state,
    myCompany: payload,
  };
}
