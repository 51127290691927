import { Box } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../themes";

export const HeaderProfile = styled(Box)`
  cursor: pointer;
  background: ${selectedTheme.colors.profilePictureBackground};
  width: 40px;
  height: 40px;
  border-radius: 100%;
  line-height: 14px;
  font-size: 14px;
  font-weight: 500;
  font-family: ${selectedTheme.fonts.mainText};
  color: ${selectedTheme.colors.profilePictureText};
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`;
