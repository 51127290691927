import { Box } from "@mui/material";
import styled from "styled-components";
import Icon from "../../../../Icon/Icon";
import Link from "../../../../Link/Link";
import selectedTheme from "../../../../../themes";

export const ActionRendererContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: ${(props) => (props?.$mobile ? "12px" : "17px")};
  @media (max-width: 600px) {
    position: absolute;
    top: 17px;
    right: 15px;
  }
`;
export const ActionRendererChangePasswordIconContainer = styled(Icon)`
  cursor: pointer;
`;
export const ActionRendererEditProfileIconContainer = styled(Link)`
  cursor: pointer;
`;

export const ActionRendererDeleteProfileIconContainer = styled(Icon)`
  cursor: pointer;
`;
export const ActionRendererResendInviteIconContainer = styled(Icon)`
  cursor: pointer;
  & svg {
    width: 18px;
    height: 18px;
  }
  & g {
    fill: ${selectedTheme.colors.iconColor};
  }
`;
