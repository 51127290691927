import React from "react";
// import PropTypes from 'prop-types'
import { ReactComponent as DoubleArrowIconSvg } from "../../../assets/images/svg/double-arrow.svg";

const DoubleArrowIcon = (props) => {
  return <DoubleArrowIconSvg {...props} />;
};

DoubleArrowIcon.propTypes = {};

export default DoubleArrowIcon;
