import React from "react";
import PropTypes from "prop-types";
import {
  FindingStatusCardContainer,
  StatusCircle,
} from "./FindingStatusCard.styled";
import { useMemo } from "react";
import { getObjectProperties } from "../../../util/helpers/objectHelpers";

const FindingStatusCard = (props) => {
  const containerProps = useMemo(() => {
    const containerPropsToReturn = { ...props };
    if (
      getObjectProperties(containerPropsToReturn).includes(
        "statusBackgroundColor"
      )
    )
      delete containerPropsToReturn.statusBackgroundColor;
    if (getObjectProperties(containerPropsToReturn).includes("statusTextColor"))
      delete containerPropsToReturn.statusTextColor;
    if (getObjectProperties(containerPropsToReturn).includes("borderWidth"))
      delete containerPropsToReturn.borderWidth;
    if (getObjectProperties(containerPropsToReturn).includes("borderColor"))
      delete containerPropsToReturn.borderColor;
    if (getObjectProperties(containerPropsToReturn).includes("circleColor"))
      delete containerPropsToReturn.circleColor;
    if (
      getObjectProperties(containerPropsToReturn).includes("circleBorderColor")
    )
      delete containerPropsToReturn.circleBorderColor;
    return containerPropsToReturn;
  }, [props]);

  return (
    <FindingStatusCardContainer
      $statusBackgroundColor={props?.statusBackgroundColor}
      $statusColor={props?.statusTextColor}
      $borderWidth={props?.borderWidth}
      $borderColor={props?.borderColor}
      {...containerProps}
    >
      <StatusCircle
        $statusColor={props?.circleColor}
        $circleBorderColor={props?.circleBorderColor}
      />
      {props?.value}
    </FindingStatusCardContainer>
  );
};

FindingStatusCard.propTypes = {
  value: PropTypes.string,
  statusBackgroundColor: PropTypes.string,
  statusTextColor: PropTypes.string,
  borderWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  borderColor: PropTypes.string,
  circleColor: PropTypes.string,
  circleBorderColor: PropTypes.string,
};

export default FindingStatusCard;
