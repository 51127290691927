import LastChangesRenderer from "../../components/Clients/ClientsTable/Renderers/LastChangesRenderer/LastChangesRenderer";
import TypeRenderer from "../../components/Findings/FindingsTable/Renderers/TypeRenderer/TypeRenderer";
import ActionRenderer from "../../components/ScanningObjects/ScanningObjectsTable/Renderers/ActionRenderer";
import UserRenderer from "../../components/Users/UsersTable/ActiveUsersTable/Renderers/UserRenderer/UserRenderer";

export default {
  name: {
    id: 0,
    i18key: "scanningObjects.table.name.columnTitle",
    renderer: UserRenderer,
    redirect: true,
    backendProperty: "name",
    style: {
      //   flex: 1,
      minWidth: "calc(100% /4)",
    },
  },
  header: {
    id: 8,
    i18key: "scanningObjects.table.name.columnTitle",
    mobile: true,
    disableSort: true,
    style: {},
  },
  pattern: {
    id: 1,
    i18key: "scanningObjects.table.pattern.columnTitle",
    style: {
      paddingRight: "24px",
      justifyContent: "center",
      textAlign: "center",
      minWidth: "calc(100% /6)",
      //   flex: 1,
    },
  },
  findingType: {
    id: 2,
    i18key: "findings.table.findingType.columnTitle",
    renderer: TypeRenderer,
    disableSort: true,
    style: {
      paddingRight: "0",
      minWidth: "calc(100% /6)",
    },
    backendProperty: "findingType",
  },
  lastChanges: {
    id: 3,
    backendProperty: "updatedAtUtc",
    i18key: "scanningObjects.table.lastChanges.columnTitle",
    renderer: LastChangesRenderer,
    style: {
      paddingRight: "24px",
      justifyContent: "center",
      textAlign: "center",
      minWidth: "calc(100% /6)",
      //   flex: 1,
    },
  },
  actions: {
    id: 3,
    i18key: "",
    renderer: ActionRenderer,
    disableSort: true,
    style: {
      paddingRight: "24px",
      justifyContent: "center",
      textAlign: "center",
      minWidth: "152px",
      maxWidth: "152px",
    },
  },
};
