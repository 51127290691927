import React from "react";
import PropTypes from "prop-types";
import { Popover } from "@mui/material";

const PopoverComponent = (props) => {
  const { open, anchorEl, onClose, content, className, anchorRight } = props
  const handleClose = () => {
    onClose();
  };

  return (
    <Popover
      className={className}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: anchorRight ? "top" : "bottom",
        horizontal: "left",
      }}
      PaperProps={{
        id: "popover-content",
        style: {
          overflow: "visible",
          overflowX: "visible",
          overflowY: "visible",
        }
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: anchorRight ? "right" : "center"
      }}
      {...props}
    >
      {content}
    </Popover>
  );
};

PopoverComponent.propTypes = {
  anchorEl: PropTypes.object,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  content: PropTypes.any,
  className: PropTypes.string,
  anchorRight: PropTypes.bool,
};
PopoverComponent.defaultProps = {
  anchorRight: false,
}

export default PopoverComponent;
