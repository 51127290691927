import { Grid } from "@mui/material";
import styled from "styled-components";

export const LoginLayoutContainer = styled(Grid)`
  display: flex;
  min-height: 100%;
  //min-width: 100%;
  align-content: center;
  margin-top: -25px;
  max-width: 1200px;
  padding: 0 20px; 
  //box-sizing: border-box; 
  margin-left: auto; 
  margin-right: auto;
  @media (max-width: 600px) {
    align-content: flex-start;
  }
`;
export const LeftImage = styled(Grid)`
  width: 80%;
  display:flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 24px;
  @media (max-width: 600px) {
    display: none;
`;

export const Content = styled(Grid)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
