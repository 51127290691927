import { Box } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../themes";

export const HorizontalSeparatorContainer = styled(Box)`
  width: ${(props) => `calc(100% - ${props?.$widthMargin * 2}px)`};
  height: 1px;
  min-height: 1px;
  background-color: ${selectedTheme.colors.questionSeparator};
  align-self: center;
`;
