import { createSelector } from "reselect";

const commonTemplatesSelector = (state) => state.commonTemplates;
const languagesSelector = (state) => state.commonTemplates;

export const selectCommonTemplates = createSelector(
  commonTemplatesSelector,
  (state) => state.commonTemplates
);
export const selectTotalCommonTemplates = createSelector(
  commonTemplatesSelector,
  (state) => state.totalCommonTemplates
);
export const selectSingleCommonTemplate = createSelector(
  commonTemplatesSelector,
  (state) => state.singleCommonTemplate
);
export const selectCommonTemplatesData = createSelector(
  commonTemplatesSelector,
  (state) => state.commonTemplates.data
);
export const selectCommonTemplatesError = createSelector(
  commonTemplatesSelector,
  (state) => state.errorMessage
);

export const selectCommonTemplatePreview = createSelector(
  commonTemplatesSelector,
  (state) => state.preview
);

export const selectLanguages = createSelector(
  languagesSelector,
  (state) => state.languagesCultural
);
