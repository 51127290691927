import React from "react";
import PropTypes from "prop-types";
import TextInputField from "../../../Field/TextInputField/TextInputField";
import { useTranslation } from "react-i18next";

const NameField = (props) => {
  const { t } = useTranslation();
  const formik = props?.formik;
  return (
    <TextInputField
      name="name"
      requiredField={formik?.values?.name?.length === 0}
      label={t("findingTypes.modal.name")}
      onChange={formik?.handleChange}
      disabledField={props?.disabled}
      onBlur={formik?.handleBlur}
      error={formik?.touched?.name && formik?.errors?.name}
      value={formik?.values?.name}
    />
  );
};

NameField.propTypes = {
  children: PropTypes.node,
  formik: PropTypes.object,
  disabled: PropTypes.bool,
};

export default NameField;
