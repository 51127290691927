import { Box } from "@mui/material";
import styled, { css } from "styled-components";
import selectedTheme from "../../../../../themes";
import DragIcon from "../../../../Icon/Icons/DragIcon";
import { TrashIcon } from "../../../../ImagePicker/ImagePreview/ImagePreview.styled";
import TextInputField from "../../../../Field/TextInputField/TextInputField";
import { ExpandMore } from "@mui/icons-material";
import CopyIcon from "../../../../Icon/Icons/CopyIcon";

export const FindingTableSingleRowContainer = styled(Box)`
  border-radius: 8px;
  border: 1px solid ${selectedTheme.colors.inputBorder};
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: ${(props) => (props?.$isExpanded ? "12px" : 0)};
  transition-duration: 0.3s;
  @media (max-width: 600px) {
    padding: ${(props) => !props?.$isExpanded && "13px 16px"};
  }
`;
export const FindingTableSingleRowHeaderOptions = styled(Box)`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;
export const DNDIcon = styled(DragIcon)`
  width: 11px;
  height: 24px;
  cursor: pointer;
`;
export const RemoveRowIcon = styled(TrashIcon)`
  width: 24px;
  cursor: pointer;
  height: 24px;
`;
export const RowCount = styled(TextInputField)`
  max-width: 76px;
  margin-top: 0;
  & * {
    max-height: 44px;
    max-width: 76px;
  }
  & input {
    padding: 10px 12px;
  }
`;

export const CopyRowIcon = styled(CopyIcon)`
  cursor: pointer;
`

export const ExpandIcon = styled(ExpandMore)`
  ${(props) =>
    !props?.$isExpanded &&
    css`
      transform: rotate(180deg);
    `}
`;
export const FindingTableInputsContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 12px;
  @media (max-width: 600px) {
    height: ${(props) => (props?.$isExpanded ? `${props?.$inputsHeight}px` : 0)};
    opacity: ${(props) => (props?.$isExpanded ? 1 : 0)};
    overflow: hidden;
    transition-duration: 0.3s;
    /* gap: 12px; */
  }
`;
export const SingleRowLeftContainer = styled(Box)`
  @media (max-width: 600px) {
    display: flex;
    align-items: center;
    gap: 16px;
  }
`;
export const MiniRowCount = styled(Box)`
  &::after {
    color: ${selectedTheme.colors.labelTextColor};
    font-feature-settings: "dlig" on, "salt" on;
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    content: "${(props) => props?.$rowCount}.";
    opacity: ${(props) => (props?.$isExpanded ? 0 : 1)};
    transition-duration: 0.3s;
  }
`;
export const RemoveRowContainer = styled(Box)`
  display: none;
  @media (max-width: 600px) {
    display: flex;
    align-self: flex-end;
    margin-top: 16px;
    gap: 10px;
  }
`
export const HeaderRowIconsContainer = styled(RemoveRowContainer)`
  display: flex;
  align-items: center;
  gap: 10px;
`
