import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import PropTypes from "prop-types";
import ManualTable from "../../../../ManualTable/ManualTable";
import { useTranslation } from "react-i18next";
import {
  clearSingleScanningObject,
  fetchScanningObjects,
  fetchSingleScanningObject,
} from "../../../../../store/actions/scanningObjects/scanningObjectsActions";
import {
  selectScanningObjectsData,
  selectSingleScanningObject,
} from "../../../../../store/selectors/scanningObjectsSelectors";
import findingScanningObjectsTableConstants from "../../../../../constants/tableConstants/findingTables/findingScanningObjectsTableConstants";
import TextRenderer from "../../../../Table/Renderers/TextRenderer/TextRenderer";
import { FindingScanningObjectsContainer } from "./FindingScanningObjects.styled";
import { useDispatch, useSelector } from "react-redux";
import {
  addFindingScanningSubject,
  removeFindingScanningSubject,
  setFindingsChanged,
} from "../../../../../store/actions/findings/findingsActions";
import {
  selectFindingSubjectsAll,
  selectIsFindingSettingsChanged,
} from "../../../../../store/selectors/findingsSelectors";

const FindingScanningObjects = forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [selectedScanningObjects, setSelectedScanningObjects] = useState([]);
  const singleSubject = useSelector(selectSingleScanningObject);
  const isFindingChanged = useSelector(selectIsFindingSettingsChanged);
  const findingSubjects = useSelector(selectFindingSubjectsAll);

  useImperativeHandle(ref, () => ({
    data: selectedScanningObjects,
    setData: setSelectedScanningObjects,
  }));

  useEffect(() => {
    return () => dispatch(clearSingleScanningObject());
  }, []);

  useEffect(() => {
    if (singleSubject?.id) {
      if (!isFindingChanged) dispatch(setFindingsChanged(true));
      let dummyFindingSubjectId = Math.random() * new Date().getMilliseconds();
      dispatch(
        addFindingScanningSubject({
          conclusion: {
            value: null,
            edited: false,
          },
          added: true,
          scanningSubjectId: singleSubject?.id,
          id: dummyFindingSubjectId,
          positiveConclusionTemplate: singleSubject?.checklistTemplates?.find?.(
            (singleTemplate) => singleTemplate?.isDefault
          )?.positiveConclusionTemplate,
          negativeConclusionTemplate: singleSubject?.checklistTemplates?.find?.(
            (singleTemplate) => singleTemplate?.isDefault
          )?.negativeConclusionTemplate,
          orderNumber: findingSubjects?.length,
          scanningSubject: {
            id: singleSubject?.id,
            name: singleSubject?.name,
          },
          standards: singleSubject?.checklistTemplates?.find?.(
            (singleTemplate) => singleTemplate?.isDefault
          )?.standards,
          measuringInstruments: singleSubject?.checklistTemplates?.find?.(
            (singleTemplate) => singleTemplate?.isDefault
          )?.measuringInstruments,
          sections:
            singleSubject?.checklistTemplates
              ?.find?.((singleTemplate) => singleTemplate?.isDefault)
              ?.sections?.map?.((singleSection) => ({
                id: singleSection?.id * Math.random(),
                title: singleSection?.title,
                note: {
                  value: null,
                  edited: false,
                },
                findingSubjectId: dummyFindingSubjectId,
                items:
                  singleSection?.questions?.map?.((singleQuestion) => ({
                    id: Math.random(),
                    isMandatory: singleQuestion?.isRequired,
                    itemTypeId: singleQuestion?.itemTypeId,
                    question: singleQuestion?.questionName,
                    note: singleQuestion?.note,
                    isRateInRange: singleQuestion?.hasResearchPassed,
                    isInRange: {
                      value: false,
                      edited: false,
                    },
                    images: {
                      value: [],
                      edited: false,
                    },
                    answer: {
                      value:
                        singleQuestion?.defaultAnswer ||
                        singleQuestion?.textTypeInputs?.defaultAnswer ||
                        singleQuestion?.selectTypeInputs?.possibleAnswers?.[
                          singleQuestion?.selectTypeInputs?.defaultAnswerIndex
                        ] ||
                        "",
                      edited: false,
                    },
                    options:
                      singleQuestion?.selectTypeInputs?.possibleAnswers?.map?.(
                        (singleAnswer) => ({
                          answer: singleAnswer,
                          id: Math.random(),
                        })
                      ),
                  })) || [],
              })) || [],
        })
      );
    }
  }, [singleSubject]);

  const tableColumns = useMemo(() => {
    return Object.keys(findingScanningObjectsTableConstants).map((property) => {
      if (property === "delete" && props?.disabled) return;
      return {
        ...findingScanningObjectsTableConstants[property],
        field: t(findingScanningObjectsTableConstants[property].i18key),
        propertyName: property,
        backendProperty:
          findingScanningObjectsTableConstants[property]?.backendProperty ||
          property,
        style: findingScanningObjectsTableConstants[property]?.style,
        renderer:
          findingScanningObjectsTableConstants[property]?.renderer ||
          TextRenderer,
      };
    });
  }, [findingScanningObjectsTableConstants, t]);

  const mapDataFunction = useCallback(
    (newData) => {
      return newData?.map?.((singleData, dataIndex) => {
        let mappedObject = {
          name: singleData?.name,
          delete: {
            id: singleData?.id,
            name: singleData?.name,
            index: dataIndex,
          },
        };
        if (props?.disabled) delete mappedObject?.delete;
        return mappedObject;
      });
    },
    [props?.disabled]
  );

  const handleAddSubject = (entity) => {
    if (props?.isEditing) {
      dispatch(
        fetchSingleScanningObject({
          scanningObjectId: entity?.id,
        })
      );
    }
  };

  const handleRemoveSubject = (entity) => {
    if (props?.isEditing) {
      dispatch(removeFindingScanningSubject(entity?.id));
    }
  };

  return (
    <FindingScanningObjectsContainer>
      <ManualTable
        disabled={props?.disabled}
        fetchedData
        availableMultipleEntities
        key={selectedScanningObjects}
        onAddEntity={handleAddSubject}
        onRemoveEntity={handleRemoveSubject}
        setSelectedData={setSelectedScanningObjects}
        selectedData={selectedScanningObjects}
        title={t("scanningObjects.title")}
        addEntityLabel={t("scanningObjects.addScanningObject")}
        selectPlaceholder={t("scanningObjects.chooseScanningObject")}
        fetchEntitiesDispatchFunction={fetchScanningObjects}
        entityDataSelector={selectScanningObjectsData}
        mapDataFunction={mapDataFunction}
        tableColumns={tableColumns}
        namePropertyOfEntity={tableColumns[0]?.propertyName}
        noActions={false}
      />
    </FindingScanningObjectsContainer>
  );
});

FindingScanningObjects.displayName = "FindingScanningObjects";

FindingScanningObjects.propTypes = {
  children: PropTypes.node,
  disabled: PropTypes.bool,
  isEditing: PropTypes.bool,
};

export default FindingScanningObjects;
