import LastChangesRenderer from "../../components/Clients/ClientsTable/Renderers/LastChangesRenderer/LastChangesRenderer";
import RedirectModalRenderer from "../../components/MeasuringInstruments/MeasuringInstrumentsTable/Renderers/RedirectModalRenderer/RedirectModalRenderer";
import ActionRenderer from "../../components/Standards/StandardsTable/Renderers/ActionRenderer/ActionRenderer";
import IsDefaultRenderer from "../../components/Standards/StandardsTable/Renderers/ActionRenderer/IsDefaultRenderer/IsDefaultRenderer";
import FindingTypesRenderer from "../../components/Standards/StandardsTable/Renderers/FindingTypesRenderer/FindingTypesRenderer";

export default {
  caption: {
    id: 0,
    i18key: "standards.table.caption.columnTitle",
    renderer: RedirectModalRenderer,
    redirect: true,
    style: {
      flex: 2,
    },
    backendProperty: "caption",
  },
  header: {
    id: 8,
    i18key: "standards.table.caption.columnTitle",
    mobile: true,
    disableSort: true,
    style: {},
  },
  isDefault: {
    id: 1,
    i18key: "standards.table.isDefault.columnTitle",
    renderer: IsDefaultRenderer,
    style: {
      minWidth: "217px",
      maxWidth: "217px",
      textAlign: "center",
      justifyContent: "center",
      paddingLeft: 0,
      paddingRight: 0,
    },
    backendProperty: "isDefault",
  },
  findingTypes: {
    id: 2,
    i18key: "standards.table.findingTypes.columnTitle",
    renderer: FindingTypesRenderer,
    style: {
      flex: 2,
    },
    backendProperty: "findingTypes",
    disableSort: true,
  },
  lastChanges: {
    id: 3,
    i18key: "standards.table.lastChanges.columnTitle",
    renderer: LastChangesRenderer,
    style: {
      textAlign: "center",
      justifyContent: "center",
      flex: 1,
    },
    backendProperty: "updatedAtUtc",
  },
  actions: {
    id: 4,
    i18key: "",
    renderer: ActionRenderer,
    disableSort: true,
    style: {
      minWidth: "132px",
      maxWidth: "132px",
      paddingRight: "24px",
      justifyContent: "center",
      textAlign: "center",
    },
  },
};
