import styled from "styled-components";
import { Box, Typography } from "@mui/material";
import PrimaryButton from "../../../Buttons/PrimaryButton/PrimaryButton";
import PlusIcon from "../../../Icon/Icons/PlusIcon";
import selectedTheme from "../../../../themes";

export const DistributionMailsLabel = styled(Typography)`
  font-family: ${selectedTheme.fonts.mainText};
  font-size: 14px;
  font-weight: 600;
  margin-top: 32px;
  margin-bottom: 8px;
  line-height: 20px;
  color: ${selectedTheme.colors.labelTextColor};
`;

export const DistributionMailsContainer = styled(Box)`
  display: flex;
  flex-direction: column;
`;

export const SingleDistributionMailContainer = styled(Box)`
  &:has(p) {
    margin-bottom: 12px;
  }
`;

export const ErrorMessage = styled(Typography)`
  font-family: ${selectedTheme.fonts.mainText};
  font-size: 12px;
  font-weight: 400;
  color: ${selectedTheme.colors.errorColor};
  margin-top: 0;
`;

export const NewEmailButton = styled(PrimaryButton)`
  text-align: right;
  background-color: white;
  display: flex;
  justify-content: flex-end;
  & > button {
    background-color: white;
    color: ${selectedTheme.colors.labelTextColor};
    display: flex;
    align-items: center;
    gap: 8px;
  }
`;

export const Plus = styled(PlusIcon)`
  path {
    stroke: ${selectedTheme.colors.labelTextColor};
  }
`;
