import React from "react";
import PropTypes from "prop-types";
import {
  TOAST_ERROR,
  TOAST_SUCCESS,
  TOAST_WARNING,
} from "../../constants/toastConstants";
import {
  CloseButton,
  IconContainer,
  ToastComponentContainer,
  ToastComponentDescription,
  ToastComponentTitle,
} from "./ToastComponent.styled";
import ErrorIcon from "../Icon/Icons/ErrorIcon";
import TickCircledIcon from "../Icon/Icons/TickCircledIcon";

const ToastComponent = (props) => {
  return (
    <ToastComponentContainer type={props?.type}>
      <IconContainer type={props?.type}>
        {props?.type === TOAST_SUCCESS ? (
          <TickCircledIcon />
        ) : TOAST_ERROR ? (
          <ErrorIcon />
        ) : (
          <></>
        )}
      </IconContainer>
      <CloseButton />
      <ToastComponentTitle type={props?.type}>
        {props?.title}
      </ToastComponentTitle>
      <ToastComponentDescription pre={props?.pre}>
        {props?.description}
      </ToastComponentDescription>
    </ToastComponentContainer>
  );
};

ToastComponent.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  type: PropTypes.oneOf([TOAST_SUCCESS, TOAST_ERROR, TOAST_WARNING]),
  pre: PropTypes.bool,
};

export default ToastComponent;
