import React from "react";
import PropTypes from "prop-types";
import {
  ActionRendererContainer,
  ActionRendererDeleteScanningObjectIconContainer,
  ActionRendererEditScanningObjectIconContainer,
} from "./ActionRenderer.styled";
import EditIcon from "../../../Icon/Icons/EditIcon";
import history from "../../../../store/utils/history";
import { replaceInRoute } from "../../../../util/helpers/routeHelpers";
import { EDIT_SCANNING_OBJECTS_PAGE } from "../../../../constants/pages";
import { fetchDeleteScanningObject } from "../../../../store/actions/scanningObjects/scanningObjectsActions";
import {
  closeModal,
  toggleSetDeleteModal,
  toggleSetLoadingModal,
} from "../../../../store/actions/modal/modalActions";
import i18n from "../../../../i18n";
import { APPLICATION_TOAST_CONTAINER_ID } from "../../../../constants/toastConstants";
import { makeToastMessage } from "../../../../store/utils/makeToastMessage";
import Tooltip from "../../../Tooltip/Tooltip";
import DeleteIcon from "../../../Icon/Icons/DeleteIcon";
import WithPermissions from "../../../WithPermissions/WithPermissions";
import { MANAGER_ROLE } from "../../../../constants/rolesConstants";

const ActionRenderer = (props) => {
  const handleEditScanningObject = () => {
    history.push(
      replaceInRoute(EDIT_SCANNING_OBJECTS_PAGE, {
        scanningObjectId: props?.value?.id,
      })
    );
  };

  const handleApiResponseSuccessDelete = () => {
    props?.dispatch(closeModal());
    makeToastMessage(
      {
        title: i18n.t("toast.success.deletedTitle", {
          typeOfData: i18n.t("scanningObjects.typeOfData"),
        }),
        description: i18n.t("toast.success.deletedDescription", {
          name: props?.value.name,
          typeOfData: i18n.t("scanningObjects.typeOfDataPlural").toLowerCase(),
        }),
      },
      {
        containerId: APPLICATION_TOAST_CONTAINER_ID,
      }
    );
    props?.value?.tableRef?.current?.forceRefetch?.();
  };

  const handleApiResponseError = () => {
    props?.dispatch(closeModal());
  };

  const handleDeleteScaningObject = () => {
    props?.dispatch(toggleSetLoadingModal({ rank: 2 }));
    props?.dispatch(
      fetchDeleteScanningObject({
        scanningObjectId: props?.value?.id,
        handleApiResponseSuccess: handleApiResponseSuccessDelete,
        handleApiResponseError,
      })
    );
  };

  const handleDelete = () => {
    props?.dispatch(
      toggleSetDeleteModal({
        title: i18n.t("scanningObjects.modal.delete.title"),
        description: {
          first: i18n.t("scanningObjects.modal.delete.description.1"),
          second: i18n.t("scanningObjects.modal.delete.description.2"),
        },
        onSubmit: handleDeleteScaningObject,
        rank: 1,
      })
    );
  };

  return (
    <ActionRendererContainer>
      <Tooltip title={i18n.t("scanningObjects.tooltip.changeScanningObject")}>
        <ActionRendererEditScanningObjectIconContainer
          onClick={handleEditScanningObject}
        >
          <EditIcon />
        </ActionRendererEditScanningObjectIconContainer>
      </Tooltip>
      <WithPermissions roles={[MANAGER_ROLE]}>
        <Tooltip title={i18n.t("scanningObjects.deleteScanningObject")}>
          <ActionRendererDeleteScanningObjectIconContainer
            onClick={handleDelete}
          >
            <DeleteIcon />
          </ActionRendererDeleteScanningObjectIconContainer>
        </Tooltip>
      </WithPermissions>
    </ActionRendererContainer>
  );
};

ActionRenderer.propTypes = {
  children: PropTypes.node,
  dispatch: PropTypes.func,
  value: PropTypes.object,
  rendererProps: PropTypes.any,
  refetchTable: PropTypes.func,
};

export default ActionRenderer;
