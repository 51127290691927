import React from "react";
// import PropTypes from 'prop-types'
import { ReactComponent as DownArrowIconSvg } from "../../../assets/images/svg/down-arrow.svg";

const DownArrowIcon = (props) => {
  return <DownArrowIconSvg {...props} />;
};

DownArrowIcon.propTypes = {};

export default DownArrowIcon;
