import LastChangesRenderer from "../../components/Clients/ClientsTable/Renderers/LastChangesRenderer/LastChangesRenderer";
import ActionRenderer from "../../components/Companies/CompaniesTable/Renderers/ActionRenderer/ActionRenderer";
import UserRenderer from "../../components/Users/UsersTable/ActiveUsersTable/Renderers/UserRenderer/UserRenderer";

export default (isActive) => ({
  name: {
    id: 0,
    i18key: "companies.table.name.columnTitle",
    style: {
      minWidth: "140px",
    },
    backendProperty: "name",
    renderer: UserRenderer,
    redirect: true,
    setMaxHeight: true,
  },
  pib: {
    id: 1,
    i18key: "companies.table.pib.columnTitle",
    backendProperty: "pib",
    style: {
      minWidth: "130px",
    },
    setMaxHeight: true,
    // showLabel: true,
  },
  idNumber: {
    id: 2,
    i18key: "companies.table.idNumber.columnTitle",
    backendProperty: "identificationNumber",
    style: {
      minWidth: "140px",
    },
    setMaxHeight: true,
  },
  address: {
    id: 3,
    i18key: "companies.table.address.columnTitle",
    style: {
      minWidth: "140px",
    },
    backendProperty: "address",
    setMaxHeight: true,
    // showLabel: true,
  },
  email: {
    id: 4,
    i18key: "companies.table.email.columnTitle",
    disableSort: true,
    style: {
      minWidth: "140px",
    },
    backendProperty: "email",
    setMaxHeight: true,
  },
  lastChanges: {
    id: 5,
    i18key: isActive
      ? "companies.table.lastChanges.columnTitle"
      : "companies.table.deactivated.columnTitle",
    backendProperty: isActive ? "updatedAtUtc" : "deactivatedAtUtc",
    renderer: LastChangesRenderer,
    hideOnMobile: true,
    style: {
      textAlign: "center",
      minWidth: "220px",
      justifyContent: "center",
    },
    setMaxHeight: true,
  },
  actions: {
    id: 6,
    i18key: "",
    renderer: ActionRenderer,
    disableSort: true,
    style: {
      minWidth: "135px",
      width: "135px",
      maxWidth: "135px",
      flex: "0 0",
      paddingLeft: "0",
      justifyContent: "right",
    },
    setMaxHeight: true,
  },
  header: {
    id: 7,
    i18key: "companies.table.header.columnTitle",
    mobile: true,
    disableSort: true,
    style: {},
  },
});
