import { Box, Typography } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../themes";
import { hexToRGB } from "../../../util/helpers/colorHelper";
import Icon from "../../Icon/Icon";
import ResetIcon from "../../Icon/Icons/ResetIcon";
import XIcon from "../../Icon/Icons/XIcon";
import DownArrowIcon from "../../Icon/Icons/DownArrowIcon";

export const FilterButtonContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 12px;
  cursor: pointer;
  position: relative;
  @media (max-width: 600px) {
    width: auto;
  }
`;
export const FilterIconContainer = styled(Icon)``;

export const FilterButtonTitle = styled(Typography)`
  font-family: ${selectedTheme.fonts.mainText};
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: ${selectedTheme.colors.filterColor};
  @media (max-width: 600px) {
    display: none;
  }
`;
export const FilterDownIconContainer = styled(Icon)`
  @media (max-width: 600px) {
    display: none;
  }
`;

export const ArrowIcon = styled(DownArrowIcon)`
  transform: ${(props) => props?.flip && "rotateX(180deg)"};
`;
export const FilterComponentContainer = styled(Box)`
  position: absolute;
  top: calc(100% + 5px);
  z-index: 50;
  padding: 32px;
  gap: 24px;
  width: 800px;
  max-width: 800px;
  background-color: ${selectedTheme.colors.white};
  border: 1px solid ${selectedTheme.colors.questionSeparator};
  box-shadow: 0px 12px 16px -4px ${hexToRGB(selectedTheme.colors.shadowColor, 0.08)},
    0px 4px 6px -2px ${hexToRGB(selectedTheme.colors.shadowColor, 0.03)};
  border-radius: 8px;
  @media (max-width: 600px) {
    width: calc(100vw - 50px);
  }
`;

export const MainContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 32px;
  @media (max-width: 600px) {
    flex-direction: column;
    gap: 16px;
  }
`;
export const LeftContaier = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;
  flex-basis: 50%;
  @media (max-width: 600px) {
    flex-basis: auto;
    width: 100%;
  }
`;
export const RightContaier = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;
  flex-basis: 50%;
  @media (max-width: 600px) {
    flex-basis: auto;
    width: 100%;
  }
`;

export const CloseButton = styled(XIcon)`
  cursor: pointer;
  width: 22px;
  height: 22px;
`;

export const ResetButton = styled(ResetIcon)`
  cursor: pointer;
  width: 20px;
  height: 20px;
`;

export const ResetButtonContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;
  cursor: pointer;
  position: absolute;
  right: 35px;
  top: -2px;
  transform: rotate(90deg);
  border-radius: 8px;
  & svg {
    pointer-events: none;
  }
`;

export const CloseButtonContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;
  cursor: pointer;
  position: absolute;
  right: 0px;
  top: 0px;

  border-radius: 8px;
  & svg {
    pointer-events: none;
  }
`;
export const ButtonsContainer = styled(Box)`
  display: flex;
  gap: 12px;
  align-self: flex-end;
  width: 100%;
  & > div {
    flex: 1;
  }
  & button {
    padding-top: 4px;
    padding-bottom: 4px;
    width: 100%;
  }
  @media (max-width: 600px) {
    margin-top: 20px;
  }
`;
