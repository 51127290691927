import { Box, Typography } from "@mui/material";
import styled from "styled-components";
import { ReactComponent as Arrow } from "../../../../../../assets/images/svg/down-arrow.svg";
import selectedTheme from "../../../../../../themes";

export const FindingBreadCrumbsContainer = styled(Box)`
  font-family: "Inter" !important;
  font-size: 18px !important;
  font-weight: 500 !important;
  color: ${selectedTheme.colors.pageTitleColor};
  display: flex;
  gap: 10px;
  justify-content: space-between;
`;
export const FindingBreadCrumbsListContainer = styled(Box)`
  font-family: "Inter" !important;
  font-size: 18px !important;
  font-weight: 500 !important;
  color: ${selectedTheme.colors.pageTitleColor};
  display: flex;
  gap: 10px;
  max-width: calc(100vw - 176px);
`;
export const FindingRouteSingleItem = styled(Typography)`
  display: inline;
  cursor: pointer;
  font-family: "Inter" !important;
  font-size: 18px !important;
  font-weight: 500 !important;
  line-height: 20px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: ${selectedTheme.colors.pageTitleColor};
  @media (max-width: 600px) {
    /* max-width: 100px; */
  }
`;

export const FindingIcon = styled(Box)`
  width: 24px;
  height: 24px;
`;

export const FindingItem = styled(Box)`
  display: flex;
  gap: 10px;
  align-items: center;
  flex: 1;
  min-width: 0;
  /* margin-right: 10px; */
`;

export const FindingArrow = styled(Arrow)`
  transform: rotate(90deg);
  /* display: inline; */
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
  & path {
    fill: ${selectedTheme.colors.iconColor};
  }
`;
