import React from "react";
// import PropTypes from 'prop-types'
import { ReactComponent as ResearchBreadIconSvg } from "../../../assets/images/svg/research-bc.svg";

const ResearchBreadIcon = (props) => {
  return <ResearchBreadIconSvg {...props} />;
};

ResearchBreadIcon.propTypes = {};

export default ResearchBreadIcon;
