import React from "react";
import PropTypes from "prop-types";
import {
  CancelButtonContainer,
  ConfirmButtonContainer,
  ConfirmModalContainer,
  ConfirmModalDescription,
} from "./ConfirmModal.styled";
import { ButtonsContainer, ModalTitle } from "../Modal.styled";

const ConfirmModal = (props) => {
  return (
    <ConfirmModalContainer>
      <ModalTitle>{props?.title}</ModalTitle>
      <ConfirmModalDescription $noPadding={props?.noPadding}>
        {props?.description}
      </ConfirmModalDescription>
      <ButtonsContainer>
        <CancelButtonContainer
          onClick={props?.onCancel}
          $variant={props?.cancelButtonVariant}
        >
          {props?.cancelButtonText}
        </CancelButtonContainer>
        <ConfirmButtonContainer onClick={props?.onConfirm}>
          {props?.confirmButtonText}
        </ConfirmButtonContainer>
      </ButtonsContainer>
    </ConfirmModalContainer>
  );
};

ConfirmModal.propTypes = {
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  cancelButtonText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  confirmButtonText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  noPadding: PropTypes.bool,
  cancelButtonVariant: PropTypes.number,
};

ConfirmModal.defaultProps = {
  cancelButtonVariant: 1,
};

export default ConfirmModal;
