import { Box } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../themes";

export const UsersContentContainer = styled(Box)``;

export const TabsContainer = styled(Box)`
  display: flex;
  margin-top: 38px;
  gap: 24px;
  margin-bottom: 20px;
`;

export const SingleTab = styled(Box)`
  cursor: pointer;
  font-family: ${selectedTheme.fonts.mainText};
  font-size: 14px;
  font-weight: 600;
  padding-bottom: 16px;
  &:hover {
    border-bottom: 3px solid ${selectedTheme.colors.pageTitleColor};
  }

  ${(props) =>
    props?.$active &&
    `border-bottom: 3px solid ${selectedTheme.colors.pageTitleColor};`}
`;
