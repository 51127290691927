import { Box } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../themes";
import { hexToRGB } from "../../../util/helpers/colorHelper";
import { ReactComponent as Edit } from "../../../assets/images/svg/edit.svg";
import { ReactComponent as Trash } from "../../../assets/images/svg/trash.svg";
import NewEyeIcon from "../../Icon/Icons/NewEye";

export const ImagePreviewContainer = styled(Box)`
  width: 100%;
  height: 100%;
  font-size: 0;
  position: relative;
  &::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${hexToRGB(selectedTheme.colors.black, 0.5)};
    z-index: 3;
    overflow: hidden;
  }
`;
export const ImageEditingContainer = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
  width: 100%;
  height: 100%;
`;

export const IconButton = styled(Box)`
  cursor: pointer;
  width: ${(props) => (props?.$smallerButton ? "28px" : "38px")};
  height: ${(props) => (props?.$smallerButton ? "28px" : "38px")};
  background-color: white;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const EditIcon = styled(Edit)``;
export const TrashIcon = styled(Trash)``;
export const EyeIcon = styled(NewEyeIcon)`
  z-index: 4;
  width: ${(props) => (props?.$smallerButton ? "33.16px" : "47.16px")};
  height: ${(props) => (props?.$smallerButton ? "24.32px" : "34.59px")};
  & path {
    stroke: white;
  }
`;

export const ToolsContainer = styled(Box)`
  position: absolute;
  right: ${(props) => (props?.$smallerButtons ? "3px" : "13px")};
  top: ${(props) => (props?.$smallerButtons ? "3px" : "13px")};
  display: flex;
  gap: ${(props) => (props?.$smallerButtons ? "2px" : "8px")};
  z-index: 4;
  @media (max-width: 600px) {
    width: 100% !important;
    justify-content: space-between;
    padding-left: 5px;
  }
`;

export const EyeButtonContainer = styled(Box)`
  z-index: 4;
  position: relative;
`;

export const ShowIconContainer = styled(Box)`
  z-index: 4;
`;
export const ImageOverlay = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${props => hexToRGB(selectedTheme.colors.black, props?.$hidden ? 0 : 0.5)};
  z-index: 3;
  overflow: hidden;
`;
export const ImageSource = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  position: relative;
  z-index: 1;
`;
