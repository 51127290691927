const settingsTabsConstants = (isSuperAdmin, isEditing) => {
  let tabsToReturn = {
    general: {
      id: 0,
      i18key: "companySettings.general",
    },
    mail: {
      id: 1,
      i18key: "companySettings.mail",
    },
    licenses: {
      id: 3,
      i18key: "companySettings.licenses",
      disabled: !isEditing,
      disabledTooltipI18key: !isEditing && "companySettings.createCompanyFirst",
    },
  };
  if (isSuperAdmin)
    tabsToReturn = {
      ...tabsToReturn,
      mail: {
        id: 1,
        i18key: "companySettings.mail",
        disabled: !isEditing,
        disabledTooltipI18key:
          !isEditing && "companySettings.createCompanyFirst",
      },
      user: {
        id: 2,
        i18key: "companySettings.users",
        disabled: !isEditing,
        disabledTooltipI18key:
          !isEditing && "companySettings.createCompanyFirst",
      },
    };
  return tabsToReturn;
};

export default settingsTabsConstants;

export const settingsTabsAsArray = (isSuperAdmin, isEditing) => {
  let tabsConstants = settingsTabsConstants(isSuperAdmin, isEditing);
  return Object.keys(tabsConstants).map(
    (singleTab) => tabsConstants[singleTab]
  )?.sort((a, b) => a?.id - b?.id);
};
