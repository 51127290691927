import React from "react";
import PropTypes from "prop-types";
import {
  SidebarToggleButtonContainer,
  ToggleIcon,
} from "./SidebarToggleButton.styled";
import { useDispatch, useSelector } from "react-redux";
import { toggleSetSidebarOpen } from "../../../store/actions/sidebar/sidebarActions";
import { selectIsSidebarOpened } from "../../../store/selectors/sidebarSelector";

const SidebarToggleButton = () => {
  const dispatch = useDispatch();
  const isSidebarOpened = useSelector(selectIsSidebarOpened);
  const handleClick = () => {
    dispatch(toggleSetSidebarOpen());
  };
  return (
    <SidebarToggleButtonContainer
      onClick={handleClick}
      // $isOpened={isSidebarOpened}
    >
      <ToggleIcon $isOpened={isSidebarOpened} />
    </SidebarToggleButtonContainer>
  );
};

SidebarToggleButton.propTypes = {
  children: PropTypes.node,
};

export default SidebarToggleButton;
