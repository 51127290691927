import {
  createErrorType,
  createFetchType,
  createSetType,
  createSuccessType,
} from "../actionHelpers";

export const ADD_TRANSLATIONS_RESOURCE = createSetType(
  "ADD_TRANSLATIONS_RESOURCE"
);
export const SET_CURRENT_LNG = createSetType("SET_CURRENT_LNG");

export const GET_TRANSLATIONS_SCOPE = "GET_TRANSLATIONS_SCOPE";
export const GET_TRANSLATIONS_FETCH = createFetchType(GET_TRANSLATIONS_SCOPE);
export const GET_TRANSLATIONS_SUCCESS = createSuccessType(
  GET_TRANSLATIONS_SCOPE
);
export const GET_TRANSLATIONS_ERROR = createErrorType(GET_TRANSLATIONS_SCOPE);
