import React from "react";
import PropTypes from "prop-types";
import { LinkRendererContainer } from "./LinkRenderer.styled";

const LinkRenderer = (props) => {
  return (
    <LinkRendererContainer href={props?.value?.link} target="_blank">
      {props?.value?.t("common.link")}
    </LinkRendererContainer>
  );
};

LinkRenderer.propTypes = {
  value: PropTypes.object,
};

export default LinkRenderer;
