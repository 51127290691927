import {
    CLEAR_DOCUMENT_TYPES,
    SET_DOCUMENT_TYPES,
    SET_TOTAL_DOCUMENT_TYPES,
    SINGLE_DOCUMENT_TYPE_SET,
  } from "../../actions/documentTypes/documentTypesActionConstants";
  import createReducer from "../../utils/createReducer";
  
  const initialState = {
    documentTypes: {
      loaded: false,
      data: [],
    },
    totalDocumentTypes: 0,
    singleDocumentType: {},
  };
  
  export default createReducer(
    {
      [SET_DOCUMENT_TYPES]: setDocumentTypes,
      [SINGLE_DOCUMENT_TYPE_SET]: setSingleDocumentType,
      [SET_TOTAL_DOCUMENT_TYPES]: setTotalDocumentTypes,
      [CLEAR_DOCUMENT_TYPES]: clearDocumentTypes,
    },
    initialState
  );
  function setDocumentTypes(state, { payload }) {
    return {
      ...state,
      documentTypes: payload,
    };
  }
  function setTotalDocumentTypes(state, { payload }) {
    return {
      ...state,
      totalDocumentTypes: payload,
    };
  }
  function clearDocumentTypes() {
    return initialState;
  }
  function setSingleDocumentType(state, { payload }) {
    return {
      ...state,
      singleDocumentType: payload,
    };
  }
  