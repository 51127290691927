import {
    createClearType,
    createErrorType,
    createFetchType,
    createSetType,
    createSuccessType,
  } from "../actionHelpers";
  
  const SINGLE_COMMON_TEMPLATE_SCOPE = "SINGLE_COMMON_TEMPLATE_SCOPE";
  export const SINGLE_COMMON_TEMPLATE_FETCH = createFetchType(
    SINGLE_COMMON_TEMPLATE_SCOPE
  );
  export const SINGLE_COMMON_TEMPLATE_SUCCESS = createSuccessType(
    SINGLE_COMMON_TEMPLATE_SCOPE
  );
  export const SINGLE_COMMON_TEMPLATE_ERROR = createErrorType(
    SINGLE_COMMON_TEMPLATE_SCOPE
  );
  export const SINGLE_COMMON_TEMPLATE_SET = createSetType(
    SINGLE_COMMON_TEMPLATE_SCOPE
  );
  
  const COMMON_TEMPLATES_CREATE_SCOPE = "COMMON_TEMPLATES_CREATE_SCOPE";
  export const COMMON_TEMPLATES_CREATE_FETCH = createFetchType(
    COMMON_TEMPLATES_CREATE_SCOPE
  );
  export const COMMON_TEMPLATES_CREATE_SUCCESS = createSuccessType(
    COMMON_TEMPLATES_CREATE_SCOPE
  );
  export const COMMON_TEMPLATES_CREATE_ERROR = createErrorType(
    COMMON_TEMPLATES_CREATE_SCOPE
  );
  
  const COMMON_TEMPLATES_EDIT_SCOPE = "COMMON_TEMPLATES_EDIT_SCOPE";
  export const COMMON_TEMPLATES_EDIT_FETCH = createFetchType(
    COMMON_TEMPLATES_EDIT_SCOPE
  );
  export const COMMON_TEMPLATES_EDIT_SUCCESS = createSuccessType(
    COMMON_TEMPLATES_EDIT_SCOPE
  );
  export const COMMON_TEMPLATES_EDIT_ERROR = createErrorType(
    COMMON_TEMPLATES_EDIT_SCOPE
  );
  
  const COMMON_TEMPLATES_DELETE_SCOPE = "COMMON_TEMPLATES_DELETE_SCOPE";
  export const COMMON_TEMPLATES_DELETE_FETCH = createFetchType(
    COMMON_TEMPLATES_DELETE_SCOPE
  );
  export const COMMON_TEMPLATES_DELETE_SUCCESS = createSuccessType(
    COMMON_TEMPLATES_DELETE_SCOPE
  );
  export const COMMON_TEMPLATES_DELETE_ERROR = createErrorType(
    COMMON_TEMPLATES_DELETE_SCOPE
  );
  
  export const COMMON_TEMPLATES_SCOPE = "COMMON_TEMPLATES_SCOPE";
  export const COMMON_TEMPLATES_FETCH = createFetchType(
    COMMON_TEMPLATES_SCOPE
  );
  export const COMMON_TEMPLATES_SUCCESS = createSuccessType(
    COMMON_TEMPLATES_SCOPE
  );
  export const COMMON_TEMPLATES_ERROR = createErrorType(
    COMMON_TEMPLATES_SCOPE
  );
  export const COMMON_TEMPLATES_SET = createSetType(
    COMMON_TEMPLATES_SCOPE
  );
  export const COMMON_TEMPLATES_CLEAR = createClearType(
    COMMON_TEMPLATES_SCOPE
  );
  
  export const COMMON_TEMPLATES_SET_TOTAL = createSetType(
    "COMMON_TEMPLATES_TOTAL_SCOPE"
  );
  export const COMMON_TEMPLATES_SET_ERROR_MESSAGE = createSetType(
    "COMMON_TEMPLATES_ERROR_MESSAGE_SCOPE"
  );
  
  export const PREVIEW_COMMON_TEMPLATES_SCOPE = "COMMON_TEMPLATES_PREVIEW_SCOPE";

  export const PREVIEW_COMMON_TEMPLATES_SET = createSetType (
    PREVIEW_COMMON_TEMPLATES_SCOPE
  );

  export const PREVIEW_COMMON_TEMPLATES_FETCH = createFetchType(
    PREVIEW_COMMON_TEMPLATES_SCOPE
  );

  export const PREVIEW_COMMON_TEMPLATES_SUCCESS = createSuccessType(
    PREVIEW_COMMON_TEMPLATES_SCOPE
  );
  export const PREVIEW_COMMON_TEMPLATES_ERROR = createErrorType(
    PREVIEW_COMMON_TEMPLATES_SCOPE
  );


  export const SET_LANGUAGES = createSetType("SET_LANGUAGES");
  export const SET_LANGUAGES_ERROR = "SET_LANGUAGES_ERROR";
  export const SET_LANGUAGES_TOTAL = createSetType("LANGUAGES_TOTAL_SCOPE");

  export const GET_LANGUAGES_SCOPE = "GET_LANGUAGES_SCOPE";
  export const GET_LANGUAGES_FETCH = createFetchType(GET_LANGUAGES_SCOPE);
  export const GET_LANGUAGES_SUCCESS = createSuccessType(GET_LANGUAGES_SCOPE);
  export const GET_LANGUAGES_ERROR = createErrorType(GET_LANGUAGES_SCOPE);
  export const GET_LANGUAGES_CLEAR = createClearType(GET_LANGUAGES_SCOPE);