import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import { SidebarNavigationItemContainer } from "./SidebarNavigationItem.styled";
import { useSelector } from "react-redux";
import { selectIsSidebarOpened } from "../../../store/selectors/sidebarSelector";
import { isInRoute } from "../../../util/helpers/routeHelpers";
import SidebarNavigationitemDetails from "./SidebarNavigationItemDetails/SidebarNavigationitemDetails";
import { useLocation } from "react-router-dom";
import SidebarNavigationItemsTooltip from "./SidebarNavigationItemDetails/SidebarNavigationItemsTooltip/SidebarNavigationItemsTooltip";
import WithPermissions from "../../WithPermissions/WithPermissions";
import useAuth from "../../../hooks/useAuth";

const SidebarNavigationItem = (props) => {
  const [isItemHovered, setIsItemHovered] = useState(false);
  const isSidebarOpened = useSelector(selectIsSidebarOpened);
  const location = useLocation();
  const { hasRole } = useAuth();

  const handleStartHovering = () => {
    setIsItemHovered(true);
  };
  const handleEndHovering = () => {
    setIsItemHovered(false);
  };

  const routeToItem = useMemo(() => {
    if (props?.item?.subnavigationRoutes) {
      let newRouteToItem = props?.item?.subnavigationRoutes?.reduce(
        (prevValue, newValue) => {
          if (prevValue?.length !== 0) return prevValue;
          let hasOneOfRoles = newValue?.roles?.find((singleRole) =>
            hasRole(singleRole)
          );
          if (hasOneOfRoles !== undefined) return newValue?.route;
          return prevValue;
        },
        ""
      );
      if (!newRouteToItem) return props?.item?.route;
      else return newRouteToItem;
    } else return props?.item?.route;
  }, [hasRole, props?.item]);


  const isInCurrentRoute = useMemo(() => {
    if (routeToItem) {
      return isInRoute(routeToItem);
    }
    return false;
  }, [routeToItem, location]);

  const shouldExpandSubnavigation = useMemo(() => {
    // Setting initial item hovered when redirecting to some page
    setIsItemHovered(false);
    // ^^^^^

    if (!props?.item?.subnavigationRoutes) return false;
    return props?.item?.subnavigationRoutes?.reduce((prevValue, newValue) => {
      if (prevValue === true || !newValue?.route) return prevValue;
      if (isInRoute(newValue?.route)) return true;
      return false;
    }, false);
  }, [location, props?.item]);

  return (
    <SidebarNavigationItemContainer
      $isSidebarOpened={isSidebarOpened}
      $isSidebarItemChosen={shouldExpandSubnavigation}
      onMouseEnter={handleStartHovering}
      onMouseLeave={handleEndHovering}
    >
      {/* Item details */}
      <SidebarNavigationitemDetails
        setIsItemSelected
        item={{ ...props?.item, route: routeToItem }}
      />

      {/* Expanded subnavigation */}
      {props?.item?.subnavigationRoutes?.length > 0 &&
        shouldExpandSubnavigation &&
        props?.item?.subnavigationRoutes?.map((singleRoute, index) => {
          const roles = singleRoute?.roles || props?.$parentRoles;
          return (
            <WithPermissions roles={roles} key={index}>
              <SidebarNavigationitemDetails
                $changeIconColor
                item={singleRoute}
              />
            </WithPermissions>
          );
        })}

      {/* Tooltip showing routes */}
      {props?.item?.subnavigationRoutes &&
        isItemHovered &&
        !shouldExpandSubnavigation && (
          <SidebarNavigationItemsTooltip
            title={props?.item?.title}
            $parentRoles={props?.$parentRoles}
            items={props?.item?.subnavigationRoutes}
          />
        )}
      {isItemHovered &&
        !props?.item?.subnavigationRoutes &&
        !isInCurrentRoute &&
        !isSidebarOpened && (
          <SidebarNavigationItemsTooltip
            title={props?.item?.title}
            route={routeToItem}
          />
        )}
    </SidebarNavigationItemContainer>
  );
};

SidebarNavigationItem.propTypes = {
  item: PropTypes.object,
  $parentRoles: PropTypes.array,
};

export default SidebarNavigationItem;
