import { Box } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../themes";
import TickIcon from "../Icon/Icons/TickIcon";

export const CheckboxContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  & * {
    cursor: ${(props) => (props?.disabled ? "auto" : "pointer")};
  }
`;
export const CheckboxLabel = styled(Box)`
  font-family: ${selectedTheme.fonts.mainText};
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
`;
export const CheckboxIcon = styled(Box)`
  width: 18px;
  border-radius: 4px;
  height: 18px;
  background-color: ${(props) =>
    props?.checked ? selectedTheme.colors.orangeBorder : "white"};
  border: 1px solid
    ${(props) => (props?.checked ? selectedTheme.colors.orangeBorder : "black")};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CustomTickIcon = styled(TickIcon)`
  & path {
    stroke: ${selectedTheme.colors.white};
  }
`;
