import { Box } from "@mui/material";
import styled from "styled-components";
import { InputFieldContainer, TextInputFieldContainer } from "../../Field/TextInputField/TextInputField.styled";

export const InviteUserModalContainer = styled(Box)`
  padding: 32px;
  position: relative;
  background: white;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  min-width: 30vw;
  overflow: auto;
  & ${TextInputFieldContainer} {
    margin-top: 0;
    margin-bottom: 0;
  }
  & ${InputFieldContainer} {
    width: 100%;
    & > div {
      width: 100%;
    }
    & input {
      width: 100%;
    }
  }

  z-index: ${(props) => "15" + props?.rank || "151"};
  max-width: 500px;
  @media (min-width: 601px) {
    max-height: 900px !important;
    /* height: 95%; */
  }
  @media (max-width: 600px) {
    width: 100%;
    max-width: 100%;
  }
`;
export const RolePickerContainer = styled(Box)`
  & > p {
    margin-top: 0;
  }
`;
