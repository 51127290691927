import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  CloseButton,
  ErrorMessage,
  FormContainer,
  ModalContainer,
} from "./RenameTableColumnsModal.styled";
import { ButtonsContainer, ModalTitle } from "../Modal.styled";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { closeModal } from "../../../store/actions/modal/modalActions";
import TextInputField from "../../Field/TextInputField/TextInputField";
import {
  CancelButtonContainer,
  ConfirmButtonContainer,
} from "../ConfirmModal/ConfirmModal.styled";
import { useFormik } from "formik";
import { useMemo } from "react";
import renameColumnsInitialValues, {
  renameColumnsFormikConstants,
} from "../../../initialValues/renameColumnsInitialValues";
import renameColumnsValidationSchema from "../../../validations/renameColumnsValidationSchema";

const RenameTableColumnsModal = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState("");
  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  const initialValues = useMemo(() =>
    renameColumnsInitialValues({
      columnTitle: props?.title,
      columnSubtitle: props?.subtitle,
    })
  );

  const validationSchema = useMemo(() =>
    renameColumnsValidationSchema(props?.subtitleRequired)
  );

  const onSubmit = (values) => {
    let isEdited = values?.columnTitle !== props?.title ||
    values?.columnSubtitle !== props?.subtitle
    let hasSameColumnTitleArray = props?.columns?.filter(
      (singleColumn) => singleColumn?.columnTitle === values?.columnTitle
    );
    if (
      values?.columnTitle === props?.title &&
      values?.columnSubtitle === props?.subtitle
    ) {
      props?.handleSubmit?.({...values, isEdited});
      return;
    }
    if (hasSameColumnTitleArray?.length !== 0) {
      if (!props?.subtitleRequired) {
        setErrorMessage(t("table.renameColumns.errors.existingWithSubtitle"));
        return;
      }
      let hasSameColumnSubtitleArray = hasSameColumnTitleArray?.filter(
        (singleColumn) =>
          singleColumn?.columnSubtitle === values?.columnSubtitle
      );
      if (hasSameColumnSubtitleArray?.length === 1) {
        if (hasSameColumnSubtitleArray?.[0]?.columnId !== props?.columnId) {
          setErrorMessage(t("table.renameColumns.errors.existingSameSubtitle"));
          return;
        }
      }
      if (
        hasSameColumnTitleArray?.length === 1 &&
        !hasSameColumnTitleArray?.[0]?.columnSubtitle
      ) {
        setErrorMessage(t("table.renameColumns.errors.onlySubtitle"));
        return;
      }
    }
    props?.handleSubmit?.({...values, isEdited});
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnBlur: true,
    onSubmit,
    enableReinitialize: true,
  });

  const handleChangeInput = (event) => {
    setErrorMessage("");
    formik?.handleChange(event);
  };

  return (
    <ModalContainer>
      <ModalTitle>{t("table.renameColumns.title")}</ModalTitle>
      <CloseButton onClick={handleCloseModal} />
      <FormContainer as="form">
        <TextInputField
          label={t("table.title")}
          value={formik?.values?.[renameColumnsFormikConstants.columnTitle]}
          onChange={handleChangeInput}
          name={renameColumnsFormikConstants.columnTitle}
          onBlur={formik?.handleBlur}
          error={
            formik?.touched?.[renameColumnsFormikConstants.columnTitle] &&
            formik?.errors?.[renameColumnsFormikConstants.columnTitle]
          }
        />
        {props?.subtitleRequired && (
          <TextInputField
            label={t("table.subtitle")}
            value={formik?.values?.[renameColumnsFormikConstants.columnSubtitle]}
            onBlur={formik?.handleBlur}
            onChange={handleChangeInput}
            name={renameColumnsFormikConstants.columnSubtitle}
            error={
              formik?.touched?.[renameColumnsFormikConstants.columnSubtitle] &&
              formik?.errors?.[renameColumnsFormikConstants.columnSubtitle]
            }
          />
        )}
      </FormContainer>
      {errorMessage && <ErrorMessage>{t(errorMessage)}</ErrorMessage>}
      <ButtonsContainer>
        <CancelButtonContainer onClick={handleCloseModal} $variant={2}>
          {t("common.close")}
        </CancelButtonContainer>
        <ConfirmButtonContainer onClick={formik?.handleSubmit}>
          {t("table.renameColumns.rename")}
        </ConfirmButtonContainer>
      </ButtonsContainer>
    </ModalContainer>
  );
};

RenameTableColumnsModal.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  columnId: PropTypes.number,
  columns: PropTypes.array,
  subtitle: PropTypes.string,
  subtitleRequired: PropTypes.bool,
  handleSubmit: PropTypes.func,
};

export default RenameTableColumnsModal;
