import React from "react";
import PropTypes from "prop-types";
import { ProgressBarContainer } from "./ProgressBar.styled";
import { CircularProgressbar } from "react-circular-progressbar";
import { useTranslation } from "react-i18next";

const ProgressBar = (props) => {
  const { t } = useTranslation();
  return (
    <ProgressBarContainer>
      <CircularProgressbar
        value={props?.currentStep}
        minValue={0}
        maxValue={props?.totalSteps}
        text={t("findings.stepsRange", {
          currentStep: props?.currentStep,
          totalSteps: props?.totalSteps,
        })}
        strokeWidth={6}
      />
    </ProgressBarContainer>
  );
};

ProgressBar.propTypes = {
  currentStep: PropTypes.number,
  totalSteps: PropTypes.number,
};

export default ProgressBar;
