import {
  createClearType,
  createErrorType,
  createFetchType,
  createLoadingType,
  createSuccessType,
  createSubmitType,
  createSetType,
} from "../actionHelpers";

const LOGIN_USER_SCOPE = "LOGIN_USER";
export const LOGIN_USER_FETCH = createFetchType(LOGIN_USER_SCOPE);
export const LOGIN_USER_SUCCESS = createSuccessType(LOGIN_USER_SCOPE);
export const LOGIN_USER_ERROR = createErrorType(LOGIN_USER_SCOPE);
export const CLEAR_LOGIN_USER_ERROR = createClearType(
  `${LOGIN_USER_SCOPE}_ERROR`
);
export const LOGIN_USER_LOADING = createLoadingType(LOGIN_USER_SCOPE);

export const UPDATE_USER_JWT_TOKEN = "UPDATE_USER_JWT_TOKEN";
export const UPDATE_REFRESH_TOKEN = "UPDATE_REFRESH_TOKEN";
export const RESET_LOGIN_STATE = "RESET_LOGIN_STATE";
export const AUTHENTICATE_USER = "AUTHENTICATE_USER";
export const LOGOUT_USER = "LOGOUT_USER";
export const REFRESH_TOKEN = "REFRESH_TOKEN";
const USER_ROLES_SCOPE = "USER_ROLES";
export const SET_USER_ROLES = createSetType(USER_ROLES_SCOPE);
export const CLEAR_USER_ROLES = createClearType(USER_ROLES_SCOPE)
export const SET_MINE_COMPANY_ID = "SET_MINE_COMPANY_ID";

const GENERATE_TOKEN_SCOPE = "GENERATE_TOKEN";
export const GENERATE_TOKEN = createSubmitType(GENERATE_TOKEN_SCOPE);
export const GENERATE_TOKEN_SUCCESS = createSuccessType(GENERATE_TOKEN_SCOPE);
export const GENERATE_TOKEN_ERROR = createErrorType(GENERATE_TOKEN_SCOPE);

const SET_MY_PROFILE_ID_SCOPE = "SET_MY_PROFILE_ID_SCOPE";
export const SET_MY_PROFILE_ID = createSetType(SET_MY_PROFILE_ID_SCOPE);
