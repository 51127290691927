import React from "react";
import PropTypes from "prop-types";
import { FindingTextField } from "../FindingSettingsForm.styled";
import { findingFormikConstants } from "../../../../../../initialValues/findingInitialValues";
import { useTranslation } from "react-i18next";
import ReactDatePicker from "react-datepicker";
import { isEqualDates } from "../../../../../../util/helpers/dateHelpers";
import { useSelector } from "react-redux";
import { selectSingleFindingSettingsOld } from "../../../../../../store/selectors/findingsSelectors";

const DateOfFindingField = (props) => {
  const formik = props?.formik;
  const { t } = useTranslation();
  const findingSettingsOld = useSelector(selectSingleFindingSettingsOld);
  return (
    <>
      <ReactDatePicker
        placeholderText={t("findings.form.dateOfFinding.placeholder")}
        disabled={props?.disabled}
        selected={formik?.values?.[findingFormikConstants.closingDate].value}
        dateFormat="dd/MM/yyyy"
        minDate={new Date(formik?.values?.[findingFormikConstants.date].value)}
        onChange={(date) =>
          formik?.setFieldValue?.(findingFormikConstants.closingDate, {
            edited: !isEqualDates(
              date,
              new Date(findingSettingsOld?.closingDate?.value)
            ),
            value: date,
          })
        }
        customInput={
          <FindingTextField
            date
            disabledField={props?.disabled}
            name={findingFormikConstants.closingDate}
            label={t("findings.form.dateOfFinding.title")}
            placeholder={t("findings.form.dateOfFinding.placeholder")}
            value={formik?.values?.[findingFormikConstants.closingDate].value}
            onChange={formik?.handleChange}
            error={
              formik?.touched?.[findingFormikConstants.closingDate]?.value &&
              formik?.errors?.[findingFormikConstants.closingDate]?.value
            }
            helperText={
              formik?.errors?.[findingFormikConstants.closingDate]?.value &&
              formik?.touched?.[findingFormikConstants.closingDate]?.value
            }
            fullWidth
          />
        }
      />
    </>
  );
};

DateOfFindingField.propTypes = {
  formik: PropTypes.object,
  disabled: PropTypes.bool,
};

export default DateOfFindingField;
