import React from "react";
import PropTypes from "prop-types";
import Checkbox from "../../../Checkbox/Checkbox";
import { useTranslation } from "react-i18next";
import { newFindingTypeFormikConstants } from "../../../../initialValues/newFindingTypeInitialValues";
import { ExaminatorSignaturesContainer, Nester } from "./ExaminatorsSignaturesField.styled";

const ExaminatorsSignaturesField = (props) => {
  const { t } = useTranslation();
  const formik = props?.formik;
  return (
    <ExaminatorSignaturesContainer
      $disabled={
        props?.disabled ||
        !formik?.values?.[newFindingTypeFormikConstants.examinators]
      }
    >
      <Nester />
      <Checkbox
        label={t("findingTypes.examinatorsSignatures")}
        checked={
          formik?.values?.[
            newFindingTypeFormikConstants?.examinatorsSignatures
          ]
        }
        disabled={
          props?.disabled ||
          !formik?.values?.[newFindingTypeFormikConstants.examinators]
        }
        onChange={(value) =>
          formik?.setFieldValue?.(
            newFindingTypeFormikConstants.examinatorsSignatures,
            value
          )
        }
      />
    </ExaminatorSignaturesContainer>
  );
};

ExaminatorsSignaturesField.propTypes = {
  formik: PropTypes.object,
  disabled: PropTypes.bool,
};

export default ExaminatorsSignaturesField;
