import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import TextInputField from "../../Field/TextInputField/TextInputField";
import {
  makeErrorToastMessage,
  makeToastMessage,
} from "../../../store/utils/makeToastMessage";
import { APPLICATION_TOAST_CONTAINER_ID } from "../../../constants/toastConstants";
import {
  CheckboxMainContainer,
  ContentLeft,
  ContentRight,
  EmailContainer,
  EmailInfoContainer,
  EmailInstructions,
  ErrorMessage,
  ErrorMessageContainer,
  FormContainer,
  PasswordButton,
  PasswordButtonLabel,
  PasswordButtonWrapper,
  SSLSwitch,
  SwitchLabel,
  VerificationButton,
  VerificationContent,
  VerificationText,
  VerificationTextContainer,
} from "./EmailInformation.styled";
import CircularLoader from "../../Loader/CircularLoader/CircularLoader";
import {
  closeLoadingModal,
  toggleSetLoadingModal,
  toggleSetPasswordModal,
} from "../../../store/actions/modal/modalActions";
import { selectIsLoadingByActionType } from "../../../store/selectors/loadingSelectors";
import { MY_COMPANY_SCOPE } from "../../../store/actions/companies/companiesActionConstants";
import { useMemo } from "react";
import { fetchEmailVerification } from "../../../store/actions/companies/companiesActions";
import { validateEmail } from "../../../validations/mailValidation";
import Checkbox from "../../Users/NewUser/Checkbox/Checkbox";
import setEmailPasswordValidationScheme from "../../../validations/setEmailPasswordValidationScheme";
import TickCircledIcon from "../../Icon/Icons/TickCircledIcon";
import { selectSingleCompany } from "../../../store/selectors/companiesSelector";
import { fetchMailInstructions } from "../../../store/actions/instructions/instructionsActions";

const EmailInformation = (props) => {
  const [changedPassword, setChangedPassword] = useState(false);
  const formik = props?.formik;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isLoading = useSelector(selectIsLoadingByActionType(MY_COMPANY_SCOPE));
  const singleCompany = useSelector(selectSingleCompany);

  const handleApiResponseSuccess = () => {
    dispatch(closeLoadingModal());
    makeToastMessage(
      { title: t("emailSettings.isVerified") },
      { containerId: APPLICATION_TOAST_CONTAINER_ID }
    );
  };

  const disabledVerifyButton = useMemo(() => {
    return (
      formik?.values?.host?.length === 0 ||
      formik?.values?.userName?.length === 0 ||
      formik?.values?.port?.length === 0 ||
      formik?.values?.enableSSl?.length === 0 ||
      !validateEmail(formik?.values?.userName)
    );
  }, [formik?.values]);

  const handleApiResponseError = (errorMessage) => {
    makeErrorToastMessage(
      { title: errorMessage },
      { containerId: APPLICATION_TOAST_CONTAINER_ID }
    );
    dispatch(closeLoadingModal());
  };

  const verifyMail = () => {
    formik?.validateForm();
    if (formik?.isValid) {
      dispatch(toggleSetLoadingModal({ rank: 1 }));
      const dataToSend = {
        userName: formik?.values?.userName,
        password:
          formik?.values?.password?.length === 0
            ? null
            : formik?.values?.password,
        host: formik?.values?.host,
        port: formik?.values?.port,
        enableSSl: formik?.values?.enableSSl,
      };
      dispatch(
        fetchEmailVerification({
          //eslint-disable-line
          id: singleCompany?.id,
          data: dataToSend,
          handleApiResponseSuccess,
          handleApiResponseError,
        })
      );
    }
  };
  if (isLoading) return <CircularLoader />;

  const changePasswordHandler = () => {
    dispatch(
      toggleSetPasswordModal({
        title: t("common.changePasswordEmail", {
          name: null,
        }),
        onSubmit: (values) => {
          setChangedPassword(true);
          formik?.setFieldValue?.("password", values?.password);
        },
        pattern: setEmailPasswordValidationScheme,
        showValidationDescription: false,
        rank: 1,
      })
    );
  };

  const handleDownload = () => {
    dispatch(fetchMailInstructions());
  };

  return (
    <EmailInfoContainer>
      <CheckboxMainContainer>
        <Checkbox
          name="automaticallySendEmails"
          label={t("companySettings.autoSendMails")}
          checked={formik?.values?.automaticallySendEmails}
          onChange={() => {
            formik?.setFieldValue?.(
              "automaticallySendEmails",
              !formik?.values?.automaticallySendEmails
            );
          }}
        />
      </CheckboxMainContainer>
      <FormContainer onSubmit={formik?.handleSubmit}>
        <ContentLeft>
          <TextInputField
            name="host"
            style={{ width: "30px" }}
            label={t("emailSettings.label.host")}
            value={formik?.values?.host}
            onChange={formik?.handleChange}
            error={formik?.touched?.host && formik?.errors?.host}
            helperText={formik?.touched?.host && formik?.errors?.host}
            requiredField={
              formik?.values?.automaticallySendEmails && !formik?.values?.host
            }
          />
          <TextInputField
            name="port"
            label={t("emailSettings.label.port")}
            value={formik?.values?.port}
            onChange={formik?.handleChange}
            error={formik?.touched?.port && formik?.errors?.port}
            helperText={formik?.touched?.port && formik?.errors?.port}
            inputProps={{ isSmall: true }}
            requiredField={
              formik?.values?.automaticallySendEmails && !formik?.values?.port
            }
          />
          <SSLSwitch
            label={
              <SwitchLabel
                requiredField={
                  formik?.values?.automaticallySendEmails &&
                  !formik?.values?.enableSSl
                }
              >
                {t("emailSettings.label.ssl")}
              </SwitchLabel>
            }
            onChange={() =>
              formik?.setFieldValue?.("enableSSl", !formik?.values?.enableSSl)
            }
            checked={formik?.values?.enableSSl}
          />
          {formik?.touched?.enableSSl && formik?.errors?.enableSSl && (
            <ErrorMessage>{t(formik?.errors?.enableSSl)}</ErrorMessage>
          )}
          <EmailInstructions handleDownload={handleDownload} />
        </ContentLeft>
        <ContentRight>
          <EmailContainer>
            <TextInputField
              name="userName"
              label={t("emailSettings.label.userName")}
              value={formik?.values?.userName}
              onChange={formik?.handleChange}
              error={formik?.touched?.userName && formik?.errors?.userName}
              helperText={formik?.touched?.userName && formik?.errors?.userName}
              requiredField={
                formik?.values?.automaticallySendEmails &&
                !formik?.values?.userName
              }
            />
          </EmailContainer>
          <PasswordButtonWrapper>
            {changedPassword && (
              <PasswordButtonLabel>
                <TickCircledIcon />
                {t("common.passwordEntered")}
              </PasswordButtonLabel>
            )}
            <PasswordButton onClick={changePasswordHandler}>
              {changedPassword
                ? t("common.changePassword")
                : t("common.setPassword")}
            </PasswordButton>
          </PasswordButtonWrapper>
          {formik?.touched?.password && formik?.errors?.password && (
            <ErrorMessageContainer>
              <ErrorMessage>{t(formik?.errors?.password)}</ErrorMessage>
            </ErrorMessageContainer>
          )}
          <VerificationContent $extraBottomPadding={disabledVerifyButton}>
            <VerificationTextContainer>
              {disabledVerifyButton && (
                <VerificationText>
                  {validateEmail(formik?.values?.userName)
                    ? t("emailSettings.verificationHint")
                    : t("emailSettings.emailFormat")}
                </VerificationText>
              )}
            </VerificationTextContainer>
            <VerificationButton
              disabled={disabledVerifyButton}
              onClick={verifyMail}
            >
              {t("emailSettings.buttonLabel")}
            </VerificationButton>
          </VerificationContent>
        </ContentRight>
      </FormContainer>
    </EmailInfoContainer>
  );
};

EmailInformation.propTypes = {
  formik: PropTypes.object,
};

export default EmailInformation;
