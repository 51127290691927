import {
  GET_TRANSLATIONS_ERROR,
  GET_TRANSLATIONS_FETCH,
  GET_TRANSLATIONS_SUCCESS,
  SET_CURRENT_LNG,
  ADD_TRANSLATIONS_RESOURCE,
} from "./translationsActionConstants";

export const addTranslationsResource = (payload) => ({
  type: ADD_TRANSLATIONS_RESOURCE,
  payload,
});

export const setCurrentLanguage = (payload) => ({
  type: SET_CURRENT_LNG,
  payload,
});

export const fetchTranslationsResource = (payload) => ({
  type: GET_TRANSLATIONS_FETCH,
  payload,
});
export const fetchTranslationsResourceSuccess = (payload) => ({
  type: GET_TRANSLATIONS_SUCCESS,
  payload,
});
export const fetchTranslationsResourceError = (payload) => ({
  type: GET_TRANSLATIONS_ERROR,
  payload,
});
