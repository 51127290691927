import React from "react";
import PropTypes from "prop-types";
import {
  FindingScanningContainer,
  FindingScanningLeftContainer,
  FindingScanningRightContainer,
} from "./FindingScanning.styled";
import FindingScanningSectionChooser from "./FindingScanningSectionChooser/FindingScanningSectionChooser";
import { useState } from "react";
import FindingScanningSectionContent from "./FindingScanningSectionContent/FindingScanningSectionContent";
import useIsMobile from "../../../../hooks/useIsMobile";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import {
  selectFindingSubject,
  selectFindingSubjectsAll,
} from "../../../../store/selectors/findingsSelectors";
import { useMemo } from "react";

const FindingScanning = (props) => {

  const [selectedSubSectionId, setSelectedSubSectionId] = useState(null);
  const [chosenScanningSubjectId, setScanningSubjectId] = useState(
    // props?.isEditing?.findingSubjects[0].id
    0
  );
  const chosenScanningSubject = useSelector(
    selectFindingSubject(chosenScanningSubjectId)
  );
  const [openSectionId, setOpenSectionId] = useState(null); 
 
  const findingSubjects = useSelector(selectFindingSubjectsAll);
  const sortedSubjects = useMemo(() => {
    let newSubjects = [...findingSubjects];
    return newSubjects
      ?.filter?.((singleSubject) => !singleSubject?.removed)
      ?.sort?.((a, b) => a?.orderNumber?.value - b?.orderNumber?.value);
  }, [findingSubjects]);
  const [isLoaded, setIsLoaded] = useState(false);
  const { isMobile } = useIsMobile();
  const [closeScanning, setCloseScanning] = useState(false)
  
  useEffect(() => {
    if (/*chosenScanningSubjectId== null || */chosenScanningSubjectId !== chosenScanningSubject?.id) 
   {
      if (!isMobile) {
        setScanningSubjectId(chosenScanningSubject?.id)
      }
    }
  }, [chosenScanningSubject])

  useEffect(() => {
    if (isMobile !== undefined && isMobile !== null && !isLoaded) {
      if (isMobile) {
        setScanningSubjectId();
      } else {
        if (findingSubjects?.length !== 0)
          {setScanningSubjectId(sortedSubjects?.[0]?.id);
          }
        else setScanningSubjectId(0);
      }
      setIsLoaded(true);
    }
  }, [isMobile]);

  useEffect(() => {
    if (
      findingSubjects?.filter?.((singleSubject) => !singleSubject?.removed)
        ?.length === 0
    ) {
      setScanningSubjectId();
    }
    if (
      !isMobile &&
      (chosenScanningSubjectId == null || chosenScanningSubject?.removed)
    ) {
      setScanningSubjectId(sortedSubjects?.[0]?.id);
    }
  }, [sortedSubjects, findingSubjects]);

 

  const handleChangeSection = (sectionId) => {
   
    if (chosenScanningSubjectId !== sectionId) {
      setTimeout(() => {
        setScanningSubjectId(sectionId);
      }, 0);
      setOpenSectionId(openSectionId === sectionId ? null : sectionId); 
      setCloseScanning(false)
    }
    else { setCloseScanning(!closeScanning)} //da zatvori predmet ispitivanja
  };

  const [sectionId, setSectionId] = useState(null); 
  const handleSectionClick = (sectionId) =>
  {
    setSectionId(sectionId); //Id za otvaranje sekcije desno 
  }

  const handleSetSelectedSubSectionId = (subSectionId) => {
   // console.log("Strelice aktivne- vrednost sekcije:",subSectionId)
    setSelectedSubSectionId(subSectionId);
  };

  return (
    <FindingScanningContainer>
      {!(isMobile && chosenScanningSubjectId != null) && (
        <FindingScanningLeftContainer
          $chosenScanningSubject={chosenScanningSubject}
        > 
          <FindingScanningSectionChooser
            // sections={props?.item?.data?.sections}
            stateId={props?.stateId}
            sections={sortedSubjects?.filter(
              (singleSubject) => !singleSubject?.removed
            )}
            isEditing={props?.isEditing}
            settingsFormik={props?.settingsFormik}
            chosenScanningSubjectId={
              chosenScanningSubjectId != null && chosenScanningSubject?.id
            }
            handleChangeSection={handleChangeSection}
            isOpen = {openSectionId }
            isMobile={isMobile}
            handleSectionClick={handleSectionClick}
            selectedSubSectionId={selectedSubSectionId}
            onSetSelectedSubSectionId={handleSetSelectedSubSectionId}
            closeScanning = {closeScanning}
          /> 
        </FindingScanningLeftContainer>
      )}
      {chosenScanningSubjectId != null && (
        <FindingScanningRightContainer>
          <FindingScanningSectionContent
            setSelectedScanningSubjectId={setScanningSubjectId}
            selectedScanningSubjectId={chosenScanningSubjectId}
            disabled={props?.disabled}
            stateId={props?.stateId}
            goBackToSections={setScanningSubjectId}
            sectionId = {sectionId} //sekcija koja ce biti prikazana u kontentu 
            setSectionId={setSectionId} //funkcija koja setuje sectionId
            selectedSubSectionId={selectedSubSectionId}
            onSetSelectedSubSectionId={handleSetSelectedSubSectionId}         
          />
        </FindingScanningRightContainer>
      )}
    </FindingScanningContainer>
  );
};

FindingScanning.propTypes = {
  children: PropTypes.node,
  item: PropTypes.object,
  selectedTab: PropTypes.object,
  answerQuestion: PropTypes.func,
  handleSubmit: PropTypes.func,
  handleChangeTab: PropTypes.func,
  disabled: PropTypes.bool,
  sections: PropTypes.array,
  stateId: PropTypes.any,
  settingsFormik: PropTypes.func,
  isEditing: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  handleSectionClick: PropTypes.func,
};

export default FindingScanning;
