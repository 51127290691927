import React from "react";
import PropTypes from "prop-types";
import {
  ButtonLabel,
  DistributionMailsContainer,
  ErrorMessage,
  NewEmailButton,
  Plus,
  SingleDistributionMailContainer,
} from "../NewClientForm.styled";
import DistributionMailInput from "../DistributionMailInput/DistributionMailInput";
import { useTranslation } from "react-i18next";
import newClientValidationSchema from "../../../../validations/newClientValidationSchema";

const DistributionMailField = (props) => {
  const { t } = useTranslation();
  const formik = props?.formik;

  const addInputHandler = () => {
    formik?.setFieldValue?.("distributionEmails", [
      ...formik?.values?.distributionEmails,
      "",
    ]);
  };

  const removeInputHandler = (index) => {
    let prevEmails = formik?.values?.distributionEmails;
    let newEmails = [...prevEmails];
    newEmails?.splice?.(index, 1);
    formik?.setFieldValue?.("distributionEmails", newEmails);
  };

  return (
    <>
      <ButtonLabel
        $required={
          newClientValidationSchema?.()?.fields?.distributionEmails?.exclusiveTests
            ?.min && !formik?.values?.distributionEmails[0]
        }
      >
        {t("clients.distributionMailList")}
      </ButtonLabel>
      <DistributionMailsContainer>
        {formik?.values?.distributionEmails?.map?.((item, index) => {
          return (
            <SingleDistributionMailContainer key={index}>
              <DistributionMailInput
                onChange={formik?.handleChange}
                value={formik?.values?.distributionEmails?.[index]}
                minusHandler={() => removeInputHandler(index)}
                name={`distributionEmails.${index}`}
                placeholder={t(
                  "clients.newClientPlaceholders.distributionEmail"
                )}
                error={
                  formik?.errors?.distributionEmails?.[index] &&
                  formik?.touched?.distributionEmails?.[index]
                }
                isReadOnly={props?.isReadOnly}
              />
              {formik?.errors?.distributionEmails?.[index] &&
                formik?.touched?.distributionEmails?.[index] &&
                Array.isArray(formik?.errors?.distributionEmails) && (
                  <ErrorMessage>
                    {t(formik?.errors?.distributionEmails?.[index])}
                  </ErrorMessage>
                )}
            </SingleDistributionMailContainer>
          );
        })}
      </DistributionMailsContainer>
      {formik?.errors?.distributionEmails &&
        formik?.touched?.distributionEmails &&
        !Array.isArray(formik?.errors?.distributionEmails) && (
          <ErrorMessage>{t(formik?.errors?.distributionEmails)}</ErrorMessage>
        )}
      {!props?.isReadOnly && (
        <NewEmailButton onClick={addInputHandler}>
          <Plus />
          {t("clients.newEmail")}
        </NewEmailButton>
      )}
    </>
  );
};

DistributionMailField.propTypes = {
  formik: PropTypes.object,
  isReadOnly: PropTypes.bool,
};

export default DistributionMailField;
