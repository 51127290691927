export const findingTypes = {
  WORK_EQUIPMENT: {
    id: 0,
    i18key: "findingTypes.workEquipment.title",
  },
  WORK_ENVIRONMENT_CONDITIONS: {
    id: 1,
    i18key: "findingTypes.workEnvironment.title",
  },
  ELETRICAL_INSTALLATIONS: {
    id: 2,
    i18key: "findingTypes.electricalInstallations.title",
  },
  LIGHTNING_PROTECTION: {
    id: 3,
    i18key: "findingTypes.lightningProtection.title",
  },
  ANTIPANIC_LIGHTNING: {
    id: 4,
    i18key: "findingTypes.antiPanicLightning.title",
  },
  TEMPORARY_ELETRICAL_INSTALLATIONS: {
    id: 5,
    i18key: "findingTypes.temporaryEletricalInstallations.title",
  },
  STATIC_CHARGE: {
    id: 6,
    i18key: "findingTypes.staticCharge.title",
  },
};
export const findingTypesAsArray = Object.keys(findingTypes).map(singlePropertyName => findingTypes?.[singlePropertyName])
// [
//   {
//     id: findingTypes.WORK_EQUIPMENT.id,
//     i18key: findingTypes.WORK_EQUIPMENT.i18key,
//   },
//   {
//     id: findingTypes.WORK_ENVIRONMENT_CONDITIONS.id,
//     i18key: findingTypes.WORK_ENVIRONMENT_CONDITIONS.i18key,
//   },
//   {
//     id: findingTypes.ELETRICAL_INSTALLATIONS.id,
//     i18key: findingTypes.ELETRICAL_INSTALLATIONS.i18key,
//   },
//   {
//     id: findingTypes.LIGHTNING_PROTECTION.id,
//     i18key: findingTypes.LIGHTNING_PROTECTION.i18key,
//   },
//   {
//     id: findingTypes.ANTIPANIC_LIGHTNING.id,
//     i18key: findingTypes.ANTIPANIC_LIGHTNING.i18key,
//   },
//   {
//     id: findingTypes.TEMPORARY_ELETRICAL_INSTALLATIONS.id,
//     i18key: findingTypes.TEMPORARY_ELETRICAL_INSTALLATIONS.i18key,
//   },
//   {
//     id: findingTypes.STATIC_CHARGE.id,
//     i18key: findingTypes.STATIC_CHARGE.i18key,
//   },
// ];
