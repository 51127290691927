import { Box, Typography } from "@mui/material";
import styled, { css } from "styled-components";
import selectedTheme from "../../../../themes";
import ImagePicker from "../../../ImagePicker/ImagePicker";

export const FindingQuestionImagePickerContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;
export const FindingQuestionImagePickerLabel = styled(Typography)`
  font-family: ${selectedTheme.fonts.mainText};
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  font-feature-settings: "salt" on;
  color: ${selectedTheme.colors.labelTextColor};
  /* color: ${selectedTheme.colors.deviceColor}; */
  ${(props) =>
    props?.required &&
    css`
      &::after {
        content: "*";
        color: ${selectedTheme.colors.errorColor};
      }
    `}
`;

export const FindingQuestionImagePickerListContainer = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  padding: 8px;
  border: ${(props) =>
    props?.$hasBorders && `1px solid ${selectedTheme.colors.inputBorder}`};
  border-radius: 8px;
  width: fit-content;
  /* max-width: 320px; */
`;
export const FindingQuestionSingleImagePicker = styled(ImagePicker)`
  flex: 1;
  ${(props) =>
    props?.$hasImages &&
    css`
      /* padding: 8px; */
      width: 95px !important;
      max-width: 95px !important;
      height: 142px !important;
      max-height: 142px !important;
      min-height: 142px !important;
      & > form {
        padding: ${(props) => !props?.image && "8px"};
      }
      & p {
        text-align: center;
      }
      & * {
        max-width: 95px !important;
        max-height: 142px !important;
      }
    `}
  @media (max-width: 600px) {
    align-self: center;
  }
`;
