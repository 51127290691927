import React from "react";
// import PropTypes from 'prop-types'
import { ReactComponent as CompanyIconSvg } from "../../../assets/images/svg/company.svg";

const CompanyIcon = (props) => {
  return <CompanyIconSvg {...props} />;
};

CompanyIcon.propTypes = {};

export default CompanyIcon;
