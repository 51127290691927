import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import { LOGOUT_PAGE, PROFILE_PAGE } from "../../constants/pages";
import history from "../../store/utils/history";
import LanguageDropdown from "../Dropdown/LanguageDropdown/LanguageDropdown";
import ProfileIcon from "../Icon/Icons/ProfileIcon";
import {
  LogoutIcon,
  UserPopoverContainer,
  UserPopoverItemText,
  UserPopoverItemsContainer,
  UserPopoverSeparator,
  UserPopoverSingleItemContainer,
} from "./UserPopover.styled";

const UserPopover = (props) => {
  const { t } = useTranslation();

  const handleLogout = () => {
    history?.push(LOGOUT_PAGE);
    props?.closePopover();
  };

  return (
    <UserPopoverContainer>
      <UserPopoverItemsContainer>
        <LanguageDropdown />
        <UserPopoverSeparator />
        <UserPopoverSingleItemContainer
          to={PROFILE_PAGE}
          onClick={props?.closePopover}
        >
          <ProfileIcon />
          <UserPopoverItemText>{t("userPopover.profile")}</UserPopoverItemText>
        </UserPopoverSingleItemContainer>
        <UserPopoverSingleItemContainer to={LOGOUT_PAGE} onClick={handleLogout}>
          <LogoutIcon />
          <UserPopoverItemText>{t("userPopover.logout")}</UserPopoverItemText>
        </UserPopoverSingleItemContainer>
      </UserPopoverItemsContainer>
    </UserPopoverContainer>
  );
};

UserPopover.propTypes = {
  children: PropTypes.node,
  closePopover: PropTypes.func,
};

export default UserPopover;
