import { Box } from "@mui/material";
import styled from "styled-components";

export const TableHeaderContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  gap: 12px;
`;
export const TableHeaderLeftContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  flex-basis: 55%;
  @media (max-width: 600px) {
    flex: 1;
  }
`;
export const ButtonsContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
`;
