import React from "react";
// import PropTypes from 'prop-types'
import { ReactComponent as DeactivateIconSvg } from "../../../assets/images/svg/deactivate.svg";

const DeactivateIcon = (props) => {
  return <DeactivateIconSvg {...props} />;
};

DeactivateIcon.propTypes = {};

export default DeactivateIcon;
