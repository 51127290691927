import React, { useCallback, useEffect, useMemo, useRef } from "react";
import PropTypes from "prop-types";
import Table from "../../Table/Table";
import TextRenderer from "../../Table/Renderers/TextRenderer/TextRenderer";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { formatDateTime } from "../../../util/helpers/dateHelpers";
import stuffsTableConstants from "../../../constants/tableConstants/stuffsTableConstants";
import {
  selectStuffsData,
  selectTotalStuffs,
} from "../../../store/selectors/stuffsSelector";
import {
  fetchStuffs,
  fetchStuffsClear,
} from "../../../store/actions/stuffs/stuffsActions";
import { EDIT_STUFF_PAGE, NEW_STUFF_PAGE } from "../../../constants/pages";
import { makeToastMessage } from "../../../store/utils/makeToastMessage";
import { APPLICATION_TOAST_CONTAINER_ID } from "../../../constants/toastConstants";
import useAuth from "../../../hooks/useAuth";
import { MANAGER_ROLE } from "../../../constants/rolesConstants";
import { replaceInRoute } from "../../../util/helpers/routeHelpers";

const StuffsTable = () => {
  const history = useHistory();
  const tableRef = useRef();
  const { t } = useTranslation();

  const { hasRole } = useAuth();

  const hideAddAndUpdate = useMemo(() => {
    return !hasRole(MANAGER_ROLE);
  }, [hasRole]);

  const handleRedirect = (stuffId) =>
    replaceInRoute(EDIT_STUFF_PAGE, {
      stuffId: stuffId,
    });

  useEffect(() => {
    if (history.location.state?.isDeleted) {
      makeToastMessage(
        {
          title: t("toast.success.deletedTitle", {
            typeOfData: t("stuffs.typeOfData"),
          }),
          description: t("toast.success.deletedDescription", {
            name: history.location.state?.name,
            typeOfData: t("stuffs.typeOfDataPlural").toLowerCase(),
          }),
        },
        {
          containerId: APPLICATION_TOAST_CONTAINER_ID,
        }
      );
      let currentState = { ...history?.location?.state };
      delete currentState.name;
      delete currentState.isDeleted;
      history.replace({
        state: currentState,
      });
      if (history.location.state?.isDeleted) {
        tableRef?.current?.forceRefetch();
      }
    }
  }, [history.location.state]);

  const tableColumns = useMemo(() => {
    let columnsObject = Object.keys(stuffsTableConstants).map((property) => ({
      ...stuffsTableConstants[property],
      field: t(stuffsTableConstants[property].i18key),
      propertyName: property,
      backendProperty:
        stuffsTableConstants[property]?.backendProperty || property,
      style: stuffsTableConstants[property]?.style,
      renderer: stuffsTableConstants[property]?.renderer || TextRenderer,
    }));
    return columnsObject;
  }, [stuffsTableConstants, t, hasRole]);

  const mapDataFunction = useCallback((newData) => {
    return newData?.map?.((singleData) => {
      let mappedDataObject = {
        stuffs: singleData?.firstName + " " + singleData?.lastName,
        email: singleData?.email,
        rank: singleData?.profession,
        lastChanges: {
          date: formatDateTime(
            singleData?.updatedAtUtc || new Date().toString()
          ),
          author: `${singleData?.updatedByUser?.firstName || ""} ${
            singleData?.updatedByUser?.lastName || ""
          }`,
        },
        id: singleData?.id,
        actions: {
          stuffId: singleData?.id,
          stuffs: singleData?.firstName + " " + singleData?.lastName,
          idNumber: singleData?.id,
          email: singleData?.email,
          rank: singleData?.profession,
          license: singleData?.licenseUrl,
          totalData: newData?.length,
          tableRef,
          t,
        },
      };
      return mappedDataObject;
    });
  }, []);

  useEffect(() => {
    if (history?.location?.state?.refetch) {
      tableRef?.current?.forceRefetch();
    }
  }, [history]);

  return (
    <Table
      tableColumns={tableColumns}
      hideAddButton={hideAddAndUpdate}
      searchPlaceholder={t("stuffs.table.searchPlaceholder")}
      dataSelector={selectStuffsData}
      totalDataSelector={selectTotalStuffs}
      dispatchFunction={fetchStuffs}
      mapDataFunction={mapDataFunction}
      redirectHrefFunction={handleRedirect}
      addButtonHref={{
        pathname: NEW_STUFF_PAGE,
        state: {
          page: tableRef?.current?.page,
          searchValue: tableRef?.current?.searchValue,
        },
      }}
      clearDispatchFunction={fetchStuffsClear}
      ref={tableRef}
      smallerTextForMobile
    />
  );
};

StuffsTable.propTypes = {
  children: PropTypes.node,
};

export default StuffsTable;
