import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  CheckboxContainer,
  CheckboxIcon,
  CheckboxLabel,
  CustomTickIcon,
} from "./Checkbox.styled";

const Checkbox = (props) => {
  const [isChecked, setIsChecked] = useState(props?.checked);

  useEffect(() => {
    if (props?.checked != null) setIsChecked(props?.checked);
  }, [props?.checked]);

  const handleChange = () => {
    if (!props?.disabled) {
      setIsChecked(!isChecked);
      props?.onChange(!isChecked);
    }
  };

  return (
    <CheckboxContainer disabled={props?.disabled}>
      <CheckboxIcon checked={props?.checked} onClick={handleChange}>
        {props?.checked && <CustomTickIcon />}
      </CheckboxIcon>
      <CheckboxLabel onClick={handleChange}>{props?.label}</CheckboxLabel>
    </CheckboxContainer>
  );
};

Checkbox.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  label: PropTypes.string,
  value: PropTypes.string,
  name: PropTypes.string,
  disabled: PropTypes.bool,
};
Checkbox.defaultProps = {
  checked: false,
};

export default Checkbox;
