import React from "react";
// import PropTypes from 'prop-types'
import { ReactComponent as ExportIconSvg } from "../../../assets/images/svg/export.svg";

const ExportIcon = (props) => {
  return <ExportIconSvg {...props} />;
};

ExportIcon.propTypes = {};

export default ExportIcon;
