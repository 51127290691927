import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
  replaceInUrl,
} from ".";
import apiEndpoints from "./apiEndpoints";

export const attemptFetchDocuments = (payload) =>
  getRequest(apiEndpoints.documents.getDocuments, payload);

export const attemptCreateDocument = (payload) =>
  postRequest(apiEndpoints.documents.createDocument, payload);

export const attemptFetchSingleDocument = (payload) =>
  getRequest(
    replaceInUrl(apiEndpoints.documents.getSingleDocument, {
      documentId: payload?.documentId,
    })
  );

export const attemptEditSingleDocument = (payload) =>
  putRequest(
    replaceInUrl(apiEndpoints.documents.editDocument, {
      documentId: payload?.documentId,
    }),
    payload?.data
  );

export const attemptDeleteDocument = (payload) =>
  deleteRequest(
    replaceInUrl(apiEndpoints.documents.deleteDocument, {
      documentId: payload?.documentId,
    })
  );
