import {
  createClearType,
  createErrorType,
  createFetchType,
  createSetType,
  createSuccessType,
} from "../actionHelpers";

const SINGLE_MEASURING_INSTRUMENT_SCOPE = "SINGLE_MEASURING_INSTRUMENT_SCOPE";
export const SINGLE_MEASURING_INSTRUMENT_FETCH = createFetchType(
  SINGLE_MEASURING_INSTRUMENT_SCOPE
);
export const SINGLE_MEASURING_INSTRUMENT_SUCCESS = createSuccessType(
  SINGLE_MEASURING_INSTRUMENT_SCOPE
);
export const SINGLE_MEASURING_INSTRUMENT_ERROR = createErrorType(
  SINGLE_MEASURING_INSTRUMENT_SCOPE
);
export const SINGLE_MEASURING_INSTRUMENT_SET = createSetType(
  SINGLE_MEASURING_INSTRUMENT_SCOPE
);

const MEASURING_INSTRUMENTS_CREATE_SCOPE = "MEASURING_INSTRUMENTS_CREATE_SCOPE";
export const MEASURING_INSTRUMENTS_CREATE_FETCH = createFetchType(
  MEASURING_INSTRUMENTS_CREATE_SCOPE
);
export const MEASURING_INSTRUMENTS_CREATE_SUCCESS = createSuccessType(
  MEASURING_INSTRUMENTS_CREATE_SCOPE
);
export const MEASURING_INSTRUMENTS_CREATE_ERROR = createErrorType(
  MEASURING_INSTRUMENTS_CREATE_SCOPE
);

const MEASURING_INSTRUMENTS_EDIT_SCOPE = "MEASURING_INSTRUMENTS_EDIT_SCOPE";
export const MEASURING_INSTRUMENTS_EDIT_FETCH = createFetchType(
  MEASURING_INSTRUMENTS_EDIT_SCOPE
);
export const MEASURING_INSTRUMENTS_EDIT_SUCCESS = createSuccessType(
  MEASURING_INSTRUMENTS_EDIT_SCOPE
);
export const MEASURING_INSTRUMENTS_EDIT_ERROR = createErrorType(
  MEASURING_INSTRUMENTS_EDIT_SCOPE
);

const MEASURING_INSTRUMENTS_DELETE_SCOPE = "MEASURING_INSTRUMENTS_DELETE_SCOPE";
export const MEASURING_INSTRUMENTS_DELETE_FETCH = createFetchType(
  MEASURING_INSTRUMENTS_DELETE_SCOPE
);
export const MEASURING_INSTRUMENTS_DELETE_SUCCESS = createSuccessType(
  MEASURING_INSTRUMENTS_DELETE_SCOPE
);
export const MEASURING_INSTRUMENTS_DELETE_ERROR = createErrorType(
  MEASURING_INSTRUMENTS_DELETE_SCOPE
);

export const MEASURING_INSTRUMENTS_SCOPE = "MEASURING_INSTRUMENTS_SCOPE";
export const MEASURING_INSTRUMENTS_FETCH = createFetchType(
  MEASURING_INSTRUMENTS_SCOPE
);
export const MEASURING_INSTRUMENTS_SUCCESS = createSuccessType(
  MEASURING_INSTRUMENTS_SCOPE
);
export const MEASURING_INSTRUMENTS_ERROR = createErrorType(
  MEASURING_INSTRUMENTS_SCOPE
);
export const MEASURING_INSTRUMENTS_SET = createSetType(
  MEASURING_INSTRUMENTS_SCOPE
);
export const MEASURING_INSTRUMENTS_CLEAR = createClearType(
  MEASURING_INSTRUMENTS_SCOPE
);

export const MEASURING_INSTRUMENTS_SET_TOTAL = createSetType(
  "MEASURING_INSTRUMENTS_TOTAL_SCOPE"
);
export const MEASURING_INSTRUMENTS_SET_ERROR_MESSAGE = createSetType(
  "MEASURING_INSTRUMENTS_ERROR_MESSAGE_SCOPE"
);
