import React from "react";
import PropTypes from "prop-types";
import AddFindingTypeContent from "../../../../components/FindingTypes/AddFindingType/AddFindingTypeContent";

const AddFindingTypePage = () => {
  return <AddFindingTypeContent />;
};

AddFindingTypePage.propTypes = {
  children: PropTypes.node,
};

export default AddFindingTypePage;
