import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import TextInputField from "../../../Field/TextInputField/TextInputField";

const IdentificationNumberField = (props) => {
  const { t } = useTranslation();
  const formik = props?.formik;
  return (
    <>
      <TextInputField
        name="identificationNumber"
        label={t("companySettings.identificationNumber")}
        number
        value={formik?.values?.identificationNumber}
        onChange={formik?.handleChange}
        error={
          formik?.touched?.identificationNumber &&
          formik?.errors?.identificationNumber
        }
        helperText={
          formik?.touched?.identificationNumber &&
          formik?.errors?.identificationNumber
        }
        requiredField={!formik?.values?.identificationNumber}
      />
    </>
  );
};

IdentificationNumberField.propTypes = {
  formik: PropTypes.object,
  isRequired: PropTypes.bool,
};

export default IdentificationNumberField;
