import { React, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { SubjectPreviewContainer, SubjectPreviewIframe } from "./SubjectPreview.styled";
import { useDispatch, useSelector } from "react-redux";
import { selectCommonTemplatePreview } from "../../../../store/selectors/commonTemplatesSelector";
import { fetchCommonTemplatePreview } from "../../../../store/actions/commonTemplates/commonTemplatesActions";

const SubjectPreview = ({valueId}) => {
  const dispatch = useDispatch();
  const commonTemplatePreview = useSelector(selectCommonTemplatePreview);
  const [value, setNewValue] = useState(null)
  const [updatedPreview, setUpdatedPreview] = useState(null);


  useEffect(() => {
    if(valueId===null) {
      setNewValue(1);
    }else {
    dispatch(fetchCommonTemplatePreview({ commonTemplateId:  valueId.id }));
    }
  }, [valueId, value]);

  //Dodati ikonice za item type
  const tableIconPath = require('../../../../assets/images/svg/table.svg').default;
  const textIconPath = require('../../../../assets/images/svg/text.svg').default;
  const graphicalIconPath = require('../../../../assets/images/svg/image.svg').default;
  const optionIconPath = require('../../../../assets/images/svg/tick-circle.svg').default;
 
  useEffect(() => {
    if (commonTemplatePreview) {
      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = commonTemplatePreview;
  
      const textSvgs = tempDiv.querySelectorAll('.textImg');
      const tableSvgs = tempDiv.querySelectorAll('.tableImg');
      const graphicalSvgs = tempDiv.querySelectorAll('.graphicalImg');
      const optionSvgs = tempDiv.querySelectorAll('.optionalImg');
  
      textSvgs.forEach(svg => {
        svg.innerHTML = `<img src="${textIconPath}" style="width: 16px; height: 16px;margin-right: 11px;" />`;
      });
      tableSvgs.forEach(svg => {
        svg.innerHTML = `<img src="${tableIconPath}" style="width: 16px; height: 16px;margin-right: 11px;" />`;
      });
      graphicalSvgs.forEach(svg => {
        svg.innerHTML = `<img src="${graphicalIconPath}" style="width: 16px; height: 16px;margin-right: 11px;" />`;
      });
      optionSvgs.forEach(svg => {
        svg.innerHTML = `<img src="${optionIconPath}" style="width: 16px; height: 16px;margin-right: 11px;" />`;
      });
  
      setUpdatedPreview(tempDiv.innerHTML);
    }
  }, [commonTemplatePreview]);

  return (
    <SubjectPreviewContainer>
      <SubjectPreviewIframe srcDoc= {updatedPreview}/> 
    </SubjectPreviewContainer>
  );
};

SubjectPreview.propTypes = {
  children: PropTypes.node,
  valueId:PropTypes.any,
};

export default SubjectPreview;
