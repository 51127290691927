import { createSelector } from "reselect";

const licensesSelector = (state) => state.licenses;

export const selectLicenses = createSelector(
  licensesSelector,
  (state) => state.licenses
);
export const selectTotalLicenses = createSelector(
  licensesSelector,
  (state) => state.totalLicenses
);
export const selectSingleLicense = createSelector(
  licensesSelector,
  (state) => state.singleLicense
);
export const selectLicensesData = createSelector(
  licensesSelector,
  (state) => state.licenses.data
);
