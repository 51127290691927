import { Box } from "@mui/material";
import styled from "styled-components";

export const CompaniesEditUserPageContainer = styled(Box)`
  /* display: flex;
  flex-direction: row;
  gap: 80px;
  justify-content: space-between;
  width: 100%; */
`;
