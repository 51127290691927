import React from "react";
import PropTypes from "prop-types";
import {
  ActionRendererExportIconContainer,
  ActionRendererContainer,
  ActionRendererEditIconContainer,
  // ActionRendererUndoIconContainer,
  ActionRendererExportIcon,
  ActionRendererEditIcon,
  // ActionRendererUndoIcon,
  ActionRendererMailIconContainer,
  ActionRendererMailIcon,
} from "./ActionRenderer.styled";
import { FINDINGS_EDIT_PAGE } from "../../../../../constants/pages";
import { replaceInRoute } from "../../../../../util/helpers/routeHelpers";
import Tooltip from "../../../../Tooltip/Tooltip";
import i18n from "../../../../../i18n";
import { statusTableConstants } from "../../../../../constants/findingStatusConstants";
import {
  // fetchChangeStateFinding,
  fetchPrintFinding,
} from "../../../../../store/actions/findings/findingsActions";
import {
  closeModal,
  toggleSendMailsModal,
  toggleSetLoadingModal,
} from "../../../../../store/actions/modal/modalActions";

const ActionRenderer = (props) => {
  const handlePrintFinding = () => {
    props?.dispatch(toggleSetLoadingModal({ rank: 1 }));
    props?.dispatch(
      fetchPrintFinding({
        findingId: props?.value?.findingId,
        findingName: props?.value?.findingNumber,
        handleApiResponseSuccess: () => props?.dispatch(closeModal()),
      })
    );
  };

  // const handleApiResponseSuccess = () => {
  //   props?.rendererProps?.refetch();
  // };
  // const handleUndoFinding = () => {
  //   props?.dispatch(
  //     fetchChangeStateFinding({
  //       findingId: props?.value?.findingId,
  //       stateId: statusTableConstants.inProgress.id,
  //       handleApiResponseSuccess,
  //     })
  //   );
  // };
  const handleSendMailFinding = () => {
    props?.dispatch(
      toggleSendMailsModal({
        rank: 1,
        title: i18n.t("findings.sendMailsModal.title"),
        findingId: props?.value?.findingId,
      })
    );
  };
  return (
    <ActionRendererContainer>
      {/* {props?.value?.status === statusTableConstants.cancelled.id && (
        <Tooltip title={i18n.t("findings.undoCancelled")}>
          <ActionRendererUndoIconContainer onClick={handleUndoFinding}>
            <ActionRendererUndoIcon />
          </ActionRendererUndoIconContainer>
        </Tooltip>
      )} */}
      {props?.value?.status === statusTableConstants.closed.id && (
        <Tooltip title={i18n.t("findings.forwardToMail")}>
          <ActionRendererMailIconContainer onClick={handleSendMailFinding}>
            <ActionRendererMailIcon />
          </ActionRendererMailIconContainer>
        </Tooltip>
      )}
      <Tooltip title={i18n.t("common.pdf")}>
        {props?.value?.status !== statusTableConstants.cancelled.id && (
          <ActionRendererExportIconContainer onClick={handlePrintFinding}>
            <ActionRendererExportIcon />
          </ActionRendererExportIconContainer>
        )}
      </Tooltip>
      <Tooltip title={i18n.t("findings.tooltip.edit")}>
        <ActionRendererEditIconContainer
          to={replaceInRoute(FINDINGS_EDIT_PAGE, {
            findingId: props?.value?.findingId,
          })}
        >
          <ActionRendererEditIcon />
        </ActionRendererEditIconContainer>
      </Tooltip>
    </ActionRendererContainer>
  );
};

ActionRenderer.propTypes = {
  children: PropTypes.node,
  dispatch: PropTypes.func,
  value: PropTypes.object,
  rendererProps: PropTypes.any,
};

export default ActionRenderer;
