import styled from "styled-components";
import { Box, Typography } from "@mui/material";
import selectedTheme from "../../themes";
import { TextInputFieldContainer } from "../Field/TextInputField/TextInputField.styled";
import { ImagePickerContainer } from "../ImagePicker/ImagePicker.styled";
import ActivateIcon from "../Icon/Icons/ActivateIcon";
import DeactivateIcon from "../Icon/Icons/DeactivateIcon";
import Icon from "../Icon/Icon";

export const ClickIconContainer = styled(Icon)`
  cursor: pointer;
  width: 20px;
  max-width: 20px;
  min-width: 20px;
  height: 20px;
  max-height: 20px;
  min-height: 20px;
`;
export const ActionRendererActivateIcon = styled(ActivateIcon)`
  width: 20px;
  max-width: 20px;
  min-width: 20px;
  height: 20px;
  max-height: 20px;
  min-height: 20px;
`;
export const ActionRendererDeactivateIcon = styled(DeactivateIcon)`
  width: 20px;
  max-width: 20px;
  min-width: 20px;
  height: 20px;
  max-height: 20px;
  min-height: 20px;
`;

export const SettingsContentContainer = styled(Box)`
  /* margin-left: 8vw; */
  padding-bottom: 130px;
  position: relative;
  min-height: calc(100vh - 72px);
  @media (max-width: 600px) {
    flex-direction: column;
    gap: 0;
    padding-bottom: 32px;
    & ${TextInputFieldContainer} input {
      width: 100%;
    }
    & ${TextInputFieldContainer} div:has(input) {
      width: 100%;
    }
    & ${ImagePickerContainer} {
      & img {
        max-width: calc(100vw - 52px);
      }
      & form {
        max-width: calc(100vw - 52px);
      }
    }
  }
`;

export const FormContainer = styled.form``;

export const ButtonsContainer = styled(Box)`
  display: flex;
  gap: 15px;
  width: 100%;
  justify-content: center;
`;

export const RequiredFieldsText = styled(Typography)`
  font-family: ${selectedTheme.fonts.mainText};
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  /* margin-bottom: 22px; */
  margin-top: 16px;
  text-align: center;
`;

export const ButtonsAreaContainer = styled(Box)`
  /* position: absolute;
  bottom: 40px;
  left: 0;
  right: 0;
  width: 100%;
  justify-content: center;
  text-align: center; */
  display: flex;
  flex-direction: column;
`;

export const ErrorMessage = styled(Typography)`
  font-family: ${selectedTheme.fonts.mainText};
  font-size: 12px;
  font-weight: 500;
  color: ${selectedTheme.colors.errorMessage};
  margin-top: 0;
`;

export const TabsContainer = styled(Box)`
  display: flex;
  margin: 38px 0;
`;

export const AppSettings = styled(Box)`
  margin-right: 24px;
  cursor: pointer;
  &:hover {
    border-bottom: 3px solid #161616;
  }
  ${(props) => !props?.active && `border-bottom: 3px solid #161616;`}
`;

export const SettingsNavText = styled(Typography)`
  font-family: ${selectedTheme.fonts.mainText};
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 16px;
`;

export const PersonalSettings = styled(Box)`
  cursor: pointer;
  &:hover {
    border-bottom: 3px solid #161616;
  }
  ${(props) => props?.active && `border-bottom: 3px solid #161616;`}
`;

export const TitleButtonsContainer = styled(Box)`
  display: flex;
  align-items: start;
  justify-content: space-between;
`;
export const PageTitleContainer = styled(Box)`
  display: flex;
  align-items: center;
  gap: 24px;
`;

export const MailAndArchiveContainer = styled(Box)`
  display: flex;
  flex-direction: column;
`;
