import React from "react";
import PropTypes from "prop-types";
import {
  ActionRendererContainer,
  ActionRendererDeleteStandardIconContainer,
  ActionRendererEditStandardIconContainer,
} from "./ActionRenderer.styled";
import EditIcon from "../../../../../Icon/Icons/EditIcon";
import DeleteIcon from "../../../../../Icon/Icons/DeleteIcon.js";
import { toggleAddFindingStaffModal } from "../../../../../../store/actions/modal/modalActions";
import i18n from "../../../../../../i18n";
import Tooltip from "../../../../../Tooltip/Tooltip";
import WithPermissions from "../../../../../WithPermissions/WithPermissions";
import { MANAGER_ROLE } from "../../../../../../constants/rolesConstants";

const ActionRenderer = (props) => {
  const handleEditStaff = () => {
    props?.dispatch(
      toggleAddFindingStaffModal({
        title: i18n.t("findings.staffs.changeStaff"),
        staffObject: props?.prefilledData?.actions?.staffObject,
        isEditing: true,
        rank: 1,
      })
    );
  };

  const handleRemoveEntity = () => {
    let indexOfEntity = null;
    if (props?.value?.index != null) indexOfEntity = props?.value?.index;
    props?.rendererProps?.handleRemoveEntity(props?.value, indexOfEntity);
  };

  return (
    <ActionRendererContainer>
      <Tooltip title={i18n.t("common.change")}>
        <ActionRendererEditStandardIconContainer onClick={handleEditStaff}>
          <EditIcon />
        </ActionRendererEditStandardIconContainer>
      </Tooltip>
      <WithPermissions roles={[MANAGER_ROLE]}>
        <Tooltip title={i18n.t("standards.tooltip.deleteStandard")}>
          <ActionRendererDeleteStandardIconContainer
            onClick={handleRemoveEntity}
          >
            <DeleteIcon />
          </ActionRendererDeleteStandardIconContainer>
        </Tooltip>
      </WithPermissions>
    </ActionRendererContainer>
  );
};

ActionRenderer.propTypes = {
  children: PropTypes.node,
  dispatch: PropTypes.func,
  value: PropTypes.object,
  rendererProps: PropTypes.any,
  refetchTable: PropTypes.func,
  prefilledData: PropTypes.object,
};

export default ActionRenderer;
