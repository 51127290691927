import React, { useEffect } from "react";
import PropTypes from "prop-types";
import NewUserForm from "../../components/Users/NewUser/NewUserForm";
import {
  fetchAcceptInviteUser,
  fetchValidateInviteUser,
  setInvitationData,
} from "../../store/actions/user/userActions";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectInvitationData } from "../../store/selectors/usersSelectors";
import { useTranslation } from "react-i18next";
import { fetchUser } from "../../store/actions/login/loginActions";
import { HOME_PAGE, INVITE_TIMEOUT_PAGE } from "../../constants/pages";
import history from "../../store/utils/history";
import { InvitePageContainer } from "./InvitePage.styled";

const InvitePage = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const prefilledData = useSelector(selectInvitationData);

  const handleApiResponseSuccessLogin = () => {
    history.push({
      pathname: HOME_PAGE,
      state: {
        from: location.pathname,
      },
    });
  };

  const handleApiResponseErrorLogin = () => {
    console.log("error login");
  };

  const handleApiResponseErrorValidate = () => {
    history?.replace(INVITE_TIMEOUT_PAGE);
  };

  const handleApiResponseSuccessValidate = (data) => {
    dispatch(
      setInvitationData({
        email: data?.mail,
        token: data?.token,
      })
    );
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    dispatch(
      fetchValidateInviteUser({
        token: params.get("token"),
        handleApiResponseSuccess: handleApiResponseSuccessValidate,
        handleApiResponseError: handleApiResponseErrorValidate,
      })
    );
  }, [location]);
  const handleSubmit = () => {};
  const userId = 0;
  const companyId = 0;
  const customRedirect = (values) => {
    dispatch(
      fetchUser({
        values: {
          userName: values?.email,
          password: values?.password,
        },
        handleApiResponseSuccess: handleApiResponseSuccessLogin,
        handleApiResponseError: handleApiResponseErrorLogin,
      })
    );
  };
  return (
    <InvitePageContainer>
      <NewUserForm
        onSubmit={handleSubmit}
        title={t("acceptInvitePage.title")}
        dispatchFunction={fetchAcceptInviteUser}
        prefilledData={prefilledData}
        userId={userId}
        companyId={companyId}
        isInvited
        customRedirect={customRedirect}
      />
    </InvitePageContainer>
  );
};

InvitePage.propTypes = {
  children: PropTypes.node,
};

export default InvitePage;
