import React, { useCallback, useMemo, useRef } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import licensesTableConstants from "../../../../constants/tableConstants/licensesTableConstants";
import TextRenderer from "../../../Table/Renderers/TextRenderer/TextRenderer";
import {
  formatDate,
  formatDateTime,
} from "../../../../util/helpers/dateHelpers";
import { makeToastMessage } from "../../../../store/utils/makeToastMessage";
import { APPLICATION_TOAST_CONTAINER_ID } from "../../../../constants/toastConstants";
import Table from "../../../Table/Table";
import {
  selectLicensesData,
  selectTotalLicenses,
} from "../../../../store/selectors/licensesSelectors";
import {
  clearLicenses,
  fetchCreateLicense,
  fetchLicenses,
} from "../../../../store/actions/licenses/licensesActions";
import { useRouteMatch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  closeModal,
  toggleLicenseModal,
} from "../../../../store/actions/modal/modalActions";
import useAuth from "../../../../hooks/useAuth";
import { SUPERADMIN_ROLE } from "../../../../constants/rolesConstants";
import { selectMyCompany } from "../../../../store/selectors/companiesSelector";

const LicensesTable = () => {
  const tableRef = useRef();
  const { t } = useTranslation();
  const routeMatch = useRouteMatch();
  const dispatch = useDispatch();
  const { hasRole } = useAuth();
  const myCompany = useSelector(selectMyCompany);

  const companyId = useMemo(
    () => routeMatch?.params?.companyId || myCompany?.id,
    [routeMatch?.params, myCompany]
  );

  const hideAddAndUpdate = useMemo(() => {
    return !hasRole(SUPERADMIN_ROLE);
  }, [hasRole]);

  const handleApiResponseSuccess = (payload) => {
    dispatch(closeModal());
    tableRef?.current?.forceRefetch();
    const titleI18Key = payload?.isEditing
      ? "toast.success.editedTitle"
      : "toast.success.createdTitle";
    const descriptionI18Key = payload?.isEditing
      ? "toast.success.editedDescription"
      : "toast.success.createdDescription";
    makeToastMessage(
      {
        title: t(titleI18Key, {
          typeOfData: t("licenses.typeOfData"),
        }),
        description: t(descriptionI18Key, {
          name: t("licenses.typeOfData"),
          typeOfData: t("licenses.typeOfDataPlural").toLowerCase(),
        }),
      },
      {
        containerId: APPLICATION_TOAST_CONTAINER_ID,
      }
    );
  };

  const handleCreateLicense = (values) => {
    dispatch(
      fetchCreateLicense({ ...values, companyId, handleApiResponseSuccess })
    );
  };

  const handleClickAddButton = () => {
    dispatch(
      toggleLicenseModal({
        title: t("licenses.modal.newLicense"),
        handleSubmit: handleCreateLicense,
      })
    );
  };

  const tableColumns = useMemo(() => {
    let columnsObject = Object.keys(licensesTableConstants).map((property) => ({
      ...licensesTableConstants[property],
      field: t(licensesTableConstants[property].i18key),
      propertyName: property,
      backendProperty: licensesTableConstants[property].backendProperty,
      style: licensesTableConstants[property]?.style,
      renderer: licensesTableConstants[property]?.renderer || TextRenderer,
    }));
    if (hideAddAndUpdate) return columnsObject.slice(0, -1);
    return columnsObject;
  }, [licensesTableConstants, t]);

  const mapDataFunction = useCallback((newData) => {
    return newData?.map?.((singleData) => {
      let mappedDataObject = {
        idString: singleData?.id,
        startDate: formatDate(singleData?.startDate || new Date().toString()),
        expirationDate: formatDate(
          singleData?.expirationDate || new Date().toString()
        ),
        status: singleData?.isDeactivated
          ? t("licenses.table.status.inactive")
          : t("licenses.table.status.active"),
        lastChanges: {
          date: formatDateTime(
            singleData?.updatedAtUtc || new Date().toString()
          ),
          author: `${singleData?.updatedByUser?.firstName || ""} ${
            singleData?.updatedByUser?.lastName || ""
          }`,
        },
        actions: {
          licenseId: singleData?.id,
          t,
          dispatch,
          isDeactivated: singleData?.isDeactivated,
          companyId,
          tableRef,
        },
      };
      if (hideAddAndUpdate)
        if (hideAddAndUpdate) delete mappedDataObject?.actions;
      return mappedDataObject;
    });
  }, []);

  return (
    <Table
      tableColumns={tableColumns}
      searchPlaceholder={t("licenses.table.searchPlaceholder")}
      dataSelector={selectLicensesData}
      totalDataSelector={selectTotalLicenses}
      customPayload={{ companyId }}
      sortingColumn={licensesTableConstants.startDate.backendProperty}
      sortDesc
      hideAddButton={hideAddAndUpdate}
      hideSearch
      dispatchFunction={fetchLicenses}
      mapDataFunction={mapDataFunction}
      handleClickAddButton={handleClickAddButton}
      clearDispatchFunction={clearLicenses}
      ref={tableRef}
    />
  );
};

LicensesTable.propTypes = {
  children: PropTypes.node,
};

export default LicensesTable;
