import styled from "styled-components";
import { Box } from "@mui/material";
import SidebarIcon from "../Icon/Icons/SidebarIcon";

export const HeaderContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: ${(props) =>
    props?.$sidebar ? "calc(100% - 258px)" : "calc(100% - 72px)"};
  position: fixed;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 8px;
  top: 0;
  right: 0;
  box-sizing: border-box;
  z-index: 150;
  background-color: white;
  transition-duration: 0.2s;
  @media (max-width: 600px) {
    width: 100%;
  }
`;

export const HeaderInfo = styled(Box)`
  display: flex;
  gap: 10px;
  @media (max-width: 600px) {
    gap: 22px;
    align-items: center;
  }
`;

export const HeaderIcon = styled(Box)``;

export const HeaderProfile = styled(Box)``;

export const HeaderProfileImage = styled.img``;
export const SidebarToggleButton = styled(SidebarIcon)`
  @media (min-width: 600px) {
    display: none;
  }
`;
export const HeaderRightContainer = styled(Box)`
  display: flex;
  gap: 12px;
  align-items: center;
  border: 1.67px solid lightgray;
  width: 255px;
  height: 52px;
  justify-content: space-around;
  border-radius: 8px;
  padding: 6px 16px;

  @media (max-width: 600px) {
    width: 112px;
    height: 52px;
    gap: 8px;
    padding: 6px 15px;
  }
`;
