import React from "react";
// import PropTypes from 'prop-types'
import { ReactComponent as ItemsIconSvg } from "../../../assets/images/svg/questions.svg";

const ItemsIcon = (props) => {
  return <ItemsIconSvg {...props} />;
};

ItemsIcon.propTypes = {};

export default ItemsIcon;
