import React from "react";
// import PropTypes from 'prop-types'
import { ReactComponent as InviteIconSvg } from "../../../assets/images/svg/invite.svg";

const InviteIcon = (props) => {
  return <InviteIconSvg {...props} />;
};

InviteIcon.propTypes = {};

export default InviteIcon;
