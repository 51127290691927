import { Box } from "@mui/material";
import styled from "styled-components";
import Icon from "../../../../Icon/Icon";
import { Link } from "react-router-dom";

export const ActionRendererContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 17px;
  @media (max-width: 600px) {
    position: absolute;
    top: 17px;
    right: 15px;
  }
`;
export const ActionRendererEditDocumentIconContainer = styled(Link)`
  cursor: pointer;
`;

export const ActionRendererDeleteDocumentIconContainer = styled(Icon)`
  cursor: pointer;
`;
