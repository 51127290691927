import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import { AppLayoutContainer, Content, Sidebar } from "./AppLayout.styled";
import { useSelector } from "react-redux";
import { selectIsSidebarOpened } from "../../store/selectors/sidebarSelector";
import { selectJWTToken } from "../../store/selectors/loginSelectors";
import useIsMobile from "../../hooks/useIsMobile";

const AppLayout = (props) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [firstRender, setFirstRender] = useState(true);
  const jwtToken = useSelector(selectJWTToken);
  const isSidebarOpened = useSelector(selectIsSidebarOpened);
  const { isMobile } = useIsMobile();
  useEffect(() => {
    if (jwtToken) {
      if (jwtToken.jwtToken?.token) setIsLoggedIn(true);
      else setIsLoggedIn(false);
    } else setIsLoggedIn(false);
  }, [jwtToken]);

  useEffect(() => {
    if (isMobile) setFirstRender(false);
  }, [isMobile]);

  return (
    <AppLayoutContainer className={props?.className}>
      {props?.children}
      <Grid container wrap="nowrap">
        {isLoggedIn && (
          <Sidebar
            $isSidebarOpened={isSidebarOpened && (!isMobile || !firstRender)}
            item
            style={{
              width: isSidebarOpened ? "258px" : "73px",
              minWidth: isSidebarOpened ? "258px" : "73px",
            }}
          >
            {props?.sidebar}
          </Sidebar>
        )}
        <Content xs item>
          {props?.content}
        </Content>
      </Grid>
    </AppLayoutContainer>
  );
};

AppLayout.propTypes = {
  children: PropTypes.node,
  sidebar: PropTypes.node,
  header: PropTypes.node,
  content: PropTypes.node,
  rightCard: PropTypes.node,
  className: PropTypes.string,
};

export default AppLayout;
