import { Box } from "@mui/material";
import styled, { css } from "styled-components";
import selectedTheme from "../../themes";
import CopyIconRoot from "../Icon/Icons/CopyIcon";
import ExportIconRoot from "../Icon/Icons/ExportIcon";
import UndoIconRoot from "../Icon/Icons/UndoIcon";
import TrashIconRoot from "../Icon/Icons/TrashIcon";
import ActivateIconRoot from "../Icon/Icons/ActivateIcon";
import DeactivateIconRoot from "../Icon/Icons/DeactivateIcon";
import { TitleTypography } from "../Typography/Title/Title.styled";
import MailIcon from "../Icon/Icons/MailIcon";
import NewEyeIcon from "../Icon/Icons/NewEye";

export const PageTitleContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 12px;
  @media (max-width: 600px) {
    & ${TitleTypography} {
      white-space:  nowrap; /*normal;
      /overflow: hidden;  */
    }
    margin-bottom: 24px;
  }
`;
export const HighlightedText = styled(Box)`
  font-family: ${selectedTheme.fonts.mainText};
  font-weight: 600;
  font-size: 30px;
  line-height: 36px;
  font-feature-settings: "salt" on;
  color: ${selectedTheme.colors.orangeBorder};
  @media (max-width: 600px) {
    font-size: 18px;
    line-height: 24px;
  }
`;
export const CopyIcon = styled(CopyIconRoot)`
  cursor: pointer;
  ${(props) =>
    props?.$disabledIcon &&
    css`
      cursor: initial;
      & path {
        stroke: ${selectedTheme.colors.iconDisabledColor};
      }
    `}
  @media (max-width: 600px) {
    margin-left: 0px;
  }
`;
export const ExportIcon = styled(ExportIconRoot)`
  cursor: pointer;
  ${(props) =>
    props?.$disabledIcon &&
    css`
      cursor: initial;
      & path {
        stroke: ${selectedTheme.colors.iconDisabledColor};
      }
    `}
`;

export const EyeIcon = styled(NewEyeIcon)`
  cursor: pointer;
  ${(props) =>
    props?.$disabledIcon &&
    css`
      cursor: initial;
      & path {
        stroke: ${selectedTheme.colors.iconDisabledColor};
      }
    `}
  width: 23px;
  height: 23px;
`;
export const SendMailIcon = styled(MailIcon)`
  cursor: pointer;
  ${(props) =>
    props?.$disabledIcon &&
    css`
      cursor: initial;
      & path {
        stroke: ${selectedTheme.colors.iconDisabledColor};
      }
    `}
`;
export const UndoIcon = styled(UndoIconRoot)`
  cursor: pointer;
  ${(props) =>
    props?.$disabledIcon &&
    css`
      cursor: initial;
      & path {
        stroke: ${selectedTheme.colors.iconDisabledColor};
      }
    `}
`;
export const TrashIcon = styled(TrashIconRoot)`
  cursor: pointer;
  ${(props) =>
    props?.$disabledIcon &&
    css`
      cursor: initial;
      & path {
        stroke: ${selectedTheme.colors.iconDisabledColor};
      }
    `}
`;
export const BlockIcon = styled(DeactivateIconRoot)`
  cursor: pointer;
  ${(props) =>
    props?.$disabledIcon &&
    css`
      cursor: initial;
      & path {
        stroke: ${selectedTheme.colors.iconDisabledColor};
      }
    `}
`;
export const UnblockIcon = styled(ActivateIconRoot)`
  cursor: pointer;
  ${(props) =>
    props?.$disabledIcon &&
    css`
      cursor: initial;
      & path {
        stroke: ${selectedTheme.colors.iconDisabledColor};
      }
    `}
`;
export const TitleDetails = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  @media (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const TitleContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
`;

export const ActionsContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin-left: 8px;
  align-items: center;
  & > div {
    width: 22px;
    height: 22px;
  }
  & > div > svg {
    width: 22px;
    height: 22px;
  }
`;

export const FindingTypeContainer = styled(Box)`
  padding: 8px 16px 8px 16px;
  height: 36px;
  border-radius: 24px;
  gap: 4px;
  background: ${selectedTheme.colors.findingTypeColor};
  font-family: ${selectedTheme.fonts.mainText};
  color: ${selectedTheme.colors.findingTypeTextColor};
  max-width: fit-content;
  font-size: 18px;
  font-weight: 500;
  line-height: 20px;
  font-feature-settings: "salt" on;
`;

export const DetailsContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: flex-start;
`;
