import React from "react";
import PropTypes from "prop-types";
import {
  FindingTableColumnEditIcon,
  FindingTableSingleInputContainer,
  FindingTableSingleInputLabelContainer,
  FindingTableSingleInputSubtitle,
  FindingTableSingleInputTitle,
  FindingTableSingleInputTitlesContainer,
} from "./FindingTableSingleInput.styled";
import { useDispatch, useSelector } from "react-redux";
import {
  closeModal,
  toggleRenameColumnsModal,
} from "../../../../../../store/actions/modal/modalActions";
import {
  selectFindingColumnAnswer,
  selectSingleFindingToEdit,
  selectFindingColumns,
  selectIsFindingSettingsChanged,
  selectSingleFinding,
} from "../../../../../../store/selectors/findingsSelectors";
import {
  setFindingColumnFromModal,
  setFindingColumnAnswer,
  setFindingsChanged,
  setSingleFinding,
} from "../../../../../../store/actions/findings/findingsActions";
import { useState } from "react";
import { useEffect } from "react";
import { selectMyProfileId } from "../../../../../../store/selectors/loginSelectors";

const FindingTableSingleInput = (props) => {
  const dispatch = useDispatch();
  const [answerObject, setAnswerObject] = useState({
    oldAnswer: "",
    currentAnswer: "",
  });
  const [isAnswerSetted, setIsAnswerSetted] = useState(false); //eslint-disable-line
  const singleFinding = useSelector(selectSingleFinding);
  const singleFindingToEdit = useSelector(selectSingleFindingToEdit);
  const userId = useSelector(selectMyProfileId);
  const columns = useSelector(selectFindingColumns(props?.questionId));
  const isFindingChanged = useSelector(selectIsFindingSettingsChanged);
  const columnAnswer = useSelector(
    selectFindingColumnAnswer({
      rowIndex: props?.rowCount - 1,
      columnId: props?.columnId,
      questionId: props?.questionId,
    })
  );

  // const usedColumnTitles = useMemo(
  //   () =>
  //     columns.filter(
  //       (singleColumn) => singleColumn.columnTitle !== props?.title
  //     ),
  //   [columns]
  // );
  // const usedColumnSubtitles = useMemo(
  //   () =>
  //     columns.filter(
  //       (singleColumn) => singleColumn.columnTitle === props?.title
  //     ),
  //   [columns]
  // );

  useEffect(() => {
    if (columnAnswer !== answerObject?.oldAnswer) {
      setAnswerObject({
        oldAnswer: columnAnswer,
        currentAnswer: columnAnswer,
      });
      // setIsAnswerSetted(true);
    }
  }, [columnAnswer]);

  const handleSubmit = (values) => {
    dispatch(setSingleFinding({...singleFinding, findingSubjects: [...singleFindingToEdit?.findingSubjects]}));
    dispatch(
      setFindingColumnFromModal({
        column: {
          columnId: props?.columnId,
          ...values,
        },
      })
    );
    dispatch(closeModal());
  };

  const handleChange = (event) => {
    if (props?.immediateApply) {
      props?.immediateApplyFunction?.();
    }
    if (!isFindingChanged) dispatch(setFindingsChanged(true));
    setAnswerObject((prevState) => ({
      ...prevState,
      currentAnswer: event.target.value,
    }));
  };

  const handleBlur = (event) => {
    dispatch(
      setFindingColumnAnswer({
        rowIndex: props?.rowCount - 1,
        columnId: props?.columnId,
        questionId: props?.questionId,
        answer: event.target.value,
        answerId: props?.answerId,
        userId,
        isEdited: answerObject?.oldAnswer !== event.target.value
        // edited: answerObject.currentAnswer !== answerObject.oldAnswer
      })
    );
  };

  const handleClickEditColumn = () => {
    dispatch(
      toggleRenameColumnsModal({
        title: props?.title,
        subtitle: props?.subtitle,
        subtitleRequired: props?.subtitle,
        columnId: props?.columnId,
        columns,
        handleSubmit,
      })
    );
  };

  return (
    <FindingTableSingleInputContainer
    onFocus={props?.clickListener}
      customLabelComponent={
        <FindingTableSingleInputLabelContainer>
          <FindingTableSingleInputTitlesContainer>
            <FindingTableSingleInputTitle>
              {props?.title}
            </FindingTableSingleInputTitle>
            {props?.subtitle && (
              <FindingTableSingleInputSubtitle>
                {props?.subtitle}
              </FindingTableSingleInputSubtitle>
            )}
          </FindingTableSingleInputTitlesContainer>
          {!props?.disabled && (
            <FindingTableColumnEditIcon onClick={handleClickEditColumn} />
          )}
        </FindingTableSingleInputLabelContainer>
      }
      disabledField={props?.disabled}
      value={answerObject?.currentAnswer}
      onChange={handleChange}
      onBlur={handleBlur}
    />
  );
};

FindingTableSingleInput.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  subtitleRequired: PropTypes.bool,
  questionId: PropTypes.number,
  columnId: PropTypes.number,
  answerId: PropTypes.number,
  rowCount: PropTypes.number,
  immediateApply: PropTypes.bool,
  disabled: PropTypes.bool,
  immediateApplyFunction: PropTypes.func,
  clickListener: PropTypes.func,
};

export default FindingTableSingleInput;
