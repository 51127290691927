import selectedTheme from "../themes";

export const statusTableConstants = {
  inProgress: {
    id: 1,
    i18key: "findings.status.inProgress",
  },
  completed: {
    id: 2,
    i18key: "findings.status.completed",
  },
  closed: {
    id: 3,
    i18key: "findings.status.closed",
  },
  cancelled: {
    id: 4,
    i18key: "findings.status.cancelled",
  },
};

export const statusTableConstantsAsArray = Object.keys(
  statusTableConstants
).map((singleConstant) => ({
  ...statusTableConstants[singleConstant],
}));

export const getAllStatusColors = (
  options = {},
  nodeOptions = [{}, {}, {}, {}]
) => {
  return Object.keys(statusTableConstants).map((singleConstant, index) => {
    const statusOptions = { ...options, ...nodeOptions[index] };
    return {
      ...statusTableConstants[singleConstant],
      tooltip: statusOptions?.tooltip || "",
      disabled: statusOptions?.disabled,
      colors: {
        backgroundColor: statusOptions?.disabled
          ? selectedTheme.colors.findingStatus.disabled.background
          : statusOptions?.outsideTable
          ? statusOptions?.selected
            ? selectedTheme.colors.findingStatus[singleConstant]
                .selectedBackgroundColor
            : selectedTheme.colors.findingStatus[singleConstant]
                .outsideTableBackgroundColor
          : selectedTheme.colors.findingStatus[singleConstant].backgroundColor,
        color: statusOptions?.disabled
          ? selectedTheme.colors.findingStatus.disabled.textColor
          : statusOptions?.outsideTable
          ? statusOptions?.selected
            ? selectedTheme.colors.findingStatus[singleConstant]
                .selectedTextColor
            : selectedTheme.colors.findingStatus[singleConstant]
                .outsideTableTextColor
          : selectedTheme.colors.findingStatus[singleConstant].textColor,
        circleColor: statusOptions?.disabled
          ? selectedTheme.colors.findingStatus.disabled.circleColor
          : statusOptions?.outsideTable
          ? statusOptions?.selected
            ? selectedTheme.colors.findingStatus[singleConstant]
                .selectedCircleColor
            : selectedTheme.colors.findingStatus[singleConstant]
                .outsideTableCircleColor
          : selectedTheme.colors.findingStatus[singleConstant].circleColor,
        borderColor: statusOptions?.disabled
          ? selectedTheme.colors.findingStatus.disabled.borderColor
          : statusOptions?.outsideTable
          ? statusOptions?.selected
            ? selectedTheme.colors.findingStatus[singleConstant]
                .selectedBorderColor
            : selectedTheme.colors.findingStatus[singleConstant]
                .outsideTableBorderColor
          : selectedTheme.colors.findingStatus[singleConstant].borderColor,
      },
    };
  });
};

export const getStatusColorsById = (id, options = {}) => {
  return getAllStatusColors(options).find((item) => item?.id === id);
};
