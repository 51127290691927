import React from "react";
import { Route, Switch } from "react-router-dom";
import ClientsContent from "../../components/Clients/ClientsContent";
import AuthRoute from "../../components/Router/AuthRoute";
// import SidebarNavigation from "../../components/SidebarNavigation/SidebarNavigation";
import {
  CLIENTS_PAGE,
  EDIT_CLIENT_PAGE,
  NEW_CLIENT_PAGE,
} from "../../constants/pages";
import { MANAGER_ROLE, OPERATOR_ROLE } from "../../constants/rolesConstants";
// import SidebarLayout from "../../layouts/SidebarLayout/SidebarLayout";
import EditClientPage from "./EditClientPage/EditClientPage";
import NewClientPage from "./NewClientPage/NewClientPage";

const ClientsPage = () => {
  return (
    <Switch>
      <Route exact path={CLIENTS_PAGE} component={ClientsContent} />
      <AuthRoute
        roles={[MANAGER_ROLE]}
        exact
        path={NEW_CLIENT_PAGE}
        component={NewClientPage}
      />
      <AuthRoute
        roles={[MANAGER_ROLE, OPERATOR_ROLE]}
        exact
        path={EDIT_CLIENT_PAGE}
        component={EditClientPage}
      />
    </Switch>
  );
};

export default ClientsPage;
