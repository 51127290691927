import { useFormik } from "formik";
import PropTypes from "prop-types";
import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import { EDIT_STUFF_PAGE, STUFFS_PAGE } from "../../../constants/pages";
import { ASC_KEY, FIRST_NAME_KEY } from "../../../constants/queryConstants";
import { APPLICATION_TOAST_CONTAINER_ID } from "../../../constants/toastConstants";
import useIsMobile from "../../../hooks/useIsMobile";
import { newStuffInitiaValues } from "../../../initialValues/newStuffInitialValues";
import {
  closeLoadingModal,
  closeModal,
  toggleSetDeleteModal,
  toggleSetLoadingModal,
} from "../../../store/actions/modal/modalActions";
import {
  fetchDeleteStuffs,
  fetchStuffs,
} from "../../../store/actions/stuffs/stuffsActions";
import { fetchUsers } from "../../../store/actions/user/userActions";
import { selectUsersData } from "../../../store/selectors/usersSelectors";
import history from "../../../store/utils/history";
import { makeToastMessage } from "../../../store/utils/makeToastMessage";
import { replaceInRoute } from "../../../util/helpers/routeHelpers";
import newStuffValidationSchema from "../../../validations/newStuffValidationSchema";
import AutocompleteField from "../../Field/AutocompleteField/AutocompleteField";
import CircularLoader from "../../Loader/CircularLoader/CircularLoader";
import ActionButtons from "../../PageTitle/ActionButtons/ActionButtons";
import PageTitle from "../../PageTitle/PageTitle";
import EmailField from "./EmailField/EmailField";
import FirstNameField from "./FirstNameField/FirstNameField";
import LastNameField from "./LastNameField/LastNameField";
import LicenseField from "./LicenseField/LicenseField";
import LicenseNumberField from "./LicenseNumberField/LicenseNumberField";
import {
  NewStuffFormContainer,
  NewStuffInputsContainer,
  NewStuffLeftColumn,
  NewStuffRightColumn,
} from "./NewStuffForm.styled";
import RankField from "./RankField/RankField";
import SignatureField from "./SignatureField/SignatureField";

const NewStuffForm = (props) => {
  const dispatch = useDispatch();
  const users = useSelector(selectUsersData);
  const routeMatch = useRouteMatch();
  const stuffId = routeMatch.params?.stuffId;
  const { t } = useTranslation();
  const { isMobile } = useIsMobile();

  useEffect(() => {
    dispatch(
      fetchUsers({
        page: 1,
        size: 200,
        sorting: {
          column: FIRST_NAME_KEY,
          sortDirection: ASC_KEY,
        },
        customPayload: { isActive: true },
        handleApiResponseError,
      })
    );
  }, []);

  const goBack = (payload) => {
    dispatch(closeModal());
    history.replace({
      pathname: STUFFS_PAGE,
      state: {
        name: `${formik?.values?.firstName} ${formik?.values?.lastName}`,
        isDeleted: payload?.isDeleted || false,
      },
    });
  };

  const handleApiResponseError = () => {
    dispatch(closeLoadingModal());
  };

  const handleApiResponseSuccess = (payload) => {
    if (!payload?.isDeleted) {
      dispatch(closeModal());
      const titleI18Key = props?.isEditing
        ? "toast.success.editedTitle"
        : "toast.success.createdTitle";
      const descriptionI18Key = props?.isEditing
        ? "toast.success.editedDescription"
        : "toast.success.createdDescription";
      makeToastMessage(
        {
          title: t(titleI18Key, {
            typeOfData: t("stuffs.typeOfData"),
          }),
          description: t(descriptionI18Key, {
            name: `${formik?.values?.firstName} ${formik?.values?.lastName}`,
            typeOfData: t("stuffs.typeOfDataPlural").toLowerCase(),
          }),
        },
        {
          containerId: APPLICATION_TOAST_CONTAINER_ID,
        }
      );
      if (!props?.isEditing) {
        history.replace(
          replaceInRoute(EDIT_STUFF_PAGE, {
            stuffId: payload,
          })
        );
      } else {
        history.replace(
          replaceInRoute(EDIT_STUFF_PAGE, {
            stuffId: stuffId,
          })
        );
      }
    } else {
      dispatch(
        fetchStuffs({
          page: history?.location?.state?.page || 1,
          forceRefetch: true,
          handleApiResponseSuccess: () => goBack(payload),
          handleApiResponseError,
        })
      );
    }
  };

  const handleSubmit = (values) => {
    dispatch(toggleSetLoadingModal({ rank: 1 }));
    const stuffData = {
      firstName: values?.firstName?.replace?.(/  +/g, " ")?.trim?.(),
      lastName: values?.lastName?.replace?.(/  +/g, " ")?.trim?.(),
      profession: values?.rank,
      licenseUrl: values?.licenseUrl,
      licenseNumber: values?.licenseNumber,
      signatureUrl: values?.signatureUrl,
      user: values?.user || null,
      email: values?.email?.replace?.(/  +/g, " ")?.trim?.(),
    };
    const stuffId = routeMatch.params?.stuffId;
    dispatch(
      props?.dispatchFunction({
        stuffData,
        stuffId,
        handleApiResponseSuccess,
        handleApiResponseError,
      })
    );
  };

  const formik = useFormik({
    initialValues: newStuffInitiaValues(props?.prefilledData),
    validationSchema: newStuffValidationSchema(),
    onSubmit: handleSubmit,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  const handleDeleteStuff = () => {
    dispatch(
      toggleSetDeleteModal({
        title: t("stuffs.modal.delete.title"),
        description: {
          first: t("stuffs.modal.delete.description.1"),
          second: t("stuffs.modal.delete.description.2"),
        },
        onSubmit: deleteStuff,
        rank: 1,
      })
    );
  };

  const deleteStuff = () => {
    dispatch(toggleSetLoadingModal({ rank: 2 }));
    dispatch(
      fetchDeleteStuffs({
        stuffId: routeMatch.params?.stuffId,
        handleApiResponseSuccess,
        handleApiResponseError,
      })
    );
  };

  const isSavingEnabled = useMemo(() => {
    if (formik?.dirty) {
      return false;
    }
    return true;
  }, [formik?.dirty]);

  return (
    <NewStuffFormContainer onSubmit={formik?.handleSubmit}>
      <PageTitle
        title={
          props?.isEditing &&
          props?.prefilledData?.firstName &&
          props?.prefilledData?.lastName
            ? `${props?.prefilledData?.firstName} ${props?.prefilledData?.lastName}`
            : props?.title
        }
        isEditing={props?.isEditing && !props?.readOnly}
        showDelete={!props?.readOnly}
        hideActionButtons={props?.readOnly}
        onDeleteClick={handleDeleteStuff}
        disableSaveButton={isSavingEnabled}
        onSubmitClick={formik?.handleSubmit}
      />
      {props?.isLoading ? (
        <CircularLoader />
      ) : (
        <>
          <NewStuffInputsContainer>
            {/* Fields */}
            {/* ****** */}
            <NewStuffLeftColumn>
              <FirstNameField formik={formik} isReadOnly={props?.readOnly} />
              <LastNameField formik={formik} isReadOnly={props?.readOnly} />
              <EmailField formik={formik} isReadOnly={props?.readOnly} />
              <RankField formik={formik} isReadOnly={props?.readOnly} />
              <AutocompleteField
                options={users}
                setMargin
                disabled={props?.readOnly}
                label={t("common.choose")}
                title={t("stuffs.connectWithUser")}
                onChange={(query) => formik?.setFieldValue?.("user", query?.id)}
                dropdownLabel={["firstName", "lastName"]}
                defaultValue={
                  props?.prefilledData?.user?.id !== 0
                    ? props?.prefilledData?.user
                    : null
                }
              />
            </NewStuffLeftColumn>

            <NewStuffRightColumn>
              <SignatureField formik={formik} isReadOnly={props?.readOnly} />
              <LicenseField formik={formik} isReadOnly={props?.readOnly} />
              <LicenseNumberField
                formik={formik}
                isReadOnly={props?.readOnly}
              />
            </NewStuffRightColumn>
          </NewStuffInputsContainer>
          {/* ****** */}

          {!props?.readOnly && isMobile && (
            <ActionButtons
              handleClickSubmitButton={formik?.handleSubmit}
              disabledSaveButton={isSavingEnabled}
            />
          )}
        </>
      )}
    </NewStuffFormContainer>
  );
};

NewStuffForm.propTypes = {
  title: PropTypes.string,
  submitText: PropTypes.string,
  prefilledData: PropTypes.any,
  dispatchFunction: PropTypes.func,
  isEditing: PropTypes.bool,
  isLoading: PropTypes.bool,
  readOnly: PropTypes.bool,
};

export default NewStuffForm;
