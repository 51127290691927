import * as Yup from "yup";

export default () =>
  Yup.object().shape({
    firstName: Yup.string()
      .trim()
      .max(255, "stuffs.firstNameNumberOfCharacters")
      .required("registerUser.firstNameRequired"),
    lastName: Yup.string()
      .trim()
      .max(255, "stuffs.lastNameNumberOfCharacters")
      .required("registerUser.lastNameRequired"),
    rank: Yup.string().max(255, "stuffs.rankNumberOfCharacters"),
    email: Yup.string()
      .trim()
      .email("clients.emailFormat")
      .max(255, "stuffs.emailNumberOfCharacters")
      .required("stuffs.emailRequired"),
    user: Yup.string(),
    licenseUrl: Yup.string(),
    signatureUrl: Yup.string(),
    licenseNumber: Yup.string().max(
      255,
      "stuffs.licenseNumberNumberOfCharacters"
    ),
  });
