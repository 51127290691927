import React from "react";
import { useTranslation } from "react-i18next";

import Title from "../Typography/Title/Title";
import { DocumentTypesContentContainer } from "./DocumentTypesContent.styled";
import DocumentTypesTable from "./DocumentTypesTable/DocumentTypesTable";

const DocumentTypesContent = () => {
  const { t } = useTranslation();

  return (
    <DocumentTypesContentContainer>
      <Title>{t("documentTypes.title")}</Title>
      <DocumentTypesTable />
    </DocumentTypesContentContainer>
  );
};

export default DocumentTypesContent;
