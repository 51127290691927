import { Box } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../../../themes";
import TextInputField from "../../../../Field/TextInputField/TextInputField";
import { InputRightElement } from "../../../../Field/TextInputField/TextInputField.styled";
import XIcon from "../../../../Icon/Icons/XIcon";

export const DateFilterComponentContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 24px;
  width: 100%;
  padding-top: 3px;
`;

export const LabelContainer = styled(Box)`
  display: flex;
  align-items: center;
  gap: 10px;
  & svg {
    width: 20px;
    height: 20px;
  }
`;

export const DateFilterComponentLabel = styled(Box)`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: ${selectedTheme.colors.labelTextColor};
  font-family: ${selectedTheme.fonts.mainText};
`;

export const DatePickerContainer = styled(Box)`
  display: flex;
  gap: 5px;
  justify-content: center;
`;
export const DateFilterComponentValue = styled(Box)`
  font-size: 16px;
  font-family: ${selectedTheme.fonts.mainText};
  line-height: 24px;
  font-weight: 500;
  color: ${selectedTheme.colors.pageTitleColor};
`;
export const DateFilterDatePickerContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  width: 100%;
  gap: 6px;
`;

export const DateTextField = styled(TextInputField)`
  margin: 0;
  width: 100%;
  text-align: left;

  & > div {
    width: 100%;
  }
  & > div > div {
    width: 100%;
  }
  & input {
    width: 100%;
  }
  & ${InputRightElement} {
    width: 20px;
    right: 28px;
    top: 0;
    justify-content: center;
    align-items: center;
    left: initial;
    border-radius: 100%;
    display: none;
  }
  &:hover {
    & ${InputRightElement} {
      display: flex;
    }
  }

`;
export const ClearIcon = styled(XIcon)`
  min-width: 23px;
  min-height: 23px;
  max-width: 23px;
  max-height: 23px;
  padding: 4px;
  border-radius: 100%;
  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
`;
