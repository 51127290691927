import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { NoteTextareaField } from "./NoteField.styled";
import { useDispatch, useSelector } from "react-redux";
import {
  setFindingsChanged,
  setSingleFindingNote,
} from "../../../../../store/actions/findings/findingsActions";
import {
  selectIsFindingSettingsChanged,
  selectSingleFindingNote,
  selectSingleFindingNoteOld,
} from "../../../../../store/selectors/findingsSelectors";
import { useState } from "react";

const NoteField = (props) => {
  const note = useSelector(selectSingleFindingNote);
  const noteOldSelector = useSelector(selectSingleFindingNoteOld);
  const [value, setValue] = useState(note?.value || "");
  const { t } = useTranslation();
  const dispatch = useDispatch(setFindingsChanged(true));
  const isFindingChanged = useSelector(selectIsFindingSettingsChanged);

  const noteOld = useMemo(() => {
    if (noteOldSelector?.value == null) return {value: ""};
    return noteOldSelector;
  }, [noteOldSelector]);

  useEffect(() => {
    setValue(note?.value);
  }, [note]);
  const handleChangeNote = (event) => {
    setValue(event.target.value);
    if (!isFindingChanged) dispatch(setFindingsChanged(true));
  };

  const handleSetNote = () => {
    if (value !== note?.value)
      dispatch(
        setSingleFindingNote({ edited: value !== noteOld?.value, value: value })
      );
  };

  return (
    <NoteTextareaField
      placeholder={t("findings.form.note.placeholder")}
      title={t("findings.form.note.title")}
      value={value}
      onChange={handleChangeNote}
      onBlur={handleSetNote}
      disabled={props?.disabled}
    />
  );
};

NoteField.propTypes = {
  formik: PropTypes.object,
  disabled: PropTypes.bool,
};

export default NoteField;
