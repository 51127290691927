import { Box } from "@mui/material";
import styled, { css } from "styled-components";

export const InstrumentsCalibrationFieldContainer = styled(Box)`
  display: flex;
  gap: 10px;
  align-items: center;
  margin-left: 8px;
  ${(props) =>
    props?.$disabled &&
    css`
      opacity: 0.3;
      cursor: default;
      & * {
        cursor: default;
      }
    `}
`;
export const Nester = styled(Box)`
  width: 13px;
  height: 15px;
  position: relative;
  top: -8px;
  border-bottom-left-radius: 4px;
  border-left: 1px solid #c4c7c5;
  border-bottom: 1px solid #c4c7c5;
`;
