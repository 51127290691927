import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { fetchCreateDocument } from "../../../store/actions/documents/documentsActions";
import NewDocumentContent from "../../../components/Documents/NewDocument/NewDocumentContent";
import { batch, useDispatch } from "react-redux";
import { fetchClients } from "../../../store/actions/clients/clientsActions";
import { ASC_KEY, NAME_KEY } from "../../../constants/queryConstants";
import { fetchDocumentTypes } from "../../../store/actions/documentTypes/documentTypesActions";

const NewDocumentPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    batch(() => {
      dispatch(
        fetchClients({
          page: 1,
          size: 200,
          sorting: {
            column: NAME_KEY,
            sortDirection: ASC_KEY,
          },
        })
      );
      dispatch(
        fetchDocumentTypes({
          page: 1,
          size: 200,
          sorting: {
            column: NAME_KEY,
            sortDirection: ASC_KEY,
          },
        })
      );
    });
  }, []);

  return (
    <NewDocumentContent
      dispatchFunction={fetchCreateDocument}
      title={t("documents.newPage.newDocument")}
      submitText={t("common.save")}
    />
  );
};

NewDocumentPage.propTypes = {
  children: PropTypes.node,
};

export default NewDocumentPage;
