export const isHtml = (string) => {
  return /^<\/?[a-z][\s\S]*>/i.test(string);
};
export const emptyHtml = "<p></p>";

export const createParagraphNode = (string) => {
  if(string.startsWith("<p>")) return string
  return `<p>${string}</p>`;
}
export const createHtmlString = (string) => {
  if (isHtml(string)) return string;
  if (string == null) return `<p></p>`;
  return `<p>${string}</p>`;
};

export const formatHtml = (string) => {
  return string?.replace(/font-family:([ ,A-Za-z]+)[;,"]*/g, "");
}

export const getPlainText = (string) => {
  if (!isHtml(string)) return string;
  let newString = trimNewLineAtEnd(string);
  while (newString?.includes("<") && newString?.includes(">")) {
    let stringToDelete = newString?.slice(
      newString?.indexOf("<"),
      newString?.indexOf(">") + 1
    );
    newString = newString?.replace(stringToDelete, "");
  }
  return newString;
};

export const trimNewLineAtEnd = (string) => {
  let newString = string;
  let endOfString = newString?.slice(-1);
  if (endOfString.charCodeAt(endOfString.length - 1) === 10)
    newString = newString?.slice(0, -1);
  endOfString = newString?.slice(-1);
  if (endOfString === "\n") newString = newString?.slice(0, -1);
  return newString;
};
export const makeParagraphInline = (string) => {
  if (string?.startsWith("<p")) {
    let newString = string?.replace(/<p/g, "<span");
    newString = newString?.replace(/\/p>/g, "/span>");
    let endOfString = newString?.slice(-1);
    if (endOfString.charCodeAt(endOfString.length - 1) === 10)
      newString = newString?.slice(0, -1);
    return newString;
  }
  return string;
};
