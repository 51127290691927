import styled from "styled-components";
import selectedTheme from "../../../../../themes";
import { hexToRGB } from "../../../../../util/helpers/colorHelper";

export const LinkRendererContainer = styled.a`
  text-decoration: none;
  padding: 4px 8px;
  font-size: 14px;
  background-color: ${hexToRGB(selectedTheme.colors.linkColor, 0.15)};
  border-radius: 12px;
  color: ${selectedTheme.colors.linkColor};
  font-family: ${selectedTheme.fonts.mainText};
  &:hover {
    background-color: ${hexToRGB(selectedTheme.colors.linkColor, 0.25)};
  }
`;
