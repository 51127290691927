import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import PlusIcon from "../../Icon/Icons/PlusIcon";
import {
  AddButtonContainer,
  AddButtonIconContainer,
  AddButtonText,
} from "./AddButton.styled";

const AddButton = (props) => {
  const { t } = useTranslation();
  return (
    <AddButtonContainer onClick={props?.onClick} {...props} title={undefined}>
      {(props?.title?.length === 0 || !props?.$hideTitle) && (
        <AddButtonText>{t(props?.title)}</AddButtonText>
      )}
      {props?.icon && (
        <AddButtonIconContainer>{props?.icon}</AddButtonIconContainer>
      )}
    </AddButtonContainer>
  );
};

AddButton.propTypes = {
  title: PropTypes.string,
  $hideTitle: PropTypes.bool,
  onClick: PropTypes.func,
  icon: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
};
AddButton.defaultProps = {
  onClick: () => {},
  title: "common.add",
  $hideTitle: false,
  icon: <PlusIcon />,
};

export default AddButton;
