import {
  createErrorType,
  createFetchType,
  createSuccessType,
} from "../actionHelpers";

export const GET_MAIL_INSTRUCTIONS_SCOPE = "GET_MAIL_INSTRUCTIONS_SCOPE";
export const GET_MAIL_INSTRUCTIONS_FETCH = createFetchType(GET_MAIL_INSTRUCTIONS_SCOPE);
export const GET_MAIL_INSTRUCTIONS_SUCCESS = createSuccessType(GET_MAIL_INSTRUCTIONS_SCOPE);
export const GET_MAIL_INSTRUCTIONS_ERROR = createErrorType(GET_MAIL_INSTRUCTIONS_SCOPE);

export const GET_GDRIVE_INSTRUCTIONS_SCOPE = "GET_GDRIVE_INSTRUCTIONS_SCOPE";
export const GET_GDRIVE_INSTRUCTIONS_FETCH = createFetchType(GET_GDRIVE_INSTRUCTIONS_SCOPE);
export const GET_GDRIVE_INSTRUCTIONS_SUCCESS = createSuccessType(GET_GDRIVE_INSTRUCTIONS_SCOPE);
export const GET_GDRIVE_INSTRUCTIONS_ERROR = createErrorType(GET_GDRIVE_INSTRUCTIONS_SCOPE);
