import { Box } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../themes";
import Switch from "../../Switch/Switch";
import Title from "../../Typography/Title/Title";

export const TemplateEditorNavigationContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
export const TemplateEditorNavigationItem = styled(Box)`
  padding-bottom: 16px;
  border-bottom: ${(props) =>
    props?.selected && `3px solid ${selectedTheme.colors.pageTitleColor}`};
  font-family: ${selectedTheme.fonts.mainText};
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: ${selectedTheme.colors.pageTitleColor};
  cursor: pointer;
`;

export const SwitchLabel = styled(Title)`
  font-size: 14px;
  line-height: 20px;
`;
export const IsActiveTemplateSwitch = styled(Switch)`
  bottom: 24px;
  right: 32px;
`;
export const TemplateTabsList = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 24px;
`;
