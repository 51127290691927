import React from "react";
import PropTypes from "prop-types";
import TextInputField from "../../../Field/TextInputField/TextInputField";
import { useTranslation } from "react-i18next";
import newClientValidationSchema from "../../../../validations/newClientValidationSchema";

const IdNumberField = (props) => {
  const { t } = useTranslation();
  const formik = props?.formik;
  return (
    <>
      <TextInputField
        name="idNumber"
        placeholder={t("clients.newClientPlaceholders.idNumber")}
        label={t("clients.idNumber")}
        number
        value={formik?.values?.idNumber}
        onChange={formik?.handleChange}
        requiredField={
          newClientValidationSchema?.()?.fields?.idNumber?.exclusiveTests
            ?.required
        }
        error={formik?.touched?.idNumber && formik?.errors?.idNumber}
        helperText={formik?.errors?.idNumber && formik?.touched?.idNumber}
        disabledField={props?.isReadOnly}
      />
    </>
  );
};

IdNumberField.propTypes = {
  formik: PropTypes.object,
  isReadOnly: PropTypes.bool,
};

export default IdNumberField;
