import React from "react";
// import PropTypes from 'prop-types'
import { ReactComponent as NewEyeIconSvg } from "../../../assets/images/svg/new-eye.svg";

const NewEyeIcon = (props) => {
  return <NewEyeIconSvg {...props} />;
};

NewEyeIcon.propTypes = {};

export default NewEyeIcon;
