import React from "react";
import { LoginImageContainer } from "../LoginContent/LoginContent.styled";
//import { Image } from "./LoginImage.styled";
import { ImageNew } from "./LoginImage.styled";
import  Image  from "../../../assets/images/png/log-in-img.png";

const LoginImage = () => {
  return (
    <LoginImageContainer>
      <ImageNew src={Image} />
    </LoginImageContainer>
  );
};

export default LoginImage;
