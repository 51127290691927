import React from "react";
import PropTypes from "prop-types";
import TextInputField from "../../../Field/TextInputField/TextInputField";
import { useTranslation } from "react-i18next";
import { scanningSubjectFormikConstants } from "../../../../initialValues/scanningSubjectInitialValues";
import { NameInputContainer } from "../NewScanningObjectContent.styled";
import { useDispatch, useSelector } from "react-redux";
import { setScanningObjectsChanged } from "../../../../store/actions/scanningObjects/scanningObjectsActions";
import { selectIsScanningObjectChanged } from "../../../../store/selectors/scanningObjectsSelectors";

const ScanningObjectNameInput = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isScanningObjectChanged = useSelector(selectIsScanningObjectChanged);
  const handleChange = (event) => {
    props?.formik?.handleChange?.(event);
    if (!isScanningObjectChanged) dispatch(setScanningObjectsChanged(true));
  };

  return (
    <NameInputContainer>
      <TextInputField
        label={t("common.title")}
        value={props?.formik?.values?.[scanningSubjectFormikConstants.name]}
        error={props?.formik?.errors?.[scanningSubjectFormikConstants.name]}
        name={scanningSubjectFormikConstants.name}
        onChange={handleChange}
        placeholder={t("scanningObjects.newScanningObject.namePlaceholder")}
        requiredField={true}
        disabledField={props?.isReadOnly}
      />
    </NameInputContainer>
  );
};

ScanningObjectNameInput.propTypes = {
  isReadOnly: PropTypes.bool,
  formik: PropTypes.object,
};
ScanningObjectNameInput.defaultProps = {
  value: "",
};

export default ScanningObjectNameInput;
