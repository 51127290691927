import React, { useState, useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "../../../store/actions/modal/modalActions";
import { useTranslation } from "react-i18next";
import {
  AddFindingStaffModalContainer,
  AutocompleteFieldLabel,
  ErrorMessage,
  InputsContainer,
  TextareaInput,
  TextareaLabel,
} from "./AddFindingStaffModal.styled";
import {
  ButtonsContainer,
  CancelButton,
  CloseButton,
  ModalTitle,
  SubmitButton,
} from "../Modal.styled";
import i18n from "../../../i18n";
import BackdropComponent from "../../Modals/ModalComponents/BackdropComponent";
import { MANAGER_ROLE } from "../../../constants/rolesConstants";
import useAuth from "../../../hooks/useAuth";
import AutocompleteField from "../../Field/AutocompleteField/AutocompleteField";
import { selectStuffsData } from "../../../store/selectors/stuffsSelector";
import {
  editFindingSingleStuff,
  setSingleFindingStaffs,
} from "../../../store/actions/findings/findingsActions";
import { selectSingleFindingStaffs } from "../../../store/selectors/findingsSelectors";

const AddFindingStaffModal = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { hasRole } = useAuth();
  const [selectedEntity, setSelectedEntity] = useState(
    props?.staffObject || null
  );
  const [inputValue, setInputValue] = useState(
    props?.staffObject?.staffRole || ""
  );
  const [errorMessage, setErrorMessage] = useState(false);
  const [touched, setTouched] = useState(false);
  const staffs = useSelector(selectSingleFindingStaffs);
  const entityData = useSelector(selectStuffsData);
  const staffOrderArray = staffs.filter(staff => !staff.removed).map(staff => staff.value.staffOrder); 
  const maxStaffOrder = (staffOrderArray && staffOrderArray.length > 0) ? Math.max(...staffOrderArray) + 1: 0;
  const isSavingDisabled = useMemo(
    () =>
      props?.staffObject?.staffRole === inputValue || inputValue?.length === 0,
    [props?.staffObject, inputValue, props?.isDefault, touched]
  );

  const availableOptions = useMemo(
    () =>
      entityData?.filter(
        (singleEntityData) =>
          staffs?.find(
            (singleEntityDataToFind) =>
              singleEntityDataToFind?.value?.id === singleEntityData?.id &&
              !singleEntityDataToFind?.removed
          ) === undefined
      ) || [],
    [entityData, staffs]
  );

  const readOnlyModal = useMemo(() => !hasRole(MANAGER_ROLE), [hasRole]);

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
    setTouched(true);
  };

  const handleClickSubmit = useCallback(() => {
    if (!isSavingDisabled)  {   
       handleSubmit(maxStaffOrder);
      }
  }, [isSavingDisabled, inputValue, entityData, staffs, selectedEntity, maxStaffOrder]);

  const handleSubmit = (maxStaffOrder) => {
    if (inputValue?.trim().length === 0) {
      setErrorMessage("examiners.positionRequired");
      return;
    }
    if (!selectedEntity) {
      setErrorMessage("examiners.staffRequired");
      return;
    }
    let staffToAdd;
    if (props.isEditing) {
      staffToAdd = {
        ...entityData?.find?.((entity) => entity?.id === selectedEntity?.id),
        staffRole: inputValue,
        staffOrder: props?.staffObject?.staffOrder
      };
    } else {
      staffToAdd = {
        ...entityData?.find?.((entity) => entity?.id === selectedEntity?.id),
        staffRole: inputValue,
        staffOrder: maxStaffOrder
      };
    } 
    delete staffToAdd?.createdByUser;
    delete staffToAdd?.updatedByUser;
    delete staffToAdd?.deletedByUser;
    delete staffToAdd?.user;
    let staffInRedux = staffs?.find(
      (singleStaff) => singleStaff?.value?.id === staffToAdd?.id
    );
    if (props?.isEditing) {
      if (staffInRedux) {
        if (staffInRedux?.removed) {
          dispatch(
            editFindingSingleStuff({
              staff: {
                value: staffToAdd,
                edited: true,
              },
            })
          );
        } else if (staffInRedux?.added) {
          dispatch(
            editFindingSingleStuff({
              staff: {
                value: staffToAdd,
                added: true,
              },
            })
          );
        } else {
          dispatch(
            editFindingSingleStuff({
              staff: {
                value: staffToAdd,
                edited: true,
              },
            })
          );
        }
      }
    } else {
      if (staffInRedux?.removed) {
        dispatch(
          editFindingSingleStuff({
            staff: {
              value: staffToAdd,
              edited: true,
            },
          })
        );
      } else {
        let newStaffs = [
          ...staffs,
          {
            value: staffToAdd,
            added: true,
          },
        ];
        dispatch(setSingleFindingStaffs(newStaffs));
      }
    }
    dispatch(closeModal());
  };

  const handleChangeStaff = (value) => {
    setSelectedEntity(value);
    if (value) {
      setInputValue(
        `${value?.profession}${
          value?.licenseNumber ? ", Licenca br. " + value?.licenseNumber : ""
        }`
      );
    }
  };

  return (
    <>
      {props?.rank > 1 ? (
        <BackdropComponent
          isLoading
          handleClose={handleCloseModal}
          position="fixed"
        />
      ) : (
        <></>
      )}
      <AddFindingStaffModalContainer rank={props?.rank}>
        <CloseButton onClick={handleCloseModal} />
        <ModalTitle>{props?.title}</ModalTitle>
        <InputsContainer>
          <AutocompleteFieldLabel $required={selectedEntity == null}>
            {t("findings.staffs.chooseStaff")}
          </AutocompleteFieldLabel>
          <AutocompleteField
            options={availableOptions}
            label={t("findings.staffs.chooseStaff")}
            onChange={handleChangeStaff}
            dropdownLabel={["firstName", "lastName"]}
            value={selectedEntity}
            disabled={props?.isEditing}
          />
          <TextareaLabel $required={inputValue?.length === 0}>
            {t("findings.staffs.attribute")}
          </TextareaLabel>
          <TextareaInput
            minRows={7}
            maxRows={7}
            onChange={handleInputChange}
            value={inputValue}
            disabled={readOnlyModal}
          />
          {errorMessage && (
            <ErrorMessage>{t(errorMessage)}</ErrorMessage>
          )}
        </InputsContainer>

        {!readOnlyModal && (
          <ButtonsContainer>
            <CancelButton onClick={handleCloseModal}>
              {t("common.cancel")}
            </CancelButton>
            <SubmitButton
              onClick={handleClickSubmit}
              disabled={isSavingDisabled}
            >
              {t("common.save")}
            </SubmitButton>
          </ButtonsContainer>
        )}
      </AddFindingStaffModalContainer>
    </>
  );
};

AddFindingStaffModal.propTypes = {
  children: PropTypes.node,
  rank: PropTypes.number,
  title: PropTypes.string,
  onSubmit: PropTypes.func,
  prefilledInput: PropTypes.string,
  isEditing: PropTypes.bool,
  isDefault: PropTypes.bool,
  prefilledData: PropTypes.object,
  dropdownLabel: PropTypes.array,
  staffObject: PropTypes.object,
};
AddFindingStaffModal.defaultProps = {
  title: i18n.t("standards.modal.defaultTitle"),
  onSubmit: () => {},
};

export default AddFindingStaffModal;
