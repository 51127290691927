import { Box } from "@mui/material";
import styled, { css } from "styled-components";
import selectedTheme from "../../../themes";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Link from "../../Link/Link";

export const HeaderBreadCrumbsContainer = styled(Box)`
  font-family: "Inter" !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  color: ${selectedTheme.colors.borderColor};
  display: flex;
`;
export const HeaderRouteSingleItem = styled(Link)`
  display: inline;
  cursor: pointer;
  font-family: "Inter" !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 20px;
  color: ${selectedTheme.colors.borderColor};
`;

export const HeaderIcon = styled(Box)`
  width: 24px;
  height: 24px;
  & path {
    ${(props) =>
      props?.$fill
        ? css`
            fill: ${selectedTheme.colors.borderColor};
          `
        : css`
            stroke: ${selectedTheme.colors.borderColor};
          `}
  }
`;

export const HeaderItem = styled(Box)`
  display: flex;
  gap: 10px;
  align-items: center;
  margin-right: 10px;
`;

export const HeaderArrow = styled(ExpandMoreIcon)`
  transform: rotate(-90deg);
  display: inline;
`;
