import { getRequest, replaceInUrl } from ".";
import apiEndpoints from "./apiEndpoints";

export const attemptFetchTranslations = (payload) =>
  getRequest(
    replaceInUrl(apiEndpoints.translations.resources, {
      lngResource: payload,
    }),
    { noAuth: true }
  );
