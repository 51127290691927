import {
  SINGLE_STANDARD_SET,
  STANDARDS_CLEAR,
  STANDARDS_SET,
  STANDARDS_SET_TOTAL,
} from "../../actions/standards/standardsActionConstants";
import createReducer from "../../utils/createReducer";

const initialState = {
  standards: {
    loaded: false,
    data: [],
  },
  totalStandards: 0,
  singleStandard: {},
};

export default createReducer(
  {
    [STANDARDS_SET]: setStandards,
    [STANDARDS_SET_TOTAL]: setStandardsTotal,
    [STANDARDS_CLEAR]: clearStandards,
    [SINGLE_STANDARD_SET]: setSingleStandard,
  },
  initialState
);
function setSingleStandard(state, { payload }) {
  return {
    ...state,
    singleStandard: payload,
  };
}
function setStandards(state, { payload }) {
  return {
    ...state,
    standards: payload,
  };
}
function setStandardsTotal(state, { payload }) {
  return {
    ...state,
    totalStandards: payload,
  };
}
function clearStandards(state) {
  return {
    ...state,
    standards: initialState.standards,
    totalStandards: initialState.totalStandards,
  };
}
