import { all, call, put, takeLatest, select } from "@redux-saga/core/effects";
import {
  attemptEmailVerification,
  attemptEditAPRImages,
  attemptEditSingleCompany,
  attemptFetchSingleCompany,
  attemptUploadJsonSecret,
  attemptValidateDriveGet,
  attemptValidateDrivePost,
  attemptFetchCompanies,
  attemptCreateCompany,
  attemptDeleteSingleCompany,
  attemptActivateCompany,
  attemptDeactivateCompany,
} from "../../request/companiesRequest";
import {
  ACTIVATE_COMPANY_FETCH,
  CREATE_APR_IMAGES_FETCH,
  CREATE_COMPANY_FETCH,
  DEACTIVATE_COMPANY_FETCH,
  DELETE_COMPANY_FETCH,
  EDIT_APR_IMAGES_FETCH,
  EDIT_COMPANY_FETCH,
  EMAIL_VERIFICATION_FETCH,
  GET_COMPANIES_FETCH,
  MY_COMPANY_FETCH,
  SINGLE_COMPANY_FETCH,
  UPLOAD_JSON_SECRET_FETCH,
  VALIDATE_DRIVE_FETCH,
  VALIDATE_NEW_DRIVE_FETCH,
} from "../actions/companies/companiesActionConstants";
import {
  fetchActivateCompanyError,
  fetchActivateCompanySuccess,
  fetchCompaniesError,
  fetchCompaniesSuccess,
  fetchCreateAprImagesError,
  fetchCreateCompanyError,
  fetchCreateCompanySuccess,
  fetchDeactivateCompanyError,
  fetchDeactivateCompanySuccess,
  fetchDeleteCompanyError,
  fetchDeleteCompanySuccess,
  // fetchCreateAprImagesSuccess,
  fetchEditAprImagesError,
  fetchEditAprImagesSuccess,
  fetchEditCompanyError,
  fetchEditCompanySuccess,
  fetchEmailVerificationError,
  fetchEmailVerificationSuccess,
  fetchMyCompanyError,
  fetchMyCompanySuccess,
  fetchSingleCompanyError,
  fetchSingleCompanySuccess,
  fetchUploadJsonSecretError,
  fetchUploadJsonSecretSuccess,
  fetchValidateDriveError,
  fetchValidateDriveSuccess,
  fetchValidateNewDriveError,
  fetchValidateNewDriveSuccess,
  setCompanies,
  setMyCompany,
  setSingleCompany,
  setTotalCompanies,
} from "../actions/companies/companiesActions";
import { selectMineCompanyId } from "../selectors/loginSelectors";
import { mapFetchAllFunction } from "../utils/mapFetchAllFunctions";
import { NAME_KEY } from "../../constants/queryConstants";

function* fetchAllCompanies({ payload }) {
  try {
    const queryObject = mapFetchAllFunction(payload, [NAME_KEY]);
    queryObject.set("IsActive", payload?.customPayload?.isActive);
    const data = yield call(attemptFetchCompanies, queryObject);
    yield put(
      setCompanies({
        data: data?.data?.companies,
      })
    );
    yield put(setTotalCompanies(data?.data?.count));
    if (payload?.handleApiResponseSuccess) {
      yield call(payload?.handleApiResponseSuccess);
    }
    yield put(fetchCompaniesSuccess());
  } catch (e) {
    console.log("greska", e);
    let errorMessage = e?.response?.data?.message;

    if (payload?.handleApiResponseError) {
      yield call(payload?.handleApiResponseError, errorMessage);
    }
    yield put(fetchCompaniesError(errorMessage));
  }
}

function* fetchCreateCompany({ payload }) {
  try {
    const { data } = yield call(attemptCreateCompany, {
      name: payload?.companyData?.companyName,
      tin: String(payload?.companyData?.companyPIB),
      identificationNumber: String(payload?.companyData?.identificationNumber),
      address: payload?.companyData?.address,
      email: payload?.companyData?.email,
      bankAccount: payload?.companyData?.bankAccountNumber,
      phoneNumber: payload?.companyData?.phoneNumber,
      logoUrl: payload?.companyData?.logo,
      stampUrl: payload?.companyData?.stamp,
      directorSignatureUrl: payload?.companyData?.signature,
      licenseUrl: payload?.companyData?.license,
      distributionEmails: payload?.companyData?.distributionEmails.join(";"),
      userNameForMailService: payload?.companyData?.userName,
      passwordForMailService: payload?.companyData?.password
        ? payload?.companyData?.password
        : null,
      hostForMailService: payload?.companyData?.host,
      portForMailService:
        payload?.companyData?.port?.length !== 0
          ? payload?.companyData?.port
          : 0,
      enableSSlForMailService: payload?.companyData?.enableSSl,
      aprImages: payload?.companyData?.APR,
      automaticallySendEmails: payload?.companyData?.automaticallySendEmails,
      directorInfo: payload?.companyData?.companyDirector,
      automaticallyUploadToDrive:
        payload?.companyData?.automaticallyUploadToDrive,
      driveFolderId: payload?.companyData?.driveFolderId,
      documentTypeIdForArchiving: payload?.documentTypeIdForArchiving,
      languageCultureName: payload?.companyData?.languageCultureName,
      description: payload?.companyData?.description,
    });
    if (payload?.handleApiResponseSuccess) {
      yield call(payload?.handleApiResponseSuccess, data);
    }
    yield put(fetchCreateCompanySuccess());
  } catch (e) {
    console.log("greska", e);
    let errorMessage = e?.response?.data?.message;

    if (payload?.handleApiResponseError) {
      yield call(payload?.handleApiResponseError, errorMessage);
    }
    yield put(fetchCreateCompanyError(errorMessage));
  }
}

function* fetchSingleCompany({ payload }) {
  try {
    const { data } = yield call(attemptFetchSingleCompany, {
      companyId: payload?.companyId,
    });
    yield put(
      setSingleCompany({
        ...data,
        aprImages: data?.aprImages?.map((singleImage) => ({
          imageUrl: singleImage?.imageUrl,
          orderNumber: singleImage?.orderNumber,
        })),
      })
    );
    if (payload?.handleApiResponseSuccess) {
      yield call(payload?.handleApiResponseSuccess);
    }
    yield put(fetchSingleCompanySuccess());
  } catch (e) {
    console.log("greska", e);
    let errorMessage = e?.response?.data?.message;

    if (payload?.handleApiResponseError) {
      yield call(payload?.handleApiResponseError, errorMessage);
    }
    yield put(fetchSingleCompanyError(errorMessage));
  }
}

function* fetchDeleteCompany({ payload }) {
  try {
    yield call(attemptDeleteSingleCompany, payload?.companyId);
    if (payload?.handleApiResponseSuccess) {
      yield call(payload?.handleApiResponseSuccess);
    }
    yield put(fetchDeleteCompanySuccess());
  } catch (e) {
    console.log("greska", e);
    let errorMessage = e?.response?.data?.message;

    if (payload?.handleApiResponseError) {
      yield call(payload?.handleApiResponseError, errorMessage);
    }
    yield put(fetchDeleteCompanyError(errorMessage));
  }
}

function* fetchMyCompany({ payload }) {
  try {
    const companyId = yield select(selectMineCompanyId);
    const { data } = yield call(attemptFetchSingleCompany, {
      companyId: companyId,
    });
    yield put(
      setMyCompany({
        ...data,
        aprImages: data?.aprImages?.map((singleImage) => ({
          imageUrl: singleImage?.imageUrl,
          orderNumber: singleImage?.orderNumber,
        })),
      })
    );
    if (payload?.handleApiResponseSuccess) {
      yield call(payload?.handleApiResponseSuccess);
    }
    yield put(fetchMyCompanySuccess());
  } catch (e) {
    console.log("greska", e);
    let errorMessage = e?.response?.data?.message;

    if (payload?.handleApiResponseError) {
      yield call(payload?.handleApiResponseError, errorMessage);
    }
    yield put(fetchMyCompanyError(errorMessage));
  }
}

function* fetchEditSingleCompany({ payload }) {
  try {
    let companyId = payload?.companyId;

    const data = {
      name: payload?.companyData?.companyName,
      tin: String(payload?.companyData?.companyPIB),
      identificationNumber: String(payload?.companyData?.identificationNumber),
      address: payload?.companyData?.address,
      email: payload?.companyData?.email,
      bankAccount: payload?.companyData?.bankAccountNumber,
      phoneNumber: payload?.companyData?.phoneNumber,
      logoUrl: payload?.companyData?.logo,
      stampUrl: payload?.companyData?.stamp,
      directorSignatureUrl: payload?.companyData?.signature,
      licenseUrl: payload?.companyData?.license,
      distributionEmails: payload?.companyData?.distributionEmails.join(";"),
      userNameForMailService: payload?.companyData?.userName,
      passwordForMailService: payload?.companyData?.password
        ? payload?.companyData?.password
        : null,
      hostForMailService: payload?.companyData?.host,
      portForMailService:
        payload?.companyData?.port?.length !== 0
          ? payload?.companyData?.port
          : 0,
      enableSSlForMailService: payload?.companyData?.enableSSl,
      aprImages: payload?.companyData?.APR,
      automaticallySendEmails: payload?.companyData?.automaticallySendEmails,
      directorInfo: payload?.companyData?.companyDirector,
      automaticallyUploadToDrive:
        payload?.companyData?.automaticallyUploadToDrive,
      driveFolderId: payload?.companyData?.driveFolderId,
      documentTypeIdForArchiving:
        payload?.companyData?.documentTypeIdForArchiving,
      languageCultureName: payload?.companyData?.languageCultureName,
      description: payload?.companyData?.description,
    };
    Object.keys(data).forEach(
      (item) =>
        (data[item] =
          typeof data[item] === "string"
            ? data[item].replace(/  +/g, " ").trim()
            : data[item])
    );
    yield call(attemptEditSingleCompany, {
      companyId,
      data,
    });
    if (payload?.handleApiResponseSuccess) {
      yield call(payload?.handleApiResponseSuccess);
    }
    yield put(fetchEditCompanySuccess());
  } catch (e) {
    console.log("greska", e);
    let errorMessage = e?.response?.data?.message;

    if (payload?.handleApiResponseError) {
      yield call(payload?.handleApiResponseError, errorMessage);
    }
    yield put(fetchEditCompanyError(errorMessage));
  }
}

function* fetchActivateCompany({ payload }) {
  try {
    let companyId = payload?.companyId;
    yield call(attemptActivateCompany, {
      companyId,
    });
    if (payload?.handleApiResponseSuccess) {
      yield call(payload?.handleApiResponseSuccess);
    }
    yield put(fetchActivateCompanySuccess());
  } catch (e) {
    console.log("greska", e);
    let errorMessage = e?.response?.data?.message;

    if (payload?.handleApiResponseError) {
      yield call(payload?.handleApiResponseError, errorMessage);
    }
    yield put(fetchActivateCompanyError(errorMessage));
  }
}

function* fetchDeactivateCompany({ payload }) {
  try {
    let companyId = payload?.companyId;
    yield call(attemptDeactivateCompany, {
      companyId,
    });
    if (payload?.handleApiResponseSuccess) {
      yield call(payload?.handleApiResponseSuccess);
    }
    yield put(fetchDeactivateCompanySuccess());
  } catch (e) {
    console.log("greska", e);
    let errorMessage = e?.response?.data?.message;

    if (payload?.handleApiResponseError) {
      yield call(payload?.handleApiResponseError, errorMessage);
    }
    yield put(fetchDeactivateCompanyError(errorMessage));
  }
}

function* fetchCreateAprImages({ payload }) {
  try {
    const images = payload?.aprImages;
    const formData = new FormData();
    formData.append("file", images[0]);
  } catch (e) {
    console.log("greska", e);
    let errorMessage = e?.response?.data?.message;

    if (payload?.handleApiResponseError) {
      yield call(payload?.handleApiResponseError, errorMessage);
    }
    yield put(fetchCreateAprImagesError(errorMessage));
  }
}

function* fetchEditAprImages({ payload }) {
  try {
    yield call(attemptEditAPRImages, {
      companyId: payload?.companyId,
      data: { ...payload?.companyData },
    });
    if (payload?.handleApiResponseSuccess) {
      yield call(payload?.handleApiResponseSuccess);
    }
    yield put(fetchEditAprImagesSuccess());
  } catch (e) {
    console.log("greska", e);
    let errorMessage = e?.response?.data?.message;

    if (payload?.handleApiResponseError) {
      yield call(payload?.handleApiResponseError, errorMessage);
    }
    yield put(fetchEditAprImagesError(errorMessage));
  }
}

function* fetchEmailverification({ payload }) {
  try {
    yield call(attemptEmailVerification, {
      companyId: payload?.id,
      data: payload?.data,
    });
    if (payload?.handleApiResponseSuccess) {
      yield call(payload?.handleApiResponseSuccess);
    }
    yield put(fetchEmailVerificationSuccess());
  } catch (e) {
    console.log("greska", e);
    let errorMessage = e?.response?.data?.message;

    if (payload?.handleApiResponseError) {
      yield call(payload?.handleApiResponseError, errorMessage);
    }
    yield put(fetchEmailVerificationError(errorMessage));
  }
}

function* fetchUploadJsonSecret({ payload }) {
  try {
    const formData = new FormData();
    formData.append("file", payload?.file);
    yield call(attemptUploadJsonSecret, {
      companyId: payload?.id,
      data: formData,
    });
    if (payload?.handleApiResponseSuccess) {
      yield call(payload?.handleApiResponseSuccess, payload?.file.name);
    }
    yield put(fetchUploadJsonSecretSuccess());
  } catch (e) {
    console.log("greska", e);
    let errorMessage = e?.response?.data?.message;

    if (payload?.handleApiResponseError) {
      yield call(payload?.handleApiResponseError, errorMessage);
    }
    yield put(fetchUploadJsonSecretError(errorMessage));
  }
}

function* fetchValidateDriveGet({ payload }) {
  try {
    yield call(attemptValidateDriveGet, {
      companyId: payload?.id,
    });
    if (payload?.handleApiResponseSuccess) {
      yield call(payload?.handleApiResponseSuccess);
    }
    yield put(fetchValidateDriveSuccess());
  } catch (e) {
    console.log("greska", e);
    let errorMessage = e?.response?.data?.message;

    if (payload?.handleApiResponseError) {
      yield call(payload?.handleApiResponseError, errorMessage);
    }
    yield put(fetchValidateDriveError(errorMessage));
  }
}

function* fetchValidateDrivePost({ payload }) {
  try {
    const formData = new FormData();
    formData.append("file", payload?.data?.file ?? null);
    formData.append("folderId", payload?.data?.folderId ?? null);
    yield call(attemptValidateDrivePost, {
      companyId: payload?.id,
      data: formData,
    });
    if (payload?.handleApiResponseSuccess) {
      yield call(payload?.handleApiResponseSuccess);
    }
    yield put(fetchValidateNewDriveSuccess());
  } catch (e) {
    console.log("greska", e);
    let errorMessage = e?.response?.data?.message;

    if (payload?.handleApiResponseError) {
      yield call(payload?.handleApiResponseError, errorMessage);
    }
    yield put(fetchValidateNewDriveError(errorMessage));
  }
}

export default function* clientsSaga() {
  yield all([
    takeLatest(SINGLE_COMPANY_FETCH, fetchSingleCompany),
    takeLatest(DELETE_COMPANY_FETCH, fetchDeleteCompany),
    takeLatest(DEACTIVATE_COMPANY_FETCH, fetchDeactivateCompany),
    takeLatest(ACTIVATE_COMPANY_FETCH, fetchActivateCompany),
    takeLatest(MY_COMPANY_FETCH, fetchMyCompany),
    takeLatest(EDIT_COMPANY_FETCH, fetchEditSingleCompany),
    takeLatest(CREATE_APR_IMAGES_FETCH, fetchCreateAprImages),
    takeLatest(EDIT_APR_IMAGES_FETCH, fetchEditAprImages),
    takeLatest(EMAIL_VERIFICATION_FETCH, fetchEmailverification),
    takeLatest(UPLOAD_JSON_SECRET_FETCH, fetchUploadJsonSecret),
    takeLatest(VALIDATE_DRIVE_FETCH, fetchValidateDriveGet),
    takeLatest(VALIDATE_NEW_DRIVE_FETCH, fetchValidateDrivePost),
    takeLatest(GET_COMPANIES_FETCH, fetchAllCompanies),
    takeLatest(CREATE_COMPANY_FETCH, fetchCreateCompany),
  ]);
}
