import React from "react";
import { ReactComponent as LicensePreviewIconSvg } from "../../../assets/images/svg/license-preview.svg";

const LicensePreviewIcon = (props) => {
  return <LicensePreviewIconSvg {...props} />;
};

LicensePreviewIcon.propTypes = {};

export default LicensePreviewIcon;
