import React from "react";
// import PropTypes from 'prop-types'
import { ReactComponent as LeftArrowIconSvg } from "../../../assets/images/svg/down-arrow.svg";

const LeftArrowIcon = (props) => {
  return (
    <LeftArrowIconSvg style={{ transform: "rotate(90deg)" }} {...props} />
  );
};

LeftArrowIcon.propTypes = {};

export default LeftArrowIcon;
