import React from "react";
import PropTypes from "prop-types";
import Checkbox from "../../../Checkbox/Checkbox";
import { useTranslation } from "react-i18next";
import { newFindingTypeFormikConstants } from "../../../../initialValues/newFindingTypeInitialValues";

const ExaminatorsField = (props) => {
  const { t } = useTranslation();
  const formik = props?.formik;

  const handleChange = (value) => {
    formik?.setFieldValue?.(newFindingTypeFormikConstants.examinators, value);
    if (!value)
      formik?.setFieldValue?.(
        newFindingTypeFormikConstants.examinatorsSignatures,
        false
      );
  };
  return (
    <Checkbox
      label={t("findingTypes.examinators")}
      checked={formik?.values?.[newFindingTypeFormikConstants?.examinators]}
      disabled={props?.disabled}
      onChange={handleChange}
    />
  );
};

ExaminatorsField.propTypes = {
  formik: PropTypes.object,
  disabled: PropTypes.bool,
};

export default ExaminatorsField;
