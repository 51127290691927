import { Typography } from "@mui/material";
import styled, { css } from "styled-components";
import selectedTheme from "../../../themes";
import { hexToRGB } from "../../../util/helpers/colorHelper";
import Icon from "../../Icon/Icon";
import Link from "../../Link/Link";

export const AddButtonContainer = styled(Link)`
  border-radius: 6px;
  background: ${selectedTheme.colors.addButtonBackground};
  padding: 8px 16px;
  height: 36px;
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
  cursor: pointer;
    ${props => props?.disabled && css`
    background: ${hexToRGB(selectedTheme.colors.addButtonBackground, 0.43)};
    cursor: default;
  `}
  @media (max-width: 600px) {
    padding: 8px;
  }
`;
export const AddButtonText = styled(Typography)`
  font-family: "Inter";
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  font-feature-settings: "salt" on;
  color: white;
  @media (max-width: 600px) {
    display: none;
  }
`;
export const AddButtonIconContainer = styled(Icon)`
  display: flex;
  align-items: center;
  justify-content: center;
`
