import React from "react";
import PropTypes from "prop-types";
import { FormatButtonContainer } from "./FormatButton.styled";

const FormatButton = (props) => {
  return (
    <FormatButtonContainer $isActive={props?.isActive} {...props}>
      {props?.icon}
    </FormatButtonContainer>
  );
};

FormatButton.propTypes = {
  isActive: PropTypes.bool,
  icon: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
};

export default FormatButton;
