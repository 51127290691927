import React from "react";
import PropTypes from "prop-types";
import {
  ButtonsContainer,
  TableHeaderContainer,
  TableHeaderLeftContainer,
} from "./TableHeader.styled";
import FilterButton from "../../Buttons/FilterButton/FilterButton";
import SearchInput from "../../Field/SearchInput/SearchInput";
import AddButton from "../../Buttons/AddButton/AddButton";
import { forwardRef } from "react";
import { useImperativeHandle } from "react";
import { useRef } from "react";
import InviteIcon from "../../Icon/Icons/InviteIcon";

const TableHeader = forwardRef((props, ref) => {
  const searchRef = useRef(null);

  useImperativeHandle(ref, () => ({
    changeSearchValue: searchRef?.current?.changeSearchValue,
  }));

  return (
    <TableHeaderContainer>
      <TableHeaderLeftContainer>
        {props?.showFilter && (
          <FilterButton
            applyFiltersFunction={props?.applyFiltersFunction}
            filterComponents={props?.filterComponents}
            onReset={props?.filterResetFunc}
          />
        )}
        {!props?.hideSearch && (
          <SearchInput
            ref={searchRef}
            onChange={props?.onChangeSearch}
            placeholder={props?.searchPlaceholder}
          />
        )}
      </TableHeaderLeftContainer>
      {(!props?.hideAddButton || props?.showInviteButton) && (
        <ButtonsContainer>
          {props?.showInviteButton && (
            <AddButton
              title={props?.inviteButtonTitle}
              to={props?.inviteButtonHref}
              icon={props?.inviteButtonIcon}
              onClick={props?.handleClickInviteButton}
            />
          )}
          {!props?.hideAddButton && (
            <AddButton
              to={props?.addButtonHref}
              onClick={props?.handleClickAddButton}
            />
          )}
        </ButtonsContainer>
      )}
    </TableHeaderContainer>
  );
});

TableHeader.displayName = "TableHeader";

TableHeader.propTypes = {
  children: PropTypes.node,
  onChangeSearch: PropTypes.func,
  searchPlaceholder: PropTypes.string,
  inviteButtonTitle: PropTypes.string,
  addButtonHref: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  handleClickAddButton: PropTypes.func,
  hideAddButton: PropTypes.bool,
  showFilter: PropTypes.bool,
  filterResetFunc: PropTypes.func,
  applyFiltersFunction: PropTypes.func,
  hideSearch: PropTypes.bool,
  filterComponents: PropTypes.node,
  inviteButtonHref: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  handleClickInviteButton: PropTypes.func,
  showInviteButton: PropTypes.bool,
  inviteButtonIcon: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
};
TableHeader.defaultProps = {
  handleClickAddButton: () => {},
  inviteButtonTitle: "users.invite",
  inviteButtonIcon: <InviteIcon />,
};

export default TableHeader;
