import React from "react";
import PropTypes from "prop-types";
import {
  ChapterContainer,
  ChapterTitle,
} from "../../../SetScanningObjectModal.styled";
import { AlignmentContainer, IconContainer } from "../TextTypeInputs.styled";
import FormatLineSpacing from "@mui/icons-material/FormatLineSpacing";
import { ORIENTATION } from "../../../../../../constants/orientationConstants";
import { useTranslation } from "react-i18next";

const OrientationPicker = (props) => {
  const { t } = useTranslation();
  return (
    <ChapterContainer>
      <ChapterTitle>{t("print.orientation")}</ChapterTitle>
      <AlignmentContainer>
        <IconContainer
          onClick={() => props?.setOrientation(ORIENTATION.VERTICAL)}
          $selected={props?.orientation === ORIENTATION.VERTICAL}
        >
          <FormatLineSpacing />
        </IconContainer>
        <IconContainer
          onClick={() => props?.setOrientation(ORIENTATION.HORIZONTAL)}
          $selected={props?.orientation === ORIENTATION.HORIZONTAL}
        >
          <FormatLineSpacing />
        </IconContainer>
      </AlignmentContainer>
    </ChapterContainer>
  );
};

OrientationPicker.propTypes = {
  orientation: PropTypes.string,
  setOrientation: PropTypes.func,
};
OrientationPicker.defaultProps = {
  orientation: ORIENTATION.VERTICAL,
  setOrientation: () => {}
};

export default OrientationPicker;
