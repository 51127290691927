import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { HelmetProvider } from "react-helmet-async";
import App from "./App";
import { persistor, store } from "./store";
import "./index.css";

import "./i18n";
import { PersistGate } from "redux-persist/integration/react";

ReactDOM.render(
  <HelmetProvider>
    {/* <React.StrictMode> */}
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
    {/* </React.StrictMode> */}
  </HelmetProvider>,
  document.getElementById("root")
);
