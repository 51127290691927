import React, { useMemo } from "react";
import {
  HeaderBreadCrumbsContainer,
  HeaderIcon,
  HeaderRouteSingleItem,
  HeaderItem,
  HeaderArrow,
} from "./HeaderBreadCrumbs.styled";
import {
  getRoutesBreadCrumb,
  getRouteName,
} from "../../../util/helpers/routeHelpers";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useIsMobile from "../../../hooks/useIsMobile";
import { HOME_PAGE } from "../../../constants/pages";
import BaseBreadIcon from "../../Icon/Icons/BaseBreadIcon";

const HeaderBreadCrumbs = () => {
  const location = useLocation();
  const { isMobile } = useIsMobile();
  const { t } = useTranslation();

  const breadcrumbs = useMemo(() => {
    let breadcrumbs = getRoutesBreadCrumb();
    if (breadcrumbs?.length === 0) {
      breadcrumbs?.push({
        id: HOME_PAGE,
        route: "/home",
        i18key: `pages.${getRouteName(HOME_PAGE)}`,
        icon: <BaseBreadIcon />,
      });
    }
    if (isMobile) {
      breadcrumbs = [breadcrumbs?.[breadcrumbs?.length - 1]];
    }
    return breadcrumbs;
  }, [location, isMobile]);

  return (
    <HeaderBreadCrumbsContainer>
      {breadcrumbs?.map?.((singleRoute, index) => (
        <HeaderItem key={index}>
          {singleRoute?.icon && (
            <HeaderIcon $fill={singleRoute?.fill}>
              {singleRoute?.icon}
            </HeaderIcon>
          )}
          <HeaderRouteSingleItem to={singleRoute?.route}>
            {t(singleRoute?.i18key)}
          </HeaderRouteSingleItem>
          {index !== breadcrumbs?.length - 1 && <HeaderArrow />}
        </HeaderItem>
      ))}
    </HeaderBreadCrumbsContainer>
  );
};

export default HeaderBreadCrumbs;
