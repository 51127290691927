import { Box } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../themes";

export const FindingStatusCardContainer = styled(Box)`
  height: ${(props) => props?.$borderWidth * 2 + 20}px;
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
  width: fit-content;
  border-radius: 24px;
  padding-left: 8px;
  padding-right: 8px;
  background-color: ${(props) => props?.$statusBackgroundColor};
  color: ${(props) => props?.$statusColor};
  font-family: ${selectedTheme.fonts.mainText};
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  white-space: nowrap;
  border: ${(props) => `${props?.$borderWidth}px solid ${props?.$borderColor}`};
`;
export const StatusCircle = styled(Box)`
  background-color: ${(props) => props?.$statusColor};
  border-radius: 100%;
  width: 8px;
  height: 8px;
  border: 1px solid
    ${(props) => props?.$circleBorderColor || props?.$statusColor};
`;
