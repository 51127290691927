import {
  CLEAR_DOCUMENT_TYPES,
  CREATE_DOCUMENT_TYPE_ERROR,
  CREATE_DOCUMENT_TYPE_FETCH,
  CREATE_DOCUMENT_TYPE_SUCCESS,
  DELETE_DOCUMENT_TYPE_ERROR,
  DELETE_DOCUMENT_TYPE_FETCH,
  DELETE_DOCUMENT_TYPE_SUCCESS,
  EDIT_DOCUMENT_TYPE_ERROR,
  EDIT_DOCUMENT_TYPE_FETCH,
  EDIT_DOCUMENT_TYPE_SUCCESS,
  GET_DOCUMENT_TYPES_CLEAR,
  GET_DOCUMENT_TYPES_ERROR,
  GET_DOCUMENT_TYPES_FETCH,
  GET_DOCUMENT_TYPES_SUCCESS,
  SET_DOCUMENT_TYPE,
  SET_DOCUMENT_TYPES,
  SET_DOCUMENT_TYPE_ERROR,
  SET_TOTAL_DOCUMENT_TYPES,
  SINGLE_DOCUMENT_TYPE_ERROR,
  SINGLE_DOCUMENT_TYPE_FETCH,
  SINGLE_DOCUMENT_TYPE_SET,
  SINGLE_DOCUMENT_TYPE_SUCCESS,
} from "./documentTypesActionConstants";

export const setDocumentType = (payload) => ({
  type: SET_DOCUMENT_TYPE,
  payload,
});

export const setDocumentTypeError = (payload) => ({
  type: SET_DOCUMENT_TYPE_ERROR,
  payload,
});

export const fetchDocumentTypes = (payload) => ({
  type: GET_DOCUMENT_TYPES_FETCH,
  payload,
});
export const fetchDocumentTypesSuccess = (payload) => ({
  type: GET_DOCUMENT_TYPES_SUCCESS,
  payload,
});
export const fetchDocumentTypesError = (payload) => ({
  type: GET_DOCUMENT_TYPES_ERROR,
  payload,
});

export const fetchDocumentTypeClear = () => ({
  type: GET_DOCUMENT_TYPES_CLEAR,
});

export const fetchCreateDocumentType = (payload) => ({
  type: CREATE_DOCUMENT_TYPE_FETCH,
  payload,
});
export const fetchCreateDocumentTypeSuccess = (payload) => ({
  type: CREATE_DOCUMENT_TYPE_SUCCESS,
  payload,
});
export const fetchCreateDocumentTypeError = (payload) => ({
  type: CREATE_DOCUMENT_TYPE_ERROR,
  payload,
});

export const fetchEditDocumentType = (payload) => ({
  type: EDIT_DOCUMENT_TYPE_FETCH,
  payload,
});
export const fetchEditDocumentTypeSuccess = (payload) => ({
  type: EDIT_DOCUMENT_TYPE_SUCCESS,
  payload,
});
export const fetchEditDocumentTypeError = (payload) => ({
  type: EDIT_DOCUMENT_TYPE_ERROR,
  payload,
});

export const fetchDeleteDocumentType = (payload) => ({
  type: DELETE_DOCUMENT_TYPE_FETCH,
  payload,
});
export const fetchDeleteDocumentTypeSuccess = (payload) => ({
  type: DELETE_DOCUMENT_TYPE_SUCCESS,
  payload,
});
export const fetchDeleteDocumentTypeError = (payload) => ({
  type: DELETE_DOCUMENT_TYPE_ERROR,
  payload,
});

export const fetchSingleDocumentType = (payload) => ({
  type: SINGLE_DOCUMENT_TYPE_FETCH,
  payload,
});
export const fetchSingleDocumentTypeSuccess = (payload) => ({
  type: SINGLE_DOCUMENT_TYPE_SUCCESS,
  payload,
});
export const fetchSingleDocumentTypeError = (payload) => ({
  type: SINGLE_DOCUMENT_TYPE_ERROR,
  payload,
});

export const setSingleDocumentType = (payload) => ({
  type: SINGLE_DOCUMENT_TYPE_SET,
  payload,
});
export const setDocumentTypes = (payload) => ({
  type: SET_DOCUMENT_TYPES,
  payload,
});
export const setTotalDocumentTypes = (payload) => ({
  type: SET_TOTAL_DOCUMENT_TYPES,
  payload,
});
export const clearDocumentTypes = () => ({
  type: CLEAR_DOCUMENT_TYPES,
});
