import React from "react";
import PropTypes from "prop-types";
import {
  TextRendererContainer,
  TextRendererInnerContainer,
} from "./TextRenderer.styled";

const TextRenderer = (props) => {
  return (
    <TextRendererContainer>
      <TextRendererInnerContainer>{props?.value}</TextRendererInnerContainer>
    </TextRendererContainer>
  );
};

TextRenderer.propTypes = {
  value: PropTypes.children,
};

export default TextRenderer;
