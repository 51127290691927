import * as Yup from "yup";
import { renameColumnsFormikConstants } from "../initialValues/renameColumnsInitialValues";

export default (subtitleRequired) =>
  Yup.object().shape({
    [renameColumnsFormikConstants.columnTitle]: Yup.string()
      .trim()
      .required("table.renameColumns.titleRequired"),
    [renameColumnsFormikConstants.columnSubtitle]: subtitleRequired
      ? Yup.string().trim().required("table.renameColumns.subtitleRequired")
      : Yup.string().trim(),
  });
