import React from "react";
// import PropTypes from 'prop-types'
import { ReactComponent as ImageIconSvg } from "../../../assets/images/svg/image.svg";

const ImageIcon = (props) => {
  return <ImageIconSvg {...props} />;
};

ImageIcon.propTypes = {};

export default ImageIcon;
