import { Box } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../../../themes";

export const LastChangesRendererContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
export const LastChangesDate = styled(Box)`
  font-family: ${selectedTheme.fonts.mainText};
  font-size: 14px;
  line-height: 20px;
  font-feature-settings: "salt" on;
  color: ${selectedTheme.colors.titleColor};
`;

export const LastChangesAuthor = styled(Box)`
  font-family: ${selectedTheme.fonts.mainText};
  font-size: 14px;
  line-height: 20px;
  font-feature-settings: "salt" on;
  color: ${selectedTheme.colors.tableColumnText};
`;
