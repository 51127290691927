import React from "react";
import PropTypes from "prop-types";
import LoginLayout from "../../layouts/LoginLayout/LoginLayout";
import LoginContent from "../../components/Login/LoginContent/LoginContent";
import LoginImage from "../../components/Login/LoginImage/LoginImage";

const LoginPage = () => {
  return <LoginLayout leftImage={<LoginImage />} content={<LoginContent />} />;
};

LoginPage.propTypes = {
  history: PropTypes.shape({
    replace: PropTypes.func,
    push: PropTypes.func,
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
  }),
};
export default LoginPage;
