import BenchmarkingRenderer from "../../components/MeasuringInstruments/MeasuringInstrumentsTable/Renderers/BenchmarkingRenderer/BenchmarkingRenderer";
import DeleteRenderer from "../../components/TemplateEditor/ActiveTemplateEditor/TemplateMeasuringInstrumentsEditor/TemplateMeasuringInstrumentsTable/Renderers/DeleteRenderer";
import UserRenderer from "../../components/Users/UsersTable/ActiveUsersTable/Renderers/UserRenderer/UserRenderer";

export default {
  name: {
    id: 0,
    i18key: "measuringInstruments.table.name.columnTitle",
    renderer: UserRenderer,
    style: {
      padding: "6px",
      flex: "2",
      whiteSpace: "wrap",
    },
    disableSort: true,
  },
  header: {
    id: 8,
    i18key: "measuringInstruments.table.name.columnTitle",
    mobile: true,
    disableSort: true,
    style: {},
  },
  factoryNumber: {
    id: 1,
    i18key: "measuringInstruments.table.factoryNumber.columnTitle",
    style: {
      padding: "6px 18px",
      whitespace: "wrap",
      width: "180px",
    },
    disableSort: true,
  },
  benchmarking: {
    id: 2,
    i18key: "measuringInstruments.table.benchmarking.columnTitle",
    renderer: BenchmarkingRenderer,
    style: {
      padding: "6px 18px",
      whitespace: "wrap",
      width: "250px",
    },
    disableSort: true,
  },
  delete: {
    id: 3,
    i18key: "",
    renderer: DeleteRenderer,
    disableSort: true,
    style: {
      padding: "6px 24px 6px 6px",
      justifyContent: "center",
      textAlign: "center",
      width: "80px",
    },
  },
};
