import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { DocumentTextField } from "../NewDocumentContent.styled";
import { documentFormikConstants } from "../../../../initialValues/documentInitialValues";
import { LinkCheck, LinkFieldContainer } from "./LinkField.styled";
import Tooltip from "../../../Tooltip/Tooltip";

const LinkField = (props) => {
  const formik = props?.formik;
  const { t } = useTranslation();
  const isValidUrl = useMemo(
    () => formik?.values?.[documentFormikConstants?.link]?.trim?.()?.length > 0,
    [formik?.values?.[documentFormikConstants?.link]]
  );
  const linkProps = useMemo(() => {
    if (isValidUrl)
      return {
        href: formik?.values?.[documentFormikConstants?.link],
        target: "_blank",
      };
    else return {};
  }, [isValidUrl, formik?.values?.[documentFormikConstants?.link]]);
  return (
    <LinkFieldContainer>
      <DocumentTextField
        name={documentFormikConstants.link}
        requiredField
        disabledField={props?.disabled}
        label={t("documents.newPage.form.link.title")}
        placeholder={t("documents.newPage.form.link.placeholder")}
        value={formik?.values?.[documentFormikConstants.link]}
        onChange={(e) =>
          formik?.setFieldValue?.(documentFormikConstants.link, e.target.value)
        }
        error={
          formik?.touched?.[documentFormikConstants.link] &&
          formik?.errors?.[documentFormikConstants.link] &&
          t(formik?.errors?.[documentFormikConstants.link])
        }
        helperText={
          formik?.errors?.[documentFormikConstants.link] &&
          formik?.touched?.[documentFormikConstants.link]
        }
        fullWidth
      />
      <Tooltip
        title={!isValidUrl && t("documents.newPage.form.link.urlInvalid")}
      >
        <LinkCheck $isValidUrl={isValidUrl} {...linkProps}>
          {t("documents.newPage.linkCheck")}
        </LinkCheck>
      </Tooltip>
    </LinkFieldContainer>
  );
};

LinkField.propTypes = {
  formik: PropTypes.object,
  disabled: PropTypes.bool,
};

export default LinkField;
