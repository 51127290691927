import React from "react";
import PropTypes from "prop-types";
import { Route, Switch } from "react-router";
import {
  EDIT_SCANNING_OBJECTS_PAGE,
  NEW_SCANNING_OBJECTS_PAGE,
} from "../../../constants/pages";
import ScanningObjectsPage from "./ScanningObjectsPage/ScanningObjectsPage";
import NewScanningObjectPage from "./NewScanningObjectPage/NewScanningObjectPage";
import EditScaningObjectPage from "./EditScanningObjectPage/EditScanningObjectPage";
import AuthRoute from "../../../components/Router/AuthRoute";
import { MANAGER_ROLE, OPERATOR_ROLE } from "../../../constants/rolesConstants";

const ScanningObjects = () => {
  return (
    <Switch>
      <AuthRoute
        roles={[MANAGER_ROLE]}
        exact
        path={NEW_SCANNING_OBJECTS_PAGE}
        component={NewScanningObjectPage}
      />
      <AuthRoute
        roles={[MANAGER_ROLE, OPERATOR_ROLE]}
        exact
        path={EDIT_SCANNING_OBJECTS_PAGE}
        component={EditScaningObjectPage}
      />
      <Route component={ScanningObjectsPage} />
    </Switch>
  );
};

ScanningObjects.propTypes = {
  children: PropTypes.node,
};

export default ScanningObjects;
