import styled, { css } from "styled-components";
import ImagePicker from "../../../ImagePicker/ImagePicker";

export const CalibrationCertificatePicker = styled(ImagePicker)`
  flex: 1;
  ${(props) =>
    props?.$hasImage ?
    css`
      /* padding: 8px; */
      width: auto !important;
      max-width: 412px !important;
      height: auto !important;
      max-height: 420px !important;
      /*min-height: 420px !important;*/
      & > form {
        padding: ${(props) => !props?.image && "8px"};
      }
      & p {
        text-align: center;
      }
      & * {
        max-width: 412px !important;
        max-height: 420px !important;
      }
       
    `
    : css`
    height: auto !important;
    width: auto !important; 
    max-width: 100% !important;
    min-width: 100% !important;
    & * {
      max-width: 100% !important;
    }
  `}
  }
`;
