import React from "react";
import PropTypes from "prop-types";
import { EmailsListContainer } from "./EmailsList.styled";
import EmailsListItem from "./EmailsListItem/EmailsListItem";

const EmailsList = (props) => {
  return (
    <EmailsListContainer>
      {props?.emails?.map((singleMail, index) => (
        <EmailsListItem email={singleMail} key={index} />
      ))}
    </EmailsListContainer>
  );
};

EmailsList.propTypes = {
  emails: PropTypes.array,
};

export default EmailsList;
