import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { FindingItemTableHeaderContainer } from "./FindingItemTableHeader.styled";
import FindingItemTableCell from "../FindingItemTableCell/FindingItemTableCell";
import { TableRow } from "@mui/material";

const FindingItemTableHeader = (props) => {
  const hasSubtitles = useMemo(() =>
    props?.columns?.some((singleColumn) => singleColumn?.columnSubtitle)
  );
  const groupedColumnsByTitle = useMemo(() => {
    const columns = props?.columns;
    let uniqueColumnTitles = [
      ...new Set(columns?.map?.((singleColumn) => singleColumn?.columnTitle)),
    ];
    return uniqueColumnTitles?.map?.((singleUniqueColumnTitle) =>
      columns?.filter?.(
        (singleColumn) => singleColumn?.columnTitle === singleUniqueColumnTitle
      )
    );
  }, [props?.columns]);

  const columnSubtitles = useMemo(
    () =>
      groupedColumnsByTitle?.reduce((acc, val) => {
        if (val?.every((singleColumn) => !singleColumn?.columnSubtitle) && val?.length === 1)
          return [...acc];
        return [...acc, ...val];
      }, []),
    [groupedColumnsByTitle]
  );

  return (
    <FindingItemTableHeaderContainer $hasSubtitles={hasSubtitles}>
      <TableRow>
        {groupedColumnsByTitle?.map?.((singleGroupedColumns, index) => (
          <FindingItemTableCell
            rowSpan={
              singleGroupedColumns?.length === 1 &&
              !singleGroupedColumns?.[0]?.columnSubtitle
                ? 2
                : 1
            }
            colSpan={singleGroupedColumns?.length}
            key={index}
            readonly={props?.readonly}
            firstInFirstRow={index === 0}
            lastInFirstRow={index === groupedColumnsByTitle?.length - 1}
            firstInLastRow={
              index === 0 &&
              props?.noData &&
              singleGroupedColumns?.every?.(
                (singleColumn) => !singleColumn?.columnSubtitle
              )
            }
            lastInLastRow={
              index === groupedColumnsByTitle?.length - 1 &&
              props?.noData &&
              singleGroupedColumns?.every?.(
                (singleColumn) => !singleColumn?.columnSubtitle
              )
            }
            isHeaderCell
            titleCell
            columnId={singleGroupedColumns?.[0]?.id}
            value={singleGroupedColumns?.[0]?.columnTitle}
          />
        ))}
      </TableRow>
      <TableRow>
        {columnSubtitles?.map?.((singleColumn, index) => (
          <FindingItemTableCell
            colSpan={1}
            rowSpan={1}
            key={index}
            readonly={props?.readonly}
            firstInLastRow={
              index === 0 &&
              props?.noData &&
              groupedColumnsByTitle?.[0]?.some(
                (singleColumnInGroup) => singleColumnInGroup?.columnSubtitle
              )
            }
            lastInLastRow={
              index === columnSubtitles?.length - 1 &&
              props?.noData &&
              groupedColumnsByTitle?.[groupedColumnsByTitle?.length - 1]?.some(
                (singleColumnInGroup) => singleColumnInGroup?.columnSubtitle
              )
            }
            isHeaderCell
            subtitleCell
            columnId={singleColumn?.id}
            value={singleColumn?.columnSubtitle}
          />
        ))}
        {/* ) : (
            <FindingItemTableColumnGroupContainer
              key={index}
              $groupFlex={singleGroupedColumns?.length}
            >
              <FindingItemTableColumnGroupFlexContainer>
                <FindingItemTableCell
                  titleCell
                  readonly={props?.readonly}
                  firstInFirstRow={index === 0}
                  lastInFirstRow={index === groupedColumnsByTitle?.length - 1}
                  columnId={singleGroupedColumns?.[0]?.id}
                  isHeaderCell
                  value={singleGroupedColumns?.[0]?.columnTitle}
                />
                <FindingItemTableColumnSubtitlesContainer>
                  {singleGroupedColumns?.map?.(
                    (singleColumn, indexOfColumn) => (
                      <FindingItemTableCellFlexContainer key={indexOfColumn}>
                        <FindingItemTableCell
                          columnId={singleColumn?.id}
                          readonly={props?.readonly}
                          firstInLastRow={
                            indexOfColumn === 0 && index === 0 && props?.noData
                          }
                          lastInLastRow={
                            indexOfColumn ===
                              singleGroupedColumns?.length - 1 &&
                            index === groupedColumnsByTitle?.length - 1 &&
                            props?.noData
                          }
                          subtitleCell
                          isHeaderCell
                          value={singleColumn?.columnSubtitle}
                        />
                      </FindingItemTableCellFlexContainer>
                    )
                  )}
                </FindingItemTableColumnSubtitlesContainer>
              </FindingItemTableColumnGroupFlexContainer>
            </FindingItemTableColumnGroupContainer>
          )
        )} */}
      </TableRow>
      {props?.otherRows}
      {/* {props?.columns?.map?.((singleColumn, index) => (
        <FindingItemTableCell
          firstInFirstRow={index === 0}
          lastInFirstRow={index === props?.columns?.length - 1}
          isHeaderCell
          key={index}
          value={singleColumn?.columnTitle?.value}
        />
      ))} */}
    </FindingItemTableHeaderContainer>
  );
};

FindingItemTableHeader.propTypes = {
  columns: PropTypes.array,
  noData: PropTypes.bool,
  readonly: PropTypes.bool,
  otherRows: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
};

export default FindingItemTableHeader;
