import React from 'react'
// import PropTypes from 'prop-types'
import {ReactComponent as FindingIconSvg} from "../../../assets/images/svg/finding.svg"

const FindingIcon = props => {
  return (
    <FindingIconSvg {...props} />
  )
}

FindingIcon.propTypes = {}

export default FindingIcon