import { Box } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../themes";
import { hexToRGB } from "../../util/helpers/colorHelper";
// import selectedTheme from "../../themes";

export const FooterContainer = styled(Box)`
  padding: 16px 10px;
  padding-left: ${(props) => (props?.$isSidebarOpened ? "268px" : "82px")};
  width: 100%;
  font-weight: 300;
  background-color: white;
  box-shadow: 0px -1px 1px ${hexToRGB(selectedTheme.colors.shadowColor, 0.03)};
  border-top: 1px solid ${selectedTheme.colors.tableBorder};
  position: absolute;
  bottom: 0;
  font-family: ${selectedTheme.fonts.mainText};
  font-size: .9rem;
  line-height: 1.5;
  left: 0;
  right: 0;
  color: ${selectedTheme.colors.gray};
  text-align: center;
  & span {
    color: ${selectedTheme.colors.gray};
    font-weight: 600;
  }
  @media (max-width: 600px) {
    padding-left: 10px;
  }
  & a.tremium-link {
    text-decoration: none;
    color:${selectedTheme.colors.gray};
  }
  & a.tremium-link:visited {
    color:${selectedTheme.colors.gray};
  }
`;
export const LogoIcon = styled.img`
  height: .9rem;
`
