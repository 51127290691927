import {
  SET_STUFF,
  SET_STUFF_ERROR,
  GET_STUFFS_FETCH,
  GET_STUFFS_SUCCESS,
  GET_STUFFS_ERROR,
  GET_STUFFS_CLEAR,
  CREATE_STUFF_FETCH,
  CREATE_STUFF_SUCCESS,
  CREATE_STUFF_ERROR,
  EDIT_STUFF_FETCH,
  EDIT_STUFF_SUCCESS,
  EDIT_STUFF_ERROR,
  SINGLE_STUFF_FETCH,
  SINGLE_STUFF_SUCCESS,
  SINGLE_STUFF_ERROR,
  SINGLE_STUFF_SET,
  SET_STUFFS,
  SET_TOTAL_STUFFS,
  CLEAR_STUFFS,
  DELETE_STUFF_FETCH,
  DELETE_STUFF_SUCCESS,
  DELETE_STUFF_ERROR,
  REMOVE_STUFF,
  SINGLE_STUFF_CLEAR,
} from "./stuffsActionConstants";

export const setStuff = (payload) => ({
  type: SET_STUFF,
  payload,
});

export const setStuffError = (payload) => ({
  type: SET_STUFF_ERROR,
  payload,
});

export const fetchStuffs = (payload) => ({
  type: GET_STUFFS_FETCH,
  payload,
});
export const fetchStuffsSuccess = (payload) => ({
  type: GET_STUFFS_SUCCESS,
  payload,
});
export const fetchStuffsError = (payload) => ({
  type: GET_STUFFS_ERROR,
  payload,
});

export const fetchStuffsClear = () => ({
  type: GET_STUFFS_CLEAR,
});

export const fetchCreateStuff = (payload) => ({
  type: CREATE_STUFF_FETCH,
  payload,
});
export const fetchCreateStuffSuccess = (payload) => ({
  type: CREATE_STUFF_SUCCESS,
  payload,
});
export const fetchCreateStuffError = (payload) => ({
  type: CREATE_STUFF_ERROR,
  payload,
});

export const fetchEditStuff = (payload) => ({
  type: EDIT_STUFF_FETCH,
  payload,
});
export const fetchEditStuffSuccess = (payload) => ({
  type: EDIT_STUFF_SUCCESS,
  payload,
});
export const fetchEditStuffError = (payload) => ({
  type: EDIT_STUFF_ERROR,
  payload,
});

export const fetchSingleStuff = (payload) => ({
  type: SINGLE_STUFF_FETCH,
  payload,
});
export const fetchSingleStuffSuccess = (payload) => ({
  type: SINGLE_STUFF_SUCCESS,
  payload,
});
export const fetchSingleStuffError = (payload) => ({
  type: SINGLE_STUFF_ERROR,
  payload,
});

export const setSingleStuff = (payload) => ({
  type: SINGLE_STUFF_SET,
  payload,
});
export const clearSingleStuff = (payload) => ({
  type: SINGLE_STUFF_CLEAR,
  payload,
});
export const setStuffs = (payload) => ({
  type: SET_STUFFS,
  payload,
});
export const setTotalStuffs = (payload) => ({
  type: SET_TOTAL_STUFFS,
  payload,
});
export const clearStuffs = () => ({
  type: CLEAR_STUFFS,
});

export const fetchDeleteStuffs = (payload) => ({
  type: DELETE_STUFF_FETCH,
  payload,
});
export const fetchDeleteStuffsSuccess = (payload) => ({
  type: DELETE_STUFF_SUCCESS,
  payload,
});
export const fetchDeleteStuffsError = (payload) => ({
  type: DELETE_STUFF_ERROR,
  payload,
});
export const removeStuff = (payload) => ({
  type: REMOVE_STUFF,
  payload,
});
