import React from "react";
import PropTypes from "prop-types";
import {
  APRImagePickersContainer,
  APRImagePickersLabel,
  APRImagePickersListContainer,
  APRSingleImagePicker,
} from "./APRImagePickers.styled";
import { useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";

const APRImagePickers = (props) => {
  const { t } = useTranslation();
  const formik = props?.formik;

  const hasImages = useMemo(
    () => formik?.values?.APR?.length !== 0,
    [formik?.values]
  );

  const handleChangeAPRImage = (newImage, indexOfImage) => {
    let oldImages = [...formik?.values?.APR];
    if (newImage !== "") {
      oldImages[indexOfImage] = {
        imageUrl: newImage,
        orderNumber: indexOfImage,
      };
    } else {
      oldImages.splice(indexOfImage, 1);
    }
    formik?.setFieldValue?.("APR", oldImages);
  };
  const handleAddAPRImage = (newImage) => {
    let oldImages = [...formik?.values?.APR];
    oldImages.push({
      imageUrl: newImage,
      orderNumber: oldImages?.length,
    });
    formik?.setFieldValue?.("APR", oldImages);
  };

  return (
    <APRImagePickersContainer>
      <APRImagePickersLabel>{t("companySettings.APR")}</APRImagePickersLabel>
      <APRImagePickersListContainer $hasBorders={hasImages}>
        {formik?.values?.APR?.map((singleAPRImage, index) => (
          <APRSingleImagePicker
            key={index}
            smallerButtons
            width={hasImages ? 95 : 320}
            height={hasImages ? 142 : 120}
            minWidth={hasImages ? 95 : 320}
            minHeight={hasImages ? 142 : 120}
            $hasImages={hasImages}
            image={singleAPRImage?.imageUrl}
            setImage={(newImage) => handleChangeAPRImage(newImage, index)}
            modalTitle={t("companySettings.APR")}
          />
        ))}
        <APRSingleImagePicker
          $hasImages={hasImages}
          console
          key={formik?.values?.APR?.length}
          smallerButtons
          setImage={handleAddAPRImage}
          minWidth={hasImages ? 95 : 320}
          minHeight={hasImages ? 142 : 120}
          width={hasImages ? 95 : 320}
          height={hasImages ? 142 : 120}
          additionalDescriptionText={hasImages && t("imagePicker.moreImages")}
          descriptionText={<Trans i18nKey="companySettings.choseAPR" />}
        />
      </APRImagePickersListContainer>
    </APRImagePickersContainer>
  );
};

APRImagePickers.propTypes = {
  formik: PropTypes.object,
};

export default APRImagePickers;
