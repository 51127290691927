import React from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { selectFindingMeasuringInstruments } from "../../../../../../store/selectors/findingsSelectors";
import { toggleTableModal } from "../../../../../../store/actions/modal/modalActions";
import measuringInstrumentsTableConstants from "../../../../../../constants/tableConstants/measuringInstrumentsTableConstants";
import TextRenderer from "../../../../../Table/Renderers/TextRenderer/TextRenderer";
import { FindingModalSingleButtonContainer } from "../../FindingTabChooser.styled";
import MeasuringInstrumentsIcon from "../../../../../Icon/Icons/MeasuringInstrumentsIcon";
import { formatDate } from "../../../../../../util/helpers/dateHelpers";
import Tooltip from "../../../../../Tooltip/Tooltip";

const MeasuringInstrumentsModalButton = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const selectedMeasuringInstruments = useSelector(
    selectFindingMeasuringInstruments(props?.subjectId)
  );
  const handleShowInstruments = () => {
    dispatch(
      toggleTableModal({
        title: t("measuringInstruments.title"),
        tableColumns: Object.keys(measuringInstrumentsTableConstants)
          ?.filter?.(
            (property) =>
              property !== "lastChanges" &&
              property !== "actions" &&
              property !== "findingTypes"
          )
          .map((property) => {
            return {
              ...measuringInstrumentsTableConstants[property],
              field: t(measuringInstrumentsTableConstants[property].i18key),
              propertyName: property,
              backendProperty:
                measuringInstrumentsTableConstants[property]?.backendProperty ||
                property,
              style: {
                ...measuringInstrumentsTableConstants[property]?.style,
                "> div": {
                  width: "100%",
                },
                minWidth: "0px !important",
              },
              renderer:
                measuringInstrumentsTableConstants[property]?.renderer ||
                TextRenderer,
              disableSort: true,
              redirect: false,
            };
          }),
        entityDataSelector: selectFindingMeasuringInstruments(props?.subjectId),
        selectedData: selectedMeasuringInstruments,
        mapDataFunction: (newData) => {
          return newData?.map?.((singleData) => ({
            name: singleData?.name,
            factoryNumber: singleData?.serialNumber,
            manufacturer: singleData?.manufacturer,
            benchmarking: {
              name: singleData?.calibrationNumber,
              date: singleData?.validUntil
                ? formatDate(singleData?.validUntil)
                : null,
            },
            // id: singleData?.id,
          }));
        },
      })
    );
  };
  return (
    <Tooltip title={t("findings.modalButtons.measuringInst")}>
      <FindingModalSingleButtonContainer onClick={handleShowInstruments}>
        <MeasuringInstrumentsIcon />
      </FindingModalSingleButtonContainer>
    </Tooltip>
  );
};

MeasuringInstrumentsModalButton.propTypes = {
  subjectId: PropTypes.any,
};

export default MeasuringInstrumentsModalButton;
