import { Box } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../../themes";

export const TemplateCardDetailContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 5.5px;
  align-items: center;
`;
export const TemplateCardDetailIcon = styled(Box)`
  width: 24px;
  height: 24px;
  & svg {
    width: 24px;
    height: 24px;
  }
  & path {
    stroke: ${selectedTheme.colors.filterColor};
  }
`;
export const TemplateCardDetailValue = styled(Box)`
  font-family: ${selectedTheme.fonts.mainText};
  font-weight: 500;
  font-size: 17.617px;
  line-height: 21px;
  color: ${selectedTheme.colors.filterColor};
`;
