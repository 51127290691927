import React from "react";
import PropTypes from "prop-types";
import {
  DeleteRendererContainer,
  DeleteRendererIconContainer,
} from "./DeleteRenderer.styled";
import DeleteIcon from "../../../../../Icon/Icons/DeleteIcon";
import Tooltip from "../../../../../Tooltip/Tooltip";
import i18n from "../../../../../../i18n";

const DeleteRenderer = (props) => {
  const handleRemoveEntity = () => {
    let indexOfEntity = null;
    if (props?.value?.index != null) indexOfEntity = props?.value?.index;
    props?.rendererProps?.handleRemoveEntity(props?.value, indexOfEntity);
  };
  return (
    <DeleteRendererContainer>
      <DeleteRendererIconContainer onClick={handleRemoveEntity}>
        <Tooltip title={i18n.t("scanningObjects.tooltip.removeInstrument")}>
          <DeleteIcon />
        </Tooltip>
      </DeleteRendererIconContainer>
    </DeleteRendererContainer>
  );
};

DeleteRenderer.propTypes = {
  children: PropTypes.node,
  dispatch: PropTypes.func,
  value: PropTypes.object,
  rendererProps: PropTypes.any,
};

export default DeleteRenderer;
