import React from 'react'
// import PropTypes from 'prop-types'
import {ReactComponent as FindingTypesIconSvg} from "../../../assets/images/svg/finding-types.svg"

const FindingTypesIcon = props => {
  return (
    <FindingTypesIconSvg {...props} />
  )
}

FindingTypesIcon.propTypes = {}

export default FindingTypesIcon