import React from "react";
import PropTypes from "prop-types";
import {
  ActionsContainer,
  CopyIcon,
  HighlightedText,
  ExportIcon,
  EyeIcon,
  DetailsContainer,
  TitleDetails,
  FindingTypeContainer,
  PageTitleContainer,
  SendMailIcon,
  TitleContainer,
  UndoIcon,
  TrashIcon,
  BlockIcon,
  UnblockIcon,
} from "./PageTitle.styled";
import Title from "../Typography/Title/Title";
import Tooltip from "../Tooltip/Tooltip";
import { useTranslation } from "react-i18next";
import useIsMobile from "../../hooks/useIsMobile";
import ActionButtons from "./ActionButtons/ActionButtons";

const PageTitle = (props) => {
  const { t } = useTranslation();
  const { isMobile } = useIsMobile();
  return (
    <PageTitleContainer>
      <DetailsContainer>
        <TitleDetails>
          <TitleContainer>
            <Title title={props?.title} />
            {props?.isEditing && props?.highlightedText && (
              <HighlightedText>{`${
                props?.highlightedText || ""
              }`}</HighlightedText>
            )}
          </TitleContainer>
          {props?.isEditing && (
            <ActionsContainer>
              {props?.showCopy && (
                <Tooltip
                  title={
                    props?.isChanged
                      ? t("common.saveFirst")
                      : t(props?.copyTooltip)
                  }
                >
                  <CopyIcon
                    $disabledIcon={props?.disableCopy || props?.isChanged}
                    onClick={props?.onCopyClick}
                  />
                </Tooltip>
              )}
              {props?.showExport && (
                <Tooltip
                  title={
                    props?.isChanged
                      ? t("common.saveFirst")
                      : t(props?.exportTooltip)
                  }
                >
                  <ExportIcon
                    $disabledIcon={props?.disableExport || props?.isChanged}
                    onClick={props?.onExportClick}
                  />
                </Tooltip>
              )}
              {!isMobile && props?.showPreview && (
                <Tooltip
                  title={
                    props?.isChanged
                      ? t("common.saveFirst")
                      : t(props?.previewTooltip)
                  }
                >
                  <EyeIcon
                    $disabledIcon={props?.disablePreview}
                    onClick={props?.onPreviewClick}
                  />
                </Tooltip>
              )}
              {props?.showSendMail && (
                <Tooltip title={t(props?.sendMailTooltip)}>
                  <SendMailIcon
                    $disabledIcon={props?.disableSendMail}
                    onClick={props?.onSendMailClick}
                  />
                </Tooltip>
              )}
              {props?.showUndo && (
                <Tooltip title={t(props?.undoTooltip)}>
                  <UndoIcon
                    $disabledIcon={props?.disableUndo}
                    onClick={props?.onUndoClick}
                  />
                </Tooltip>
              )}
              {props?.showDelete && (
                <Tooltip title={t(props?.deleteTooltip)}>
                  <TrashIcon
                    $disabledIcon={props?.disableDelete}
                    onClick={props?.onDeleteClick}
                  />
                </Tooltip>
              )}
              {props?.showBlock && (
                <Tooltip title={t(props?.blockTooltip)}>
                  <BlockIcon
                    $disabledIcon={props?.disableBlock}
                    onClick={props?.onBlockClick}
                  />
                </Tooltip>
              )}
              {props?.showUnblock && (
                <Tooltip title={t(props?.unblockTooltip)}>
                  <UnblockIcon
                    $disabledIcon={props?.disableUnblock}
                    onClick={props?.onUnblockClick}
                  />
                </Tooltip>
              )}
            </ActionsContainer>
          )}
        </TitleDetails>
        {props?.findingType && (
          <FindingTypeContainer>{props?.findingType}</FindingTypeContainer>
        )}
      </DetailsContainer>
      {!isMobile && !props?.hideActionButtons && (
        <ActionButtons
          disabledSaveButton={props?.disableSaveButton}
          handleClickSubmitButton={props?.onSubmitClick}
        />
      )}
    </PageTitleContainer>
  );
};

PageTitle.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node,
  ]),
  highlightedText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node,
  ]),
  isEditing: PropTypes.bool,
  hideActionButtons: PropTypes.bool,
  isChanged: PropTypes.bool,
  findingType: PropTypes.string,
  // Tooltip
  copyTooltip: PropTypes.string,
  exportTooltip: PropTypes.string,
  previewTooltip: PropTypes.string,
  sendMailTooltip: PropTypes.string,
  deleteTooltip: PropTypes.string,
  blockTooltip: PropTypes.string,
  unblockTooltip: PropTypes.string,
  undoTooltip: PropTypes.string,
  // Show flags
  showCopy: PropTypes.bool,
  showExport: PropTypes.bool,
  showPreview: PropTypes.bool,
  showDelete: PropTypes.bool,
  showBlock: PropTypes.bool,
  showUnblock: PropTypes.bool,
  showSendMail: PropTypes.bool,
  showUndo: PropTypes.bool,
  // Disable flags
  disableCopy: PropTypes.bool,
  disableExport: PropTypes.bool,
  disablePreview: PropTypes.bool,
  disableDelete: PropTypes.bool,
  disableBlock: PropTypes.bool,
  disableUnblock: PropTypes.bool,
  disableSendMail: PropTypes.bool,
  disableSaveButton: PropTypes.bool,
  disableUndo: PropTypes.bool,
  // Click functions
  onCopyClick: PropTypes.func,
  onExportClick: PropTypes.func,
  onPreviewClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  onBlockClick: PropTypes.func,
  onUnblockClick: PropTypes.func,
  onSendMailClick: PropTypes.func,
  onUndoClick: PropTypes.func,
  onSubmitClick: PropTypes.func,
};

PageTitle.defaultProps = {
  copyTooltip: "common.copy",
  previewTooltip: "common.preview",
  exportTooltip: "common.pdf",
  sendMailTooltip: "findings.forwardToMail",
  deleteTooltip: "common.delete",
  blockTooltip: "common.activate",
  unblockTooltip: "common.deactivate",
  undoTooltip: "findings.undoCancelled",
};

export default PageTitle;
