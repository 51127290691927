import React, { useCallback, useEffect, useMemo, useRef } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { makeToastMessage } from "../../../../store/utils/makeToastMessage";
import { APPLICATION_TOAST_CONTAINER_ID } from "../../../../constants/toastConstants";
import invitedUsersTableConstants from "../../../../constants/tableConstants/invitedUsersTableConstants";
import TextRenderer from "../../../Table/Renderers/TextRenderer/TextRenderer";
import { formatDateTime } from "../../../../util/helpers/dateHelpers";
import { findRoleByRoleName } from "../../../../constants/rolesConstants";
import { UsersTableContainer } from "./InvitedUsersTable.styled";
import Table from "../../../Table/Table";
import {
  fetchUsers,
  fetchUsersClear,
} from "../../../../store/actions/user/userActions";
import {
  selectTotalUsers,
  selectUsersData,
} from "../../../../store/selectors/usersSelectors";
import { toggleInviteUserModal } from "../../../../store/actions/modal/modalActions";
import { useDispatch } from "react-redux";

const InvitedUsersTable = () => {
  const { t } = useTranslation();
  const tableRef = useRef(null);
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (location?.state?.forceRefetch) {
      tableRef?.current?.setPage(location?.state?.page);
      tableRef?.current?.setSearchValue(location?.state?.searchValue);
      let currentState = { ...history?.location?.state };
      delete currentState.page;
      delete currentState.searchValue;
      delete currentState.forceRefetch;
      history.replace({
        state: currentState,
      });
    }
    if (location?.state?.isBlocked) {
      makeToastMessage(
        {
          title: t("toast.success.deactivateTitle", {
            typeOfData: t("users.typeOfData"),
          }),
          description: t("toast.success.deactivateDescription", {
            name: history.location.state?.name,
            typeOfData: t("users.typeOfDataPlural").toLowerCase(),
          }),
        },
        {
          containerId: APPLICATION_TOAST_CONTAINER_ID,
        }
      );
      let currentState = { ...history?.location?.state };
      delete currentState.name;
      delete currentState.isBlocked;
      history.replace({
        state: currentState,
      });
    }
  }, [location]);

  const tableColumns = useMemo(() => {
    return Object.keys(invitedUsersTableConstants).map((property) => ({
      ...invitedUsersTableConstants[property],
      field: t(invitedUsersTableConstants[property].i18key),
      backendProperty:
        invitedUsersTableConstants[property]?.backendProperty || property,
      propertyName: property,
      style: invitedUsersTableConstants[property]?.style,
      renderer: invitedUsersTableConstants[property]?.renderer || TextRenderer,
    }));
  }, [invitedUsersTableConstants, t]);

  const mapDataFunction = useCallback(
    (newData) =>
      newData?.map?.((singleData) => ({
        mail: singleData?.email,
        lastChanges: {
          date: formatDateTime(
            singleData?.updatedAtUtc || new Date().toString()
          ),
          author: `${singleData?.updatedByUser?.firstName || ""} ${
            singleData?.updatedByUser?.lastName || ""
          }`,
        },
        id: singleData?.id,
        actions: {
          firstName: singleData?.firstName,
          lastName: singleData?.lastName,
          email: singleData?.email || "Not provided",
          role: singleData?.roles?.map((item) => findRoleByRoleName(item)),
          lastLogin: formatDateTime(singleData?.lastLoginDate),
          userId: singleData?.id,
          routeState: {
            page: tableRef?.current?.page,
            searchValue: tableRef?.current?.searchValue,
          },
          totalData: newData?.length,
          tableRef,
          t,
          isActiveTab: true,
          refetchTable,
        },
      })),
    []
  );

  const handleApiResponseSuccess = () => {
    tableRef?.current?.forceRefetch?.();
  };

  const handleClickInviteButton = () => {
    dispatch(
      toggleInviteUserModal({
        title: "users.inviteModal.title",
        handleApiResponseSuccess,
      })
    );
  };

  const refetchTable = () => {
    tableRef?.current?.forceRefetch();
  };
  return (
    <UsersTableContainer>
      <Table
        tableColumns={tableColumns}
        smallerTextForMobile
        searchPlaceholder={t("users.searchPlaceholder")}
        dispatchFunction={fetchUsers}
        customPayload={{ isInvited: true }}
        hideAddButton
        initialPage={location?.state?.page}
        initialSearchValue={location?.state?.searchValue}
        dataSelector={selectUsersData}
        totalDataSelector={selectTotalUsers}
        showInviteButton
        handleClickInviteButton={handleClickInviteButton}
        mapDataFunction={mapDataFunction}
        clearDispatchFunction={fetchUsersClear}
        ref={tableRef}
      />
    </UsersTableContainer>
  );
};

InvitedUsersTable.propTypes = {
  children: PropTypes.node,
};

export default InvitedUsersTable;
