import { Box, Typography } from "@mui/material";
import styled from "styled-components";

export const TextRendererContainer = styled(Box)`
  display: inline-block;
  width: 100%;
`;
export const TextRendererInnerContainer = styled(Typography)`
  font-family: "Inter";
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  font-feature-settings: "salt" on;
  white-space: normal;
  word-wrap: break-word;
`;
