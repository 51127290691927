import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeLoadingModal, toggleSetLoadingModal } from "../store/actions/modal/modalActions";
import { selectLoaderCount } from "../store/selectors/loadingSelectors";

export const useIsLoading = () => {
  const loaderCount = useSelector(selectLoaderCount);
  const dispatch = useDispatch();

  useEffect(() => {
    if (loaderCount !== 0) {
      dispatch(toggleSetLoadingModal());
    } else  {
      dispatch(closeLoadingModal());
    }
    return () => {
      dispatch(closeLoadingModal());
    };
  }, [loaderCount]);
};
