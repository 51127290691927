import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import { selectIsLoadingByActionType } from "../../../store/selectors/loadingSelectors";
import { SINGLE_USER_SCOPE } from "../../../store/actions/user/userActionConstants";
import { selectSingleUser } from "../../../store/selectors/usersSelectors";
import {
  fetchEditUser,
  fetchSingleUser,
} from "../../../store/actions/user/userActions";
import { CompaniesEditUserPageContainer } from "./CompaniesEditUserPage.styled";
import NewUserForm from "../../../components/Users/NewUser/NewUserForm";
import LastChanges from "../../../components/LastChanges/LastChanges";
import { formatDateTime } from "../../../util/helpers/dateHelpers";
import history from "../../../store/utils/history";

const CompaniesEditUserPage = () => {
  const { t } = useTranslation();
  const routeMatch = useRouteMatch();
  const dispatch = useDispatch();
  const prefilledData = useSelector(selectSingleUser);
  const isLoading = useSelector(selectIsLoadingByActionType(SINGLE_USER_SCOPE));

  useEffect(() => {
    dispatch(
      fetchSingleUser({
        userId: routeMatch.params?.userId,
        companyId: routeMatch?.params?.companyId,
      })
    );
  }, [routeMatch]);

  // Add layout
  return (
    <CompaniesEditUserPageContainer>
      <NewUserForm
        dispatchFunction={fetchEditUser}
        title={t("users.editUser")}
        prefilledData={prefilledData}
        isEditing
        isDeactive={history?.state?.state?.blocked || false}
        isLoading={isLoading ? true : false}
        companyId={routeMatch?.params?.companyId}
        submitText={t("common.save")}
      />
      {isLoading ? (
        <></>
      ) : (
        <LastChanges
          created={{
            date: formatDateTime(
              prefilledData?.createdAtUtc || new Date().toString()
            ),
            author: `${prefilledData?.createdByUser?.firstName || ""} ${
              prefilledData?.createdByUser?.lastName || ""
            }`,
          }}
          lastChanges={{
            date: formatDateTime(
              prefilledData?.updatedAtUtc || new Date().toString()
            ),
            author: `${prefilledData?.updatedByUser?.firstName || ""} ${
              prefilledData?.updatedByUser?.lastName || ""
            }`,
          }}
        />
      )}
    </CompaniesEditUserPageContainer>
  );
};

export default CompaniesEditUserPage;
