import { Box } from "@mui/material";
import styled from "styled-components";
import { ReactComponent as Logout } from "../../assets/images/svg/logout.svg";
import selectedTheme from "../../themes";
import Link from "../Link/Link";
import DropdownIcon from "../Icon/Icons/DropdownIcon";

export const UserPopoverContainer = styled(Box)`
  padding: 32px;
  border-radius: 12px;
  background: white;
`;
export const UserPopoverItemsContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
export const LanguageDropdownIcon = styled(DropdownIcon)`
  width: 12px;
  height: 7px;
  & path {
    stroke: ${selectedTheme.colors.dropdownIcon};
  }
`;
export const UserPopoverSingleItemContainer = styled(Link)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  cursor: pointer;
`;
export const LogoutIcon = styled(Logout)``;
export const UserPopoverItemText = styled(Box)`
  font-family: ${selectedTheme.fonts.mainText};
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${selectedTheme.colors.userPopoverItemColor2};
`;
export const UserPopoverSeparator = styled(Box)`
  width: 100%;
  height: 0px;
  border-top: 1px solid ${selectedTheme.colors.userPopoverSeparator};
  margin: 12px 0;
`;
