import React from "react";
// import PropTypes from 'prop-types'
import { ReactComponent as TextIconSvg } from "../../../assets/images/svg/text.svg";

const TextIcon = (props) => {
  return <TextIconSvg {...props} />;
};

TextIcon.propTypes = {};

export default TextIcon;
