import React from "react";
// import PropTypes from 'prop-types'
import { ReactComponent as FindingBreadIconSvg } from "../../../assets/images/svg/findings-bc.svg";

const FindingBreadIcon = (props) => {
  return <FindingBreadIconSvg {...props} />;
};

FindingBreadIcon.propTypes = {};

export default FindingBreadIcon;
