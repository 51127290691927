import React from "react";
import PropTypes from "prop-types";
import {
  InputsContainer,
  PasswordValidationText,
  SetPasswordModalContainer,
} from "./SetPasswordModal.styled";
import { useDispatch } from "react-redux";
import { closeModal } from "../../../store/actions/modal/modalActions";
import { Trans, useTranslation } from "react-i18next";
import { useFormik } from "formik";
import setPasswordInitialValues from "../../../initialValues/setPasswordInitialValues";
import setPasswordValidationSchema from "../../../validations/setPasswordValidationSchema";
import PasswordField from "./PasswordField/PasswordField";
import ConfirmPasswordField from "./ConfirmPasswordField/ConfirmPasswordField";
import {
  ButtonsContainer,
  CancelButton,
  CloseButton,
  ModalTitle,
  SubmitButton,
} from "../Modal.styled";
import BackdropComponent from "../../Modals/ModalComponents/BackdropComponent";

const SetPasswordModal = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const handleCloseModal = () => {
    dispatch(closeModal());
  };
  const handleSubmit = (values) => {
    props?.onSubmit(values);
    dispatch(closeModal());
  };
  const formik = useFormik({
    initialValues: setPasswordInitialValues,
    validationSchema: props?.pattern
      ? props?.pattern
      : setPasswordValidationSchema(),
    onSubmit: handleSubmit,
    validateOnBlur: true,
    enableReinitialize: true,
  });
  return (
    <>
      {props?.rank > 1 ? (
        <BackdropComponent
          isLoading
          handleClose={handleCloseModal}
          position="fixed"
        />
      ) : (
        <></>
      )}
      <SetPasswordModalContainer
        component="form"
        onSubmit={formik?.handleSubmit}
        rank={props?.rank}
      >
        <CloseButton onClick={handleCloseModal} />
        <ModalTitle>{props?.title}</ModalTitle>
        <InputsContainer>
          <PasswordField formik={formik} />
          <ConfirmPasswordField formik={formik} />
          {props?.showValidationDescription !== false && (
            <PasswordValidationText>
              <Trans i18nKey={"changePassword.validationPasswordDescription"} />
            </PasswordValidationText>
          )}
        </InputsContainer>
        <ButtonsContainer>
          <CancelButton onClick={handleCloseModal}>
            {t("common.cancel")}
          </CancelButton>
          <SubmitButton onClick={formik?.handleSubmit}>
            {t("common.save")}
          </SubmitButton>
        </ButtonsContainer>
      </SetPasswordModalContainer>
    </>
  );
};

SetPasswordModal.propTypes = {
  children: PropTypes.node,
  rank: PropTypes.number,
  title: PropTypes.string,
  onSubmit: PropTypes.func,
  pattern: PropTypes.any,
  showValidationDescription: PropTypes.bool,
};
SetPasswordModal.defaultProps = {
  title: "Promeni lozinku",
  onSubmit: () => {},
};

export default SetPasswordModal;
