import React from "react";
import PropTypes from "prop-types";
import {
  ClearIcon,
  DateTextField,
  FindingsFilterComponentContainer,
  FindingsFilterComponentLabel,
  FindingsFilterDatePickerContainer,
  LabelContainer,
} from "./FindingsFilterComponent.styled";
import ReactDatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";

const FindingsFilterComponent = (props) => {
  const { t } = useTranslation();

  return (
    <FindingsFilterComponentContainer>
      <FindingsFilterDatePickerContainer>
        <LabelContainer>
          <FindingsFilterComponentLabel>
            {t("findings.filter.dateMin")}
          </FindingsFilterComponentLabel>
        </LabelContainer>
        <ReactDatePicker
          placeholderText={t("findings.filter.placeholder.date")}
          onChange={props?.setStartingDate}
          selected={props?.startingDate}
          dateFormat="dd.MM.yyyy"
          customInput={
            <DateTextField
              date
              requiredField={false}
              placeholderText={t("findings.filter.placeholder.date")}
              value={props?.startingDate}
              onChange={props?.setStartingDate}
              icon={<ClearIcon onClick={() => props?.setStartingDate("")} />}
              fullWidth
            />
          }
        />
      </FindingsFilterDatePickerContainer>
      <FindingsFilterDatePickerContainer>
        <LabelContainer>
          <FindingsFilterComponentLabel>
            {t("findings.filter.dateMax")}
          </FindingsFilterComponentLabel>
        </LabelContainer>
        <ReactDatePicker
          placeholderText={t("findings.filter.placeholder.date")}
          onChange={props?.setEndingDate}
          selected={props?.endingDate}
          dateFormat="dd.MM.yyyy"
          customInput={
            <DateTextField
              date
              requiredField={false}
              placeholderText={t("findings.filter.placeholder.date")}
              value={props?.endingDate}
              onChange={props?.setEndingDate}
              icon={<ClearIcon onClick={() => props?.setEndingDate("")} />}
              fullWidth
            />
          }
        />
      </FindingsFilterDatePickerContainer>
    </FindingsFilterComponentContainer>
  );
};

FindingsFilterComponent.propTypes = {
  children: PropTypes.node,
  startingDate: PropTypes.object,
  endingDate: PropTypes.object,
  setStartingDate: PropTypes.func,
  setEndingDate: PropTypes.func,
};

export default FindingsFilterComponent;
