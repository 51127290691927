import React, { useCallback, useRef, useState } from "react";
import PropTypes from "prop-types";
import {
  DeleteButton,
  DNDIcon,
  DNDIconContainer,
  EditButton,
  EditButtonsContainer,
  QuestionCount,
  TemplateQuestionCardContainer,
  TemplateQuestionCardDescription,
  TemplateQuestionCardTitle,
  TemplateQuestionCardTypeIcon,
  TemplateQuestionDropContainer,
  TemplateQuestionPreviewContainer,
} from "./TemplateQuestionCard.styled";
import { useDrag, useDrop } from "react-dnd";
import { QUESTION_CARD_DND } from "../../../constants/dndConstants";
import Tooltip from "../../Tooltip/Tooltip";
import i18n from "../../../i18n";
import questionTypeConstants from "../../../constants/questionTypeConstants";
import TextIcon from "../../Icon/Icons/TextIcon";
import TickCircledIcon from "../../Icon/Icons/TickCircledIcon";
import ImageIcon from "../../Icon/Icons/ImageIcon";
import TableIcon from "../../Icon/Icons/TableIcon";

const TemplateQuestionCard = (props) => {
  const [isHovered, setIsHovered] = useState(false);
  const [canDrop, setCanDrop] = useState(false);

  const onDrop = (item) => {
    if (item?.ordinalNumber === props?.ordinalNumber) return;
    props?.handleMoveQuestion(
      item?.ordinalNumber - 1,
      props?.ordinalNumber - 1
    );
  };

  const hoverTimeoutFunction = useCallback(
    () => setCanDrop(false),
    [setCanDrop]
  );
  const ref = useRef(null);
  const [{ handlerId }, drop] = useDrop(
    {
      accept: QUESTION_CARD_DND,
      collect(monitor) {
        return {
          handlerId: monitor.getHandlerId(),
        };
      },
      drop: onDrop,
      // hover(item, monitor) {
      //   if (!ref.current) {
      //     return;
      //   }
      //   const dragIndex = item?.ordinalNumber - 1;
      //   const hoverIndex = props?.ordinalNumber - 1;
      //   if (dragIndex === hoverIndex) {
      //     return;
      //   }
      //   const hoverBoundingRect = ref.current?.getBoundingClientRect();
      //   const hoverBound =
      //     ((hoverBoundingRect.bottom - hoverBoundingRect.top) / 5) * 4;
      //   const clientOffset = monitor.getClientOffset();
      //   const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      //   if (dragIndex < hoverIndex && hoverClientY < hoverBound) {
      //     return;
      //   }
      //   if (dragIndex > hoverIndex && hoverClientY > hoverBound) {
      //     return;
      //   }
      //   setCanDrop(true);
      //   if (hoverTimeout) clearTimeout(hoverTimeout);
      //   hoverTimeout = setTimeout(hoverTimeoutFunction, 200);
      // },
      hover(item, monitor) {
        if (!ref.current) {
          return;
        }
        const dragIndex = item?.ordinalNumber - 1;
        const hoverIndex = props?.ordinalNumber - 1;
        if (dragIndex === hoverIndex) {
          return;
        }
        const hoverBoundingRect = ref.current?.getBoundingClientRect?.();
        const clientOffset = monitor.getClientOffset?.();
        setCanDrop(
          clientOffset?.y > hoverBoundingRect?.top &&
            clientOffset?.y < hoverBoundingRect?.bottom
        );
        if (hoverTimeout) clearTimeout(hoverTimeout);
        hoverTimeout = setTimeout(hoverTimeoutFunction, 200);
      },
    },
    [hoverTimeoutFunction, props?.chosenSectionId]
  );

  const [{ isDragging }, drag, preview] = useDrag({
    //eslint-disable-line
    type: QUESTION_CARD_DND,
    item: () => {
      return { ordinalNumber: props?.ordinalNumber };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  let hoverTimeout;
  drop(ref);

  const handleHoverStart = () => setIsHovered(true);
  const handleHoverEnd = () => setIsHovered(false);
  return (
    <TemplateQuestionDropContainer
      key={props?.ordinalNumber + props?.title}
      ref={ref}
      $canDrop={canDrop}
      $isDragging={isDragging}
      data-handler-id={handlerId}
    >
      <TemplateQuestionPreviewContainer
        key={props?.ordinalNumber + props?.title}
        ref={preview}
      >
        <TemplateQuestionCardContainer
          onMouseEnter={handleHoverStart}
          onMouseLeave={handleHoverEnd}
        >
          {!props?.isReadOnly && (
            <DNDIconContainer ref={drag}>
              <DNDIcon />
            </DNDIconContainer>
          )}
          <QuestionCount
            vertical
            key={props?.ordinalNumber + props?.title}
            number={props?.ordinalNumber}
            onBelowArrowClick={props?.onBelowArrowClick}
            onAboveArrowClick={props?.onAboveArrowClick}
            disabled={props?.isReadOnly}
          />
          <Tooltip title={props?.title}>
            <TemplateQuestionCardTitle>
              {props?.title}
            </TemplateQuestionCardTitle>
          </Tooltip>
          {props?.isRequired && (
            <TemplateQuestionCardDescription>
              {i18n.t("questions.requiredAnswer")}
            </TemplateQuestionCardDescription>
          )}
          {props?.hasResearchPassed && (
            <TemplateQuestionCardDescription>
              {i18n.t("questions.researchPassed")}
            </TemplateQuestionCardDescription>
          )}
          <TemplateQuestionCardTypeIcon>
            {props?.questionType === questionTypeConstants.TEXT && <TextIcon />}
            {props?.questionType === questionTypeConstants.SELECT && (
              <TickCircledIcon />
            )}
            {props?.questionType === questionTypeConstants.IMAGE && (
              <ImageIcon />
            )}
            {props?.questionType === questionTypeConstants.TABLE && (
              <TableIcon />
            )}
          </TemplateQuestionCardTypeIcon>
          {isHovered && !props?.isReadOnly && (
            <EditButtonsContainer>
              <Tooltip title={i18n.t("scanningObjects.tooltip.editItem")}>
                <EditButton onClick={props?.handleEditQuestion} />
              </Tooltip>
              <Tooltip title={i18n.t("scanningObjects.tooltip.removeItem")}>
                <DeleteButton onClick={props?.handleDeleteQuestion} />
              </Tooltip>
            </EditButtonsContainer>
          )}
        </TemplateQuestionCardContainer>
      </TemplateQuestionPreviewContainer>
    </TemplateQuestionDropContainer>
  );
};

TemplateQuestionCard.propTypes = {
  ordinalNumber: PropTypes.number,
  title: PropTypes.string,
  isRequired: PropTypes.bool,
  hasResearchPassed: PropTypes.bool,
  questionType: PropTypes.number,
  onBelowArrowClick: PropTypes.func,
  onAboveArrowClick: PropTypes.func,
  handleDeleteQuestion: PropTypes.func,
  handleEditQuestion: PropTypes.func,
  handleChangeQuestionIndexes: PropTypes.func,
  handleMoveQuestion: PropTypes.func,
  isReadOnly: PropTypes.isReadOnly,
  isMobile: PropTypes.bool,
  chosenSectionId: PropTypes.number,
};

export default TemplateQuestionCard;
