import React from "react";
// import PropTypes from 'prop-types'
import { ReactComponent as TickSvg } from "../../../assets/images/svg/tick.svg";

const TickIcon = (props) => {
  return <TickSvg {...props} />;
};

TickIcon.propTypes = {};

export default TickIcon;
