// import { isString } from "lodash";

export function separateByUppercase(string) {
  return string.split(/(?=[A-Z])/).join(" ");
}

export function separateByUnderscore(string) {
  return string.replaceAll("_", " ");
}

export function joinArrayWithComma(arr) {
  return arr.join(", ");
}
export const removeBackslashes = (string) => {
  let newString = string;
  while (newString.indexOf("/") > -1) {
    newString = newString.replace("/", "");
  }
  return newString;
};
export const removeSemicolonsAtEnd = (string) => {
  let newString = string;
  while (newString.charAt(newString?.length - 1) === ";") {
    newString = newString.slice(0, -1);
  }
  return newString;
};
// export const matchUrl = (string) => {
//   if (!isString(string)) return false;
//   return string.match(
//     /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/i
//   );
// };
