import * as Yup from "yup";
import { newMeasuringInstrumentFormikConstants } from "../initialValues/newMeasuringInstrumentInitialValues";

export default () =>
  Yup.object().shape({
    [newMeasuringInstrumentFormikConstants.findingTypes]: Yup.array(),
    [newMeasuringInstrumentFormikConstants.name]: Yup.string()
      .trim()
      .max(255, "standards.modal.validationMessage")
      .required("measuringInstruments.errors.nameRequired"),
    [newMeasuringInstrumentFormikConstants.factoryNumber]: Yup.string(),
    [newMeasuringInstrumentFormikConstants.manufacturer]: Yup.string(),
    [newMeasuringInstrumentFormikConstants.benchmarking]: Yup.string(),
    [newMeasuringInstrumentFormikConstants.date]: Yup.string(),
    [newMeasuringInstrumentFormikConstants.calibrationCertificateUrl]:
      Yup.string(),
  });
