import React from "react";
import PropTypes from "prop-types";
import {
  CustomTickIcon,
  QuestionCheckboxContainer,
  QuestionCheckboxIcon,
  QuestionCheckboxLabel,
  QuestionCheckboxLabelWrapper,
} from "./QuestionCheckbox.styled";

const QuestionCheckbox = (props) => {
  return (
    <QuestionCheckboxContainer onClick={props?.onChange}>
      <QuestionCheckboxIcon checked={props?.checked}>
        {props?.checked && <CustomTickIcon />}
      </QuestionCheckboxIcon>
      <QuestionCheckboxLabelWrapper>
        <QuestionCheckboxLabel>{props?.label}</QuestionCheckboxLabel>
        <QuestionCheckboxLabel>
          {props?.labelSatisfaction}
        </QuestionCheckboxLabel>
      </QuestionCheckboxLabelWrapper>
    </QuestionCheckboxContainer>
  );
};

QuestionCheckbox.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  label: PropTypes.string,
  labelSatisfaction: PropTypes.string,
};

export default QuestionCheckbox;
