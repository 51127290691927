import {
  createClearType,
  createErrorType,
  createFetchType,
  createSetType,
  createSuccessType,
} from "../actionHelpers";

export const SINGLE_FINDING_SCOPE = "SINGLE_FINDING_SCOPE";
export const SINGLE_FINDING_FETCH = createFetchType(SINGLE_FINDING_SCOPE);
export const SINGLE_FINDING_SUCCESS = createSuccessType(SINGLE_FINDING_SCOPE);
export const SINGLE_FINDING_ERROR = createErrorType(SINGLE_FINDING_SCOPE);
export const SINGLE_FINDING_SET = createSetType(SINGLE_FINDING_SCOPE);
export const SINGLE_FINDING_CLEAR = createClearType(SINGLE_FINDING_SCOPE);
export const SINGLE_FINDING_OLD_CLEAR = createClearType(
  "SINGLE_FINDING_OLD_CLEAR"
);
export const SINGLE_FINDING_SET_OLD = createSetType("SINGLE_FINDING_SET_OLD");
export const SINGLE_FINDING_SET_CLIENT_TO_EDIT = createSetType(
  "SINGLE_FINDING_SET_CLIENT_TO_EDIT"
);
export const SINGLE_FINDING_SET_FACILITY_TO_EDIT = createSetType(
  "SINGLE_FINDING_SET_FACILITY_TO_EDIT"
);
export const SINGLE_FINDING_SET_LOCATION_TO_EDIT = createSetType(
  "SINGLE_FINDING_SET_LOCATION_TO_EDIT"
);
export const SINGLE_FINDING_REMOVE_SCANNING = createSetType(
  "SINGLE_FINDING_REMOVE_SCANNING"
);
export const SINGLE_FINDING_ADD_SCANNING = createSetType(
  "SINGLE_FINDING_ADD_SCANNING"
);
export const SINGLE_FINDING_SET_SCANNING = createSetType(
  "SINGLE_FINDING_SET_SCANNING"
);
export const SINGLE_FINDING_SET_SECTION_NOTE = createSetType(
  "SINGLE_FINDING_SET_SECTION_NOTE"
);
export const SINGLE_FINDING_SET_SUBJECT_NOTE = createSetType(
  "SINGLE_FINDING_SET_SUBJECT_NOTE"
);
export const SINGLE_FINDING_SUBJECT_MOVE_ABOVE = createSetType(
  "SINGLE_FINDING_SUBJECT_MOVE_ABOVE"
);
export const SINGLE_FINDING_SUBJECT_MOVE_BELOW = createSetType(
  "SINGLE_FINDING_SUBJECT_MOVE_BELOW"
);
export const SINGLE_FINDING_COLUMN_SET = createSetType(
  "SINGLE_FINDING_COLUMN_SET"
);
export const SINGLE_FINDING_COLUMN_SET_FROM_MODAL = createSetType(
  "SINGLE_FINDING_COLUMN_SET_FROM_MODAL"
);
export const SINGLE_FINDING_COLUMN_FROM_EDITED_SET = createSetType(
  "SINGLE_FINDING_COLUMN_FROM_EDITED_SET"
);
export const SINGLE_FINDING_COLUMN_ANSWER_SET = createSetType(
  "SINGLE_FINDING_COLUMN_ANSWER_SET"
);
export const SINGLE_FINDING_COLUMN_ANSWER_COPY = createSetType(
  "SINGLE_FINDING_COLUMN_ANSWER_COPY"
);
export const SINGLE_FINDING_COLUMN_ANSWER_ADD = createSetType(
  "SINGLE_FINDING_COLUMN_ANSWER_ADD"
);
export const SINGLE_FINDING_COLUMN_ANSWER_REMOVE = createSetType(
  "SINGLE_FINDING_COLUMN_ANSWER_REMOVE"
);
export const SINGLE_FINDING_SUBJECT_ORDER_NUMBER_REPLACE = createSetType(
  "SINGLE_FINDING_SUBJECT_ORDER_NUMBER_REPLACE"
);
export const SINGLE_FINDING_REVERT_OLD = createSetType(
  "SINGLE_FINDING_REVERT_OLD"
);
export const SINGLE_FINDING_ADD_STATE_CHANGE = createSetType(
  "SINGLE_FINDING_ADD_STATE_CHANGE"
);
export const SINGLE_FINDING_ADD_CHANGE = createSetType(
  "SINGLE_FINDING_ADD_CHANGE"
);
export const SINGLE_FINDING_SET_FINDING_TYPE = createSetType(
  "SINGLE_FINDING_SET_FINDING_TYPE"
);

export const FINDINGS_CREATE_SCOPE = "FINDINGS_CREATE_SCOPE";
export const FINDINGS_CREATE_FETCH = createFetchType(FINDINGS_CREATE_SCOPE);
export const FINDINGS_CREATE_SUCCESS = createSuccessType(FINDINGS_CREATE_SCOPE);
export const FINDINGS_CREATE_ERROR = createErrorType(FINDINGS_CREATE_SCOPE);

const FINDINGS_EDIT_SCOPE = "FINDINGS_EDIT_SCOPE";
export const FINDINGS_EDIT_FETCH = createFetchType(FINDINGS_EDIT_SCOPE);
export const FINDINGS_EDIT_SUCCESS = createSuccessType(FINDINGS_EDIT_SCOPE);
export const FINDINGS_EDIT_ERROR = createErrorType(FINDINGS_EDIT_SCOPE);

const FINDINGS_PRINT_SCOPE = "FINDINGS_PRINT_SCOPE";
export const FINDINGS_PRINT_FETCH = createFetchType(FINDINGS_PRINT_SCOPE);
export const FINDINGS_PRINT_SUCCESS = createSuccessType(FINDINGS_PRINT_SCOPE);
export const FINDINGS_PRINT_ERROR = createErrorType(FINDINGS_PRINT_SCOPE);

export const FINDINGS_ITEM_SET = createSetType("FINDINGS_ITEM_SET");

const FINDINGS_CHANGE_STATE_SCOPE = "FINDINGS_CHANGE_STATE_SCOPE";
export const FINDINGS_CHANGE_STATE_FETCH = createFetchType(
  FINDINGS_CHANGE_STATE_SCOPE
);
export const FINDINGS_CHANGE_STATE_SUCCESS = createSuccessType(
  FINDINGS_CHANGE_STATE_SCOPE
);
export const FINDINGS_CHANGE_STATE_ERROR = createErrorType(
  FINDINGS_CHANGE_STATE_SCOPE
);

const FINDINGS_SEND_MAILS_SCOPE = "FINDINGS_SEND_MAILS_SCOPE";
export const FINDINGS_SEND_MAILS_FETCH = createFetchType(
  FINDINGS_SEND_MAILS_SCOPE
);
export const FINDINGS_SEND_MAILS_SUCCESS = createSuccessType(
  FINDINGS_SEND_MAILS_SCOPE
);
export const FINDINGS_SEND_MAILS_ERROR = createErrorType(
  FINDINGS_SEND_MAILS_SCOPE
);

const FILL_FINDING_QUESTIONS_SCOPE = "FILL_FINDING_QUESTIONS_SCOPE";
export const FILL_FINDING_QUESTIONS_FETCH = createFetchType(
  FILL_FINDING_QUESTIONS_SCOPE
);
export const FILL_FINDING_QUESTIONS_SUCCESS = createSuccessType(
  FILL_FINDING_QUESTIONS_SCOPE
);
export const FILL_FINDING_QUESTIONS_ERROR = createErrorType(
  FILL_FINDING_QUESTIONS_SCOPE
);

const FETCH_FINDING_SUBJECT_SCOPE = "FETCH_FINDING_SUBJECT_SCOPE";
export const FETCH_FINDING_SUBJECT_FETCH = createFetchType(
  FETCH_FINDING_SUBJECT_SCOPE
);
export const FETCH_FINDING_SUBJECT_SUCCESS = createSuccessType(
  FETCH_FINDING_SUBJECT_SCOPE
);
export const FETCH_FINDING_SUBJECT_ERROR = createErrorType(
  FETCH_FINDING_SUBJECT_SCOPE
);

const COPY_FINDING_SCOPE = "COPY_FINDING_SCOPE";
export const COPY_FINDING_FETCH = createFetchType(COPY_FINDING_SCOPE);
export const COPY_FINDING_SUCCESS = createSuccessType(COPY_FINDING_SCOPE);
export const COPY_FINDING_ERROR = createErrorType(COPY_FINDING_SCOPE);

export const PREVIEW_FINDING_SCOPE = "PREVIEW_FINDING_SCOPE";
export const PREVIEW_FINDING_FETCH = createFetchType(PREVIEW_FINDING_SCOPE);
export const PREVIEW_FINDING_SUCCESS = createSuccessType(PREVIEW_FINDING_SCOPE);
export const PREVIEW_FINDING_ERROR = createErrorType(PREVIEW_FINDING_SCOPE);
export const PREVIEW_FINDING_SET = createSetType(PREVIEW_FINDING_SCOPE);

export const FINDINGS_SCOPE = "FINDINGS_SCOPE";
export const FINDINGS_FETCH = createFetchType(FINDINGS_SCOPE);
export const FINDINGS_SUCCESS = createSuccessType(FINDINGS_SCOPE);
export const FINDINGS_ERROR = createErrorType(FINDINGS_SCOPE);
export const FINDINGS_SET = createSetType(FINDINGS_SCOPE);
export const FINDINGS_CLEAR = createClearType(FINDINGS_SCOPE);

export const FINDINGS_SECOND_SCOPE = "FINDINGS_SECOND_SCOPE";
export const FINDINGS_SECOND_FETCH = createFetchType(FINDINGS_SECOND_SCOPE);
export const FINDINGS_SECOND_SUCCESS = createSuccessType(FINDINGS_SECOND_SCOPE);
export const FINDINGS_SECOND_ERROR = createErrorType(FINDINGS_SECOND_SCOPE);

export const FINDINGS_CHANGED_SET = createSetType("FINDINGS_CHANGED_SET");
export const FINDINGS_SET_TOTAL = createSetType("FINDINGS_TOTAL_SCOPE");
export const FINDINGS_SET_ERROR_MESSAGE = createSetType(
  "FINDINGS_ERROR_MESSAGE_SCOPE"
);

export const SINGLE_FINDING_TO_EDIT_SET = createSetType(
  "SINGLE_FINDING_TO_EDIT_SET"
);

export const SINGLE_FINDING_SCHEMAS_SET = createSetType(
  "SINGLE_FINDING_SCHEMAS_SET"
);
export const SINGLE_FINDING_STAFFS_SET = createSetType(
  "SINGLE_FINDING_STAFFS_SET"
);
export const SINGLE_FINDING_SINGLE_STUFF_EDIT = createSetType(
  "SINGLE_FINDING_SINGLE_STUFF_EDIT"
);

export const SINGLE_FINDING_SETTINGS_SET = createSetType(
  "SINGLE_FINDING_SETTINGS_SET"
);

export const SINGLE_FINDING_ATTENDEES_SET = createSetType(
  "SINGLE_FINDING_ATTENDEES_SET"
);
export const REMOVE_ATTENDED_PERSON = createSetType("REMOVE_ATTENDED_PERSON");

export const SINGLE_FINDING_NOTE_SET = createSetType("SINGLE_FINDING_NOTE_SET");

export const FINDINGS_PATCH_SCOPE = "FINDINGS_PATCH_SCOPE";
export const FINDINGS_PATCH_FETCH = createFetchType(FINDINGS_PATCH_SCOPE);
export const FINDINGS_PATCH_SUCCESS = createSuccessType(FINDINGS_PATCH_SCOPE);
export const FINDINGS_PATCH_ERROR = createErrorType(FINDINGS_PATCH_SCOPE);

export const SET_CONCLUSION = createSetType("SCANNING_SUBJECT_CONCLUSION_SET");

export const SCANNING_SUBJECT_CHANGE_NAME = createSetType(
  "SCANNING_SUBJECT_CHANGE_NAME"
);
export const COPY_FINDING_SUBJECT = createSetType("COPY_FINDING_SUBJECT");

export const FINDING_TYPES_SCOPE = "FINDINGS_TYPES_SCOPE";
export const FINDING_TYPES_FETCH = createFetchType(FINDING_TYPES_SCOPE);
export const FINDING_TYPES_SUCCESS = createSuccessType(FINDING_TYPES_SCOPE);
export const FINDING_TYPES_ERROR = createErrorType(FINDING_TYPES_SCOPE);
export const FINDING_TYPES_SET = createSetType(FINDING_TYPES_SCOPE);
export const FINDING_TYPES_CLEAR = createClearType(FINDING_TYPES_SCOPE);
