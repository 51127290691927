import React from "react";
import PropTypes from "prop-types";
import { TableTitle } from "../ActiveTemplateEditor.styled";
import { useTranslation } from "react-i18next";
import { TemplateSectionsEditorContainer } from "./TemplateSectionsEditor.styled";

const TemplateSectionsEditor = (props) => {
  const { t } = useTranslation();
  return (
    <TemplateSectionsEditorContainer>
      <TableTitle>
        {t("scanningObjects.newScanningObject.templateEditor.sections")}
      </TableTitle>
      {props?.children}
    </TemplateSectionsEditorContainer>
  );
};

TemplateSectionsEditor.propTypes = {
  children: PropTypes.node,
  sections: PropTypes.array,
  handleMoveSection: PropTypes.func,
  chosenSectionId: PropTypes.number,
  setChosenSectionId: PropTypes.func,
  handleAddSection: PropTypes.func,
  handleDeleteSection: PropTypes.func,
};

export default TemplateSectionsEditor;
