import React from "react";
import { Redirect } from "react-router-dom";
import { LOGIN_PAGE } from "../../constants/pages";
import useAuth from "../../hooks/useAuth";
import HomePage from "../HomePage/HomePage";
const BasePage = () => {
  const { isLoaded, isLoggedIn } = useAuth();
  if (!isLoaded || isLoaded === undefined || isLoaded === null) return <></>;
  if (isLoggedIn) return <HomePage />;
  return <Redirect to={LOGIN_PAGE} />;
};

export default BasePage;
