import React from "react";
import PropTypes from "prop-types";
import {
  ActionRendererClickIconContainer,
  ActionRendererContainer,
} from "./ActionRenderer.styled";
import {
  closeLoadingModal,
  closeModal,
  toggleSetLoadingModal,
} from "../../../../../../store/actions/modal/modalActions";
import { makeToastMessage } from "../../../../../../store/utils/makeToastMessage";
import { APPLICATION_TOAST_CONTAINER_ID } from "../../../../../../constants/toastConstants";
import {
  fetchActivateLicense,
  fetchDeactivateLicense,
} from "../../../../../../store/actions/licenses/licensesActions";
import i18n from "../../../../../../i18n";
import DeactivateIcon from "../../../../../Icon/Icons/DeactivateIcon";
import ActivateIcon from "../../../../../Icon/Icons/ActivateIcon";
import Tooltip from "../../../../../Tooltip/Tooltip";

const ActionRenderer = (props) => {
  const handleApiResponseError = () => {
    props?.dispatch(closeLoadingModal());
  };

  const handleApiResponseSuccess = (payload) => {
    props?.dispatch(closeModal());
    const titleI18Key = payload?.isDeactivated
      ? "toast.success.deactivateTitle"
      : "toast.success.activateTitle";
    const descriptionI18Key = payload?.isDeactivated
      ? "toast.success.deactivateDescription"
      : "toast.success.activateDescription";
    makeToastMessage(
      {
        title: props?.value?.t?.(titleI18Key, {
          typeOfData: props?.value?.t?.("licenses.typeOfData"),
        }),
        description: props?.value?.t?.(descriptionI18Key, {
          name: props?.value?.t?.("licenses.typeOfData"),
          typeOfData: props?.value
            ?.t?.("licenses.typeOfDataPlural")
            .toLowerCase(),
        }),
      },
      {
        containerId: APPLICATION_TOAST_CONTAINER_ID,
      }
    );
    props?.value?.tableRef?.current?.forceRefetch?.();
  };

  const handleToggleDeactivateLicense = () => {
    props?.dispatch(toggleSetLoadingModal({ rank: 2 }));
    if (props?.value?.isDeactivated) {
      props?.dispatch(
        fetchActivateLicense({
          licenseId: props?.value?.licenseId,
          companyId: props?.value?.companyId,
          handleApiResponseSuccess,
          handleApiResponseError,
        })
      );
    } else {
      props?.dispatch(
        fetchDeactivateLicense({
          licenseId: props?.value?.licenseId,
          companyId: props?.value?.companyId,
          handleApiResponseSuccess,
          handleApiResponseError,
        })
      );
    }
  };

  return (
    <ActionRendererContainer>
      <Tooltip
        title={
          props?.value?.isDeactivated
            ? i18n.t("common.activate")
            : i18n.t("common.deactivate")
        }
      >
        <ActionRendererClickIconContainer
          onClick={handleToggleDeactivateLicense}
        >
          {props?.value?.isDeactivated ? <ActivateIcon /> : <DeactivateIcon />}
        </ActionRendererClickIconContainer>
      </Tooltip>
    </ActionRendererContainer>
  );
};

ActionRenderer.propTypes = {
  children: PropTypes.node,
  dispatch: PropTypes.func,
  value: PropTypes.object,
  rendererProps: PropTypes.any,
};

export default ActionRenderer;
