import { Box } from "@mui/material";
import styled from "styled-components";
import Icon from "../../../../Icon/Icon";
import EditIcon from "../../../../Icon/Icons/EditIcon";
import ActivateIcon from "../../../../Icon/Icons/ActivateIcon";
import DeactivateIcon from "../../../../Icon/Icons/DeactivateIcon";
import Link from "../../../../Link/Link";
import DeleteIcon from "../../../../Icon/Icons/DeleteIcon";

export const ActionRendererContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 17px;
  @media (max-width: 600px) {
    position: absolute;
    top: 25px;
    right: 33px;
  }
`;
export const ActionRendererClickIconContainer = styled(Icon)`
  cursor: pointer;
`;
export const ActionRendererEditIconContainer = styled(Link)`
  cursor: pointer;
`;
export const ActionRendererDeleteIcon = styled(DeleteIcon)`
  width: 20px;
  height: 20px;
`;
export const ActionRendererEditIcon = styled(EditIcon)`
  width: 20px;
  height: 20px;
`;
export const ActionRendererActivateIcon = styled(ActivateIcon)`
  width: 20px;
  height: 20px;
`;
export const ActionRendererDeactivateIcon = styled(DeactivateIcon)`
  width: 20px;
  height: 20px;
`;
