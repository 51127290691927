import React from "react";
import PropTypes from "prop-types";
import { Trans, useTranslation } from "react-i18next";
import ImagePicker from "../../../ImagePicker/ImagePicker";

const SignatureField = (props) => {
  const { t } = useTranslation();
  const formik = props?.formik;
  return (
    <ImagePicker
      smallerButtons
      width={180}
      height={120}
      maxWidth={180}
      maxHeight={120}
      minWidth={50}
      minHeight={50}
      name="signatureUrl"
      label={t("stuffs.signature")}
      image={formik?.values?.signatureUrl}
      setImage={(newImage) => formik?.setFieldValue?.("signatureUrl", newImage)}
      descriptionText={<Trans i18nKey={"stuffs.chooseSignature"} />}
      disabled={props?.isReadOnly}
    />
  );
};

SignatureField.propTypes = {
  formik: PropTypes.object,
  isReadOnly: PropTypes.bool,
};

export default SignatureField;
