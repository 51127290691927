import { Box } from "@mui/material";
import styled from "styled-components";
import DeleteIcon from "../../../../Icon/Icons/DeleteIcon";
import PlusCircledIcon from "../../../../Icon/Icons/PlusCircledIcon";

export const PossibleAnswerContainer = styled(Box)`
  display: flex;
  gap: 10px;
  align-items: center;
`;
export const RemoveAnswerIcon = styled(DeleteIcon)`
  cursor: pointer;
  width: 20px;
  height: 20px;
`;
export const AddAnswerIcon = styled(PlusCircledIcon)`
  cursor: pointer;
  margin-top: 10px;
  width: 20px;
  height: 20px;
`;
