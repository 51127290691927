import { Box } from "@mui/material";
import styled, { css } from "styled-components";
import selectedTheme from "../../../../../../themes";

export const FindingScanningSectionContainer = styled(Box)`
  width: 100%;
  max-width: 400px;
  min-width: 300px;
  display: flex;
  border-radius: 6px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  cursor: pointer;
  gap: 16px;
  padding: 8px 12px;
  opacity: ${(props) => props?.$isDragging && "0.2"};
  ${(props) =>
    props?.$selected &&
    css`
      background-color: ${selectedTheme.colors.findingSubjectChosenBg};
      & ${FindingScanningSectionText} {
        color: ${selectedTheme.colors.pageTitleColor};
      }
      & ${FindingScanningSectionNumber} {
        color: ${selectedTheme.colors.pageTitleColor};
      }
      & ${FindingScanningSectionNumberContainer} {
        /* mix-blend-mode: multiply; */
        /* background-color: ${selectedTheme.colors.primaryButtonLightColor}; */
      }
    `}
`;
export const FindingScanningSectionText = styled(Box)`
  font-family: ${selectedTheme.fonts.mainText};
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  font-feature-settings: "salt" on;
  color: ${selectedTheme.colors.tableColumnText};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 150px;
  min-width: 150px;
  @media (max-width: 600px) {
    max-width: 150px;//calc(100vw - 208px);
  }
`;
export const FindingScanningSectionSecondText = styled(Box)`
  font-family: ${selectedTheme.fonts.mainText};
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  font-feature-settings: "salt" on;
  color: ${selectedTheme.colors.tableColumnText};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 150px;
  min-width: 150px;
  @media (max-width: 600px) {
    max-width:  150px;//calc(100vw - 208px);
  }
`;
export const FindingScanningSectionNumberContainer = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  /* background-color: ${selectedTheme.colors.pagingSelected}; */
`;
export const FindingScanningSectionNumber = styled(Box)`
  font-family: ${selectedTheme.fonts.mainText};
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  font-feature-settings: "salt" on;
  color: ${selectedTheme.colors.tableColumnText};
`;
export const FindingScanningStartContainer = styled(Box)`
  display: flex;
  gap: 8px;
  align-items: center;
`;
export const FindingScanningDropContainer = styled(Box)`
  padding-bottom: ${(props) => props?.$canDrop && "50px"};
  transition: 0.2s;
  display: flex;
   flex-direction: column;
`;


export const SubSectionContainer = styled(Box)`
 
  display: flex;
  flex-direction: column;
  /*gap: 8px;*/
  background-color: white;
  transform: translateY(-10px); 
  transition: opacity 0.3s ease, transform 0.3s ease;
 
  &.visible {
    opacity: 1; 
    transform: translateY(0);
  }
`;

export const SubSectionButton = styled.button`

  font-family: ${selectedTheme.fonts.mainText};
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: ${selectedTheme.colors.tableColumnText};
  background-color:  ${(props) => (props.isSelected ? selectedTheme.colors.sidebar.hoveredIcon : 'white')};
  text-overflow: ellipsis;
  text-align: left ;
  overflow: hidden;
  white-space: nowrap;
  padding: 12px 12px 12px 36px; 
  border-radius: 6px; 
  border:  ${(props) => (props.isSelected ?  '1px solid #D1D5DB': 'none')}; 
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color:  ${selectedTheme.colors.sidebar.hoveredIcon};
  }

  &:focus {
    outline: none; 
  }
`;

export const SubSectionText = styled(Box)`
    display: flex; 
    justify-content: flex-start; 
    align-items: center; 
    -webkit-box-orient: vertical; 
    -webkit-line-clamp: 2;  
    overflow: hidden;   
    font-family: ${selectedTheme.fonts.mainText};
    font-size: 12px;
    font-weight: 500;
    line-height: 12px;
    color: ${selectedTheme.colors.tableColumnText};
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word; 
    white-space: normal;
    overflow: visible; 
    text-overflow: clip; 
    height: 2vh;
    max-width:300px;
 
`;