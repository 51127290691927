import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import ManualTable from "../../../../ManualTable/ManualTable";
import { useTranslation } from "react-i18next";
import TextRenderer from "../../../../Table/Renderers/TextRenderer/TextRenderer";
import { useDispatch, useSelector } from "react-redux";
import {
  selectSingleFindingAttendees,
  selectSingleFindingFindingType,
} from "../../../../../store/selectors/findingsSelectors";
import { toggleSetAttendedPersonModal } from "../../../../../store/actions/modal/modalActions";
import { FindingAttendeesContainer } from "./FindingAttendees.styled";
import findingAttendeesTableConstants from "../../../../../constants/tableConstants/findingTables/findingAttendeesTableConstants";
import { removeAttendedPerson } from "../../../../../store/actions/findings/findingsActions";

const FindingAttendees = (props) => {
  const { t } = useTranslation();
  //   const selectedStaffs = useSelector(selectStuffsData);
  //   const isFindingChanged = useSelector(selectIsFindingSettingsChanged);
  const dispatch = useDispatch();

  //   const changeEntity = () => {
  //     if (!isFindingChanged) dispatch(setFindingsChanged(true));
  //   };
  const attendees = useSelector(selectSingleFindingAttendees);
  const findingType = useSelector(selectSingleFindingFindingType);

  const tableColumns = useMemo(() => {
    return Object.keys(findingAttendeesTableConstants).map((property) => {
      if (property === "actions" && props?.disabled) return;
      return {
        ...findingAttendeesTableConstants[property],
        field: t(findingAttendeesTableConstants[property].i18key),
        propertyName:
          findingAttendeesTableConstants[property]?.property || property,
        backendProperty:
          findingAttendeesTableConstants[property]?.backendProperty || property,
        style: findingAttendeesTableConstants[property]?.style,
        renderer:
          findingAttendeesTableConstants[property]?.renderer || TextRenderer,
      };
    });
  }, [findingAttendeesTableConstants, t, props?.disabled]);

  const mapDataFunction = useCallback(
    (newData) => {
      return newData?.map?.((singleData) => {
        if (singleData == null) return;
        let mappedObject = {
          name: `${singleData?.firstName} ${singleData?.lastName}`,
          title: singleData?.title,
          id: singleData?.id,
          actions: {
            id: singleData?.id,
            name: `${singleData?.firstName} ${singleData?.lastName}`,
            title: singleData?.title,
            data: singleData,
            onDelete: handleRemove,
          },
        };
        if (props?.disabled) delete mappedObject?.delete;
        return mappedObject;
      });
    },
    [props?.disabled]
  );

  const handleRemove = (id) => {
    dispatch(removeAttendedPerson(id));
  };

  const handleClickAddButton = () => {
    dispatch(
      toggleSetAttendedPersonModal({
        title: t("findings.findingAttendees.newPerson"),
        isEditing: false,
        rank: 1,
        disabled: props?.disabled,
      })
    );
  };

  return (
    <FindingAttendeesContainer>
      <ManualTable
        disabled={props?.disabled}
        isExpanded={findingType?.enableAttendees}
        key={JSON.stringify(
          attendees
            ?.filter?.((singleData) => !singleData?.removed)
            ?.map?.((singleData) => {
              return {
                ...singleData?.value,
                firstName: singleData?.value?.firstName?.value,
                lastName: singleData?.value?.lastName?.value,
                title: singleData?.value?.title?.value,
              };
            })
        )}
        fetchedData
        selectedData={attendees
          ?.filter?.((singleData) => !singleData?.removed)
          ?.map?.((singleData) => {
            return {
              ...singleData?.value,
              firstName: singleData?.value?.firstName?.value,
              lastName: singleData?.value?.lastName?.value,
              title: singleData?.value?.title?.value,
            };
          })}
        title={t("findings.findingAttendees.tableTitle")}
        mapDataFunction={mapDataFunction}
        tableColumns={tableColumns}
        buttonTitle={t("common.add")}
        handleClickAddButton={handleClickAddButton}
        noActions
      />
    </FindingAttendeesContainer>
  );
};

FindingAttendees.propTypes = {
  children: PropTypes.node,
  disabled: PropTypes.bool,
};

export default FindingAttendees;
