import { Box } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../../../../themes";
import DragIcon from "../../../../../Icon/Icons/DragIcon";

export const SubjectOrderNumberContainer = styled(Box)`
  display: flex;
  gap: 8px;
  align-items: center;
`;
export const DNDIcon = styled(DragIcon)`
  cursor: grab;
  & circle {
    fill: ${selectedTheme.colors.borderColor};
  }
`;
export const DNDIconContainer = styled(Box)`
  display: flex;
  align-items: center;
  padding: 15px !important;
  margin: -15px -15px;
`;
