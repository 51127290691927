import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { fetchMyCompany } from "../../../store/actions/companies/companiesActions";
//import { selectMyCompany } from "../../../store/selectors/companiesSelector";
import { selectIsSidebarOpened } from "../../../store/selectors/sidebarSelector";
import {
  //LogoImage,
  SidebarLogoContainer,
  SidebarLogoImageContainer,
  SidebarLogoTitle,
} from "./SidebarLogo.styled";
//import Tooltip from "../../Tooltip/Tooltip";
//import { useState } from "react";
//import { fetchImages } from "../../../store/actions/images/imagesActions";
import { ImageOverlay } from "../../ImagePicker/ImagePreview/ImagePreview.styled";
import {ReactComponent as Logo } from "../../../assets/images/svg/IM -logo.svg";
import {ReactComponent as LogoSmall } from "../../../assets/images/svg/Im-logo-short.svg";

const SidebarLogo = () => {
  //const [logoImage, setLogoImage] = useState("");
  const dispatch = useDispatch();
  //const company = useSelector(selectMyCompany);
  const isSidebarOpened = useSelector(selectIsSidebarOpened);

  useEffect(() => {
    dispatch(fetchMyCompany());
  }, []);
  /*useEffect(() => {
    if (company?.logoUrl && company?.logoUrl !== "logo") {
      dispatch(
        fetchImages({
          images: [company?.logoUrl],
          handleApiResponseSuccess: (payload) => {
            //setLogoImage(payload?.imagesArray[0]);
          },
        })
      );
    }
  }, [company?.logoUrl]);*/

  return (
    //<Tooltip title={company?.name} placement="right">
      <SidebarLogoContainer>
        <SidebarLogoImageContainer>
          {/*company?.logoUrl && company?.logoUrl !== "logo" ? (
            <LogoImage src={logoImage} />
          ) : (
            <SidebarLogoIcon />
          )*/}
          {isSidebarOpened ? (
            //<LogoImage src={logo} />
            <Logo/>

          ) : (
            //<LogoImage src={logoSmall} />
            <LogoSmall/>
          )}
          <ImageOverlay $hidden />
        </SidebarLogoImageContainer>
        {isSidebarOpened && (
          <SidebarLogoTitle>{/*company?.name*/}</SidebarLogoTitle>
        )}
      </SidebarLogoContainer>
    //</Tooltip>
  );
};

SidebarLogo.propTypes = {
  children: PropTypes.node,
};

export default SidebarLogo;
