import { 
    SET_CATEGORIES,
    SET_CATEGORIES_ERROR,
    SET_CATEGORIES_TOTAL, 
} from "../../actions/categories/categoriesActionConstant";
import createReducer from "../../utils/createReducer";

  const initialState = {
    categories: {
      loaded: false,
      data: [],
    },
    totalCategories: 0,
    errorMessage:""
  };



  export default createReducer(
    {
      //[GET_CATEGORIES_FETCH]: getCategories,
      //[GET_CATEGORIES_SUCCESS]: getCategoriesSuccess,
      [SET_CATEGORIES_ERROR]: setCategoriesErrorMessage,
      [SET_CATEGORIES]: setCategories,
      [SET_CATEGORIES_TOTAL]: setCategoriesTotal,
      //[SET_TOTAL_COMPANIES]: setTotalCompanies,
      //[GET_CATEGORIES_ERROR]: getCategoriesError,
      //[GET_CATEGORIES_CLEAR]: getCategoriesClear,
    },
    initialState
  );

  function setCategories(state, { payload }) {
    return {
      ...state,
      categories: payload,
    };
  }

  function setCategoriesErrorMessage(state, { payload }) {
    return {
      ...state,
      errorMessage: payload,
    };
  }

  function setCategoriesTotal(state, { payload }) {
    return {
      ...state,
      totalCategories: payload,
    };
  }
