import { all } from "redux-saga/effects";
import clientsSaga from "./clientsSaga";
import loginSaga from "./loginSaga";
import measuringInstrumentsSaga from "./measuringInstrumentsSaga";
import scanningObjectsSaga from "./scanningObjectsSaga";
import standardsSaga from "./standardsSaga";
import stuffsSaga from "./stuffsSaga";
import usersSaga from "./usersSaga";
import companiesSaga from "./companiesSaga";
import findingsSaga from "./findingsSaga";
import imagesSaga from "./imagesSaga";
import findingTypesSaga from "./findingTypesSaga";
import instructionsSaga from "./instructionsSaga";
import licensesSaga from "./licensesSaga";
import documentTypesSaga from "./documentTypesSaga";
import documentsSaga from "./documentsSaga";
import translationsSaga from "./translationsSaga";
import commonTemplatesSaga from "./commonTemplatesSaga";

export default function* rootSaga() {
  yield all([
    loginSaga(),
    usersSaga(),
    standardsSaga(),
    measuringInstrumentsSaga(),
    clientsSaga(),
    scanningObjectsSaga(),
    stuffsSaga(),
    companiesSaga(),
    findingsSaga(),
    findingTypesSaga(),
    imagesSaga(),
    instructionsSaga(),
    licensesSaga(),
    documentTypesSaga(),
    documentsSaga(),
    translationsSaga(),
    commonTemplatesSaga(),
  ]);
}
