import React from "react";
import PropTypes from "prop-types";
import { HorizontalSeparatorContainer } from "./HorizontalSeparator.styled";

const HorizontalSeparator = (props) => {
  return <HorizontalSeparatorContainer $widthMargin={props?.widthMargin} />;
};

HorizontalSeparator.propTypes = {
  widthMargin: PropTypes.number,
};
HorizontalSeparator.defaultProps = {
  widthMargin: 0,
};

export default HorizontalSeparator;
