import { Box } from "@mui/material";
import styled from "styled-components";

export const NewFindingContentContainer = styled(Box)`
padding-bottom: 26px;
`;
export const FindingTabContent = styled(Box)`
  margin-top: 12px;
  @media (max-width: 600px) {
    margin-top: 0px;
  }
`;
