import {
  FINDINGS_ERROR,
  FINDINGS_FETCH,
  FINDINGS_SUCCESS,
  FINDINGS_SET,
  SINGLE_FINDING_ERROR,
  SINGLE_FINDING_FETCH,
  SINGLE_FINDING_SUCCESS,
  SINGLE_FINDING_SET,
  PREVIEW_FINDING_ERROR,
  PREVIEW_FINDING_FETCH,
  PREVIEW_FINDING_SUCCESS,
  PREVIEW_FINDING_SET,
  FINDINGS_CREATE_ERROR,
  FINDINGS_CREATE_FETCH,
  FINDINGS_CREATE_SUCCESS,
  FINDINGS_CHANGE_STATE_ERROR,
  FINDINGS_CHANGE_STATE_FETCH,
  FINDINGS_CHANGE_STATE_SUCCESS,
  FINDINGS_EDIT_ERROR,
  FINDINGS_EDIT_FETCH,
  FINDINGS_EDIT_SUCCESS,
  FINDINGS_PRINT_ERROR,
  FINDINGS_PRINT_FETCH,
  FINDINGS_PRINT_SUCCESS,
  FINDINGS_SEND_MAILS_ERROR,
  FINDINGS_SEND_MAILS_FETCH,
  FINDINGS_SEND_MAILS_SUCCESS,
  FILL_FINDING_QUESTIONS_ERROR,
  FILL_FINDING_QUESTIONS_FETCH,
  FILL_FINDING_QUESTIONS_SUCCESS,
  FINDINGS_CLEAR,
  FINDINGS_SET_TOTAL,
  FINDINGS_SECOND_FETCH,
  FINDINGS_SECOND_SUCCESS,
  FINDINGS_SECOND_ERROR,
  FINDINGS_ITEM_SET,
  SINGLE_FINDING_SET_OLD,
  SINGLE_FINDING_ADD_SCANNING,
  SINGLE_FINDING_REMOVE_SCANNING,
  SINGLE_FINDING_SET_SCANNING,
  SINGLE_FINDING_SET_SUBJECT_NOTE,
  SINGLE_FINDING_SET_SECTION_NOTE,
  SINGLE_FINDING_CLEAR,
  FINDINGS_CHANGED_SET,
  SINGLE_FINDING_SUBJECT_MOVE_ABOVE,
  SINGLE_FINDING_SUBJECT_MOVE_BELOW,
  SINGLE_FINDING_SUBJECT_ORDER_NUMBER_REPLACE,
  SINGLE_FINDING_REVERT_OLD,
  SINGLE_FINDING_TO_EDIT_SET,
  FINDINGS_PATCH_FETCH,
  FINDINGS_PATCH_SUCCESS,
  FINDINGS_PATCH_ERROR,
  SINGLE_FINDING_SCHEMAS_SET,
  SINGLE_FINDING_STAFFS_SET,
  SINGLE_FINDING_SETTINGS_SET,
  SINGLE_FINDING_NOTE_SET,
  COPY_FINDING_FETCH,
  COPY_FINDING_SUCCESS,
  COPY_FINDING_ERROR,
  FETCH_FINDING_SUBJECT_FETCH,
  FETCH_FINDING_SUBJECT_SUCCESS,
  FETCH_FINDING_SUBJECT_ERROR,
  SINGLE_FINDING_OLD_CLEAR,
  SINGLE_FINDING_ADD_STATE_CHANGE,
  SINGLE_FINDING_ADD_CHANGE,
  SET_CONCLUSION,
  SCANNING_SUBJECT_CHANGE_NAME,
  SINGLE_FINDING_COLUMN_SET,
  SINGLE_FINDING_COLUMN_ANSWER_SET,
  SINGLE_FINDING_COLUMN_ANSWER_ADD,
  SINGLE_FINDING_COLUMN_ANSWER_REMOVE,
  SINGLE_FINDING_SINGLE_STUFF_EDIT,
  FINDING_TYPES_FETCH,
  FINDING_TYPES_SUCCESS,
  FINDING_TYPES_ERROR,
  FINDING_TYPES_SET,
  SINGLE_FINDING_ATTENDEES_SET,
  REMOVE_ATTENDED_PERSON,
  FINDING_TYPES_CLEAR,
  SINGLE_FINDING_COLUMN_FROM_EDITED_SET,
  SINGLE_FINDING_COLUMN_ANSWER_COPY,
  SINGLE_FINDING_COLUMN_SET_FROM_MODAL,
  SINGLE_FINDING_SET_CLIENT_TO_EDIT,
  SINGLE_FINDING_SET_LOCATION_TO_EDIT,
  SINGLE_FINDING_SET_FACILITY_TO_EDIT,
  COPY_FINDING_SUBJECT,
  SINGLE_FINDING_SET_FINDING_TYPE,
} from "./findingsActionConstants";

export const fetchFindings = (payload) => ({
  type: FINDINGS_FETCH,
  payload,
});
export const fetchFindingsSuccess = (payload) => ({
  type: FINDINGS_SUCCESS,
  payload,
});
export const fetchFindingsError = (payload) => ({
  type: FINDINGS_ERROR,
  payload,
});
export const addFindingScanningSubject = (payload) => ({
  type: SINGLE_FINDING_ADD_SCANNING,
  payload,
});
export const removeFindingScanningSubject = (payload) => ({
  type: SINGLE_FINDING_REMOVE_SCANNING,
  payload,
});
export const setFindingScanningSubject = (payload) => ({
  type: SINGLE_FINDING_SET_SCANNING,
  payload,
});
export const setFindingSectionNote = (payload) => ({
  type: SINGLE_FINDING_SET_SECTION_NOTE,
  payload,
});
export const setFindingSubjectNote = (payload) => ({
  type: SINGLE_FINDING_SET_SUBJECT_NOTE,
  payload,
});
export const setFindingColumn = (payload) => ({
  type: SINGLE_FINDING_COLUMN_SET,
  payload,
});
export const setSingleFindingFindingType = (payload) => ({
  type: SINGLE_FINDING_SET_FINDING_TYPE,
  payload,
});
export const setFindingColumnFromModal = (payload) => ({
  type: SINGLE_FINDING_COLUMN_SET_FROM_MODAL,
  payload,
});
export const setFindingColumnAnswer = (payload) => ({
  type: SINGLE_FINDING_COLUMN_ANSWER_SET,
  payload,
});
export const addFindingColumnAnswer = (payload) => ({
  type: SINGLE_FINDING_COLUMN_ANSWER_ADD,
  payload,
});
export const copyFindingColumnAnswer = (payload) => ({
  type: SINGLE_FINDING_COLUMN_ANSWER_COPY,
  payload,
});
export const removeFindingColumnAnswer = (payload) => ({
  type: SINGLE_FINDING_COLUMN_ANSWER_REMOVE,
  payload,
});
export const setFindingItem = (payload) => ({
  type: FINDINGS_ITEM_SET,
  payload,
});
export const copyFindingSubject = (payload) => ({
  type: COPY_FINDING_SUBJECT,
  payload,
});
export const revertOldFinding = () => ({
  type: SINGLE_FINDING_REVERT_OLD,
});
export const moveFindingSubjectAbove = (payload) => ({
  type: SINGLE_FINDING_SUBJECT_MOVE_ABOVE,
  payload,
});
export const moveFindingSubjectBelow = (payload) => ({
  type: SINGLE_FINDING_SUBJECT_MOVE_BELOW,
  payload,
});
export const replaceFindingSubjectOrderNumber = (payload) => ({
  type: SINGLE_FINDING_SUBJECT_ORDER_NUMBER_REPLACE,
  payload,
});
export const fetchSecondFindings = (payload) => ({
  type: FINDINGS_SECOND_FETCH,
  payload,
});
export const fetchSecondFindingsSuccess = (payload) => ({
  type: FINDINGS_SECOND_SUCCESS,
  payload,
});
export const fetchSecondFindingsError = (payload) => ({
  type: FINDINGS_SECOND_ERROR,
  payload,
});
export const fetchCreateFinding = (payload) => ({
  type: FINDINGS_CREATE_FETCH,
  payload,
});
export const fetchCreateFindingSuccess = (payload) => ({
  type: FINDINGS_CREATE_SUCCESS,
  payload,
});
export const fetchCreateFindingError = (payload) => ({
  type: FINDINGS_CREATE_ERROR,
  payload,
});
export const fetchEditFinding = (payload) => ({
  type: FINDINGS_EDIT_FETCH,
  payload,
});
export const fetchEditFindingSuccess = (payload) => ({
  type: FINDINGS_EDIT_SUCCESS,
  payload,
});
export const fetchEditFindingError = (payload) => ({
  type: FINDINGS_EDIT_ERROR,
  payload,
});
export const fetchFindingSubject = (payload) => ({
  type: FETCH_FINDING_SUBJECT_FETCH,
  payload,
});
export const fetchFindingSubjectSuccess = (payload) => ({
  type: FETCH_FINDING_SUBJECT_SUCCESS,
  payload,
});
export const fetchFindingSubjectError = (payload) => ({
  type: FETCH_FINDING_SUBJECT_ERROR,
  payload,
});

export const fetchPrintFinding = (payload) => ({
  type: FINDINGS_PRINT_FETCH,
  payload,
});
export const fetchPrintFindingSuccess = (payload) => ({
  type: FINDINGS_PRINT_SUCCESS,
  payload,
});
export const fetchPrintFindingError = (payload) => ({
  type: FINDINGS_PRINT_ERROR,
  payload,
});

export const sendFindingMails = (payload) => ({
  type: FINDINGS_SEND_MAILS_FETCH,
  payload,
});
export const sendFindingMailsSuccess = (payload) => ({
  type: FINDINGS_SEND_MAILS_SUCCESS,
  payload,
});
export const sendFindingMailsError = (payload) => ({
  type: FINDINGS_SEND_MAILS_ERROR,
  payload,
});

export const fillFindingQuestions = (payload) => ({
  type: FILL_FINDING_QUESTIONS_FETCH,
  payload,
});
export const fillFindingQuestionsSuccess = (payload) => ({
  type: FILL_FINDING_QUESTIONS_SUCCESS,
  payload,
});
export const fillFindingQuestionsError = (payload) => ({
  type: FILL_FINDING_QUESTIONS_ERROR,
  payload,
});

export const fetchChangeStateFinding = (payload) => ({
  type: FINDINGS_CHANGE_STATE_FETCH,
  payload,
});
export const fetchChangeStateFindingSuccess = (payload) => ({
  type: FINDINGS_CHANGE_STATE_SUCCESS,
  payload,
});
export const fetchChangeStateFindingError = (payload) => ({
  type: FINDINGS_CHANGE_STATE_ERROR,
  payload,
});

export const fetchCopyFinding = (payload) => ({
  type: COPY_FINDING_FETCH,
  payload,
});
export const fetchCopyFindingSuccess = (payload) => ({
  type: COPY_FINDING_SUCCESS,
  payload,
});
export const fetchCopyFindingError = (payload) => ({
  type: COPY_FINDING_ERROR,
  payload,
});

export const fetchSingleFinding = (payload) => ({
  type: SINGLE_FINDING_FETCH,
  payload,
});
export const fetchSingleFindingSuccess = (payload) => ({
  type: SINGLE_FINDING_SUCCESS,
  payload,
});
export const fetchSingleFindingError = (payload) => ({
  type: SINGLE_FINDING_ERROR,
  payload,
});
export const clearSingleFinding = (payload) => ({
  type: SINGLE_FINDING_CLEAR,
  payload,
});
export const clearSingleFindingOld = (payload) => ({
  type: SINGLE_FINDING_OLD_CLEAR,
  payload,
});
export const setFindings = (payload) => ({
  type: FINDINGS_SET,
  payload,
});
export const setFindingsTotal = (payload) => ({
  type: FINDINGS_SET_TOTAL,
  payload,
});
export const clearFindings = () => ({
  type: FINDINGS_CLEAR,
});

export const setSingleFinding = (payload) => ({
  type: SINGLE_FINDING_SET,
  payload,
});
export const setSingleFindingOld = (payload) => ({
  type: SINGLE_FINDING_SET_OLD,
  payload,
});
export const setFindingsChanged = (payload) => ({
  type: FINDINGS_CHANGED_SET,
  payload,
});

export const setSingleFindingToEdit = (payload) => ({
  type: SINGLE_FINDING_TO_EDIT_SET,
  payload,
});
export const setSingleFindingClientToEdit = (payload) => ({
  type: SINGLE_FINDING_SET_CLIENT_TO_EDIT,
  payload,
});
export const setSingleFindingLocationToEdit = (payload) => ({
  type: SINGLE_FINDING_SET_LOCATION_TO_EDIT,
  payload,
});
export const setSingleFindingFacilityToEdit = (payload) => ({
  type: SINGLE_FINDING_SET_FACILITY_TO_EDIT,
  payload,
});

export const setSingleFindingColumnsFromEdited = (payload) => ({
  type: SINGLE_FINDING_COLUMN_FROM_EDITED_SET,
  payload,
});

// NEW

export const setSingleFindingSchemas = (payload) => ({
  type: SINGLE_FINDING_SCHEMAS_SET,
  payload,
});

export const fetchPatchFinding = (payload) => ({
  type: FINDINGS_PATCH_FETCH,
  payload,
});
export const fetchPatchFindingSuccess = (payload) => ({
  type: FINDINGS_PATCH_SUCCESS,
  payload,
});
export const fetchPatchFindingError = (payload) => ({
  type: FINDINGS_PATCH_ERROR,
  payload,
});

export const setSingleFindingStaffs = (payload) => ({
  type: SINGLE_FINDING_STAFFS_SET,
  payload,
});

export const editFindingSingleStuff = (payload) => ({
  type: SINGLE_FINDING_SINGLE_STUFF_EDIT,
  payload,
});

export const setSingleFindingSettings = (payload) => ({
  type: SINGLE_FINDING_SETTINGS_SET,
  payload,
});

export const setSingleFindingNote = (payload) => ({
  type: SINGLE_FINDING_NOTE_SET,
  payload,
});
export const addFindingStateChange = (payload) => ({
  type: SINGLE_FINDING_ADD_STATE_CHANGE,
  payload,
});
export const addFindingChange = (payload) => ({
  type: SINGLE_FINDING_ADD_CHANGE,
  payload,
});
export const setConclusion = (payload) => ({
  type: SET_CONCLUSION,
  payload,
});

export const setNewSubjectName = (payload) => ({
  type: SCANNING_SUBJECT_CHANGE_NAME,
  payload,
});

export const fetchFindingTypes = (payload) => ({
  type: FINDING_TYPES_FETCH,
  payload,
});
export const fetchFindingTypesSuccess = (payload) => ({
  type: FINDING_TYPES_SUCCESS,
  payload,
});
export const fetchFindingTypesError = (payload) => ({
  type: FINDING_TYPES_ERROR,
  payload,
});
export const setFindingTypes = (payload) => ({
  type: FINDING_TYPES_SET,
  payload,
});

export const fetchFindingPreview = (payload) => ({
  type: PREVIEW_FINDING_FETCH,
  payload,
});
export const fetchFindingPreviewSuccess = (payload) => ({
  type: PREVIEW_FINDING_SUCCESS,
  payload,
});
export const fetchFindingPreviewError = (payload) => ({
  type: PREVIEW_FINDING_ERROR,
  payload,
});
export const setFindingPreview = (payload) => ({
  type: PREVIEW_FINDING_SET,
  payload,
});
export const clearFindingTypes = (payload) => ({
  type: FINDING_TYPES_CLEAR,
  payload,
});

export const setSingleFindingAttendees = (payload) => ({
  type: SINGLE_FINDING_ATTENDEES_SET,
  payload,
});

export const removeAttendedPerson = (payload) => ({
  type: REMOVE_ATTENDED_PERSON,
  payload,
});
