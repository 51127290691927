import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Redirect, Route } from "react-router";
import { LOGIN_PAGE } from "../../constants/pages";
import { selectJWTToken } from "../../store/selectors/loginSelectors";

const PrivateRoute = ({ ...props }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(null);
  const jwtToken = useSelector(selectJWTToken);

  useEffect(() => {
    if (jwtToken) {
      if (jwtToken?.jwtToken?.token) setIsLoggedIn(true);
      else setIsLoggedIn(false);
    } else setIsLoggedIn(false);
  }, [jwtToken]);

  return isLoggedIn !== null ? (
    isLoggedIn ? (
      <Route {...props} />
    ) : (
      <Redirect to={LOGIN_PAGE} />
    )
  ) : (
    <></>
  );
};

export default PrivateRoute;
