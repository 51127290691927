import { all, call, put, takeLatest } from "@redux-saga/core/effects";
import { licensesConstants } from "../../constants/licenseStatusesConstants";
import {
  attemptActivateLicense,
  attemptCreateLicense,
  attemptDeactivateLicense,
  attemptDeleteLicense,
  attemptEditSingleLicense,
  attemptFetchLicenses,
  attemptFetchSingleLicense,
} from "../../request/licensesRequest";
import {
  BEdateFormat,
  convertDateToISO,
  formatDate,
} from "../../util/helpers/dateHelpers";
import {
  ACTIVATE_LICENSE_FETCH,
  CREATE_LICENSE_FETCH,
  DEACTIVATE_LICENSE_FETCH,
  DELETE_LICENSE_FETCH,
  EDIT_LICENSE_FETCH,
  GET_LICENSES_FETCH,
  SINGLE_LICENSE_FETCH,
} from "../actions/licenses/licensesActionConstants";
import {
  fetchActivateLicenseError,
  fetchActivateLicenseSuccess,
  fetchCreateLicenseError,
  fetchCreateLicenseSuccess,
  fetchDeactivateLicenseError,
  fetchDeactivateLicenseSuccess,
  fetchDeleteLicenseError,
  fetchDeleteLicenseSuccess,
  fetchEditLicenseError,
  fetchEditLicenseSuccess,
  fetchLicensesError,
  fetchLicensesSuccess,
  fetchSingleLicenseError,
  fetchSingleLicenseSuccess,
  setLicenses,
  setSingleLicense,
  setTotalLicenses,
} from "../actions/licenses/licensesActions";
import { mapFetchAllFunction } from "../utils/mapFetchAllFunctions";

function* fetchLicenses({ payload }) {
  try {
    const queryObject = mapFetchAllFunction(payload);
    const data = yield call(attemptFetchLicenses, {
      companyId: payload?.customPayload?.companyId,
      query: queryObject,
    });
    yield put(
      setLicenses({
        data: data?.data?.licenses,
      })
    );
    yield put(setTotalLicenses(data?.data?.count));
    if (payload?.handleApiResponseSuccess) {
      yield call(payload?.handleApiResponseSuccess);
    }
    yield put(fetchLicensesSuccess());
  } catch (e) {
    console.log("greska", e);
    if (payload?.handleApiResponseError) {
      yield call(payload?.handleApiResponseError);
    }
    yield put(fetchLicensesError());
  }
}

function* fetchCreateLicense({ payload }) {
  try {
    const data = yield call(attemptCreateLicense, {
      companyId: payload?.companyId,
      data: {
        companyId: payload?.companyId,
        startDate: formatDate(
          convertDateToISO(new Date(payload?.startDate)),
          BEdateFormat
        ),
        expirationDate: formatDate(
          convertDateToISO(new Date(payload?.expirationDate)),
          BEdateFormat
        ),
        licenseStatusId: licensesConstants.ACTIVE.id,
      },
    });
    if (payload?.handleApiResponseSuccess) {
      yield call(payload?.handleApiResponseSuccess, data?.data?.id);
    }
    yield put(fetchCreateLicenseSuccess());
  } catch (e) {
    console.log("greska", e);
    if (e.response && e.response.data) {
      const errorMessage = e?.response?.data?.message;
      if (payload?.handleApiResponseError) {
        yield call(payload?.handleApiResponseError, errorMessage);
      }
      yield put(fetchCreateLicenseError(errorMessage));
    } else {
      if (payload?.handleApiResponseError) {
        yield call(payload?.handleApiResponseError);
      }
      yield put(fetchCreateLicenseError());
    }
  }
}

function* fetchSingleLicense({ payload }) {
  try {
    const { data } = yield call(attemptFetchSingleLicense, {
      licenseId: payload?.licenseId,
    });
    yield put(setSingleLicense(data));
    if (payload?.handleApiResponseSuccess) {
      yield call(payload?.handleApiResponseSuccess);
    }
    yield put(fetchSingleLicenseSuccess());
  } catch (e) {
    console.log("greska", e);
    if (e.response && e.response.data) {
      const errorMessage = e?.response?.data?.message;
      if (payload?.handleApiResponseError) {
        yield call(payload?.handleApiResponseError, errorMessage);
      }
      yield put(fetchSingleLicenseError(errorMessage));
    } else {
      if (payload?.handleApiResponseError) {
        yield call(payload?.handleApiResponseError);
      }
      yield put(fetchSingleLicenseError());
    }
  }
}

function* fetchEditSingleLicense({ payload }) {
  try {
    yield call(attemptEditSingleLicense, {
      licenseId: payload?.licenseId,
      data: {
        ...payload?.licenseData,
        id: payload?.licenseId,
      },
    });
    if (payload?.handleApiResponseSuccess) {
      yield call(payload?.handleApiResponseSuccess);
    }
    yield put(fetchEditLicenseSuccess());
  } catch (e) {
    console.log("greska", e);
    if (e.response && e.response.data) {
      const errorMessage = e?.response?.data?.message;
      if (payload?.handleApiResponseError) {
        yield call(payload?.handleApiResponseError, errorMessage);
      }
      yield put(fetchEditLicenseError(errorMessage));
    } else {
      if (payload?.handleApiResponseError) {
        yield call(payload?.handleApiResponseError);
      }
      yield put(fetchEditLicenseError());
    }
  }
}

function* fetchDeleteLicense({ payload }) {
  try {
    yield call(attemptDeleteLicense, {
      licenseId: payload?.licenseId,
    });
    if (payload?.handleApiResponseSuccess) {
      yield call(payload?.handleApiResponseSuccess, { isDeleted: true });
    }
    yield put(fetchDeleteLicenseSuccess());
  } catch (e) {
    console.log("greska", e);
    let errorMessage = e?.response?.data?.message;

    if (payload?.handleApiResponseError) {
      yield call(payload?.handleApiResponseError, errorMessage);
    }
    yield put(fetchDeleteLicenseError(errorMessage));
  }
}

function* fetchDeactivateLicense({ payload }) {
  try {
    yield call(attemptDeactivateLicense, {
      licenseId: payload?.licenseId,
      companyId: payload?.companyId,
    });
    if (payload?.handleApiResponseSuccess) {
      yield call(payload?.handleApiResponseSuccess, { isDeactivated: true });
    }
    yield put(fetchDeactivateLicenseSuccess());
  } catch (e) {
    console.log("greska", e);
    let errorMessage = e?.response?.data?.message;

    if (payload?.handleApiResponseError) {
      yield call(payload?.handleApiResponseError, errorMessage);
    }
    yield put(fetchDeactivateLicenseError(errorMessage));
  }
}

function* fetchActivateLicense({ payload }) {
  try {
    yield call(attemptActivateLicense, {
      licenseId: payload?.licenseId,
      companyId: payload?.companyId,
    });
    if (payload?.handleApiResponseSuccess) {
      yield call(payload?.handleApiResponseSuccess, { isActivated: true });
    }
    yield put(fetchActivateLicenseSuccess());
  } catch (e) {
    console.log("greska", e);
    let errorMessage = e?.response?.data?.message;

    if (payload?.handleApiResponseError) {
      yield call(payload?.handleApiResponseError, errorMessage);
    }
    yield put(fetchActivateLicenseError(errorMessage));
  }
}

export default function* licensesSaga() {
  yield all([
    takeLatest(GET_LICENSES_FETCH, fetchLicenses),
    takeLatest(CREATE_LICENSE_FETCH, fetchCreateLicense),
    takeLatest(EDIT_LICENSE_FETCH, fetchEditSingleLicense),
    takeLatest(SINGLE_LICENSE_FETCH, fetchSingleLicense),
    takeLatest(DELETE_LICENSE_FETCH, fetchDeleteLicense),
    takeLatest(DEACTIVATE_LICENSE_FETCH, fetchDeactivateLicense),
    takeLatest(ACTIVATE_LICENSE_FETCH, fetchActivateLicense),
  ]);
}
