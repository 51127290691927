import { Box, Typography } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../../themes";
import LastChanges from "../../../LastChanges/LastChanges";

export const FindingLastChangesContainer = styled(Box)`
  display: flex;
  @media (max-width: 600px) {
    flex-direction: column;
  }
`;
export const FindingSingleStateChangeStatusContainer = styled(Box)`
  width: 98px;
  min-width: 98px;
  max-width: 98px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LastDetailsChanges = styled(LastChanges)`
  padding-left: 0;
  border-left: 0;
  padding-top: 6px;
  width: 250px;
  max-width: 250px;
  min-width: 250px;
  height: 273px;
  position: static;
  border-right: 1px solid ${selectedTheme.colors.tabsSeparator};
  @media (max-width: 600px) {
    padding-bottom: 24px;
    padding-left: 32px;
    height: initial;
    border: 0;
    border-bottom: 1px solid ${selectedTheme.colors.tabsSeparator};
    border-right-width: 0;
    width: 100%;
    max-width: 100%;
  }
`;

export const FindingLastStateChangesContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 25px;
  margin-left: 45px;
  @media (max-width: 600px) {
    margin-left: 32px;
    margin-top: 14px;
  }
`;
export const FindingLastStateChangesTitle = styled(Typography)`
  font-family: ${selectedTheme.fonts.mainText};
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: ${selectedTheme.colors.tableColumnText};
`;

export const FindingLastStateChangesListContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
export const FindingSingleStateChangeContainer = styled(Box)`
  display: flex;
  align-items: center;
`;
export const FindingSingleStateChangeDetailsContainer = styled(Box)`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  padding: 8px 16px;
`;
export const FindingSingleStateChangeAuthor = styled(Typography)`
  font-family: ${selectedTheme.fonts.mainText};
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  font-feature-settings: "salt" on;
  color: ${selectedTheme.colors.labelTextColor};
`;
export const FindingSingleStateChangeDate = styled(Typography)`
  font-family: ${selectedTheme.fonts.mainText};
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  font-feature-settings: "salt" on;
  color: ${selectedTheme.colors.findingDateStateChanged};
`;
