import React from "react";
import PropTypes from "prop-types";
import useAuth from "../../hooks/useAuth";


const WithPermissions = (props) => {
  const { hasRole } = useAuth();

  if (
    props?.roles?.reduce?.((prevValue, currentValue) => {
      if (prevValue === true) return prevValue;
      if (hasRole(currentValue)) return true;
      return false;
    }, false)
  ) {
    return props?.children;
  }

  return <></>;
};

WithPermissions.propTypes = {
  roles: PropTypes.array,
  children: PropTypes.node,
};

export default WithPermissions;
