import React from "react";
import { LoaderContainer, LoaderLabel } from "./CircularLoader.styled";
import CircularProgress, {
  circularProgressClasses,
} from "@mui/material/CircularProgress";
import selectedTheme from "../../../themes";
import i18n from "../../../i18n";
import PropTypes from "prop-types";

const CircularLoader = (props) => {
  return (
    <LoaderContainer $isStatic={props?.isStatic} className={props?.className}>
      <CircularProgress
        sx={{
          color: `${selectedTheme.colors.loader}`,
          animationDuration: "1000ms",
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: "round",
          },
        }}
      ></CircularProgress>
      <LoaderLabel>{i18n.t("common.loading")}</LoaderLabel>
    </LoaderContainer>
  );
};

CircularLoader.propTypes = {
  isStatic: PropTypes.bool,
  className: PropTypes.string,
};

export default CircularLoader;
