import PropTypes from "prop-types";
import React, { useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { findLanguageByCulture } from "../../../constants/languageConstants";
import { selectCurrentLanguage } from "../../../store/selectors/translationsSelectors";
import LanguagePopover from "../../Popovers/LanguagePopover/LanguagePopover";
import {
  LangaugeLeftDropdownContainer,
  LanguageDropdownContainer,
  LanguageDropdownIcon,
  LanguageDropdownTitle,
  LanguagePopoverComponent,
} from "./LanguageDropdown.styled";

const LanguageDropdown = (props) => {
  const [isOpenedLanguagePopover, setIsOpenedLangaugePopover] = useState(false);
  const languageRef = useRef(null);
  const currentLanguage = useSelector(selectCurrentLanguage);

  const languageObject = useMemo(
    () => findLanguageByCulture(currentLanguage),
    [currentLanguage]
  );

  const handleChangeLanguage = () => {
    setIsOpenedLangaugePopover(true);
  };

  const handleCloseLanguagePopover = () => setIsOpenedLangaugePopover(false);
  return (
    <>
      <LanguageDropdownContainer
        ref={languageRef}
        onClick={handleChangeLanguage}
        {...props}
      >
        <LangaugeLeftDropdownContainer>
          {languageObject?.icon}
          <LanguageDropdownTitle>{languageObject?.text}</LanguageDropdownTitle>
        </LangaugeLeftDropdownContainer>
        <LanguageDropdownIcon />
      </LanguageDropdownContainer>
      <LanguagePopoverComponent
        anchorEl={languageRef?.current}
        open={isOpenedLanguagePopover}
        onClose={handleCloseLanguagePopover}
        content={<LanguagePopover closePopover={handleCloseLanguagePopover} />}
      />
    </>
  );
};

LanguageDropdown.propTypes = {
  children: PropTypes.node,
};

export default LanguageDropdown;
