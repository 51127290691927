import {
  CLEAR_LICENSES,
  CREATE_LICENSE_ERROR,
  CREATE_LICENSE_FETCH,
  CREATE_LICENSE_SUCCESS,
  DELETE_LICENSE_ERROR,
  DELETE_LICENSE_FETCH,
  DELETE_LICENSE_SUCCESS,
  DEACTIVATE_LICENSE_ERROR,
  DEACTIVATE_LICENSE_FETCH,
  DEACTIVATE_LICENSE_SUCCESS,
  ACTIVATE_LICENSE_ERROR,
  ACTIVATE_LICENSE_FETCH,
  ACTIVATE_LICENSE_SUCCESS,
  EDIT_LICENSE_ERROR,
  EDIT_LICENSE_FETCH,
  EDIT_LICENSE_SUCCESS,
  GET_LICENSES_CLEAR,
  GET_LICENSES_ERROR,
  GET_LICENSES_FETCH,
  GET_LICENSES_SUCCESS,
  SET_LICENSE,
  SET_LICENSES,
  SET_LICENSE_ERROR,
  SET_TOTAL_LICENSES,
  SINGLE_LICENSE_ERROR,
  SINGLE_LICENSE_FETCH,
  SINGLE_LICENSE_SET,
  SINGLE_LICENSE_SUCCESS,
} from "./licensesActionConstants";

export const setLicense = (payload) => ({
  type: SET_LICENSE,
  payload,
});

export const setLicenseError = (payload) => ({
  type: SET_LICENSE_ERROR,
  payload,
});

export const fetchLicenses = (payload) => ({
  type: GET_LICENSES_FETCH,
  payload,
});
export const fetchLicensesSuccess = (payload) => ({
  type: GET_LICENSES_SUCCESS,
  payload,
});
export const fetchLicensesError = (payload) => ({
  type: GET_LICENSES_ERROR,
  payload,
});

export const fetchLicenseClear = () => ({
  type: GET_LICENSES_CLEAR,
});

export const fetchCreateLicense = (payload) => ({
  type: CREATE_LICENSE_FETCH,
  payload,
});
export const fetchCreateLicenseSuccess = (payload) => ({
  type: CREATE_LICENSE_SUCCESS,
  payload,
});
export const fetchCreateLicenseError = (payload) => ({
  type: CREATE_LICENSE_ERROR,
  payload,
});

export const fetchEditLicense = (payload) => ({
  type: EDIT_LICENSE_FETCH,
  payload,
});
export const fetchEditLicenseSuccess = (payload) => ({
  type: EDIT_LICENSE_SUCCESS,
  payload,
});
export const fetchEditLicenseError = (payload) => ({
  type: EDIT_LICENSE_ERROR,
  payload,
});

export const fetchDeleteLicense = (payload) => ({
  type: DELETE_LICENSE_FETCH,
  payload,
});
export const fetchDeleteLicenseSuccess = (payload) => ({
  type: DELETE_LICENSE_SUCCESS,
  payload,
});
export const fetchDeleteLicenseError = (payload) => ({
  type: DELETE_LICENSE_ERROR,
  payload,
});

export const fetchDeactivateLicense = (payload) => ({
  type: DEACTIVATE_LICENSE_FETCH,
  payload,
});
export const fetchDeactivateLicenseSuccess = (payload) => ({
  type: DEACTIVATE_LICENSE_SUCCESS,
  payload,
});
export const fetchDeactivateLicenseError = (payload) => ({
  type: DEACTIVATE_LICENSE_ERROR,
  payload,
});

export const fetchActivateLicense = (payload) => ({
  type: ACTIVATE_LICENSE_FETCH,
  payload,
});
export const fetchActivateLicenseSuccess = (payload) => ({
  type: ACTIVATE_LICENSE_SUCCESS,
  payload,
});
export const fetchActivateLicenseError = (payload) => ({
  type: ACTIVATE_LICENSE_ERROR,
  payload,
});

export const fetchSingleLicense = (payload) => ({
  type: SINGLE_LICENSE_FETCH,
  payload,
});
export const fetchSingleLicenseSuccess = (payload) => ({
  type: SINGLE_LICENSE_SUCCESS,
  payload,
});
export const fetchSingleLicenseError = (payload) => ({
  type: SINGLE_LICENSE_ERROR,
  payload,
});

export const setSingleLicense = (payload) => ({
  type: SINGLE_LICENSE_SET,
  payload,
});
export const setLicenses = (payload) => ({
  type: SET_LICENSES,
  payload,
});
export const setTotalLicenses = (payload) => ({
  type: SET_TOTAL_LICENSES,
  payload,
});
export const clearLicenses = () => ({
  type: CLEAR_LICENSES,
});
