import React from 'react'
// import PropTypes from 'prop-types'
import {ReactComponent as SearchIconSvg} from "../../../assets/images/svg/search.svg"

const SearchIcon = props => {
  return (
    <SearchIconSvg {...props} />
  )
}

SearchIcon.propTypes = {}

export default SearchIcon