import React from "react";
import PropTypes from "prop-types";
import {
  LanguagePopoverContainer,
  LanguagePopoverTickIcon,
  SingleLanguageItemContainer,
  SingleLanguageItemLeftContainer,
  SingleLanguageItemText,
} from "./LanguagePopover.styled";
import { LANGUAGES } from "../../../constants/languageConstants";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentLanguage } from "../../../store/selectors/translationsSelectors";
import { fetchTranslationsResource } from "../../../store/actions/translations/translationsActions";

const LanguagePopover = (props) => {
  const currentLanguage = useSelector(selectCurrentLanguage);
  const dispatch = useDispatch();

  const handleChangeLanguage = (culture) => {
    dispatch(fetchTranslationsResource({ culture }));
    props?.closePopover?.();
  };

  return (
    <LanguagePopoverContainer>
      {LANGUAGES.map?.((singleLanguage, index) => (
        <SingleLanguageItemContainer
          onClick={() => handleChangeLanguage(singleLanguage?.culture)}
          key={index}
          $chosen={currentLanguage === singleLanguage?.culture}
        >
          <SingleLanguageItemLeftContainer>
            {singleLanguage?.icon}
            <SingleLanguageItemText>
              {singleLanguage?.text}
            </SingleLanguageItemText>
          </SingleLanguageItemLeftContainer>
          {currentLanguage === singleLanguage?.culture && (
            <LanguagePopoverTickIcon />
          )}
        </SingleLanguageItemContainer>
      ))}
    </LanguagePopoverContainer>
  );
};

LanguagePopover.propTypes = {
  children: PropTypes.node,
  closePopover: PropTypes.func,
};

export default LanguagePopover;
