import React, { forwardRef, useImperativeHandle, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  ChapterContainer,
  ChapterTitle,
  TextInput,
} from "../../SetScanningObjectModal.styled";
import {
  AddAnswerIcon,
  PossibleAnswerContainer,
  RemoveAnswerIcon,
} from "./SelectTypeInputs.styled";
import PropTypes from "prop-types";
import AutocompleteField from "../../../../Field/AutocompleteField/AutocompleteField";
import OrientationPicker from "../TextTypeInputs/OrientationPicker/OrientationPicker";
import { ORIENTATION } from "../../../../../constants/orientationConstants";

const SelectTypeInputs = forwardRef((props, ref) => {
  const { t } = useTranslation();
  const [orientation, setOrientation] = useState(props?.orientation);
  const [possibleAnswers, setPossibleAnswers] = useState(
    props?.possibleAnswers.length !== 0 && props?.possibleAnswers[0] !== ""
      ? props?.possibleAnswers
      : [{ answer: "", questionId: 1 }]
  );
  const [defaultAnswerIndex, setDefaultAnswerIndex] = useState(
    props?.defaultAnswerIndex >= 0 ? props?.defaultAnswerIndex : -1
  ); //eslint-disable-line

  useImperativeHandle(ref, () => ({
    possibleAnswers,
    setPossibleAnswers,
    defaultAnswerIndex,
    setDefaultAnswerIndex,
    orientation,
    setOrientation,
  }));

  const handleChangeAnswer = (newAnswer, id) => {
    setPossibleAnswers((prevPossibleAnswers) => {
      let newPossibleAnswers = [...prevPossibleAnswers];
      newPossibleAnswers?.splice(
        newPossibleAnswers?.findIndex(
          (singleAnswer) => singleAnswer?.questionId === id
        ),
        1,
        { answer: newAnswer, questionId: id }
      );
      return [...newPossibleAnswers];
    });
  };

  const handleRemoveAnswer = (index) => {
    setPossibleAnswers((prevPossibleAnswers) => {
      let newPossibleAnswers = [...prevPossibleAnswers];
      newPossibleAnswers.splice(index, 1);
      if (newPossibleAnswers.length === 0)
        newPossibleAnswers = [{ answer: "" }];
      return [...newPossibleAnswers];
    });
    setDefaultAnswerIndex((prevState) => {
      if (index === prevState) return -1;
      if (index < prevState) return prevState - 1;
      return prevState;
    });
  };

  const handleAddAnswer = () => {
    var maxIndex =
      Math.max(...possibleAnswers?.map?.((o) => o.questionId)) || 0;
    setPossibleAnswers((prevPossibleAnswers) => [
      ...prevPossibleAnswers,
      { answer: "", questionId: maxIndex + 1 },
    ]);
  };

  const handleChangeDefaultAnswer = (id) => {
    setDefaultAnswerIndex(
      possibleAnswers?.findIndex(
        (singleAnswer) => singleAnswer?.questionId === id
      )
    );
  };

  return (
    <>
      <ChapterContainer>
        {possibleAnswers
          ?.sort(function (a, b) {
            return a?.id - b?.id;
          })
          .map((singlePossibleAnswer, index) => (
            <PossibleAnswerContainer key={index}>
              <TextInput
                placeholder={t("questions.countedAnswer", { count: index + 1 })}
                value={possibleAnswers[index].answer}
                inputProps={{
                  style: {
                    flex: 1,
                  },
                }}
                onChange={(event) =>
                  handleChangeAnswer(
                    event.target.value,
                    singlePossibleAnswer?.questionId || 0
                  )
                }
              />
              <RemoveAnswerIcon onClick={() => handleRemoveAnswer(index)} />
            </PossibleAnswerContainer>
          ))}
        <AddAnswerIcon onClick={handleAddAnswer} />
      </ChapterContainer>
      <ChapterContainer>
        <ChapterTitle>{t("questions.defaultAnswer")}</ChapterTitle>
        <AutocompleteField
          removeMaxWidth
          options={possibleAnswers?.filter(
            (singlePossibleAnswer) => singlePossibleAnswer?.answer?.length !== 0
          )}
          label={t("questions.chooseDefaultAnswer")}
          onChange={(value) => {
            handleChangeDefaultAnswer(value?.questionId);
          }}
          dropdownLabel={["answer"]}
          value={possibleAnswers?.[defaultAnswerIndex] || { answer: "" }}
        />
      </ChapterContainer>
      <OrientationPicker
        orientation={orientation}
        setOrientation={setOrientation}
      />
    </>
  );
});

SelectTypeInputs.displayName = "SelectTypeInputs";

SelectTypeInputs.propTypes = {
  defaultAnswerIndex: PropTypes.number,
  possibleAnswers: PropTypes.array,
  orientation: PropTypes.string,
};
SelectTypeInputs.defaultProps = {
  possibleAnswers: [{ answer: "", id: 0 }],
  orientation: ORIENTATION.VERTICAL,
};

export default SelectTypeInputs;
