import React from "react";
import { ReactComponent as LoginIlustrationSvg } from "../../../assets/images/svg/login-ilustration.svg";

const LoginIlustration = (props) => {
  return <LoginIlustrationSvg {...props} />;
};

LoginIlustration.propTypes = {};

export default LoginIlustration;
