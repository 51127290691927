import React from "react";
// import PropTypes from 'prop-types'
import { ReactComponent as OnlineIconSvg } from "../../../assets/images/svg/online.svg";

const OnlineIcon = (props) => {
  return <OnlineIconSvg {...props} />;
};

OnlineIcon.propTypes = {};

export default OnlineIcon;
