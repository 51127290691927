import { Box } from "@mui/material";
import styled from "styled-components";

export const CustomDragLayerContainer = styled(Box)`
  position: fixed;
  pointer-events: none;
  z-index: 100;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
`;

export const CustomDragLayerItemContainer = styled(Box)``;
