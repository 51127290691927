import { Box } from "@mui/material";
import styled from "styled-components";
// import Icon from "../../../../Icon/Icon";

export const IsDefaultRendererContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  padding-left: 32px;
`;
// export const IsDefaultRendererEditStandardIconContainer = styled(Icon)`
//   cursor: pointer;
// `;
