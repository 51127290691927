import React from "react";
// import PropTypes from 'prop-types'
import { ReactComponent as UnderlineIconSvg } from "../../../assets/images/lexical/type-underline.svg";

const UnderlineIcon = (props) => {
  return <UnderlineIconSvg {...props} />;
};

UnderlineIcon.propTypes = {};

export default UnderlineIcon;
