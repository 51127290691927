import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  ErrorMessage,
  TextareaInputContainer,
  TextareaInputField,
  TextareaInputTitle,
} from "./TextareaInput.styled";
import selectedTheme from "../../../themes";
import { ContentState, EditorState, convertToRaw } from "draft-js";
import htmlToDraft from "html-to-draftjs";
import {
  createHtmlString,
  formatHtml,
} from "../../../util/helpers/htmlHelpers";
import draftToHtml from "draftjs-to-html";
import LexicalEditor from "../../LexicalEditor/LexicalEditor";
import { useTranslation } from "react-i18next";

const TextareaInput = (props) => {
  let propsObject = { ...props };
  const { t } = useTranslation();
  if (props?.requiredField) delete propsObject.requiredField;
  if (props?.disabledField) delete propsObject.disabledField;
  const [editorState, setEditorState] = useState(() =>
    EditorState.createWithContent(
      ContentState.createFromBlockArray(
        htmlToDraft(createHtmlString(props?.value))
      )
    )
  );

  useEffect(() => {
    if (
      draftToHtml(convertToRaw(editorState?.getCurrentContent?.())) !==
      props?.value
    ) {
      setEditorState(
        EditorState.createWithContent(
          ContentState.createFromBlockArray(
            htmlToDraft(createHtmlString(props?.value))
          )
        )
      );
    }
    if (props?.value === null) {
      setEditorState(EditorState.createEmpty());
    }
  }, [props?.value]);

  const handleChangeRichText = (newValue) => {
    props?.onBlur(formatHtml(newValue));
  };

  return (
    <TextareaInputContainer className={props?.className}>
      <TextareaInputTitle
        $required={
          (props?.required || props?.requiredField) &&
          props?.value?.length === 0
        }
      >
        {props?.title || props?.label}
      </TextareaInputTitle>
      {props?.isRichText ? (
        <LexicalEditor
          readOnly={props?.disabled || props?.disabledField}
          value={props?.value}
          key={props?.value}
          onChange={handleChangeRichText}
          onClickFnc={props?.onClickFnc}
        />
      ) : (
        <TextareaInputField
          sx={{
            "& .MuiInputBase-input.Mui-disabled": {
              WebkitTextFillColor: selectedTheme.colors.disabledFieldText,
            },
            ...props?.sx,
          }}
          disabled={props?.disabled || props?.disabledField}
          width="100%"
          $isErrorShown={props?.error}
          id={props?.name}
          minRows={props?.minRows}
          onChange={props?.onChange}
          onBlur={props?.onBlur}
          value={props?.value}
          placeholder={props?.placeholder}
          {...propsObject}
        />
      )}

      <ErrorMessage>{t(props?.error)} </ErrorMessage>
    </TextareaInputContainer>
  );
};

TextareaInput.propTypes = {
  name: PropTypes.string,
  minRows: PropTypes.number,
  required: PropTypes.bool,
  requiredField: PropTypes.bool,
  disabledField: PropTypes.bool,
  disabled: PropTypes.bool,
  isRichText: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  value: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  title: PropTypes.string,
  error: PropTypes.string,
  className: PropTypes.string,
  sx: PropTypes.object,
  onClickFnc: PropTypes.func,
};

TextareaInput.defaultProps = {
  minRows: 3,
  value: "",
  onBlur: () => {},
};

export default TextareaInput;
