import styled, { css } from "styled-components";
import { Box, Button, Typography } from "@mui/material";
import Title from "../../Typography/Title/Title";
//import PrimaryButton from "../../Buttons/PrimaryButton/PrimaryButton";
import selectedTheme from "../../../themes";
import PlusIcon from "../../Icon/Icons/PlusIcon";
import { TextInputFieldContainer } from "../../Field/TextInputField/TextInputField.styled";

export const NewClientFormContainer = styled.form`
  /* margin-left: 8vw; */
  min-height: calc(100vh - 66px);
  display: flex;
  flex-direction: column;
  padding-bottom: 72px;
  @media (max-width: 600px) {
    padding-bottom: 32px;
    & ${TextInputFieldContainer} div {
      width: 100%;
    }
  }
`;

export const PageTitle = styled(Title)`
  margin-bottom: 16px;
`;

export const NewClientInputsContainer = styled(Box)`
  display: flex;
  gap: 64px;
  flex: 1;
  @media (max-width: 600px) {
    flex-direction: column;
    gap: 0;
    & input {
      width: 100%;
    }
  }
`;

export const NewClientLeftColumn = styled(Box)``;

export const NewClientRightColumn = styled(Box)``;

export const ButtonLabel = styled(Typography)`
  font-family: ${selectedTheme.fonts.mainText};
  color: ${selectedTheme.colors.labelTextColor};
  font-size: 14px;
  font-weight: 600;
  margin-top: 32px;
  margin-bottom: 8px;
  ${(props) =>
    props?.$required &&
    css`
      &::after {
        content: " *" !important;
        color: ${selectedTheme.colors.errorColor} !important;
      }
    `}
`;

export const RequiredFieldsText = styled(Typography)`
  font-family: ${selectedTheme.fonts.mainText};
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-top: 16px;
  @media (max-width: 600px) {
    text-align: center;
  }
`;

export const NewEmailButton = styled(Button)`
  background-color: ${selectedTheme.colors.white};
  color: ${selectedTheme.colors.labelTextColor};
  text-transform: none;
  padding: 8px 16px;
  border-radius: 6px;
  font-family: ${selectedTheme.fonts.mainText};
  font-size: 14px;
  margin-left: 185px;
  font-weight: 600;
  line-height: 20px;
  white-space: nowrap;
  & svg {
    width: 22px;
    height: 22px;
    margin-right: 5px;
  }
`;

export const ButtonsAreaContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* margin-top: 50px; */
  @media (max-width: 600px) {
    margin-top: 16px;
  }
`;

export const ButtonsContainer = styled(Box)`
  display: flex;
  gap: 15px;
  margin-left: -50px;
  @media (max-width: 600px) {
    margin-left: 0;
  }
`;

export const ErrorMessage = styled(Typography)`
  font-family: ${selectedTheme.fonts.mainText};
  font-size: 12px;
  font-weight: 400;
  color: ${selectedTheme.colors.errorColor};
`;
export const DistributionMailsContainer = styled(Box)`
  display: flex;
  flex-direction: column;
`;
export const SingleDistributionMailContainer = styled(Box)`
  &:has(p) {
    margin-bottom: 12px;
  }
`;

export const Plus = styled(PlusIcon)`
  path {
    stroke: ${selectedTheme.colors.labelTextColor};
  }
`;

export const TitleButtonsContainer = styled(Box)`
  display: flex;
  align-items: start;
  /* margin-bottom: 62px; */
`;
