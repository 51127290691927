import React, { useEffect, useState, useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import FindingTabChooser from "./FindingTabChooser/FindingTabChooser";
import {
  FindingTabContent,
  NewFindingContentContainer,
} from "./NewFindingContent.styled";
import FindingSettings from "./FindingSettings/FindingSettings";
import FindingScanning from "./FindingScanning/FindingScanning";
import CircularLoader from "../../Loader/CircularLoader/CircularLoader";
import { useDispatch, useSelector } from "react-redux";
import {
  // fetchChangeStateFinding,
  fetchCreateFinding,
  fetchPatchFinding,
  fetchPrintFinding,
  // fetchSingleFinding,
  setFindingsChanged,
  // fillFindingQuestions,
} from "../../../store/actions/findings/findingsActions";
import { useRef } from "react";
import FindingStatusCardsChain from "./FindingSettings/FindingStatusCardsChain/FindingStatusCardsChain";
import { statusTableConstants } from "../../../constants/findingStatusConstants";
import FindingLastChanges from "./FindingLastChanges/FindingLastChanges";
import {
  closeLoadingModal,
  closeModal,
  toggleConfirmModal,
  toggleCopyFindingModal,
  toggleFindingPreviewModal,
  toggleSendMailsModal,
  toggleSetLoadingModal,
} from "../../../store/actions/modal/modalActions";
import useIsMobile from "../../../hooks/useIsMobile";
import {
  makeErrorToastMessage,
  makeToastMessage,
} from "../../../store/utils/makeToastMessage";
import { Trans, useTranslation } from "react-i18next";
import { APPLICATION_TOAST_CONTAINER_ID } from "../../../constants/toastConstants";
import { useIsLoading } from "../../../hooks/useIsLoading";
import {
  selectFindingSavingDetails,
  selectIsFindingSettingsChanged,
  selectSingleFindingOld,
} from "../../../store/selectors/findingsSelectors";
import {
  CloseModalInnerDescriptionContainer,
  CloseModalInnerDescriptionNumber,
  CloseModalInnerDescriptionText,
} from "./FindingSettings/FindingStatusCardsChain/FindingStatusCardsChain.styled";
import { replaceInRoute } from "../../../util/helpers/routeHelpers";
import { FINDINGS_EDIT_PAGE } from "../../../constants/pages";
import history from "../../../store/utils/history";
import { useLocation } from "react-router-dom";
import FindingSchema from "./FindingSchema/FindingSchema";
import PageTitle from "../../PageTitle/PageTitle";
import ActionButtons from "../../PageTitle/ActionButtons/ActionButtons";

const NewFindingContent = (props) => {
  useIsLoading();
  const { t } = useTranslation();
  const location = useLocation();
  const [selectedTab, setSelectedTab] = useState({ id: 0 });
  const [isLoaded, setIsLoaded] = useState(false);
  const settingsRef = useRef(null);
  const dispatch = useDispatch();
  const [stateId, setStateId] = useState(statusTableConstants.inProgress?.id);
  const { isMobile } = useIsMobile();
  const findingDetails = useSelector(selectFindingSavingDetails);
  const singleFindingOld = useSelector(selectSingleFindingOld);
  const isFindingSettingsChanged = useSelector(selectIsFindingSettingsChanged);

  const isSavingEnabled = useMemo(() => {
    if (isFindingSettingsChanged || findingDetails?.isSavingAllowed) {
      return true;
    }
    return false;
  }, [findingDetails, isFindingSettingsChanged]);

  useEffect(() => {
    if (
      props?.isEditing &&
      props?.prefilledData?.findingStateId &&
      Object.getOwnPropertyNames(props?.prefilledData)?.length > 4 &&
      !isLoaded
    ) {
      setIsLoaded(true);
      // debugger; //eslint-disable-line
      setStateId(props?.prefilledData?.findingStateId);
    }
  }, [
    props?.isEditing,
    props?.prefilledData,
    props?.prefilledData?.findingStateId,
  ]);

  const handleSubmitFormik = useCallback(() => {
    setTimeout(() => {
      if (settingsRef?.current) {
        settingsRef?.current
          ?.allData?.()
          ?.formik?.()
          ?.submitForm?.()
          .then(() => {
            let arrayOfErrors = Object.keys(
              settingsRef?.current?.allData?.()?.formik?.()?.errors
            )?.map(
              (singleProperty) =>
                settingsRef?.current?.allData?.()?.formik?.()?.errors?.[
                  singleProperty
                ]
            );
            // debugger; //eslint-disable-line
            if (selectedTab?.id !== 0 && arrayOfErrors?.length !== 0) {
              makeErrorToastMessage(
                {
                  title: t("apiErrors.error"),
                  description:
                    arrayOfErrors?.length !== 0
                      ? arrayOfErrors
                          ?.map?.((singleError) => t(singleError?.value))
                          ?.join?.("\r\n")
                      : "",
                },
                {
                  containerId: APPLICATION_TOAST_CONTAINER_ID,
                  pre: true,
                }
              );
            }
          });
      } else {
        return () => {};
      }
    }, 0);
  }, [selectedTab]);

  const handleApiResponseSuccess = (payload) => {
    location.state = {};

    setStateId(payload?.stateId);
    const titleI18Key = props?.isEditing
      ? "toast.success.editedTitle"
      : "toast.success.createdTitle";
    const descriptionI18Key = props?.isEditing
      ? "toast.success.editedDescription"
      : "toast.success.createdDescription";
    makeToastMessage(
      {
        title: t(titleI18Key, {
          typeOfData: t("findings.typeOfData"),
        }),
        description: t(descriptionI18Key, {
          name: props?.prefilledData?.findingNumber || payload?.findingNumber,
          typeOfData: t("findings.typeOfData").toLowerCase(),
        }),
      },
      {
        containerId: APPLICATION_TOAST_CONTAINER_ID,
      }
    );
    if (props?.isEditing) return;
    history.replace(
      replaceInRoute(FINDINGS_EDIT_PAGE, {
        findingId: payload?.id,
      })
    );
  };

  const handleSubmit = (
    copyFinding = false,
    successCallback = handleApiResponseSuccess,
    supressModal = false
  ) => {
    let newStatusId = stateId;
    if (
      newStatusId === statusTableConstants?.cancelled?.id &&
      singleFindingOld?.findingStateId < statusTableConstants?.cancelled?.id &&
      !supressModal
    ) {
      dispatch(
        toggleConfirmModal({
          onConfirm: () => dispatch(closeModal()),
          onCancel: () => handleSubmit(copyFinding, successCallback, true),
          title: t("findings.cancelFindingModal.title"),
          description: (
            <Trans
              i18nKey="findings.cancelFindingModal.description"
              components={[<br key={0} />]}
            />
          ),
          cancelButtonText: t(
            "common.confirm"
          ).toUpperCase(),
          confirmButtonText: t("common.cancel"),
        })
      );
    } else if (
      newStatusId === statusTableConstants?.closed?.id &&
      singleFindingOld?.findingStateId < statusTableConstants?.closed?.id &&
      !supressModal
    ) {
      dispatch(
        toggleConfirmModal({
          onConfirm: () => dispatch(closeModal()),
          onCancel: () => handleSubmit(copyFinding, successCallback, true),
          cancelButtonVariant: 2,
          title: t("findings.closeFinding"),
          description: (
            <CloseModalInnerDescriptionContainer>
              <CloseModalInnerDescriptionText>
                <Trans
                  i18nKey={"findings.closeFindingFirstDesc"}
                  values={{ findingNumber: singleFindingOld?.findingNumber }}
                  components={[<CloseModalInnerDescriptionNumber key={1} />]}
                />
              </CloseModalInnerDescriptionText>
            </CloseModalInnerDescriptionContainer>
          ),
          cancelButtonText: t(
            "common.confirm"
          ).toUpperCase(),
          confirmButtonText: t("common.cancel"),
          noPadding: true,
        })
      );
    } else {
      dispatch(closeModal());
      if (props?.isEditing && !copyFinding) {
        dispatch(
          fetchPatchFinding({
            // data: settingsRef?.current?.allData?.(),
            stateId,
            handleApiResponseSuccess: successCallback,
          })
        );
      } else {
        dispatch(
          fetchCreateFinding({
            data: settingsRef?.current?.allData?.(),
            findingTypeId: location?.state?.findingType.id,
            stateId,
            handleApiResponseSuccess: successCallback,
          })
        );
      }
    }
  };

  const handleChangeTab = (newTab) => {
    setSelectedTab(newTab);
  };

  const handleResponseSuccessCopy = () => {
    setStateId(statusTableConstants?.inProgress?.id);
  };

  const handleCopyFinding = () => {
    if (!isFindingSettingsChanged)
      dispatch(
        toggleCopyFindingModal({
          rank: 1,
          date: props?.prefilledData?.date,
          id: props?.prefilledData?.id,
          setSelectedTab: setSelectedTab,
          handleResponseSuccess: handleResponseSuccessCopy,
        })
      );
  };

  const handleApiResponseErrorExport = () => {
    dispatch(closeLoadingModal());
  };

  const handleApiResponseSuccessExport = () => {
    dispatch(closeLoadingModal());
  };

  const handleExportFinding = () => {
    if (
      stateId !== statusTableConstants?.cancelled?.id &&
      !isFindingSettingsChanged
    ) {
      dispatch(toggleSetLoadingModal({ rank: 1 }));
      dispatch(
        fetchPrintFinding({
          findingId: props?.prefilledData?.id,
          findingName: props?.prefilledData?.findingNumber,
          handleApiResponseSuccess: handleApiResponseSuccessExport,
          handleApiResponseError: handleApiResponseErrorExport,
        })
      );
    }
  };

  const handleSendMailFinding = () => {
    if (
      stateId === statusTableConstants?.closed?.id &&
      !isFindingSettingsChanged
    )
      dispatch(
        toggleSendMailsModal({
          rank: 1,
          title: t("findings.sendMailsModal.title"),
          findingId: props?.prefilledData?.id,
        })
      );
  };

  // const handleUndoFinding = () => {
  //   if (stateId === statusTableConstants?.cancelled?.id) {
  //     dispatch(
  //       fetchChangeStateFinding({
  //         findingId: props?.prefilledData?.id,
  //         stateId: statusTableConstants?.inProgress?.id,
  //         handleApiResponseSuccess: () => {
  //           dispatch(
  //             fetchSingleFinding({ findingId: props?.prefilledData?.id })
  //           );
  //           handleApiResponseSuccess();
  //         },
  //       })
  //     );
  //   }
  // };

  const handleChangeState = (newStateId) => {
    setStateId(newStateId);
    if (!isFindingSettingsChanged) dispatch(setFindingsChanged(true));
  };

  const handlePreviewFinding = () => {
    if (
      stateId !== statusTableConstants?.cancelled?.id &&
      !isFindingSettingsChanged
    )
      dispatch(
        toggleFindingPreviewModal({ findingId: props?.prefilledData?.id })
      );
  };

  return (
    <NewFindingContentContainer>
      <PageTitle
        findingType={
          props?.isEditing
            ? props?.prefilledData?.findingType?.name
            : location?.state?.findingType
            ? location?.state?.findingType?.name
            : null
        }
        title={props?.title}
        isEditing={props?.isEditing}
        disableSaveButton={!isSavingEnabled}
        highlightedText={`#${
          props?.prefilledData?.findingNumber ||
          props?.prefilledData?.number ||
          ""
        }`}
        showExport
        disableExport={
          stateId === statusTableConstants?.cancelled?.id || isSavingEnabled
        }
        onExportClick={handleExportFinding}
        showSendMail={stateId === statusTableConstants?.closed?.id}
        onSendMailClick={handleSendMailFinding}
        showCopy
        disableCopy={isSavingEnabled}
        onCopyClick={handleCopyFinding}
        showPreview
        disablePreview={isSavingEnabled}
        previewTooltip="findingPreview.title"
        onPreviewClick={handlePreviewFinding}
        onSubmitClick={handleSubmitFormik}
      />

      {props?.isLoading ? (
        <CircularLoader />
      ) : (
        <>
          <FindingStatusCardsChain
            findingNumber={
              props?.prefilledData?.findingNumber ||
              props?.prefilledData?.number
            }
            isEditing={props?.isEditing}
            currentActiveStatus={stateId}
            handleChangeStatus={handleChangeState}
          />
          <FindingTabChooser
            tabs={[]}
            hideModalButtons
            isEditing={props?.isEditing}
            selectedTab={selectedTab}
            handleChangeTab={handleChangeTab}
          />
          <FindingTabContent>
            <FindingSettings
              ref={settingsRef}
              isEditing={props?.isEditing}
              prefilledData={props?.prefilledData}
              stateId={stateId}
              shouldHide={selectedTab?.id !== 0}
              handleSubmit={handleSubmit}
            />
            {selectedTab?.id === -1 && (
              <FindingLastChanges prefilledData={props?.prefilledData} />
            )}
            {selectedTab?.id === 1 && (
              <FindingScanning
                stateId={stateId}
                settingsFormik={() => settingsRef?.current?.formik?.()}
                isEditing={props?.prefilledData}
                // key={JSON.stringify(tabs)}
                disabled={stateId !== statusTableConstants?.inProgress?.id}
              />
            )}
            {selectedTab?.id === 2 && (
              <FindingSchema
                settingsFormik={() =>
                  settingsRef?.current?.allData?.().formik?.()
                }
                stateId={stateId}
              />
            )}
          </FindingTabContent>
        </>
      )}
      {isMobile && (
        <ActionButtons
          handleClickSubmitButton={handleSubmitFormik}
          disabledSaveButton={!isSavingEnabled}
        />
      )}
    </NewFindingContentContainer>
  );
};

NewFindingContent.propTypes = {
  isEditing: PropTypes.bool,
  isLoading: PropTypes.bool,
  title: PropTypes.string,
  prefilledData: PropTypes.object,
};

NewFindingContent.defaultProps = {
  prefilledData: {},
};

export default NewFindingContent;
