import { Box } from "@mui/material";
import styled, { css } from "styled-components";
import selectedTheme from "../../../../../../themes";

export const ViewRadioContainer = styled(Box)`
  display: flex;
  border-radius: 8px;
  border: 1px solid ${selectedTheme.colors.inputBorderColor};
  align-self: flex-end;
  overflow: hidden;
`;

export const ViewRadioButtonContainer = styled(Box)`
  display: flex;
  justify-content: center;
  cursor: pointer;
  align-items: center;
  width: 44px;
  height: 40px;
  ${(props) =>
    props?.selected
      ? css`
          background-color: ${selectedTheme.colors.deviceColor};
          & path {
            stroke: ${selectedTheme.colors.white};
          }
        `
      : css`
          background-color: ${selectedTheme.colors.white};
          & path {
            stroke: ${selectedTheme.colors.deviceColor};
          }
        `}
`;
