import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import DistributionMailInput from "../../../Clients/NewClient/DistributionMailInput/DistributionMailInput";
import {
  DistributionMailsContainer,
  DistributionMailsLabel,
  ErrorMessage,
  NewEmailButton,
  Plus,
  SingleDistributionMailContainer,
} from "./DistributionEmails.styled";

const DistributionEmails = (props) => {
  const { t } = useTranslation();

  const addInputHandler = () => {
    if (
      props?.formik?.values?.distributionEmails?.every(
        (value) => value?.length !== 0
      )
    ) {
      props?.formik?.setFieldValue?.("distributionEmails", [
        ...props?.formik?.values?.distributionEmails,
        "",
      ]);
    }
  };

  const removeInputHandler = (index) => {
    let prevEmails = props?.formik?.values?.distributionEmails;
    if (prevEmails?.length !== 1) {
      let newEmails = [...prevEmails];
      newEmails.splice(index, 1);
      props?.formik?.setFieldValue?.("distributionEmails", newEmails);
    } else {
      props?.formik?.setFieldValue?.("distributionEmails", [""]);
    }
  };

  return (
    <>
      <DistributionMailsLabel>
        {t("companySettings.distributionEmails")}
      </DistributionMailsLabel>
      <DistributionMailsContainer>
        {props?.formik?.values?.distributionEmails?.map?.((item, index) => {
          return (
            <SingleDistributionMailContainer key={index}>
              <DistributionMailInput
                onChange={props?.formik?.handleChange}
                value={props?.formik?.values?.distributionEmails[index]}
                minusHandler={() => removeInputHandler(index)}
                name={`distributionEmails.${index}`}
              />
              {props?.formik?.errors?.distributionEmails?.[index] &&
                props?.formik?.touched?.distributionEmails?.[index] &&
                Array.isArray(props?.formik?.errors?.distributionEmails) && (
                  <ErrorMessage>
                    {t(props?.formik?.errors?.distributionEmails?.[index])}
                  </ErrorMessage>
                )}
            </SingleDistributionMailContainer>
          );
        })}
      </DistributionMailsContainer>
      <NewEmailButton onClick={addInputHandler}>
        <Plus />
        {t("companySettings.newEmail")}
      </NewEmailButton>
    </>
  );
};

DistributionEmails.propTypes = {
  formik: PropTypes.object,
};

export default DistributionEmails;
