import React from "react";
import PropTypes from "prop-types";
import {
  ActionRendererContainer,
  ActionRendererDeleteStandardIconContainer,
  ActionRendererEditStandardIconContainer,
  FieldText,
} from "./ActionRenderer.styled";
import EditIcon from "../../../../../Icon/Icons/EditIcon";
import DeleteIcon from "../../../../../Icon/Icons/DeleteIcon.js";
import i18n from "../../../../../../i18n";
import Tooltip from "../../../../../Tooltip/Tooltip";
import WithPermissions from "../../../../../WithPermissions/WithPermissions";
import { MANAGER_ROLE } from "../../../../../../constants/rolesConstants";
import {
  closeModal,
  toggleSetAttendedPersonModal,
  toggleSetDeleteModal,
} from "../../../../../../store/actions/modal/modalActions";
import { Trans } from "react-i18next";

const ActionRenderer = (props) => {
  const handleEditStaff = () => {
    props?.dispatch(
      toggleSetAttendedPersonModal({
        title: i18n.t("findings.findingAttendees.editPerson"),
        rank: 1,
        prefilledData: props?.prefilledData,
        isEditing: true,
      })
    );
  };

  const handleRemoveEntity = () => {
    props?.dispatch(
      toggleSetDeleteModal({
        title: i18n.t("findings.findingAttendees.deletePerson"),
        description: {
          first: (
            <Trans
              t={i18n.t()}
              i18nKey={"findings.findingAttendees.deleteDescriptionOne"}
              values={{
                name: props?.prefilledData?.name,
              }}
              components={[<FieldText key={1} />]}
            />
          ),
          second: i18n.t("findings.findingAttendees.deleteDescriptionTwo"),
        },
        rank: 1,
        prefilledData: props?.prefilledData,
        isEditing: true,
        onSubmit: () => {
          props?.value?.onDelete(props?.prefilledData?.id);
          props?.dispatch(closeModal());
        },
      })
    );
  };

  return (
    <ActionRendererContainer>
      <Tooltip title={i18n.t("common.change")}>
        <ActionRendererEditStandardIconContainer onClick={handleEditStaff}>
          <EditIcon />
        </ActionRendererEditStandardIconContainer>
      </Tooltip>
      <WithPermissions roles={[MANAGER_ROLE]}>
        <Tooltip title={i18n.t("standards.tooltip.deleteStandard")}>
          <ActionRendererDeleteStandardIconContainer
            onClick={handleRemoveEntity}
          >
            <DeleteIcon />
          </ActionRendererDeleteStandardIconContainer>
        </Tooltip>
      </WithPermissions>
    </ActionRendererContainer>
  );
};

ActionRenderer.propTypes = {
  children: PropTypes.node,
  dispatch: PropTypes.func,
  value: PropTypes.object,
  rendererProps: PropTypes.any,
  refetchTable: PropTypes.func,
  prefilledData: PropTypes.object,
  onDelete: PropTypes.func,
  t: PropTypes.any,
};

export default ActionRenderer;
