import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import newStuffValidationSchema from "../../../../validations/newStuffValidationSchema";
import TextInputField from "../../../Field/TextInputField/TextInputField";

const RankField = (props) => {
  const { t } = useTranslation();
  const formik = props?.formik;
  return (
    <>
      <TextInputField
        name="rank"
        label={t("stuffs.rank")}
        placeholder={t("stuffs.newStuffPlaceholders.rank")}
        value={formik?.values?.rank}
        onChange={formik?.handleChange}
        inputProps={{
          sx: {
            input: {
              paddingRight:
                formik?.errors?.rank &&
                formik?.touched?.rank &&
                "40px !important",
              width:
                formik?.errors?.rank &&
                formik?.touched?.rank &&
                "314px !important",
            },
          },
        }}
        error={formik?.touched?.rank && formik?.errors?.rank}
        helperText={formik?.errors?.rank && formik?.touched?.rank}
        requiredField={
          newStuffValidationSchema?.()?.fields?.rank?.exclusiveTests?.required
        }
        disabledField={props?.isReadOnly}
      />
    </>
  );
};

RankField.propTypes = {
  formik: PropTypes.object,
  isReadOnly: PropTypes.bool,
};

export default RankField;
