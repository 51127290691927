import React from "react";
// import PropTypes from 'prop-types'
import { ReactComponent as SetPasswordIconSvg } from "../../../assets/images/svg/set-password.svg";

const SetPasswordIcon = (props) => {
  return <SetPasswordIconSvg {...props} />;
};

SetPasswordIcon.propTypes = {};

export default SetPasswordIcon;
