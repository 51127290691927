import { Box } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../themes";

export const HeaderCompanyImageContainer = styled(Box)`
  cursor: pointer;
  //background: ${selectedTheme.colors.profilePictureBackground};
  width: 160px;
  height: 40px;
  text-align: center;
  display: flex;
  //justify-content: center;
  align-items: center;

  gap:12px;
  justify-content: start;
  padding-left: 12px;

  @media (max-width: 600px) {
    width: 32px !important; 
    height: 32px !important; 
    gap: 4px; 
    padding-left: 0px; 
  }
`;

/*export const CompanyImageHeader = styled(Box)`
  cursor: pointer;
  background: ${selectedTheme.colors.profilePictureBackground};
  width: 28px;
  height: 28px;
  text-align: center;
  max-width: 28px;
    max-height: 28px;
    object-fit: contain;
`;*/

export const CompanyImageHeader = styled.img`
  cursor: pointer;
  width: 80px;
  height: 45px;
  text-align: center;
  max-width: 45px;
    max-height: 37px;
    object-fit: contain;

     @media (max-width: 600px) {
    width: 32px !important;
    height: 32px !important;
  }
`;

export const CompanyTitleHeader = styled(Box)`
  color: #636363; //${selectedTheme.colors.gray};
  font-family: ${selectedTheme.fonts.mainText};
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media (max-width: 600px) {
    display: none;
  }
`;