import React, { useEffect } from "react";
import PropTypes from "prop-types";
import {
  FindingScanningQuestionContainer,
  FindingScanningWizzardContainer,
  QuestionsPaging,
} from "./FindingScanningWizzard.styled";
import ProgressBar from "../../../../../ProgressBar/ProgressBar";
import { useState } from "react";
import FindingQuestionCard from "../../../../../Cards/FindingQuestionCard/FindingQuestionCard";

const FindingScanningWizzard = (props) => {
  const [currentStep, setCurrentStep] = useState(1);

  useEffect(() => {
    setCurrentStep(1);
  }, [props?.section?.id]);

  const changeQuestion = (newQuestion) => {
    setTimeout(() => {
      if (newQuestion > 0 && newQuestion <= props?.items?.length) {
        setCurrentStep(newQuestion);
        window.scrollTo(0, 150);
      }
    }, 0)
  };

  return (
    <FindingScanningWizzardContainer>
      <FindingScanningQuestionContainer>
        <ProgressBar
          totalSteps={props?.items?.length}
          currentStep={currentStep}
        />
        <FindingQuestionCard
          mobile
          key={props?.items?.[currentStep - 1]?.id}
          question={props?.items?.[currentStep - 1]}
          answerQuestion={props?.answerQuestion}
          disabled={props?.disabled}
          changeConclusion={props?.changeConclusion}
        />
      </FindingScanningQuestionContainer>
      <QuestionsPaging
        showNameOnMobile
        hidePageNumbers
        current={currentStep}
        pages={props?.items?.length}
        changePage={changeQuestion}
      />
    </FindingScanningWizzardContainer>
  );
};

FindingScanningWizzard.propTypes = {
  items: PropTypes.array,
  answerQuestion: PropTypes.func,
  handleChangeTab: PropTypes.func,
  selectedTab: PropTypes.object,
  section: PropTypes.object,
  disabled: PropTypes.bool,
  changeConclusion: PropTypes.func,
};

export default FindingScanningWizzard;
