import React from "react";
import PropTypes from "prop-types";
import {
  ArrowIcon,
  ButtonLabel,
  GoBackButtonContainer,
} from "./GoBackButton.styled";

const GoBackButton = (props) => {
  return (
    <GoBackButtonContainer
      className={props?.className}
      onClick={props?.onClick}
    >
      <ArrowIcon />
      <ButtonLabel>{props?.label}</ButtonLabel>
    </GoBackButtonContainer>
  );
};

GoBackButton.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func,
};

export default GoBackButton;
