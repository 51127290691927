import React, { forwardRef, useImperativeHandle, useState } from "react";
import PropTypes from "prop-types";
import EmptyScanningObjectPicker from "./EmptyScanningObjectPicker/EmptyScanningObjectPicker";
import { ScanningObjectPickerContainer } from "./ScanningObjectPicker.styled";
import TemplateEditor from "../../../TemplateEditor/TemplateEditor";
import { useDispatch } from "react-redux";
import { addScanningObjectSelectedChecklistTemplate } from "../../../../store/actions/scanningObjects/scanningObjectsActions";

const ScanningObjectPicker = forwardRef((props, ref) => {
  const [isTemplateChosen, setIsTemplateChosen] = useState(false);
  const dispatch = useDispatch();

  const { scanningObjectRef, templateRef } = ref;

  useImperativeHandle(scanningObjectRef, () => ({
    isTemplateChosen,
    setIsTemplateChosen,
  }));

  const handleAddTemplate = () => {
    setIsTemplateChosen(true);
    dispatch(
      addScanningObjectSelectedChecklistTemplate({
        isDefault: true,
        id: Math.random(),
        measuringInstruments: [],
        standards: [],
        sections: [],
      })
    );
  };
  return (
    <ScanningObjectPickerContainer isEditing={props?.isEditing}>
      {isTemplateChosen || props?.isEditing ? (
        <TemplateEditor
          isEditing={props?.isEditing}
          isReadOnly={props?.isReadOnly}
          ref={templateRef}
        />
      ) : (
        <EmptyScanningObjectPicker
          handleAddTemplate={handleAddTemplate}
          isEditing={props?.isEditing}
        />
      )}
    </ScanningObjectPickerContainer>
  );
});

ScanningObjectPicker.displayName = "ScanningObjectPicker";

ScanningObjectPicker.propTypes = {
  children: PropTypes.node,
  isEditing: PropTypes.bool,
  isReadOnly: PropTypes.bool,
};

export default ScanningObjectPicker;
