import React from "react";
import PropTypes from "prop-types";
import {
  BenchmarkDate,
  BenchmarkingRendererContainer,
  BenchmarkName,
} from "./BenchmarkingRenderer.styled";
import Tooltip from "../../../../Tooltip/Tooltip";

const BenchmarkingRenderer = (props) => {
  return (
    <BenchmarkingRendererContainer>
      <Tooltip title={props?.value?.name}>
        <BenchmarkDate>{props?.value?.date}</BenchmarkDate>
        <BenchmarkName>{props?.value?.name}</BenchmarkName>
      </Tooltip>
    </BenchmarkingRendererContainer>
  );
};

BenchmarkingRenderer.propTypes = {
  value: PropTypes.any,
};

export default BenchmarkingRenderer;
