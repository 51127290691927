import React from "react";
import PropTypes from "prop-types";
import i18n from "../../../../../../i18n";
import { StatusRendererContainer } from "./StatusRenderer.styled";

const StatusRenderer = (props) => {
  return (
    <StatusRendererContainer>
      {i18n.t(props?.value?.label)}
    </StatusRendererContainer>
  );
};

StatusRenderer.propTypes = {
  value: PropTypes.number,
};

export default StatusRenderer;
