import * as Yup from "yup";

export default (isEditing) =>
  Yup.object().shape({
    firstName: Yup.string()
      .trim()
      .max(255, "registerUser.firstNameNumberOfCharacters")
      .required("registerUser.firstNameRequired"),
    lastName: Yup.string()
      .trim()
      .max(255, "registerUser.lastNameNumberOfCharacters")
      .required("registerUser.lastNameRequired"),
    createdDate: Yup.string(),
    userRole: Yup.array(),
    email: Yup.string()
      .trim()
      .email("clients.emailFormat")
      .max(255, "registerUser.emailNumberOfCharacters")
      .required("registerUser.emailRequired"),
    password: isEditing
      ? Yup.string().max(255, "registerUser.passwordNumberOfCharacters")
      : Yup.string()
          .max(255, "registerUser.passwordNumberOfCharacters")
          .required("registerUser.blankPasswordError"),
    confirmPassword: isEditing
      ? Yup.string().max(255, "registerUser.passwordNumberOfCharacters")
      : Yup.string()
          .max(255, "registerUser.passwordNumberOfCharacters")
          .required("registerUser.confirmPasswordRequred")
          .oneOf(
            [Yup.ref("password"), null],
            "changePassword.differentPasswordsError"
          )
          .matches(/^(?=.{6,})/, "changePassword.passwordLength")
          .matches(/^(?=.*[0-9])/, "changePassword.numbersRequred")
          .matches(
            /^(?=.*[!@#$%^&*])/,
            "changePassword.specialCharactersRequired"
          )
          .matches(/^(?=.*[a-z])(?=.*[A-Z])/, "changePassword.lettersRequred"),
  });
