import React from "react";
import PropTypes from "prop-types";
import { SidebarLowerContentContainer } from "./SidebarLowerContent.styled";
import SidebarNavigationItem from "../SidebarNavigationItem/SidebarNavigationItem";
import SettingsIcon from "../../Icon/Icons/SettingsIcon";
import SidebarToggleButton from "../SidebarToggleButton/SidebarToggleButton";
import { SETTINGS_PAGE } from "../../../constants/pages";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectIsSidebarOpened } from "../../../store/selectors/sidebarSelector";
import WithPermissions from "../../WithPermissions/WithPermissions";
import { ADMIN_ROLE, MANAGER_ROLE } from "../../../constants/rolesConstants";

const SidebarLowerContent = () => {
  const { t } = useTranslation();
  const isSidebarOpened = useSelector(selectIsSidebarOpened);
  return (
    <SidebarLowerContentContainer $isSidebarOpened={isSidebarOpened}>
      <SidebarToggleButton />
      <WithPermissions roles={[ADMIN_ROLE, MANAGER_ROLE]}>
        <SidebarNavigationItem
          item={{
            icon: <SettingsIcon />,
            route: SETTINGS_PAGE,
            title: t("pages.settings"),
          }}
        />
      </WithPermissions>
    </SidebarLowerContentContainer>
  );
};

SidebarLowerContent.propTypes = {
  children: PropTypes.node,
};

export default SidebarLowerContent;
