import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import ReactDatePicker from "react-datepicker";
import {
  DateFieldContainer,
  DateTextField,
  SingleInputTitle,
} from "./ExpirationDateField.styled";
import { licenseFormikConstants } from "../../../../initialValues/licenseInitialValues";

const ExpirationDateField = (props) => {
  const formik = props?.formik;
  const { t } = useTranslation();

  return (
    <DateFieldContainer>
      <SingleInputTitle>
        {t("licenses.modal.fields.expirationDate.label")}
      </SingleInputTitle>
      <ReactDatePicker
        placeholderText={t("licenses.modal.fields.expirationDate.placeholder")}
        disabled={props?.disabled}
        minDate={
          formik?.values?.[licenseFormikConstants.startDate]
            ? new Date(formik?.values?.[licenseFormikConstants.startDate])
            : null
        }
        selected={
          formik?.values?.[licenseFormikConstants.expirationDate]
            ? new Date(formik?.values?.[licenseFormikConstants.expirationDate])
            : null
        }
        dateFormat="dd.MM.yyyy"
        onChange={(date) =>
          formik?.setFieldValue?.(licenseFormikConstants.expirationDate, date)
        }
        customInput={
          <DateTextField
            date
            disabledField={props?.disabled}
            requiredField={false}
            name={licenseFormikConstants.expirationDate}
            placeholder={t("licenses.modal.fields.expirationDate.placeholder")}
            value={formik?.values?.[licenseFormikConstants.expirationDate]}
            onChange={formik?.handleChange}
            error={
              formik?.touched?.[licenseFormikConstants.expirationDate] &&
              formik?.errors?.[licenseFormikConstants.expirationDate]
            }
            helperText={
              formik?.errors?.[licenseFormikConstants.expirationDate] &&
              formik?.touched?.[licenseFormikConstants.expirationDate]
            }
            fullWidth
          />
        }
      />
    </DateFieldContainer>
  );
};

ExpirationDateField.propTypes = {
  formik: PropTypes.object,
  disabled: PropTypes.bool,
};

export default ExpirationDateField;
