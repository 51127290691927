import styled from "styled-components";
import OnlineIcon from "../../Icon/Icons/OnlineIcon";
import { Box } from "@mui/material";
import PopoverComponent from "../../Modals/ModalComponents/PopoverComponent";
import { hexToRGB } from "../../../util/helpers/colorHelper";
import selectedTheme from "../../../themes";

export const HeaderIsOnlineContainer = styled(Box)`
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  border-radius: 100%;
  background-color: #fef3f2;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
`;

export const HeaderOnlineIconCircle = styled(Box)`
  border-radius: 100%;
  position: absolute;
  top: 8px;
  left: 8px;
  width: 24px;
  height: 24px;
  z-index: 0;
  background: #fee4e2;
`;

export const HeaderOnlineIcon = styled(OnlineIcon)`
  height: 26px;
  position: relative;
  top: 1px;
  left: 1px;
  z-index: 1;
  width: 26px;
  & svg {
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }
`;
export const NotOnlinePopoverComponent = styled(PopoverComponent)`
  & #popover-content {
    margin-top: 5px;
    box-shadow: 0px 8px 8px -4px ${hexToRGB(selectedTheme.colors.shadowColor, 0.03)},
      0px 20px 24px -4px ${hexToRGB(selectedTheme.colors.shadowColor, 0.08)};
    border-radius: 12px;
    overflow: hidden;
  }
`;
