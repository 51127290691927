import React from "react";
import PropTypes from "prop-types";
import {
  ActionRendererChangePasswordIconContainer,
  ActionRendererContainer,
  ActionRendererDeleteProfileIconContainer,
  ActionRendererEditProfileIconContainer,
  ActionRendererResendInviteIconContainer,
} from "./ActionRenderer.styled";
import SetPasswordIcon from "../../../../../Icon/Icons/SetPasswordIcon";
import EditIcon from "../../../../../Icon/Icons/EditIcon";
import {
  closeLoadingModal,
  closeModal,
  toggleSetLoadingModal,
  toggleSetPasswordModal,
  toggleSetUserModal,
} from "../../../../../../store/actions/modal/modalActions";
import i18n from "../../../../../../i18n";
import { EDIT_COMPANY_USER } from "../../../../../../constants/pages";
import { replaceInRoute } from "../../../../../../util/helpers/routeHelpers";
import {
  fetchChangeUserPassword,
  fetchBlockUser,
  fetchUnblockUser,
  fetchReinviteUser,
  fetchDeleteInviteUser,
} from "../../../../../../store/actions/user/userActions";
import Tooltip from "../../../../../Tooltip/Tooltip";
import {
  makeErrorToastMessage,
  makeToastMessage,
} from "../../../../../../store/utils/makeToastMessage";
import { APPLICATION_TOAST_CONTAINER_ID } from "../../../../../../constants/toastConstants";
import DeactivateIcon from "../../../../../Icon/Icons/DeactivateIcon";
import ActivateIcon from "../../../../../Icon/Icons/ActivateIcon";
import { USER_STATUS } from "../../../../../../constants/userStatusConstants";
import DeleteIcon from "../../../../../Icon/Icons/DeleteIcon";
import InviteIcon from "../../../../../Icon/Icons/InviteIcon";

const ActionRenderer = (props) => {
  let blocked = props?.value?.status?.id === USER_STATUS.INACTIVE.id;
  let invited = props?.value?.status?.id === USER_STATUS.INVITED.id;
  const handleChangeUserPassword = (values) => {
    props?.dispatch(
      fetchChangeUserPassword({
        userId: props?.value?.userId,
        newPassword: values?.password,
        handleApiResponseSuccess: handleApiResponseSuccessChangePassword,
      })
    );
  };
  const handleApiResponseSuccessChangePassword = () => {
    makeToastMessage(
      {
        title: i18n.t("changePassword.changedPasswordSuccess"),
        description: i18n.t(
          "changePassword.changedPasswordSuccessDescription",
          {
            name: props?.value?.firstName + " " + props?.value?.lastName,
          }
        ),
      },
      {
        containerId: APPLICATION_TOAST_CONTAINER_ID,
      }
    );
  };
  const handleChangePassword = () => {
    props?.dispatch(
      toggleSetPasswordModal({
        title: i18n.t("changePassword.changePasswordTitle", {
          name: props?.value?.firstName + " " + props?.value?.lastName,
        }),
        onSubmit: handleChangeUserPassword,
        rank: 1,
      })
    );
  };

  const showSuccessToastMessage = (title, description) => {
    makeToastMessage(
      {
        title: props?.value.t(title, {
          typeOfData: props?.value.t("users.typeOfData"),
        }),
        description: props?.value.t(description, {
          name: props?.value.firstName + " " + props?.value.lastName,
          typeOfData: props?.value.t("users.typeOfDataPlural").toLowerCase(),
        }),
      },
      {
        containerId: APPLICATION_TOAST_CONTAINER_ID,
      }
    );
  };

  const handleApiResponseError = (errorMessage) => {
    props?.dispatch(closeLoadingModal());
    makeErrorToastMessage(
      {
        title: i18n.t("apiErrors.error"),
        description: errorMessage,
      },
      {
        containerId: APPLICATION_TOAST_CONTAINER_ID,
      }
    );
  };

  const handleApiResponseSuccess = (title, description) => {
    props?.dispatch(closeLoadingModal());
    props?.dispatch(closeModal());
    showSuccessToastMessage(title, description);
    props?.value?.tableRef?.current?.forceRefetch?.();
  };

  const handleApiResponseSuccessActivate = () => {
    handleApiResponseSuccess(
      "toast.success.activateTitle",
      "toast.success.activateDescription"
    );
  };
  const handleApiResponseSuccessDeactivate = () => {
    handleApiResponseSuccess(
      "toast.success.deactivateTitle",
      "toast.success.deactivateDescription"
    );
  };
  const handleApiResponseSuccessResend = () => {
    handleApiResponseSuccess(
      "toast.success.resendTitle",
      "toast.success.resendDescription"
    );
  };
  const handleApiResponseSuccessDelete = () => {
    handleApiResponseSuccess(
      "toast.success.deleteTitle",
      "toast.success.deleteDescription"
    );
  };

  const handleDeactivateUser = () => {
    props?.dispatch(toggleSetLoadingModal({ rank: 2 }));
    props?.dispatch(
      fetchBlockUser({
        userId: props?.value?.userId,
        handleApiResponseSuccess: handleApiResponseSuccessDeactivate,
        handleApiResponseError,
      })
    );
  };

  const handleDeleteInvitedUser = () => {
    props?.dispatch(closeModal());
    props?.dispatch(toggleSetLoadingModal({ rank: 2 }));
    props?.dispatch(
      fetchDeleteInviteUser({
        userId: props?.value?.userId,
        handleApiResponseSuccess: handleApiResponseSuccessDelete,
      })
    );
  };

  const handleDeactivate = () => {
    props?.dispatch(
      toggleSetUserModal({
        title: i18n.t("users.modal.deactivate.title"),
        description: i18n.t("users.modal.deactivate.description"),
        buttonTitle: i18n.t("common.deactivate"),
        onSubmit: handleDeactivateUser,
        rank: 1,
      })
    );
  };

  const handleActivateUser = () => {
    props?.dispatch(toggleSetLoadingModal({ rank: 2 }));
    props?.dispatch(
      fetchUnblockUser({
        userId: props?.value?.userId,
        handleApiResponseSuccess: handleApiResponseSuccessActivate,
        handleApiResponseError,
      })
    );
  };

  const handleActivate = () => {
    props?.dispatch(
      toggleSetUserModal({
        title: i18n.t("users.modal.activate.title"),
        description: i18n.t("users.modal.activate.description"),
        buttonTitle: i18n.t("common.activate"),
        onSubmit: handleActivateUser,
        rank: 1,
      })
    );
  };

  const handleDelete = () => {
    props?.dispatch(
      toggleSetUserModal({
        title: i18n.t("users.modal.delete.title"),
        description: i18n.t("users.modal.delete.description"),
        buttonTitle: i18n.t("common.delete"),
        onSubmit: handleDeleteInvitedUser,
        rank: 1,
      })
    );
  };

  const handleResend = () => {
    props?.dispatch(
      fetchReinviteUser({
        userId: props?.value?.userId,
        handleApiResponseSuccess: handleApiResponseSuccessResend,
      })
    );
  };

  return (
    <ActionRendererContainer $mobile={props?.isMobile}>
      {!blocked && !invited && (
        <>
          {!props?.rendererProps?.hideChangePassword && (
            <Tooltip title={i18n.t("users.tooltip.changePassword")}>
              <ActionRendererChangePasswordIconContainer
                onClick={handleChangePassword}
              >
                <SetPasswordIcon />
              </ActionRendererChangePasswordIconContainer>
            </Tooltip>
          )}
        </>
      )}
      {!invited && (
        <Tooltip title={i18n.t("users.tooltip.changeUser")}>
          <ActionRendererEditProfileIconContainer
            to={{
              pathname: replaceInRoute(EDIT_COMPANY_USER, {
                userId: props?.value?.userId,
                companyId: props?.value?.companyId,
              }),
              state: {
                ...props?.value?.routeState,
                isDeactive: !blocked,
              },
            }}
          >
            <EditIcon />
          </ActionRendererEditProfileIconContainer>
        </Tooltip>
      )}
      {!invited && (
        <Tooltip
          title={
            blocked
              ? i18n.t("users.activateUser")
              : i18n.t("users.deactivateUser")
          }
        >
          <ActionRendererDeleteProfileIconContainer
            onClick={blocked ? handleActivate : handleDeactivate}
          >
            {blocked ? <ActivateIcon /> : <DeactivateIcon />}
          </ActionRendererDeleteProfileIconContainer>
        </Tooltip>
      )}
      {invited && (
        <Tooltip title={i18n.t("users.reinviteUser")}>
          <ActionRendererResendInviteIconContainer onClick={handleResend}>
            <InviteIcon />
          </ActionRendererResendInviteIconContainer>
        </Tooltip>
      )}
      {invited && (
        <Tooltip title={i18n.t("users.deleteUser")}>
          <ActionRendererDeleteProfileIconContainer onClick={handleDelete}>
            <DeleteIcon />
          </ActionRendererDeleteProfileIconContainer>
        </Tooltip>
      )}
    </ActionRendererContainer>
  );
};

ActionRenderer.propTypes = {
  children: PropTypes.node,
  dispatch: PropTypes.func,
  value: PropTypes.object,
  rendererProps: PropTypes.any,
  isMobile: PropTypes.bool,
};

export default ActionRenderer;
