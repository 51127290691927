import { Box } from "@mui/material";
import styled from "styled-components";
import { hexToRGB } from "../../../util/helpers/colorHelper";
import selectedTheme from "../../../themes";
import XIcon from "../../Icon/Icons/XIcon";
import { TextInputFieldContainer } from "../../Field/TextInputField/TextInputField.styled";

export const ModalContainer = styled(Box)`
  padding: 24px;
  background-color: white;
  box-shadow: 0px 20px 24px -4px ${hexToRGB(selectedTheme.colors.shadowColor, 0.08)},
    0px 8px 8px -4px ${hexToRGB(selectedTheme.colors.shadowColor, 0.03)};
  border-radius: 12px;
  max-width: 942px;
  min-width: 350px;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 24px;
  overflow: auto;
  @media (max-width: 600px) {
    width: 100vw;
    height: 100vh;
    border-radius: 0;
  }
`;

export const ErrorMessage = styled(Box)`
  font-family: ${selectedTheme.fonts.mainText};
  font-size: 14px;
  font-weight: 400;
  color: ${selectedTheme.colors.errorColor};
  margin-top: -6px;
`;

export const CloseButton = styled(XIcon)`
  position: absolute;
  top: 26px;
  right: 26px;
  cursor: pointer;
  width: 24px;
  height: 24px;
`;
export const FormContainer = styled(Box)`
  & ${TextInputFieldContainer} {
    margin-top: 0;
  }
`;
