import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import questionTypeConstants from "../../../../constants/questionTypeConstants";
import { ChapterTitle } from "../SetScanningObjectModal.styled";
import {
  QuestionTypeSelectorContainer,
  QuestionTypeSelectorOptions,
} from "./QuestionTypeSelector.styled";
import SelectOptionCard from "../../../Cards/SelectOptionCard/SelectOptionCard";
import TextIcon from "../../../Icon/Icons/TextIcon";
import TickCircledIcon from "../../../Icon/Icons/TickCircledIcon";
import ImageIcon from "../../../Icon/Icons/ImageIcon";
import TableIcon from "../../../Icon/Icons/TableIcon";

const QuestionTypeSelector = (props) => {
  const { t } = useTranslation();
  const [selectedOption, setSelectedOption] = useState(
    props?.selectedOption || questionTypeConstants.TEXT
  );

  const handleClickOption = (newSelectedOption) => {
    setSelectedOption(newSelectedOption);
    props?.setSelectedOption(newSelectedOption);
  };

  const selectedQuestionType = useMemo(() => {
    if (props?.selectedOption !== undefined) {
      return props?.selectedOption;
    }
    return selectedOption;
  }, [props?.selectedOption, selectedOption]);

  return (
    <QuestionTypeSelectorContainer>
      <ChapterTitle>{t("questions.type")}</ChapterTitle>
      <QuestionTypeSelectorOptions>
        <SelectOptionCard
          icon={<TextIcon />}
          onClick={() => handleClickOption(questionTypeConstants.TEXT)}
          isSelected={selectedQuestionType === questionTypeConstants.TEXT}
        />
        <SelectOptionCard
          icon={<TickCircledIcon />}
          onClick={() => handleClickOption(questionTypeConstants.SELECT)}
          isSelected={selectedQuestionType === questionTypeConstants.SELECT}
        />
        <SelectOptionCard
          icon={<TableIcon />}
          onClick={() => handleClickOption(questionTypeConstants.TABLE)}
          isSelected={selectedQuestionType === questionTypeConstants.TABLE}
        />
        <SelectOptionCard
          icon={<ImageIcon />}
          onClick={() => handleClickOption(questionTypeConstants.IMAGE)}
          isSelected={selectedQuestionType === questionTypeConstants.IMAGE}
        />
      </QuestionTypeSelectorOptions>
    </QuestionTypeSelectorContainer>
  );
};

QuestionTypeSelector.propTypes = {
  selectedOption: PropTypes.string,
  setSelectedOption: PropTypes.func,
};

export default QuestionTypeSelector;
