import {
  getRequest,
  postRequest,
  deleteRequest,
  putRequest,
  replaceInUrl,
} from ".";
import apiEndpoints from "./apiEndpoints";

export const attemptFetchStandards = (payload) =>
  getRequest(apiEndpoints.standards.getStandards, payload);

export const attemptFetchSingleStandard = (payload) =>
  getRequest(
    replaceInUrl(apiEndpoints.standards.getSingleStandard, {
      standardId: payload,
    })
  );
export const attemptFetchCreateStandard = (payload) =>
  postRequest(apiEndpoints.standards.createStandard, payload);

export const attemptFetchEditStandard = (payload) =>
  putRequest(
    replaceInUrl(apiEndpoints.standards.getSingleStandard, {
      standardId: payload?.standardId,
    }),
    payload?.data
  );

export const attemptFetchDeleteStandard = (payload) =>
  deleteRequest(
    replaceInUrl(apiEndpoints.standards.getSingleStandard, {
      standardId: payload?.standardId,
    })
  );
