import { Box, Typography } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../themes";

export const LoginHeaderContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
`;
export const LoginTitle = styled(Typography)`
  font-family: "Inter";
  font-weight: 700;
  font-size: 28px;
  line-height: 36px;
  text-align: center;
  letter-spacing: -0.02em;
  color: ${selectedTheme.colors.titleColor};
`;
export const Logo = styled.img``;
