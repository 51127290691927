import { Box } from "@mui/material";
import styled from "styled-components";
import Icon from "../../../../Icon/Icon";
import Link from "../../../../Link/Link";

export const ActionRendererContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 17px;
  @media (max-width: 600px) {
    position: absolute;
    top: 17px;
    right: 15px;
  }
`;
export const ActionRendererEditFindingTypeIconContainer = styled(Link)`
  cursor: pointer;
`;

export const ActionRendererDeleteFindingTypeIconContainer = styled(Icon)`
  cursor: pointer;
`;
export const ActionRendererLicensePreviewContainer = styled(Icon)`
  cursor: pointer;
`;
