import React from "react";
import {
  AutocompleteContainer,
  AutocompleteDropdown,
  AutocompleteInputField,
  AutocompleteItem,
  DeleteButton,
  FieldLabel,
  Tag,
  TagsContainer,
} from "./AutocompleteField.styled";
import PropTypes from "prop-types";
import { ErrorMessage } from "../../Select/Select.styled";
import { useTranslation } from "react-i18next";
import selectedTheme from "../../../themes";
import { useState } from "react";
import { useEffect } from "react";
import useIsMobile from "../../../hooks/useIsMobile";
import { InputAdornment } from "@mui/material";

const AutocompleteField = (props) => {
  const { t } = useTranslation();
  const [autoValue, setAutoValue] = useState(props?.value);
  const { isMobile } = useIsMobile();

  useEffect(() => {
    setAutoValue(props?.value);
  }, [props?.value]);

  return (
    <AutocompleteContainer>
      {props?.title && (
        <FieldLabel $required={props?.required} $setMargin={props?.setMargin}>
          {props?.title}
        </FieldLabel>
      )}
      <AutocompleteDropdown
        disablePortal
        clearText={t("common.remove")}
        closeText={t("common.close")}
        openText={t("common.open")}
        noOptionsText={t("common.noOptions")}
        fullWidth
        disableClearable={props?.disableClearable}
        disabled={props?.disabled}
        multiple={props?.isMultiple}
        $removeMaxWidth={props?.removeMaxWidth}
        disableCloseOnSelect={props?.isMultiple}
        options={props?.options}
        //InputLabelProps={{ shrink: false }}
        getOptionLabel={(option) =>
          option
            ? props?.dropdownLabel?.reduce(
                (accumulator, currVal, index) =>
                  index === props?.dropdownLabel?.length - 1
                    ? accumulator + option[currVal]
                    : accumulator + option[currVal] + " ",
                ""
              )
            : null
        }
        renderInput={(params) => (
          <AutocompleteInputField
            {...params}
            InputProps={{
              ...params?.InputProps,
              endAdornment:
                !props?.disabled && params?.InputProps?.endAdornment,
              startAdornment: props?.value?.icon ? (
                <InputAdornment position="start">
                  {props?.value?.icon}
                </InputAdornment>
              ) : (
                params?.InputProps?.startAdornment
              ),
            }}
            inputProps={{
              ...params?.inputProps,
              style: {
                display: props?.value?.length > 0 && props?.disabled && "none",
              },
            }}
            error={Boolean(props?.error)}
            InputLabelProps={{ shrink: false }}
            placeholder={props?.label}
            value={props?.value}
          />
        )}
        renderOption={(propsItem, option) => (
          <AutocompleteItem
            {...propsItem}
            {...props?.optionProps}
            key={option?.id}
          >
            {props?.dropdownLabel?.reduce?.(
              (accumulator, currVal) => accumulator + option?.[currVal] + " ",
              ""
            )}
          </AutocompleteItem>
        )}
        renderTags={(selected) => (
          <TagsContainer>
            {selected?.map?.((value) => (
              <Tag
                key={value?.id}
                label={props?.dropdownLabel?.reduce(
                  (accumulator, currVal) => accumulator + value[currVal] + " ",
                  ""
                )}
                clickable={isMobile}
                onClick={() => (isMobile ? props?.onDelete(value) : () => {})}
                onDelete={() => props?.onDelete(value)}
                deleteIcon={
                  props?.disabled ? (
                    <></>
                  ) : (
                    <DeleteButton
                      onMouseDown={(event) => event.stopPropagation()}
                    />
                  )
                }
              />
            ))}
          </TagsContainer>
        )}
        onChange={(event, query) => {
          props?.onChange(query);
        }}
        isOptionEqualToValue={(option, value) => option?.id === value?.id}
        defaultValue={props?.defaultValue}
        value={autoValue}
        sx={{
          "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            border: !props?.error
              ? `1px solid ${selectedTheme.colors.inputBorderColor};`
              : `1px solid ${selectedTheme.colors.errorColor}`,
          },
          "&.Mui-disabled .MuiOutlinedInput-root": {
            color: `${selectedTheme.colors.black} !important`,
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: `${selectedTheme.colors.inputBorderColor} !important`,
          },
          "&.MuiAutocomplete-endAdornment": {
            position: "absolute !important",
          },
        }}
      />
      {props?.error && <ErrorMessage>{t(props?.error)}</ErrorMessage>}
    </AutocompleteContainer>
  );
};

AutocompleteField.propTypes = {
  options: PropTypes.array,
  label: PropTypes.string,
  defaultValue: PropTypes.object,
  value: PropTypes.object,
  onChange: PropTypes.func,
  dropdownLabel: PropTypes.array,
  title: PropTypes.string,
  error: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  setMargin: PropTypes.bool,
  isMultiple: PropTypes.bool,
  onDelete: PropTypes.func,
  removeMaxWidth: PropTypes.bool,
  renderOption: PropTypes.node,
  optionProps: PropTypes.object,
  disableClearable: PropTypes.bool,
  renderValue: PropTypes.func,
};

AutocompleteField.defaultProps = {
  value: null,
  renderOption: <></>,
  optionProps: {},
};

export default AutocompleteField;
