import React from "react";
import PropTypes from "prop-types";
import { FindingScanningSectionChooserContainer } from "./FindingScanningSectionChooser.styled";
import FindingNewScanningSubject from "./FindingNewScanningSubject/FindingNewScanningSubject";
import { statusTableConstants } from "../../../../../constants/findingStatusConstants";
import FindingScanningSingleSection from "./FindingScanningSingleSection/FindingScanningSingleSection";
import useIsMobile from "../../../../../hooks/useIsMobile";
import CustomDragLayer from "../../../../CustomDragLayer/CustomDragLayer";
import EmptyFindingScanningSection from "./EmptyFindingScanningSection/EmptyFindingScanningSection";

// Now uses scanning subjects instead of sections !!!!
const FindingScanningSectionChooser = (props) => {
  const { isMobile } = useIsMobile();

  const dragLayerFunc = (item) => {
    return props?.sections?.find(
      (section) => section?.orderNumber?.value === item?.orderNumber
    );
  };
  return (
    <FindingScanningSectionChooserContainer
      style={props?.style}
      $isMobile={props?.isMobile}
    >
      {isMobile && (
        <CustomDragLayer
          data={props?.sections}
          selectFunction={dragLayerFunc}
        />
      )}
      {props?.stateId === statusTableConstants.inProgress.id &&
        !props?.notEditable && (
          <FindingNewScanningSubject
            settingsFormik={props?.settingsFormik}
            isEditing={props?.isEditing}
          />
        )}
      <EmptyFindingScanningSection/>
      {props?.sections?.map((singleSection, index) => (
      
        <FindingScanningSingleSection
          key={index}
          selected={props?.chosenScanningSubjectId === singleSection?.id}
          section={{
            ...singleSection,
            title: singleSection?.name
              ? singleSection?.name
              : singleSection?.title,
          }}
          handleChangeSection={props?.handleChangeSection}
          notEditable={
            props?.notEditable ||
            props?.stateId !== statusTableConstants.inProgress.id
          }
          stateId={props?.stateId}
          data={props?.sections}
          scanningSection={props?.scanningSection}
          handleSectionClick={props.handleSectionClick}
          isOpen={props.isOpen}
          selectedSubSectionId={props.selectedSubSectionId}
          onSetSelectedSubSectionId={props.onSetSelectedSubSectionId}
          closeScanning = {props.closeScanning}
        />
  ))}     
    </FindingScanningSectionChooserContainer>
  );
};

FindingScanningSectionChooser.propTypes = {
  sections: PropTypes.array,
  chosenScanningSubjectId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.object,
  ]),
  handleChangeSection: PropTypes.func,
  stateId: PropTypes.any,
  settingsFormik: PropTypes.func,
  isEditing: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  notEditable: PropTypes.bool,
  style: PropTypes.object,
  setScanningSubjectId: PropTypes.func,
  scanningSection: PropTypes.bool,
  isMobile: PropTypes.bool,
  handleSectionClick: PropTypes.func,
  isOpen: PropTypes.bool,
  onSetSelectedSubSectionId: PropTypes.func,
  selectedSubSectionId: PropTypes.any,
  closeScanning: PropTypes.bool

};

export default FindingScanningSectionChooser;
