import React, { useCallback, useMemo, useRef } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import EmptyMeasuringInstrumentsTable from "./EmptyMeasuringInstrumentsTable/EmptyMeasuringInstrumentsTable";
import templateMeasuringInstrumentsTableConstants from "../../../../../constants/tableConstants/templateMeasuringInstrumentsTableConstants";
import Table from "../../../../Table/Table";
import TextRenderer from "./Renderers/TextRenderer/TextRenderer";
import { formatDate } from "../../../../../util/helpers/dateHelpers";

const TemplateMeasuringInstrumentsTable = (props) => {
  const { t } = useTranslation();
  const tableRef = useRef(null);

  const tableColumns = useMemo(() => {
    let columnsObject = Object.keys(
      templateMeasuringInstrumentsTableConstants
    ).map((property) => ({
      ...templateMeasuringInstrumentsTableConstants[property],
      field: t(templateMeasuringInstrumentsTableConstants[property].i18key),
      propertyName: property,
      disableSort: true,
      style: {
        ...templateMeasuringInstrumentsTableConstants[property]?.style,
        // whiteSpace: "nowrap",
        minWidth: 0,
      },
      renderer:
        templateMeasuringInstrumentsTableConstants[property]?.renderer ||
        TextRenderer,
      rendererProps: {
        handleRemoveEntity: props?.handleRemoveInstrument,
      },
    }));
    if (props?.isReadOnly) return columnsObject?.slice?.(0, -1);
    return columnsObject;
  }, [
    templateMeasuringInstrumentsTableConstants,
    t,
    props?.handleRemoveInstrument,
  ]);

  const mapDataFunction = useCallback((newData) => {
    return newData?.map((singleData) => {
      let mappedDataObject = {
        name: singleData?.name,
        factoryNumber: singleData?.serialNumber,
        benchmarking: {
          name: singleData?.calibrationNumber,
          date: singleData?.validUntil
            ? formatDate(new Date(singleData?.validUntil))
            : null,
        },
        delete: {
          id: singleData?.id,
          name: singleData?.name,
          factoryNumber: singleData?.serialNumber,
          benchmarking: {
            name: singleData?.calibrationNumber,
            date: singleData?.validUntil
              ? formatDate(new Date(singleData?.validUntil))
              : null,
          },
        },
      };
      if (props?.isReadOnly) delete mappedDataObject?.delete;
      return mappedDataObject;
    });
  }, []);

  if (props?.data?.length === 0) return <EmptyMeasuringInstrumentsTable />;

  return (
    <Table
      ref={tableRef}
      tableColumns={tableColumns}
      hideHeader
      hidePaging
      custom
      customData={props?.data}
      mapDataFunction={mapDataFunction}
    />
  );
};

TemplateMeasuringInstrumentsTable.propTypes = {
  children: PropTypes.node,
  data: PropTypes.array,
  handleRemoveInstrument: PropTypes.func,
  custom: PropTypes.bool,
  isReadOnly: PropTypes.bool,
};
TemplateMeasuringInstrumentsTable.defaultProps = {
  data: [],
};

export default TemplateMeasuringInstrumentsTable;
