import React from "react";
import { useTranslation } from "react-i18next";

import Title from "../Typography/Title/Title";
import { ScanningObjectsContentContainer } from "./ScanningObjectsContent.styled";
import ScanningObjectsTable from "./ScanningObjectsTable/ScanningObjectsTable";

const ScanningObjectsContent = () => {
  const { t } = useTranslation();
  return (
    <ScanningObjectsContentContainer>
      <Title>{t("scanningObjects.title")}</Title>
      <ScanningObjectsTable />
    </ScanningObjectsContentContainer>
  );
};

export default ScanningObjectsContent;
