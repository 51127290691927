import React from "react";
import PropTypes from "prop-types";
import OrdinalNumberButton from "../../../../../Buttons/OrdinalNumberButton/OrdinalNumberButton";
import {
  DNDIcon,
  DNDIconContainer,
  SubjectOrderNumberContainer,
} from "./SubjectOrderNumber.styled";
import { useDispatch, useSelector } from "react-redux";
import {
  moveFindingSubjectAbove,
  moveFindingSubjectBelow,
  setFindingsChanged,
} from "../../../../../../store/actions/findings/findingsActions";
import { selectIsFindingSettingsChanged } from "../../../../../../store/selectors/findingsSelectors";
import { forwardRef } from "react";

const SubjectOrderNumber = forwardRef((props, ref) => {
  const isFindingChanged = useSelector(selectIsFindingSettingsChanged);
  const dispatch = useDispatch();
  const handleMoveAbove = (event) => {
    event.stopPropagation();
    if (props?.notEditable) return;
    if (!isFindingChanged) dispatch(setFindingsChanged(true));
    dispatch(moveFindingSubjectAbove(props?.number - 1));
  };
  const handleMoveBelow = (event) => {
    event.stopPropagation();
    if (props?.notEditable) return;
    if (!isFindingChanged) dispatch(setFindingsChanged(true));
    dispatch(moveFindingSubjectBelow(props?.number - 1));
  };
  return (
    <SubjectOrderNumberContainer onClick={(event) => event.stopPropagation()}>
      <DNDIconContainer ref={ref}>
        <DNDIcon />
      </DNDIconContainer>
      {/* Component use opposite directions */}
      <OrdinalNumberButton
        number={props?.number}
        vertical
        separated
        onAboveArrowClick={handleMoveAbove}
        onBelowArrowClick={handleMoveBelow}
      />
    </SubjectOrderNumberContainer>
  );
});

SubjectOrderNumber.displayName = "SubjectOrderNumber";

SubjectOrderNumber.propTypes = {
  number: PropTypes.number,
  dragRef: PropTypes.any,
  notEditable: PropTypes.bool,
};

export default SubjectOrderNumber;
