import { Box, MenuItem, Select, Typography } from "@mui/material";
import styled, { css } from "styled-components";
import selectedTheme from "../../themes";
import { hexToRGB } from "../../util/helpers/colorHelper";
import ErrorIcon from "../Icon/Icons/ErrorIcon";

export const SelectMUI = styled(Select)`
  border: 1px solid
    ${(props) =>
      props?.$isErrorShown
        ? selectedTheme.colors.errorBorderColor
        : selectedTheme.colors.inputBorderColor};
  &::after {
    border: 2px solid
      ${(props) =>
        props?.$isErrorShown
          ? selectedTheme.colors.errorBorderColor
          : selectedTheme.colors.inputBorderColor};
  }
  &::before {
    border: 2px solid
      ${(props) =>
        props?.$isErrorShown
          ? selectedTheme.colors.errorBorderColor
          : selectedTheme.colors.inputBorderColor};
  }
  & * {
    border: 0 !important;
  }
  border-radius: 8px;
  font-family: ${selectedTheme.fonts.mainText};
  font-size: 16px;
  line-height: 24px;
  background-color: ${(props) =>
    props?.disabled && selectedTheme.colors.disabledFieldBackground};
  font-feature-settings: "salt" on;
  box-shadow: 0px 1px 2px ${hexToRGB(selectedTheme.colors.shadowColor, 0.05)};
  color: ${(props) =>
    props?.value !== undefined
      ? selectedTheme.colors.titleColor
      : selectedTheme.colors.borderColor} !important;
  color: ${(props) =>
    props?.disabled && `${selectedTheme.colors.disabledFieldText} !important`};
  -webkit-text-fill-color: ${(props) =>
    props?.disabled && `${selectedTheme.colors.disabledFieldText}`};
`;
export const Option = styled(MenuItem)`
  font-family: ${selectedTheme.fonts.mainText};
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  padding: 9px;
  margin: 0px 0px 0px 6px;
  border-radius: 6px 0 0 6px;
  font-feature-settings: "salt" on;
  color: ${(props) =>
    props?.value !== "PLACEHOLDER_VALUE"
      ? selectedTheme.colors.titleColor
      : selectedTheme.colors.borderColor} !important;
  &:nth-child(1) {
    border-bottom: 1px solid ${selectedTheme.colors.tableBorder};
    display: ${(props) => (props?.hideDefaultValue ? "none" : "block")};
  }
  &:nth-child(2) {
    margin-top: 2px;
  }
`;
export const SelectLabel = styled(Box)`
  font-family: ${selectedTheme.fonts.mainText};
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: ${selectedTheme.colors.labelTextColor};
  ${(props) =>
    props?.$required &&
    css`
      &::after {
        content: " *";
        color: ${selectedTheme.colors.errorColor};
      }
    `}
`;

export const ErrorMessage = styled(Typography)`
  font-family: ${selectedTheme.fonts.mainText};
  font-size: 12px;
  font-weight: 400;
  color: ${selectedTheme.colors.errorColor};
  text-align: ${(props) => props?.$rightAlign && "right"};
  margin-top: 3px;
`;

export const IconError = styled(ErrorIcon)`
  width: 18px;
  height: 18px;
  margin-right: 8px;
`;
