import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import {
  RedirectIcon,
  RedirectIconContainer,
  TableTitle,
  TableTitleContainer,
} from "./DashboardTable.styled";

const DashboardTable = (props) => {
  return (
    <Grid item xs={12} sm={6}>
      <TableTitleContainer>
        <TableTitle>{props?.title}</TableTitle>
        <RedirectIconContainer to={props?.href}>
          <RedirectIcon />
        </RedirectIconContainer>
      </TableTitleContainer>
      {props?.tableComponent}
    </Grid>
  );
};

DashboardTable.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.element,
  ]),
  href: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  tableComponent: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
};

export default DashboardTable;
