import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { fetchFindingTypes } from "../../../store/actions/findingTypes/findingTypesActions";
import { selectFindingTypesData } from "../../../store/selectors/findingTypesSelectors";
//import FindingTypeLabel from "./FindingTypeLabel/FindingTypeLabel";
import {
  //FindingTypes,
  FindingTypesContainer,
  //FindingTypesLabel,
} from "./FindingTypesSelect.styled";
import AutocompleteField from "../../Field/AutocompleteField/AutocompleteField";

const FindingTypesSelect = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isTouchedValue, setIsTouchedValue] = useState(false);
  const [selectedValue, setSelectedValue] = useState(null);
  const findingTypes = useSelector(selectFindingTypesData);

  useEffect(() => {
    dispatch(fetchFindingTypes());
  }, []);

  useEffect(() => {
    if (!isTouchedValue && selectedValue !== props?.initValue) {
      setIsTouchedValue(true);
    }
  }, [selectedValue]);

  const handleChange = (value) => {
    setSelectedValue(value);
    props?.onChange(value);
  };

  return (
    <FindingTypesContainer>
      {/*<FindingTypesLabel>{t("findingTypes.findingType")}</FindingTypesLabel>*/}

      <AutocompleteField
        required
        options={findingTypes}
        removeMaxWidth
        label={t("findingTypes.findingType")}
        title={t("findingTypes.findingType")}
        dropdownLabel={["name"]}
        onChange={handleChange}
        value={selectedValue}
        error={
          !selectedValue &&
          isTouchedValue &&
          t("findingTypes.modal.errorMessage")
        }
      />
      {/*<FindingTypes value={selectedValue} onChange={handleChange}>
        {findingTypes?.map((item, index) => (
          <FindingTypeLabel
            isChecked={JSON.stringify(item) === selectedValue}
            key={index}
            label={item?.name}
            value={JSON.stringify(item)}
            uncheckedIcon={props?.uncheckedIcon ?? props?.uncheckedIcon}
            checkedIcon={props?.checkedIcon ?? props?.checkedIcon}
          />
        ))}
      </FindingTypes>*/}
    </FindingTypesContainer>
  );
};

FindingTypesSelect.propTypes = {
  data: PropTypes.array,
  onChange: PropTypes.func,
  value: PropTypes.any,
  initValue: PropTypes.any,
  uncheckedIcon: PropTypes.oneOfType([PropTypes.element, PropTypes.node]),
  checkedIcon: PropTypes.oneOfType([PropTypes.element, PropTypes.node]),
};

FindingTypesSelect.defaultProps = {
  onChange: () => {},
  initValue: null,
};

export default FindingTypesSelect;
