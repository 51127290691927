import React from "react";
import PropTypes from "prop-types";
import {
  FieldLabel,
  LabelContainer,
  NumberSearchField,
  SearchFilterComponentContainer,
} from "./SearchFilterComponent.styled";
import { useRef } from "react";
import { useEffect } from "react";

const SearchFilterComponent = (props) => {
  const searchRef = useRef();

  const changeSearchHandler = (value) => {
    props?.setSearch?.({ value: value, isValid: value?.toString().length >= 4 });
  };

  useEffect(() => {
    if (props?.value == null) {
      searchRef.current?.resetInput?.();
    }
  }, [props?.value]);

  return (
    <SearchFilterComponentContainer>
      <LabelContainer>
        <FieldLabel>{props?.label}</FieldLabel>
      </LabelContainer>
      <NumberSearchField
        onChange={() => changeSearchHandler(event.target.value)}
        ref={searchRef}
        placeholder={props?.placeholder}
        defaultValue={null}
        value={props?.value}
        type="number"
      />
    </SearchFilterComponentContainer>
  );
};

SearchFilterComponent.propTypes = {
  placeholder: PropTypes.string,
  label: PropTypes.string,
  icon: PropTypes.any,
  value: PropTypes.any,
  setSearch: PropTypes.func,
};
export default SearchFilterComponent;
