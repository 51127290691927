import React, { useEffect } from "react";
import PropTypes from "prop-types";
import AddFindingTypeContent from "../../../../components/FindingTypes/AddFindingType/AddFindingTypeContent";
import { useDispatch, useSelector } from "react-redux";
import { fetchSingleFindingType } from "../../../../store/actions/findingTypes/findingTypesActions";
import { useRouteMatch } from "react-router-dom";
import { selectSingleFindingType } from "../../../../store/selectors/findingTypesSelectors";

const EditFindingTypePage = () => {
  const dispatch = useDispatch();
  const routeMatch = useRouteMatch();
  const prefilledData = useSelector(selectSingleFindingType);
  useEffect(() => {
    dispatch(
      fetchSingleFindingType({
        findingTypeId: routeMatch.params?.findingTypeId,
      })
    );
  }, []);
  return <AddFindingTypeContent prefilledData={prefilledData} isEditing />;
};

EditFindingTypePage.propTypes = {
  children: PropTypes.node,
};

export default EditFindingTypePage;
