import PropTypes from "prop-types";
import React from "react";
import LoginForm from "../LoginForm/LoginForm";
import LoginHeader from "../LoginHeader/LoginHeader";
import { LoginContainer } from "./LoginContent.styled";

const LoginContent = () => {
  return (
    <LoginContainer>
      <LoginHeader />
      <LoginForm />
    </LoginContainer>
  );
};

LoginContent.propTypes = {
  children: PropTypes.node,
};

export default LoginContent;
