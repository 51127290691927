import ActionRenderer from "../../components/Clients/ClientsTable/Renderers/ActionRenderer/ActionRenderer";
import LastChangesRenderer from "../../components/Clients/ClientsTable/Renderers/LastChangesRenderer/LastChangesRenderer";
import UserRenderer from "../../components/Users/UsersTable/ActiveUsersTable/Renderers/UserRenderer/UserRenderer";

export default {
  clients: {
    id: 0,
    i18key: "clients.table.clients.columnTitle",
    renderer: UserRenderer,
    backendProperty: "name",
    redirect: true,
    style: {
      minWidth: "calc(100% / 6)",
    },
  },
  header: {
    id: 8,
    i18key: "clients.table.clients.columnTitle",
    mobile: true,
    disableSort: true,
    style: {},
  },
  pib: {
    id: 1,
    i18key: "clients.table.pib.columnTitle",
    backendProperty: "tin",
    style: {
      minWidth: "calc(100% / 6)",
    },
  },
  address: {
    id: 2,
    i18key: "clients.table.address.columnTitle",
    backendProperty: "address",
    style: {
      minWidth: "calc(100% / 6)",
    },
  },
  idNumber: {
    id: 3,
    i18key: "clients.table.idNumber.columnTitle",
    backendProperty: "identificationNumber",
    style: {
      minWidth: "calc(100% / 6)",
    },
  },
  lastChanges: {
    id: 4,
    i18key: "clients.table.lastChanges.columnTitle",
    renderer: LastChangesRenderer,
    style: {
      minWidth: "calc(100% / 6)",
      textAlign: "center",
      justifyContent: "center",
    },
    backendProperty: "updatedAtUtc",
  },
  actions: {
    id: 5,
    i18key: "",
    renderer: ActionRenderer,
    disableSort: true,
    style: {
      minWidth: "132px",
      paddingRight: "24px",
      justifyContent: "center",
      textAlign: "center",
      maxWidth: "132px",
    },
  },
};
