import React from "react";
import PropTypes from "prop-types";
import TextInputField from "../../../Field/TextInputField/TextInputField";
import { useTranslation } from "react-i18next";
import newStuffValidationSchema from "../../../../validations/newStuffValidationSchema";

const FirstNameField = (props) => {
  const { t } = useTranslation();
  const formik = props?.formik;
  return (
    <>
      <TextInputField
        name="firstName"
        label={t("stuffs.firstName")}
        placeholder={t("stuffs.newStuffPlaceholders.firstName")}
        value={formik?.values?.firstName}
        onChange={formik?.handleChange}
        inputProps={{
          sx: {
            input: {
              paddingRight:
                formik?.errors?.firstName &&
                formik?.touched?.firstName &&
                "40px !important",
              width:
                formik?.errors?.firstName &&
                formik?.touched?.firstName &&
                "314px !important",
            },
          },
        }}
        error={formik?.touched?.firstName && formik?.errors?.firstName}
        helperText={formik?.errors?.firstName && formik?.touched?.firstName}
        requiredField={
          newStuffValidationSchema?.()?.fields?.firstName?.exclusiveTests?.required
        }
        disabledField={props?.isReadOnly}
      />
    </>
  );
};

FirstNameField.propTypes = {
  formik: PropTypes.object,
  isReadOnly: PropTypes.bool,
};

export default FirstNameField;
