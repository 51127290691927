export const ACTIVE_TEMPLATE_TAB = 0;
export const ALL_TEMPLATES_TAB = 1;
export const templateEditorTabs = [
  {
    id: ACTIVE_TEMPLATE_TAB,
    i18key: "scanningObjects.newScanningObject.templateEditor.pages.active",
  },
  {
    id: ALL_TEMPLATES_TAB,
    i18key: "scanningObjects.newScanningObject.templateEditor.pages.all",
  },
];
export const findTabById = (tabs, tabId) =>
  tabs.find((singleTab) => singleTab.id === tabId);
