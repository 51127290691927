import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { formatDate } from "../../../util/helpers/dateHelpers";
import {
  IsActiveTemplateSwitch,
  SwitchLabel,
  TemplateCardContainer,
  TemplateCardCreatedBy,
  TemplateCardDetails,
  TemplateCardTitle,
} from "./TemplateCard.styled";
import TemplateCardDetail from "./TemplateCardDetail/TemplateCardDetail";
import MeasuringInstrumentsIcon from "../../Icon/Icons/MeasuringInstrumentsIcon";
import SectionsIcon from "../../Icon/Icons/SectionsIcon";
import ItemsIcon from "../../Icon/Icons/ItemsIcon";
import { useDispatch } from "react-redux";
import {
  setScanningObjectsChanged,
  toggleScanningObjectSelectedChecklistTemplate,
} from "../../../store/actions/scanningObjects/scanningObjectsActions";

const TemplateCard = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleChangeIsActive = () => {
    dispatch(
      toggleScanningObjectSelectedChecklistTemplate({
        templateId: props?.template?.id,
      })
    );
    dispatch(setScanningObjectsChanged(true));
  };

  const createdByText = useMemo(() => {
    return t("scanningObjects.newScanningObject.templateEditor.createdBy", {
      username: props?.username,
      date: formatDate(props?.createdDate),
    });
  }, [props?.username, props?.createdDate, t]);

  const updatedByText = useMemo(() => {
    return t("scanningObjects.newScanningObject.templateEditor.updatedAt", {
      date: formatDate(props?.updatedDate),
      state: props?.isActive
        ? t("scanningObjects.newScanningObject.templateEditor.active")
        : t("scanningObjects.newScanningObject.templateEditor.archived"),
    });
  }, [props?.username, props?.updatedDate, t]);

  const numberOfInstruments = useMemo(() => {
    return (
      props?.template?.measuringInstruments?.length ||
      props?.numberOfInstruments
    );
  }, [props?.template?.measuringInstruments, props?.numberOfInstruments]);

  const numberOfSections = useMemo(() => {
    return props?.template?.sections?.length || props?.numberOfSections;
  }, [props?.template?.sections, props?.numberOfSections]);

  const numberOfItems = useMemo(() => {
    return props?.template?.sections?.reduce(
      (prevValue, currentValue) => prevValue + currentValue?.questions?.length,
      0
    );
  });

  const numberOfStandards = useMemo(() => {
    return props?.template?.standards?.length || props?.numberOfStandards;
  }, [props?.template?.standards, props?.numberOfStandards]);

  return (
    <TemplateCardContainer>
      <IsActiveTemplateSwitch
        label={<SwitchLabel>{t("common.active")}</SwitchLabel>}
        checked={props?.isActive}
        onChange={handleChangeIsActive}
        disabled={props?.isReadOnly}
      />
      <TemplateCardTitle>{updatedByText}</TemplateCardTitle>
      <TemplateCardCreatedBy>{createdByText}</TemplateCardCreatedBy>
      <TemplateCardDetails>
        <TemplateCardDetail
          icon={<MeasuringInstrumentsIcon />}
          value={numberOfInstruments}
        />
        <TemplateCardDetail icon={<ItemsIcon />} value={numberOfStandards} />
        <TemplateCardDetail icon={<SectionsIcon />} value={numberOfSections} />

        <TemplateCardDetail icon={<ItemsIcon />} value={numberOfItems} />
      </TemplateCardDetails>
    </TemplateCardContainer>
  );
};

TemplateCard.propTypes = {
  updatedDate: PropTypes.string,
  createdDate: PropTypes.string,
  username: PropTypes.string,
  title: PropTypes.string,
  numberOfInstruments: PropTypes.number,
  numberOfSections: PropTypes.number,
  numberOfItems: PropTypes.number,
  numberOfStandards: PropTypes.number,
  template: PropTypes.object,
  isActive: PropTypes.bool,
  setIsActive: PropTypes.func,
  isReadOnly: PropTypes.bool,
};
TemplateCard.defaultProps = {
  numberOfInstruments: 0,
  numberOfSections: 0,
  numberOfItems: 0,
  numberOfStandards: 0,
};

export default TemplateCard;
