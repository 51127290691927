import React, { useEffect } from "react";
import PropTypes from "prop-types";
import {
  InviteTimeoutPageContainer,
  InviteTimeoutPageDescription,
  InviteTimeoutPageTitle,
} from "./InviteTimeoutPage.styled";
import history from "../../store/utils/history";
import { LOGIN_PAGE } from "../../constants/pages";
import { Trans, useTranslation } from "react-i18next";

const InviteTimeoutPage = () => {
  const { t } = useTranslation();
  useEffect(() => {
    setTimeout(() => {
      history?.replace(LOGIN_PAGE);
    }, 7000);
  }, []);

  return (
    <InviteTimeoutPageContainer>
      <InviteTimeoutPageTitle>
        {t("invitationTimeoutPage.title")}
      </InviteTimeoutPageTitle>
      <InviteTimeoutPageDescription>
        <Trans i18nKey="invitationTimeoutPage.description" />
      </InviteTimeoutPageDescription>
    </InviteTimeoutPageContainer>
  );
};

InviteTimeoutPage.propTypes = {
  children: PropTypes.node,
};

export default InviteTimeoutPage;
