import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "../../../store/actions/modal/modalActions";
import { useTranslation } from "react-i18next";
import {
  AttendedPersonModalContainer,
  InputsContainer,
  TextInput,
  TextareaInput,
  TextareaLabel,
} from "./AttendedPersonModal.styled";
import {
  ButtonsContainer,
  CancelButton,
  CloseButton,
  ModalTitle,
  SubmitButton,
} from "../Modal.styled";
import BackdropComponent from "../../Modals/ModalComponents/BackdropComponent";
import { setSingleFindingAttendees } from "../../../store/actions/findings/findingsActions";
import { selectSingleFindingAttendees } from "../../../store/selectors/findingsSelectors";

const AttendedPersonModal = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [name, setName] = useState(
    props?.isEditing ? props?.prefilledData?.name : ""
  );
  const [title, setTitle] = useState(
    props?.isEditing ? props?.prefilledData?.title : ""
  );
  const attendees = useSelector(selectSingleFindingAttendees);

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  const handleSubmit = () => {
    const arr = name.split(/ (.*)/);
    let dataToAdd = {
      id: props?.prefilledData?.id
        ? props?.prefilledData?.id
        : attendees?.length > 0
        ? attendees?.[attendees?.length - 1]?.value?.id + 1
        : 0,
      firstName: { value: arr[0], edited: false },
      lastName: { value: arr[1] || "", edited: false },
      title: { value: title, edited: false },
    };
    let newAttendees;
    if (props?.isEditing) {
      newAttendees = [...attendees];
      const dataIndex = newAttendees?.findIndex(
        (entry) => entry?.value?.id === dataToAdd?.id
      );
      if (newAttendees[dataIndex]?.added)
        newAttendees[dataIndex] = {
          value: dataToAdd,
          added: true,
        };
      else {
        if (name !== props?.prefilledData?.name) {
          dataToAdd = {
            id: dataToAdd?.id,
            firstName: { value: arr[0], edited: true },
            lastName: { value: arr[1] || "", edited: true },
            title: { value: title, edited: false },
          };
        }
        if (title !== props?.prefilledData?.title) {
          dataToAdd = {
            id: dataToAdd?.id,
            firstName: { value: arr[0], edited: false },
            lastName: { value: arr[1] || "", edited: false },
            title: { value: title, edited: true },
          };
        }
        newAttendees[dataIndex] = {
          value: dataToAdd,
          edited: false,
        };
      }
    } else
      newAttendees = [
        ...attendees,
        {
          value: dataToAdd,
          added: true,
        },
      ];
    dispatch(setSingleFindingAttendees(newAttendees));
    dispatch(closeModal());
  };

  const isSavingEnabled = useMemo(() => {
    if (name && title) {
      if (!props?.isEditing) return true;
      if (
        props?.isEditing &&
        (name !== props?.prefilledData?.name ||
          title !== props?.prefilledData?.title)
      ) {
        return true;
      }
      return false;
    }
    return false;
  }, [name, props?.isEditing, title]);

  return (
    <>
      {props?.rank > 1 ? (
        <BackdropComponent
          isLoading
          handleClose={handleCloseModal}
          position="fixed"
        />
      ) : (
        <></>
      )}
      <AttendedPersonModalContainer rank={props?.rank}>
        <CloseButton onClick={handleCloseModal} />
        <ModalTitle>{props?.title}</ModalTitle>
        <InputsContainer>
          <TextInput
            requiredField
            label={t("findings.findingAttendees.nameField")}
            name="name"
            value={name}
            onChange={(e) => {
              setName(e?.target?.value);
            }}
          />
          <TextareaLabel $required={title?.length === 0}>
            {t("findings.findingAttendees.titleField")}
          </TextareaLabel>
          <TextareaInput
            name="title"
            minRows={7}
            maxRows={7}
            onChange={(e) => {
              setTitle(e?.target?.value);
            }}
            value={title}
            disabled={props?.disabled}
          />
        </InputsContainer>

        {!props?.disabled && (
          <ButtonsContainer>
            <CancelButton onClick={handleCloseModal}>
              {t("common.cancel")}
            </CancelButton>
            <SubmitButton
              onClick={!isSavingEnabled ? () => {} : handleSubmit}
              disabled={!isSavingEnabled}
            >
              {t("common.save")}
            </SubmitButton>
          </ButtonsContainer>
        )}
      </AttendedPersonModalContainer>
    </>
  );
};

AttendedPersonModal.propTypes = {
  children: PropTypes.node,
  rank: PropTypes.number,
  title: PropTypes.string,
  onSubmit: PropTypes.func,
  prefilledInput: PropTypes.string,
  isEditing: PropTypes.bool,
  isDefault: PropTypes.bool,
  prefilledData: PropTypes.object,
  dropdownLabel: PropTypes.array,
  disabled: PropTypes.bool,
};
AttendedPersonModal.defaultProps = {
  onSubmit: () => {},
};

export default AttendedPersonModal;
