import { Box, Divider, Typography } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../../../themes";

export const LabelContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 6px;
`;
export const FieldLabel = styled(Typography)`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: ${selectedTheme.colors.labelTextColor};
  font-family: ${selectedTheme.fonts.mainText};
`;

export const PickerFilterComponentContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 6px;
  width: 100%;
  margin-top: 8px;
`;

export const CheckboxContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
  width: 100%;
`;

export const TopCheckboxContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;
`;

export const Separator = styled(Divider)`
  background-color: ${selectedTheme.colors.inputBorder};
  width: 100%;
`;
export const BottomCheckboxContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  padding: 0px;
  gap: 32px;

  @media (max-width: 340px) {
    flex-direction: column;
    gap: 4px;
  }
`;
export const LeftSide = styled(Box)`
  display: flex;
  flex-direction: column;
  padding: 0px;
  gap: 4px;
`;

export const RightSide = styled(Box)`
  display: flex;
  flex-direction: column;
  padding: 0px;
  gap: 4px;
`;
