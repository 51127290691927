import React from "react";
import PropTypes from "prop-types";
import { Trans, useTranslation } from "react-i18next";
import ImagePicker from "../../../ImagePicker/ImagePicker";

const LicenseField = (props) => {
  const { t } = useTranslation();
  const formik = props?.formik;
  return (
    <ImagePicker
      maxWidth={270}
      maxHeight={270}
      minWidth={50}
      minHeight={50}
      name="licenseUrl"
      label={t("stuffs.license")}
      image={formik?.values?.licenseUrl}
      setImage={(newImage) => formik?.setFieldValue?.("licenseUrl", newImage)}
      descriptionText={
        <Trans i18nKey={"companySettings.choseCompanyLicense"} />
      }
      disabled={props?.isReadOnly}
    />
  );
};

LicenseField.propTypes = {
  formik: PropTypes.object,
  isReadOnly: PropTypes.bool,
};

export default LicenseField;
