import { Box } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../../../../themes";

export const RoleRendererContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: ${(props) => (props?.$isInDashboard ? "3px" : "4px 20px")};
  justify-content: center;
`;
export const RoleRendererContentContainer = styled(Box)`
  height: 20px;
  padding: 0 8px;
  border-radius: 24px;
  background: ${(props) =>
    props?.role
      ? selectedTheme.colors.usersTable.roles?.[props?.role?.toLowerCase?.()]
          ?.background
      : ""};
`;
export const RoleRendererContentTitle = styled(Box)`
  color: ${(props) =>
    props?.role
      ? selectedTheme.colors.usersTable.roles?.[props?.role?.toLowerCase?.()]
          ?.text
      : ""};
  font-family: ${selectedTheme.fonts.mainText};
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  display: flex;
  align-items: center;
  font-feature-settings: "salt" on;
`;
