import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import TextInputField from "../../../Field/TextInputField/TextInputField";

const CompanyTinNumber = (props) => {
  const { t } = useTranslation();
  const formik = props?.formik;
  return (
    <>
      <TextInputField
        name="companyPIB"
        label={t("companySettings.PIB")}
        number
        value={formik?.values?.companyPIB}
        onChange={formik?.handleChange}
        error={formik?.touched?.companyPIB && formik?.errors?.companyPIB}
        helperText={formik?.touched?.companyPIB && formik?.errors?.companyPIB}
        requiredField={!formik?.values?.companyPIB}
      />
    </>
  );
};

CompanyTinNumber.propTypes = {
  formik: PropTypes.object,
  isRequired: PropTypes.bool,
};

export default CompanyTinNumber;
