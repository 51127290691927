import React from "react";
// import PropTypes from 'prop-types'
import { ReactComponent as DocumentTypeIconSvg } from "../../../assets/images/svg/document-type.svg";

const DocumentTypeIcon = (props) => {
  return <DocumentTypeIconSvg {...props} />;
};

DocumentTypeIcon.propTypes = {};

export default DocumentTypeIcon;
