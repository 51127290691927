import LastChangesRenderer from "../../components/Clients/ClientsTable/Renderers/LastChangesRenderer/LastChangesRenderer";
import ActionRenderer from "../../components/FindingTypes/FindingTypesTable/Renderers/ActionRenderer/ActionRenderer";
import UserRenderer from "../../components/Users/UsersTable/ActiveUsersTable/Renderers/UserRenderer/UserRenderer";

export default {
  name: {
    id: 0,
    i18key: "findingTypes.table.name.columnTitle",
    redirect: true,
    renderer: UserRenderer,
    style: {
      flex: 2,
    },
    backendProperty: "name",
  },
  caption: {
    id: 1,
    i18key: "findingTypes.table.caption.columnTitle",
    redirect: true,
    style: {
      flex: 2,
    },
    backendProperty: "caption",
  },
  subcaption: {
    id: 2,
    i18key: "findingTypes.table.subcaption.columnTitle",
    redirect: true,
    style: {
      flex: 2,
    },
    backendProperty: "subcaption",
  },
  lastChanges: {
    id: 3,
    i18key: "findingTypes.table.lastChanges.columnTitle",
    renderer: LastChangesRenderer,
    style: {
      textAlign: "center",
      justifyContent: "center",
      flex: 1,
    },
    backendProperty: "updatedAtUtc",
  },
  actions: {
    id: 4,
    i18key: "",
    renderer: ActionRenderer,
    disableSort: true,
    style: {
      minWidth: "132px",
      maxWidth: "132px",
      paddingRight: "24px",
      justifyContent: "center",
      textAlign: "center",
    },
  },
  header: {
    id: 5,
    i18key: "findingTypes.table.header.columnTitle",
    mobile: true,
    disableSort: true,
    style: {},
  }
};
