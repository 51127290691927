import { createSelector } from "reselect";

const measuringInstrumentsSelector = (state) => state.measuringInstruments;

export const selectMeasuringInstruments = createSelector(
  measuringInstrumentsSelector,
  (state) => state.measuringInstruments
);
export const selectTotalMeasuringInstruments = createSelector(
  measuringInstrumentsSelector,
  (state) => state.totalMeasuringInstruments
);
export const selectSingleMeasuringInstrument = createSelector(
  measuringInstrumentsSelector,
  (state) => state.singleMeasuringInstrument
);
export const selectMeasuringInstrumentsData = createSelector(
  measuringInstrumentsSelector,
  (state) => state.measuringInstruments.data
);
export const selectMeasuringInstrumentsError = createSelector(
  measuringInstrumentsSelector,
  (state) => state.errorMessage
);
