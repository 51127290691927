import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { TemplateEditorContainer } from "./TemplateEditor.styled";

import TemplateEditorNavigation from "./TemplateEditorNavigation/TemplateEditorNavigation";
import ActiveTemplateEditor from "./ActiveTemplateEditor/ActiveTemplateEditor";
import AllTemplatesEditor from "./AllTemplatesEditor/AllTemplatesEditor";
import {
  ACTIVE_TEMPLATE_TAB,
  ALL_TEMPLATES_TAB,
  templateEditorTabs,
} from "../../constants/templateEditorTabs";
import { useSelector } from "react-redux";
import {
  selectSelectedChecklistTemplate,
  selectSingleScanningObject,
  // selectSingleScanningObjectAllSections,
  // selectSingleScanningObjectMeasuringInstruments,
} from "../../store/selectors/scanningObjectsSelectors";

const TemplateEditor = (props) => {
  const templateRef = useRef(null);
  const [chosenSectionId, setChosenSectionId] = useState(0);
  // const sections = useSelector(selectSingleScanningObjectAllSections);
  const checklistTemplate = useSelector(selectSelectedChecklistTemplate);
  const scanningObject = useSelector(selectSingleScanningObject);
  // const selectedMeasuringInstruments = useSelector(
  //   selectSingleScanningObjectMeasuringInstruments
  // );
  const [tabs, setTabs] = useState(
    templateEditorTabs?.map?.((singleTab) => {
      return {
        ...singleTab,
        selected: singleTab?.id === ACTIVE_TEMPLATE_TAB,
      };
    })
  );

  useEffect(() => {
    if (
      scanningObject?.checklistTemplates[0]?.sections?.length > 0 &&
      !chosenSectionId
    ) {
      setChosenSectionId(
        scanningObject?.checklistTemplates[0]?.sections[0]?.id
      );
    }
  }, [props?.isEditing, scanningObject]);

  const isTabActive = (tabId) => {
    return tabs?.find((singleTab) => singleTab?.id === tabId)?.selected;
  };

  const handleChangeTab = (tabId) => {
    setTabs(
      templateEditorTabs?.map((singleTab) => ({
        ...singleTab,
        selected: singleTab?.id === tabId,
      }))
    );
  };
  return (
    <TemplateEditorContainer>
      <TemplateEditorNavigation
        tabs={tabs}
        isEditing={props?.isEditing}
        handleChangeTab={handleChangeTab}
        showSwitch={
          isTabActive(ACTIVE_TEMPLATE_TAB) &&
          checklistTemplate &&
          props?.isEditing
        }
        isReadOnly={props?.isReadOnly}
      />
      {isTabActive(ACTIVE_TEMPLATE_TAB) && (
        <ActiveTemplateEditor
          ref={templateRef}
          chosenSectionId={chosenSectionId}
          setChosenSectionId={setChosenSectionId}
          handleChangeTab={handleChangeTab}
          isReadOnly={props?.isReadOnly}
        />
      )}
      {isTabActive(ALL_TEMPLATES_TAB) && (
        <AllTemplatesEditor
          handleChangeTab={handleChangeTab}
          isReadOnly={props?.isReadOnly}
        />
      )}
    </TemplateEditorContainer>
  );
};

TemplateEditor.displayName = "TemplateEditor";

TemplateEditor.propTypes = {
  children: PropTypes.node,
  isEditing: PropTypes.bool,
  isReadOnly: PropTypes.bool,
};

export default TemplateEditor;
