import styled from "styled-components";
import { Box, Typography } from "@mui/material";
import Title from "../../Typography/Title/Title";
import selectedTheme from "../../../themes";
import Icon from "../../Icon/Icon";
import { TextInputFieldContainer } from "../../Field/TextInputField/TextInputField.styled";
import { ImagePickerContainer } from "../../ImagePicker/ImagePicker.styled";

export const NewStuffFormContainer = styled.form`
  /* margin-left: 8vw; */
  min-height: calc(100vh - 66px);
  display: flex;
  flex-direction: column;
  padding-bottom: 72px;
  @media (max-width: 600px) {
    padding-bottom: 32px;
    & ${TextInputFieldContainer} input {
      width: 100%;
    }
    & ${TextInputFieldContainer} div:has(input) {
      width: 100%;
    }
    & ${ImagePickerContainer} {
      margin-top: 32px;
    }
  }
`;
export const InputContainer = styled(Box)``;

export const PageTitleContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
`;

export const PageTitle = styled(Title)`
  margin-bottom: 16px;
  width: 100%;
`;

export const DeleteIconContainer = styled(Icon)`
  cursor: pointer;
`;

export const NewStuffInputsContainer = styled(Box)`
  display: flex;
  gap: 43px;
  flex: 1;
  @media (max-width: 600px) {
    flex-direction: column;
    gap: 0;
  }
`;

export const NewStuffLeftColumn = styled(Box)`
  margin-top: -32px;
`;

export const NewStuffRightColumn = styled(Box)`
  margin: 0px 0px 0px 60px;
  gap: 40px;
  display: flex;
  flex-direction: column;
  @media (max-width: 600px) {
    margin: 0;
  }
`;

export const ButtonsContainer = styled(Box)`
  display: flex;
  gap: 15px;
  margin-left: -50px;
  @media (max-width: 600px) {
    margin-left: 0;
  }
`;

export const ErrorMessage = styled(Typography)`
  font-family: ${selectedTheme.fonts.mainText};
  font-size: 12px;
  font-weight: 400;
  color: ${selectedTheme.colors.errorColor};
  height: 0px;
`;

export const TitleButtonsContainer = styled(Box)`
  display: flex;
  align-items: start;
`;
