import { useDragLayer } from "react-dnd";
import React from "react";
import PropTypes from "prop-types";
import {
  CustomDragLayerContainer,
  CustomDragLayerItemContainer,
} from "./CustomDragLayer.styled";
import FindingScanningSingleSection from "../Findings/NewFinding/FindingScanning/FindingScanningSectionChooser/FindingScanningSingleSection/FindingScanningSingleSection";
import TemplateQuestionCard from "../Cards/TemplateQuestionCard/TemplateQuestionCard";
import {
  FINDING_CARD_TYPE,
  SCANNING_SUBJECT_CARD_TYPE,
} from "../../constants/findingConclusionConstants";

function getItemStyles(initialOffset, currentOffset) {
  if (!initialOffset || !currentOffset) {
    return {
      display: "none",
    };
  }
  let { x, y } = currentOffset;
  const transform = `translate(${x}px, ${y}px)`;
  return {
    transform,
    WebkitTransform: transform,
  };
}
const CustomDragLayer = (props) => {
  const { isDragging, item, itemType, initialOffset, currentOffset } =
    useDragLayer((monitor) => ({
      item: monitor.getItem(),
      itemType: monitor.getItemType(),
      initialOffset: monitor.getInitialSourceClientOffset(),
      currentOffset: monitor.getSourceClientOffset(),
      isDragging: monitor.isDragging(),
    }));
  const singleData = props?.selectFunction(item);

  const renderItem = () => {
    if (itemType === FINDING_CARD_TYPE)
      return (
        <FindingScanningSingleSection
          section={{
            ...singleData,
            title: singleData?.name ? singleData?.name : singleData?.title,
          }}
          data={props?.data}
        />
      );
    else if (itemType === SCANNING_SUBJECT_CARD_TYPE)
      return (
        <TemplateQuestionCard
          ordinalNumber={item?.ordinalNumber}
          questionType={singleData?.questionType}
          title={singleData?.questionName}
          isMobile
        />
      );
  };

  if (!isDragging) {
    return null;
  }

  return (
    <CustomDragLayerContainer>
      <CustomDragLayerItemContainer
        style={getItemStyles(initialOffset, currentOffset)}
      >
        {renderItem()}
      </CustomDragLayerItemContainer>
    </CustomDragLayerContainer>
  );
};

CustomDragLayer.propTypes = {
  data: PropTypes.array,
  selectFunction: PropTypes.func,
};

export default CustomDragLayer;
