import React from "react";
import { useTranslation } from "react-i18next";
import {
  FINDINGS_PAGE,
  SCANNING_OBJECTS_PAGE,
  USERS_PAGE,
} from "../../constants/pages";
import {
  MANAGER_ROLE,
  OPERATOR_ROLE,
  ADMIN_ROLE,
} from "../../constants/rolesConstants";
import Title from "../Typography/Title/Title";
import WithPermissions from "../WithPermissions/WithPermissions";
import { DashboardContainer, TablesContainer } from "./DashboardContent.styled";
import FindingsTableCreated from "./FindingsTables/TableRecentlyCreated/FindingsTable";
import FindingsTableCompleted from "./FindingsTables/TableRecentlyCompleted/FindingsTable";
import ScanningObjectsTable from "./ScanningObjectsTable/ScanningObjectsTable";
import UsersTable from "./UsersTable/UsersTable";
import DashboardTable from "./DashboardTable/DashboardTable";

const DashboardContent = () => {
  const { t } = useTranslation();

  return (
    <DashboardContainer>
      <Title>{t("dashboard.title")}</Title>
      <TablesContainer container rowSpacing={4} columnSpacing={{ xs: 7 }}>
        <WithPermissions roles={[MANAGER_ROLE, OPERATOR_ROLE]}>
          <DashboardTable
            title={t("dashboard.tableSubtitle1")}
            href={FINDINGS_PAGE}
            tableComponent={<FindingsTableCreated />}
          />
          <DashboardTable
            title={t("dashboard.tableSubtitle2")}
            href={FINDINGS_PAGE}
            tableComponent={<FindingsTableCompleted />}
          />
          <DashboardTable
            title={t("dashboard.tableSubtitle3")}
            href={SCANNING_OBJECTS_PAGE}
            tableComponent={<ScanningObjectsTable />}
          />
        </WithPermissions>
        <WithPermissions roles={[ADMIN_ROLE]}>
          <DashboardTable
            title={t("dashboard.tableSubtitle4")}
            href={USERS_PAGE}
            tableComponent={<UsersTable />}
          />
        </WithPermissions>
      </TablesContainer>
    </DashboardContainer>
  );
};

export default DashboardContent;
