import React from "react";
import PropTypes from "prop-types";
import {
  FindingQuestionImagePickerContainer,
  FindingQuestionImagePickerLabel,
  FindingQuestionImagePickerListContainer,
  FindingQuestionSingleImagePicker,
} from "./FindingQuestionImagePicker.styled";
import { useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import useIsMobile, { getScreenWidth } from "../../../../hooks/useIsMobile";
import { useSelector } from "react-redux";
import { selectMyProfileId } from "../../../../store/selectors/loginSelectors";

const FindingQuestionImagePicker = (props) => {
  const { t } = useTranslation();
  const { isMobile } = useIsMobile();
  const userId = useSelector(selectMyProfileId);

  const hasImages = useMemo(() => props?.images.length !== 0, [props?.images]);

  const handleChangeFindingQuestionImage = (newImage, indexOfImage) => {
    // let existingIndexOfImage = findExistingIndex([...props?.images],)
    let oldImages = [...props?.images];
    if (newImage !== "") {
      oldImages[indexOfImage] = {
        edited: !oldImages[indexOfImage]?.added,
        added: oldImages[indexOfImage]?.added,
        value: {
          id: oldImages[indexOfImage]?.value?.id,
          imageUrl: newImage,
          createdAtUtc: new Date(
            new Date()?.getTime() - new Date()?.getTimezoneOffset() * 60000
          ).toISOString(),
          createdByUserId: userId,
          updatedAtUtc: new Date(
            new Date()?.getTime() - new Date()?.getTimezoneOffset() * 60000
          ).toISOString(),
          updatedByUserId: userId,
        },
      };
    } else {
      if (oldImages[indexOfImage]?.added) oldImages.splice(indexOfImage, 1);
      else
        oldImages.splice(indexOfImage, 1, {
          ...oldImages[indexOfImage],
          removed: true,
        });
    }
    props?.onChange(oldImages);
  };
  const handleAddFindingQuestionImage = (newImage) => {
    let oldImages = [...props?.images];
    oldImages.push({
      added: true,
      value: {
        imageUrl: newImage,
        createdAtUtc: new Date(
          new Date()?.getTime() - new Date()?.getTimezoneOffset() * 60000
        ).toISOString(),
        createdByUserId: userId,
        updatedAtUtc: new Date(
          new Date()?.getTime() - new Date()?.getTimezoneOffset() * 60000
        ).toISOString(),
        updatedByUserId: userId,
      },
    });
    props?.onChange(oldImages);
  };

  const imageFullSize = useMemo(() => {
    return getScreenWidth() - 2 * (8 + 16 + 26);
  }, [isMobile]);

  return (
    <FindingQuestionImagePickerContainer>
      <FindingQuestionImagePickerLabel required={props?.required}>
        {props?.questionName}
      </FindingQuestionImagePickerLabel>
      <FindingQuestionImagePickerListContainer $hasBorders={hasImages}>
        {/* ?.filter?.((singleImage) => !singleImage?.removed) */}
        {props?.images?.map?.((singleFindingQuestionImage, index) =>
          singleFindingQuestionImage?.removed ? (
            <></>
          ) : (
            <FindingQuestionSingleImagePicker
              key={index}
              smallerButtons
              width={hasImages ? 95 : 320}
              height={hasImages ? 142 : 120}
              minWidth={hasImages ? 95 : 320}
              minHeight={hasImages ? 142 : 120}
              $hasImages={hasImages}
              disabled={props?.disabled}
              image={singleFindingQuestionImage?.value?.imageUrl}
              setImage={(newImage) =>
                handleChangeFindingQuestionImage(newImage, index)
              }
              modalTitle={props?.questionName}
            />
          )
        )}
        <FindingQuestionSingleImagePicker
          $hasImages={hasImages}
          console
          key={props?.images?.length}
          smallerButtons
          disabled={props?.disabled}
          setImage={handleAddFindingQuestionImage}
          minWidth={hasImages ? 95 : isMobile ? imageFullSize : 320}
          minHeight={hasImages ? 142 : 120}
          width={hasImages ? 95 : isMobile ? imageFullSize : 320}
          height={hasImages ? 142 : 120}
          additionalDescriptionText={hasImages && t("imagePicker.moreImages")}
          descriptionText={<Trans i18nKey="imagePicker.choose" />}
        />
      </FindingQuestionImagePickerListContainer>
    </FindingQuestionImagePickerContainer>
  );
};

FindingQuestionImagePicker.propTypes = {
  images: PropTypes.array,
  onChange: PropTypes.func,
  questionName: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
};

export default FindingQuestionImagePicker;
