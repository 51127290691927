import {
  ADD_USER,
  BLOCK_USER_ERROR,
  CLEAR_USERS,
  CREATE_USER_ERROR,
  EDIT_USER_ERROR,
  GET_USERS_CLEAR,
  GET_USERS_ERROR,
  MY_USER_SET,
  SET_INVITATION_DATA,
  SET_INVITATION_MAIL,
  SET_INVITATION_TOKEN,
  SET_TOTAL_USERS,
  SET_USERS,
  SET_USERS_ERROR_MESSAGE,
  SINGLE_USER_CLEAR,
  SINGLE_USER_ERROR,
  SINGLE_USER_SET,
  UNBLOCK_USER_ERROR,
  USER_CHANGE_PASSWORD_ERROR,
} from "../../actions/user/userActionConstants";
import createReducer from "../../utils/createReducer";

const initialState = {
  users: {
    loaded: false,
    data: [],
  },
  blockedUsers: [],
  activeUsers: [],
  totalActiveUsers: 0,
  totalBlockedUsers: 0,
  totalUsers: 0,
  singleUser: {},
  myUser: {},
  errorMessage: "",
  invitation: {
    firstName: "",
    lastName: "",
    email: "",
    token: "",
  },
};

export default createReducer(
  {
    [SET_USERS]: setUsers,
    [ADD_USER]: addUser,
    [SINGLE_USER_SET]: setSingleUser,
    [SINGLE_USER_CLEAR]: clearSingleUser,
    [SET_TOTAL_USERS]: setTotalUsers,
    [CLEAR_USERS]: clearUsers,
    [GET_USERS_CLEAR]: fetchUsersClear,
    [GET_USERS_ERROR]: setErrorMessage,
    [SINGLE_USER_ERROR]: setErrorMessage,
    [CREATE_USER_ERROR]: setErrorMessage,
    [USER_CHANGE_PASSWORD_ERROR]: setErrorMessage,
    [EDIT_USER_ERROR]: setErrorMessage,
    [BLOCK_USER_ERROR]: setErrorMessage,
    [UNBLOCK_USER_ERROR]: setErrorMessage,
    [SET_USERS_ERROR_MESSAGE]: setErrorMessage,
    [MY_USER_SET]: setMyUser,
    [SET_INVITATION_DATA]: setInvitationData,
    [SET_INVITATION_MAIL]: setInvitationMail,
    [SET_INVITATION_TOKEN]: setInvitationToken,
  },
  initialState
);
function setUsers(state, { payload }) {
  return { ...state, users: payload };
}
function addUser(state, { payload }) {
  return {
    ...state,
    users: {
      ...state?.users,
      data: [...state?.users?.data, payload],
    },
  };
}
function setTotalUsers(state, { payload }) {
  return { ...state, totalUsers: payload?.count };
}
function clearUsers() {
  return initialState;
}
function fetchUsersClear(state) {
  return {
    ...state,
    totalUsers: initialState.totalUsers,
    users: initialState.users,
  };
}
function setInvitationData(state, { payload }) {
  return {
    ...state,
    invitation: {
      ...state.invitation,
      ...payload,
    },
  };
}
function setInvitationMail(state, { payload }) {
  return {
    ...state,
    invitation: {
      ...state.invitation,
      email: payload,
    },
  };
}
function setInvitationToken(state, { payload }) {
  return {
    ...state,
    invitation: {
      ...state.invitation,
      token: payload,
    },
  };
}
function setSingleUser(state, { payload }) {
  return {
    ...state,
    singleUser: payload,
  };
}
function clearSingleUser(state) {
  return {
    ...state,
    singleUser: initialState?.singleUser,
  };
}
function setMyUser(state, { payload }) {
  return {
    ...state,
    myUser: payload,
  };
}
function setErrorMessage(state, { payload }) {
  return {
    ...state,
    errorMessage: payload,
  };
}
