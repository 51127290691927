import { Box } from "@mui/material";
import styled, { css } from "styled-components";
import selectedTheme from "../../../../themes";
import TextInputField from "../../../Field/TextInputField/TextInputField";

export const DateTextField = styled(TextInputField)`
  margin: 0;
  width: 100%;
  text-align: left;

  & > div {
    width: 100%;
  }
  & > div > div {
    width: 100%;
  }
  & input {
    width: 100%;
  }
`;

export const SingleInputTitle = styled(Box)`
  font-family: ${selectedTheme.fonts.mainText};
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  font-feature-settings: "salt" on;
  text-align: left;
  margin-bottom: -4px;
  color: ${selectedTheme.colors.labelTextColor};
  ${(props) =>
    props?.$required &&
    css`
      &::after {
        content: " *" !important;
        color: ${selectedTheme.colors.errorColor} !important;
      }
    `}
`;
