import styled, { css } from "styled-components";
import { Box } from "@mui/material";
import TextInputField from "../../Field/TextInputField/TextInputField";

export const ModalContainer = styled(Box)`
  width: 460px;
  padding: 24px;
  position: relative;
  background: white;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  /* gap: 20px;
  overflow: auto; */
  z-index: ${(props) => "15" + props?.rank || "151"};
  @media (max-width: 600px) {
    width: 90%;
  }
`;

export const InputsContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  margin-top: ${(props) => (!props?.isEditing ? "24px" : 0)};
  margin-bottom: 58px;
  & * {
    text-align: left;
  }
`;

export const TextField = styled(TextInputField)`
  width: 100%;
  & > div > div {
    width: 100%;
  }
  & input {
    width: 100%;
  }
  @media (max-width: 600px) {
    width: 100%;
    & > div > div {
      width: 100%;
    }
  }
  & input {
    ${(props) =>
      props?.isMobile &&
      css`
        width: 100%;
      `};
  }
`;
