import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  CheckboxContainer,
  CheckboxIcon,
  CheckboxLabel,
  CustomTickIcon,
} from "./Checkbox.styled";

const Checkbox = ({ label, onChange, checked, disabled }) => {
  const [isChecked, setIsChecked] = useState(checked);
  const handleChange = () => {
    if (!disabled) {
      setIsChecked(!isChecked);
      onChange(!isChecked);
    }
  };

  return (
    <CheckboxContainer onClick={handleChange} disabled={disabled}>
      <CheckboxIcon checked={checked}>
        {checked && <CustomTickIcon />}
      </CheckboxIcon>
      <CheckboxLabel>{label}</CheckboxLabel>
    </CheckboxContainer>
  );
};

Checkbox.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  label: PropTypes.string,
  value: PropTypes.string,
  name: PropTypes.string,
  disabled: PropTypes.bool,
};
Checkbox.defaultProps = {
  checked: false,
};

export default Checkbox;
