import React from 'react'
// import PropTypes from 'prop-types'
import {ReactComponent as PlusIconSvg} from "../../../assets/images/svg/plus.svg"

const PlusIcon = props => {
  return (
    <PlusIconSvg {...props} />
  )
}

PlusIcon.propTypes = {}

export default PlusIcon