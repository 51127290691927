import React from "react";
import {
  FINDINGS_EDIT_PAGE,
  FINDINGS_NEW_PAGE,
  FINDINGS_PAGE,
} from "../../constants/pages";
import { Route, Switch } from "react-router";
import { MANAGER_ROLE, OPERATOR_ROLE } from "../../constants/rolesConstants";
import AuthRoute from "../../components/Router/AuthRoute";
import FindingsIndexPage from "./FindingsIndexPage/FindingsIndexPage";
import NewFindingPage from "./NewFindingPage/NewFindingPage";
import EditFindingPage from "./EditFindingPage/EditFindingPage";
// import AuthRoute from "../../components/Router/AuthRoute";

const FindingsPage = () => {
  return (
    <Switch>
      <AuthRoute
        roles={[OPERATOR_ROLE, MANAGER_ROLE]}
        path={FINDINGS_NEW_PAGE}
        component={NewFindingPage}
      />
      <Route exact path={FINDINGS_PAGE} component={FindingsIndexPage} />
      <AuthRoute
        path={FINDINGS_EDIT_PAGE}
        component={EditFindingPage}
        roles={[OPERATOR_ROLE, MANAGER_ROLE]}
      />
      {/* <AuthRoute
        roles={[MANAGER_ROLE]}
        exact
        path={EDIT_CLIENT_PAGE}
        component={EditClientPage}
      /> */}
    </Switch>
  );
};

export default FindingsPage;
