import * as Yup from "yup";
import { loginFormikConstants } from "../initialValues/loginInitialValues";

export default () =>
  Yup.object().shape({
    [loginFormikConstants.username]: Yup.string()
      // .email(("login.emailFormat"))
      .required("login.usernameRequired"),
    [loginFormikConstants.password]: Yup.string().required(
      "login.passwordRequired"
    ),
  });
