import React from "react";
// import PropTypes from 'prop-types'
import { ReactComponent as ItalicIconSvg } from "../../../assets/images/lexical/type-italic.svg";

const ItalicIcon = (props) => {
  return <ItalicIconSvg {...props} />;
};

ItalicIcon.propTypes = {};

export default ItalicIcon;
