import React from "react";
import PropTypes from "prop-types";
import {
  ActionRendererContainer,
  ActionRendererDeleteFindingTypeIconContainer,
  ActionRendererEditFindingTypeIconContainer,
  ActionRendererLicensePreviewContainer,
} from "./ActionRenderer.styled";
import EditIcon from "../../../../Icon/Icons/EditIcon";
import DeleteIcon from "../../../../Icon/Icons/DeleteIcon.js";
import {
  closeLoadingModal,
  closeModal,
  toggleImagePreviewModal,
  toggleSetDeleteModal,
  toggleSetLoadingModal,
} from "../../../../../store/actions/modal/modalActions";
import i18n from "../../../../../i18n";
import { fetchDeleteFindingType } from "../../../../../store/actions/findingTypes/findingTypesActions";
import Tooltip from "../../../../Tooltip/Tooltip";
import WithPermissions from "../../../../WithPermissions/WithPermissions";
import { MANAGER_ROLE } from "../../../../../constants/rolesConstants";
import LicensePreviewDisableIcon from "../../../../Icon/Icons/LicensePreviewDisableIcon.js";
import LicensePreviewIcon from "../../../../Icon/Icons/LicensePreviewIcon.js";
import { fetchImages } from "../../../../../store/actions/images/imagesActions.js";
import { replaceInRoute } from "../../../../../util/helpers/routeHelpers.js";
import { EDIT_FINDING_TYPE_PAGE } from "../../../../../constants/pages.js";

const ActionRenderer = (props) => {
  const handleApiResponseSuccess = (name) => {
    props?.dispatch(closeModal());
    props?.value?.refetchTable(name);
  };

  const handleApiResponseError = () => {
    props?.dispatch(closeLoadingModal());
  };

  const handleApiResponseSuccessLicense = (payload) => {
    props?.dispatch(
      toggleImagePreviewModal({
        title: i18n.t("findingTypes.modal.licensePreview"),
        image: payload?.imagesArray[0],
        rank: 1,
      })
    );
  };
  const handleShowLicensePreview = () => {
    props?.dispatch(
      fetchImages({
        images: [props?.value.licenseUrl],
        handleApiResponseSuccess: handleApiResponseSuccessLicense,
      })
    );
  };

  const handleDeleteFindingType = () => {
    props?.dispatch(toggleSetLoadingModal({ rank: 2 }));
    props?.dispatch(
      fetchDeleteFindingType({
        id: props?.value?.id,
        handleApiResponseSuccess: () =>
          handleApiResponseSuccess({
            inputValue: props?.value?.name,
            isDeleted: true,
          }),
        handleApiResponseError,
      })
    );
  };

  const handleDelete = () => {
    props?.dispatch(
      toggleSetDeleteModal({
        title: i18n.t("findingTypes.modal.delete.title"),
        description: {
          first: i18n.t("findingTypes.modal.delete.description.1"),
          second: i18n.t("findingTypes.modal.delete.description.2"),
        },
        onSubmit: handleDeleteFindingType,
        rank: 1,
      })
    );
  };

  return (
    <ActionRendererContainer>
      <WithPermissions roles={[MANAGER_ROLE]}>
        <Tooltip
          title={
            props?.value?.licenseUrl
              ? i18n.t("stuffs.tooltip.licencePreview")
              : i18n.t("stuffs.tooltip.noLicense")
          }
        >
          <ActionRendererLicensePreviewContainer
            onClick={
              props?.value?.licenseUrl ? handleShowLicensePreview : () => {}
            }
          >
            {props?.value?.licenseUrl ? (
              <LicensePreviewIcon />
            ) : (
              <LicensePreviewDisableIcon />
            )}
          </ActionRendererLicensePreviewContainer>
        </Tooltip>
      </WithPermissions>
      <Tooltip title={i18n.t("findingTypes.tooltip.changeFindingType")}>
        <ActionRendererEditFindingTypeIconContainer
          to={replaceInRoute(EDIT_FINDING_TYPE_PAGE, {
            findingTypeId: props?.value?.id,
          })}
        >
          <EditIcon />
        </ActionRendererEditFindingTypeIconContainer>
      </Tooltip>
      <WithPermissions roles={[MANAGER_ROLE]}>
        <Tooltip title={i18n.t("findingTypes.tooltip.deleteFindingType")}>
          <ActionRendererDeleteFindingTypeIconContainer onClick={handleDelete}>
            <DeleteIcon />
          </ActionRendererDeleteFindingTypeIconContainer>
        </Tooltip>
      </WithPermissions>
    </ActionRendererContainer>
  );
};

ActionRenderer.propTypes = {
  children: PropTypes.node,
  dispatch: PropTypes.func,
  value: PropTypes.object,
  rendererProps: PropTypes.any,
  refetchTable: PropTypes.func,
};

export default ActionRenderer;
