import { Box } from "@mui/material";
import styled, { css } from "styled-components";
import selectedTheme from "../../themes";
import {
  TableDataCellContainer,
  TableDataRowContainer,
} from "../Table/TableContent/TableData/TableData.styled";
import Title from "../Typography/Title/Title";
import { ExpandMore } from "@mui/icons-material";

export const EntityInputsLabel = styled(Box)`
  font-family: ${selectedTheme.fonts.mainText};
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: ${selectedTheme.colors.deviceColor};
  margin-bottom: 8px;
  margin-top: 12px;
`;
export const ManualTableContainer = styled(Box)`
  overflow: hidden;
  transition: all 0.1s;
  height: ${(props) =>
    props?.$isExpanded ? props?.$noOfRows * 72 + 32 + 20 + 34 : 34}px;
  @media (max-width: 600px) {
    & ${TableDataRowContainer} {
      flex-direction: ${(props) => (props?.$noActions ? "column" : "row")};
    }
    & ${TableDataCellContainer}:nth-child(2) {
      align-items: ${(props) => !props?.$noActions && "center"} !important;
      justify-content: ${(props) =>
        !props?.$noActions && "flex-end"} !important;
    }
  }
`;
export const TableTitle = styled(Title)`
  font-size: 24px;
`;
export const TableTitleContainer = styled(Box)`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
`;
export const ExpandIcon = styled(ExpandMore)`
  transition: all 0.1s;
  ${(props) =>
    !props?.$isExpanded &&
    css`
      transform: rotate(180deg);
    `}
`;
export const TitleButtonContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
`;
