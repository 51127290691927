import React from "react";
// import PropTypes from 'prop-types'
import { ReactComponent as LeftAlignIconSvg } from "../../../assets/images/lexical/text-left.svg";

const LeftAlignIcon = (props) => {
  return <LeftAlignIconSvg {...props} />;
};

LeftAlignIcon.propTypes = {};

export default LeftAlignIcon;
