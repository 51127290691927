import { all, call, put, takeLatest } from "@redux-saga/core/effects";
import { EXISTING_FACTORY_NUMBER } from "../../constants/backendErrorMessages";
import i18n from "../../i18n";
// import { attemptFetchCommonTemplates } from "../../request/commonTemplatesRequest";
import {
  COMMON_TEMPLATES_CREATE_FETCH,
  COMMON_TEMPLATES_DELETE_FETCH,
  COMMON_TEMPLATES_EDIT_FETCH,
  COMMON_TEMPLATES_FETCH,
  SINGLE_COMMON_TEMPLATE_FETCH,
  PREVIEW_COMMON_TEMPLATES_FETCH,
  GET_LANGUAGES_FETCH,
} from "../actions/commonTemplates/commonTemplatesActionConstants";
import {
  fetchCommonTemplatesError,
  fetchCommonTemplatesSuccess,
  fetchCreateCommonTemplateError,
  fetchCreateCommonTemplateSuccess,
  fetchDeleteCommonTemplateError,
  fetchDeleteCommonTemplateSuccess,
  fetchEditCommonTemplateError,
  fetchEditCommonTemplateSuccess,
  fetchSingleCommonTemplateError,
  fetchSingleCommonTemplateSuccess,
  setCommonTemplates,
  setCommonTemplatesTotal,
  setCommonTemplatePreview,
  fetchCommonTemplatePreviewSuccess,
  fetchCommonTemplatePreviewError,
  setSingleCommonTemplate,
  setLanguages,
  setLanguagesTotal,
  fetchLanguagesSuccess,
  fetchLanguagesError
} from "../actions/commonTemplates/commonTemplatesActions";
import { mapFetchAllFunction } from "../utils/mapFetchAllFunctions";
import { attemptFetchCategories, attemptFetchCommonTemplatePreview, attemptFetchCommonTemplates,attemptFetchCreateCommonTemplate,attemptFetchLanguages,attemptFetchSingleCommonTemplate} from "../../request/commonTemplatesRequest"
import { GET_CATEGORIES_FETCH } from "../actions/categories/categoriesActionConstant";
import { fetchCategoriesError, fetchCategoriesSuccess, setCategories, setCategoriesTotal } from "../actions/categories/categoriesAction";


function* fetchCommonTemplatePreview({ payload }) {
  try {
    const { data } = yield call(attemptFetchCommonTemplatePreview, payload?.commonTemplateId);
    yield put(setCommonTemplatePreview(data));
    if (payload?.handleApiResponseSuccess) {
      yield call(payload?.handleApiResponseSuccess);
    }
    yield put(fetchCommonTemplatePreviewSuccess());
  } catch (e) {
    console.log("greska", e);
    let errorMessage = e?.response?.data?.message;

    if (payload?.handleApiResponseError) {
      yield call(payload?.handleApiResponseError, errorMessage);
    }
    yield put(fetchCommonTemplatePreviewError(errorMessage));
  }
}

function* fetchCommonTemplates({ payload }) {
  try {
    const queryObject = mapFetchAllFunction(payload);
    if (payload?.customPayload) queryObject.set("Intersect", true);
    if (payload?.customPayload?.category)
      queryObject.set("commonTemplateCategoryId", payload?.customPayload?.category);
    if (payload?.searchValue)
      queryObject.set("scanningSubjectCaption", payload?.searchValue);
    if (payload?.customPayload?.language)
      queryObject.set("languageCultureName", payload?.customPayload?.language);
    const { data } = yield call(attemptFetchCommonTemplates, queryObject);
    yield put(
      setCommonTemplates({
        data: data?.commonTemplates,
      })
    );
    yield put(setCommonTemplatesTotal(data?.count));
    if (payload?.handleApiResponseSuccess) {
      yield call(payload?.handleApiResponseSuccess);
    }
    yield put(fetchCommonTemplatesSuccess());
  } catch (e) {
    console.log("greska", e);
    let errorMessage = e?.response?.data?.message;

    if (payload?.handleApiResponseError) {
      yield call(payload?.handleApiResponseError, errorMessage);
    }
    yield put(fetchCommonTemplatesError(errorMessage));
  }
}

function* fetchCategories({ payload }) {
  try {
    //const queryObject = mapFetchAllFunction(payload);
    const { data } = yield call(attemptFetchCategories);
    //console.log(data);
    yield put(
      setCategories({
        data: data,
      })
    );
    yield put(setCategoriesTotal(data?.count));
    if (payload?.handleApiResponseSuccess) {
      yield call(payload?.handleApiResponseSuccess);
    }
    yield put(fetchCategoriesSuccess());
  } catch (e) {
    console.log("greska", e);
    let errorMessage = e?.response?.data?.message;

    if (payload?.handleApiResponseError) {
      yield call(payload?.handleApiResponseError, errorMessage);
    }
    yield put(fetchCategoriesError(errorMessage));
  }
}

function* fetchLanguages({ payload }) {
  try {
    //const queryObject = mapFetchAllFunction(payload);
    const { data } = yield call(attemptFetchLanguages);
    yield put(
      setLanguages({
        data: data,
      })
    );
    yield put(setLanguagesTotal(data?.count));
    if (payload?.handleApiResponseSuccess) {
      yield call(payload?.handleApiResponseSuccess);
    }
    yield put(fetchLanguagesSuccess());
  } catch (e) {
    console.log("greska", e);
    let errorMessage = e?.response?.data?.message;

    if (payload?.handleApiResponseError) {
      yield call(payload?.handleApiResponseError, errorMessage);
    }
    yield put(fetchLanguagesError(errorMessage));
  }
}
function* fetchSingleCommonTemplate({ payload }) {
  try {
     const { data } = yield call(
       attemptFetchSingleCommonTemplate,
       payload?.commonTemplateId
     );
     yield put(setSingleCommonTemplate(data));
    if (payload?.handleApiResponseSuccess) {
      yield call(payload?.handleApiResponseSuccess);
    }
    yield put(fetchSingleCommonTemplateSuccess());
  } catch (e) {
    console.log("greska", e);
    let errorMessage = e?.response?.data?.message;

    if (payload?.handleApiResponseError) {
      yield call(payload?.handleApiResponseError, errorMessage);
    }
    yield put(fetchSingleCommonTemplateError(errorMessage));
  }
}
function* fetchCreateCommonTemplate({ payload }) {
  // eslint-disable-next-line no-debugger
  //debugger;
  try {
    const data = yield call(attemptFetchCreateCommonTemplate, payload?.data);
    if (payload?.handleApiResponseSuccess) {
      yield call(payload?.handleApiResponseSuccess, data?.data);
    }
    yield put(fetchCreateCommonTemplateSuccess());
  } catch (e) {
    console.log("greska", e);
    let errorMessage = e?.response?.data?.message;

    if (payload?.handleApiResponseError) {
      yield call(payload?.handleApiResponseError, errorMessage);
    }
    if (errorMessage?.trim() === EXISTING_FACTORY_NUMBER.trim()) {
      yield put(
        fetchCreateCommonTemplateError(
          i18n.t("findings.errors.factoryNumberExisting")
        )
      );
    } else yield put(fetchCreateCommonTemplateError(errorMessage));
  }
}
function* fetchEditCommonTemplate({ payload }) {
  try {
    // yield call(attemptFetchEditCommonTemplate, {
    //   commonTemplateId: payload?.commonTemplateId,
    //   data: payload?.data,
    // });
    if (payload?.handleApiResponseSuccess) {
      yield call(payload?.handleApiResponseSuccess);
    }
    yield put(fetchEditCommonTemplateSuccess());
  } catch (e) {
    console.log(e);
    let errorMessage = e?.response?.data?.message;

    if (payload?.handleApiResponseError) {
      yield call(payload?.handleApiResponseError, errorMessage);
    }
    if (errorMessage?.trim() === EXISTING_FACTORY_NUMBER.trim()) {
      yield put(
        fetchEditCommonTemplateError(
          i18n.t("findings.errors.factoryNumberExisting")
        )
      );
    } else yield put(fetchEditCommonTemplateError(errorMessage));
  }
}

function* fetchDeleteCommonTemplate({ payload }) {
  try {
    // yield call(attemptFetchDeleteCommonTemplate, {
    //   commonTemplateId: payload?.commonTemplateId,
    // });
    if (payload?.handleApiResponseSuccess) {
      yield call(payload?.handleApiResponseSuccess, { isDeleted: true });
    }
    yield put(fetchDeleteCommonTemplateSuccess());
  } catch (e) {
    console.log("greska", e);
    let errorMessage = e?.response?.data?.message;

    if (payload?.handleApiResponseError) {
      yield call(payload?.handleApiResponseError, errorMessage);
    }
    yield put(fetchDeleteCommonTemplateError(errorMessage));
  }
}

export default function* commonTemplatesSaga() {
  yield all([
    takeLatest(COMMON_TEMPLATES_FETCH, fetchCommonTemplates),
    takeLatest(SINGLE_COMMON_TEMPLATE_FETCH, fetchSingleCommonTemplate),
    takeLatest(COMMON_TEMPLATES_CREATE_FETCH, fetchCreateCommonTemplate),
    takeLatest(COMMON_TEMPLATES_EDIT_FETCH, fetchEditCommonTemplate),
    takeLatest(COMMON_TEMPLATES_DELETE_FETCH, fetchDeleteCommonTemplate),
    takeLatest(PREVIEW_COMMON_TEMPLATES_FETCH,fetchCommonTemplatePreview),
    takeLatest(GET_CATEGORIES_FETCH,fetchCategories),
    takeLatest(GET_LANGUAGES_FETCH,fetchLanguages)
  ]);
}
