import {
  getRequest,
  postRequest,
  putRequest,
  replaceInUrl,
  deleteRequest,
} from ".";
import apiEndpoints from "./apiEndpoints";

export const attemptFetchScanningObjects = (payload) =>
  getRequest(apiEndpoints.scanningObjects.getScanningObjects, payload);

export const attemptFetchSingleScanningObject = (payload) =>
  getRequest(
    replaceInUrl(apiEndpoints.scanningObjects.getSingleScanningObject, {
      scanningObjectId: payload,
    })
  );
export const attemptFetchCreateScanningObject = (payload) =>
  postRequest(apiEndpoints.scanningObjects.createScanningObject, payload);

export const attemptFetchEditScanningObject = (payload) =>
  putRequest(
    replaceInUrl(apiEndpoints.scanningObjects.getSingleScanningObject, {
      scanningObjectId: payload?.scanningObjectId,
    }),
    payload?.data
  );

export const attemptDeleteScanningObject = (payload) =>
  deleteRequest(
    replaceInUrl(apiEndpoints.scanningObjects.deleteScanningObject, {
      scanningObjectId: payload?.scanningObjectId,
    })
  );

export const attemptCopyScanningObject = (payload) =>
  postRequest(
    replaceInUrl(apiEndpoints.scanningObjects.copyScanningObject, {
      scanningObjectId: payload?.scanningObjectId,
    })
  );
