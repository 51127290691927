import React from "react";
import PropTypes from "prop-types";
import LicensesTable from "./LicensesTable/LicensesTable";

const LicensesContent = () => {
  return <LicensesTable />;
};

LicensesContent.propTypes = {
  children: PropTypes.node,
};

export default LicensesContent;
