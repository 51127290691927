import { all, call, put, takeLatest, select } from "@redux-saga/core/effects";
import {
  fetchGDriveInstructionsError,
  fetchGDriveInstructionsSuccess,
  fetchMailInstructionsError,
  fetchMailInstructionsSuccess,
} from "../actions/instructions/instructionsActions";
import {
  GET_GDRIVE_INSTRUCTIONS_FETCH,
  GET_MAIL_INSTRUCTIONS_FETCH,
} from "../actions/instructions/instructionsActionConstants";
import {
  attemptFetchGDriveInstructions,
  attemptFetchMailInstructions,
} from "../../request/instructionsRequest";
import { selectCurrentLanguage } from "../selectors/translationsSelectors";
import i18next from "i18next";

function* fetchMailInstructions({ payload }) {
  const currentLanguage = yield select(selectCurrentLanguage);
  try {
    const { data } = yield call(
      attemptFetchMailInstructions,
      {
        culture: currentLanguage,
      },
      {
        responseType: "blob",
      }
    );
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute(
      "download",
      `${i18next.t("instructions.mail.fileName")}.pdf`
    );
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
    if (payload?.handleApiResponseSuccess) {
      yield call(payload?.handleApiResponseSuccess, {
        data,
      });
    }
    yield put(fetchMailInstructionsSuccess());
  } catch (e) {
    console.log("greska", e);
    let errorMessage = e?.response?.data?.message;

    if (payload?.handleApiResponseError) {
      yield call(payload?.handleApiResponseError, errorMessage);
    }
    yield put(fetchMailInstructionsError(errorMessage));
  }
}

function* fetchGDriveInstructions({ payload }) {
  try {
    const currentLanguage = yield select(selectCurrentLanguage);

    const { data } = yield call(
      attemptFetchGDriveInstructions,
      {
        culture: currentLanguage,
      },
      {
        responseType: "blob",
      }
    );
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute(
      "download",
      `${i18next.t("instructions.gdrive.fileName")}.pdf`
    );
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
    if (payload?.handleApiResponseSuccess) {
      yield call(payload?.handleApiResponseSuccess, {
        data,
      });
    }
    yield put(fetchGDriveInstructionsSuccess());
  } catch (e) {
    console.log("greska", e);
    let errorMessage = e?.response?.data?.message;

    if (payload?.handleApiResponseError) {
      yield call(payload?.handleApiResponseError, errorMessage);
    }
    yield put(fetchGDriveInstructionsError(errorMessage));
  }
}

export default function* instructionsSaga() {
  yield all([
    takeLatest(GET_MAIL_INSTRUCTIONS_FETCH, fetchMailInstructions),
    takeLatest(GET_GDRIVE_INSTRUCTIONS_FETCH, fetchGDriveInstructions),
  ]);
}
