import { Box, Icon, Typography } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../themes";
import OrdinalNumberButton from "../../Buttons/OrdinalNumberButton/OrdinalNumberButton";
import DragIcon from "../../Icon/Icons/DragIcon";
import EditIcon from "../../Icon/Icons/EditIcon";
import DeleteIcon from "../../Icon/Icons/DeleteIcon";

export const TemplateQuestionCardContainer = styled(Box)`
  padding: 12px 0px 12px 16px;
  border-radius: 12px;
  border: 1px solid ${selectedTheme.colors.tableBorder};
  background-color: ${selectedTheme.colors.dashboardRowColor};
  min-height: 134px;
  position: relative;
  &:hover {
    background-color: ${selectedTheme.colors.orangeLight};
    border: 1px solid ${selectedTheme.colors.orange};
  }
`;
export const TemplateQuestionDropContainer = styled(Box)`
  padding-top: ${(props) => props?.$canDrop && "50px"};
  transition: 0.2s all;
  opacity: ${(props) => props?.$isDragging && "0.2"};
`;

export const DNDIcon = styled(DragIcon)``;
export const QuestionCount = styled(OrdinalNumberButton)`
  position: absolute;
  top: 12px;
  left: 16px;
`;
export const EditButtonsContainer = styled(Box)`
  position: absolute;
  top: 9px;
  right: 10px;
  display: flex;
  flex-direction: row;
  gap: 7px;
`;
export const DeleteButton = styled(DeleteIcon)`
  cursor: pointer;
  width: 20px;
  height: 20px;
  & path {
    stroke: ${selectedTheme.colors.iconColor};
  }
`;
export const EditButton = styled(EditIcon)`
  cursor: pointer;
  width: 20px;
  height: 20px;
  & path {
    stroke: ${selectedTheme.colors.iconColor};
  }
`;
export const TemplateQuestionCardTitle = styled(Typography)`
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  font-family: ${selectedTheme.fonts.mainText};
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  padding-left: 56px;
  color: ${selectedTheme.colors.pageTitleColor};
  max-height: 84px;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 600px;
  margin-bottom: 4px;
  @media (max-width: 600px) {
    padding-right: 5px;
    margin-top: 15px;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
    hyphens: auto;
  }
`;
export const TemplateQuestionCardDescription = styled(Typography)`
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  font-family: ${selectedTheme.fonts.mainText};
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  padding-left: 56px;
  color: ${selectedTheme.colors.pageTitleColor};
  max-height: 84px;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 600px;
`;
export const TemplateQuestionPreviewContainer = styled(Box)``;
export const DNDIconContainer = styled(Box)`
  position: absolute;
  bottom: 20px;
  left: 27px;
  cursor: grab;
  padding: 15px !important;
  margin: -15px -15px;
  @media (max-width: 600px) {
    top: 95px;
  }
`;

export const TemplateQuestionCardTypeIcon = styled(Icon)`
  width: 20px;
  height: 20px;
  position: relative;
  margin-left: 56px;
  margin-top: 5px;
`;
