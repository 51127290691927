import {
    CLEAR_DOCUMENTS,
    CREATE_DOCUMENT_ERROR,
    CREATE_DOCUMENT_FETCH,
    CREATE_DOCUMENT_SUCCESS,
    DELETE_DOCUMENT_ERROR,
    DELETE_DOCUMENT_FETCH,
    DELETE_DOCUMENT_SUCCESS,
    EDIT_DOCUMENT_ERROR,
    EDIT_DOCUMENT_FETCH,
    EDIT_DOCUMENT_SUCCESS,
    GET_DOCUMENTS_CLEAR,
    GET_DOCUMENTS_ERROR,
    GET_DOCUMENTS_FETCH,
    GET_DOCUMENTS_SUCCESS,
    SET_DOCUMENT,
    SET_DOCUMENTS,
    SET_DOCUMENT_ERROR,
    SET_TOTAL_DOCUMENTS,
    SINGLE_DOCUMENT_ERROR,
    SINGLE_DOCUMENT_FETCH,
    SINGLE_DOCUMENT_SET,
    SINGLE_DOCUMENT_SUCCESS,
  } from "./documentsActionConstants";
  
  export const setDocument = (payload) => ({
    type: SET_DOCUMENT,
    payload,
  });
  
  export const setDocumentError = (payload) => ({
    type: SET_DOCUMENT_ERROR,
    payload,
  });
  
  export const fetchDocuments = (payload) => ({
    type: GET_DOCUMENTS_FETCH,
    payload,
  });
  export const fetchDocumentsSuccess = (payload) => ({
    type: GET_DOCUMENTS_SUCCESS,
    payload,
  });
  export const fetchDocumentsError = (payload) => ({
    type: GET_DOCUMENTS_ERROR,
    payload,
  });
  
  export const fetchDocumentClear = () => ({
    type: GET_DOCUMENTS_CLEAR,
  });
  
  export const fetchCreateDocument = (payload) => ({
    type: CREATE_DOCUMENT_FETCH,
    payload,
  });
  export const fetchCreateDocumentSuccess = (payload) => ({
    type: CREATE_DOCUMENT_SUCCESS,
    payload,
  });
  export const fetchCreateDocumentError = (payload) => ({
    type: CREATE_DOCUMENT_ERROR,
    payload,
  });
  
  export const fetchEditDocument = (payload) => ({
    type: EDIT_DOCUMENT_FETCH,
    payload,
  });
  export const fetchEditDocumentSuccess = (payload) => ({
    type: EDIT_DOCUMENT_SUCCESS,
    payload,
  });
  export const fetchEditDocumentError = (payload) => ({
    type: EDIT_DOCUMENT_ERROR,
    payload,
  });
  
  export const fetchDeleteDocument = (payload) => ({
    type: DELETE_DOCUMENT_FETCH,
    payload,
  });
  export const fetchDeleteDocumentSuccess = (payload) => ({
    type: DELETE_DOCUMENT_SUCCESS,
    payload,
  });
  export const fetchDeleteDocumentError = (payload) => ({
    type: DELETE_DOCUMENT_ERROR,
    payload,
  });
  
  export const fetchSingleDocument = (payload) => ({
    type: SINGLE_DOCUMENT_FETCH,
    payload,
  });
  export const fetchSingleDocumentSuccess = (payload) => ({
    type: SINGLE_DOCUMENT_SUCCESS,
    payload,
  });
  export const fetchSingleDocumentError = (payload) => ({
    type: SINGLE_DOCUMENT_ERROR,
    payload,
  });
  
  export const setSingleDocument = (payload) => ({
    type: SINGLE_DOCUMENT_SET,
    payload,
  });
  export const setDocuments = (payload) => ({
    type: SET_DOCUMENTS,
    payload,
  });
  export const setTotalDocuments = (payload) => ({
    type: SET_TOTAL_DOCUMENTS,
    payload,
  });
  export const clearDocuments = () => ({
    type: CLEAR_DOCUMENTS,
  });
  