import React from "react";
import { ReactComponent as TaskIcon } from "../assets/images/svg/task-square.svg";
import { ReactComponent as UsersIcon } from "../assets/images/svg/users.svg";
import HomeIcon from "../components/Icon/Icons/HomeIcon";
import DirectBoxIcon from "../components/Icon/Icons/DirectBoxIcon";
import {
  CLIENTS_PAGE,
  STANDARDS_PAGE,
  USERS_PAGE,
  MEASURING_INSTRUMENTS_PAGE,
  SCANNING_OBJECTS_PAGE,
  STUFFS_PAGE,
  RESEARCH_PAGE,
  FINDINGS_PAGE,
  FINDING_TYPES_PAGE,
  COMPANIES_PAGE,
  DOCUMENT_TYPES_PAGE,
  DOCUMENTS_PAGE,
} from "./pages";
import {
  ADMIN_ROLE,
  MANAGER_ROLE,
  OPERATOR_ROLE,
  SUPERADMIN_ROLE,
} from "./rolesConstants";
import MeasuringInstrumentsSidebarIcon from "../components/Icon/Icons/MeasuringInstrumentsSiderbarIcon";
import ScanningSubjectIcon from "../components/Icon/Icons/ScanningSubjectsIcon";
import ClientsIcon from "../components/Icon/Icons/ClientsIcon";
import PersonelIcon from "../components/Icon/Icons/PersonelIcon";
import StaffIcon from "../components/Icon/Icons/StaffIcon";
import FindingIcon from "../components/Icon/Icons/FindingIcon";
import FindingTypesIcon from "../components/Icon/Icons/FindingTypesIcon";
import CompanyIcon from "../components/Icon/Icons/CompanyIcon";
import DocumentTypeIcon from "../components/Icon/Icons/DocumentTypeIcon";
import DocumentIcon from "../components/Icon/Icons/DocumentIcon";

export const SIDEBAR_NAVIGATION = [
  {
    title: "sidebarNavigation.home",
    icon: <HomeIcon />,
    roles: [OPERATOR_ROLE, MANAGER_ROLE, ADMIN_ROLE],
    route: "/home",
  },
  {
    title: "sidebarNavigation.scanning",
    icon: <DirectBoxIcon />,
    route: RESEARCH_PAGE,
    roles: [OPERATOR_ROLE, MANAGER_ROLE],
    subnavigationRoutes: [
      {
        title: "sidebarNavigation.findingTypes",
        route: FINDING_TYPES_PAGE,
        icon: <FindingTypesIcon />,
      },
      {
        title: "sidebarNavigation.standards",
        route: STANDARDS_PAGE,
        icon: <TaskIcon />,
      },
      {
        title: "sidebarNavigation.scanningInstruments",
        icon: <MeasuringInstrumentsSidebarIcon />,
        route: MEASURING_INSTRUMENTS_PAGE,
      },
      {
        title: "sidebarNavigation.scanningObjects",
        route: SCANNING_OBJECTS_PAGE,
        icon: <ScanningSubjectIcon />,
      },
      {
        title: "sidebarNavigation.documentTypes",
        route: DOCUMENT_TYPES_PAGE,
        icon: <DocumentTypeIcon />,
      },
    ],
  },
  {
    title: "sidebarNavigation.findingsPlural",
    icon: <FindingIcon />,
    route: FINDINGS_PAGE,
    roles: [OPERATOR_ROLE, MANAGER_ROLE],
  },
  {
    title: "sidebarNavigation.clients",
    icon: <ClientsIcon />,
    route: CLIENTS_PAGE,
    roles: [OPERATOR_ROLE, MANAGER_ROLE],
  },
  {
    title: "sidebarNavigation.personal",
    icon: <PersonelIcon />,
    // route: USERS_PAGE,
    roles: [ADMIN_ROLE, OPERATOR_ROLE, MANAGER_ROLE],
    subnavigationRoutes: [
      {
        title: "sidebarNavigation.users",
        route: USERS_PAGE,
        roles: [ADMIN_ROLE],
        icon: <UsersIcon />,
      },
      {
        title: "sidebarNavigation.staff",
        route: STUFFS_PAGE,
        roles: [OPERATOR_ROLE, MANAGER_ROLE],
        icon: <StaffIcon />,
      },
      // {
      //   title: "sidebarNavigation.privilege",
      //   icon: <PersonalIcon />,
      //   roles: [ADMIN_ROLE],
      //   // route: "/privilegije"
      // },
    ],
  },
  {
    title: "sidebarNavigation.documents",
    roles: [OPERATOR_ROLE, MANAGER_ROLE],
    route: DOCUMENTS_PAGE,
    icon: <DocumentIcon />,
    fill: true,
  },
  {
    title: "sidebarNavigation.companies",
    roles: [SUPERADMIN_ROLE],
    route: COMPANIES_PAGE,
    icon: <CompanyIcon />,
  },
];
