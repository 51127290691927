import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { $getSelection, $isRangeSelection } from "lexical";
import { $patchStyleText } from "@lexical/selection";
import { fontSizes } from "../../../../../constants/richTextConstants";
import { FontSizeContainer } from "./FontDropdown.styled";
import Select from "../../../../Select/Select";

const FontDropdown = (props) => {
  const handleClick = useCallback(
    (event) => {
      let option = event.target.value;
      props?.editor.update(() => {
        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
          $patchStyleText(selection, {
            [props?.styleProperty]: option,
          });
        }
      });
    },
    [props?.editor, props?.styleProperty]
  );
  return (
    <FontSizeContainer>
      <Select
        hideDefaultValue
        value={props?.value}
        options={fontSizes.map?.((singleFontSizeOption) => ({
          id: singleFontSizeOption,
          label: singleFontSizeOption?.replace("pt", ""),
        }))}
        onChange={handleClick}
        placeholder={fontSizes?.[0]?.replace("pt", "")}
      />
    </FontSizeContainer>
  );
};

FontDropdown.propTypes = {
  editor: PropTypes.any,
  styleProperty: PropTypes.string,
  value: PropTypes.any,
};

export default FontDropdown;
