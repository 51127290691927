import * as Yup from "yup";
import { newFindingTypeFormikConstants } from "../initialValues/newFindingTypeInitialValues";

export default () =>
  Yup.object().shape({
    [newFindingTypeFormikConstants.name]: Yup.string()
      .trim()
      .max(255, "findingTypes.modal.nameNoOfChars")
      .required("findingTypes.modal.nameEmpty"),
    [newFindingTypeFormikConstants.caption]: Yup.string()
      .trim()
      .max(255, "findingTypes.modal.captionNoOfChars")
      .required("findingTypes.modal.captionEmpty"),
    [newFindingTypeFormikConstants.subcaption]: Yup.string()
      .trim()
      .max(255, "findingTypes.modal.subcaptionNoOfChars")
      .required("findingTypes.modal.subcaptionEmpty"),
    [newFindingTypeFormikConstants.authorization]: Yup.bool(),
    [newFindingTypeFormikConstants.companyLicense]: Yup.bool(),
    [newFindingTypeFormikConstants.standards]: Yup.bool(),
    [newFindingTypeFormikConstants.instruments]: Yup.bool(),
    [newFindingTypeFormikConstants.instrumentsCalibration]: Yup.bool(),
    [newFindingTypeFormikConstants.summary]: Yup.bool(),
    [newFindingTypeFormikConstants.schema]: Yup.bool(),
    [newFindingTypeFormikConstants.implementation]: Yup.bool(),
    [newFindingTypeFormikConstants.attendees]: Yup.bool(),
    [newFindingTypeFormikConstants.examinators]: Yup.bool(),
    [newFindingTypeFormikConstants.examinatorsSignatures]: Yup.bool(),
    [newFindingTypeFormikConstants.personInCharge]: Yup.string()
      .trim()
      .required("findingTypes.modal.personInChargeEmpty")
  });
