import React from 'react'
// import PropTypes from 'prop-types'
import {ReactComponent as SectionsIconSvg} from "../../../assets/images/svg/sections.svg"

const SectionsIcon = props => {
  return (
    <SectionsIconSvg {...props} />
  )
}

SectionsIcon.propTypes = {}

export default SectionsIcon