import { Box } from "@mui/material";
import styled from "styled-components";
import Icon from "../../../../../../Icon/Icon";

export const DeleteRendererContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 17px;
`;
export const DeleteRendererIconContainer = styled(Icon)`
  cursor: pointer;
`;
