import { Box, TableBody } from "@mui/material";
import styled from "styled-components";
import { FindingItemTableCellContainer } from "../FindingItemTableCell/FindingItemTableCell.styled";

export const FindingItemTableHeaderContainer = styled(TableBody)`
  /* display: flex; */
  & ${FindingItemTableCellContainer} {
    align-items: center;
    padding: 12px;
    & textarea {
      text-align: center;
    }
  }
`;
export const FindingItemTableColumnGroupContainer = styled(Box)`
  display: flex;
  align-items: center;
  flex-direction: column;
  flex: ${(props) => props?.$groupFlex};
  flex-shrink: 0;
  & ${FindingItemTableCellContainer} {
    /* width: 100%; */
  }
`;
export const FindingItemTableColumnGroupFlexContainer = styled(Box)`
  display: flex;
  align-items: center;
  flex-direction: column;
  flex: 1;
  width: 100%;
`;
export const FindingItemTableColumnSubtitlesContainer = styled(Box)`
  display: flex;
  width: 100%;
  flex: 1;
  & ${FindingItemTableCellContainer} {
    height: 100%;
  }
`;
export const FindingItemTableCellFlexContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  flex: 1;

  & ${FindingItemTableCellContainer} {
    height: 100%;
  }
`;
