import React from "react";
// import PropTypes from 'prop-types'
import { ReactComponent as ClipboardIconSvg } from "../../../assets/images/svg/clipboard.svg";

const ClipboardIcon = (props) => {
  return <ClipboardIconSvg {...props} />;
};

ClipboardIcon.propTypes = {};

export default ClipboardIcon;
