import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
  AddInstrumentContainer,
  InstrumentInputsLabel,
} from "./TemplateMeasuringInstrumentsEditor.styled";
import TemplateMeasuringInstrumentsTable from "./TemplateMeasuringInstrumentsTable/TemplateMeasuringInstrumentsTable";
import TemplateMeasuringInstrumentsPicker from "./TemplateMeasuringInstrumentsPicker/TemplateMeasuringInstrumentsPicker";
import { TableTitle } from "../ActiveTemplateEditor.styled";
import { useDispatch, useSelector } from "react-redux";
import { selectSingleScanningObjectMeasuringInstruments } from "../../../../store/selectors/scanningObjectsSelectors";
import {
  addScanningObjectInstruments,
  deleteScanningObjectInstruments,
  setScanningObjectsChanged,
} from "../../../../store/actions/scanningObjects/scanningObjectsActions";

const TemplateMeasuringInstrumentsEditor = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const selectedMeasuringInstruments = useSelector(
    selectSingleScanningObjectMeasuringInstruments
  );

  const handleAddInstrument = (instrument) => {
    if (
      selectedMeasuringInstruments?.find?.(
        (singleInstrument) => singleInstrument?.id === instrument?.id
      )
    )
      return;
    dispatch(addScanningObjectInstruments(instrument));
    dispatch(setScanningObjectsChanged(true));
  };

  const handleRemoveInstrument = useCallback(
    (instrument) => {
      if (
        selectedMeasuringInstruments?.find?.(
          (singleInstrument) => singleInstrument?.id === instrument?.id
        )
      ) {
        dispatch(deleteScanningObjectInstruments(instrument?.id));
        dispatch(setScanningObjectsChanged(true));
      }
    },
    [selectedMeasuringInstruments]
  );

  return (
    <AddInstrumentContainer>
      <TableTitle>{t("measuringInstruments.title")}</TableTitle>
      {!props?.isReadOnly && (
        <>
          <InstrumentInputsLabel>
            {t(
              "scanningObjects.newScanningObject.templateEditor.addInstrument"
            )}
          </InstrumentInputsLabel>
          <TemplateMeasuringInstrumentsPicker
            handleAddInstrument={handleAddInstrument}
            data={selectedMeasuringInstruments}
          />
        </>
      )}

      <TemplateMeasuringInstrumentsTable
        custom
        data={selectedMeasuringInstruments}
        handleRemoveInstrument={handleRemoveInstrument}
        isReadOnly={props?.isReadOnly}
      />
    </AddInstrumentContainer>
  );
};

TemplateMeasuringInstrumentsEditor.displayName =
  "TemplateMeasuringInstrumentsEditor";

TemplateMeasuringInstrumentsEditor.propTypes = {
  children: PropTypes.node,
  selectedMeasuringInstruments: PropTypes.array,
  setSelectedMeasuringInstruments: PropTypes.func,
  isReadOnly: PropTypes.bool,
};
TemplateMeasuringInstrumentsEditor.defaultProps = {
  selectedMeasuringInstruments: [],
};

export default TemplateMeasuringInstrumentsEditor;
