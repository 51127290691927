import { Box } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../themes";

export const InviteTimeoutPageContainer = styled(Box)`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    gap: 48px;
`
export const InviteTimeoutPageTitle = styled(Box)`
    font-family: ${selectedTheme.fonts.mainText};
    font-size: 22px;
    font-weight: bold;
`
export const InviteTimeoutPageDescription = styled(Box)`
    font-family: ${selectedTheme.fonts.mainText};
    font-size: 16px;
`