import { createGlobalStyle } from "styled-components";
import selectedTheme from "../../themes";
// import selectedTheme from "../../themes";
// import { hexToRGB } from "../../util/helpers/colorHelper";

const GlobalStyle = createGlobalStyle`
  .react-datepicker__day--selected {
    background-color: ${selectedTheme.colors.orange};
  }
  .CircularProgressbar-text {
    font-family: ${selectedTheme.fonts.mainText};
    color: ${selectedTheme.colors.pageTitleColor};
    fill: ${selectedTheme.colors.pageTitleColor} !important;
    font-size: 18px !important;
  }
  .CircularProgressbar-path {
    stroke: ${selectedTheme.colors.pageTitleColor} !important;
  }
  #TABLE_TOAST_CONTAINER_ID {
    & > div {
        position: absolute;
        top: -12px;
        right: -12px;
        width: 362px;
        height: fit-content;
        min-height: 93px;
        border-radius: 12px;
        & > div {
            border-radius: 12px;
            box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.18);
            min-height: 93px;
            padding: 0;
            & > div {
                border-radius: 12px;
                box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.18);
                min-height: 93px;
                padding: 0;
            }
        }
    }
  }
  #APPLICATION_TOAST_CONTAINER_ID {
    & > div {
        position: fixed;
        top: 16px;
        right: 16px;
        width: 362px;
        height: fit-content;
        min-height: 93px;
        border-radius: 12px;
        & > div {
            border-radius: 12px;
            box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.18);
            min-height: 93px;
            padding: 0;
            & > div {
                border-radius: 12px;
                box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.18);
                min-height: 93px;
                padding: 0;
            }
        }
    }
  }
  // To dissappear upper and right arrows on the right of input
  // ******
  input[type=number]::-webkit-inner-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
  }
  input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
  }
  .bold {
    font-weight: 700;
  }
  .italic {
    font-style: italic;
  }
  .underline {
    text-decoration: underline;
  }
  .TableCellResizer__resizer {
  position: absolute;
}

  // ******
`;

export default GlobalStyle;
