import { Box } from "@mui/material";
import styled, { css } from "styled-components";
import ImagePicker from "../../ImagePicker/ImagePicker";

export const GeneralSettingsContainer = styled(Box)`
  display: flex;
  gap: 110px;
  margin-bottom: 40px;
  @media (max-width: 600px) {
    flex-direction: column;
    gap: 0;
    padding-bottom: 32px;
  }
`;

export const LeftContainer = styled(Box)``;

export const RightContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 58px;
  margin-top: 30px;
`;
export const LogoImagePicker = styled(ImagePicker)`
  ${(props) =>
    props?.image &&
    css`
      & form {
        width: 180px;
        height: 120px !important;
        max-height: 120px;
      }
      & div {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1;
      }
      & img {
        width: 50px;
        height: auto;
        max-height: 50px;
      }
    `}
`;
export const SignatureImagePicker = styled(ImagePicker)`
  ${(props) =>
    props?.image &&
    css`
      & form {
        width: 180px;
        height: 120px !important;
        max-height: 120px;
      }
      & div {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1;
      }
    `}
`;

export const StampImagePicker = styled(ImagePicker)`
  ${(props) =>
    props?.image &&
    css`
      & form {
        width: 180px;
        height: 120px !important;
        max-height: 120px;
      }
      & div {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1;
      }
    `}
`;

export const MiniRightContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 40px;
  @media (max-width: 600px) {
    flex-direction: column;
  }
`;
export const LicenseImagePicker = styled(ImagePicker)``;
