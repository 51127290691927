import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { selectIsLoadingByActionTypes } from "../../../store/selectors/loadingSelectors";
import CircularLoader from "../CircularLoader/CircularLoader";

const IsLoadingComponent = (props) => {
  const isLoading = useSelector(
    selectIsLoadingByActionTypes(props?.isLoadingScopes)
  );
  if (
    isLoading ||
    isLoading === null ||
    isLoading === undefined ||
    props?.isLoading
  )
    return <CircularLoader isStatic className={props?.className} />;
  return props?.children;
};

IsLoadingComponent.propTypes = {
  isLoadingScopes: PropTypes.array,
  children: PropTypes.node,
  className: PropTypes.string,
  isLoading: PropTypes.bool,
};

IsLoadingComponent.defaultProps = {
  isLoadingScopes: [],
  isLoading: false,
};

export default IsLoadingComponent;
