import React from "react";
import PropTypes from "prop-types";
import { Switch } from "react-router-dom/cjs/react-router-dom.min";
import AuthRoute from "../../components/Router/AuthRoute";
import { MANAGER_ROLE, OPERATOR_ROLE } from "../../constants/rolesConstants";
import {
  DOCUMENTS_EDIT_PAGE,
  DOCUMENTS_NEW_PAGE,
  DOCUMENTS_PAGE,
} from "../../constants/pages";
import DocumentsIndexPage from "./DocumentsIndexPage/DocumentsIndexPage";
import NewDocumentPage from "./NewDocumentPage/NewDocumentPage";
import EditDocumentPage from "./EditDocumentPage/EditDocumentPage";

const DocumentsPage = () => {
  return (
    <Switch>
      <AuthRoute
        roles={[OPERATOR_ROLE, MANAGER_ROLE]}
        path={DOCUMENTS_NEW_PAGE}
        component={NewDocumentPage}
      />
      <AuthRoute
        roles={[OPERATOR_ROLE, MANAGER_ROLE]}
        path={DOCUMENTS_EDIT_PAGE}
        component={EditDocumentPage}
      />
      <AuthRoute
        roles={[OPERATOR_ROLE, MANAGER_ROLE]}
        path={DOCUMENTS_PAGE}
        component={DocumentsIndexPage}
      />
    </Switch>
  );
};

DocumentsPage.propTypes = {
  children: PropTypes.node,
};

export default DocumentsPage;
