import { Table, TableBody } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../../../themes";
import { hexToRGB } from "../../../../../util/helpers/colorHelper";

export const FindingItemTableContainer = styled(Table)`
  /* display: flex; */
  /* flex-direction: column; */
  overflow-wrap: anywhere;
  width: ${(props) => !props?.$manuallyMobile && "calc(100vw - 800px)"};
  max-width: ${(props) => !props?.$manuallyMobile && "calc(100vw - 800px)"};
  /* max-inline-size: calc(100vw - 700px); */
  overflow: visible;
  gap: 0;
  border-collapse: separate;
  border-radius: 12px;
  box-shadow: 0px 1px 2px 0px
      ${hexToRGB(selectedTheme.colors.shadowColor, 0.06)},
    0px 1px 3px 0px ${hexToRGB(selectedTheme.colors.shadowColor, 0.1)};
`;
export const FindingItemTableBody = styled(TableBody)``;
