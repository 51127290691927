import { ERROR, FETCH, SUCCESS } from "../actions/actionHelpers";
import { openOnlinePopover, removeLoader, setIsOnline } from "../actions/app/appActions";
import { closeModal } from "../actions/modal/modalActions";

const promiseType = FETCH;

export default ({ dispatch }) =>
  (next) =>
  (action) => {
    try {
      let isOnline = navigator.onLine;
      if (action.type.includes(promiseType) && !isOnline) {
        dispatch(setIsOnline(false));
        dispatch(openOnlinePopover(true));
        dispatch(closeModal());
        return;
      }

      if (action.type.includes(SUCCESS) || action.type.includes(ERROR)) {
        // const actionType = action.type.includes(SUCCESS)
        //   ? action.type.replace(SUCCESS, "[LOADING]")
        //   : action.type.replace(ERROR, "[LOADING]");

        dispatch(removeLoader(action.type));
        return next(action);
      }
    } catch (e) {
      console.log(e);
      console.log("wrong action", action);
    }
    next(action);
  };
