import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import Title from "../Typography/Title/Title";
import { StandardsContentContainer } from "./StandardsContent.styled";
import StandardsTable from "./StandardsTable/StandardsTable";
import { useDispatch } from "react-redux";
import { fetchFindingTypes } from "../../store/actions/findingTypes/findingTypesActions";

const StandardsContent = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(fetchFindingTypes());
  }, []);

  return (
    <StandardsContentContainer>
      <Title>{t("standards.title")}</Title>
      <StandardsTable />
    </StandardsContentContainer>
  );
};

export default StandardsContent;
