import React from "react";
// import PropTypes from 'prop-types'
import { ReactComponent as ErrorIconSvg } from "../../../assets/images/svg/error.svg";

const ErrorIcon = (props) => {
  return <ErrorIconSvg {...props} />;
};

ErrorIcon.propTypes = {};

export default ErrorIcon;
