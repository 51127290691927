import { Box } from "@mui/material";
import styled, { css } from "styled-components";
import selectedTheme from "../../../themes";
import { CancelButton, SubmitButton } from "../Modal.styled";

export const ConfirmModalContainer = styled(Box)`
  background-color: white;
  padding: 24px;
  border-radius: 12px;
  position: relative;
  width: 460px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  @media (max-width: 600px) {
    width: calc(100% - 16px);
  }
`;
export const ConfirmModalDescription = styled(Box)`
  font-family: ${selectedTheme.fonts.mainText};
  font-weight: 300;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: -0.02em;
  font-feature-settings: "salt" on;
  color: ${selectedTheme.colors.titleColor};
  text-align: left;
  padding-left: ${(props) => !props?.$noPadding && "24px"};
  /* max-width: 297px; */
  @media (max-width: 600px) {
    padding: 0;
  }
`;
export const CancelButtonContainer = styled(CancelButton)`
  font-size: 16px;
  ${(props) =>
    props?.$variant === 1
      ? css`
          border: 0;
          color: ${selectedTheme.colors.errorColor};
          font-weight: 600;
        `
      : css`
          height: 48px;
        `}
`;
export const ConfirmButtonContainer = styled(SubmitButton)`
  border-radius: 8px;
  background-color: ${selectedTheme.colors.orange};
  font-size: 16px;
`;
