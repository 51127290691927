import { Box } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../themes";
import { ReactComponent as NotOnlineImage } from "../../../assets/images/svg/online-image.svg";

export const NotOnlinePopoverContainer = styled(Box)`
  border-radius: 12px;
  padding: 24px 53px;
  border: 1px solid ${selectedTheme.colors.inputBorder};
  background: ${selectedTheme.colors.white};
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 24px;
`;
export const NotOnlinePopoverImageContainer = styled(Box)`
  padding: 22px;
  padding-bottom: 0;
`;
export const NotOnlinePopoverImage = styled(NotOnlineImage)``;
export const NotOnlineTitle = styled(Box)`
  white-space: nowrap;
  color: ${selectedTheme.colors.titleColor};
  font-family: ${selectedTheme.fonts.mainText};
  font-feature-settings: "salt" on;
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: -0.36px;
`;
export const NotOnlineDescription = styled(Box)`
  color: ${selectedTheme.colors.titleColor};
  font-family: ${selectedTheme.fonts.mainText};
  font-feature-settings: "salt" on;
  font-size: 16px;
  font-weight: 300;
  line-height: 21px;
  max-width: 262px;
  letter-spacing: -0.32px;
`;
export const CloseButton = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  flex: 1;
  height: 48px;
  width: 200px;
  min-height: 48px;
  background: white;
  border: 1px solid ${selectedTheme.colors.inputBorder};
  font-family: ${selectedTheme.fonts.mainText};
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  font-feature-settings: "salt" on;
  color: ${selectedTheme.colors.tableColumnText};
  border-radius: 6px;
`;
