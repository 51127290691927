import { Box } from "@mui/material";
import styled from "styled-components";
import Icon from "../../../../../Icon/Icon";
import selectedTheme from "../../../../../../themes";

export const ActionRendererContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 17px;
  @media (max-width: 600px) {
    position: absolute;
    top: 0px;
    right: 15px;
    height: 100%;
    align-items: center;
  }
`;
export const ActionRendererEditStandardIconContainer = styled(Icon)`
  cursor: pointer;
`;

export const ActionRendererDeleteStandardIconContainer = styled(Icon)`
  cursor: pointer;
`;

export const FieldText = styled(Box)`
  font-family: ${selectedTheme.fonts.mainText};
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  display: inline;
  color: ${selectedTheme.colors.titleColor};
  margin-left: 5px;
`;
