import { Box, MenuItem, Select, Typography } from "@mui/material";
import styled from "styled-components";
import { ReactComponent as DropdownImage } from "../../../../../assets/images/svg/dropdown-icon.svg";
import selectedTheme from "../../../../../themes";

export const SelectOption = styled(MenuItem)`
  font-family: ${selectedTheme.fonts.mainText};
  color: ${selectedTheme.colors.shadowColor};
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin: 0px 0px 0px 6px;
  border-radius: 6px 0 0 6px;

  &:nth-child(1) {
    border-bottom: 1px solid ${selectedTheme.colors.tableBorder};
  }
  &:nth-child(2) {
    margin-top: 2px;
  }
`;

export const LabelContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 6px;
  width: 100%;
`;
export const FieldLabel = styled(Typography)`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: ${selectedTheme.colors.labelTextColor};
  font-family: ${selectedTheme.fonts.mainText};
`;

export const DropdownFilterComponentContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 6px;
  width: 100%;
  height: 70px;
`;

// color:
//   props?.value !== ""
//     ? selectedTheme.colors.pageTitleColor
//     : selectedTheme.colors.dropdownIcon,
export const SelectField = styled(Select)`
  width: 100%;
  max-width: 321px;
  height: 48px;
  border-radius: 8px;
  padding-right: 20px;
  font-family: ${selectedTheme.fonts.mainText};
  font-weight: 400;
  font-size: 16px;
  color: ${(props) =>
    props?.value !== ""
      ? selectedTheme.colors.pageTitleColor
      : selectedTheme.colors.dropdownIcon};
  & fieldset {
    border-color: ${selectedTheme.colors.inputBorderColor};
    border-width: 1px;
  }
  &.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${selectedTheme.colors.inputBorderColor};
  }
  &.Mui-error {
    border-color: ${selectedTheme.colors.inputBorderColor};
  }
  @media (max-width: 600px) {
    width: 100%;
  }
  & ul {
    overflow: "auto";
  }
`;

export const DropdownIcon = styled(DropdownImage)`
  position: absolute;
  right: 24px;
  pointer-events: none;
`;
