import React from "react";
import { useSelector } from "react-redux";
import { selectJWTToken } from "../../store/selectors/loginSelectors";
import { Redirect } from "react-router-dom";
import { LOGIN_PAGE } from "../../constants/pages";

const NotFoundPage = () => {
  const jwtToken = useSelector(selectJWTToken);

  if (!jwtToken?.JwtToken) return <Redirect to={LOGIN_PAGE} />;

  return <div>Not found page</div>;
};

NotFoundPage.propTypes = {};

export default NotFoundPage;
