import React from "react";
import PropTypes from "prop-types";
import Title from "../Typography/Title/Title";
import { useTranslation } from "react-i18next";
import { ClientsContentContainer } from "./ClientsContent.styled";
import ClientsTable from "./ClientsTable/ClientsTable";

const ClientsContent = () => {
  const { t } = useTranslation();
  return (
    <ClientsContentContainer>
      <Title>{t("clients.title")}</Title>
      <ClientsTable />
    </ClientsContentContainer>
  );
};

ClientsContent.propTypes = {
  children: PropTypes.node,
};

export default ClientsContent;
