import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $generateHtmlFromNodes } from "@lexical/html";
// import { BLUR_COMMAND, COMMAND_PRIORITY_LOW } from "lexical";
// import { useSelector } from "react-redux";
// import { selectModalValues } from "../../../../store/selectors/modalSelectors";
// import { useMemo } from "react";

const OnChangePlugin = (props) => {
  // const modals = useSelector(selectModalValues);
  const [editor] = useLexicalComposerContext();

  // const isModalShown = useMemo(() => {
  //   const properties = Object.getOwnPropertyNames(modals);
  //   let isModalShownLocal = false;
  //   properties.forEach((singleModal) => {
  //     if (modals[singleModal] === true) isModalShownLocal = true;
  //   });
  //   return isModalShownLocal;
  // }, [modals]);


  useEffect(() => {
    return editor.registerUpdateListener(({ editorState }) => {
      editorState.read(() => {
        const htmls = $generateHtmlFromNodes(editor);
        let newHtml = htmls?.replace(/(text-indent: )([0-9]+)([a-zA-Z]+)(;)/g, "");
        if (htmls?.includes("text-indent")) {
          props?.onChange(newHtml?.replace(/(<p><br><\/p>)/g, ""));
          props?.immediateChange(newHtml?.replace(/(<p><br><\/p>)/g, ""));
        }
        props?.onChange(newHtml?.replace(/(<p><br><\/p>)/g, ""));
      });
    });
  });

  useEffect(() => {
    if (props?.readOnly === editor.isEditable()) {
      editor.setEditable(!props?.readOnly);
    }
  });

  // useEffect(() => {
  //   editor.registerCommand(
  //       BLUR_COMMAND,
  //       props?.customOnBlurFunction,
  //       COMMAND_PRIORITY_LOW
  //     );
  // },[isModalShown]);

  return <div></div>;
};

OnChangePlugin.propTypes = {
  value: PropTypes.any,
  innerValue: PropTypes.any,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  readOnly: PropTypes.bool,
  customOnBlurFunction: PropTypes.func,
  immediateChange: PropTypes.func
};

export default OnChangePlugin;
