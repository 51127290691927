import {
  ABOUT_ROUTE_SELECTED,
  ADD_LOADER,
  IS_ONLINE_SET,
  OPEN_ONLINE_POPOVER,
  REMOVE_LOADER,
} from "./appActionConstants";

export const addLoader = (payload) => ({
  type: ADD_LOADER,
  payload,
});
export const removeLoader = (payload) => ({
  type: REMOVE_LOADER,
  payload,
});
export const setAboutRouteSelected = (payload) => ({
  type: ABOUT_ROUTE_SELECTED,
  payload,
});
export const setIsOnline = (payload) => ({
  type: IS_ONLINE_SET,
  payload,
});
export const openOnlinePopover = (payload) => ({
  type: OPEN_ONLINE_POPOVER,
  payload,
});
