import React from "react";
import PropTypes from "prop-types";
import {
  AlignmentPickerContainer,
  IconContainer,
} from "./AlignmentPicker.styled";
import LeftAlignIcon from "../../../../../Icon/Icons/LeftAlignIcon";
import CenterAlignIcon from "../../../../../Icon/Icons/CenterAlignIcon";
import RightAlignIcon from "../../../../../Icon/Icons/RightAlignIcon";
import { ALIGNMENT } from "../../../../../../constants/alignmentConstants";

const AlignmentPicker = (props) => {
  return (
    <AlignmentPickerContainer>
      <IconContainer
        $selected={props?.value === ALIGNMENT.LEFT}
        onClick={() => props?.onChange?.(ALIGNMENT.LEFT)}
      >
        <LeftAlignIcon />
      </IconContainer>
      <IconContainer
        $selected={props?.value === ALIGNMENT.CENTER}
        onClick={() => props?.onChange?.(ALIGNMENT.CENTER)}
      >
        <CenterAlignIcon />
      </IconContainer>
      <IconContainer
        $selected={props?.value === ALIGNMENT.RIGHT}
        onClick={() => props?.onChange?.(ALIGNMENT.RIGHT)}
      >
        <RightAlignIcon />
      </IconContainer>
    </AlignmentPickerContainer>
  );
};

AlignmentPicker.propTypes = {
  children: PropTypes.node,
  value: PropTypes.oneOf(
    Object.keys(ALIGNMENT).map((singleKey) => ALIGNMENT[singleKey])
  ),
  onChange: PropTypes.func,
};

export default AlignmentPicker;
