import { Box, Typography } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../../themes";

export const TextRendererContainer = styled(Box)`
  display: flex;
  gap: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
`;
export const TextRendererLabel = styled(Typography)`
  font-family: ${selectedTheme.fonts.mainText};
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  color: ${selectedTheme.colors.borderColor};
  display: none;
  @media (max-width: 600px) {
    display: block;
    &::after {
      content: ":";
    }
  }
`;
export const TextRendererValue = styled(Typography)`
  font-family: ${selectedTheme.fonts.mainText};
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  font-feature-settings: "salt" on;
  /* white-space: normal; */
  word-wrap: break-word;
  width: 100%;
  vertical-align: middle;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  max-height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
`;
