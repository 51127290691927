import { Box } from "@mui/material";
import styled from "styled-components";

export const SubjectPreviewContainer = styled(Box)``;
export const SubjectPreviewIframe =styled.iframe`
    width:100%;
    height:486px;
    margin-top: 52px;
    border: 2px solid #ccc;
    border-radius: 12px;
    padding:-14px;

`
