import React, { useCallback, useMemo, useRef } from "react";
import PropTypes from "prop-types";
import Table from "../../Table/Table";
import { useTranslation } from "react-i18next";
import measuringInstrumentsTableConstants from "../../../constants/tableConstants/measuringInstrumentsTableConstants";
import TextRenderer from "../../Table/Renderers/TextRenderer/TextRenderer";
import {
  clearMeasuringInstruments,
  fetchMeasuringInstruments,
} from "../../../store/actions/measuringInstruments/measuringInstrumentsActions";
import {
  selectMeasuringInstruments,
  selectTotalMeasuringInstruments,
} from "../../../store/selectors/measuringInstrumentsSelectors";
import { useDispatch } from "react-redux";
import {
  closeModal,
  toggleSetMeasuringInstrumentModal,
} from "../../../store/actions/modal/modalActions";
import { makeToastMessage } from "../../../store/utils/makeToastMessage";
import { APPLICATION_TOAST_CONTAINER_ID } from "../../../constants/toastConstants";
import useAuth from "../../../hooks/useAuth";
import { MANAGER_ROLE } from "../../../constants/rolesConstants";
import { formatDate, formatDateTime } from "../../../util/helpers/dateHelpers";
import useIsMobile from "../../../hooks/useIsMobile";

const MeasuringInstrumentsTable = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const tableRef = useRef(null);
  const modalRef = useRef(null);
  const { isMobile } = useIsMobile();

  const { hasRole } = useAuth();

  const hideAddAndUpdate = useMemo(() => {
    return !hasRole(MANAGER_ROLE);
  }, [hasRole]);

  const tableColumns = useMemo(() => {
    let columnsObject = Object.keys(measuringInstrumentsTableConstants).map(
      (property) => {
        const style = isMobile
          ? measuringInstrumentsTableConstants[property]?.styleMobile
          : {};
        return {
          ...measuringInstrumentsTableConstants[property],
          field: t(measuringInstrumentsTableConstants[property].i18key),
          propertyName: property,
          backendProperty:
            measuringInstrumentsTableConstants[property]?.backendProperty ||
            property,
          style: {
            ...measuringInstrumentsTableConstants[property]?.style,
            ...style,
          },
          renderer:
            measuringInstrumentsTableConstants[property]?.renderer ||
            TextRenderer,
        };
      }
    );
    return columnsObject;
  }, [isMobile, measuringInstrumentsTableConstants, t, hideAddAndUpdate]);

  const mapDataFunction = useCallback(
    (newData) => {
      return newData?.data?.map((singleData) => {
        let mappedDataObject = {
          name: singleData.name,
          factoryNumber: singleData?.serialNumber,
          manufacturer: singleData?.manufacturer,
          benchmarking: {
            name: singleData?.calibrationNumber,
            date: singleData?.validUntil
              ? formatDate(singleData?.validUntil)
              : null,
          },
          findingTypes: singleData.findingTypes,
          lastChanges: {
            date: formatDateTime(
              singleData?.updatedAtUtc || new Date().toString()
            ),
            author: `${singleData?.updatedByUser?.firstName || ""} ${
              singleData?.updatedByUser?.lastName || ""
            }`,
          },
          id: singleData?.id,
          actions: {
            measuringInstrumentId: singleData?.id,
            name: singleData.name,
            factoryNumber: singleData?.serialNumber,
            calibrationCertificateUrl: singleData?.calibrationCertificateUrl,
            manufacturer: singleData?.manufacturer,
            benchmarking: singleData?.calibrationNumber,
            findingTypes: singleData.findingTypes,
            benchmarkDate: singleData?.validUntil
              ? formatDate(singleData?.validUntil)
              : null,
            refetchTable: (name) => handleApiResponseSuccess(name, true),
            tableRef,
          },
        };
        return mappedDataObject;
      });
    },
    [hideAddAndUpdate]
  );

  const refetchTable = () => {
    tableRef?.current?.forceRefetch();
  };

  const handleApiResponseSuccess = (name, isEditing = false) => {
    dispatch(closeModal());
    refetchTable();
    const titleI18Key = isEditing
      ? "toast.success.editedTitle"
      : "toast.success.createdTitle";
    const descriptionI18Key = isEditing
      ? "toast.success.editedDescription"
      : "toast.success.createdDescription";
    makeToastMessage(
      {
        title: t(titleI18Key, {
          typeOfData: t("measuringInstruments.typeOfData"),
        }),
        description: t(descriptionI18Key, {
          name: name,
          typeOfData: t("measuringInstruments.typeOfDataPlural").toLowerCase(),
        }),
      },
      {
        containerId: APPLICATION_TOAST_CONTAINER_ID,
      }
    );
  };

  const handleClickAddButton = () => {
    dispatch(
      toggleSetMeasuringInstrumentModal({
        title: t("measuringInstruments.modal.newInstrument"),
        modalRef,
        rank: 0,
        refetchTable: (name) => handleApiResponseSuccess(name, false),
        tableRef,
      })
    );
  };

  const handleRedirect = (values) => {
    dispatch(
      toggleSetMeasuringInstrumentModal({
        title: t("measuringInstruments.modal.editInstrument"),
        prefilledData: values?.prefilledData,
        isEditing: true,
        rank: 0,
        measuringInstrumentId: values?.id,
        refetchTable: (name) => handleApiResponseSuccess(name, true),
        tableRef,
      })
    );
  };

  return (
    <Table
      ref={tableRef}
      hideAddButton={hideAddAndUpdate}
      tableColumns={tableColumns}
      searchPlaceholder={t("measuringInstruments.table.searchPlaceholder")}
      dataSelector={selectMeasuringInstruments}
      totalDataSelector={selectTotalMeasuringInstruments}
      dispatchFunction={fetchMeasuringInstruments}
      mapDataFunction={mapDataFunction}
      redirectFunction={handleRedirect}
      handleClickAddButton={handleClickAddButton}
      clearDispatchFunction={clearMeasuringInstruments}
      stickyActions
    />
  );
};

MeasuringInstrumentsTable.propTypes = {
  children: PropTypes.node,
};

export default MeasuringInstrumentsTable;
