import { Box } from "@mui/material";
import styled from "styled-components";

export const DistributionEmailsModalContainer = styled(Box)`
  padding: 32px;
  position: relative;
  background: white;
  border-radius: 12px;
  width: 368px;
  z-index: ${(props) => "151" + props?.rank || "151"};
`;
