import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Clipboard,
  EmailsListItemContainer,
  EmailsListItemText,
} from "./EmailsListItem.styled";
import Tooltip from "../../../../Tooltip/Tooltip";
import { useTranslation } from "react-i18next";

const EmailsListItem = (props) => {
  const { t } = useTranslation();
  const [isHovered, setIsHovered] = useState(false);

  const onHover = () => {
    setIsHovered(true);
  };
  const onHoverEnd = () => {
    setIsHovered(false);
  };

  const copyText = () => {
    navigator.clipboard.writeText(props?.email);
  };

  return (
    <EmailsListItemContainer onMouseEnter={onHover} onMouseLeave={onHoverEnd}>
      <EmailsListItemText>{props?.email}</EmailsListItemText>
      {isHovered && (
        <Tooltip title={t("clipboard.copy")} placement="right-start">
          <Clipboard onClick={copyText} />
        </Tooltip>
      )}
    </EmailsListItemContainer>
  );
};

EmailsListItem.propTypes = {
  email: PropTypes.string,
};

export default EmailsListItem;
