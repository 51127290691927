import { Box } from "@mui/material";
import styled, { css } from "styled-components";
import selectedTheme from "../../../themes";
import OrdinalNumberArrow from "../../Icon/Icons/OrdinalNumberArrow";

export const OrdinalNumberButtonContainer = styled(Box)`
  border: 2px solid ${selectedTheme.colors.secondBorderColor};
  border-radius: 12.7376px;
  font-family: ${selectedTheme.fonts.mainText};
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: ${selectedTheme.colors.pageTitleColor};
  display: flex;
  justify-content: ${(props) => (props?.disabled ? "center" : "space-between")};
  align-items: center;
  height: ${(props) =>
    props?.disabled
      ? "30px"
      : props?.separated
      ? "36px"
      : props?.vertical
      ? "64px"
      : "38px"};
  width: ${(props) =>
    props?.disabled
      ? "30px"
      : props?.separated
      ? "47px"
      : props?.vertical
      ? "34px"
      : "51px"};
  padding: ${(props) => props?.separated && "6px 9px"};
  ${props => props?.disabled && css`
    & > div {
      width: fit-content;
      height: fit-content;
      text-align: center;
    }
  `}
`;

export const OrdinalNumberButtonItemsContainer = styled(Box)`
  display: flex;
  flex-direction: ${(props) => (props?.vertical ? "column" : "row")};
  align-items: center;
  justify-content: ${(props) =>
    props?.separated ? "center" : "space-between"};
  height: 100%;
  width: ${(props) => (props?.separated ? "10px" : "100%")};
`;

export const LeftArrow = styled(OrdinalNumberArrow)`
  cursor: pointer;
`;
export const RightArrow = styled(OrdinalNumberArrow)`
  cursor: pointer;
  transform: rotate(180deg);
`;
export const DownArrow = styled(OrdinalNumberArrow)`
  cursor: pointer;
  transform: rotate(90deg);
`;
export const UpArrow = styled(OrdinalNumberArrow)`
  cursor: pointer;
  transform: rotate(270deg);
`;
