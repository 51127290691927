import { Box } from "@mui/material";
import styled from "styled-components";
import Select from "../../../../Select/Select";
import TextInputField from "../../../../Field/TextInputField/TextInputField";

export const FindingSettingsFormContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 85px;
  margin-top: 32px;
  @media (max-width: 600px) {
    flex-direction: column;
    gap: 30px;
  }
`;
export const FindingSettingsFormColumn = styled(Box)`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 30px;
  max-width: 375px;
  @media (max-width: 400px) {
    max-width: 100%;
  }
`;
export const FindingTextField = styled(TextInputField)`
  margin: 0;
  width: 100%;
  & > div {
    width: 100%;
  }
  & > div > div {
    width: 100%;
  }
  & input {
    width: 100%;
  }
`;
export const FindingSelectField = styled(Select)`
  & > div:nth-child(1) {
    margin-bottom: 9px;
  }
  & * {
    max-height: 48px;
  }
`;
