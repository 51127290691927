import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toggleFindingPreviewModal } from "../../../store/actions/modal/modalActions";
import {
  SubmitButton as CancelButton,
  CloseButton,
  ModalTitle,
} from "../Modal.styled";
import {
  FindingPreviewModalContainer,
  ButtonsContainer,
  FindingPreviewContainer,
  LoadingWrapper,
} from "./FindingPreviewModal.styled";
import BackdropComponent from "../../Modals/ModalComponents/BackdropComponent";
import { fetchFindingPreview } from "../../../store/actions/findings/findingsActions";
import { selectSingleFindingPreview } from "../../../store/selectors/findingsSelectors";
import { PREVIEW_FINDING_SCOPE } from "../../../store/actions/findings/findingsActionConstants";

const FindingPreviewModal = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const findingPreview = useSelector(selectSingleFindingPreview);

  useEffect(() => {
    dispatch(fetchFindingPreview({ findingId: props?.findingId }));
  }, [props?.findingId]);

  const handleCloseModal = () => {
    dispatch(toggleFindingPreviewModal());
  };

  return (
    <>
      {props?.rank > 1 ? (
        <BackdropComponent
          isLoading
          handleClose={handleCloseModal}
          position="absolute"
        />
      ) : (
        <></>
      )}
      <FindingPreviewModalContainer rank={props?.rank}>
        <CloseButton onClick={handleCloseModal} />
        <ModalTitle>{t("findingPreview.title")}</ModalTitle>
        <LoadingWrapper isLoadingScopes={[PREVIEW_FINDING_SCOPE]}>
          <FindingPreviewContainer
            srcDoc={findingPreview?.replace(
              "body {",
              `body::-webkit-scrollbar {
            width: 8px;
            border-radius: 8px;
          }
          body::-webkit-scrollbar-thumb {
            background-color: #EAECF0;
            border-radius: 8px;
          }
          body {
            padding-right: 10px;`
            )}
          ></FindingPreviewContainer>
        </LoadingWrapper>
        <ButtonsContainer>
          <CancelButton onClick={handleCloseModal}>
            {t("common.close")}
          </CancelButton>
        </ButtonsContainer>
      </FindingPreviewModalContainer>
    </>
  );
};

FindingPreviewModal.propTypes = {
  children: PropTypes.node,
  rank: PropTypes.number,
  title: PropTypes.string,
  onSubmit: PropTypes.func,
  columns: PropTypes.array,
  findingId: PropTypes.number,
};

export default FindingPreviewModal;
