import React from "react";
// import PropTypes from 'prop-types'
import { ReactComponent as ExportIcon2Svg } from "../../../assets/images/svg/export-2.svg";

const ExportIcon2 = (props) => {
  return <ExportIcon2Svg {...props} />;
};

ExportIcon2.propTypes = {};

export default ExportIcon2;
