import { all, call, put, takeLatest } from "@redux-saga/core/effects";
import { NAME_KEY } from "../../constants/queryConstants";
import {
  attemptCreateStuff,
  attemptDeleteStuff,
  attemptEditSingleStuff,
  attemptFetchSingleStuff,
  attemptFetchStuffs,
} from "../../request/stuffsRequest";
import {
  CREATE_STUFF_FETCH,
  DELETE_STUFF_FETCH,
  EDIT_STUFF_FETCH,
  GET_STUFFS_FETCH,
  SINGLE_STUFF_FETCH,
} from "../actions/stuffs/stuffsActionConstants";
import {
  fetchCreateStuffError,
  fetchCreateStuffSuccess,
  fetchDeleteStuffsError,
  fetchDeleteStuffsSuccess,
  fetchEditStuffError,
  fetchEditStuffSuccess,
  fetchSingleStuffError,
  fetchSingleStuffSuccess,
  fetchStuffsError,
  fetchStuffsSuccess,
  setSingleStuff,
  setStuffs,
  setTotalStuffs,
} from "../actions/stuffs/stuffsActions";
import { mapFetchAllFunction } from "../utils/mapFetchAllFunctions";

function* fetchStuffs({ payload }) {
  try {
    const queryObject = mapFetchAllFunction(payload, [NAME_KEY]);
    const data = yield call(attemptFetchStuffs, queryObject);
    yield put(
      setStuffs({
        data: data?.data?.staffs,
      })
    );
    yield put(setTotalStuffs(data?.data?.count));
    if (payload?.handleApiResponseSuccess) {
      yield call(payload?.handleApiResponseSuccess);
    }
    yield put(fetchStuffsSuccess());
  } catch (e) {
    console.log("greska", e);
    let errorMessage = e?.response?.data?.message;

    if (payload?.handleApiResponseError) {
      yield call(payload?.handleApiResponseError, errorMessage);
    }
    yield put(fetchStuffsError(errorMessage));
  }
}
function* fetchCreateStuff({ payload }) {
  try {
    const stuffObject = {
      firstName: payload?.stuffData?.firstName,
      lastName: payload?.stuffData?.lastName,
      email: payload?.stuffData?.email,
      profession: payload?.stuffData?.profession,
      licenseUrl: payload?.stuffData?.licenseUrl,
      licenseNumber: payload?.stuffData?.licenseNumber,
      signatureUrl: payload?.stuffData?.signatureUrl,
      userId: payload?.stuffData?.user,
    };
    const data = yield call(attemptCreateStuff, stuffObject);
    yield put(fetchCreateStuffSuccess());
    if (payload?.handleApiResponseSuccess) {
      yield call(payload?.handleApiResponseSuccess, data?.data?.id);
    }
  } catch (e) {
    console.log("greska", e);
    let errorMessage = e?.response?.data?.message;

    if (payload?.handleApiResponseError) {
      yield call(payload?.handleApiResponseError, errorMessage);
    }
    yield put(fetchCreateStuffError(errorMessage));
  }
}

function* fetchSingleStuff({ payload }) {
  try {
    const { data } = yield call(attemptFetchSingleStuff, {
      stuffId: payload?.stuffId,
    });
    yield put(setSingleStuff(data));
    if (payload?.handleApiResponseSuccess) {
      yield call(payload?.handleApiResponseSuccess);
    }
    yield put(fetchSingleStuffSuccess());
  } catch (e) {
    console.log("greska", e);
    let errorMessage = e?.response?.data?.message;

    if (payload?.handleApiResponseError) {
      yield call(payload?.handleApiResponseError, errorMessage);
    }
    yield put(fetchSingleStuffError(errorMessage));
  }
}

function* fetchEditSingleStuff({ payload }) {
  try {
    const stuffData = payload?.stuffData;
    const stuffObject = {
      firstName: stuffData.firstName,
      lastName: stuffData.lastName,
      email: stuffData.email,
      profession: stuffData.profession,
      licenseUrl: stuffData.licenseUrl,
      licenseNumber: stuffData.licenseNumber,
      signatureUrl: stuffData.signatureUrl,
      userId: stuffData.user,
    };
    yield call(attemptEditSingleStuff, {
      stuffId: payload?.stuffId,
      data: stuffObject,
    });
    if (payload?.handleApiResponseSuccess) {
      yield call(payload?.handleApiResponseSuccess);
    }
    yield put(fetchEditStuffSuccess());
  } catch (e) {
    console.log("greska", e);
    let errorMessage = e?.response?.data?.message;

    if (payload?.handleApiResponseError) {
      yield call(payload?.handleApiResponseError, errorMessage);
    }
    yield put(fetchEditStuffError(errorMessage));
  }
}

function* fetchDeleteSingleStuff({ payload }) {
  try {
    yield call(attemptDeleteStuff, {
      stuffId: payload?.stuffId,
    });
    if (payload?.handleApiResponseSuccess) {
      yield call(payload?.handleApiResponseSuccess, { isDeleted: true });
    }
    yield put(fetchDeleteStuffsSuccess());
  } catch (e) {
    console.log("greska", e);
    let errorMessage = e?.response?.data?.message;

    if (payload?.handleApiResponseError) {
      yield call(payload?.handleApiResponseError, errorMessage);
    }
    yield put(fetchDeleteStuffsError(errorMessage));
  }
}
export default function* stuffsSaga() {
  yield all([
    takeLatest(GET_STUFFS_FETCH, fetchStuffs),
    takeLatest(CREATE_STUFF_FETCH, fetchCreateStuff),
    takeLatest(SINGLE_STUFF_FETCH, fetchSingleStuff),
    takeLatest(EDIT_STUFF_FETCH, fetchEditSingleStuff),
    takeLatest(DELETE_STUFF_FETCH, fetchDeleteSingleStuff),
  ]);
}
