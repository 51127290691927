import React from "react";
import { forwardRef } from "react";
// import PropTypes from 'prop-types'
import { ReactComponent as DragIconSvg } from "../../../assets/images/svg/drag.svg";

const DragIcon = forwardRef((props, ref) => {
  return <DragIconSvg {...props} ref={ref} />;
});

DragIcon.displayName = "DragIcon";

DragIcon.propTypes = {};

export default DragIcon;
