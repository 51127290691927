import { Box } from "@mui/material";
import styled from "styled-components";

export const TableContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 17px;
  position: relative;
`;

export const PagingContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
  margin-top: -15px;
  @media (max-width: 600px) {
    justify-content: center;
  }
`;
