import React from "react";
import PropTypes from "prop-types";
import {
  TextRendererContainer,
  TextRendererLabel,
  TextRendererValue,
} from "./TextRenderer.styled";
import Tooltip from "../../../Tooltip/Tooltip";

const TextRenderer = (props) => {
  return (
    <TextRendererContainer>
      {props?.showLabel && (
        <TextRendererLabel>{props?.label}</TextRendererLabel>
      )}
      <TextRendererValue>
        <Tooltip title={props?.value}>{props?.value}</Tooltip>
      </TextRendererValue>
    </TextRendererContainer>
  );
};

TextRenderer.propTypes = {
  value: PropTypes.children,
  label: PropTypes.string,
  showLabel: PropTypes.bool,
};

export default TextRenderer;
