import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { ButtonsContainer, CloseButton, ModalTitle } from "../Modal.styled";
import { useDispatch } from "react-redux";
import { closeModal } from "../../../store/actions/modal/modalActions";
import {
  CancelButton,
  SubmitButton,
  LicenseModalContainer,
} from "./LicenseModal.styled";
import { useTranslation } from "react-i18next";
import BackdropComponent from "../../Modals/ModalComponents/BackdropComponent";
import { useFormik } from "formik";
import licenseInitialValues from "../../../initialValues/licenseInitialValues";
import licensesValidationSchema from "../../../validations/licensesValidationSchema";
import StartDateField from "./StartDateField/StartDateField";
import ExpirationDateField from "./ExpirationDateField/ExpirationDateField";

const LicenseModal = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  const handleSubmit = (values) => {
    props?.handleSubmit?.(values);
  };

  const initialValues = useMemo(
    () => licenseInitialValues(props?.prefilledData),
    [props?.prefilledData]
  );

  const formik = useFormik({
    initialValues,
    validationSchema: licensesValidationSchema(),
    onSubmit: handleSubmit,
  });

  return (
    <>
      {props?.rank > 1 ? (
        <BackdropComponent
          isLoading
          handleClose={handleCloseModal}
          position="fixed"
        />
      ) : (
        <></>
      )}
      <LicenseModalContainer rank={props?.rank}>
        <ModalTitle>{props?.title}</ModalTitle>
        <CloseButton onClick={handleCloseModal} />
        <StartDateField formik={formik} />
        <ExpirationDateField formik={formik} />
        <ButtonsContainer>
          <CancelButton
            onClick={handleCloseModal}
            $variant={props?.cancelButtonVariant}
          >
            {t("common.cancel")}
          </CancelButton>
          <SubmitButton onClick={formik?.handleSubmit}>
            {t("common.add")}
          </SubmitButton>
        </ButtonsContainer>
      </LicenseModalContainer>
    </>
  );
};

LicenseModal.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  cancelButtonVariant: PropTypes.number,
  rank: PropTypes.number,
  history: PropTypes.any,
  handleSubmit: PropTypes.func,
  prefilledData: PropTypes.object,
};

LicenseModal.defaultProps = {
  cancelButtonVariant: 1,
  data: [],
  handleSubmit: () => {},
};

export default LicenseModal;
