import React from "react";
// import PropTypes from 'prop-types'
import { ReactComponent as ProfileBreadIconSvg } from "../../../assets/images/svg/profile-bc.svg";

const ProfileBreadIcon = (props) => {
  return <ProfileBreadIconSvg {...props} />;
};

ProfileBreadIcon.propTypes = {};

export default ProfileBreadIcon;
