import { createSelector } from "reselect";

const clientsSelector = (state) => state.clients;

export const selectClients = createSelector(
  clientsSelector,
  (state) => state.clients
);
export const selectTotalClients = createSelector(
  clientsSelector,
  (state) => state.totalClients
);
export const selectSingleClient = createSelector(
  clientsSelector,
  (state) => state.singleClient
);
export const selectClientsData = createSelector(
  clientsSelector,
  (state) => state.clients.data
);
