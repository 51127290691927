import { Box } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../themes";

export const SelectOptionCardContainer = styled(Box)`
  width: 88px;
  height: 56px;
  background-color: ${(props) =>
    props?.isSelected
      ? selectedTheme.colors.orange
      : selectedTheme.colors.white};
  border: 1px solid ${selectedTheme.colors.borderColorSecond};
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  & svg {
    width: 24px;
    height: 24px;
  }
  & path {
    stroke: ${(props) =>
      props?.isSelected
        ? selectedTheme.colors.white
        : selectedTheme.colors.iconColor};
  }
`;
