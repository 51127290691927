import React from "react";
import PropTypes from "prop-types";
import ReactDatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";
import {
  ClearIcon,
  DateFilterComponentContainer,
  DateFilterComponentLabel,
  DateFilterDatePickerContainer,
  DateTextField,
  LabelContainer,
} from "./DateFilterComponent.styled";

const DateFilterComponent = (props) => {
  const { t } = useTranslation();

  return (
    <DateFilterComponentContainer>
      <DateFilterDatePickerContainer>
        <LabelContainer>
          <DateFilterComponentLabel>
            {t("documents.filter.date.label")}
          </DateFilterComponentLabel>
        </LabelContainer>
        <ReactDatePicker
          placeholderText={t("documents.filter.date.placeholder")}
          onChange={props?.setDate}
          selected={props?.date}
          dateFormat="dd.MM.yyyy"
          customInput={
            <DateTextField
              date
              requiredField={false}
              placeholderText={t("documents.filter.date.placeholder")}
              value={props?.date}
              onChange={props?.setDate}
              icon={<ClearIcon onClick={() => props?.setDate("")} />}
              fullWidth
            />
          }
        />
      </DateFilterDatePickerContainer>
    </DateFilterComponentContainer>
  );
};

DateFilterComponent.propTypes = {
  children: PropTypes.node,
  date: PropTypes.object,
  setDate: PropTypes.func,
};

export default DateFilterComponent;
