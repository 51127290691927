import {
  CLEAR_CLIENTS,
  SET_CLIENTS,
  SET_TOTAL_CLIENTS,
  SINGLE_CLIENT_CLEAR,
  SINGLE_CLIENT_SET,
} from "../../actions/clients/clientsActionConstants";
import createReducer from "../../utils/createReducer";

const initialState = {
  clients: {
    loaded: false,
    data: [],
  },
  totalClients: 0,
  singleClient: {},
};

export default createReducer(
  {
    [SET_CLIENTS]: setClients,
    [SINGLE_CLIENT_SET]: setSingleClient,
    [SET_TOTAL_CLIENTS]: setTotalClients,
    [CLEAR_CLIENTS]: clearClients,
    [SINGLE_CLIENT_CLEAR]: clearSingleClient,
  },
  initialState
);
function setClients(state, { payload }) {
  return {
    ...state,
    clients: payload,
  };
}
function setTotalClients(state, { payload }) {
  return {
    ...state,
    totalClients: payload,
  };
}
function clearClients() {
  return initialState;
}
function setSingleClient(state, { payload }) {
  return {
    ...state,
    singleClient: payload,
  };
}
function clearSingleClient(state) {
  return {
    ...state,
    singleClient: initialState?.singleClient,
  };
}
