import React from "react";
import PropTypes from "prop-types";
import {
  CancelButton,
  ButtonsContainer,
  SubmitButton,
  ActionButtonsContainer,
  RequiredFieldsText,
} from "./ActionButtons.styled";
import { useTranslation } from "react-i18next";
import history from "../../../store/utils/history";

const ActionButtons = (props) => {
  const { t } = useTranslation();
  const handleClickCancelButton = () => {
    if (props?.handleClickCancelButton) {
      props?.handleClickCancelButton();
    } else history.goBack();
  };
  const handleClickSubmitButton = () => {
    if (props?.handleClickSubmitButton) {
      props?.handleClickSubmitButton();
    }
  };
  return (
    <ActionButtonsContainer>
      <ButtonsContainer>
        <CancelButton onClick={handleClickCancelButton}>
          {t("common.cancel")}
        </CancelButton>
        <SubmitButton
          variant="contained"
          onClick={handleClickSubmitButton}
          disabled={props?.disabledSaveButton}
        >
          {t("common.save")}
        </SubmitButton>
      </ButtonsContainer>
      <RequiredFieldsText>
        {t("common.requiredFieldsText")}
      </RequiredFieldsText>
    </ActionButtonsContainer>
  );
};

ActionButtons.propTypes = {
  children: PropTypes.node,
  handleClickCancelButton: PropTypes.func,
  handleClickSubmitButton: PropTypes.func,
  disabledSaveButton: PropTypes.bool,
};

export default ActionButtons;
