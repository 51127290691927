import React from "react";
import PropTypes from "prop-types";
import {
  RoleRendererContainer,
  RoleRendererContentContainer,
  RoleRendererContentTitle,
} from "./RoleRenderer.styled";
import i18n from "../../../../../../i18n";

const RoleRenderer = (props) => {
  return (
    <RoleRendererContainer $isInDashboard={props?.inDashboard}>
      {props?.value
        ?.filter?.((singleRole) => singleRole)
        ?.map((item, index) => (
          <RoleRendererContentContainer key={index} role={item?.roleName}>
            <RoleRendererContentTitle role={item?.roleName}>
              {i18n?.t(item?.i18key)}
            </RoleRendererContentTitle>
          </RoleRendererContentContainer>
        ))}
    </RoleRendererContainer>
  );
};

RoleRenderer.propTypes = {
  value: PropTypes.array,
  inDashboard: PropTypes.bool,
};

export default RoleRenderer;
