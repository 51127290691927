import {
    createClearType,
    createErrorType,
    createFetchType,
    createSetType,
    createSuccessType,
  } from "../actionHelpers";
  
  export const SET_DOCUMENT = createSetType("SET_DOCUMENT");
  export const SET_DOCUMENT_ERROR = "SET_DOCUMENT_ERROR";
  
  export const GET_DOCUMENTS_SCOPE = "GET_DOCUMENTS_SCOPE";
  export const GET_DOCUMENTS_FETCH = createFetchType(GET_DOCUMENTS_SCOPE);
  export const GET_DOCUMENTS_SUCCESS = createSuccessType(GET_DOCUMENTS_SCOPE);
  export const GET_DOCUMENTS_ERROR = createErrorType(GET_DOCUMENTS_SCOPE);
  export const GET_DOCUMENTS_CLEAR = createClearType(GET_DOCUMENTS_SCOPE);
  
  export const SINGLE_DOCUMENT_SCOPE = "SINGLE_DOCUMENT_SCOPE";
  export const SINGLE_DOCUMENT_FETCH = createFetchType(SINGLE_DOCUMENT_SCOPE);
  export const SINGLE_DOCUMENT_SUCCESS = createSuccessType(SINGLE_DOCUMENT_SCOPE);
  export const SINGLE_DOCUMENT_ERROR = createErrorType(SINGLE_DOCUMENT_SCOPE);
  export const SINGLE_DOCUMENT_SET = createSetType(SINGLE_DOCUMENT_SCOPE);
  
  const CREATE_DOCUMENT_SCOPE = "CREATE_DOCUMENT_SCOPE";
  export const CREATE_DOCUMENT_FETCH = createFetchType(CREATE_DOCUMENT_SCOPE);
  export const CREATE_DOCUMENT_SUCCESS = createSuccessType(CREATE_DOCUMENT_SCOPE);
  export const CREATE_DOCUMENT_ERROR = createErrorType(CREATE_DOCUMENT_SCOPE);
  
  const EDIT_DOCUMENT_SCOPE = "EDIT_DOCUMENT_SCOPE";
  export const EDIT_DOCUMENT_FETCH = createFetchType(EDIT_DOCUMENT_SCOPE);
  export const EDIT_DOCUMENT_SUCCESS = createSuccessType(EDIT_DOCUMENT_SCOPE);
  export const EDIT_DOCUMENT_ERROR = createErrorType(EDIT_DOCUMENT_SCOPE);
  
  const DELETE_DOCUMENT_SCOPE = "DELETE_DOCUMENT_SCOPE";
  export const DELETE_DOCUMENT_FETCH = createFetchType(DELETE_DOCUMENT_SCOPE);
  export const DELETE_DOCUMENT_SUCCESS = createSuccessType(DELETE_DOCUMENT_SCOPE);
  export const DELETE_DOCUMENT_ERROR = createErrorType(DELETE_DOCUMENT_SCOPE);
  
  export const SET_DOCUMENTS = createSetType("SET_DOCUMENTS");
  export const SET_TOTAL_DOCUMENTS = createSetType("SET_TOTAL_DOCUMENTS");
  export const CLEAR_DOCUMENTS = createClearType("CLEAR_DOCUMENTS");
  