import React from "react";
// import PropTypes from 'prop-types'
import { ReactComponent as TrashIconSvg } from "../../../assets/images/svg/trash.svg";

const TrashIcon = (props) => {
  return <TrashIconSvg {...props} />;
};

TrashIcon.propTypes = {};

export default TrashIcon;
