import { removeBackslashes } from "./stringHelpers";

export const removeDuplicates = (array, property = null) => {
  let helperObject = {};
  let newArray = [];
  array.forEach((element, index) => {
    let elementToCompare = property ? element[property] : element;
    if (typeof elementToCompare === "string")
      elementToCompare = removeBackslashes(elementToCompare);
    if (!helperObject?.[elementToCompare]) {
      helperObject[removeBackslashes(elementToCompare)] = index;
      newArray?.push(element)
    } 
    // debugger; //eslint-disable-line
  });
  return newArray;
};
