import { Box } from "@mui/material";
import styled from "styled-components";
import DropdownIcon from "../../Icon/Icons/DropdownIcon";
import selectedTheme from "../../../themes";
import PopoverComponent from "../../Modals/ModalComponents/PopoverComponent";
import { hexToRGB } from "../../../util/helpers/colorHelper";

export const LanguageDropdownContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  justify-content: space-between;
  border-radius: 8px;
  cursor: pointer;
`;
export const LanguageDropdownIcon = styled(DropdownIcon)`
  width: 12px;
  height: 7px;
  & path {
    stroke: ${selectedTheme.colors.dropdownIcon};
  }
`;
export const LanguageDropdownTitle = styled(Box)`
  font-family: ${selectedTheme.fonts.mainText};
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${selectedTheme.colors.userPopoverItemColor2};
`;
export const LanguagePopoverComponent = styled(PopoverComponent)`
  & #popover-content {
    box-shadow: 0px 4px 6px -2px ${hexToRGB(selectedTheme.colors.shadowColor, 0.03)},
      0px 12px 16px -4px ${hexToRGB(selectedTheme.colors.shadowColor, 0.08)};
    border-radius: 8px;
    border: 1px solid ${selectedTheme.colors.languageDropdownBorder};
  }
`;
export const LangaugeLeftDropdownContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
`;
