
import TypeRenderer from "../../components/Findings/FindingsTable/Renderers/TypeRenderer/TypeRenderer";
export default {
  caption: {
    id: 0,
    i18key: "common.name",
    style: {
      minWidth: "343.5px",
    },
    backendProperty: "ScanningSubjectCaption",
    redirect: true,
    setMaxHeight: true,
    disableSort: false,
  },
  category: {
    id: 1,
    i18key: "commonTemplate.filter.categoryTitle",
    renderer: TypeRenderer,
    disableSort: true,
    style: {
      paddingRight: "0",
      minWidth: "100px",//calc(100% /6)", 
    },
    backendProperty: "category",
   
  },
  language: {
    id: 2,
    i18key: "common.language",
    style: {
      minWidth: "80px",
    },
    backendProperty: "language",
    redirect: true,
    setMaxHeight: true,
    disableSort: true,
  },
  header: {
    id: 3,
    i18key: "commonTemplates.table.header.columnTitle",
    mobile: true,
    disableSort: true,
    style: {},
  },
};