import React from "react";
// import PropTypes from 'prop-types'
import { ReactComponent as CalendarIconSvg } from "../../../assets/images/svg/calendar.svg";

const CalendarIcon = (props) => {
  return <CalendarIconSvg {...props} />;
};

CalendarIcon.propTypes = {};

export default CalendarIcon;
