import { all, call, put, takeLatest } from "@redux-saga/core/effects";
import jwt from "jsonwebtoken";
import history from "../utils/history";
import {
  AUTHENTICATE_USER,
  LOGIN_USER_FETCH,
  LOGOUT_USER,
  REFRESH_TOKEN,
  GENERATE_TOKEN,
} from "../actions/login/loginActionConstants";
import {
  attemptLogin,
  logoutUserRequest,
  generateTokenRequest,
} from "../../request/loginRequest";
import {
  fetchUserError,
  fetchUserSuccess,
  resetLoginState,
  setMineCompanyId,
  setMyProfileId,
  setUserRoles,
  updateRefreshToken,
  updateUserToken,
} from "../actions/login/loginActions";
import { LOGIN_PAGE } from "../../constants/pages";
import { setUser } from "../actions/user/userActions";
import { addHeaderToken, removeHeaderToken } from "../../request";
import {
  IMPERSONATE_USER_UID,
  REGISTRATION_USER_UID,
} from "../../constants/sessionStorage";
import {
  JWT_REFRESH_TOKEN,
  JWT_TOKEN,
  REFRESH_TOKEN_CONST,
} from "../../constants/localStorage";
import {
  authScopeClearHelper,
  authScopeStringGetHelper,
  authScopeRemoveHelper,
  authScopeSetHelper,
} from "../../util/helpers/authScopeHelpers";
import { rejectErrorCodeHelper } from "../../util/helpers/rejectErrorCodeHelper";
import { fetchMyCompany } from "../actions/companies/companiesActions";
import { clearFindingTypes } from "../actions/findings/findingsActions";

function* fetchUser({ payload }) {
  try {
    const { data } = yield call(attemptLogin, payload?.values);
    const tokenDecoded = jwt.decode(data.token);
    // const refreshDecoded = jwt.decode(data.refreshToken);
    yield call(authScopeSetHelper, JWT_TOKEN, data.token);
    yield call(authScopeSetHelper, JWT_REFRESH_TOKEN, data.refreshToken);
    yield call(addHeaderToken, data.token);
    yield put(setMyProfileId(tokenDecoded.Id));
    const accessToken = {
      token: data.token,
      exp: tokenDecoded.exp,
    };
    yield put(setMineCompanyId(tokenDecoded.CompanyId));
    if (typeof tokenDecoded.Roles === "string") {
      yield put(setUserRoles([tokenDecoded.Roles]));
    } else {
      yield put(setUserRoles(tokenDecoded.Roles));
    }
    yield call(fetchMyCompany);
    // const refreshToken = {
    //   token: data.refreshToken,
    //   exp: refreshDecoded.exp,
    // };
    const userId = tokenDecoded.Id;
    yield put(
      fetchUserSuccess({
        jwtToken: accessToken,
        refreshToken: data.refreshToken,
        userId,
      })
    );
    if (payload?.handleApiResponseSuccess) {
      yield call(payload?.handleApiResponseSuccess);
    }
  } catch (e) {
    console.log("greska", e);
    if (e.response && e.response.data) {
      const errorMessage = e?.response?.data?.message;
      if (payload?.handleApiResponseError) {
        yield call(payload?.handleApiResponseError, errorMessage);
      }
      yield put(fetchUserError(errorMessage));
    } else {
      if (payload?.handleApiResponseError) {
        yield call(payload?.handleApiResponseError);
      }
      yield put(fetchUserError());
    }
  }
}
function* authenticateUser() {
  try {
    const JwtToken = yield call(authScopeStringGetHelper, JWT_TOKEN);

    if (!JwtToken) {
      yield call(history.push, LOGIN_PAGE);
    }

    return yield put(
      fetchUserSuccess({
        JwtToken,
      })
    );
  } catch (error) {
    const errorMessage = yield call(rejectErrorCodeHelper, error);
    yield put(fetchUserError(errorMessage));
    yield call(authScopeRemoveHelper, JWT_TOKEN);
    yield call(authScopeRemoveHelper, JWT_REFRESH_TOKEN);
    yield call(authScopeRemoveHelper, REFRESH_TOKEN_CONST);
  }
}

function* logoutUser() {
  try {
    const JwtToken = yield call(authScopeStringGetHelper, JWT_TOKEN);
    const refreshToken = yield call(
      authScopeStringGetHelper,
      JWT_REFRESH_TOKEN
    );
    const user = jwt.decode(JwtToken);
    if (user) {
      yield call(logoutUserRequest, {
        refreshToken,
      });
    }
  } catch (error) {
    console.log(error); // eslint-disable-line
  } finally {
    yield put(clearFindingTypes())
    yield call(authScopeClearHelper);
    yield call(removeHeaderToken);
    yield put(resetLoginState());
    yield call(history.replace, LOGIN_PAGE);
  }
}

export function* refreshToken({ payload }) {
  try {
    yield call(authScopeSetHelper, JWT_TOKEN, payload?.token);
    yield call(authScopeSetHelper, JWT_REFRESH_TOKEN, payload?.refreshToken);
    const user = jwt.decode(payload?.token);
    addHeaderToken(payload?.token);
    yield put(setUser(user));
    yield put(updateUserToken(payload?.token));
    yield put(updateRefreshToken(payload?.refreshToken));
  } catch (error) {
    yield call(logoutUser);
    console.log(error); // eslint-disable-line
  }
}

export function* generateToken({ payload }) {
  try {
    const { data } = yield call(generateTokenRequest, payload?.data);
    const { JwtToken, JwtRefreshToken } = data;

    if (JwtToken && JwtRefreshToken) {
      yield call(authScopeSetHelper, JWT_TOKEN, data.JwtToken);
      yield call(authScopeSetHelper, JWT_REFRESH_TOKEN, data.JwtRefreshToken);

      if (payload?.impersonate) {
        sessionStorage.setItem(IMPERSONATE_USER_UID, payload?.accountUid);
      }

      if (payload?.registration) {
        sessionStorage.setItem(REGISTRATION_USER_UID, payload?.accountUid);
      }

      const user = jwt.decode(data.JwtToken);
      addHeaderToken(data.JwtToken);
      if (user) {
        yield put(setUser(user));
      }
      yield put(updateUserToken(data.JwtToken));

      if (payload?.onSuccess) {
        yield call(payload?.onSuccess);
      }
    }
  } catch (error) {
    yield call(logoutUser);
    console.log(error); // eslint-disable-line
  }
}

export default function* loginSaga() {
  yield all([
    takeLatest(LOGIN_USER_FETCH, fetchUser),
    takeLatest(AUTHENTICATE_USER, authenticateUser),
    takeLatest(LOGOUT_USER, logoutUser),
    takeLatest(REFRESH_TOKEN, refreshToken),
    takeLatest(GENERATE_TOKEN, generateToken),
  ]);
}
