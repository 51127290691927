import { Box } from "@mui/material";
import styled from "styled-components";

export const FindingSettingsContainer = styled(Box)`
  display: ${(props) => (props?.$shouldHide ? "none" : "flex")};
  flex-direction: column;
  width: 75%;
  @media (max-width: 600px) {
    width: 100%;
  }
`;
