import { GET_CATEGORIES_CLEAR, GET_CATEGORIES_ERROR, GET_CATEGORIES_FETCH, GET_CATEGORIES_SUCCESS, SET_CATEGORIES, SET_CATEGORIES_ERROR, SET_CATEGORIES_TOTAL } from "./categoriesActionConstant";

export const setCategories = (payload) => ({
    type: SET_CATEGORIES,
    payload,
  });
  
  export const setCategoriesError = (payload) => ({
    type: SET_CATEGORIES_ERROR,
    payload,
  });
  
  export const fetchCategories = (payload) => ({
    type: GET_CATEGORIES_FETCH,
    payload,
  });
  export const fetchCategoriesSuccess = (payload) => ({
    type: GET_CATEGORIES_SUCCESS,
    payload,
  });
  export const fetchCategoriesError = (payload) => ({
    type: GET_CATEGORIES_ERROR,
    payload,
  });
  
  export const fetchCategoriesClear = () => ({
    type: GET_CATEGORIES_CLEAR,
  });

  export const setCategoriesTotal = (payload) => ({
    type: SET_CATEGORIES_TOTAL,
    payload,
  });