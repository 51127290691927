import React from "react";
import PropTypes from "prop-types";
import {
  EditIcon,
  EyeButtonContainer,
  EyeIcon,
  IconButton,
  ImageEditingContainer,
  ImageOverlay,
  ImagePreviewContainer,
  ImageSource,
  ToolsContainer,
  TrashIcon,
} from "./ImagePreview.styled";
import Tooltip from "../../Tooltip/Tooltip";
import { forwardRef } from "react";
import { useState } from "react";
import { useCallback } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toggleImagePreviewModal } from "../../../store/actions/modal/modalActions";

const ImagePreview = forwardRef((props, ref) => {
  const { t } = useTranslation();
  const [isEditing, setIsEditing] = useState(false);
  const dispatch = useDispatch();

  const listener = useCallback(
    (event) => {
      if (ref.current) {
        if (ref.current?.contains?.(event.target)) {
          setIsEditing((prevState) => !prevState);
        } else {
          setIsEditing(false);
        }
      }
    },
    [ref.current]
  );

  useEffect(() => {
    window.addEventListener("click", listener);
    return () => window.removeEventListener("click", listener);
  }, []);

  const handleImagePreview = () => {
    dispatch(
      toggleImagePreviewModal({
        title: props?.label || props?.modalTitle,
        image: props?.image,
      })
    );
    setIsEditing(false);
  };

  return (
    <ImagePreviewContainer ref={ref}>
      <ImageOverlay $hidden />
      <ImageSource src={props?.image} draggable={false} />
      {isEditing && (
        <ImageEditingContainer>
          {/* Image tools overlay */}
          {!(props?.disabled && props?.disablePreview) && (
            <>
              <ImageOverlay />
              {!props?.disabled && (
                <ToolsContainer $smallerButtons={props?.smallerButtons}>
                  {/* Change icon */}
                  {/* ****** */}
                  <Tooltip title={t("imagePicker.tooltip.changeImage")}>
                    <IconButton
                      onClick={props?.handleChange}
                      $smallerButton={props?.smallerButtons}
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                  {/* ****** */}

                  {/* Delete icon */}
                  {/* ****** */}
                  <Tooltip title={t("imagePicker.tooltip.deleteImage")}>
                    <IconButton
                      onClick={props?.handleDelete}
                      $smallerButton={props?.smallerButtons}
                    >
                      <TrashIcon />
                    </IconButton>
                  </Tooltip>
                  {/* ****** */}
                </ToolsContainer>
              )}
            </>
          )}

          {!props?.disablePreview && (
            <Tooltip title={t("imagePicker.tooltip.showImage")}>
              <EyeButtonContainer onClick={handleImagePreview}>
                <EyeIcon $smallerButton={props?.smallerButtons} />
              </EyeButtonContainer>
            </Tooltip>
          )}
        </ImageEditingContainer>
      )}
    </ImagePreviewContainer>
  );
});

ImagePreview.displayName = "ImagePreview";

ImagePreview.propTypes = {
  image: PropTypes.string,
  label: PropTypes.string,
  handleChange: PropTypes.func,
  handleDelete: PropTypes.func,
  smallerButtons: PropTypes.bool,
  disabled: PropTypes.bool,
  modalTitle: PropTypes.string,
  disablePreview: PropTypes.bool,
};

export default ImagePreview;
