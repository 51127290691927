import React, { useMemo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  ErrorMessage,
  GoogleDriveInfoContainer,
  VerificationButton,
  VerificationContent,
  VerificationText,
  VerificationTextContainer,
} from "./GoogleDriveInfo.styled";
import TextInputField from "../../../Field/TextInputField/TextInputField";
import { Trans, useTranslation } from "react-i18next";
import FileJsonPicker from "../../../FilePicker/FileJsonPicker";
import { useDispatch, useSelector } from "react-redux";
import { selectSingleCompany } from "../../../../store/selectors/companiesSelector";
import {
  closeLoadingModal,
  toggleSetLoadingModal,
} from "../../../../store/actions/modal/modalActions";
import { makeToastMessage } from "../../../../store/utils/makeToastMessage";
import {
  fetchUploadJsonSecret,
  fetchValidateNewDrive,
} from "../../../../store/actions/companies/companiesActions";

const GoogleDriveInfo = (props) => {
  const formik = props?.formik;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const singleCompany = useSelector(selectSingleCompany);
  const [file, setFile] = useState(null);
  const verificationDisabled = useMemo(() => {
    if (
      formik?.values?.driveFolderId &&
      (file != null || singleCompany?.hasCredentialsFile)
    )
      return false;
    return true;
  }, [file, formik?.values?.driveFolderId, singleCompany]);

  const handleApiResponseSuccessValidate = () => {
    dispatch(closeLoadingModal());
    makeToastMessage({
      title: t("companySettings.archive.googleDrive.driveVerified"),
    });
  };

  const handleApiResponseErrorValidate = () => {
    dispatch(closeLoadingModal());
  };

  const handleApiResponseSuccess = (fileName) => {
    dispatch(closeLoadingModal());
    makeToastMessage({
      title: (
        <Trans
          i18nKey="companySettings.archive.googleDrive.fileUploaded"
          values={{ name: fileName }}
        />
      ),
    });
  };

  const handleApiResponseError = () => {
    dispatch(closeLoadingModal());
  };
  const validateDrive = () => {
    dispatch(toggleSetLoadingModal({ rank: 1 }));
    dispatch(
      fetchValidateNewDrive({
        id: singleCompany?.id,
        data: { file: file, folderId: formik?.values?.driveFolderId },
        handleApiResponseSuccess: handleApiResponseSuccessValidate,
        handleApiResponseError: handleApiResponseErrorValidate,
      })
    );
  };

  const uploadJson = (newFile) => {
    dispatch(toggleSetLoadingModal({ rank: 1 }));
    dispatch(
      fetchUploadJsonSecret({
        id: singleCompany?.id,
        file: newFile,
        handleApiResponseSuccess,
        handleApiResponseError,
      })
    );
    setFile(newFile);
  };

  const hiddenValidationText = useMemo(() => {
    return formik?.values?.driveFolderId?.trim().length === 0;
  }, [formik?.values]);

  useEffect(() => {
    props?.onFileChange(file);
    if (file) formik?.setFieldValue?.("hasCredentialsFile", true);
  }, [file]);

  return (
    <GoogleDriveInfoContainer>
      <TextInputField
        name="driveFolderId"
        style={{ width: "30px" }}
        placeholder={t("companySettings.archive.googleDrive.driveId")}
        label={t("companySettings.archive.googleDrive.driveId")}
        value={formik?.values?.driveFolderId}
        onChange={formik?.handleChange}
        error={formik?.touched?.driveFolderId && formik?.errors?.driveFolderId}
        helperText={
          formik?.touched?.driveFolderId && formik?.errors?.driveFolderId
        }
        requiredField={
          formik?.values?.automaticallyUploadToDrive &&
          !formik?.values?.driveFolderId
        }
      />
      <FileJsonPicker
        id={singleCompany?.id}
        dispatchFunction={uploadJson}
        fileUploaded={file != null || formik?.values?.hasCredentialsFile}
        descriptionText={
          <Trans i18nKey="companySettings.archive.googleDrive.descriptionText" />
        }
        addedFileText={
          <Trans i18nKey="companySettings.archive.googleDrive.addedFileDesc" />
        }
      />
      {formik?.values?.automaticallyUploadToDrive &&
        !formik?.values?.hasCredentialsFile && (
          <ErrorMessage>{t(formik?.errors?.hasCredentialsFile)}</ErrorMessage>
        )}
      <VerificationContent $extraBottomPadding={hiddenValidationText}>
        {hiddenValidationText && (
          <VerificationTextContainer>
            <VerificationText>
              {t("companySettings.archive.googleDrive.verifyText")}
            </VerificationText>
          </VerificationTextContainer>
        )}
        <VerificationButton
          disabled={verificationDisabled}
          onClick={validateDrive}
        >
          {t("companySettings.archive.googleDrive.verify")}
        </VerificationButton>
      </VerificationContent>
    </GoogleDriveInfoContainer>
  );
};

GoogleDriveInfo.propTypes = {
  formik: PropTypes.object,
  onFileChange: PropTypes.any,
};
export default GoogleDriveInfo;
