import { Box } from "@mui/material";
import styled from "styled-components";
import { hexToRGB } from "../../../util/helpers/colorHelper";
import selectedTheme from "../../../themes";

export const TableModalContainer = styled(Box)`
  padding: 24px;
  background-color: white;
  box-shadow: 0px 20px 24px -4px ${hexToRGB(selectedTheme.colors.shadowColor, 0.08)},
    0px 8px 8px -4px ${hexToRGB(selectedTheme.colors.shadowColor, 0.03)};
  border-radius: 12px;
  max-width: 1000px;
  width: fit-content;
  min-width: 350px;
  position: relative;
  display: inline-flex;
  flex-direction: column;
  gap: 16px;
  overflow-y: auto;
  overflow-x: hidden;
  @media (max-width: 600px) {
    width: 100vw;
    height: 100vh;
    border-radius: 0;
  }
`;
export const TableContainer = styled(Box)`
  /* overflow-y: auto; */
  width: 100%;
  & > div {
    width: 100%;
  }
`;
