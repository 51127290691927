import React from "react";
// import PropTypes from 'prop-types'
import { ReactComponent as OrderedListIconSvg } from "../../../assets/images/lexical/list-ol.svg";

const OrderedListIcon = (props) => {
  return <OrderedListIconSvg {...props} />;
};

OrderedListIcon.propTypes = {};

export default OrderedListIcon;
