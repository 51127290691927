import {
    deleteRequest,
    getRequest,
    postRequest,
    putRequest,
    replaceInUrl,
  } from ".";
  import apiEndpoints from "./apiEndpoints";
  
  export const attemptFetchDocumentTypes = (payload) =>
    getRequest(apiEndpoints.documentTypes.getDocumentTypes, payload);
  
  export const attemptCreateDocumentType = (payload) =>
    postRequest(apiEndpoints.documentTypes.createDocumentType, payload);
  
  export const attemptFetchSingleDocumentType = (payload) =>
    getRequest(
      replaceInUrl(apiEndpoints.documentTypes.getSingleDocumentType, {
        documentTypeId: payload?.documentTypeId,
      })
    );
  
  export const attemptEditSingleDocumentType = (payload) =>
    putRequest(
      replaceInUrl(apiEndpoints.documentTypes.editDocumentType, {
        documentTypeId: payload?.documentTypeId,
      }),
      payload?.data
    );
  
  export const attemptDeleteDocumentType = (payload) =>
    deleteRequest(
      replaceInUrl(apiEndpoints.documentTypes.deleteDocumentType, {
        documentTypeId: payload?.documentTypeId,
      })
    );
  