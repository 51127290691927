import React from "react";
// import PropTypes from 'prop-types'
import { ReactComponent as CopyIconSvg } from "../../../assets/images/svg/copy.svg";

const CopyIcon = (props) => {
  return <CopyIconSvg {...props} />;
};

CopyIcon.propTypes = {};

export default CopyIcon;
