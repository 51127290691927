import { pagesAsArray } from "../../constants/pages";
import history from "../../store/utils/history";
import { removeDuplicates } from "./arrayHelpers";

export const replaceInRoute = (route, pathVariables = {}) => {
  const keys = Object.keys(pathVariables);
  return keys.reduce(
    (acc, key) => acc.replace(`:${key}`, pathVariables[`${key}`]),
    route
  );
};

export const getRouteName = (route) =>
  route.split("/")[route.split("/").length - 1];

// export const getRoutesBreadCrumb = () => {
//   let breadCrumb = [];
//   let currentRoute = history.location.pathname;
//   if (currentRoute[currentRoute.length - 1] === "/") {
//     currentRoute = currentRoute.slice(0, -1);
//   }
//   while (currentRoute.length > 1) {
//     let isDynamic = false;
//     for (let i = 0; i < pagesAsArray.length; i++) {
//       if (
//         routeMatches(pagesAsArray[i].route, currentRoute) ||
//         dynamicRouteMatches(pagesAsArray[i].route, currentRoute)
//       ) {
//         breadCrumb.push(pagesAsArray[i]);
//         isDynamic = dynamicRouteMatches(pagesAsArray[i].route, currentRoute);
//         break;
//       }
//     }
//     let indexOfSlash = currentRoute.lastIndexOf("/");
//     if (!indexOfSlash) return breadCrumb.reverse();
//     currentRoute = currentRoute.slice(0, indexOfSlash);
//     if (isDynamic) {
//       let indexOfSlash = currentRoute.lastIndexOf("/");
//       currentRoute = currentRoute.slice(0, indexOfSlash);
//     }
//   }
//   return breadCrumb;
// };

export const isAuthRoute = () => {
  // Check if route is auth
  // if (
  //   routeMatches(LOGIN_PAGE) ||
  //   routeMatches(REGISTER_PAGE) ||
  //   routeMatches(REGISTER_SUCCESSFUL_PAGE) ||
  //   routeMatches(FORGOT_PASSWORD_PAGE) ||
  //   routeMatches(FORGOT_PASSWORD_MAIL_SENT) ||
  //   routeMatches(ADMIN_LOGIN_PAGE) ||
  //   dynamicRouteMatches(RESET_PASSWORD_PAGE)
  // ) {
  //   return true;
  // }
  return false;
};
export const isAdminRoute = () => {
  // Check if route is admin
  // if (
  //   routeMatches(ADMIN_LOGIN_PAGE) ||
  //   routeMatches(ADMIN_HOME_PAGE) ||
  //   routeMatches(ADMIN_USERS_PAGE) ||
  //   routeMatches(ADMIN_CATEGORIES_PAGE) ||
  //   dynamicRouteMatches(ADMIN_SUBCATEGORIES_PAGE) ||
  //   routeMatches(ADMIN_LOCATIONS_PAGE) ||
  //   routeMatches(ADMIN_PAYMENT_PAGE) ||
  //   dynamicRouteMatches(ADMIN_SINGLE_USER_PAGE) ||
  //   dynamicRouteMatches(ADMIN_ITEM_DETAILS_PAGE) ||
  //   isInRoute(ADMIN_HOME_PAGE)
  // )
  //   return true;
  return false;
};

export const isInRoute = (routeToCheck) => {
  return (
    history.location.pathname.includes(routeToCheck) ||
    dynamicRouteMatches(routeToCheck)
  );
};
export const getCurrentRoute = () => {
  return pagesAsArray.find(
    (singlePage) =>
      routeMatches(singlePage?.route) || dynamicRouteMatches(singlePage?.route)
  )?.route;
};

export const getRoutesBreadCrumb = () => {
  let breadCrumb = [];
  let currentRoute = history.location.pathname;
  if (currentRoute[currentRoute.length - 1] === "/") {
    currentRoute = currentRoute.slice(0, -1);
  }
  while (currentRoute.length > 1) {
    for (let i = 0; i < pagesAsArray.length; i++) {
      if (routeMatches(pagesAsArray[i].route, currentRoute)) {
        breadCrumb.push(pagesAsArray[i]);
        break;
      }
      if (dynamicRouteMatches(pagesAsArray[i].route, currentRoute)) {
        const params = getRouteParams(pagesAsArray[i].route);
        breadCrumb.push({
          i18key: pagesAsArray[i]?.i18key,
          route: replaceInRoute(pagesAsArray[i]?.route, params),
          icon: pagesAsArray[i]?.icon,
        });
        break;
      }
    }
    let indexOfSlash = currentRoute.lastIndexOf("/");
    if (!indexOfSlash) return removeDuplicates(breadCrumb.reverse(), "route");
    currentRoute = currentRoute.slice(0, indexOfSlash);
  }

  return removeDuplicates(breadCrumb, "route");
};
export const dynamicRouteMatches = (dynamicRoute, secondRoute) => {
  const routeToCheck = secondRoute || history.location.pathname;
  let newDynamicRoute = dynamicRoute;
  while (newDynamicRoute.includes(":")) {
    let indexOfDynamicChar = newDynamicRoute.indexOf(":");

    let indexOfNextSlashDynamicRoute =
      newDynamicRoute.slice(indexOfDynamicChar).indexOf("/") +
      indexOfDynamicChar;
    if (newDynamicRoute.slice(indexOfDynamicChar).indexOf("/") === -1)
      indexOfNextSlashDynamicRoute = newDynamicRoute?.length;
    let indexOfNextSlashRouteToChack =
      routeToCheck.slice(indexOfDynamicChar).indexOf("/") + indexOfDynamicChar;
    if (routeToCheck.slice(indexOfDynamicChar).indexOf("/") === -1)
      indexOfNextSlashRouteToChack = routeToCheck?.length;

    newDynamicRoute =
      newDynamicRoute.slice(0, indexOfDynamicChar) +
      routeToCheck.slice(indexOfDynamicChar, indexOfNextSlashRouteToChack) +
      newDynamicRoute.slice(indexOfNextSlashDynamicRoute);
  }
  return routeMatches(routeToCheck, newDynamicRoute);
};

export const getRouteParams = (dynamicRoute) => {
  const routeToCheck = history.location.pathname;
  let newDynamicRoute = dynamicRoute;
  let params = {};
  while (newDynamicRoute.includes(":")) {
    let indexOfDynamicChar = newDynamicRoute.indexOf(":");
    let indexOfNextSlashDynamicRoute =
      newDynamicRoute.slice(indexOfDynamicChar).indexOf("/") +
      indexOfDynamicChar;
    if (newDynamicRoute.slice(indexOfDynamicChar).indexOf("/") === -1)
      indexOfNextSlashDynamicRoute = newDynamicRoute?.length;
    let indexOfNextSlashRouteToChack =
      routeToCheck.slice(indexOfDynamicChar).indexOf("/") + indexOfDynamicChar;
    if (routeToCheck.slice(indexOfDynamicChar).indexOf("/") === -1)
      indexOfNextSlashRouteToChack = routeToCheck?.length;

    let paramName = newDynamicRoute.slice(
      indexOfDynamicChar + 1,
      indexOfNextSlashDynamicRoute
    );
    params = {
      ...params,
      [paramName]: routeToCheck.slice(
        indexOfDynamicChar,
        indexOfNextSlashRouteToChack
      ),
    };
    newDynamicRoute =
      newDynamicRoute.slice(0, indexOfDynamicChar) +
      routeToCheck.slice(indexOfDynamicChar, indexOfNextSlashRouteToChack) +
      newDynamicRoute.slice(indexOfNextSlashDynamicRoute);
  }
  return params;
};
export const routeMatches = (route, secondRoute = null) => {
  let routeToCheck = secondRoute || history.location.pathname;
  if (
    routeToCheck === route ||
    routeToCheck + "/" === route ||
    routeToCheck.slice(0, -1) === route
  )
    return true;
  return false;
};
