const MAX_WIDTH = 1920;
const MAX_HEIGHT = 1080;

export const getImageSizes = (oldWidth, oldHeight) => {
  let widthMinimizingFactor = oldWidth / MAX_WIDTH;
  let heightMinimizingFactor = oldHeight / MAX_HEIGHT;
  if (widthMinimizingFactor > 1 || heightMinimizingFactor > 1) {
    if (widthMinimizingFactor > heightMinimizingFactor) {
      let newWidth = oldWidth / widthMinimizingFactor;
      let newHeight = oldHeight / widthMinimizingFactor;
      return {
        height: newHeight,
        width: newWidth,
      };
    } else {
      let newWidth = oldWidth / heightMinimizingFactor;
      let newHeight = oldHeight / heightMinimizingFactor;
      return {
        height: newHeight,
        width: newWidth,
      };
    }
  }
  return {
    height: oldHeight,
    width: oldWidth,
  };
};
export const getImageFormatFromBase64 = (image) => {
  image.indexOf("data:");
  image.indexOf(";");
  let mimeType = image?.slice(
    image.indexOf("data:") + "data:".length,
    image.indexOf(";")
  );
  let extension = mimeType.slice(mimeType.indexOf("image/") + "image/".length);
  return { mimeType, extension };
};
