import React from "react";
// import PropTypes from 'prop-types'
import { ReactComponent as HomeIconSvg } from "../../../assets/images/svg/home-red.svg";

const HomeIcon = (props) => {
  return <HomeIconSvg {...props} />;
};

HomeIcon.propTypes = {};

export default HomeIcon;
