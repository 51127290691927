import React, { useCallback, useEffect, useMemo, useRef } from "react";
import PropTypes from "prop-types";
import Table from "../../Table/Table";
import clientsTableConstants from "../../../constants/tableConstants/clientsTableConstants";
import TextRenderer from "../../Table/Renderers/TextRenderer/TextRenderer";
import { useTranslation } from "react-i18next";
import {
  selectClientsData,
  selectTotalClients,
} from "../../../store/selectors/clientsSelector";
import {
  fetchClientClear,
  fetchClients,
} from "../../../store/actions/clients/clientsActions";
import { useHistory, useLocation } from "react-router-dom";
import { EDIT_CLIENT_PAGE, NEW_CLIENT_PAGE } from "../../../constants/pages";
import { formatDateTime } from "../../../util/helpers/dateHelpers";
import { makeToastMessage } from "../../../store/utils/makeToastMessage";
import { APPLICATION_TOAST_CONTAINER_ID } from "../../../constants/toastConstants";
import useAuth from "../../../hooks/useAuth";
import { MANAGER_ROLE } from "../../../constants/rolesConstants";
import { replaceInRoute } from "../../../util/helpers/routeHelpers";

const ClientsTable = () => {
  const history = useHistory();
  const location = useLocation();
  const tableRef = useRef();
  const { t } = useTranslation();

  const { hasRole } = useAuth();

  const hideAddAndUpdate = useMemo(() => {
    return !hasRole(MANAGER_ROLE);
  }, [hasRole]);

  const handleClickAddButton = () => {
    history.push(NEW_CLIENT_PAGE);
  };

  const handleRedirect = (clientId) =>
    replaceInRoute(EDIT_CLIENT_PAGE, {
      clientId: clientId,
    });

  const tableColumns = useMemo(() => {
    let columnsObject = Object.keys(clientsTableConstants).map((property) => ({
      ...clientsTableConstants[property],
      field: t(clientsTableConstants[property].i18key),
      propertyName: property,
      backendProperty: clientsTableConstants[property].backendProperty,
      style: clientsTableConstants[property]?.style,
      renderer: clientsTableConstants[property]?.renderer || TextRenderer,
    }));
    return columnsObject;
  }, [clientsTableConstants, t, hasRole]);

  const mapDataFunction = useCallback(
    (newData) => {
      return newData?.map?.((singleData) => {
        let mappedDataObject = {
          clients: singleData?.name,
          pib: singleData?.tin,
          address: singleData?.address,
          idNumber: singleData?.identificationNumber,
          lastChanges: {
            date: formatDateTime(
              singleData?.updatedAtUtc || new Date().toString()
            ),
            author: `${singleData?.updatedByUser?.firstName || ""} ${
              singleData?.updatedByUser?.lastName || ""
            }`,
          },
          id: singleData?.id,
          actions: {
            clientId: singleData?.id,
            clients: singleData?.name,
            address: singleData?.address,
            phoneNumber: singleData?.phoneNumber,
            pib: singleData?.tin,
            idNumber: singleData?.identificationNumber,
            lastChanges: {
              date: formatDateTime(
                singleData?.updatedAtUtc || new Date().toString()
              ),
              author: `${singleData?.updatedByUser?.firstName || ""} ${
                singleData?.updatedByUser?.lastName || ""
              }`,
            },
            emails: singleData?.distributionEmails?.split?.(";"),
            totalData: newData?.length,
            tableRef,
            t,
          },
        };
        return mappedDataObject;
      });
    },
    [hasRole]
  );

  useEffect(() => {
    if (location?.state?.isDeleted) {
      makeToastMessage(
        {
          title: t("toast.success.deletedTitle", {
            typeOfData: t("clients.typeOfData"),
          }),
          description: t("toast.success.deletedDescription", {
            name: location.state?.name,
            typeOfData: t("clients.typeOfDataPlural").toLowerCase(),
          }),
        },
        {
          containerId: APPLICATION_TOAST_CONTAINER_ID,
        }
      );
      let currentState = { ...location?.state };
      delete currentState.name;
      delete currentState.isDeleted;
      history.replace({
        state: currentState,
      });
    }
    if (location?.state?.refetch) {
      tableRef?.current?.forceRefetch();
    }
  }, [location]);

  return (
    <Table
      tableColumns={tableColumns}
      hideAddButton={hideAddAndUpdate}
      searchPlaceholder={t("clients.table.searchPlaceholder")}
      dataSelector={selectClientsData}
      totalDataSelector={selectTotalClients}
      dispatchFunction={fetchClients}
      mapDataFunction={mapDataFunction}
      redirectHrefFunction={handleRedirect}
      addButtonHref={{
        pathname: NEW_CLIENT_PAGE,
        state: {
          page: tableRef?.current?.page,
          searchValue: tableRef?.current?.searchValue,
        },
      }}
      handleClickAddButton={handleClickAddButton}
      clearDispatchFunction={fetchClientClear}
      ref={tableRef}
    />
  );
};

ClientsTable.propTypes = {
  children: PropTypes.node,
};

export default ClientsTable;
