import React, { useEffect } from "react";
import { useState } from "react";
import { ConclusionTextareaField } from "./ConclusionField.styled";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import {
  setScanningObjectsChanged,
  setScanningObjectsConclusion,
} from "../../../../store/actions/scanningObjects/scanningObjectsActions";

const ConclusionField = (props) => {
  const [value, setValue] = useState("");
  const dispatch = useDispatch();

  const handleClickConclusion = () => {
    dispatch(setScanningObjectsChanged(true));
  };

  const handleBlurConclusion = (newAnswer) => {
    setValue(newAnswer);
    dispatch(setScanningObjectsChanged(true));
    dispatch(
      setScanningObjectsConclusion({ type: props?.type, conclusion: newAnswer })
    );
  };

  useEffect(() => {
    setValue(props?.conclusion === null ? "" : props?.conclusion);
  }, [props?.conclusion]);

  return (
    <ConclusionTextareaField
      placeholder={props?.placeholder}
      title={props?.title}
      value={value}
      disabled={props?.disabled}
      onBlur={handleBlurConclusion}
      onClickFnc={handleClickConclusion}
      isRichText
    />
  );
};

ConclusionField.propTypes = {
  placeholder: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string,
  conclusion: PropTypes.string,
  disabled: PropTypes.bool,
};

export default ConclusionField;
