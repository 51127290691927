import React, { useCallback, useMemo, useRef } from "react";
import PropTypes from "prop-types";
import Table from "../../../Table/Table";
import { useTranslation } from "react-i18next";
import { formatDateTime } from "../../../../util/helpers/dateHelpers";
import useAuth from "../../../../hooks/useAuth";
import {
  MANAGER_ROLE,
  OPERATOR_ROLE,
} from "../../../../constants/rolesConstants";
import findingsTableConstants from "../../../../constants/tableConstants/dashboardTables/findingsTableCreatedConstants";
import {
  selectFindingsData,
  selectTotalFindings,
} from "../../../../store/selectors/findingsSelectors";
import {
  clearFindings,
  fetchFindings,
} from "../../../../store/actions/findings/findingsActions";
import TextRenderer from "../../../Table/Renderers/TextRenderer/TextRenderer";
import { FINDINGS_SCOPE } from "../../../../store/actions/findings/findingsActionConstants";

const FindingsTable = () => {
  const tableRef = useRef();
  const { t } = useTranslation();

  const { hasRole } = useAuth();

  const hideAddAndUpdate = useMemo(() => {
    return !(hasRole(OPERATOR_ROLE) || hasRole(MANAGER_ROLE));
  }, [hasRole]);

  const tableColumns = useMemo(() => {
    let columnsObject = Object.keys(findingsTableConstants).map((property) => ({
      ...findingsTableConstants[property],
      field: t(findingsTableConstants[property].i18key),
      propertyName: property,
      backendProperty:
        findingsTableConstants[property].backendProperty || property,
      style: findingsTableConstants[property]?.style,
      renderer: findingsTableConstants[property]?.renderer || TextRenderer,
    }));
    if (hideAddAndUpdate) return columnsObject.slice(0, -1);
    return columnsObject;
  }, [findingsTableConstants, t, hasRole]);

  const mapDataFunction = useCallback(
    (newData) => {
      return newData?.map?.((singleData) => {
        let mappedDataObject = {
          client: singleData?.clientName,
          created: {
            date: formatDateTime(
              singleData?.createdAtUtc || new Date().toString()
            ),
            author: `${singleData?.createdByUser?.firstName || ""} ${
              singleData?.createdByUser?.lastName || ""
            }`,
          },
          status:
            singleData?.findingStateId === 0 ? 1 : singleData?.findingStateId,
          id: singleData?.id,
        };
        if (hideAddAndUpdate) delete mappedDataObject?.actions;
        return mappedDataObject;
      });
    },
    [hasRole]
  );

  return (
    <Table
      hideAddButton
      hidePaging
      hideHeader
      sortingColumn="createdAtUtc"
      initialSize={4}
      sortDesc
      isStatic
      loadingActionType={FINDINGS_SCOPE}
      tableColumns={tableColumns}
      dataSelector={selectFindingsData}
      totalDataSelector={selectTotalFindings}
      dispatchFunction={fetchFindings}
      customPayload={{ isSecondTable: false }}
      mapDataFunction={mapDataFunction}
      clearDispatchFunction={clearFindings}
      ref={tableRef}
    />
  );
};

FindingsTable.propTypes = {
  children: PropTypes.node,
  isLoading: PropTypes.bool,
};

export default FindingsTable;
