import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import NewStuffForm from "../../../components/Stuffs/NewStuff/NewStuffForm";
import { fetchCreateStuff } from "../../../store/actions/stuffs/stuffsActions";

const NewStuffPage = () => {
  const { t } = useTranslation();
  // Add layout
  return (
    <NewStuffForm
      dispatchFunction={fetchCreateStuff}
      title={t("stuffs.newStuff")}
      submitText={t("common.save")}
    />
  );
};

NewStuffPage.propTypes = {
  children: PropTypes.node,
};

export default NewStuffPage;
