import React from "react";
// import PropTypes from 'prop-types'
import { ReactComponent as CenterAlignIconSvg } from "../../../assets/images/lexical/text-center.svg";

const CenterAlignIcon = (props) => {
  return <CenterAlignIconSvg {...props} />;
};

CenterAlignIcon.propTypes = {};

export default CenterAlignIcon;
