import { createSelector } from "reselect";

const standardsSelector = (state) => state.standards;

export const selectStandards = createSelector(
  standardsSelector,
  (state) => state.standards
);
export const selectTotalStandards = createSelector(
  standardsSelector,
  (state) => state.totalStandards
);
export const selectSingleStandard = createSelector(
  standardsSelector,
  (state) => state.singleStandard
);
export const selectStandardsData = createSelector(
  standardsSelector,
  (state) => state.standards.data
)
