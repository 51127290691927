import React from "react";
// import PropTypes from 'prop-types'
import { ReactComponent as ClientsBreadIconSvg } from "../../../assets/images/svg/clients-bc.svg";

const ClientsBreadIcon = (props) => {
  return <ClientsBreadIconSvg {...props} />;
};

ClientsBreadIcon.propTypes = {};

export default ClientsBreadIcon;
