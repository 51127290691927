import {
  MEASURING_INSTRUMENTS_ERROR,
  MEASURING_INSTRUMENTS_FETCH,
  MEASURING_INSTRUMENTS_SUCCESS,
  MEASURING_INSTRUMENTS_SET,
  SINGLE_MEASURING_INSTRUMENT_ERROR,
  SINGLE_MEASURING_INSTRUMENT_FETCH,
  SINGLE_MEASURING_INSTRUMENT_SUCCESS,
  SINGLE_MEASURING_INSTRUMENT_SET,
  MEASURING_INSTRUMENTS_CREATE_ERROR,
  MEASURING_INSTRUMENTS_CREATE_FETCH,
  MEASURING_INSTRUMENTS_CREATE_SUCCESS,
  MEASURING_INSTRUMENTS_EDIT_ERROR,
  MEASURING_INSTRUMENTS_EDIT_FETCH,
  MEASURING_INSTRUMENTS_EDIT_SUCCESS,
  MEASURING_INSTRUMENTS_CLEAR,
  MEASURING_INSTRUMENTS_SET_TOTAL,
  MEASURING_INSTRUMENTS_DELETE_FETCH,
  MEASURING_INSTRUMENTS_DELETE_SUCCESS,
  MEASURING_INSTRUMENTS_DELETE_ERROR,
  MEASURING_INSTRUMENTS_SET_ERROR_MESSAGE
} from "./measuringInstrumentsActionConstants.js";

export const fetchMeasuringInstruments = (payload) => ({
  type: MEASURING_INSTRUMENTS_FETCH,
  payload,
});
export const fetchMeasuringInstrumentsSuccess = (payload) => ({
  type: MEASURING_INSTRUMENTS_SUCCESS,
  payload,
});
export const fetchMeasuringInstrumentsError = (payload) => ({
  type: MEASURING_INSTRUMENTS_ERROR,
  payload,
});
export const fetchCreateMeasuringInstrument = (payload) => ({
  type: MEASURING_INSTRUMENTS_CREATE_FETCH,
  payload,
});
export const fetchCreateMeasuringInstrumentSuccess = (payload) => ({
  type: MEASURING_INSTRUMENTS_CREATE_SUCCESS,
  payload,
});
export const fetchCreateMeasuringInstrumentError = (payload) => ({
  type: MEASURING_INSTRUMENTS_CREATE_ERROR,
  payload,
});
export const fetchEditMeasuringInstrument = (payload) => ({
  type: MEASURING_INSTRUMENTS_EDIT_FETCH,
  payload,
});
export const fetchEditMeasuringInstrumentSuccess = (payload) => ({
  type: MEASURING_INSTRUMENTS_EDIT_SUCCESS,
  payload,
});
export const fetchEditMeasuringInstrumentError = (payload) => ({
  type: MEASURING_INSTRUMENTS_EDIT_ERROR,
  payload,
});

export const fetchDeleteMeasuringInstrument = (payload) => ({
  type: MEASURING_INSTRUMENTS_DELETE_FETCH,
  payload,
});
export const fetchDeleteMeasuringInstrumentSuccess = (payload) => ({
  type: MEASURING_INSTRUMENTS_DELETE_SUCCESS,
  payload,
});
export const fetchDeleteMeasuringInstrumentError = (payload) => ({
  type: MEASURING_INSTRUMENTS_DELETE_ERROR,
  payload,
});

export const fetchSingleMeasuringInstrument = (payload) => ({
  type: SINGLE_MEASURING_INSTRUMENT_FETCH,
  payload,
});
export const fetchSingleMeasuringInstrumentSuccess = (payload) => ({
  type: SINGLE_MEASURING_INSTRUMENT_SUCCESS,
  payload,
});
export const fetchSingleMeasuringInstrumentError = (payload) => ({
  type: SINGLE_MEASURING_INSTRUMENT_ERROR,
  payload,
});
export const setMeasuringInstruments = (payload) => ({
  type: MEASURING_INSTRUMENTS_SET,
  payload,
});
export const setMeasuringInstrumentsTotal = (payload) => ({
  type: MEASURING_INSTRUMENTS_SET_TOTAL,
  payload,
});
export const clearMeasuringInstruments = () => ({
  type: MEASURING_INSTRUMENTS_CLEAR,
});

export const setSingleMeasuringInstrument = (payload) => ({
  type: SINGLE_MEASURING_INSTRUMENT_SET,
  payload,
});
export const setMeasuringInstrumentsErrorMessage = (payload) => ({
  type: MEASURING_INSTRUMENTS_SET_ERROR_MESSAGE,
  payload,
});
