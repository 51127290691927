import { Box } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../../themes";

export const ToolbarContainer = styled(Box)`
  display: flex;
  margin-bottom: 1px;
  padding: 4px;
  flex-wrap: wrap;
  border-bottom: 1px solid ${selectedTheme.colors.inputBorderLighterColor};
`;
