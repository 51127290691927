import {
  CLEAR_CLIENTS,
  CREATE_CLIENT_ERROR,
  CREATE_CLIENT_FETCH,
  CREATE_CLIENT_SUCCESS,
  DELETE_CLIENT_ERROR,
  DELETE_CLIENT_FETCH,
  DELETE_CLIENT_SUCCESS,
  EDIT_CLIENT_ERROR,
  EDIT_CLIENT_FETCH,
  EDIT_CLIENT_SUCCESS,
  GET_CLIENTS_CLEAR,
  GET_CLIENTS_ERROR,
  GET_CLIENTS_FETCH,
  GET_CLIENTS_SUCCESS,
  SET_CLIENT,
  SET_CLIENTS,
  SET_CLIENT_ERROR,
  SET_TOTAL_CLIENTS,
  SINGLE_CLIENT_CLEAR,
  SINGLE_CLIENT_ERROR,
  SINGLE_CLIENT_FETCH,
  SINGLE_CLIENT_SET,
  SINGLE_CLIENT_SUCCESS,
} from "./clientsActionConstants";

export const setClient = (payload) => ({
  type: SET_CLIENT,
  payload,
});

export const setClientError = (payload) => ({
  type: SET_CLIENT_ERROR,
  payload,
});

export const fetchClients = (payload) => ({
  type: GET_CLIENTS_FETCH,
  payload,
});
export const fetchClientsSuccess = (payload) => ({
  type: GET_CLIENTS_SUCCESS,
  payload,
});
export const fetchClientsError = (payload) => ({
  type: GET_CLIENTS_ERROR,
  payload,
});

export const fetchClientClear = () => ({
  type: GET_CLIENTS_CLEAR,
});

export const fetchCreateClient = (payload) => ({
  type: CREATE_CLIENT_FETCH,
  payload,
});
export const fetchCreateClientSuccess = (payload) => ({
  type: CREATE_CLIENT_SUCCESS,
  payload,
});
export const fetchCreateClientError = (payload) => ({
  type: CREATE_CLIENT_ERROR,
  payload,
});

export const fetchEditClient = (payload) => ({
  type: EDIT_CLIENT_FETCH,
  payload,
});
export const fetchEditClientSuccess = (payload) => ({
  type: EDIT_CLIENT_SUCCESS,
  payload,
});
export const fetchEditClientError = (payload) => ({
  type: EDIT_CLIENT_ERROR,
  payload,
});

export const fetchDeleteClient = (payload) => ({
  type: DELETE_CLIENT_FETCH,
  payload,
});
export const fetchDeleteClientSuccess = (payload) => ({
  type: DELETE_CLIENT_SUCCESS,
  payload,
});
export const fetchDeleteClientError = (payload) => ({
  type: DELETE_CLIENT_ERROR,
  payload,
});

export const fetchSingleClient = (payload) => ({
  type: SINGLE_CLIENT_FETCH,
  payload,
});
export const fetchSingleClientSuccess = (payload) => ({
  type: SINGLE_CLIENT_SUCCESS,
  payload,
});
export const fetchSingleClientError = (payload) => ({
  type: SINGLE_CLIENT_ERROR,
  payload,
});
export const clearSingleClient = (payload) => ({
  type: SINGLE_CLIENT_CLEAR,
  payload,
});

export const setSingleClient = (payload) => ({
  type: SINGLE_CLIENT_SET,
  payload,
});
export const setClients = (payload) => ({
  type: SET_CLIENTS,
  payload,
});
export const setTotalClients = (payload) => ({
  type: SET_TOTAL_CLIENTS,
  payload,
});
export const clearClients = () => ({
  type: CLEAR_CLIENTS,
});
