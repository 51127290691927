import React from "react";
// import PropTypes from 'prop-types'
import { ReactComponent as EyeIconSvg } from "../../../assets/images/svg/eye-on.svg";

const EyeIcon = (props) => {
  return <EyeIconSvg {...props} />;
};

EyeIcon.propTypes = {};

export default EyeIcon;
