import {
  SINGLE_MEASURING_INSTRUMENT_SET,
  MEASURING_INSTRUMENTS_CLEAR,
  MEASURING_INSTRUMENTS_SET,
  MEASURING_INSTRUMENTS_SET_TOTAL,
  MEASURING_INSTRUMENTS_SET_ERROR_MESSAGE,
  MEASURING_INSTRUMENTS_CREATE_ERROR,
  MEASURING_INSTRUMENTS_ERROR,
  MEASURING_INSTRUMENTS_EDIT_ERROR,
  SINGLE_MEASURING_INSTRUMENT_ERROR,
  MEASURING_INSTRUMENTS_DELETE_ERROR,
} from "../../actions/measuringInstruments/measuringInstrumentsActionConstants";
import createReducer from "../../utils/createReducer";

const initialState = {
  measuringInstruments: {
    loaded: false,
    data: [],
  },
  totalMeasuringInstruments: 0,
  singleMeasuringInstrument: {},
  errorMessage: "",
};

export default createReducer(
  {
    [MEASURING_INSTRUMENTS_SET]: setMeasuringInstruments,
    [MEASURING_INSTRUMENTS_SET_TOTAL]: setMeasuringInstrumentsTotal,
    [MEASURING_INSTRUMENTS_CLEAR]: clearMeasuringInstruments,
    [SINGLE_MEASURING_INSTRUMENT_SET]: setSingleMeasuringInstrument,
    [MEASURING_INSTRUMENTS_SET_ERROR_MESSAGE]: setErrorMessage,
    [MEASURING_INSTRUMENTS_CREATE_ERROR]: setErrorMessage,
    [MEASURING_INSTRUMENTS_DELETE_ERROR]: setErrorMessage,
    [MEASURING_INSTRUMENTS_ERROR]: setErrorMessage,
    [MEASURING_INSTRUMENTS_EDIT_ERROR]: setErrorMessage,
    [SINGLE_MEASURING_INSTRUMENT_ERROR]: setErrorMessage,
    [MEASURING_INSTRUMENTS_SET_ERROR_MESSAGE]: setErrorMessage,
  },
  initialState
);
function setSingleMeasuringInstrument(state, { payload }) {
  return {
    ...state,
    singleMeasuringInstrument: payload,
    errorMessage: "",
  };
}
function setMeasuringInstruments(state, { payload }) {
  return {
    ...state,
    measuringInstruments: payload,
  };
}
function setMeasuringInstrumentsTotal(state, { payload }) {
  return {
    ...state,
    totalMeasuringInstruments: payload,
  };
}
function clearMeasuringInstruments(state) {
  return {
    ...state,
    measuringInstruments: initialState.measuringInstruments,
    totalMeasuringInstruments: initialState.totalMeasuringInstruments,
  };
}
function setErrorMessage(state, { payload }) {
  return {
    ...state,
    errorMessage: payload,
  };
}
