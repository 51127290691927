import { Box } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../../../themes";

export const FindingStatusCardsChainContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 15px;
  align-items: center;
  margin-bottom: 36px;
  @media (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 36px;
  }
`;
export const FindingStatusCardsChainVerticalSeparator = styled(Box)`
  height: 13.5px;
  width: 1px;
  background-color: ${selectedTheme.colors.findingStatus.separator};
  @media (max-width: 600px) {
    display: none;
  }
`;
export const FindingStatusCardsChainHorizontalSeparator = styled(Box)`
  height: 1px;
  width: 23px;
  background-color: ${selectedTheme.colors.findingStatus.separator};
`;
export const FindingStatusCardsConnectedChain = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
export const CloseModalInnerDescriptionContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
export const CloseModalInnerDescriptionText = styled(Box)`
  font-family: ${selectedTheme.fonts.mainText};
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  font-feature-settings: "salt" on;
  color: ${selectedTheme.colors.deviceColor};
`;
export const CloseModalInnerDescriptionNumber = styled(Box)`
  font-family: ${selectedTheme.fonts.mainText};
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  font-feature-settings: "salt" on;
  color: ${selectedTheme.colors.orange};
  display: inline-block;
`;
export const TooltipContainer = styled(Box)`
  white-space: nowrap;
  @media (max-width: 600px) {
    white-space: normal;
    margin-bottom: 8px;
    &:nth-last-child() {
      margin-bottom: 0;
    }
  }
`;
