import React, { useEffect } from "react";
import { logoutUser } from "../../store/actions/login/loginActions";
import { useDispatch } from "react-redux";

const LogoutPage = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(logoutUser());
  }, []);
  return <></>;
};

export default LogoutPage;
