import React from "react";
// import PropTypes from 'prop-types'
import { ReactComponent as SidebarIconSvg } from "../../../assets/images/svg/sidebar.svg";

const SidebarIcon = (props) => {
  return <SidebarIconSvg {...props} />;
};

SidebarIcon.propTypes = {};

export default SidebarIcon;
