import React from "react";
import PropTypes from "prop-types";
import { LanguagePickerContainer } from "./LanguagePicker.styled";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentLanguage } from "../../store/selectors/translationsSelectors";
import { useMemo } from "react";
import { EN_CULTURE, RS_CULTURE, DE_CULTURE } from "../../constants/culturesConstants";
import { fetchTranslationsResource } from "../../store/actions/translations/translationsActions";

const LanguagePicker = (props) => {
  const dispatch = useDispatch();
  const currentLanguage = useSelector(selectCurrentLanguage);

  const selectedLanguage = useMemo(() => {
    if (typeof currentLanguage === "string")
      return currentLanguage?.toUpperCase?.();
    return RS_CULTURE.toUpperCase();
  }, [currentLanguage]);

  const handleChangeLanguage = () => {
    let nextCulture;
    if (currentLanguage === RS_CULTURE) {
      nextCulture = EN_CULTURE;
    } else if (currentLanguage === EN_CULTURE) {
      nextCulture = DE_CULTURE; 
    } else {
      nextCulture = RS_CULTURE;
    }
    dispatch(fetchTranslationsResource({ culture: nextCulture }));
  };
  
  return (
    <LanguagePickerContainer
      onClick={handleChangeLanguage}
      className={props?.className}
    >
      {selectedLanguage}
    </LanguagePickerContainer>
  );
};

LanguagePicker.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default LanguagePicker;
