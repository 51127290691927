import LastChangesRenderer from "../../components/Clients/ClientsTable/Renderers/LastChangesRenderer/LastChangesRenderer";
import ActionRenderer from "../../components/DocumentTypes/DocumentTypesTable/Renderers/ActionRenderer/ActionRenderer";
import RedirectModalRenderer from "../../components/MeasuringInstruments/MeasuringInstrumentsTable/Renderers/RedirectModalRenderer/RedirectModalRenderer";

export default {
  caption: {
    id: 0,
    i18key: "documentTypes.table.caption.columnTitle",
    redirect: true,
    renderer: RedirectModalRenderer,
    style: {
      flex: 2,
    },
    backendProperty: "name",
  },
  description: {
    id: 1,
    i18key: "documentTypes.table.description.columnTitle",
    redirect: true,
    style: {
      flex: 2,
    },
    backendProperty: "description",
  },
  lastChanges: {
    id: 4,
    i18key: "documentTypes.table.lastChanges.columnTitle",
    renderer: LastChangesRenderer,
    style: {
      textAlign: "center",
      justifyContent: "center",
      flex: 1,
    },
    backendProperty: "updatedAtUtc",
  },
  actions: {
    id: 5,
    i18key: "",
    disableSort: true,
    renderer: ActionRenderer,
    style: {
      minWidth: "132px",
      maxWidth: "132px",
      paddingRight: "24px",
      justifyContent: "center",
      textAlign: "center",
    },
  },
  header: {
    id: 6,
    i18key: "documentTypes.table.header.columnTitle",
    mobile: true,
    disableSort: true,
    style: {},
  }
};
