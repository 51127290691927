import {
  createErrorType,
  createFetchType,
  createSuccessType,
} from "../actionHelpers";

export const GET_IMAGES_SCOPE = "GET_IMAGES_SCOPE";
export const GET_IMAGES_FETCH = createFetchType(GET_IMAGES_SCOPE);
export const GET_IMAGES_SUCCESS = createSuccessType(GET_IMAGES_SCOPE);
export const GET_IMAGES_ERROR = createErrorType(GET_IMAGES_SCOPE);

export const POST_IMAGES_SCOPE = "POST_IMAGES_SCOPE";
export const POST_IMAGES_FETCH = createFetchType(POST_IMAGES_SCOPE);
export const POST_IMAGES_SUCCESS = createSuccessType(POST_IMAGES_SCOPE);
export const POST_IMAGES_ERROR = createErrorType(POST_IMAGES_SCOPE);
