import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { closeModal } from "../../../store/actions/modal/modalActions";
import {
  SubmitButton as CancelButton,
  CloseButton,
  ModalTitle,
} from "../Modal.styled";
import {
  ImagePreviewModalContainer,
  ImagePreview,
  ButtonsContainer,
  ImagePreviewContainer,
} from "./ImagePreviewModal.styled";
import BackdropComponent from "../../Modals/ModalComponents/BackdropComponent";
import { ImageOverlay } from "../../ImagePicker/ImagePreview/ImagePreview.styled";

const ImagePreviewModal = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const handleCloseModal = () => {
    dispatch(closeModal());
  };
  return (
    <>
      {props?.rank > 1 ? (
        <BackdropComponent
          isLoading
          handleClose={handleCloseModal}
          position="fixed"
        />
      ) : (
        <></>
      )}
      <ImagePreviewModalContainer rank={props?.rank}>
        <CloseButton onClick={handleCloseModal} />
        <ModalTitle>{props?.title}</ModalTitle>
        <ImagePreviewContainer>
          <ImagePreview src={props?.image} />
          <ImageOverlay $hidden />
        </ImagePreviewContainer>
        <ButtonsContainer>
          <CancelButton onClick={handleCloseModal}>
            {t("common.close")}
          </CancelButton>
        </ButtonsContainer>
      </ImagePreviewModalContainer>
    </>
  );
};

ImagePreviewModal.propTypes = {
  children: PropTypes.node,
  rank: PropTypes.number,
  title: PropTypes.string,
  onSubmit: PropTypes.func,
  image: PropTypes.any,
};

export default ImagePreviewModal;
