import React from "react";
// import PropTypes from 'prop-types'
import { ReactComponent as DocumentIconSvg } from "../../../assets/images/svg/document.svg";

const DocumentIcon = (props) => {
  return <DocumentIconSvg {...props} />;
};

DocumentIcon.propTypes = {};

export default DocumentIcon;
