export const newStuffInitiaValues = (values) => {
  return {
    firstName: values?.firstName || "",
    lastName: values?.lastName || "",
    email: values?.email || "",
    rank: values?.profession || "",
    licenseUrl: values?.licenseUrl || "",
    user: values?.user?.id || "",
    signatureUrl: values?.signatureUrl || "",
    licenseNumber: values?.licenseNumber || "",
  };
};
