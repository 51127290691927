import { Box } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../themes";

export const InstructionsContainer = styled(Box)`
  font-size: 12px;
  font-weight: 500;
  font-family: ${selectedTheme.fonts.mainText};
  color: ${selectedTheme.colors.linkColor};
  margin-top: 12px;
  position: relative;
  /* flex: 1; */
`;
export const InstructionsTextContainer = styled(Box)`
  cursor: pointer;
  bottom: 0;
  left: 0;
  position: ${(props) => props?.$isAbsolute && "absolute"};
  font-size: 12px;
  font-weight: 500;
  font-family: ${selectedTheme.fonts.mainText};
  color: ${selectedTheme.colors.linkColor};
`;
