import React from "react";
import PropTypes from "prop-types";
import { FindingTextField } from "../FindingSettingsForm.styled";
import { findingFormikConstants } from "../../../../../../initialValues/findingInitialValues";
import { useTranslation } from "react-i18next";
import ReactDatePicker from "react-datepicker";
import { useSelector } from "react-redux";
import { selectSingleFindingSettingsOld } from "../../../../../../store/selectors/findingsSelectors";
import { isEqualDates } from "../../../../../../util/helpers/dateHelpers";

const DateOfCheckingField = (props) => {
  const formik = props?.formik;
  const { t } = useTranslation();
  const findingSettingsOld = useSelector(selectSingleFindingSettingsOld);
  const handleChangeDate = (date) => {
    formik?.setFieldValue?.(findingFormikConstants.date, {
      edited: !isEqualDates(date, new Date(findingSettingsOld?.date?.value)),
      value: date,
    });
    if (
      formik?.values?.[findingFormikConstants.closingDate].value &&
      new Date(date) >
        new Date(formik?.values?.[findingFormikConstants.closingDate].value)
    ) {
      formik?.setFieldValue?.(findingFormikConstants.closingDate, {
        edited: !isEqualDates(date, findingSettingsOld?.closingDate?.value),
        value: date,
      });
    }
  };

  return (
    <>
      <ReactDatePicker
        placeholderText={t("findings.form.dateOfChecking.placeholder")}
        disabled={props?.disabled}
        selected={formik?.values?.[findingFormikConstants.date].value}
        dateFormat="dd/MM/yyyy"
        minDate={
          props?.isEditing &&
          new Date(
            new Date(
              formik?.values?.[findingFormikConstants.date].value
            ).getFullYear(),
            0
          )
        }
        maxDate={
          props?.isEditing &&
          new Date(
            new Date(
              formik?.values?.[findingFormikConstants.date].value
            ).getFullYear(),
            11,
            31
          )
        }
        onChange={handleChangeDate}
        customInput={
          <FindingTextField
            date
            disabledField={props?.disabled}
            requiredField={true}
            name={findingFormikConstants.date}
            label={t("findings.form.dateOfChecking.title")}
            placeholder={t("findings.form.dateOfChecking.placeholder")}
            value={formik?.values?.[findingFormikConstants.date].value}
            onChange={formik?.handleChange}
            error={
              formik?.touched?.[findingFormikConstants.date]?.value &&
              formik?.errors?.[findingFormikConstants.date]?.value
            }
            helperText={
              formik?.errors?.[findingFormikConstants.date]?.value &&
              formik?.touched?.[findingFormikConstants.date]?.value
            }
            fullWidth
          />
        }
      />
    </>
  );
};

DateOfCheckingField.propTypes = {
  formik: PropTypes.object,
  disabled: PropTypes.bool,
  isEditing: PropTypes.bool,
};

export default DateOfCheckingField;
