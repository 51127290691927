import React, { useCallback, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import ToolbarPlugin from "./plugins/ToolbarPlugin/ToolbarPlugin";
import { HeadingNode } from "@lexical/rich-text";
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table";
import { ListItemNode, ListNode } from "@lexical/list";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { TablePlugin } from "@lexical/react/LexicalTablePlugin";
import TableActionMenuPlugin from "./plugins/TableActionMenuPlugin/TableActionMenuPlugin";
import OnChangePlugin from "./plugins/OnChangePlugin/OnChangePlugin";
import {
  LexicalContainer,
  LexicalEditorContainer,
} from "./LexicalEditor.styled";
import TableCellResizerPlugin from "./plugins/TableCellResizerPlugin/TableCellResizerPlugin";
import {
  $createParagraphNode,
  $getRoot,
  $isDecoratorNode,
  $isElementNode,
  COMMAND_PRIORITY_LOW,
  SELECTION_CHANGE_COMMAND,
  TextNode,
} from "lexical";
import { createHtmlString } from "../../util/helpers/htmlHelpers";
import { $generateNodesFromDOM } from "@lexical/html";
import { useRef } from "react";
import { useEffect } from "react";
import { setFindingsChanged } from "../../store/actions/findings/findingsActions";
import { useDispatch, useSelector } from "react-redux";
import { selectIsFindingSettingsChanged } from "../../store/selectors/findingsSelectors";
import { ExtentedTextNode } from "./nodes/ExtendedTextNode";

const LexicalEditor = (props) => {
  const [htmlValue, setHtmlValue] = useState("");
  const htmlValueRef = useRef("");
  const isFindingChanged = useSelector(selectIsFindingSettingsChanged);
  const containerRef = useRef(null);
  // Library focuses ContentEditable way too much times
  // which causes application laggy
  //***** */
  const [isListenerAdded, setIsListenerAdded] = useState(false);
  //***** */
  const [floatingAnchorElem, setFloatingAnchorElem] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    htmlValueRef.current = htmlValue;
  }, [htmlValue]);

  useEffect(() => {
    setHtmlValue(props?.value);
  }, [props?.value]);

  const listener = useCallback(
    (event) => {
      if (containerRef.current) {
        if (event.type === "click") {
          if (
            !containerRef.current?.contains?.(event.target) &&
            event.target?.localName !== "body"
          ) {
            props?.onChange(htmlValueRef.current);
            setIsListenerAdded(false);
            window.removeEventListener("click", listener);
            window.removeEventListener("keydown", listener);
          }
        }
        if (event.type === "keydown") {
          if (event?.key === "Tab") {
            setTimeout(() => {
              props?.onChange?.(htmlValueRef.current);
              setIsListenerAdded(false);
              window.removeEventListener("keydown", listener);
            }, 0);
          }
        }
      } else {
        if (event.type === "click") {
          props?.onChange(htmlValueRef.current);
          setIsListenerAdded(false);
          window.removeEventListener("click", listener);
          window.removeEventListener("keydown", listener);
        }
      }
    },
    [containerRef.current, htmlValue, htmlValueRef.current]
  );

  const handleClick = () => {
    if (props?.readOnly) return;
    if (props?.onClickFnc) props?.onClickFnc();
    setIsListenerAdded(true);
    if (!isListenerAdded) {
      window.addEventListener("click", listener);
      window.addEventListener("keydown", listener);
    }
  };

  const customOnBlurFunction = () => {
    props?.onChange(htmlValueRef.current);
  };

  const customOnChangeFunction = useCallback(() => {
    if (!isFindingChanged && !props?.readOnly) dispatch(setFindingsChanged(true));
  }, [isFindingChanged]);

  const initialConfig = useMemo(
    () => ({
      theme: {
        text: {
          italic: "italic",
          bold: "bold",
          underline: "underline",
        },
      },
      editable: true,
      nodes: [
        HeadingNode,
        ExtentedTextNode,
        {
          replace: TextNode,
          with: (node) => new ExtentedTextNode(node.__text, node.__key),
        },
        ListNode,
        ListItemNode,
        TableNode,
        TableRowNode,
        TableCellNode,
      ],
      editorState: (editor) => {
        editor.update(() => {
          try {
            const htmlString = createHtmlString(props?.value);
            const parser = new DOMParser();
            const dom = parser.parseFromString(htmlString, "text/html");

            const nodes = $generateNodesFromDOM(editor, dom);
            const isSingleNode =
              nodes?.length === 1 &&
              ($isElementNode(nodes?.[0]) || $isDecoratorNode(nodes?.[0]));
            const paragraphNode = isSingleNode
              ? nodes?.[0]
              : $createParagraphNode("h1");
            if (!isSingleNode) {
              nodes.forEach((n) => paragraphNode.append(n));
            }
            const root = $getRoot();
            const isEmpty =
              root.isEmpty() ||
              (root?.getFirstChild?.()?.getTextContentSize?.() &&
                root?.getChildrenSize?.() === 1);
            if (isEmpty) {
              let root = $getRoot().clear();
              let newNode = $createParagraphNode();
              let isElementOrDecorator = true;
              nodes.forEach((n, index) => {
                if (n?.setIndent) {
                  n?.setIndent(0);
                }
                if ($isElementNode(n) || $isDecoratorNode(n)) {
                  if (!isElementOrDecorator) {
                    root.append(newNode);
                    newNode = $createParagraphNode();
                  }
                  root.append(n);
                  isElementOrDecorator = true;
                } else {
                  isElementOrDecorator = false;
                  newNode.append(n);
                  if (index !== nodes.length - 1) root.append(newNode);
                }
              });
            }
          } catch (e) {
            console.log(e);
          }
        });
        editor.registerCommand(
          SELECTION_CHANGE_COMMAND,
          customOnChangeFunction,
          COMMAND_PRIORITY_LOW
        );
      },
      onError: console.error,
    }),
    [props?.value]
  );

  const onRef = (newFloatingAnchorElem) => {
    if (newFloatingAnchorElem !== null)
      setFloatingAnchorElem(newFloatingAnchorElem);
  };

  const immediateChange = (newValue) => {
    if (!props?.readOnly) props?.onChange(newValue);
  };

  return (
    <LexicalContainer ref={containerRef} onMouseDown={handleClick}>
      <LexicalComposer initialConfig={initialConfig}>
        {!props?.readOnly && <ToolbarPlugin />}
        <RichTextPlugin
          contentEditable={
            <LexicalEditorContainer
              style={{ position: "relative" }}
              ref={onRef}
              disabled={props?.readOnly}
            >
              <ContentEditable
                onFocus={handleClick}
                onSelect={handleClick}
                className="editor-input"
              />
            </LexicalEditorContainer>
          }
          placeholder={null}
          ErrorBoundary={LexicalErrorBoundary}
        />
        <TableCellResizerPlugin cellMerge />
        {floatingAnchorElem && (
          <TableActionMenuPlugin anchorElem={floatingAnchorElem} cellMerge />
        )}
        <TablePlugin hasCellMerge hasCellBackgroundColor />
        <HistoryPlugin />
        <ListPlugin />
        <OnChangePlugin
          immediateChange={immediateChange}
          onChange={setHtmlValue}
          value={props?.value}
          innerValue={htmlValue}
          readOnly={props?.readOnly}
          customOnBlurFunction={customOnBlurFunction}
        />
      </LexicalComposer>
    </LexicalContainer>
  );
};

LexicalEditor.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
  onClickFnc: PropTypes.func,
};
LexicalEditor.defaultProps = {
  value: "",
  onChange: () => {},
};
export default LexicalEditor;
