import React from "react";
// import PropTypes from 'prop-types'
import { ReactComponent as EyeStrikedIconSvg } from "../../../assets/images/svg/eye-off.svg";

const EyeStrikedIcon = (props) => {
  return <EyeStrikedIconSvg {...props} />;
};

EyeStrikedIcon.propTypes = {};

export default EyeStrikedIcon;
