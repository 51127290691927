import { Box } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../../../themes";

export const FormatButtonContainer = styled(Box)`
  background-color: ${(props) =>
    props?.$isActive && selectedTheme.colors.activeFormatButton};
  padding: 8px;
  border-radius: 10px;
  cursor: pointer;
  &:hover {
    background-color: ${selectedTheme.colors.activeFormatButton};
  }
  & svg {
    background-size: contain;
    display: inline-block;
    height: 18px;
    width: 18px;
    margin-top: 2px;
    vertical-align: -0.25em;
    display: flex;
    opacity: 0.6;
  }
`;
