export const documentFormikConstants = {
  clientId: "clientId",
  documentNumber: "documentNumber",
  documentTypeId: "documentTypeId",
  documentDate: "documentDate",
  link: "link",
  findingId: "findingId"
};

export const documentInitialValues = (values) => ({
  [documentFormikConstants.clientId]: values?.client?.id || null,
  [documentFormikConstants.documentNumber]: values?.documentNumber || "",
  [documentFormikConstants.documentTypeId]: values?.documentType?.id || null,
  [documentFormikConstants.documentDate]: values?.documentDate
    ? new Date(values?.documentDate)
    : null,
  [documentFormikConstants.link]: values?.link || "",
  [documentFormikConstants.findingId]: values?.finding?.id || null
});
