import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { FindingSettingsContainer } from "./FindingSettings.styled";
import FindingSettingsForm from "./FindingSettingsForm/FindingSettingsForm";
import FindingStandards from "./FindingStandards/FindingStandards";
import FindingScanningObjects from "./FindingScanningObjects/FindingScanningObjects";
import FindingStaffs from "./FindingStaffs/FindingStaffs";
// import { findingFormikConstants } from "../../../../initialValues/findingInitialValues";
import { statusTableConstants } from "../../../../constants/findingStatusConstants";
import { forwardRef } from "react";
import { useImperativeHandle } from "react";
import NoteField from "./NoteField/NoteField";
import { useDispatch } from "react-redux";
import { setSingleFindingSettings } from "../../../../store/actions/findings/findingsActions";
import FindingAttendees from "./FindingAttendees/FindingAttendees";
// import { selectStuffsData } from "../../../../store/selectors/stuffsSelector";
// import { selectClientsData } from "../../../../store/selectors/clientsSelector";

const FindingSettings = forwardRef((props, ref) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const formRef = useRef(null);
  const standardsRef = useRef(null);
  const scanningObjectsRef = useRef(null);
  const dispatch = useDispatch();
  // const staff = useSelector(selectStuffsData);
  // const clients = useSelector(selectClientsData);
  useImperativeHandle(
    ref,
    () => ({
      standards: () => standardsRef?.current?.data,
      scanningObjects: () => scanningObjectsRef?.current?.data,
      formData: () => ({
        ...formRef?.current?.formik()?.values,
      }),
      allData: () => ({
        standards: (() => standardsRef?.current?.data)(),
        scanningObjects: (() => scanningObjectsRef?.current?.data)(),
        ...formRef?.current?.formik()?.values,
        formik: () => formRef?.current?.formik(),
      }),
    }),
    [standardsRef.current, scanningObjectsRef.current]
  );

  useEffect(() => {
    if (
      Object.getOwnPropertyNames(props?.prefilledData)?.length > 1 &&
      !isLoaded
    ) {
      setIsLoaded(true);
      scanningObjectsRef?.current?.setData(
        props?.prefilledData?.findingSubjects?.map(
          (singleSubject) => singleSubject?.scanningSubject
        )
      );
      standardsRef?.current?.setData?.(props?.prefilledData?.findingStandards);
    }
  }, [props?.prefilledData]);

  const handleSubmit = () => {
    const formObj = {
      ...formRef?.current?.formik()?.values,
    };
    dispatch(setSingleFindingSettings(formObj));
    props?.handleSubmit();
  };

  return (
    <FindingSettingsContainer $shouldHide={props?.shouldHide}>
      <FindingSettingsForm
        handleFormikSubmit={handleSubmit}
        ref={formRef}
        stateId={props?.stateId}
        isEditing={props?.isEditing}
        prefilledData={props?.prefilledData}
        disabled={props?.stateId !== statusTableConstants.inProgress.id}
      />
      <FindingStandards
        disabled={props?.stateId !== statusTableConstants.inProgress.id}
        ref={standardsRef}
        isEditing={props?.isEditing}
      />
      <FindingScanningObjects
        disabled={props?.stateId !== statusTableConstants.inProgress.id}
        ref={scanningObjectsRef}
        isEditing={props?.isEditing}
      />
      <FindingStaffs
        disabled={props?.stateId !== statusTableConstants.inProgress.id}
      />
      <FindingAttendees
        disabled={props?.stateId !== statusTableConstants.inProgress.id}
      />
      <NoteField
        disabled={props?.stateId !== statusTableConstants.inProgress.id}
      />
    </FindingSettingsContainer>
  );
});

FindingSettings.displayName = "FindingSettings";

FindingSettings.propTypes = {
  children: PropTypes.node,
  isEditing: PropTypes.bool,
  shouldHide: PropTypes.bool,
  prefilledData: PropTypes.object,
  handleSubmit: PropTypes.func,
  stateId: PropTypes.number,
};

export default FindingSettings;
