import { Box } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../../../../themes";

export const TemplateSectionsAddButtonContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  margin-top: 10px;
`;
export const TemplateSectionsAddButtonSeparator = styled(Box)`
  width: 1px;
  height: 24px;
  background-color: ${selectedTheme.colors.tableBorder};
`;
export const TemplateSectionsAddButtonBackground = styled(Box)`
  padding: 8px 16px;
  width: 42px;
  height: 36px;
  background: ${selectedTheme.colors.pageTitleColor};
  border-radius: 8px;
  color: white;
  font-family: ${selectedTheme.fonts.mainText};
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
`;
export const TemplateSectionsAddButtonAltText = styled(Box)`
  font-family: ${selectedTheme.fonts.mainText};
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  font-feature-settings: "salt" on;
  color: ${selectedTheme.colors.emptyMeasuringInstrumentsPlaceholder};
`;

export const AddButtonIcon = styled(Box)`
  cursor: pointer;
  margin-left: 10px;
`;
