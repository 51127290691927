import { Box, Typography } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../../themes";

export const QuestionTypeSelectorOptions = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;
export const QuestionTypeSelectorContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const HintText = styled(Typography)`
  display: flex;
  gap: 5px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  text-align: left;
  color: ${selectedTheme.colors.labelTextColor};
  font-family: ${selectedTheme.fonts.mainText};
`;

export const HintTypeText = styled(Typography)`
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  text-align: left;
  color: ${selectedTheme.colors.orange};
  font-family: ${selectedTheme.fonts.mainText};
`;
