import { APPLICATION_TOAST_CONTAINER_ID } from "../../constants/toastConstants";
import i18n from "../../i18n";
import { attachPostRequestListener } from "../../request";
import apiEndpoints from "../../request/apiEndpoints";
import { makeErrorToastMessage } from "../utils/makeToastMessage";

export default () => (next) => (action) => {
  attachPostRequestListener(async (error) => {
    if (!error?.response) {
      return Promise.reject(error);
    }
    if (error?.response?.status !== 401) {
      let errorMessage;
      try {
        if (typeof error?.response?.data === "string") {
          errorMessage = error?.response?.data;
        } else {
          const res = await error?.response?.data?.text?.();
          errorMessage = JSON.parse(res)?.message;
        }
      } catch (e) {
        errorMessage = error?.response?.data?.message;
      } finally {
        if (typeof errorMessage !== "string")
          errorMessage = i18n.t("apiErrors.error");
        if (
          error?.config?.url?.split?.("?")?.[0] !==
          apiEndpoints.users.validateInvitationToken
        )
          makeErrorToastMessage(
            {
              title: i18n.t("apiErrors.error"),
              description: i18n.t(errorMessage),
            },
            {
              containerId: APPLICATION_TOAST_CONTAINER_ID,
            }
          );
      }
      return Promise.reject(error);
    }
    return Promise.reject(error);
  });

  next(action);
};
