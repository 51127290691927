import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  FindingNewScanningSubjectButtonContainer,
  FindingNewScanningSubjectContainer,
} from "./FindingNewScanningSubject.styled";
import PlusIcon from "../../../../../Icon/Icons/PlusIcon";
import { useTranslation } from "react-i18next";
import EntityPicker from "../../../../../ManualTable/EntityPicker/EntityPicker";
import { useDispatch } from "react-redux";
import { selectActiveScanningSubjects } from "../../../../../../store/selectors/scanningObjectsSelectors";
import {
  fetchFindingSubject,
  setFindingsChanged,
} from "../../../../../../store/actions/findings/findingsActions";
import { toggleRenameScanningSubjectModal } from "../../../../../../store/actions/modal/modalActions";
import { VARIANT } from "../../../../../../constants/buttonConstants";

const FindingNewScanningSubject = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isAddingSubject, setIsAddingSubject] = useState(false);

  // Already called in FindingScanningObjects which is always
  // rendered but not displayed
  //   const singleSubject = useSelector(selectSingleScanningObject);
  //   useEffect(() => {
  //     if (singleSubject?.id) {
  //       debugger; //eslint-disable-line
  //       dispatch(
  //         addFindingScanningSubject({
  //           conclusion: null,
  //           scanningSubjectId: singleSubject?.id,
  //           id: Math.random(),
  //           scanningSubject: {
  //             id: singleSubject?.id,
  //             name: singleSubject?.name,
  //           },
  //           sections:
  //             singleSubject?.checklistTemplates
  //               ?.find?.((singleTemplate) => singleTemplate?.isDefault)
  //               ?.sections?.map?.((singleSection) => ({
  //                 id: singleSection?.id,
  //                 title: singleSection?.title,
  //                 note: null,
  //                 items:
  //                   singleSection?.questions?.map?.((singleQuestion) => ({
  //                     id: Math.random(),
  //                     isMandatory: singleQuestion?.isRequired,
  //                     itemTypeId: singleQuestion?.itemTypeId,
  //                     question: singleQuestion?.questionName,
  //                     note: singleQuestion?.note,
  //                     isRateInRange: singleQuestion?.hasResearchPassed,
  //                     isInRange: false,
  //                     answer:
  //                       singleQuestion?.defaultAnswer ||
  //                       singleQuestion?.selectTypeInputs?.possibleAnswers?.[
  //                         singleQuestion?.selectTypeInputs?.defaultAnswerIndex
  //                       ] ||
  //                       "",
  //                     options:
  //                       singleQuestion?.selectTypeInputs?.possibleAnswers?.map?.(
  //                         (singleAnswer) => ({
  //                           answer: singleAnswer,
  //                           id: Math.random(),
  //                         })
  //                       ),
  //                   })) || [],
  //               })) || [],
  //         })
  //       );
  //       dispatch(clearSingleScanningObject());
  //     }
  //   }, [singleSubject]);
  const handleButtonClick = () => {
    // setIsAddingSubject((prevState) => !prevState);
    dispatch(
      toggleRenameScanningSubjectModal({
        title: t("scanningObjects.newScanningObject.title"),
        rank: 1,
        handleSubmit: ({subjectId, specificName}) => {
          dispatch(setFindingsChanged(true));
          dispatch(
            fetchFindingSubject({
              subjectId,
              specificName,
            })
          );
        },
      })
    );
  };
  const handleAddSubject = (entity) => {
    dispatch(
      fetchFindingSubject({
        subjectId: entity?.id,
        name: entity?.name,
      })
    );
    dispatch(setFindingsChanged(true));
    setIsAddingSubject(false);
  };
  return (
    <FindingNewScanningSubjectContainer>
      {isAddingSubject && (
        <EntityPicker
          handleAddEntity={handleAddSubject}
          fetchedData
          entityDataSelector={selectActiveScanningSubjects}
          namePropertyOfEntity={"name"}
          selectPlaceholder={t("scanningObjects.newScanningObject.title")}
          dropdownLabel={["name"]}
        />
      )}
      <FindingNewScanningSubjectButtonContainer variant={VARIANT.CONTAINED} onClick={handleButtonClick}>
        <PlusIcon />
        {t("scanningObjects.addScanningObjectShorter")}
      </FindingNewScanningSubjectButtonContainer>
    </FindingNewScanningSubjectContainer>
  );
};

FindingNewScanningSubject.propTypes = {
  children: PropTypes.node,
  isEditing: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  settingsFormik: PropTypes.func,
};

export default FindingNewScanningSubject;
