import React from "react";
import PropTypes from "prop-types";
import Checkbox from "../../../Checkbox/Checkbox";
import { useTranslation } from "react-i18next";
import { newFindingTypeFormikConstants } from "../../../../initialValues/newFindingTypeInitialValues";

const AttendeesField = (props) => {
  const { t } = useTranslation();
  const formik = props?.formik;
  return (
    <Checkbox
      label={t("findingTypes.attendees")}
      checked={formik?.values?.[newFindingTypeFormikConstants?.attendees]}
      disabled={props?.disabled}
      onChange={(value) =>
        formik?.setFieldValue?.(newFindingTypeFormikConstants.attendees, value)
      }
    />
  );
};

AttendeesField.propTypes = {
  formik: PropTypes.object,
  disabled: PropTypes.bool,
};

export default AttendeesField;
