import React from "react";
import PropTypes from "prop-types";
import useAuth from "../../hooks/useAuth";


const WithoutPermissions = (props) => {
  const { hasRole } = useAuth();

  if (
    props?.roles?.reduce?.((prevValue, currentValue) => {
      if (prevValue === false) return prevValue;
      if (hasRole(currentValue)) return false;
      return true;
    }, true)
  ) {
    return props?.children;
  }

  return <></>;
};

WithoutPermissions.propTypes = {
  roles: PropTypes.array,
  children: PropTypes.node,
};

export default WithoutPermissions;
