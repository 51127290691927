import React from "react";
import { ReactComponent as LicensePreviewDisableIconSvg } from "../../../assets/images/svg/license-preview-disable.svg";

const LicensePreviewDisableIcon = (props) => {
  return <LicensePreviewDisableIconSvg {...props} />;
};

LicensePreviewDisableIcon.propTypes = {};

export default LicensePreviewDisableIcon;
