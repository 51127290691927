import React from "react";
// import PropTypes from 'prop-types'
import { ReactComponent as SettingsBreadIconSvg } from "../../../assets/images/svg/settings-bc.svg";

const SettingsBreadIcon = (props) => {
  return <SettingsBreadIconSvg {...props} />;
};

SettingsBreadIcon.propTypes = {};

export default SettingsBreadIcon;
