import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { DocumentTextField } from "../NewDocumentContent.styled";
import { documentFormikConstants } from "../../../../initialValues/documentInitialValues";

const DocumentNumberField = (props) => {
  const formik = props?.formik;
  const { t } = useTranslation();
  return (
    <DocumentTextField
      name={documentFormikConstants.documentNumber}
      requiredField
      disabledField={props?.disabled}
      label={t("documents.newPage.form.documentNumber.title")}
      placeholder={t("documents.newPage.form.documentNumber.placeholder")}
      value={formik?.values?.[documentFormikConstants.documentNumber]}
      onChange={(e) =>
        formik?.setFieldValue?.(
          documentFormikConstants.documentNumber,
          e.target.value
        )
      }
      error={
        formik?.touched?.[documentFormikConstants.documentNumber] &&
        formik?.errors?.[documentFormikConstants.documentNumber] &&
        t(formik?.errors?.[documentFormikConstants.documentNumber])
      }
      helperText={
        formik?.errors?.[documentFormikConstants.documentNumber] &&
        formik?.touched?.[documentFormikConstants.documentNumber]
      }
      fullWidth
    />
  );
};

DocumentNumberField.propTypes = {
  formik: PropTypes.object,
  disabled: PropTypes.bool,
};

export default DocumentNumberField;
