import { createSelector } from "reselect";

const documentsSelector = (state) => state.documents;

export const selectDocuments = createSelector(
  documentsSelector,
  (state) => state.documents
);
export const selectTotalDocuments = createSelector(
  documentsSelector,
  (state) => state.totalDocuments
);
export const selectSingleDocument = createSelector(
  documentsSelector,
  (state) => state.singleDocument
);
export const selectDocumentsData = createSelector(
  documentsSelector,
  (state) => state.documents.data
);
