export default [
  {
    id: Math.random(),
    i18key: "companies.tabs.active",
  },
  {
    id: Math.random(),
    i18key: "companies.tabs.inactive",
  },
];
