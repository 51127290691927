export const PAGE_SIZE = "PageSize";
export const PAGE_NUM = "PageNumber";
export const SORT_COLUMN = "SortColumn";
export const SORT_DIR = "SortDirection";
export const FILTER_COLUMN = "FilterColumn";
export const FILTER_VAL = "FilterValue";
export const FINDING_TYPE_ID = "FindingTypeId"

export const NAME_KEY = "name";
export const CAPTION_KEY = "caption";
export const FIRST_NAME_KEY = "firstName";
export const LAST_NAME_KEY = "lastName";
export const FINDING_NUMBER_KEY = "findingNumber";
export const ASC_KEY = "asc";
export const DESC_KEY = "desc";
export const NOT_SORTED = "not_sorted";
