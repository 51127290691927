import React from "react";
import PropTypes from "prop-types";
import {
  Arrow,
  ArrowText,
  LeftArrow,
  PageNumber,
  PagingContainer,
  RightArrow,
  ThreeDots,
} from "./Paging.styled";
import { useTranslation } from "react-i18next";
import useIsMobile from "../../hooks/useIsMobile";

const Paging = (props) => {
  const { t } = useTranslation();
  const { isMobile } = useIsMobile();
  let pageRange = isMobile ? 1 : 2;

  // Determining total pages
  const pages = props?.pages
    ? props?.pages
    : props?.totalElements
    ? Math.ceil(props?.totalElements / props?.elementsPerPage)
    : 1;

  let moving = 0;
  // Making array of pages which contains 2 pages before and after current page
  const pagesAsArray = Array.apply(null, Array(5)).map(() => {});
  if (isMobile) {
    pagesAsArray.pop();
    pagesAsArray.pop();
  }

  // Showing 3 dots if current page is away more than 3 of starting or ending page
  const threeDotsBefore = props?.current - pageRange > 1;
  const threeDotsAfter = props?.current + pageRange < pages;
  return (
    <PagingContainer className={props?.className}>
      {/* Left arrow */}
      <Arrow
        onClick={() => props?.changePage(props?.current - 1)}
        disabled={props?.current - 1 < 1}
      >
        <LeftArrow side="left" />
        <ArrowText $showNameOnMobile={props?.showNameOnMobile}>
          {t("paging.prev")}
        </ArrowText>
      </Arrow>

      {props?.customPaging
        ? props?.children
        : !props?.hidePageNumbers && (
            <>
              {threeDotsBefore && (
                <React.Fragment>
                  <PageNumber onClick={() => props?.changePage(1)}>1</PageNumber>
                  {props?.current - 3 !== 1 && <ThreeDots>...</ThreeDots>}
                </React.Fragment>
              )}
              {/* Pages */}
              {pagesAsArray.map((item, index) => {
                const pageNum = props?.current - pageRange + moving++;
                if (pageNum > pages) return;
                if (pageNum < 1) return;
                return (
                  <PageNumber
                    $current={pageNum === props?.current}
                    key={index}
                    onClick={() => props?.changePage(pageNum)}
                  >
                    {pageNum}
                  </PageNumber>
                );
              })}
              {threeDotsAfter && (
                <>
                  {props?.current + 3 !== pages && <ThreeDots>...</ThreeDots>}
                  <PageNumber onClick={() => props?.changePage(pages)}>
                    {pages}
                  </PageNumber>
                </>
              )}
            </>
          )}

      {/* Right arrow */}
      <Arrow
        onClick={() => props?.changePage(props?.current + 1)}
        disabled={props?.current + 1 > pages}
      >
        <ArrowText $showNameOnMobile={props?.showNameOnMobile}>
          {t("paging.next")}
        </ArrowText>
        <RightArrow side="right" />
      </Arrow>
    </PagingContainer>
  );
};

Paging.propTypes = {
  children: PropTypes.any,
  totalElements: PropTypes.number,
  elementsPerPage: PropTypes.number,
  pages: PropTypes.number,
  current: PropTypes.number,
  changePage: PropTypes.func,
  customPaging: PropTypes.bool,
  hidePageNumbers: PropTypes.bool,
  className: PropTypes.string,
  showNameOnMobile: PropTypes.bool,
};
Paging.defaultProps = {
  elementsPerPage: 10,
  changePage: () => {}
};

export default Paging;
