import React from "react";
import PropTypes from "prop-types";
import {
  FindingBreadCrumbsContainer,
  FindingRouteSingleItem,
  FindingItem,
  FindingArrow,
  FindingBreadCrumbsListContainer,
} from "./FindingBreadCrumbs.styled";
import { FindingModalButtonsContainer } from "../../../FindingTabChooser/FindingTabChooser.styled";
import MeasuringInstrumentsModalButton from "../../../FindingTabChooser/FindingModalButtons/MeasuringInstrumentsModalButton/MeasuringInstrumentsModalButton";
import StandardsModalButton from "../../../FindingTabChooser/FindingModalButtons/StandardsModalButton/StandardsModalButton";

const FindingBreadCrumbs = (props) => {
  return (
    <FindingBreadCrumbsContainer>
      <FindingBreadCrumbsListContainer>
        {props?.breadcrumbs?.map?.((singleRoute, index) => (
          <FindingItem key={index}>
            <FindingArrow />
            <FindingRouteSingleItem onClick={singleRoute?.clickFunction}>
              {singleRoute?.name}
            </FindingRouteSingleItem>
          </FindingItem>
        ))}
      </FindingBreadCrumbsListContainer>
      <FindingModalButtonsContainer>
        <MeasuringInstrumentsModalButton subjectId={props?.subjectId} />
        <StandardsModalButton subjectId={props?.subjectId} />
      </FindingModalButtonsContainer>
    </FindingBreadCrumbsContainer>
  );
};

FindingBreadCrumbs.propTypes = {
  breadcrumbs: PropTypes.array,
  subjectId: PropTypes.number,
};

FindingBreadCrumbs.defaultProps = {
  breadcrumbs: [],
};

export default FindingBreadCrumbs;
