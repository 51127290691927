import React from "react";
// import PropTypes from 'prop-types'
import { ReactComponent as TableIconSvg } from "../../../assets/images/svg/table.svg";

const TableIcon = (props) => {
  return <TableIconSvg {...props} />;
};

TableIcon.propTypes = {};

export default TableIcon;
