import React from "react";
// import PropTypes from 'prop-types'
import { ReactComponent as MobileIconSvg } from "../../../assets/images/svg/mobile.svg";

const MobileIcon = (props) => {
  return <MobileIconSvg {...props} />;
};

MobileIcon.propTypes = {};

export default MobileIcon;
