import { Box } from "@mui/material";
import styled from "styled-components";
import PlusCircledIcon from "../../Icon/Icons/PlusCircledIcon";
import Select from "../../Select/Select";

export const EntitySelectInput = styled(Select)`
  height: 48px;
  & * {
    max-height: 48px;
  }
`;
export const EntityInputsContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 18px;
  align-items: center;
  margin-bottom: 11px;
`;
export const AddButton = styled(PlusCircledIcon)`
  cursor: pointer;
`
