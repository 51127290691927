import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import TextInputField from "../../../Field/TextInputField/TextInputField";
import EyeStrikedIcon from "../../../Icon/Icons/EyeStriked";
import EyeIcon from "../../../Icon/Icons/Eye";
import {
  InputContainer,
  InputRightElement,
} from "../PasswordField/PasswordField.styled";

const ConfirmPasswordField = (props) => {
  const { t } = useTranslation();
  const formik = props?.formik;
  const [showPassword, setShowPassword] = useState(false);
  return (
    <>
      <InputContainer
        $isError={
          formik?.touched?.confirmPassword && formik?.errors?.confirmPassword
        }
      >
        <TextInputField
          name="confirmPassword"
          label={t("users.confirmPassword")}
          placeholder={t("users.newUserPlaceholders.confirmPassword")}
          type={!showPassword && "password"}
          value={formik?.values?.confirmPassword}
          onChange={formik?.handleChange}
          inputProps={{
            sx: {
              input: {
                paddingRight: "44px !important",
                width: "310px !important",
              },
            },
          }}
          error={
            formik?.touched?.confirmPassword && formik?.errors?.confirmPassword
          }
          helperText={
            formik?.touched?.confirmPassword && formik?.errors?.confirmPassword
          }
          requiredField={true}
          icon={
            <InputRightElement
              onClick={() => setShowPassword((prevValue) => !prevValue)}
            >
              {showPassword ? <EyeStrikedIcon /> : <EyeIcon />}
            </InputRightElement>
          }
        />
      </InputContainer>
    </>
  );
};

ConfirmPasswordField.propTypes = {
  formik: PropTypes.node,
  isRequired: PropTypes.bool,
};

export default ConfirmPasswordField;
