import React, { useCallback, useMemo, useRef } from "react";
import PropTypes from "prop-types";
import Table from "../../Table/Table";
import { useTranslation } from "react-i18next";
import scanningObjectsTableConstants from "../../../constants/tableConstants/dashboardTables/scanningObjectsTableConstants";
import TextRenderer from "../../Table/Renderers/TextRenderer/TextRenderer";
import {} from "../../../store/actions/measuringInstruments/measuringInstrumentsActions";
import { formatDateTime } from "../../../util/helpers/dateHelpers";
import {
  clearScanningObjects,
  fetchScanningObjects,
} from "../../../store/actions/scanningObjects/scanningObjectsActions";
import {
  selectScanningObjects,
  selectTotalScanningObjects,
} from "../../../store/selectors/scanningObjectsSelectors";
import { SCANNING_OBJECTS_SCOPE } from "../../../store/actions/scanningObjects/scanningObjectsActionConstants";

const ScanningObjectsTable = () => {
  const { t } = useTranslation();
  const tableRef = useRef(null);

  const tableColumns = useMemo(() => {
    let columnsObject = Object.keys(scanningObjectsTableConstants).map(
      (property) => ({
        ...scanningObjectsTableConstants[property],
        field: t(scanningObjectsTableConstants[property].i18key),
        propertyName: property,
        backendProperty:
          scanningObjectsTableConstants[property]?.backendProperty || property,
        style: scanningObjectsTableConstants[property]?.style,
        renderer:
          scanningObjectsTableConstants[property]?.renderer || TextRenderer,
      })
    );
    return columnsObject;
  }, [scanningObjectsTableConstants, t]);

  const mapDataFunction = useCallback((newData) => {
    return newData?.data?.map((singleData) => {
      let mappedDataObject = {
        name: singleData?.name,
        lastChanges: {
          date: formatDateTime(
            singleData?.updatedAtUtc || new Date().toString()
          ),
          author: `${singleData?.updatedByUser?.firstName || ""} ${
            singleData?.updatedByUser?.lastName || ""
          }`,
        },
        id: singleData?.id,
      };
      return mappedDataObject;
    });
  }, []);

  return (
    <Table
      ref={tableRef}
      hideAddButton
      hidePaging
      hideHeader
      sortingColumn="updatedAtUtc"
      initialSize={4}
      sortDesc
      loadingActionType={SCANNING_OBJECTS_SCOPE}
      isStatic
      tableColumns={tableColumns}
      dataSelector={selectScanningObjects}
      totalDataSelector={selectTotalScanningObjects}
      dispatchFunction={fetchScanningObjects}
      mapDataFunction={mapDataFunction}
      clearDispatchFunction={clearScanningObjects}
    />
  );
};

ScanningObjectsTable.propTypes = {
  children: PropTypes.node,
};

export default ScanningObjectsTable;
