import React from "react";
import PropTypes from "prop-types";
import { ButtonsContainer, CloseButton, ModalTitle } from "../Modal.styled";
import { ConfirmButtonContainer } from "../ConfirmModal/ConfirmModal.styled";
import { useDispatch } from "react-redux";
import { closeModal } from "../../../store/actions/modal/modalActions";
import { useTranslation } from "react-i18next";
import { TableContainer, TableModalContainer } from "./TableModal.styled";
import ManualTable from "../../ManualTable/ManualTable";

const TableModal = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const handleCloseModal = () => {
    dispatch(closeModal());
  };
  return (
    <TableModalContainer>
      <ModalTitle>{props?.title}</ModalTitle>
      <CloseButton onClick={handleCloseModal} />
      <TableContainer>
        <ManualTable
          disabled
          fetchedData
          noActions={props?.noActions}
          selectedData={props?.selectedData}
          // setSelectedData={setSelectedStandards}
          // title={t("standards.title")}
          entityDataSelector={props?.entityDataSelector}
          mapDataFunction={props?.mapDataFunction}
          tableColumns={props?.tableColumns}
          namePropertyOfEntity={props?.tableColumns?.[0]?.propertyName}
        />
      </TableContainer>
      <ButtonsContainer>
        <ConfirmButtonContainer onClick={handleCloseModal} $variant={2}>
          {t("common.close")}
        </ConfirmButtonContainer>
        {/* <ConfirmButtonContainer onClick={props?.onConfirm}>
          {props?.confirmButtonText}
        </ConfirmButtonContainer> */}
      </ButtonsContainer>
    </TableModalContainer>
  );
};

TableModal.propTypes = {
  title: PropTypes.string,
  entityDataSelector: PropTypes.any,
  mapDataFunction: PropTypes.func,
  tableColumns: PropTypes.array,
  selectedData: PropTypes.array,
  noActions: PropTypes.bool,
};

TableModal.defaultProps = {
  noActions: true,
};

export default TableModal;
