import {
  GET_IMAGES_ERROR,
  GET_IMAGES_FETCH,
  GET_IMAGES_SUCCESS,
  POST_IMAGES_ERROR,
  POST_IMAGES_FETCH,
  POST_IMAGES_SUCCESS,
} from "./imagesActionConstant";

export const fetchImages = (payload) => ({
  type: GET_IMAGES_FETCH,
  payload,
});
export const fetchImagesSuccess = (payload) => ({
  type: GET_IMAGES_SUCCESS,
  payload,
});

export const fetchImagesError = (payload) => ({
  type: GET_IMAGES_ERROR,
  payload,
});

export const fetchPostImages = (payload) => ({
  type: POST_IMAGES_FETCH,
  payload,
});
export const fetchPostImagesSuccess = (payload) => ({
  type: POST_IMAGES_SUCCESS,
  payload,
});

export const fetchPostImagesError = (payload) => ({
  type: POST_IMAGES_ERROR,
  payload,
});
