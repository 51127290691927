import React from "react";
// import PropTypes from 'prop-types'
import { ReactComponent as ScanningSubjectBreadIconSvg } from "../../../assets/images/svg/scanning-bc.svg";

const ScanningSubjectsBreadIcon = (props) => {
  return <ScanningSubjectBreadIconSvg {...props} />;
};

ScanningSubjectsBreadIcon.propTypes = {};

export default ScanningSubjectsBreadIcon;
