import React from "react";
import PropTypes from "prop-types";
import {
  SingleTab,
  TabsContainer,
  UsersContentContainer,
} from "./UsersContent.styled";
import Title from "../Typography/Title/Title";
import { useTranslation } from "react-i18next";
import ActiveUsersTable from "./UsersTable/ActiveUsersTable/UsersTable";
import DeactiveUsersTable from "./UsersTable/DeactiveUsersTable/UsersTable";
import { useState } from "react";
import { USERS_TABS } from "../../constants/usersTabsConstants";
import InvitedUsersTable from "./UsersTable/InvitedUsersTable/InvitedUsersTable";

const UsersContent = () => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(USERS_TABS.ACTIVE);
  return (
    <UsersContentContainer>
      <Title>{t("users.title")}</Title>
      <TabsContainer>
        <SingleTab
          $active={activeTab === USERS_TABS.ACTIVE}
          onClick={() => setActiveTab(USERS_TABS.ACTIVE)}
        >
          {t("users.tabActive")}
        </SingleTab>
        <SingleTab
          $active={activeTab === USERS_TABS.INACTIVE}
          onClick={() => setActiveTab(USERS_TABS.INACTIVE)}
        >
          {t("users.tabDeactive")}
        </SingleTab>
        <SingleTab
          $active={activeTab === USERS_TABS.INVITED}
          onClick={() => setActiveTab(USERS_TABS.INVITED)}
        >
          {t("users.tabInvited")}
        </SingleTab>
      </TabsContainer>
      {activeTab === USERS_TABS.ACTIVE && <ActiveUsersTable />}
      {activeTab === USERS_TABS.INACTIVE && <DeactiveUsersTable />}
      {activeTab === USERS_TABS.INVITED && <InvitedUsersTable />}
    </UsersContentContainer>
  );
};

UsersContent.propTypes = {
  children: PropTypes.node,
};

export default UsersContent;
