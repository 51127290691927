import React from "react";
import PropTypes from "prop-types";
import { FindingModalSingleButtonContainer } from "../../FindingTabChooser.styled";
import StandardIcon from "../../../../../Icon/Icons/StandardsIcon";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { selectFindingStandards } from "../../../../../../store/selectors/findingsSelectors";
import { toggleTableModal } from "../../../../../../store/actions/modal/modalActions";
import findingStandardsTableConstants from "../../../../../../constants/tableConstants/findingTables/findingStandardsTableConstants";
import TextRenderer from "../../../../../Table/Renderers/TextRenderer/TextRenderer";
import Tooltip from "../../../../../Tooltip/Tooltip";

const StandardsModalButton = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const selectedStandards = useSelector(
    selectFindingStandards(props?.subjectId)
  );
  const handleShowStandards = () => {
    dispatch(
      toggleTableModal({
        title: t("standards.title"),
        tableColumns: Object.keys(findingStandardsTableConstants).map(
          (property) => {
            if (property === "delete") return;
            return {
              ...findingStandardsTableConstants[property],
              redirect: false,
              field: t(findingStandardsTableConstants[property].i18key),
              propertyName: property,
              backendProperty:
                findingStandardsTableConstants[property]?.backendProperty ||
                property,
              style: findingStandardsTableConstants[property]?.style,
              renderer:
                findingStandardsTableConstants[property]?.renderer ||
                TextRenderer,
            };
          }
        ),
        entityDataSelector: selectFindingStandards(props?.subjectId),
        selectedData: selectedStandards,
        mapDataFunction: (newData) => {
          return newData?.map?.((singleData) => {
            let mappedObject = {
              caption: singleData.caption,
            };
            return mappedObject;
          });
        },
      })
    );
  };
  return (
    <Tooltip title={t("findings.modalButtons.standards")}>
      <FindingModalSingleButtonContainer onClick={handleShowStandards}>
        <StandardIcon />
      </FindingModalSingleButtonContainer>
    </Tooltip>
  );
};

StandardsModalButton.propTypes = {
  subjectId: PropTypes.any,
};

export default StandardsModalButton;
