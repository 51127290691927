import { Box, Typography } from "@mui/material";
import styled, { css } from "styled-components";
import selectedTheme from "../../../../themes";
import ImagePicker from "../../../ImagePicker/ImagePicker";

export const SchemaImagePickersContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;
export const SchemaImagePickersLabel = styled(Typography)`
  font-family: ${selectedTheme.fonts.mainText};
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  font-feature-settings: "salt" on;
  color: ${selectedTheme.colors.labelTextColor};
  /* color: ${selectedTheme.colors.deviceColor}; */
`;

export const SchemaImagePickersListContainer = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  padding: 8px;
  border: ${(props) =>
    props?.$hasBorders && `1px solid ${selectedTheme.colors.inputBorder}`};
  border-radius: 8px;
  max-width: 620px;
`;
export const SchemaSingleImagePicker = styled(ImagePicker)`
  flex: 1;
  ${(props) =>
    props?.$hasImages &&
    css`
      /* padding: 8px; */
      width: 295px !important;
      max-width: 295px !important;
      height: 170px !important;
      max-height: 170px !important;
      min-height: 170px !important;
      @media (max-width: 600px) {
        width: calc((100vw - 80px) / 2) !important;
        max-width: calc((100vw - 80px) / 2);
        height: 120px !important;
        max-height: 120px !important;
        min-height: 120px !important;
      }
      & > form {
        padding: ${(props) => !props?.image && "8px"};
        @media (max-width: 600px) {
          width: calc((100vw - 80px) / 2) !important;
          max-width: calc((100vw - 80px) / 2);
          max-height: 120px !important;
        }
      }
      & p {
        text-align: center;
      }
      & * {
        max-width: 295px !important;
        max-height: 170px !important;
      }
    `}
`;
