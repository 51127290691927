import React from "react";
// import PropTypes from 'prop-types'
import { ReactComponent as MeasuringInstrumentsIconSvg } from "../../../assets/images/svg/measuring-instruments.svg";

const MeasuringInstrumentsIcon = (props) => {
  return <MeasuringInstrumentsIconSvg {...props} />;
};

MeasuringInstrumentsIcon.propTypes = {};

export default MeasuringInstrumentsIcon;
