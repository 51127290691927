import { Box } from "@mui/material";
import styled from "styled-components";
import Icon from "../../../../Icon/Icon";

export const ActionRendererContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 17px;
  @media (max-width: 600px) {
    position: absolute;
    top: 17px;
    right: 15px;
  }
`;
export const ActionRendererEditStandardIconContainer = styled(Icon)`
  cursor: pointer;
`;

export const ActionRendererDeleteStandardIconContainer = styled(Icon)`
  cursor: pointer;
`;
