import React, { useEffect } from "react";
import NewCompanyContent from "../../../components/Companies/NewCompany/NewCompanyContent";
import { useTranslation } from "react-i18next";
import { useRouteMatch } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch } from "react-redux";
import {
  clearDocumentTypes,
  fetchDocumentTypes,
} from "../../../store/actions/documentTypes/documentTypesActions";
import { clearCompanies } from "../../../store/actions/companies/companiesActions";

const CompaniesEditPage = () => {
  const { t } = useTranslation();
  const routeMatch = useRouteMatch();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      fetchDocumentTypes({
        page: 1,
        size: 200,
        companyId: routeMatch?.params?.companyId,
      })
    );
    return () => {
      dispatch(clearCompanies());
      dispatch(clearDocumentTypes());
    };
  }, []);

  return (
    <NewCompanyContent
      title={t("companies.editCompany")}
      isEditing
      companyId={routeMatch?.params?.companyId}
    />
  );
};

export default CompaniesEditPage;
