import { Box } from "@mui/material";
import styled from "styled-components";

export const EditScanningObjectPageContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 80px;
  justify-content: space-between;
  width: 100%;
  flex: 1;
  @media (max-width: 600px) {
    flex-direction: column;
    gap: 32px;
  }
`;
