import React from "react";
import PropTypes from "prop-types";
import { DropdownFilterComponentContainer } from "./DropdownFilterComponent.styled";
import AutocompleteField from "../../../../Field/AutocompleteField/AutocompleteField";

const DropdownFilterComponent = (props) => {
  return (
    <DropdownFilterComponentContainer>
      <AutocompleteField
        options={props?.data}
        label={props?.placeholder}
        title={props?.label}
        onChange={(value) => {
          if (props?.useText) {
            props?.setSelectedData?.(value?.languageCultureName);
          } else {
            props?.setSelectedData?.(value?.id);
          }
          
        }}
        dropdownLabel={props?.dropdownLabel}
        value={props?.data?.find((entry) =>
          entry?.id === props?.selectedData ? entry : null
        )}
      />
    </DropdownFilterComponentContainer>
  );
};

DropdownFilterComponent.propTypes = {
  selectedData: PropTypes.any,
  icon: PropTypes.any,
  data: PropTypes.any,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  setSelectedData: PropTypes.func,
  disable: PropTypes.bool,
  dropdownLabel: PropTypes.array,
  useText: PropTypes.bool
};

export default DropdownFilterComponent;
