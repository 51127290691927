import React from "react";
import PropTypes from "prop-types";
import {
  IsActiveTemplateSwitch,
  SwitchLabel,
  TemplateEditorNavigationContainer,
  TemplateEditorNavigationItem,
  TemplateTabsList,
} from "./TemplateEditorNavigation.styled";
import { useTranslation } from "react-i18next";
import { ALL_TEMPLATES_TAB } from "../../../constants/templateEditorTabs";
import { useDispatch, useSelector } from "react-redux";
import { selectSelectedChecklistTemplate } from "../../../store/selectors/scanningObjectsSelectors";
import {
  setScanningObjectsChanged,
  toggleScanningObjectSelectedChecklistTemplate,
} from "../../../store/actions/scanningObjects/scanningObjectsActions";

const TemplateEditorNavigation = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const checklistTemplate = useSelector(selectSelectedChecklistTemplate);
  const handleChangeIsActive = () => {
    dispatch(
      toggleScanningObjectSelectedChecklistTemplate({
        templateId: checklistTemplate?.id,
      })
    );
    dispatch(setScanningObjectsChanged(true));
  };
  return (
    <TemplateEditorNavigationContainer>
      <TemplateTabsList>
        {props?.tabs?.map((singleTab) => {
          if (!props?.isEditing && singleTab?.id === ALL_TEMPLATES_TAB) return;
          return (
            <TemplateEditorNavigationItem
              key={singleTab?.id}
              selected={singleTab?.selected}
              onClick={() => props?.handleChangeTab(singleTab?.id)}
            >
              {t(singleTab.i18key)}
            </TemplateEditorNavigationItem>
          );
        })}
      </TemplateTabsList>
      {props?.showSwitch && (
        <IsActiveTemplateSwitch
          label={<SwitchLabel>{t("common.active")}</SwitchLabel>}
          onChange={handleChangeIsActive}
          checked={true}
          disabled={props?.isReadOnly}
        />
      )}
    </TemplateEditorNavigationContainer>
  );
};

TemplateEditorNavigation.propTypes = {
  tabs: PropTypes.array,
  handleChangeTab: PropTypes.func,
  isEditing: PropTypes.bool,
  showSwitch: PropTypes.bool,
  handleChangeIsActive: PropTypes.func,
  isReadOnly: PropTypes.bool,
};

export default TemplateEditorNavigation;
