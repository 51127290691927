import React from 'react'
// import PropTypes from 'prop-types'
import {ReactComponent as FilterIconSvg} from "../../../assets/images/svg/filter.svg"

const FilterIcon = props => {
  return (
    <FilterIconSvg {...props} />
  )
}

FilterIcon.propTypes = {}

export default FilterIcon