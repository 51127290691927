import React from "react";
import PropTypes from "prop-types";
import { DownArrow, SortButtonContainer, UpArrow } from "./SortButton.styled";
import {
  ASC_KEY,
  DESC_KEY,
  NOT_SORTED,
} from "../../../constants/queryConstants";

const SortButton = (props) => {
  return (
    <SortButtonContainer>
      {(props?.sortDirection === NOT_SORTED ||
        props?.sortDirection === ASC_KEY) && <UpArrow />}
      {(props?.sortDirection === NOT_SORTED ||
        props?.sortDirection === DESC_KEY) && <DownArrow />}
    </SortButtonContainer>
  );
};

SortButton.propTypes = {
  sortDirection: PropTypes.oneOf([ASC_KEY, DESC_KEY, NOT_SORTED]),
};
SortButton.defaultProps = {
  sortDirection: NOT_SORTED,
};

export default SortButton;
