import React from "react";
import { useTranslation } from "react-i18next";
import NewScanningObjectContent from "../../../../components/ScanningObjects/NewScanningObject/NewScanningObjectContent";
import { fetchCreateScanningObject } from "../../../../store/actions/scanningObjects/scanningObjectsActions";

const NewScanningObjectPage = () => {
  const {t} = useTranslation();
  return (
    <NewScanningObjectContent
      title={t("scanningObjects.newScanningObject.title")}
      submitButtonText={t("scanningObjects.newScanningObject.submitButton")}
      dispatchFunction={fetchCreateScanningObject}
    />
  );
};

export default NewScanningObjectPage;
