import React from "react";
import { useTranslation } from "react-i18next";

import Title from "../Typography/Title/Title";
import { FindingTypesContentContainer } from "./FindingTypesContent.styled";
import FindingTypesTable from "./FindingTypesTable/FindingTypesTable";

const FindingTypesContent = () => {
  const { t } = useTranslation();

  return (
    <FindingTypesContentContainer>
      <Title>{t("findingTypes.title")}</Title>
      <FindingTypesTable />
    </FindingTypesContentContainer>
  );
};

export default FindingTypesContent;
