import { postRequest, putRequest, replaceInUrl } from ".";
import apiEndpoints from "./apiEndpoints";

export const attemptFetchCreateChecklistTemplate = (payload) =>
  postRequest(apiEndpoints.checklistTemplate.createChecklistTemplate, payload);

export const attemptFetchEditChecklistTemplate = (payload) =>
  putRequest(
    replaceInUrl(apiEndpoints.checklistTemplate.editChecklistTemplate, {
      checklistTemplateId: payload?.checklistTemplateId,
    }),
    payload?.data
  );
