import React from "react";
import PropTypes from "prop-types";
import {
  LastChangesContainer,
  LastChangesItem,
  LastChangesItemTitle,
} from "./LastChanges.styled";
import LastChangesRenderer from "../Clients/ClientsTable/Renderers/LastChangesRenderer/LastChangesRenderer";
import { useTranslation } from "react-i18next";

const LastChanges = (props) => {
  const { t } = useTranslation();
  return (
    <LastChangesContainer className={props?.className}>
      <LastChangesItem>
        <LastChangesItemTitle>{t("common.created")}</LastChangesItemTitle>
        <LastChangesRenderer value={props?.created} />
      </LastChangesItem>
      <LastChangesItem>
        <LastChangesItemTitle>{t("common.lastChanges")}</LastChangesItemTitle>
        <LastChangesRenderer value={props?.lastChanges} />
      </LastChangesItem>
    </LastChangesContainer>
  );
};

LastChanges.propTypes = {
  created: PropTypes.any,
  lastChanges: PropTypes.any,
  className: PropTypes.string,
};

export default LastChanges;
