import React from "react";
import PropTypes from "prop-types";
import { CompaniesContentContainer } from "./CompaniesContent.styled";
import Title from "../Typography/Title/Title";
import { useTranslation } from "react-i18next";
import CompaniesTable from "./CompaniesTable/CompaniesTable";

const CompaniesContent = () => {
  const { t } = useTranslation();

  return (
    <CompaniesContentContainer>
      <Title>{t("companies.title")}</Title>
      <CompaniesTable />
    </CompaniesContentContainer>
  );
};

CompaniesContent.propTypes = {
  children: PropTypes.node,
};

export default CompaniesContent;
