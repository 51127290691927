import React from "react";
import PropTypes from "prop-types";
import { FindingScanningSectionContentContainer } from "./FindingScanningSectionContent.styled";
import { useState } from "react";
import deviceConstants from "../../../../../constants/deviceConstants";
import useIsMobile from "../../../../../hooks/useIsMobile";
import { useEffect } from "react";
import FindingScanningQuestions from "./FindingScanningQuestions/FindingScanningQuestions";
import { useSelector } from "react-redux";
import {
  selectFindingSubject,
  selectFindingSubjectOld,
  selectFindingSubjectSection,
  selectFindingSubjectSectionOld,
} from "../../../../../store/selectors/findingsSelectors";
import { forwardRef } from "react";
import { useImperativeHandle } from "react";
import { FindingScanningLeftContainer } from "../FindingScanning.styled";
import FindingScanningSectionChooser from "../FindingScanningSectionChooser/FindingScanningSectionChooser";
import { useMemo } from "react";
import FindingBreadCrumbs from "./FindingBreadCrumbs/FindingBreadCrumbs";

const FindingScanningSectionContent = forwardRef((props, ref) => {
  const [selectedSectionId, setSelectedSectionId] = useState();
  const { isMobile } = useIsMobile();
  const chosenSection = useSelector(
    selectFindingSubjectSection(selectedSectionId)//selectedSectionId) //props.sectionId
  );
  const chosenSectionOld = useSelector(
    selectFindingSubjectSectionOld(selectedSectionId)
  );
  const [selectedDevice, setSelectedDevice] = useState(deviceConstants.desktop);
  const chosenScanningSubject = useSelector(
   selectFindingSubject(props?.selectedScanningSubjectId || 0) 
  );
  const chosenScanningSubjectOld = useSelector(
    selectFindingSubjectOld(props?.selectedScanningSubjectId || 0)
  );

  useImperativeHandle(ref, () => ({
    setSelectedSectionId,
  }));
 
  useEffect(() => {     
    setSelectedSectionId(props.sectionId); // Ažurirajte selectedSectionId kad se props.sectionId promeni   
  }, [props.sectionId]);

  useEffect(() => {
    if (isMobile != null) {
      if (isMobile) {
        if (selectedDevice !== deviceConstants.mobile)
          setSelectedDevice(deviceConstants.mobile);
      } else {
        if (selectedDevice !== deviceConstants.desktop)
          setSelectedDevice(deviceConstants.desktop);
      }
    }
  }, [isMobile]);

  const breadcrumbs = useMemo(() => {
    let breadcrumbsToReturn = [
      {
        name:
          chosenScanningSubject?.specificName?.value ||
          chosenScanningSubject?.name,
          clickFunction: () => props?.setSelectedScanningSubjectId(),      
      },
    ];

    if (selectedSectionId != null) {
      breadcrumbsToReturn.push({
        name: chosenSection?.title,
        clickFunction: () => setSelectedSectionId(),
      });
    } 
    return breadcrumbsToReturn;
  }, [chosenScanningSubject, chosenSection, selectedSectionId]);

  useEffect(() => {
    props.onSetSelectedSubSectionId(chosenScanningSubject?.sections[0].id)

    if (isMobile) {
      setSelectedSectionId(null);
    } else {
      setSelectedSectionId(chosenScanningSubject?.sections?.[0]?.id);
    }
  }, [/*chosenScanningSubject,*/props?.selectedScanningSubjectId, isMobile]);

  const handleNextSection =() => {
    const sectionIndex = chosenScanningSubject?.sections?.findIndex(
      (section) => section.id === selectedSectionId
    );

    if (sectionIndex < chosenScanningSubject?.sections.length - 1) {
      setSelectedSectionId(chosenScanningSubject?.sections[sectionIndex + 1].id)
      props.setSectionId(chosenScanningSubject?.sections[sectionIndex + 1].id);
      //props.handleSectionClick(chosenScanningSubject?.sections[sectionIndex + 1].id);
      props.onSetSelectedSubSectionId(chosenScanningSubject?.sections[sectionIndex + 1].id)
      
    }
  }

  const handlePreviousSection = () => {
    const sectionIndex = chosenScanningSubject?.sections?.findIndex(
      (section) => section.id === selectedSectionId
    );

    if (sectionIndex > 0) {
      setSelectedSectionId(chosenScanningSubject?.sections[sectionIndex - 1].id);
      props.setSectionId(chosenScanningSubject?.sections[sectionIndex - 1].id);
      //props.handleSectionClick(chosenScanningSubject?.sections[sectionIndex - 1].id);
      props.onSetSelectedSubSectionId(chosenScanningSubject?.sections[sectionIndex - 1].id)
    }
  };

  return (
    <FindingScanningSectionContentContainer $selectedDevice={selectedDevice}>
      {isMobile && (
        <FindingBreadCrumbs
          breadcrumbs={breadcrumbs}
          subjectId={chosenScanningSubject?.id}
        />
      )}
      {isMobile ? (
        selectedSectionId == null && (
          <FindingScanningLeftContainer
            $chosenScanningSubject={chosenScanningSubject}
          >
            <FindingScanningSectionChooser
              style={{ marginTop: "18px" }}
              // sections={props?.item?.data?.sections}
              stateId={props?.stateId}
              sections={chosenScanningSubject?.sections}
              notEditable
              scanningSection
              chosenScanningSubjectId={
                selectedSectionId != null && chosenSection?.id
              }
              handleChangeSection={setSelectedSectionId}           
            />
          </FindingScanningLeftContainer>
        )
      ) : <></>
     
      }
      {selectedSectionId != null &&
        chosenScanningSubject?.sections?.length !== 0 &&
        props?.selectedScanningSubjectId != null && (
          <FindingScanningQuestions
            {...props}
            questions={chosenSection?.items}
            device={isMobile ? deviceConstants.mobile : deviceConstants.desktop}
            section={chosenSection}
            sectionOld={chosenSectionOld}
            chosenScanningSubject={chosenScanningSubject}
            chosenScanningSubjectOld={chosenScanningSubjectOld}
            chosenScanningSubjectId={props?.selectedScanningSubjectId}
            subjectNote={chosenScanningSubject?.conclusion}
            handleNextSection={handleNextSection}  // Prosleđujemo funkciju
            handlePreviousSection={handlePreviousSection}  // Prosleđujemo funkciju
          />
        )}
    </FindingScanningSectionContentContainer>
  );
});

FindingScanningSectionContent.displayName = "FindingScanningSectionContent";

FindingScanningSectionContent.propTypes = {
  questions: PropTypes.array,
  answerQuestion: PropTypes.func,
  handleChangeTab: PropTypes.func,
  handleSubmit: PropTypes.func,
  selectedTab: PropTypes.object,
  goBackToSections: PropTypes.func,
  setSelectedScanningSubjectId: PropTypes.func,
  disabled: PropTypes.bool,
  selectedScanningSubjectId: PropTypes.number,
  stateId: PropTypes.number,
  sectionId: PropTypes.number,
  setSectionId: PropTypes.func,
  onSetSelectedSubSectionId: PropTypes.func,
};

export default FindingScanningSectionContent;
