import { Box } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../../themes";

export const FindingScanningContainer = styled(Box)`
  display: flex;
  flex-direction: row;
`;
export const FindingScanningLeftContainer = styled(Box)`
  min-width: 235px;
  width: fit-content;
  height: calc(100vh - 250px);
  box-shadow: inset -1px 0px 0px ${selectedTheme.colors.tableBorder};
  @media (max-width: 600px) {
    box-shadow: none;
    width: 100%;
    ${props => props?.$chosenSection && `display: none;`}
  }
`;
export const FindingScanningRightContainer = styled(Box)`
  flex: 1;
  padding-right: 72px;
  @media (max-width: 600px) {
    padding-right: 0;
  }
`;
