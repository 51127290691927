import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import NewClientForm from "../../../components/Clients/NewClient/NewClientForm";
import { fetchCreateClient } from "../../../store/actions/clients/clientsActions";

const NewClientPage = () => {
  const { t } = useTranslation();
  // Add layout
  return (
    <NewClientForm
      dispatchFunction={fetchCreateClient}
      title={t("clients.newClient")}
      submitText={t("common.save")}
    />
  );
};

NewClientPage.propTypes = {
  children: PropTypes.node,
};

export default NewClientPage;
