import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { LinkContainer, NonLinkContainer } from "./Link.styled";
import { forwardRef } from "react";

const Link = forwardRef((props, ref) => {
  const componentProps = useMemo(() => {
    let allProps = { ...props };
    let componentProps = { ...allProps };
    delete componentProps?.children;
    return componentProps;
  }, [props]);

  const nonLinkProps = useMemo(() => {
    let allProps = { ...props };
    let nonLinkProps = { ...allProps };
    delete nonLinkProps?.children;
    delete nonLinkProps?.to;
    return nonLinkProps;
  }, [props]);

  if (props?.to)
    return (
      <LinkContainer ref={ref} {...componentProps}>
        {props?.children}
      </LinkContainer>
    );
  return (
    <NonLinkContainer ref={ref} {...nonLinkProps}>
      {props?.children}
    </NonLinkContainer>
  );
});

Link.displayName = "Link";

Link.propTypes = {
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.func]),
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.node]),
};

export default Link;
