import { all, call, put, takeLatest } from "@redux-saga/core/effects";
import {
  attemptCreateDocumentType,
  attemptDeleteDocumentType,
  attemptEditSingleDocumentType,
  attemptFetchDocumentTypes,
  attemptFetchSingleDocumentType,
} from "../../request/documentTypesRequest";
import {
  CREATE_DOCUMENT_TYPE_FETCH,
  DELETE_DOCUMENT_TYPE_FETCH,
  EDIT_DOCUMENT_TYPE_FETCH,
  GET_DOCUMENT_TYPES_FETCH,
  SINGLE_DOCUMENT_TYPE_FETCH,
} from "../actions/documentTypes/documentTypesActionConstants";
import {
  fetchDocumentTypesError,
  fetchDocumentTypesSuccess,
  fetchCreateDocumentTypeError,
  fetchCreateDocumentTypeSuccess,
  fetchDeleteDocumentTypeError,
  fetchDeleteDocumentTypeSuccess,
  fetchEditDocumentTypeError,
  fetchEditDocumentTypeSuccess,
  fetchSingleDocumentTypeError,
  fetchSingleDocumentTypeSuccess,
  setDocumentTypes,
  setSingleDocumentType,
  setTotalDocumentTypes,
} from "../actions/documentTypes/documentTypesActions";
import { mapFetchAllFunction } from "../utils/mapFetchAllFunctions";

function* fetchDocumentTypes({ payload }) {
  try {
    const queryObject = mapFetchAllFunction(payload, ["caption"]);
    if (payload?.companyId) queryObject.set("CompanyId", payload?.companyId);
    const data = yield call(attemptFetchDocumentTypes, queryObject);
    yield put(
      setDocumentTypes({
        data: data?.data?.documentTypes,
      })
    );
    yield put(setTotalDocumentTypes(data?.data?.count));
    if (payload?.handleApiResponseSuccess) {
      yield call(payload?.handleApiResponseSuccess);
    }
    yield put(fetchDocumentTypesSuccess());
  } catch (e) {
    console.log("greska", e);
    if (payload?.handleApiResponseError) {
      yield call(payload?.handleApiResponseError);
    }
    yield put(fetchDocumentTypesError());
  }
}

function* fetchCreateDocumentType({ payload }) {
  try {
    const data = yield call(attemptCreateDocumentType, {
      caption: payload?.caption,
      description: payload?.description,
    });
    if (payload?.handleApiResponseSuccess) {
      yield call(payload?.handleApiResponseSuccess, data?.data);
    }
    yield put(fetchCreateDocumentTypeSuccess());
  } catch (e) {
    console.log("greska", e);
    if (e.response && e.response.data) {
      const errorMessage = e?.response?.data?.message;
      if (payload?.handleApiResponseError) {
        yield call(payload?.handleApiResponseError, errorMessage);
      }
      yield put(fetchCreateDocumentTypeError(errorMessage));
    } else {
      if (payload?.handleApiResponseError) {
        yield call(payload?.handleApiResponseError);
      }
      yield put(fetchCreateDocumentTypeError());
    }
  }
}

function* fetchSingleDocumentType({ payload }) {
  try {
    const { data } = yield call(attemptFetchSingleDocumentType, {
      documentTypeId: payload?.documentTypeId,
    });
    yield put(setSingleDocumentType(data));
    if (payload?.handleApiResponseSuccess) {
      yield call(payload?.handleApiResponseSuccess);
    }
    yield put(fetchSingleDocumentTypeSuccess());
  } catch (e) {
    console.log("greska", e);
    if (e.response && e.response.data) {
      const errorMessage = e?.response?.data?.message;
      if (payload?.handleApiResponseError) {
        yield call(payload?.handleApiResponseError, errorMessage);
      }
      yield put(fetchSingleDocumentTypeError(errorMessage));
    } else {
      if (payload?.handleApiResponseError) {
        yield call(payload?.handleApiResponseError);
      }
      yield put(fetchSingleDocumentTypeError());
    }
  }
}

function* fetchEditSingleDocumentType({ payload }) {
  try {
    const { data } = yield call(attemptEditSingleDocumentType, {
      documentTypeId: payload?.id,
      data: {
        id: payload?.id,
        caption: payload?.caption,
        description: payload?.description,
      },
    });
    if (payload?.handleApiResponseSuccess) {
      yield call(payload?.handleApiResponseSuccess, data);
    }
    yield put(fetchEditDocumentTypeSuccess());
  } catch (e) {
    console.log("greska", e);
    if (e.response && e.response.data) {
      const errorMessage = e?.response?.data?.message;
      if (payload?.handleApiResponseError) {
        yield call(payload?.handleApiResponseError, errorMessage);
      }
      yield put(fetchEditDocumentTypeError(errorMessage));
    } else {
      if (payload?.handleApiResponseError) {
        yield call(payload?.handleApiResponseError);
      }
      yield put(fetchEditDocumentTypeError());
    }
  }
}

function* fetchDeleteDocumentType({ payload }) {
  try {
    yield call(attemptDeleteDocumentType, {
      documentTypeId: payload?.id,
    });
    if (payload?.handleApiResponseSuccess) {
      yield call(payload?.handleApiResponseSuccess, { isDeleted: true });
    }
    yield put(fetchDeleteDocumentTypeSuccess());
  } catch (e) {
    console.log("greska", e);
    let errorMessage = e?.response?.data?.message;

    if (payload?.handleApiResponseError) {
      yield call(payload?.handleApiResponseError, errorMessage);
    }
    yield put(fetchDeleteDocumentTypeError(errorMessage));
  }
}

export default function* documentTypesSaga() {
  yield all([
    takeLatest(GET_DOCUMENT_TYPES_FETCH, fetchDocumentTypes),
    takeLatest(CREATE_DOCUMENT_TYPE_FETCH, fetchCreateDocumentType),
    takeLatest(EDIT_DOCUMENT_TYPE_FETCH, fetchEditSingleDocumentType),
    takeLatest(SINGLE_DOCUMENT_TYPE_FETCH, fetchSingleDocumentType),
    takeLatest(DELETE_DOCUMENT_TYPE_FETCH, fetchDeleteDocumentType),
  ]);
}
