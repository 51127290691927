import React from "react";
import PropTypes from "prop-types";
import FindingStatusCard from "../../../../Cards/FindingStatus/FindingStatusCard";
import { getStatusColorsById } from "../../../../../constants/findingStatusConstants";
import i18n from "../../../../../i18n";

const StatusRenderer = (props) => {
  const status = getStatusColorsById(props?.value);
  return (
    <FindingStatusCard
      statusBackgroundColor={status?.colors?.backgroundColor}
      statusTextColor={status?.colors?.color}
      borderWidth={1}
      borderColor={status?.colors?.borderColor}
      circleColor={status?.colors?.circleColor}
      value={i18n.t(status?.i18key)}
    />
  );
};

StatusRenderer.propTypes = {
  value: PropTypes.string,
};

export default StatusRenderer;
