import React from "react";
import PropTypes from "prop-types";
import SettingsContent from "../../components/Settings/SettingsContent";
import { useDispatch, useSelector } from "react-redux";
import { selectMineCompanyId } from "../../store/selectors/loginSelectors";
import { useEffect } from "react";
import {
  clearDocumentTypes,
  fetchDocumentTypes,
} from "../../store/actions/documentTypes/documentTypesActions";

const SettingsPage = () => {
  const myCompanyId = useSelector(selectMineCompanyId);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      fetchDocumentTypes({
        page: 1,
        size: 200,
      })
    );
    return () => {
      dispatch(clearDocumentTypes());
    };
  }, []);
  return <SettingsContent companyId={myCompanyId} isEditing />;
};

SettingsPage.propTypes = {
  children: PropTypes.node,
};

export default SettingsPage;
