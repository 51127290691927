import { Box, TextareaAutosize, Typography } from "@mui/material";
import styled, { css } from "styled-components";
import selectedTheme from "../../../themes";
import { hexToRGB } from "../../../util/helpers/colorHelper";

export const TextareaInputContainer = styled(Box)``;

export const TextareaInputTitle = styled(Box)`
  font-family: ${selectedTheme.fonts.mainText};
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 8px;
  color: ${selectedTheme.colors.labelTextColor};
  ${(props) =>
    props?.$required &&
    css`
      &::after {
        content: " *";
        color: ${selectedTheme.colors.errorColor};
      }
    `}
`;

export const TextareaInputField = styled(TextareaAutosize)`
  width: ${(props) => props?.width || "384px"};
  border: 1px solid
    ${(props) =>
      props?.$isErrorShown
        ? selectedTheme.colors.errorBorderColor
        : selectedTheme.colors.inputBorderColor};
  background-color: ${(props) =>
    props?.disabled && selectedTheme.colors.disabledFieldBackground};
  resize: ${(props) => props?.disabled && "none"};
  border-radius: 8px;
  font-family: ${selectedTheme.fonts.mainText};
  box-shadow: 0px 1px 2px ${hexToRGB(selectedTheme.colors.shadowColor, 0.05)};
  font-size: 16px;
  line-height: 24px;
  font-feature-settings: "salt" on;
  color: ${hexToRGB(selectedTheme.colors.black, 0.87)};
  padding: 8px 12px;
  outline: 0;
  & p {
    position: relative;
    top: -3px;
  }
`;
export const ErrorMessage = styled(Typography)`
  font-family: ${selectedTheme.fonts.mainText};
  font-size: 12px;
  font-weight: 400;
  color: ${selectedTheme.colors.errorColor};
  text-align: ${(props) => props?.$rightAlign && "right"};
`;
