import React from "react";
import PropTypes from "prop-types";
import {
  ErrorMessage,
  SingleInputContainer,
  SingleInputTitle,
} from "../SetMeasuringInstrumentModal.styled";
import { newMeasuringInstrumentFormikConstants } from "../../../../initialValues/newMeasuringInstrumentInitialValues";
import { useTranslation } from "react-i18next";
import { InputField } from "../../../Login/LoginForm/LoginForm.styled";

const FactoryNumberField = (props) => {
  const formik = props?.formik;
  const { t } = useTranslation();

  const handleChangeFactoryNumber = (event) => {
    // var regExp = /^[0-9]/g;
    // if (regExp.test(event.target.value)) {
    formik?.handleChange?.(event);
    // }
  };

  return (
    <SingleInputContainer>
      <SingleInputTitle
      // $required={
      //   !formik?.values?.[newMeasuringInstrumentFormikConstants.factoryNumber]
      // }
      >
        {t("measuringInstruments.modal.fields.factoryNumber.label")}
      </SingleInputTitle>
      <InputField
        style={{
          width: "100%",
        }}
        number
        id={newMeasuringInstrumentFormikConstants.factoryNumber}
        value={
          formik?.values?.[newMeasuringInstrumentFormikConstants.factoryNumber]
        }
        onChange={handleChangeFactoryNumber}
        placeholder={t(
          "measuringInstruments.modal.fields.factoryNumber.placeholder"
        )}
        disabled={props?.isReadOnly}
      />
      {formik?.errors?.[newMeasuringInstrumentFormikConstants.factoryNumber] &&
        formik?.touched?.[
          newMeasuringInstrumentFormikConstants.factoryNumber
        ] && (
          <ErrorMessage>
            {t(
              formik?.errors?.[
                newMeasuringInstrumentFormikConstants.factoryNumber
              ]
            )}
          </ErrorMessage>
        )}
    </SingleInputContainer>
  );
};

FactoryNumberField.propTypes = {
  formik: PropTypes.object,
  isReadOnly: PropTypes.bool,
};

export default FactoryNumberField;
