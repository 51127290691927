import React from "react";
import PropTypes from "prop-types";
import Title from "../Typography/Title/Title";
import { useTranslation } from "react-i18next";
import { StuffContentContainer } from "./StuffsContent.styled";
import StuffsTable from "./StuffsTable/StuffsTable";
import { useSelector } from "react-redux";
import { selectIsLoadingByActionType } from "../../store/selectors/loadingSelectors";
import { GET_IMAGES_SCOPE } from "../../store/actions/images/imagesActionConstant";
import CircularLoader from "../Loader/CircularLoader/CircularLoader";

const StuffContent = () => {
  const { t } = useTranslation();
  const isLoadingImage = useSelector(
    selectIsLoadingByActionType(GET_IMAGES_SCOPE)
  );
  return (
    <StuffContentContainer>
      {isLoadingImage ? <CircularLoader></CircularLoader> : <></>}
      <Title>{t("stuffs.title")}</Title>
      <StuffsTable />
    </StuffContentContainer>
  );
};

StuffContent.propTypes = {
  children: PropTypes.node,
};

export default StuffContent;
