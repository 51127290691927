import React from "react";
import PropTypes from "prop-types";
import {
  formatDateTime,
  getDateWithoutTimezone,
} from "../../../../util/helpers/dateHelpers";
import {
  FindingLastChangesContainer,
  FindingLastStateChangesContainer,
  FindingLastStateChangesListContainer,
  FindingLastStateChangesTitle,
  FindingSingleStateChangeAuthor,
  FindingSingleStateChangeContainer,
  FindingSingleStateChangeDate,
  FindingSingleStateChangeDetailsContainer,
  FindingSingleStateChangeStatusContainer,
  LastDetailsChanges,
} from "./FindingLastChanges.styled";
import { useTranslation } from "react-i18next";
import FindingStatusCard from "../../../Cards/FindingStatus/FindingStatusCard";
import { getStatusColorsById } from "../../../../constants/findingStatusConstants";
import { useSelector } from "react-redux";
import { selectSingleFinding } from "../../../../store/selectors/findingsSelectors";

const FindingLastChanges = () => {
  const { t } = useTranslation();
  const singleFinding = useSelector(selectSingleFinding);
  return (
    <FindingLastChangesContainer>
      <LastDetailsChanges
        created={{
          date: formatDateTime(
            singleFinding?.createdAtUtc || new Date().toString()
          ),
          author: `${singleFinding?.createdByUser?.firstName || ""} ${
            singleFinding?.createdByUser?.lastName || ""
          }`,
        }}
        lastChanges={{
          date: formatDateTime(
            getDateWithoutTimezone(singleFinding?.updatedAtUtc) ||
              new Date().toString()
          ),
          author: `${singleFinding?.updatedByUser?.firstName || ""} ${
            singleFinding?.updatedByUser?.lastName || ""
          }`,
        }}
      />
      <FindingLastStateChangesContainer>
        <FindingLastStateChangesTitle>
          {t("findings.lastStateChanges")}
        </FindingLastStateChangesTitle>
        <FindingLastStateChangesListContainer>
          {singleFinding.findingStateChanges
            ?.sort?.((a, b) => a?.id - b?.id)
            ?.map((singleStateChange, index) => (
              <FindingSingleStateChangeContainer key={index}>
                <FindingSingleStateChangeStatusContainer>
                  <FindingStatusCard
                    {...getStatusColorsById(
                      singleStateChange?.findingStateTypeId
                    ).colors}
                    value={t(
                      getStatusColorsById(singleStateChange?.findingStateTypeId)
                        .i18key
                    )}
                  />
                </FindingSingleStateChangeStatusContainer>
                <FindingSingleStateChangeDetailsContainer>
                  <FindingSingleStateChangeAuthor>
                    {/* There is no user details in BE response when this is implemented */}
                    {`${singleStateChange?.createdByUser?.firstName} ${singleStateChange?.createdByUser?.lastName}`}
                  </FindingSingleStateChangeAuthor>
                  <FindingSingleStateChangeDate>
                    {formatDateTime(
                      getDateWithoutTimezone(singleStateChange?.createdAtUtc)
                    )}
                  </FindingSingleStateChangeDate>
                </FindingSingleStateChangeDetailsContainer>
              </FindingSingleStateChangeContainer>
            ))}
        </FindingLastStateChangesListContainer>
      </FindingLastStateChangesContainer>
    </FindingLastChangesContainer>
  );
};

FindingLastChanges.propTypes = {
  children: PropTypes.node,
  prefilledData: PropTypes.object,
};

export default FindingLastChanges;
