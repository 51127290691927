import React from "react";
import { ReactComponent as DropdownIconSvg } from "../../../assets/images/svg/dropdown-icon.svg";

const DropdownIcon = (props) => {
  return <DropdownIconSvg {...props} />;
};

DropdownIcon.propTypes = {};

export default DropdownIcon;
