import React from "react";
// import PropTypes from 'prop-types'
import { ReactComponent as ResetIconSvg } from "../../../assets/images/svg/reset.svg";

const ResetIcon = (props) => {
  return <ResetIconSvg style={{ transform: "rotate(-90deg)" }} {...props} />;
};

ResetIcon.propTypes = {};

export default ResetIcon;
