import React from "react";
import PropTypes from "prop-types";
import {
  InstructionsContainer,
  InstructionsTextContainer,
} from "./Instructions.styled";
import { Trans } from "react-i18next";

const Instructions = (props) => {
  const handleDownload = () => {
    props?.handleDownload?.();
  };
  return (
    <InstructionsContainer className={props?.className}>
      <InstructionsTextContainer
        $isAbsolute={props?.isAbsolute}
        onClick={handleDownload}
      >
        <Trans i18nKey="instructions.title" components={<i />} />
      </InstructionsTextContainer>
    </InstructionsContainer>
  );
};

Instructions.propTypes = {
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  handleDownload: PropTypes.func,
  isAbsolute: PropTypes.bool,
};

export default Instructions;
