import React from "react";
// import PropTypes from 'prop-types'
import { ReactComponent as RightArrowIconSvg } from "../../../assets/images/svg/down-arrow.svg";

const RightArrowIcon = (props) => {
  return (
    <RightArrowIconSvg style={{ transform: "rotate(-90deg)" }} {...props} />
  );
};

RightArrowIcon.propTypes = {};

export default RightArrowIcon;
