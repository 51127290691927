import React from "react";
// import PropTypes from 'prop-types'
import { ReactComponent as XIconSvg } from "../../../assets/images/svg/x-icon.svg";

const XIcon = (props) => {
  return <XIconSvg {...props} />;
};

XIcon.propTypes = {};

export default XIcon;
