import React from "react";
import NewCompanyContent from "../../../components/Companies/NewCompany/NewCompanyContent";
import { useTranslation } from "react-i18next";

const CompaniesNewPage = () => {
  const { t } = useTranslation();
  return <NewCompanyContent title={t("companies.newCompany")} />;
};

export default CompaniesNewPage;
