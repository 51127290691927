import React from "react";
import PropTypes from "prop-types";
import { IsDefaultRendererContainer } from "./IsDefaultRenderer.styled";
import TickCircledIcon from "../../../../../Icon/Icons/TickCircledIcon";

const IsDefaultRenderer = (props) => {
  return (
    <IsDefaultRendererContainer>
      {props?.value ? <TickCircledIcon /> : <></>}
    </IsDefaultRendererContainer>
  );
};

IsDefaultRenderer.propTypes = {
  children: PropTypes.node,
  dispatch: PropTypes.func,
  value: PropTypes.object,
  rendererProps: PropTypes.any,
};

export default IsDefaultRenderer;
