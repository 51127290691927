import React from "react";
import PropTypes from "prop-types";
import { TemplateSectionsContentContainer } from "./TemplateSectionsContent.styled";
import TemplateQuestions from "./TemplateQuestions/TemplateQuestions";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { selectSingleScanningObjectSectionQuestions } from "../../../../store/selectors/scanningObjectsSelectors";
import {
  addScanningObjectSectionSingleQuestion,
  deleteScanningObjectSectionSingleQuestion,
  moveScanningObjectSectionSingleQuestion,
  moveSingleQuestionOnDragAndDrop,
  setScanningObjectSectionSingleQuestion,
  setScanningObjectsChanged,
} from "../../../../store/actions/scanningObjects/scanningObjectsActions";
import { toggleScanningObjectModal } from "../../../../store/actions/modal/modalActions";
import CustomDragLayer from "../../../CustomDragLayer/CustomDragLayer";
import useIsMobile from "../../../../hooks/useIsMobile";

const TemplateSectionsContent = (props) => {
  const { isMobile } = useIsMobile();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const questions = useSelector(
    selectSingleScanningObjectSectionQuestions(props?.chosenSectionId)
  );

  const handleDeleteQuestion = (question) => {
    dispatch(
      deleteScanningObjectSectionSingleQuestion({
        sectionId: props?.chosenSectionId,
        questionId: question?.id,
      })
    );
    dispatch(setScanningObjectsChanged(true));
  };

  const handleAddQuestion = (singleQuestion) => {
    dispatch(
      addScanningObjectSectionSingleQuestion({
        sectionId: props?.chosenSectionId,
        question: { ...singleQuestion, id: Math.random() },
      })
    );
  };

  const handleMoveQuestion = (firstIndex, secondIndex) => {
    dispatch(
      moveScanningObjectSectionSingleQuestion({
        sectionId: props?.chosenSectionId,
        firstIndex,
        secondIndex,
      })
    );
    dispatch(setScanningObjectsChanged(true));
  };

  const handleMoveQuestionOnDragAndDrop = (firstIndex, secondIndex) => {
    dispatch(
      moveSingleQuestionOnDragAndDrop({
        sectionId: props?.chosenSectionId,
        firstIndex,
        secondIndex,
      })
    );
    dispatch(setScanningObjectsChanged(true));
  };

  const handleEditQuestion = (question) => {
    dispatch(
      setScanningObjectSectionSingleQuestion({
        sectionId: props?.chosenSectionId,
        question,
      })
    );
  };

  const handleStartAddingNewQuestion = () => {
    dispatch(
      toggleScanningObjectModal({
        title: t("questions.new"),
        ordinalNumber: questions?.length + 1,
        handleSubmitForm: handleAddQuestion,
      })
    );
  };

  const handleStartEditingQuestion = (question) => {
    dispatch(
      toggleScanningObjectModal({
        title: t("questions.edit"),
        ordinalNumber: question?.index + 1,
        handleSubmitForm: handleEditQuestion,
        ...question,
      })
    );
  };

  const dragLayerFunc = (item) => {
    return questions[item?.ordinalNumber - 1];
  };

  return (
    <TemplateSectionsContentContainer>
      {isMobile && <CustomDragLayer selectFunction={dragLayerFunc} />}
      <TemplateQuestions
        questions={questions}
        handleChangeQuestionIndexes={handleMoveQuestion}
        handleStartAddingNewQuestion={handleStartAddingNewQuestion}
        handleMoveQuestion={handleMoveQuestion}
        handleMoveQuestionOnDragAndDrop={handleMoveQuestionOnDragAndDrop}
        handleDeleteQuestion={handleDeleteQuestion}
        handleStartEditingQuestion={handleStartEditingQuestion}
        isReadOnly={props?.isReadOnly}
        chosenSectionId={props?.chosenSectionId}
      />
    </TemplateSectionsContentContainer>
  );
};

TemplateSectionsContent.displayName = "TemplateSectionsContent";

TemplateSectionsContent.propTypes = {
  children: PropTypes.node,
  chosenSectionId: PropTypes.object,
  handleMoveQuestion: PropTypes.func,
  handleEditQuestion: PropTypes.func,
  handleDeleteQuestion: PropTypes.func,
  handleAddQuestion: PropTypes.func,
  handleChangeQuestionIndexes: PropTypes.func,
  isReadOnly: PropTypes.bool,
};

export default TemplateSectionsContent;
