import React from 'react';

const ErrorPage = () => {
  return (
    <div>
        Error page
    </div>
  );
};

ErrorPage.propTypes = {};

export default ErrorPage;
