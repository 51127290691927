import React, { forwardRef, useEffect } from "react";
import PropTypes from "prop-types";
import {
  CopyRowIcon,
  // DNDIcon,
  ExpandIcon,
  FindingTableInputsContainer,
  FindingTableSingleRowContainer,
  FindingTableSingleRowHeaderOptions,
  HeaderRowIconsContainer,
  MiniRowCount,
  RemoveRowContainer,
  RemoveRowIcon,
  RowCount,
  SingleRowLeftContainer,
} from "./FindingTableSingleRow.styled";
import { useTranslation } from "react-i18next";
import HorizontalSeparator from "../../../../Separators/HorizontalSeparator/HorizontalSeparator";
import { batch, useDispatch } from "react-redux";
import {
  copyFindingColumnAnswer,
  removeFindingColumnAnswer,
  setSingleFindingColumnsFromEdited,
} from "../../../../../store/actions/findings/findingsActions";
import useIsMobile from "../../../../../hooks/useIsMobile";
import { useState } from "react";
import FindingTableGroupInput from "./FindingTableGroupInput/FindingTableGroupInput";

const FindingTableSingleRow = forwardRef((props, ref) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [inputsHeight, setInputsHeight] = useState(0);
  const { isMobile } = useIsMobile();
  const [isExpanded, setIsExpanded] = useState(props?.lastRow);

  useEffect(() => {
    setInputsHeight(props?.inputsHeight);
  }, [props?.inputsHeight]);

  const handleCopyRow = () => {
    batch(() => {
      dispatch(
        setSingleFindingColumnsFromEdited({ itemId: props?.questionId })
      );
      dispatch(
        copyFindingColumnAnswer({
          questionId: props?.questionId,
          answerIndex: props?.rowCount - 1,
        })
      );
    });
    props?.setIsAddedRow?.();
  };

  // const handleDrag = () => {};
  const handleRemoveRow = () => {
    batch(() => {
      dispatch(
        setSingleFindingColumnsFromEdited({ itemId: props?.questionId })
      );
      dispatch(
        removeFindingColumnAnswer({
          questionId: props?.questionId,
          answerIndex: props?.rowCount - 1,
        })
      );
    });
  };

  const handleExpand = () => {
    setIsExpanded((prevState) => !prevState);
  };

  // const calculateHeight = (index, el) => {
  //   groupItemsRef.current[index] = el;
  //   let newInputsHeight =
  //     groupItemsRef?.current?.reduce((acc, val) => {
  //       if (val == null) return acc;
  //       return acc + val?.offsetHeight;
  //     }, 0) +
  //     93 +
  //     56 +
  //     groupItemsRef?.current?.length * 25;
  //   newInputsHeight = isNaN(newInputsHeight) ? 0 : newInputsHeight;
  //   setInputsHeight(newInputsHeight);
  //   if (props?.inputs?.length - 1 === index)
  //     props?.setInputsHeight?.(newInputsHeight);
  // };

  return (
    <FindingTableSingleRowContainer $isExpanded={isExpanded} ref={ref}>
      <FindingTableSingleRowHeaderOptions onClick={handleExpand}>
        <SingleRowLeftContainer>
          {/* <DNDIcon onClick={handleDrag} isExpanded={isExpanded} /> */}
          {isMobile && (
            <MiniRowCount
              $isExpanded={isExpanded}
              $rowCount={props?.rowCount}
            />
          )}
        </SingleRowLeftContainer>
        <HeaderRowIconsContainer>
          {isMobile ? (
            <ExpandIcon $isExpanded={isExpanded} />
          ) : (
            <>
              {!props?.disabled && (
                <>
                  <CopyRowIcon onClick={handleCopyRow} />
                  <RemoveRowIcon onClick={handleRemoveRow} />
                </>
              )}
            </>
          )}
        </HeaderRowIconsContainer>
      </FindingTableSingleRowHeaderOptions>
      <FindingTableInputsContainer
        $isExpanded={isExpanded}
        $inputsHeight={inputsHeight}
      >
        <RowCount
          label={t("questions.ordinalNumber")}
          disabledField
          value={props?.rowCount}
        />
        {props?.inputs?.map?.((singleColumnGroupInput) => {
          // if (index === props?.inputs?.length - 1 && inputsHeight === 0) calculateHeight();
          return (
            <>
              <HorizontalSeparator />
              <FindingTableGroupInput
                clickListener={props?.clickListener}
                disabled={props?.disabled}
                questionId={props?.questionId}
                rowCount={props?.rowCount}
                immediateApply={props?.immediateApply}
                immediateApplyFunction={props?.immediateApplyFunction}
                groupInputs={[...singleColumnGroupInput]}
              />
            </>
          );
        })}
        <>
          {!props?.disabled && (
            <RemoveRowContainer>
              <CopyRowIcon onClick={handleCopyRow} />
              <RemoveRowIcon onClick={handleRemoveRow} />
            </RemoveRowContainer>
          )}
        </>
      </FindingTableInputsContainer>
    </FindingTableSingleRowContainer>
  );
});

FindingTableSingleRow.displayName = "FindingTableSingleRow";

FindingTableSingleRow.propTypes = {
  children: PropTypes.node,
  rowCount: PropTypes.number,
  questionId: PropTypes.number,
  inputsHeight: PropTypes.number,
  immediateApply: PropTypes.bool,
  disabled: PropTypes.bool,
  lastRow: PropTypes.bool,
  clickListener: PropTypes.func,
  setInputsHeight: PropTypes.func,
  immediateApplyFunction: PropTypes.func,
  setIsAddedRow: PropTypes.func,
  inputs: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        columnTitle: PropTypes.string,
        columnSubtitle: PropTypes.string,
      })
    )
  ),
};

export default FindingTableSingleRow;
