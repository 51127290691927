import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { Trans } from "react-i18next";
import { LicensePicker } from "./LicenseField.styled";

const LicenseField = (props) => {
  const formik = props?.formik;
  const hasImage = useMemo(() => formik?.values?.license, [props?.formik]);
  return (
    <LicensePicker
      smallerButtons
      width="100%"
      disabled={props?.disabled}
      height={hasImage ? 420 : 120}
      minWidth="100%"
      minHeight={hasImage ? 420 : 120}
      $hasImage={hasImage}
      image={formik?.values?.license}
      setImage={(newImage) => formik?.setFieldValue?.("license", newImage)}
      descriptionText={<Trans i18nKey="findingTypes.modal.chooseLicense" />}
      label={<Trans i18nKey="findingTypes.modal.license" />}
      disablePreview
    />
  );
};

LicenseField.propTypes = {
  formik: PropTypes.object,
  disabled: PropTypes.bool,
};

export default LicenseField;
