export const renameColumnsFormikConstants = {
  columnTitle: "columnTitle",
  columnSubtitle: "columnSubtitle",
};

export default (initialValues) => ({
  [renameColumnsFormikConstants.columnTitle]: initialValues?.columnTitle || "",
  [renameColumnsFormikConstants.columnSubtitle]:
    initialValues?.columnSubtitle || "",
});
