import {
  CLEAR_COMPANIES,
  CREATE_APR_IMAGES_ERROR,
  CREATE_APR_IMAGES_FETCH,
  CREATE_APR_IMAGES_SUCCESS,
  CREATE_COMPANY_ERROR,
  CREATE_COMPANY_FETCH,
  CREATE_COMPANY_SUCCESS,
  DELETE_COMPANY_ERROR,
  DELETE_COMPANY_FETCH,
  DELETE_COMPANY_SUCCESS,
  DEACTIVATE_COMPANY_ERROR,
  DEACTIVATE_COMPANY_FETCH,
  DEACTIVATE_COMPANY_SUCCESS,
  ACTIVATE_COMPANY_ERROR,
  ACTIVATE_COMPANY_FETCH,
  ACTIVATE_COMPANY_SUCCESS,
  EDIT_APR_IMAGES_ERROR,
  EDIT_APR_IMAGES_FETCH,
  EDIT_APR_IMAGES_SUCCESS,
  EDIT_COMPANY_ERROR,
  EDIT_COMPANY_FETCH,
  EDIT_COMPANY_SUCCESS,
  GET_COMPANIES_CLEAR,
  GET_COMPANIES_ERROR,
  GET_COMPANIES_FETCH,
  GET_COMPANIES_SUCCESS,
  MY_COMPANY_CLEAR,
  SET_COMPANIES,
  SET_COMPANY,
  SET_COMPANY_ERROR,
  SET_TOTAL_COMPANIES,
  SINGLE_COMPANY_ERROR,
  SINGLE_COMPANY_FETCH,
  SINGLE_COMPANY_SET,
  SINGLE_COMPANY_SUCCESS,
  MY_COMPANY_ERROR,
  MY_COMPANY_FETCH,
  MY_COMPANY_SET,
  MY_COMPANY_SUCCESS,
  EMAIL_VERIFICATION_FETCH,
  EMAIL_VERIFICATION_SUCCESS,
  EMAIL_VERIFICATION_ERROR,
  UPLOAD_JSON_SECRET_FETCH,
  UPLOAD_JSON_SECRET_SUCCESS,
  UPLOAD_JSON_SECRET_ERROR,
  VALIDATE_DRIVE_FETCH,
  VALIDATE_DRIVE_SUCCESS,
  VALIDATE_DRIVE_ERROR,
  VALIDATE_NEW_DRIVE_FETCH,
  VALIDATE_NEW_DRIVE_SUCCESS,
  VALIDATE_NEW_DRIVE_ERROR,
  SET_COMPANY_FROM_FORMIK,
  SET_IS_COMPANY_ACTIVATED,
  SET_COMPANY_SAVING_DISABLED,
} from "./companiesActionConstants";

export const setCompany = (payload) => ({
  type: SET_COMPANY,
  payload,
});

export const setCompanyError = (payload) => ({
  type: SET_COMPANY_ERROR,
  payload,
});

export const fetchCompanies = (payload) => ({
  type: GET_COMPANIES_FETCH,
  payload,
});
export const fetchCompaniesSuccess = (payload) => ({
  type: GET_COMPANIES_SUCCESS,
  payload,
});
export const fetchCompaniesError = (payload) => ({
  type: GET_COMPANIES_ERROR,
  payload,
});

export const fetchCompaniesClear = () => ({
  type: GET_COMPANIES_CLEAR,
});

export const fetchCreateCompany = (payload) => ({
  type: CREATE_COMPANY_FETCH,
  payload,
});
export const fetchCreateCompanySuccess = (payload) => ({
  type: CREATE_COMPANY_SUCCESS,
  payload,
});
export const fetchCreateCompanyError = (payload) => ({
  type: CREATE_COMPANY_ERROR,
  payload,
});

export const fetchDeleteCompany = (payload) => ({
  type: DELETE_COMPANY_FETCH,
  payload,
});
export const fetchDeleteCompanySuccess = (payload) => ({
  type: DELETE_COMPANY_SUCCESS,
  payload,
});
export const fetchDeleteCompanyError = (payload) => ({
  type: DELETE_COMPANY_ERROR,
  payload,
});

export const fetchEditCompany = (payload) => ({
  type: EDIT_COMPANY_FETCH,
  payload,
});
export const fetchEditCompanySuccess = (payload) => ({
  type: EDIT_COMPANY_SUCCESS,
  payload,
});
export const fetchEditCompanyError = (payload) => ({
  type: EDIT_COMPANY_ERROR,
  payload,
});

export const fetchActivateCompany = (payload) => ({
  type: ACTIVATE_COMPANY_FETCH,
  payload,
});
export const fetchActivateCompanySuccess = (payload) => ({
  type: ACTIVATE_COMPANY_SUCCESS,
  payload,
});
export const fetchActivateCompanyError = (payload) => ({
  type: ACTIVATE_COMPANY_ERROR,
  payload,
});

export const fetchDeactivateCompany = (payload) => ({
  type: DEACTIVATE_COMPANY_FETCH,
  payload,
});
export const fetchDeactivateCompanySuccess = (payload) => ({
  type: DEACTIVATE_COMPANY_SUCCESS,
  payload,
});
export const fetchDeactivateCompanyError = (payload) => ({
  type: DEACTIVATE_COMPANY_ERROR,
  payload,
});

export const fetchSingleCompany = (payload) => ({
  type: SINGLE_COMPANY_FETCH,
  payload,
});
export const fetchSingleCompanySuccess = (payload) => ({
  type: SINGLE_COMPANY_SUCCESS,
  payload,
});
export const fetchSingleCompanyError = (payload) => ({
  type: SINGLE_COMPANY_ERROR,
  payload,
});
export const setSingleCompany = (payload) => ({
  type: SINGLE_COMPANY_SET,
  payload,
});

export const fetchMyCompany = (payload) => ({
  type: MY_COMPANY_FETCH,
  payload,
});
export const fetchMyCompanySuccess = (payload) => ({
  type: MY_COMPANY_SUCCESS,
  payload,
});
export const fetchMyCompanyError = (payload) => ({
  type: MY_COMPANY_ERROR,
  payload,
});
export const setMyCompany = (payload) => ({
  type: MY_COMPANY_SET,
  payload,
});
export const setCompanyFromFormik = (payload) => ({
  type: SET_COMPANY_FROM_FORMIK,
  payload,
});
export const setCompanySavingDisabled = (payload) => ({
  type: SET_COMPANY_SAVING_DISABLED,
  payload,
});
export const clearMyCompany = (payload) => ({
  type: MY_COMPANY_CLEAR,
  payload,
});
export const setIsCompanyActivated = (payload) => ({
  type: SET_IS_COMPANY_ACTIVATED,
  payload,
});

export const setCompanies = (payload) => ({
  type: SET_COMPANIES,
  payload,
});
export const setTotalCompanies = (payload) => ({
  type: SET_TOTAL_COMPANIES,
  payload,
});
export const clearCompanies = () => ({
  type: CLEAR_COMPANIES,
});
export const fetchCreateAprImages = (payload) => ({
  type: CREATE_APR_IMAGES_FETCH,
  payload,
});
export const fetchCreateAprImagesSuccess = (payload) => ({
  type: CREATE_APR_IMAGES_SUCCESS,
  payload,
});
export const fetchCreateAprImagesError = (payload) => ({
  type: CREATE_APR_IMAGES_ERROR,
  payload,
});
export const fetchEditAprImages = (payload) => ({
  type: EDIT_APR_IMAGES_FETCH,
  payload,
});
export const fetchEditAprImagesSuccess = (payload) => ({
  type: EDIT_APR_IMAGES_SUCCESS,
  payload,
});
export const fetchEditAprImagesError = (payload) => ({
  type: EDIT_APR_IMAGES_ERROR,
  payload,
});

export const fetchEmailVerification = (payload) => ({
  type: EMAIL_VERIFICATION_FETCH,
  payload,
});
export const fetchEmailVerificationSuccess = (payload) => ({
  type: EMAIL_VERIFICATION_SUCCESS,
  payload,
});
export const fetchEmailVerificationError = (payload) => ({
  type: EMAIL_VERIFICATION_ERROR,
  payload,
});

export const fetchUploadJsonSecret = (payload) => ({
  type: UPLOAD_JSON_SECRET_FETCH,
  payload,
});
export const fetchUploadJsonSecretSuccess = (payload) => ({
  type: UPLOAD_JSON_SECRET_SUCCESS,
  payload,
});
export const fetchUploadJsonSecretError = (payload) => ({
  type: UPLOAD_JSON_SECRET_ERROR,
  payload,
});

export const fetchValidateDrive = (payload) => ({
  type: VALIDATE_DRIVE_FETCH,
  payload,
});
export const fetchValidateDriveSuccess = (payload) => ({
  type: VALIDATE_DRIVE_SUCCESS,
  payload,
});
export const fetchValidateDriveError = (payload) => ({
  type: VALIDATE_DRIVE_ERROR,
  payload,
});

export const fetchValidateNewDrive = (payload) => ({
  type: VALIDATE_NEW_DRIVE_FETCH,
  payload,
});
export const fetchValidateNewDriveSuccess = (payload) => ({
  type: VALIDATE_NEW_DRIVE_SUCCESS,
  payload,
});
export const fetchValidateNewDriveError = (payload) => ({
  type: VALIDATE_NEW_DRIVE_ERROR,
  payload,
});
