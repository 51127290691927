import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { closeModal } from "../../../store/actions/modal/modalActions";
import {
  DistributionMailsContainer,
  ErrorMessage,
  MailsLabel,
  // ErrorMessage,
  NewEmailButton,
  NewEmailIcon,
  SendMailsModalContainer,
  SingleDistributionMailContainer,
} from "./SendMailsModal.styled";
import {
  ButtonsContainer,
  CancelButton,
  CloseButton,
  ModalTitle,
  SubmitButton,
} from "../Modal.styled";
import BackdropComponent from "../../Modals/ModalComponents/BackdropComponent";
import DistributionMailInput from "../../Clients/NewClient/DistributionMailInput/DistributionMailInput";
import { validateEmail } from "../../../validations/mailValidation";
import { useMemo } from "react";
import { sendFindingMails } from "../../../store/actions/findings/findingsActions";

const SendMailsModal = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [triedToSend, setTriedToSend] = useState(false);
  const [mails, setMails] = useState([
    {
      valid: true,
      value: "",
    },
  ]);
  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  const addInputHandler = () => {
    setMails((prevMails) => [...prevMails, { valid: true, value: "" }]);
  };

  const removeInputHandler = (index) => {
    if (mails?.length !== 1) {
      setMails((prevMails) => {
        let newEmails = [...prevMails];
        newEmails.splice(index, 1);
        return newEmails;
      });
    }
  };

  const areMailsEmpty = useMemo(() => {
    return mails?.every((singleMail) => {
      return singleMail?.value?.length === 0;
    });
  }, [mails]);

  const handleChangeMail = (event, index) => {
    setMails((prevMails) => {
      let newEmails = [...prevMails];
      let isValidMail = true;
      if (!newEmails?.[index]?.valid) {
        isValidMail = validateEmail(event.target.value);
      }
      newEmails?.splice?.(index, 1);
      return [
        ...newEmails?.slice?.(0, index),
        {
          valid: isValidMail,
          value: event?.target?.value,
        },
        ...newEmails?.slice?.(index),
      ];
    });
  };

  const handleSubmit = () => {
    setTriedToSend(true);
    let validMails = true;
    for (let i = 0; i < mails?.length; i++) {
      if (!validateEmail(mails?.[i]?.value)) {
        validMails = false;
      }
    }
    if (!validMails) {
      setMails((prevMails) => {
        return prevMails?.map((singleMail) => ({
          ...singleMail,
          valid: validateEmail(singleMail?.value),
        }));
      });
    } else {
      if (!areMailsEmpty) {
        dispatch(
          sendFindingMails({
            findingId: props?.findingId,
            mails: mails?.map((singleMail) => singleMail?.value).join(";"),
          })
        );
        dispatch(closeModal());
      }
    }
  };

  return (
    <>
      {props?.rank > 1 ? (
        <BackdropComponent
          isLoading
          handleClose={handleCloseModal}
          position="fixed"
        />
      ) : (
        <></>
      )}
      <SendMailsModalContainer rank={props?.rank}>
        <CloseButton onClick={handleCloseModal} />
        <ModalTitle>{props?.title}</ModalTitle>

        <MailsLabel>{t("findings.sendMailsModal.inputLabel")}</MailsLabel>
        <DistributionMailsContainer>
          {mails?.map((item, index) => {
            return (
              <SingleDistributionMailContainer key={index}>
                <DistributionMailInput
                  onChange={(event) => handleChangeMail(event, index)}
                  value={item?.value}
                  minusHandler={() => removeInputHandler(index)}
                  error={!item?.valid}
                />
              </SingleDistributionMailContainer>
            );
          })}
          <NewEmailButton onClick={addInputHandler}>
            <NewEmailIcon />
            {t("clients.newEmail")}
          </NewEmailButton>
        </DistributionMailsContainer>
        {areMailsEmpty && triedToSend && (
          <ErrorMessage>{t("findings.sendMailsModal.emptyMails")}</ErrorMessage>
        )}

        <ButtonsContainer>
          <CancelButton onClick={handleCloseModal} style={{ height: 44 }}>
            {t("common.cancel")}
          </CancelButton>
          <SubmitButton onClick={handleSubmit} style={{ height: 44 }}>
            {t("common.submit")}
          </SubmitButton>
        </ButtonsContainer>
      </SendMailsModalContainer>
    </>
  );
};

SendMailsModal.propTypes = {
  children: PropTypes.node,
  rank: PropTypes.number,
  title: PropTypes.string,
  addInputTitle: PropTypes.string,
  onSubmit: PropTypes.func,
  emails: PropTypes.array,
  findingId: PropTypes.number,
};
SendMailsModal.defaultProps = {
  onSubmit: () => {},
};

export default SendMailsModal;
