import { Box } from "@mui/material";
import styled, { css } from "styled-components";
import { hexToRGB } from "../../../../util/helpers/colorHelper";
import selectedTheme from "../../../../themes";

export const LinkFieldContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-end;
`;
export const LinkCheck = styled.a`
  text-decoration: none;
  padding: 4px 8px;
  font-size: 14px;
  cursor: ${(props) => props?.$isValidUrl && "pointer"};
  background-color: ${(props) =>
    hexToRGB(selectedTheme.colors.linkColor, props?.$isValidUrl ? 0.15 : 0.4)};
  border-radius: 12px;
  color: ${(props) =>
    hexToRGB(selectedTheme.colors.linkColor, props?.$isValidUrl ? 1 : 0.4)};
  font-family: ${selectedTheme.fonts.mainText};
  &:hover {
    ${(props) =>
      props?.$isValidUrl &&
      css`
        background-color: ${hexToRGB(selectedTheme.colors.linkColor, 0.25)};
      `}
  }
`;
