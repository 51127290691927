import styled, { css } from "styled-components";
import { Box, Typography } from "@mui/material";
import { ReactComponent as AddImage } from "../../assets/images/svg/image-upload.svg";
import { ReactComponent as TrashIcon } from "../../assets/images/svg/trash.svg";
import selectedTheme from "../../themes";
import { hexToRGB } from "../../util/helpers/colorHelper";

export const ImagePickerContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const ImagePickerLabel = styled(Typography)`
  font-family: ${selectedTheme.fonts.mainText};
  font-size: 14px;
  font-weight: 600;
  color: ${selectedTheme.colors.labelTextColor};
    ${(props) =>
    props?.$required &&
    css`
      &::after {
        content: " *";
        color: ${selectedTheme.colors.errorColor};
      }
    `}
`;

export const AdditionalDescription = styled(Box)`
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  font-feature-settings: "salt" on;
  font-family: ${selectedTheme.fonts.mainText};
  color: ${selectedTheme.colors.imagePickerAdditionalDesc};
`;
export const ImagePickerDescriptionText = styled(Box)``;

export const ImagePickerContentContainer = styled(Box)`
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: center;
  ${(props) =>
    props?.$hasImage &&
    `
    border-radius: 12px;
  `};
  border: 1px dashed ${selectedTheme.colors.inputBorder};
  border-radius: 8px;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  ${(props) =>
    props?.$disabled &&
    css`
      background-color: ${selectedTheme.colors.disabledFieldBackground};
      cursor: initial;
    `}
`;
export const AddIcon = styled(AddImage)`
  z-index: 1;
`;
export const AddFile = styled.input`
  display: none;
`;

export const ImageUploadedContainer = styled(Box)`
  width: 64px;
  height: 64px;
  overflow: hidden;
`;

export const Tooltip = styled(Box)`
  background-color: ${hexToRGB(selectedTheme.colors.white, 0.5)};
  width: 100px;
  height: 100px;
  position: absolute;
  left: 0;
  top: 0;
`;
export const Trash = styled(TrashIcon)`
  cursor: pointer;
  margin: auto;
  width: 22px;
  height: 22px;
  & path {
    stroke: white;
  }
`;

export const ImagePickerDescription = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 8px;
  & * {
    display: inline;
    font-family: ${selectedTheme.fonts.mainText};
    font-size: 12px;
    text-align: center;
    & p {
      color: ${selectedTheme.colors.imagePickerDescription};
    }
  }
`;
