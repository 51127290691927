import React from 'react'
// import PropTypes from 'prop-types'
import {ReactComponent as ScanningSubjectIconSvg} from "../../../assets/images/svg/scanning-subjects.svg"

const ScanningSubjectIcon = props => {
  return (
    <ScanningSubjectIconSvg {...props} />
  )
}

ScanningSubjectIcon.propTypes = {}

export default ScanningSubjectIcon