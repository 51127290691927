import styled, { css } from "styled-components";
import Autocomplete from "@mui/material/Autocomplete";
import {
  Box,
  Chip,
  ListItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import selectedTheme from "../../../themes";
import { hexToRGB } from "../../../util/helpers/colorHelper";
import XIcon from "../../Icon/Icons/XIcon";

export const AutocompleteDropdown = styled(Autocomplete)`
  & > div > div {
    border-radius: 8px;
    height: ${(props) => (props?.multiple ? "auto" : "46px")};
    max-width: ${(props) =>
      !props?.$removeMaxWidth ? "412px !important" : "none !important"};
    padding-right: 9px !important;
    ${(props) =>
      props?.disabled &&
      css`
        background-color: ${selectedTheme.colors.disabledFieldBackground};
      `}
  }
  & input {
    padding-top: ${(props) =>
      props?.multiple ? "6px !important" : "2px !important"};
  }
  & input:disabled {
    -webkit-text-fill-color: ${selectedTheme.colors
      .disabledFieldText} !important;
  }
  & input::placeholder {
    font-family: ${selectedTheme.fonts.mainText};
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    opacity: 1 !important;
    color: ${selectedTheme.colors.placeholderColor} !important;
  }
  ${(props) =>
    props?.multiple &&
    css`
      & .MuiAutocomplete-endAdornment {
        bottom: 14px !important;
        top: auto !important;
        right: 9px !important;
      }
    `}
`;

export const AutocompleteInputField = styled(TextField)`
  & fieldset {
    border: 1px solid ${selectedTheme.colors.inputBorderColor} !important;
    box-shadow: 0px 1px 2px ${hexToRGB(selectedTheme.colors.shadowColor, 0.05)};
  }
  & input {
    font-family: Inter;
    font-size: 16px;
    font-feature-settings: "salt" on;
    padding-right: 55px !important;
    &::placeholder {
      -webkit-text-fill-color: ${selectedTheme.colors
        .inputPlaceholder} !important;
      color: ${selectedTheme.colors.inputPlaceholder} !important;
      opacity: 1;
    }
  }
  & > .Mui-error {
    & fieldset {
      border: 1px solid ${selectedTheme.colors.errorInputBorderColor} !important;
      box-shadow: 0px 1px 2px
        ${hexToRGB(selectedTheme.colors.shadowColor, 0.05)};
    }
  }
  & > .Mui-disabled {
    & input {
      -webkit-text-fill-color: ${selectedTheme.colors
        .disabledFieldText} !important;
      &::placeholder {
        -webkit-text-fill-color: ${selectedTheme.colors
          .disabledFieldText} !important;
        opacity: 1;
      }
    }
  }
`;

export const AutocompleteItem = styled(ListItem)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
`;

export const FieldLabel = styled(Typography)`
  font-family: ${selectedTheme.fonts.mainText};
  color: ${selectedTheme.colors.labelTextColor};
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 8px;
  color: ${selectedTheme.colors.labelTextColor};
  ${(props) =>
    props?.$required &&
    css`
      &::after {
        content: " *";
        color: ${selectedTheme.colors.errorColor};
      }
    `}
  ${(props) =>
    props?.$setMargin &&
    css`
      margin-top: 32px;
    `}
`;

export const AutocompleteContainer = styled(Box)`
  width: 100%;
`;

export const TagsContainer = styled(Stack)`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;
  flex-wrap: wrap;
  gap: 4px;
`;

export const Tag = styled(Chip)`
  padding: 0px 8px 0px 8px;
  height: 28px;
  border-radius: 24px;
  gap: 4px;
  background: ${selectedTheme.colors.findingTypeColor};
  font-family: ${selectedTheme.fonts.mainText};
  color: ${selectedTheme.colors.findingTypeTextColor};
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  font-feature-settings: "salt" on;
`;
export const DeleteButton = styled(XIcon)`
  min-width: 14px;
  min-height: 14px;
  max-width: 14px;
  max-height: 14px;
`;
