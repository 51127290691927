import { TOGGLE_SET_SIDEBAR_OPEN } from "../../actions/sidebar/sidebarActionConstants";
import createReducer from "../../utils/createReducer";

const initialState = {
  sidebar: false,
};

export default createReducer(
  {
    [TOGGLE_SET_SIDEBAR_OPEN]: toggleSetSidebarOpen,
  },
  initialState
);
function toggleSetSidebarOpen(state) {
  return {
    ...state,
    sidebar: !state.sidebar,
  };
}
