import React from "react";
// import PropTypes from 'prop-types'
import { ReactComponent as SettingsIconSvg } from "../../../assets/images/svg/settings-red.svg";

const SettingsIcon = (props) => {
  return <SettingsIconSvg {...props} />;
};

SettingsIcon.propTypes = {};

export default SettingsIcon;
