import { Box } from "@mui/material";
import styled from "styled-components";
import { TextInputFieldContainer } from "../../components/Field/TextInputField/TextInputField.styled";

export const InvitePageContainer = styled(Box)`
  & form {
    min-height: 0;
  }
  & ${TextInputFieldContainer} {
    max-width: 450px;
    & > div {
      width: 450px;
      & > div:nth-child(1) {
        width: 450px;
        & input {
          width: 450px !important;
        }
      }
    }
  }
`;
