const categoriesConstants = [
  {
    id: 0,
    name: "construction",
    i18key: "category.construction",
  },
  {
    id: 1,
    name: "emergencyServices",
    i18key: "category.emergencyServices",
  },
  {
    id: 2,
    name: "foodAndHospitality",
    i18key: "category.foodAndHospitality",
  },
  {
    id: 3,
    name: "general",
    i18key: "category.general",
  },
  {
    id: 4,
    name: "healthcare",
    i18key: "category.healthcare",
  },
  {
    id: 5,
    name: "manufacturing",
    i18key: "category.manufacturing",
  },
  {
    id: 6,
    name: "safety",
    i18key: "category.safety",
  },
  {
    id: 7,
    name: "transportAndLogistics",
    i18key: "category.transportAndLogistics",
  },
  
];

export default categoriesConstants;

export const findCategoryByName = (name) => {
  return categoriesConstants.find(
    (item) => item.name.toLowerCase() === name.toLowerCase()
  );
};
