import React from "react";
// import PropTypes from 'prop-types'
import { ReactComponent as TickCircledSvg } from "../../../assets/images/svg/tick-circle.svg";

const TickCircledIcon = (props) => {
  return <TickCircledSvg {...props} />;
};

TickCircledIcon.propTypes = {};

export default TickCircledIcon;
