import * as Yup from "yup";
import { licenseFormikConstants } from "../initialValues/licenseInitialValues";
import { constructDate, isStringDate } from "../util/helpers/dateHelpers";

export default () => Yup.object().shape({
  [licenseFormikConstants.startDate]: Yup.string().test(
    "required-check",
    ("licenses.modal.fields.startDate.required"),
    (value) => isStringDate(value)
  ),
  [licenseFormikConstants.expirationDate]: Yup.string().test(
    "required-and-time-check",
    ("licenses.modal.fields.expirationDate.timecheck"),
    function () {
      if (!isStringDate(this.parent.expirationDate)) return false;
      if (!isStringDate(this.parent.startDate)) return false;
      if (
        new Date(constructDate(this.parent.startDate)).getTime() >=
        new Date(constructDate(this.parent.expirationDate)).getTime()
      )
        return false;
      return true;
    }
  ),
});
