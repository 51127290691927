import { Box, Typography } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../themes";

export const SectionContainer = styled(Box)`
    display: flex;
    flex-direction: column;
    gap: 20px;
`
export const SectionTitle = styled(Typography)`
    margin: 0;
    font-family: ${selectedTheme.fonts.mainText};
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 6px;
    &::after {
        content: "";
        display: block;
        width: 190px;
        height: 1px;
        background-color: ${selectedTheme.colors.black};
    }
`