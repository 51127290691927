import { Box } from "@mui/material";
import styled from "styled-components";

export const TemplateSectionsListItemContainer = styled(Box)`
    display: flex;
    flex-direction: row;
    gap: 16px;
    align-items: center;
    @media (max-width:600px)
    {
        width:100%
    }
`