import React, { useCallback, useMemo, useRef } from "react";
import PropTypes from "prop-types";
import Table from "../../Table/Table";
import standardsTableConstants from "../../../constants/tableConstants/standardsTableConstants";
import TextRenderer from "../../Table/Renderers/TextRenderer/TextRenderer";
import { useTranslation } from "react-i18next";
import {
  selectStandardsData,
  selectTotalStandards,
} from "../../../store/selectors/standardsSelectors";
import {
  clearStandards,
  fetchStandards,
} from "../../../store/actions/standards/standardsActions";
import { useDispatch } from "react-redux";
import {
  closeModal,
  toggleSetStandardModal,
} from "../../../store/actions/modal/modalActions";
import { makeToastMessage } from "../../../store/utils/makeToastMessage";
import { APPLICATION_TOAST_CONTAINER_ID } from "../../../constants/toastConstants";
import useAuth from "../../../hooks/useAuth";
import { MANAGER_ROLE } from "../../../constants/rolesConstants";
import { formatDateTime } from "../../../util/helpers/dateHelpers";

const StandardsTable = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const tableRef = useRef(null);
  const { hasRole } = useAuth();

  const hideAddAndUpdate = useMemo(() => {
    return !hasRole(MANAGER_ROLE);
  }, [hasRole]);

  const tableColumns = useMemo(() => {
    let columnsObject = Object.keys(standardsTableConstants).map((property) => {
      return {
        ...standardsTableConstants[property],
        field: t(standardsTableConstants[property].i18key),
        propertyName: property,
        backendProperty:
          standardsTableConstants[property]?.backendProperty || property,
        style: standardsTableConstants[property]?.style,
        renderer: standardsTableConstants[property]?.renderer || TextRenderer,
        rendererProps: { hideChangePassword: true },
      };
    });
    return columnsObject;
  }, [standardsTableConstants, t, hideAddAndUpdate]);

  const mapDataFunction = useCallback((newData) => {
    return newData.map((singleData) => {
      let mappedDataObject = {
        caption: singleData.caption,
        isDefault: singleData.isDefault,
        findingTypes: singleData.findingTypes,
        lastChanges: {
          date: formatDateTime(
            singleData?.updatedAtUtc || new Date().toString()
          ),
          author: `${singleData?.updatedByUser?.firstName || ""} ${
            singleData?.updatedByUser?.lastName || ""
          }`,
        },
        id: singleData?.id,
        actions: {
          standardId: singleData?.id,
          caption: singleData?.caption,
          findingTypes: singleData.findingTypes,
          isDefault: singleData?.isDefault,
          hideUpdate: hideAddAndUpdate,
          refetchTable: (name) => handleApiResponseSuccess(name, true),
        },
      };
      return mappedDataObject;
    });
  }, []);

  const refetchTable = () => {
    tableRef?.current?.forceRefetch();
  };

  const handleApiResponseSuccess = (name, isEditing = false) => {
    refetchTable();
    const titleI18Key = name.isDeleted
      ? "toast.success.deletedTitle"
      : isEditing
      ? "toast.success.editedTitle"
      : "toast.success.createdTitle";
    const descriptionI18Key = name.isDeleted
      ? "toast.success.deletedDescription"
      : isEditing
      ? "toast.success.editedDescription"
      : "toast.success.createdDescription";
    dispatch(closeModal());
    makeToastMessage(
      {
        title: t(titleI18Key, {
          typeOfData: t("standards.typeOfData"),
        }),
        description: t(descriptionI18Key, {
          name: name.inputValue,
          typeOfData: t("standards.typeOfDataPlural").toLowerCase(),
        }),
      },
      {
        containerId: APPLICATION_TOAST_CONTAINER_ID,
      }
    );
  };

  const handleClickAddButton = () => {
    dispatch(
      toggleSetStandardModal({
        title: t("standards.modal.newStandard"),
        isEditing: false,
        rank: 1,
        refetchTable: (name) => handleApiResponseSuccess(name, false),
        tableRef,
      })
    );
  };

  const handleRedirect = (values) => {
    dispatch(
      toggleSetStandardModal({
        title: t("standards.modal.editStandard"),
        prefilledData: values?.prefilledData,
        isEditing: true,
        rank: 0,
        standardId: values?.id,
        refetchTable: (name) => handleApiResponseSuccess(name, true),
        tableRef,
      })
    );
  };

  return (
    <Table
      ref={tableRef}
      tableColumns={tableColumns}
      hideAddButton={hideAddAndUpdate}
      searchPlaceholder={t("standards.table.searchPlaceholder")}
      dataSelector={selectStandardsData}
      totalDataSelector={selectTotalStandards}
      dispatchFunction={fetchStandards}
      mapDataFunction={mapDataFunction}
      redirectFunction={handleRedirect}
      handleClickAddButton={handleClickAddButton}
      clearDispatchFunction={clearStandards}
    />
  );
};

StandardsTable.propTypes = {
  children: PropTypes.node,
};

export default StandardsTable;
