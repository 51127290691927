import React from "react";
import PropTypes from "prop-types";
import { ActiveTemplateEditorContainer } from "./ActiveTemplateEditor.styled";
import TemplateMeasuringInstrumentsEditor from "./TemplateMeasuringInstrumentsEditor/TemplateMeasuringInstrumentsEditor";
import TemplateSectionsEditor from "./TemplateSectionsEditor/TemplateSectionsEditor";
import TemplateSectionsContent from "./TemplateSectionsContent/TemplateSectionsContent";
import TemplateSectionsList from "./TemplateSectionsEditor/TemplateSectionsList/TemplateSectionsList";
import { useDispatch, useSelector } from "react-redux";
import {
  selectSelectedChecklistTemplate,
  selectSingleScanningObjectAllSections,
  selectSingleScanningObjectSection,
} from "../../../store/selectors/scanningObjectsSelectors";
import EmptyScanningObjectPicker from "../../ScanningObjects/NewScanningObject/ScanningObjectPicker/EmptyScanningObjectPicker/EmptyScanningObjectPicker";
import { addScanningObjectSelectedChecklistTemplate } from "../../../store/actions/scanningObjects/scanningObjectsActions";
import { ALL_TEMPLATES_TAB } from "../../../constants/templateEditorTabs";
import TemplateStandardsEditor from "./TemplateStandardsEditor/TemplateStandardsEditor";
import TemplateConclusionEditor from "./TemplateConclusionEditor/TemplateConclusionEditor";

const ActiveTemplateEditor = (props) => {
  const allSections = useSelector(selectSingleScanningObjectAllSections);
  const checklistTemplate = useSelector(selectSelectedChecklistTemplate);
  const dispatch = useDispatch();

  const handleChooseSection = (sectionId) => {
    props?.setChosenSectionId(sectionId);
  };

  const chosenSection = useSelector(
    selectSingleScanningObjectSection(props?.chosenSectionId)
  );

  const handleAddTemplate = () => {
    dispatch(
      addScanningObjectSelectedChecklistTemplate({
        isDefault: true,
        changed: true,
        added: true,
        id: Math.random(),
        measuringInstruments: [],
        standards: [],
        sections: [],
      })
    );
  };

  if (!checklistTemplate) {
    return !props?.isReadOnly ? (
      <EmptyScanningObjectPicker
        activeTemplates={true}
        handleAddTemplate={handleAddTemplate}
        handleSwitchToAllTemplates={() =>
          props?.handleChangeTab(ALL_TEMPLATES_TAB)
        }
      />
    ) : (
      <></>
    );
  }

  return (
    <ActiveTemplateEditorContainer>
      <TemplateMeasuringInstrumentsEditor isReadOnly={props?.isReadOnly} />
      <TemplateStandardsEditor isReadOnly={props?.isReadOnly} />
      <TemplateSectionsEditor>
        <TemplateSectionsList
          chosenSectionId={props?.chosenSectionId}
          setChosenSectionId={handleChooseSection}
          isReadOnly={props?.isReadOnly}
        />
      </TemplateSectionsEditor>
      {allSections?.length !== 0 && chosenSection && (
        <TemplateSectionsContent
          chosenSectionId={props?.chosenSectionId}
          isReadOnly={props?.isReadOnly}
        />
      )}
      <TemplateConclusionEditor isReadOnly={props?.isReadOnly} />
    </ActiveTemplateEditorContainer>
  );
};

ActiveTemplateEditor.displayName = "ActiveTemplateEditor";

ActiveTemplateEditor.propTypes = {
  children: PropTypes.node,
  allSections: PropTypes.array,
  selectedMeasuringInstruments: PropTypes.array,
  chosenSectionId: PropTypes.number,
  setSections: PropTypes.func,
  setSelectedMeasuringInstruments: PropTypes.func,
  setChosenSectionId: PropTypes.func,
  handleChangeTab: PropTypes.func,
  isReadOnly: PropTypes.bool,
};

ActiveTemplateEditor.defaultProps = {
  allSections: [],
  selectedMeasuringInstruments: [],
  chosenSectionId: 0,
};

export default ActiveTemplateEditor;
