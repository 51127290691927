import { Box } from "@mui/material";
import styled, { css } from "styled-components";
import selectedTheme from "../../../themes";
import TickIcon from "../../Icon/Icons/TickIcon";

export const LanguagePopoverContainer = styled(Box)`
  padding: 6px;
  background-color: ${selectedTheme.colors.white};
  width: 202px;
  border-radius: 10px;
`;
export const SingleLanguageItemContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 8px;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: ${selectedTheme.colors.languageDropdownHoverBg};
  }
  ${(props) =>
    props?.$chosen &&
    css`
      background-color: ${selectedTheme.colors.languageDropdownChosenBg};
    `}
`;
export const SingleLanguageItemLeftContainer = styled(Box)`
  display: flex;
  gap: 16px;
  align-items: center;
`;
export const SingleLanguageItemText = styled(Box)`
  font-family: ${selectedTheme.fonts.mainText};
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${selectedTheme.colors.userPopoverItemColor2};
`;
export const LanguagePopoverTickIcon = styled(TickIcon)`
  justify-self: flex-end;
  & path {
    stroke: ${selectedTheme.colors.dropdownIcon};
  }
`;
