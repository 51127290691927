import * as Yup from "yup";
import { findingFormikConstants } from "../initialValues/findingInitialValues";

export default () =>
  Yup.object().shape({
    [findingFormikConstants.clientId]: Yup.object().shape({
      value: Yup.number()
        .typeError("findings.form.client.required")
        .test(
          "required-check",
          "findings.form.client.required",
          (value) => typeof value === "number" && value > 0
        ),
    }),
    [findingFormikConstants.location]: Yup.object().shape({
      value: Yup.string().test(
        "required-check",
        "findings.form.location.required",
        (value) => value?.length > 0
      ),
    }),
    [findingFormikConstants.dateOfChecking]: Yup.object().shape({
      value: Yup.string().test(
        "required-check",
        "findings.form.dateOfChecking.required",
        (value) => value?.length > 0
      ),
    }),
    [findingFormikConstants.dateOfFinding]: Yup.object().shape({
      value: Yup.string(),
    }),
    // .required(
    //   ("findings.form.dateOfFinding.required")
    // )
    [findingFormikConstants.staffId]: Yup.object().shape({
      value: Yup.number()
        .typeError("findings.form.staff.required")
        .test(
          "required-check",
          "findings.form.staff.required",
          (value) => typeof value === "number" && value > 0
        ),
    }),
    [findingFormikConstants.description]: Yup.object().shape({
      value: Yup.string(),
    }),
    [findingFormikConstants.note]: Yup.object().shape({
      value: Yup.string(),
    }),
    [findingFormikConstants.facility]: Yup.object().shape({
      value: Yup.string().nullable(),
    }),
  });
