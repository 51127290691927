import React, { useCallback, useMemo, useRef } from "react";
import PropTypes from "prop-types";
import Table from "../../Table/Table";
import findingTypesTableConstants from "../../../constants/tableConstants/findingTypesTableConstants";
import TextRenderer from "../../Table/Renderers/TextRenderer/TextRenderer";
import { useTranslation } from "react-i18next";
import {
  selectFindingTypesData,
  selectTotalFindingTypes,
} from "../../../store/selectors/findingTypesSelectors";
import {
  clearFindingTypes,
  fetchFindingTypes,
} from "../../../store/actions/findingTypes/findingTypesActions";
import { useDispatch } from "react-redux";
import {
  closeModal,
  toggleSetFindingTypeModal,
} from "../../../store/actions/modal/modalActions";
import { makeToastMessage } from "../../../store/utils/makeToastMessage";
import { APPLICATION_TOAST_CONTAINER_ID } from "../../../constants/toastConstants";
import useAuth from "../../../hooks/useAuth";
import { MANAGER_ROLE } from "../../../constants/rolesConstants";
import { formatDateTime } from "../../../util/helpers/dateHelpers";
import {
  EDIT_FINDING_TYPE_PAGE,
  NEW_FINDING_TYPE_PAGE,
} from "../../../constants/pages";
import { replaceInRoute } from "../../../util/helpers/routeHelpers";

const FindingTypesTable = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const tableRef = useRef(null);
  const { hasRole } = useAuth();

  const hideAddAndUpdate = useMemo(() => {
    return !hasRole(MANAGER_ROLE);
  }, [hasRole]);

  const tableColumns = useMemo(() => {
    let columnsObject = Object.keys(findingTypesTableConstants).map(
      (property) => {
        return {
          ...findingTypesTableConstants[property],
          field: t(findingTypesTableConstants[property].i18key),
          propertyName: property,
          backendProperty:
            findingTypesTableConstants[property]?.backendProperty || property,
          style: findingTypesTableConstants[property]?.style,
          renderer:
            findingTypesTableConstants[property]?.renderer || TextRenderer,
          rendererProps: { hideChangePassword: true },
        };
      }
    );
    return columnsObject;
  }, [findingTypesTableConstants, t, hideAddAndUpdate]);

  const mapDataFunction = useCallback((newData) => {
    return newData.map((singleData) => {
      let mappedDataObject = {
        name: singleData.name,
        caption: singleData.caption,
        subcaption: singleData.subcaption,
        lastChanges: {
          date: formatDateTime(
            singleData?.updatedAtUtc || new Date().toString()
          ),
          author: `${singleData?.updatedByUser?.firstName || ""} ${
            singleData?.updatedByUser?.lastName || ""
          }`,
        },
        id: singleData?.id,
        actions: {
          id: singleData?.id,
          name: singleData?.name,
          caption: singleData?.caption,
          subcaption: singleData?.subcaption,
          licenseUrl: singleData?.licenseUrl,
          refetchTable: (name) => handleApiResponseSuccess(name, true),
        },
      };
      return mappedDataObject;
    });
  }, []);

  const refetchTable = () => {
    tableRef?.current?.forceRefetch();
  };

  const handleApiResponseSuccess = (name, isEditing = false) => {
    refetchTable();
    const titleI18Key = name.isDeleted
      ? "toast.success.deletedTitle"
      : isEditing
      ? "toast.success.editedTitle"
      : "toast.success.createdTitle";
    const descriptionI18Key = name.isDeleted
      ? "toast.success.deletedDescription"
      : isEditing
      ? "toast.success.editedDescription"
      : "toast.success.createdDescription";
    dispatch(closeModal());
    makeToastMessage(
      {
        title: t(titleI18Key, {
          typeOfData: t("findingTypes.typeOfData"),
        }),
        description: t(descriptionI18Key, {
          name: name.inputValue,
          typeOfData: t("findingTypes.typeOfDataPlural").toLowerCase(),
        }),
      },
      {
        containerId: APPLICATION_TOAST_CONTAINER_ID,
      }
    );
  };

  const handleClickAddButton = () => {
    dispatch(
      toggleSetFindingTypeModal({
        title: t("findingTypes.modal.newFindingType"),
        isEditing: false,
        rank: 1,
        refetchTable: (name) => handleApiResponseSuccess(name, false),
        tableRef,
      })
    );
  };
  console.log(handleClickAddButton);

  // const handleRedirect = (values) => {
  //   dispatch(
  //     toggleSetFindingTypeModal({
  //       title: t("findingTypes.modal.editFindingType"),
  //       prefilledData: {
  //         name: values?.prefilledData?.name,
  //         caption: values?.prefilledData?.caption,
  //         subcaption: values?.prefilledData?.subcaption,
  //         licenseUrl: values?.prefilledData?.actions?.licenseUrl,
  //       },
  //       isEditing: true,
  //       rank: 1,
  //       id: values?.prefilledData?.id,
  //       refetchTable: (name) => handleApiResponseSuccess(name, true),
  //       tableRef,
  //     })
  //   );
  // };

  const redirectHrefFunction = (findingTypeId) =>
    replaceInRoute(EDIT_FINDING_TYPE_PAGE, {
      findingTypeId,
    });

  return (
    <Table
      ref={tableRef}
      tableColumns={tableColumns}
      hideAddButton={hideAddAndUpdate}
      searchPlaceholder={t("findingTypes.table.searchPlaceholder")}
      dataSelector={selectFindingTypesData}
      totalDataSelector={selectTotalFindingTypes}
      dispatchFunction={fetchFindingTypes}
      mapDataFunction={mapDataFunction}
      // redirectFunction={handleRedirect}
      redirectHrefFunction={redirectHrefFunction}
      addButtonHref={NEW_FINDING_TYPE_PAGE}
      // handleClickAddButton={handleClickAddButton}
      clearDispatchFunction={clearFindingTypes}
    />
  );
};

FindingTypesTable.propTypes = {
  children: PropTypes.node,
};

export default FindingTypesTable;
