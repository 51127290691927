import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { selectIsSidebarOpened } from "../../../../store/selectors/sidebarSelector";
import {
  SidebarNavigationItemDetailsContainer,
  SidebarNavigationItemIconContainer,
  SidebarNavigationItemTitleContainer,
} from "./SidebarNavigationItemDetails.styled";
import { isInRoute } from "../../../../util/helpers/routeHelpers";
import { useLocation } from "react-router-dom";
import Tooltip from "../../../Tooltip/Tooltip";
import useIsMobile from "../../../../hooks/useIsMobile";
import { toggleSetSidebarOpen } from "../../../../store/actions/sidebar/sidebarActions";
import { Trans, useTranslation } from "react-i18next";

const SidebarNavigationitemDetails = (props) => {
  const isSidebarOpened = useSelector(selectIsSidebarOpened);
  const dispatch = useDispatch();
  const { isMobile } = useIsMobile();
  const location = useLocation();
  const { t } = useTranslation();

  const handleClick = () => {
    if (isMobile) dispatch(toggleSetSidebarOpen());
  };

  const isRouteActive = useMemo(() => {
    if (!props?.item?.route) return false;
    return !props?.item?.subnavigationRoutes && isInRoute(props?.item?.route);
  }, [location]);

  return (
    <SidebarNavigationItemDetailsContainer
      to={props?.item?.route || ""}
      $cancelHover={props?.item?.subnavigationRoutes}
      $isActive={isRouteActive}
      onClick={handleClick}
    >
      {/* Sidebar icon */}
      <Tooltip
        title={
          !isSidebarOpened && !props?.item?.subnavigationRoutes
            ? t(props?.item?.title)
            : ""
        }
        placement="right"
      >
        <SidebarNavigationItemIconContainer
          $isSidebarOpened={isSidebarOpened}
          $isActive={isRouteActive}
          $fill={props?.item?.fill}
          $changeIconColor={props?.$changeIconColor}
        >
          {props?.item?.icon}
        </SidebarNavigationItemIconContainer>
      </Tooltip>

      {/* Shown only on expanded sidebar */}
      {isSidebarOpened && (
        <SidebarNavigationItemTitleContainer $isActive={isRouteActive}>
          <Trans i18nKey={props?.item?.title} />
        </SidebarNavigationItemTitleContainer>
      )}
    </SidebarNavigationItemDetailsContainer>
  );
};

SidebarNavigationitemDetails.propTypes = {
  item: PropTypes.object,
  $changeIconColor: PropTypes.bool,
};

export default SidebarNavigationitemDetails;
