import React from "react";
import PropTypes from "prop-types";
import {
  ActionRendererContainer,
  ActionRendererDeleteStandardIconContainer,
  ActionRendererEditStandardIconContainer,
} from "./ActionRenderer.styled";
import EditIcon from "../../../../Icon/Icons/EditIcon";
import DeleteIcon from "../../../../Icon/Icons/DeleteIcon.js";
import {
  closeLoadingModal,
  closeModal,
  toggleSetDeleteModal,
  toggleSetLoadingModal,
  toggleSetStandardModal,
} from "../../../../../store/actions/modal/modalActions";
import i18n from "../../../../../i18n";
import { fetchDeleteStandard } from "../../../../../store/actions/standards/standardsActions";
import Tooltip from "../../../../Tooltip/Tooltip";
import WithPermissions from "../../../../WithPermissions/WithPermissions";
import { MANAGER_ROLE } from "../../../../../constants/rolesConstants";

const ActionRenderer = (props) => {
  const handleApiResponseSuccess = (name) => {
    props?.dispatch(closeModal());
    props?.value?.refetchTable(name);
  };

  const handleApiResponseError = () => {
    props?.dispatch(closeLoadingModal());
  };

  const handleEditStandard = () => {
    props?.dispatch(
      toggleSetStandardModal({
        title: i18n.t("standards.modal.editStandard"),
        prefilledData: {
          caption: props?.value?.caption,
          isDefault: props?.value?.isDefault,
          findingTypes: props?.value?.findingTypes,
        },
        isEditing: true,
        rank: 1,
        standardId: props?.value?.standardId,
        refetchTable: props?.value?.refetchTable,
        tableRef: props?.value.tableRef,
      })
    );
  };

  const handleDeleteStandard = () => {
    props?.dispatch(toggleSetLoadingModal({ rank: 2 }));
    props?.dispatch(
      fetchDeleteStandard({
        standardId: props?.value?.standardId,
        handleApiResponseSuccess: () =>
          handleApiResponseSuccess({
            inputValue: props?.value?.caption,
            isDeleted: true,
          }),
        handleApiResponseError,
      })
    );
  };

  const handleDelete = () => {
    props?.dispatch(
      toggleSetDeleteModal({
        title: i18n.t("standards.modal.delete.title"),
        description: {
          first: i18n.t("standards.modal.delete.description.1"),
          second: i18n.t("standards.modal.delete.description.2"),
        },
        onSubmit: handleDeleteStandard,
        rank: 1,
      })
    );
  };

  return (
    <ActionRendererContainer>
      <Tooltip title={i18n.t("standards.tooltip.changeStandard")}>
        <ActionRendererEditStandardIconContainer onClick={handleEditStandard}>
          <EditIcon />
        </ActionRendererEditStandardIconContainer>
      </Tooltip>
      <WithPermissions roles={[MANAGER_ROLE]}>
        <Tooltip title={i18n.t("standards.tooltip.deleteStandard")}>
          <ActionRendererDeleteStandardIconContainer onClick={handleDelete}>
            <DeleteIcon />
          </ActionRendererDeleteStandardIconContainer>
        </Tooltip>
      </WithPermissions>
    </ActionRendererContainer>
  );
};

ActionRenderer.propTypes = {
  children: PropTypes.node,
  dispatch: PropTypes.func,
  value: PropTypes.object,
  rendererProps: PropTypes.any,
  refetchTable: PropTypes.func,
};

export default ActionRenderer;
