import { LANGUAGES } from "../../../constants/languageConstants";
import {
  ADD_TRANSLATIONS_RESOURCE,
  SET_CURRENT_LNG,
} from "../../actions/translations/translationsActionConstants";
import createReducer from "../../utils/createReducer";

const initialState = {
  currentLanguage: LANGUAGES?.[0]?.culture,
  languageResources: [],
};

export default createReducer(
  {
    [ADD_TRANSLATIONS_RESOURCE]: addTranslationsResource,
    [SET_CURRENT_LNG]: setCurrentLanguage,
  },
  initialState
);
function addTranslationsResource(state, { payload }) {
  return {
    ...state,
    languageResources: [...state?.languageResources, payload],
  };
}
function setCurrentLanguage(state, { payload }) {
  return {
    ...state,
    currentLanguage: payload,
  };
}
