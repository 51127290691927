import * as Yup from "yup";

export default () =>
  Yup.object().shape({
    caption: Yup.string()
      .trim()
      .max(255, "standards.modal.validationMessage")
      .required("standards.modal.errorMessage"),
    isDefault: Yup.bool(),
    findingTypes: Yup.array(),
  });
