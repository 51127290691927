import React from "react";
import PropTypes from "prop-types";
import { forwardRef } from "react";
import {
  ChapterContainer,
  TableTextInput,
} from "../../SetScanningObjectModal.styled";
import {
  AddColumnIcon,
  AddColumnText,
  AddContainer,
  AlignmentTitle,
  ColumnsContainer,
  FlexChapterTitle,
  PreviewContainer,
  PreviewIcon,
  PreviewText,
  RemoveColumnIcon,
  TitleContainer,
  TitleFlexTitlesContainer,
} from "./TableTypeInputs.styled";
import { useState } from "react";
import { useImperativeHandle } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toggleTablePreviewModal } from "../../../../../store/actions/modal/modalActions";
import AlignmentPicker from "./AlignmentPicker/AlignmentPicker";
import { ALIGNMENT } from "../../../../../constants/alignmentConstants";
import { Box } from "@mui/material";
import useIsMobile from "../../../../../hooks/useIsMobile";

const TableTypeInputs = forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const {isMobile} = useIsMobile();
  const [columns, setColumns] = useState(
    props?.columns[0] !== "" && props?.columns?.length > 0
      ? props?.columns?.sort?.(function (a, b) {
        return a?.columnIndex - b?.columnIndex;
      })
      : [
        {
          columnTitle: "",
          columnSubtitle: "",
          columnIndex: 0,
          defaultAnswer: "",
          textAlignment: ALIGNMENT.LEFT,
        },
      ]
  );

  const [rows, setRows] = useState(
    props?.rows[0] !== "" && props?.rows?.length > 0
      ? props?.rows?.sort?.(function (a, b) {
        return a?.columnIndex - b?.columnIndex;
      })
      :
      []//{ rowIndex: 0, columnIndex: 0, answer: "" }

  )
  const { t } = useTranslation();

  console.log('TableTypeInputs',props);
  useImperativeHandle(ref, () => ({
    columns,
    rows,
    setColumns,
    setRows
  }));

  const handleChangeValue = (newValue, id) => {
    setColumns((prevColumns) => {
      let newColumns = [...prevColumns];
      let selectedColumn = newColumns?.find?.(
        (column) => column?.columnIndex === id
      );
      selectedColumn = { ...selectedColumn, ...newValue };
      newColumns?.splice(
        newColumns?.findIndex(
          (singleColumn) => singleColumn?.columnIndex === id
        ),
        1,
        selectedColumn
      );
      return [...newColumns];
    });
  };

  const handleRemoveColumn = (index) => {
    setColumns((prevColumns) => {
      let newColumns = [...prevColumns];
      newColumns?.splice?.(index, 1);
      if (newColumns?.length === 0)
        newColumns = [
          {
            columnTitle: "",
            columnSubtitle: "",
            columnIndex: 0,
            defaultAnswer: "",
            textAlignment: ALIGNMENT.LEFT,
          },
        ];
      return [...newColumns];
    });

    setRows((prevRows) => {
      let newRow = prevRows?.filter((row) => {
        return row.columnIndex != parseInt(index)
      })
      return newRow
    })


  };
  const handleAddColumn = () => {
    var maxIndex = Math.max(...columns?.map?.((o) => o?.columnIndex)) || 0;
    setColumns((prevColumns) => [
      ...prevColumns,
      {
        columnTitle: "",
        columnSubtitle: "",
        columnIndex: maxIndex + 1,
        defaultAnswer: "",
        textAlignment: ALIGNMENT.LEFT,
      },
    ]);

    //Provera da li u rows imamo novu kolonu 
    const columnExistsInRows = props.rows.some((row) => row.columnIndex === (maxIndex + 1));

    if (rows.length > 1 && !columnExistsInRows) {

      setRows((prevRows) => {

        const uniqueRowIndices = [...new Set(prevRows.map(row => row.rowIndex))];
        const newRows = uniqueRowIndices.map(rowIndex => ({
          rowIndex,
          columnIndex: maxIndex + 1,
          answer: ""
        }))
        return [...prevRows, ...newRows];
      });

    }
    /*else {
      setRows((prevRows) => [
        ...prevRows,
        {
          rowIndex: 0,
          columnIndex: maxIndex + 1,
          answer: ""
        }
      ])
    }
*/
  };

  const handleTablePreview = () => {
    dispatch(toggleTablePreviewModal({ rank: 2, columns, rows, onSubmit: handleSubmit }));
  };

  const handleSubmit = (newRows) => {
    setRows(newRows)
  }

  return (
    <>
      <ChapterContainer>
        <TitleContainer>
          <TitleFlexTitlesContainer>
            <FlexChapterTitle>{t("questions.columnTitle")}</FlexChapterTitle>
            <FlexChapterTitle>{t("questions.columnSubtitle")}</FlexChapterTitle>
            {/* <FlexChapterTitle>{t("common.answer")}</FlexChapterTitle> */}
          </TitleFlexTitlesContainer>
          {!isMobile && <AlignmentTitle component={Box}>
            {t("text.textAlignment")}
          </AlignmentTitle>}
        </TitleContainer>
        {columns?.map((singleColumn, index) => (
          <ColumnsContainer key={singleColumn?.columnIndex}>
            <TableTextInput
              placeholder={t("questions.columnTitle")}
              value={singleColumn?.columnTitle}
              inputProps={{
                style: {
                  flex: 1,
                },
              }}
              onChange={(event) =>
                handleChangeValue(
                  { columnTitle: event?.target?.value },
                  singleColumn?.columnIndex || 0
                )
              }
            />
            <TableTextInput
              placeholder={t("questions.columnSubtitle")}
              value={singleColumn?.columnSubtitle}
              inputProps={{
                style: {
                  flex: 1,
                },
              }}
              onChange={(event) =>
                handleChangeValue(
                  { columnSubtitle: event?.target?.value },
                  singleColumn?.columnIndex || 0
                )
              }
            />
            {/* <TableTextInput
              placeholder={t("common.answer")}
              value={singleColumn?.defaultAnswer}
              inputProps={{
                style: {
                  flex: 1,
                },
              }}
              onChange={(event) =>
                handleChangeValue(
                  { defaultAnswer: event?.target?.value },
                  singleColumn?.columnIndex || 0
                )
              }
            /> */}
            {!isMobile && <AlignmentPicker
              value={singleColumn?.textAlignment || ALIGNMENT.LEFT}
              onChange={(value) =>
                handleChangeValue(
                  { textAlignment: value },
                  singleColumn?.columnIndex || 0
                )
              }
            />}
            <RemoveColumnIcon onClick={() => handleRemoveColumn(index)} />
          </ColumnsContainer>
        ))}
        <AddContainer onClick={handleAddColumn}>
          <AddColumnIcon />
          <AddColumnText> {t("questions.addColumn")}</AddColumnText>
        </AddContainer>
        <PreviewContainer onClick={handleTablePreview}>
          <PreviewIcon />
          <PreviewText>{t("questions.previewTable")}</PreviewText>
        </PreviewContainer>
      </ChapterContainer>
    </>
  );
});

TableTypeInputs.displayName = "TableTypeInputs";

TableTypeInputs.propTypes = {
  defaultAnswerIndex: PropTypes.number,
  columns: PropTypes.array,
  rows: PropTypes.array
};
TableTypeInputs.defaultProps = {
  columns: [{ columnTitle: "", columnSubtitle: "", columnIndex: 0 }],
  rows: [],//{ rowIndex: 0, columnIndex: 0, answer: "" }
};

export default TableTypeInputs;
