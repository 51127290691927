import { Box } from "@mui/material";
import styled from "styled-components";

export const NewScanningObjectContentContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 12px;
  min-height: calc(100vh - 86px);
  flex: 1;
  position: relative;
`;

export const TitleButtonsContainer = styled(Box)`
  display: flex;
  align-items: start;
  justify-content: space-between;
`;

export const NameInputContainer = styled(Box)`
  width: fit-content !important;
  @media (max-width: 600px) {
    width: 100% !important;
    & div {
      width: 100%;
    }
    & input {
      width: 100%;
    }
  }
`;
