import LastChangesRenderer from "../../../components/Clients/ClientsTable/Renderers/LastChangesRenderer/LastChangesRenderer";
import StatusRenderer from "../../../components/Findings/FindingsTable/Renderers/StatusRenderer/StatusRenderer";
import UserRenderer from "../../../components/Users/UsersTable/ActiveUsersTable/Renderers/UserRenderer/UserRenderer";

export default {
  client: {
    id: 0,
    i18key: "findings.table.client.columnTitle",
    renderer: UserRenderer,
    disableSort: true,
    style: {
      maxWidth: "calc(100% / 3)",
    },
  },
  lastChanges: {
    id: 1,
    i18key: "findings.table.lastChanges.columnTitle",
    backendProperty: "updatedAtUtc",
    renderer: LastChangesRenderer,
    disableSort: true,
    style: {
      textAlign: "center",
      justifyContent: "center",
      maxWidth: "calc(100% / 3)",
    },
  },
  status: {
    id: 2,
    i18key: "findings.table.status.columnTitle",
    renderer: StatusRenderer,
    disableSort: true,
    style: {
      textAlign: "center",
      justifyContent: "center",
      maxWidth: "calc(100% / 3)",
    },
    backendProperty: "findingStateId",
  },
  header: {
    id: 3,
    i18key: "findings.table.header.columnTitle",
    mobile: true,
    disableSort: true,
    style: {},
  }
};
