import styled from "styled-components";
import { Box, Button } from "@mui/material";
import { ReactComponent as Refresh } from "../../../assets/images/svg/refresh.svg";
import selectedTheme from "../../../themes";

export const PrimaryButtonContainer = styled(Box)``;

export const PrimaryButtonContent = styled(Button)`
  background-color: ${(props) =>
    props?.variant === "contained"
      ? selectedTheme.colors.primaryButtonDarkColor
      : selectedTheme.colors.primaryButtonLightColor};
  color: ${(props) =>
    props?.variant === "contained"
      ? "white"
      : selectedTheme.colors.primaryButtonTextColor};
  text-transform: none;
  padding: 8px 16px;
  border-radius: 6px;
  font-family: ${selectedTheme.fonts.mainText};
  font-size: 16px;
  font-weight: 500;
  width: 150px;
  white-space: nowrap;
  &:hover {
    background-color: ${(props) =>
      props?.variant === "contained"
        ? selectedTheme.colors.orangeLightHover
        : selectedTheme.colors.primaryButtonLightColor};
  }
`;

export const RefreshIcon = styled(Refresh)`
  margin-right: 9px;
`;
