import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
// import SidebarNavigation from "../../components/SidebarNavigation/SidebarNavigation";
import { EDIT_USER, NEW_USER, USERS_PAGE } from "../../constants/pages";
// import SidebarLayout from "../../layouts/SidebarLayout/SidebarLayout";
import EditUserPage from "./EditUserPage/EditUserPage";
import NewUserPage from "./NewUserPage/NewUserPage";
import UsersIndexPage from "./UsersIndexPage/UsersIndexPage";

const UsersPage = () => {
  return (
    <Switch>
      <Route exact path={USERS_PAGE} component={UsersIndexPage} />
      <Route exact path={NEW_USER} component={NewUserPage} />
      <Route exact path={EDIT_USER} component={EditUserPage} />
      <Redirect to={USERS_PAGE} />
    </Switch>
  );
};

export default UsersPage;
