import React from "react";
import PropTypes from "prop-types";
import TextInputField from "../../../Field/TextInputField/TextInputField";
import { useTranslation } from "react-i18next";

const DescriptionField = (props) => {
  const { t } = useTranslation();
  const formik = props?.formik;
  return (
    <TextInputField
      name="description"
      requiredField={formik?.values?.description?.length === 0}
      label={t("documentTypes.modal.description")}
      onChange={formik?.handleChange}
      disabledField={props?.disabled}
      onBlur={formik?.handleBlur}
      error={formik?.touched?.description && formik?.errors?.description}
      value={formik?.values?.description}
    />
  );
};

DescriptionField.propTypes = {
  children: PropTypes.node,
  disabled: PropTypes.bool,
  formik: PropTypes.object,
};

export default DescriptionField;
