import {
  createClearType,
  createErrorType,
  createFetchType,
  createSetType,
  createSuccessType,
} from "../actionHelpers";

export const SET_FINDING_TYPE = createSetType("SET_FINDING_TYPE");
export const SET_FINDING_TYPE_ERROR = "SET_FINDING_TYPE_ERROR";

export const GET_FINDING_TYPES_SCOPE = "GET_FINDING_TYPES_SCOPE";
export const GET_FINDING_TYPES_FETCH = createFetchType(GET_FINDING_TYPES_SCOPE);
export const GET_FINDING_TYPES_SUCCESS = createSuccessType(GET_FINDING_TYPES_SCOPE);
export const GET_FINDING_TYPES_ERROR = createErrorType(GET_FINDING_TYPES_SCOPE);
export const GET_FINDING_TYPES_CLEAR = createClearType(GET_FINDING_TYPES_SCOPE);

export const SINGLE_FINDING_TYPE_SCOPE = "SINGLE_FINDING_TYPE_SCOPE";
export const SINGLE_FINDING_TYPE_FETCH = createFetchType(SINGLE_FINDING_TYPE_SCOPE);
export const SINGLE_FINDING_TYPE_SUCCESS = createSuccessType(SINGLE_FINDING_TYPE_SCOPE);
export const SINGLE_FINDING_TYPE_ERROR = createErrorType(SINGLE_FINDING_TYPE_SCOPE);
export const SINGLE_FINDING_TYPE_SET = createSetType(SINGLE_FINDING_TYPE_SCOPE);

const CREATE_FINDING_TYPE_SCOPE = "CREATE_FINDING_TYPE_SCOPE";
export const CREATE_FINDING_TYPE_FETCH = createFetchType(CREATE_FINDING_TYPE_SCOPE);
export const CREATE_FINDING_TYPE_SUCCESS = createSuccessType(CREATE_FINDING_TYPE_SCOPE);
export const CREATE_FINDING_TYPE_ERROR = createErrorType(CREATE_FINDING_TYPE_SCOPE);

const EDIT_FINDING_TYPE_SCOPE = "EDIT_FINDING_TYPE_SCOPE";
export const EDIT_FINDING_TYPE_FETCH = createFetchType(EDIT_FINDING_TYPE_SCOPE);
export const EDIT_FINDING_TYPE_SUCCESS = createSuccessType(EDIT_FINDING_TYPE_SCOPE);
export const EDIT_FINDING_TYPE_ERROR = createErrorType(EDIT_FINDING_TYPE_SCOPE);

const DELETE_FINDING_TYPE_SCOPE = "DELETE_FINDING_TYPE_SCOPE";
export const DELETE_FINDING_TYPE_FETCH = createFetchType(DELETE_FINDING_TYPE_SCOPE);
export const DELETE_FINDING_TYPE_SUCCESS = createSuccessType(DELETE_FINDING_TYPE_SCOPE);
export const DELETE_FINDING_TYPE_ERROR = createErrorType(DELETE_FINDING_TYPE_SCOPE);

export const SET_FINDING_TYPES = createSetType("SET_FINDING_TYPES");
export const SET_TOTAL_FINDING_TYPES = createSetType("SET_TOTAL_FINDING_TYPES");
export const CLEAR_FINDING_TYPES = createClearType("CLEAR_FINDING_TYPES");
