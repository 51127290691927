export const deleteCRUDInfo = (object) => {
  try {
    let newObject = { ...object };
    delete newObject.createdAtUtc;
    delete newObject.createdByUser;
    delete newObject.createdByUserId;
    delete newObject.updatedAtUtc;
    delete newObject.updatedByUser;
    delete newObject.updatedByUserId;
    delete newObject.deletedAtUtc;
    delete newObject.deletedByUser;
    delete newObject.deletedByUserId;
    return newObject;
  } catch (e) {
    console.log(e);
    return object;
  }
};
export const addCRUDInfo = (object, userId) => ({
  ...object,
  createdAtUtc: new Date(
    new Date()?.getTime() - new Date()?.getTimezoneOffset() * 60000
  ).toISOString(),
  updatedAtUtc: new Date(
    new Date()?.getTime() - new Date()?.getTimezoneOffset() * 60000
  ).toISOString(),
  deletedAtUtc: null,
  createdByUserId: userId,
  updatedByUserId: userId,
  deletedByUserId: null,
});

export const addUpdateInfo = (object, userId) => ({
  ...object,
  updatedAtUtc: new Date(
    new Date()?.getTime() - new Date()?.getTimezoneOffset() * 60000
  ).toISOString(),
  updatedByUserId: userId,
});
