import React, { useEffect, useMemo } from "react";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import TextInputField from "../../components/Field/TextInputField/TextInputField";
import {
  fetchEditUser,
  fetchSingleUser,
} from "../../store/actions/user/userActions";
import { selectMyProfileId } from "../../store/selectors/loginSelectors";
import { selectSingleUser } from "../../store/selectors/usersSelectors";
import { newUserInitialValues } from "../../initialValues/newUserInitialValues";
import { makeToastMessage } from "../../store/utils/makeToastMessage";
import { APPLICATION_TOAST_CONTAINER_ID } from "../../constants/toastConstants";
import {
  FormContainer,
  PasswordContainer,
  ProfilePageContainer,
} from "./ProfilePage.styled";
import settingsNewUserValidationSchema from "../../validations/settingsNewUserValidationSchema";
import { selectIsLoadingByActionType } from "../../store/selectors/loadingSelectors";
import { SINGLE_USER_SCOPE } from "../../store/actions/user/userActionConstants";
import CircularLoader from "../../components/Loader/CircularLoader/CircularLoader";
import {
  closeLoadingModal,
  closeModal,
  toggleSetLoadingModal,
  toggleSetPasswordModal,
} from "../../store/actions/modal/modalActions";
import i18n from "../../i18n";
import PageTitle from "../../components/PageTitle/PageTitle";
import ActionButtons from "../../components/PageTitle/ActionButtons/ActionButtons";
import useIsMobile from "../../hooks/useIsMobile";
import {
  PasswordButton,
  PasswordValidationIcon,
  TickIcon,
} from "../../components/Users/NewUser/NewUserForm.styled";
import { ResetPasswordIcon } from "./ProfilePage.styled";
import ErrorIcon from "../../components/Icon/Icons/ErrorIcon";

const ProfilePage = () => {
  const { t } = useTranslation();
  const myProfileId = useSelector(selectMyProfileId);
  const dispatch = useDispatch();
  const { isMobile } = useIsMobile();
  const prefilledData = useSelector(selectSingleUser);
  const isLoading = useSelector(selectIsLoadingByActionType(SINGLE_USER_SCOPE));

  const fetchMyUser = () => {
    dispatch(fetchSingleUser({ userId: myProfileId }));
  };

  useEffect(() => {
    fetchMyUser();
  }, []);

  const handleApiResponseSuccess = () => {
    fetchMyUser();
    formik?.setFieldValue?.("password", "");
    formik?.setFieldValue?.("confirmPassword", "");

    dispatch(closeModal());
    makeToastMessage(
      {
        title: t("users.userChangedSuccess"),
        description: i18n.t("toast.success.editedDescription", {
          name: `${formik?.values?.firstName} ${formik?.values?.lastName}`,
        }),
      },
      {
        containerId: APPLICATION_TOAST_CONTAINER_ID,
      }
    );
  };

  const handleApiResponseError = () => {
    dispatch(closeLoadingModal());
  };

  const handleSubmit = (values) => {
    dispatch(toggleSetLoadingModal({ rank: 1 }));
    const userData = {
      firstname: values?.firstName,
      lastname: values?.lastName,
      email: values?.email,
      password: "",
      createdDate: values?.createdDate,
      roles: prefilledData.userRole,
      newPassword: values?.password,
    };

    dispatch(
      fetchEditUser({
        userData,
        userId: myProfileId,
        handleApiResponseSuccess,
        handleApiResponseError,
      })
    );
  };

  const initialValues = useMemo(
    () => newUserInitialValues(prefilledData),
    [prefilledData]
  );

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: settingsNewUserValidationSchema(),
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });
  const isSavingEnabled = useMemo(() => {
    if (
      formik?.values?.firstName?.length === 0 ||
      formik?.values?.lastName?.length === 0 ||
      formik?.values?.email?.length === 0
    ) {
      return false;
    }
    return formik?.dirty;
  }, [formik?.values]);

  const handleChangePassword = () => {
    dispatch(
      toggleSetPasswordModal({
        title: t("users.tooltip.changePassword"),
        onSubmit: (values) => {
          formik?.setFieldValue?.("password", values?.password);
          formik?.setFieldValue?.("confirmPassword", values?.password);
        },
        rank: 1,
      })
    );
  };

  return (
    <ProfilePageContainer>
      <PageTitle
        title={t("pages.profile")}
        disableSaveButton={!isSavingEnabled}
        onSubmitClick={formik?.handleSubmit}
      />
      {isLoading ? (
        <CircularLoader />
      ) : (
        <>
          <FormContainer>
            <TextInputField
              name="firstName"
              label={t("users.firstName")}
              value={formik?.values?.firstName}
              onChange={formik?.handleChange}
              error={formik?.touched?.firstName && formik?.errors?.firstName}
              helperText={
                formik?.touched?.firstName && formik?.errors?.firstName
              }
              requiredField={
                settingsNewUserValidationSchema?.()?.fields?.firstName
                  ?.exclusiveTests?.required
              }
            />
            <TextInputField
              name="lastName"
              label={t("users.lastName")}
              value={formik?.values?.lastName}
              onChange={formik?.handleChange}
              error={formik?.touched?.lastName && formik?.errors?.lastName}
              helperText={formik?.touched?.lastName && formik?.errors?.lastName}
              requiredField={
                settingsNewUserValidationSchema?.()?.fields?.lastName
                  ?.exclusiveTests?.required
              }
            />
            <TextInputField
              name="email"
              label={t("users.email")}
              value={formik?.values?.email}
              onChange={formik?.handleChange}
              error={formik?.touched?.email && formik?.errors?.email}
              helperText={formik?.touched?.email && formik?.errors?.email}
              requiredField={
                settingsNewUserValidationSchema?.()?.fields?.email
                  ?.exclusiveTests?.required
              }
            />
            <PasswordContainer>
              <PasswordValidationIcon>
                {formik?.errors?.password && formik?.touched?.password && (
                  <ErrorIcon />
                )}
                {formik?.values?.password && <TickIcon />}
              </PasswordValidationIcon>
              <PasswordButton
                variant="contained"
                onClick={handleChangePassword}
                value={formik?.values?.password}
              >
                {t("users.setPassword")}
                <ResetPasswordIcon />
              </PasswordButton>
            </PasswordContainer>
          </FormContainer>
          {isMobile && (
            <ActionButtons
              disabledSaveButton={!isSavingEnabled}
              handleClickSubmitButton={formik?.handleSubmit}
            />
          )}
        </>
      )}
    </ProfilePageContainer>
  );
};

export default ProfilePage;
