import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import PropTypes from "prop-types";
import ManualTable from "../../../../ManualTable/ManualTable";
import { useTranslation } from "react-i18next";
import { fetchStandards } from "../../../../../store/actions/standards/standardsActions";
import { selectStandardsData } from "../../../../../store/selectors/standardsSelectors";
import findingStandardsTableConstants from "../../../../../constants/tableConstants/findingTables/findingStandardsTableConstants";
import TextRenderer from "../../../../Table/Renderers/TextRenderer/TextRenderer";
import { FindingStandardsContainer } from "./FindingStandards.styled";
import { useSelector } from "react-redux";

const FindingStandards = forwardRef((props, ref) => {
  const { t } = useTranslation();
  const [selectedStandards, setSelectedStandards] = useState([]);
  const standards = useSelector(selectStandardsData);

  useEffect(() => {
    if (standards?.length > 0 && !props?.isEditing) {
      setSelectedStandards([
        ...standards?.filter((singleStandard) => singleStandard?.isDefault),
      ]);
    }
  }, [standards]);

  useImperativeHandle(ref, () => ({
    data: selectedStandards,
    setData: setSelectedStandards,
  }));

  const tableColumns = useMemo(() => {
    return Object.keys(findingStandardsTableConstants).map((property) => {
      if (property === "delete" && props?.disabled) return;
      return {
        ...findingStandardsTableConstants[property],
        field: t(findingStandardsTableConstants[property].i18key),
        propertyName: property,
        backendProperty:
          findingStandardsTableConstants[property]?.backendProperty || property,
        style: findingStandardsTableConstants[property]?.style,
        renderer:
          findingStandardsTableConstants[property]?.renderer || TextRenderer,
      };
    });
  }, [findingStandardsTableConstants, t]);

  const mapDataFunction = useCallback(
    (newData) => {
      return newData.map((singleData) => {
        let mappedObject = {
          caption: singleData.caption,
          delete: {
            id: singleData?.id,
            caption: singleData.caption,
          },
        };
        if (props?.disabled) delete mappedObject?.delete;
        return mappedObject;
      });
    },
    [props?.disabled]
  );

  return (
    <FindingStandardsContainer>
      <ManualTable
        disabled={props?.disabled}
        fetchedData
        key={selectedStandards}
        selectedData={selectedStandards}
        setSelectedData={setSelectedStandards}
        title={t("standards.title")}
        addEntityLabel={t("standards.addStandard")}
        selectPlaceholder={t("standards.chooseStandard")}
        fetchEntitiesDispatchFunction={fetchStandards}
        entityDataSelector={selectStandardsData}
        mapDataFunction={mapDataFunction}
        tableColumns={tableColumns}
        namePropertyOfEntity={tableColumns[0]?.propertyName}
        noActions={false}
      />
    </FindingStandardsContainer>
  );
});

FindingStandards.displayName = "FindingStandards";

FindingStandards.propTypes = {
  children: PropTypes.node,
  disabled: PropTypes.bool,
  isEditing: PropTypes.bool,
};

export default FindingStandards;
