import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  AddButton,
  InstrumentInputsContainer,
} from "./TemplateMeasuringInstrumentsPicker.styled";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { selectMeasuringInstrumentsData } from "../../../../../store/selectors/measuringInstrumentsSelectors";
import { fetchMeasuringInstruments } from "../../../../../store/actions/measuringInstruments/measuringInstrumentsActions";

import PlusCircledIcon from "../../../../Icon/Icons/PlusCircledIcon";
import Tooltip from "../../../../Tooltip/Tooltip";
import { ASC_KEY, NAME_KEY } from "../../../../../constants/queryConstants";
import AutocompleteField from "../../../../Field/AutocompleteField/AutocompleteField";
import { selectScanningObjectType } from "../../../../../store/selectors/scanningObjectsSelectors";

const TemplateMeasuringInstrumentsPicker = (props) => {
  const { t } = useTranslation();
  const [selectedInstrument, setSelectedInstrument] = useState(null);
  const measuringInstruments = useSelector(selectMeasuringInstrumentsData);
  const findingType = useSelector(selectScanningObjectType);
  const dispatch = useDispatch();

  const handleAddInstrument = () => {
    if (selectedInstrument) {
      props?.handleAddInstrument(selectedInstrument);
    }
    setSelectedInstrument(null);
  };

  useEffect(() => {
    dispatch(
      fetchMeasuringInstruments({
        page: 1,
        size: 200,
        sorting: {
          column: NAME_KEY,
          sortDirection: ASC_KEY,
        },
        findingTypeId: findingType?.id,
      })
    );
    setSelectedInstrument(null);
  }, [findingType]);

  return (
    <InstrumentInputsContainer>
      <AutocompleteField
        options={measuringInstruments?.filter?.(
          (singleInstrument) =>
            props?.data?.find(
              (singleInstrumentToFind) =>
                singleInstrumentToFind?.id === singleInstrument?.id
            ) === undefined
        )}
        label={t(
          "scanningObjects.newScanningObject.templateEditor.pickInstrument"
        )}
        onChange={(value) => setSelectedInstrument(value)}
        dropdownLabel={["name"]}
        value={selectedInstrument}
        removeMaxWidth
      />
      <Tooltip title={t("scanningObjects.tooltip.addInstrument")}>
        <AddButton onClick={handleAddInstrument}>
          <PlusCircledIcon />
        </AddButton>
      </Tooltip>
    </InstrumentInputsContainer>
  );
};

TemplateMeasuringInstrumentsPicker.propTypes = {
  handleAddInstrument: PropTypes.func,
  data: PropTypes.array,
};

TemplateMeasuringInstrumentsPicker.defaultProps = {
  data: [],
};

export default TemplateMeasuringInstrumentsPicker;
