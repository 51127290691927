import React from "react";
import PropTypes from "prop-types";
import {
  AddButtonIcon,
  TemplateSectionsAddButtonAltText,
  TemplateSectionsAddButtonBackground,
  TemplateSectionsAddButtonContainer,
  TemplateSectionsAddButtonSeparator,
} from "./TemplateSectionsAddButton.styled";
import { useTranslation } from "react-i18next";
import Tooltip from "../../../../../Tooltip/Tooltip";
import PlusCircledIcon from "../../../../../Icon/Icons/PlusCircledIcon";

const TemplateSectionsAddButton = (props) => {
  const { t } = useTranslation();
  return (
    <TemplateSectionsAddButtonContainer>
      {!props?.showAltText && <TemplateSectionsAddButtonSeparator />}
      {props?.firstSection ? (
        <TemplateSectionsAddButtonBackground onClick={props?.onClick}>
          +
        </TemplateSectionsAddButtonBackground>
      ) : (
        <Tooltip title={t("scanningObjects.tooltip.addSection")}>
          <AddButtonIcon onClick={props?.onClick}>
            <PlusCircledIcon />
          </AddButtonIcon>
        </Tooltip>
      )}
      {props?.showAltText && (
        <TemplateSectionsAddButtonAltText>
          {t("scanningObjects.newScanningObject.templateEditor.addSections")}
        </TemplateSectionsAddButtonAltText>
      )}
    </TemplateSectionsAddButtonContainer>
  );
};

TemplateSectionsAddButton.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
  showAltText: PropTypes.bool,
  firstSection: PropTypes.bool,
};

export default TemplateSectionsAddButton;
