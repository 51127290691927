import React, { useState } from "react";
import PropTypes from "prop-types";
import { TemplateSectionsListContainer } from "./TemplateSectionsList.styled";
import TemplateSectionsListItem from "./ListItem/ListItem";
import TemplateSectionsAddButton from "./TemplateSectionsAddButton/TemplateSectionsAddButton";
import { useDispatch, useSelector } from "react-redux";
import {
  addScanningObjectSingleSection,
  setScanningObjectsChanged,
} from "../../../../../store/actions/scanningObjects/scanningObjectsActions";
import { selectSingleScanningObjectAllSections } from "../../../../../store/selectors/scanningObjectsSelectors";

const TemplateSectionsList = (props) => {
  const [isAddingNewSection, setIsAddingNewSection] = useState(false);
  const dispatch = useDispatch();
  const sections = useSelector(selectSingleScanningObjectAllSections);
  const handleChooseSection = (sectionId) => {
    props?.setChosenSectionId(sectionId);
  };

  const handleStartAddingNewSection = () => {
    setIsAddingNewSection(true);
  };
  const handleAddSection = (sectionName) => {
    setIsAddingNewSection(false);
    dispatch(
      addScanningObjectSingleSection({
        section: { id: Math.random(), title: sectionName, questions: [] },
      })
    );
    dispatch(setScanningObjectsChanged(true));
  };
  const handleCancelAddingSection = () => {
    setIsAddingNewSection(false);
    dispatch(setScanningObjectsChanged(true));
  };

  return (
    <TemplateSectionsListContainer>
      {sections?.map((singleSection, index) => (
        <TemplateSectionsListItem
          section={singleSection}
          ordinalNumber={index + 1}
          key={singleSection?.id}
          chosen={singleSection?.id === props?.chosenSectionId}
          handleChooseSection={() => handleChooseSection(singleSection?.id)}
          isReadOnly={props?.isReadOnly}
        />
      ))}
      {isAddingNewSection ? (
        <TemplateSectionsListItem
          isEditing
          handleAddSection={handleAddSection}
          handleCancelAddingSection={handleCancelAddingSection}
        />
      ) : !props?.isReadOnly ? (
        <TemplateSectionsAddButton
          onClick={handleStartAddingNewSection}
          showAltText={sections?.length === 0}
          firstSection={sections?.length === 0}
        />
      ) : (
        <></>
      )}
    </TemplateSectionsListContainer>
  );
};

TemplateSectionsList.propTypes = {
  children: PropTypes.node,
  sections: PropTypes.array,
  chosenSectionId: PropTypes.number,
  setChosenSectionId: PropTypes.func,
  handleMoveSection: PropTypes.func,
  handleAddSection: PropTypes.func,
  handleDeleteSection: PropTypes.func,
  handleEditSection: PropTypes.func,
  isReadOnly: PropTypes.bool,
};

export default TemplateSectionsList;
