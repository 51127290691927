import React from "react";
import PropTypes from "prop-types";
import { Content, LeftImage, LoginLayoutContainer } from "./LoginLayout.styled";

const LoginLayout = (props) => {
  return (
    <LoginLayoutContainer container>
      <LeftImage
        item
        lg={7}
        md={6}
        xs={0}
        sx={{
          display: { xs: "none", md: "block" },
        }}
      >
        {props?.leftImage}
      </LeftImage>
      <Content
        item
        lg={5}
        md={6}
        xs={12}
        sx={{
          alignItems: { xs: "center", md: "flex-start" },
        }}
      >
        {props?.content}
      </Content>
    </LoginLayoutContainer>
  );
};

LoginLayout.propTypes = {
  leftImage: PropTypes.node,
  content: PropTypes.node,
};

export default LoginLayout;
