import React from "react";
import PropTypes from "prop-types";
import {
  BottomCheckboxContainer,
  CheckboxContainer,
  FieldLabel,
  LabelContainer,
  LeftSide,
  PickerFilterComponentContainer,
  RightSide,
  Separator,
  TopCheckboxContainer,
} from "./PickerFilterComponent.styled";
import Checkbox from "../../../../Users/NewUser/Checkbox/Checkbox";
import { useTranslation } from "react-i18next";

const PickerFilterComponent = (props) => {
  const { t } = useTranslation();
  return (
    <PickerFilterComponentContainer>
      <LabelContainer>
        <FieldLabel>{props?.label}</FieldLabel>
      </LabelContainer>
      <CheckboxContainer>
        <TopCheckboxContainer>
          <Checkbox
            label={t("findings.filter.stateArray.all")}
            checked={props?.isSelectAll}
            onChange={props?.selectAll}
          />
        </TopCheckboxContainer>
        <Separator />
        <BottomCheckboxContainer>
          <LeftSide>
            {props?.data
              ?.slice?.(0, Math.ceil(props?.data?.length / 2))
              ?.map?.((entity) => {
                return (
                  <Checkbox
                    key={entity?.id}
                    name={entity?.id}
                    checked={
                      !props?.isSelectAll &&
                      props?.selected?.find?.((value) => entity?.id === value) !=
                        null
                    }
                    label={t(entity?.i18key)}
                    onChange={() => props?.setSelected?.(entity?.id)}
                  />
                );
              })}
          </LeftSide>
          <RightSide>
            {props?.data
              ?.slice?.(Math.ceil(props?.data?.length / 2))
              ?.map?.((entity) => {
                return (
                  <Checkbox
                    key={entity?.id}
                    name={entity?.id}
                    checked={
                      !props?.isSelectAll &&
                      props?.selected?.find?.((value) => entity?.id === value) !=
                        null
                    }
                    label={t(entity?.i18key)}
                    onChange={() => props?.setSelected?.(entity?.id)}
                  />
                );
              })}
          </RightSide>
        </BottomCheckboxContainer>
      </CheckboxContainer>
    </PickerFilterComponentContainer>
  );
};

PickerFilterComponent.propTypes = {
  selected: PropTypes.any,
  data: PropTypes.any,
  icon: PropTypes.any,
  label: PropTypes.string,
  setSelected: PropTypes.func,
  selectAll: PropTypes.func,
  isSelectAll: PropTypes.bool,
};

export default PickerFilterComponent;
