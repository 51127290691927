import { Box } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../themes";

export const LastChangesContainer = styled(Box)`
  border-left: 1px solid ${selectedTheme.colors.borderColor};
  padding-left: 43px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  height: fit-content;
  min-width: 220px;
  position: absolute;
  right: 0;
  top: 200px;
  @media (max-width: 600px) {
    position: static;
  }
`;
export const LastChangesItem = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
export const LastChangesItemTitle = styled(Box)`
  font-family: ${selectedTheme.fonts.mainText};
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: ${selectedTheme.colors.tableColumnText};
`;
