import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
  ErrorMessage,
  SingleInputContainer,
  SingleInputTitle,
} from "../SetMeasuringInstrumentModal.styled";
import { newMeasuringInstrumentFormikConstants } from "../../../../initialValues/newMeasuringInstrumentInitialValues";
import { InputField } from "../../../Login/LoginForm/LoginForm.styled";

const ManufacturerField = (props) => {
  const formik = props?.formik;
  const { t } = useTranslation();
  return (
    <SingleInputContainer>
      <SingleInputTitle
      // $required={
      //   !formik?.values?.[newMeasuringInstrumentFormikConstants.manufacturer]
      // }
      >
        {t("measuringInstruments.modal.fields.manufacturer.label")}
      </SingleInputTitle>
      <InputField
        style={{
          width: "100%",
        }}
        id={newMeasuringInstrumentFormikConstants.manufacturer}
        value={
          formik?.values?.[newMeasuringInstrumentFormikConstants.manufacturer]
        }
        onChange={formik?.handleChange}
        placeholder={t(
          "measuringInstruments.modal.fields.manufacturer.placeholder"
        )}
        disabled={props?.isReadOnly}
      />
      {formik?.errors?.[newMeasuringInstrumentFormikConstants.manufacturer] &&
        formik?.touched?.[
          newMeasuringInstrumentFormikConstants.manufacturer
        ] && (
          <ErrorMessage>
            {t(
              formik?.errors?.[
                newMeasuringInstrumentFormikConstants.manufacturer
              ]
            )}
          </ErrorMessage>
        )}
    </SingleInputContainer>
  );
};

ManufacturerField.propTypes = {
  formik: PropTypes.object,
  isReadOnly: PropTypes.bool,
};

export default ManufacturerField;
