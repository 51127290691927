import styled from "styled-components";
import { Box, IconButton, Menu, MenuItem, Typography } from "@mui/material";
import selectedTheme from "../../../../../themes";
import CopyIcon from "../../../../Icon/Icons/CopyIcon";

export const ActionLabel = styled(Typography)`
  font-family: ${selectedTheme.fonts.mainText};
  font-size: 16px;
  font-weight: 600;
  margin-left: 17px;
`;

export const MenuButton = styled(IconButton)`
  border-radius: 8px; 
`;

export const MenuContainer = styled(Box)`
  display: column;
`;

export const MenuPopover = styled(Menu)``;

export const ItemMenu = styled(MenuItem)`
  padding: 6px 24px 6px 24px;
`;
export const CopySubjectIcon = styled(CopyIcon)`
  width: 18px;
  height: 18px;
` 