export const newMeasuringInstrumentFormikConstants = {
  name: "name",
  factoryNumber: "factoryNumber",
  manufacturer: "manufacturer",
  benchmarking: "benchmarking",
  date: "date",
  findingTypes: "findingTypes",
  calibrationCertificateUrl: "calibrationCertificateUrl",
};

export default (values) => ({
  [newMeasuringInstrumentFormikConstants.name]: values?.name || "",
  [newMeasuringInstrumentFormikConstants.factoryNumber]:
    values?.factoryNumber || "",
  [newMeasuringInstrumentFormikConstants.manufacturer]:
    values?.manufacturer || "",
  [newMeasuringInstrumentFormikConstants.benchmarking]:
    values?.benchmarking?.name || "",
  [newMeasuringInstrumentFormikConstants.date]:
    values?.benchmarking?.date || "",
  [newMeasuringInstrumentFormikConstants.findingTypes]:
    values?.findingTypes || [],
  [newMeasuringInstrumentFormikConstants.calibrationCertificateUrl]:
    values?.calibrationCertificateUrl ||
    values?.actions?.calibrationCertificateUrl ||
    "",
});
