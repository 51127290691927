import React, {useMemo} from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { selectFindingsData } from "../../../../store/selectors/findingsSelectors";
import { documentFormikConstants } from "../../../../initialValues/documentInitialValues";
import AutocompleteField from "../../../Field/AutocompleteField/AutocompleteField";

const DocumentFindingField = (props) =>
{
    const formik = props?.formik;
    const { t } = useTranslation();
    const findings = useSelector(selectFindingsData);
    
    // const dispatch = useDispatch();
    // useEffect(() => {
    //   dispatch(fetchFindings());
    // }, []);
  
    
const value = useMemo(() => {
    let findingToReturn = findings.find((finding) => 
    finding?.id == formik?.values?.[documentFormikConstants.findingId]);
   return findingToReturn;  
}, [formik?.values?.[documentFormikConstants.findingId], findings]);

const handleChange = (value) =>{
    formik?.setFieldValue?.(documentFormikConstants.findingId, value?.id);
};
const filteredFindings = useMemo(() => {
    return findings.filter(
      (finding) =>
        finding?.clientId === formik?.values?.[documentFormikConstants.clientId]
    );
  }, [formik?.values?.[documentFormikConstants.clientId], findings]);

return (
    <AutocompleteField
        options = {filteredFindings}
        label = {t("documents.newPage.form.finding.placeholder")}
        disabled = {props?.disabled}
        title = {t("documents.newPage.form.finding.title")}
        removeMaxWidth
        onChange={handleChange}
        dropdownLabel = {["findingNumber"]}
        value = {value}
        erorr = {
            formik?.touched?.[documentFormikConstants.findingId] && 
            formik?.errors?.[documentFormikConstants.findingId] &&
            t(formik?.errors?.[documentFormikConstants.findingId])
        }
    />
    );

}
DocumentFindingField.propTypes = {
    formik: PropTypes.object,
    disabled: PropTypes.bool,
    findingId: PropTypes.number,
    caption: PropTypes.symbol,
    stateId: PropTypes.number,
}

export default DocumentFindingField;