import { createSelector } from "reselect";

const translationsSelector = (state) => state.translations;

export const selectCurrentLanguage = createSelector(
  translationsSelector,
  (state) => state.currentLanguage
);

export const selectTranslatedResources = createSelector(
  translationsSelector,
  (state) => state.languageResources
);
