export const loginFormikConstants = {
    username: "userName",
    password: "password",
    rememberMe: "rememberMe"
}

export default {
    [loginFormikConstants.username]: "",
    [loginFormikConstants.password]: "",
    [loginFormikConstants.rememberMe]: false,
}