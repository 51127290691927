import React from "react";
import PropTypes from "prop-types";
import { SelectOptionCardContainer } from "./SelectOptionCard.styled";

const SelectOptionCard = (props) => {
  return (
    <SelectOptionCardContainer isSelected={props?.isSelected} {...props}>
      {props?.icon}
    </SelectOptionCardContainer>
  );
};

SelectOptionCard.propTypes = {
  icon: PropTypes.node,
  isSelected: PropTypes.bool,
};

export default SelectOptionCard;
