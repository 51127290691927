import * as Yup from "yup";

export default () =>
  Yup.object().shape({
    firstName: Yup.string().required("registerUser.firstNameRequired"),
    lastName: Yup.string().required("registerUser.lastNameRequired"),
    createdDate: Yup.string(),
    email: Yup.string()
      .email("clients.emailFormat")
      .required("registerUser.emailRequired"),
  });
