import React from "react";
import PropTypes from "prop-types";
import { TypeContainer } from "./TypeRenderer.styled";

const TypeRenderer = (props) => {
  return <TypeContainer>{props?.value?.name}</TypeContainer>;
};

TypeRenderer.propTypes = {
  value: PropTypes.object,
};

export default TypeRenderer;
