export const licensesConstants = {
  ACTIVE: {
    id: 1,
    i18key: "licenses.status.active.title",
  },
  INACTIVE: {
    id: 2,
    i18key: "licenses.status.inactive.title",
  },
};
export const licensesConstantsAsArray = Object.keys(licensesConstants).map(
  (singlePropertyName) => licensesConstants?.[singlePropertyName]
);
