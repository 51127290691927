import React from "react";
import PropTypes from "prop-types";
import {
  ActionRendererContainer,
  ActionRendererDeleteDocumentIconContainer,
  ActionRendererEditDocumentIconContainer,
} from "./ActionRenderer.styled";
import EditIcon from "../../../../Icon/Icons/EditIcon";
import DeleteIcon from "../../../../Icon/Icons/DeleteIcon.js";
import {
  closeLoadingModal,
  toggleSetDeleteModal,
  toggleSetLoadingModal,
} from "../../../../../store/actions/modal/modalActions";
import { fetchDeleteDocument } from "../../../../../store/actions/documents/documentsActions";
import i18n from "../../../../../i18n";
import Tooltip from "../../../../Tooltip/Tooltip";
import { DOCUMENTS_EDIT_PAGE } from "../../../../../constants/pages";
import { replaceInRoute } from "../../../../../util/helpers/routeHelpers";

const ActionRenderer = (props) => {
  const handleApiResponseError = () => {
    props?.dispatch(closeLoadingModal());
  };

  const handleDeleteDocument = () => {
    props?.dispatch(toggleSetLoadingModal({ rank: 2 }));
    props?.dispatch(
      fetchDeleteDocument({
        id: props?.value?.id,
        handleApiResponseSuccess: (data) =>
          props?.value?.handleApiResponseSuccess({
            ...data,
            data: { ...data?.data, name: props?.value?.documentNumber },
          }),
        handleApiResponseError,
      })
    );
  };

  const handleDelete = () => {
    props?.dispatch(
      toggleSetDeleteModal({
        title: i18n.t("documents.modal.delete.title"),
        description: {
          first: i18n.t("documents.modal.delete.description.1"),
          second: i18n.t("documents.modal.delete.description.2"),
        },
        onSubmit: handleDeleteDocument,
        rank: 1,
      })
    );
  };

  return (
    <ActionRendererContainer>
      <Tooltip title={i18n.t("documents.tooltip.changeDocument")}>
        <ActionRendererEditDocumentIconContainer
          to={replaceInRoute(DOCUMENTS_EDIT_PAGE, {
            documentId: props?.value?.id,
          })}
        >
          <EditIcon />
        </ActionRendererEditDocumentIconContainer>
      </Tooltip>
      <Tooltip title={i18n.t("documents.tooltip.deleteDocument")}>
        <ActionRendererDeleteDocumentIconContainer onClick={handleDelete}>
          <DeleteIcon />
        </ActionRendererDeleteDocumentIconContainer>
      </Tooltip>
    </ActionRendererContainer>
  );
};

ActionRenderer.propTypes = {
  children: PropTypes.node,
  dispatch: PropTypes.func,
  value: PropTypes.object,
  rendererProps: PropTypes.any,
  refetchTable: PropTypes.func,
  handleApiResponseSuccess: PropTypes.func,
};

export default ActionRenderer;
