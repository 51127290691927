import React from "react";
import PropTypes from "prop-types";
import {
  SortingButton,
  TableSingleColumnContainer,
} from "./TableSingleColumn.styled";
import { ASC_KEY, DESC_KEY } from "../../../../../constants/queryConstants";

const TableSingleColumn = (props) => {
  const handleChangeSorting = () => {
    if (props?.column?.disableSort) return;
    let sortDirection = ASC_KEY;
    if (props?.sorting === ASC_KEY) sortDirection = DESC_KEY;
    props?.$onChangeSorting({
      column: props?.column?.backendProperty,
      sortDirection,
    });
  };

  return (
    <TableSingleColumnContainer {...props} onClick={handleChangeSorting}>
      {props?.column?.field}
      {props?.column?.field?.length > 0 && !props?.column?.disableSort && (
        <SortingButton sortDirection={props?.sorting} />
      )}
    </TableSingleColumnContainer>
  );
};

TableSingleColumn.propTypes = {
  column: PropTypes.object,
  $onChangeSorting: PropTypes.func,
  sorting: PropTypes.string,
};

export default TableSingleColumn;
