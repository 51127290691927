import React from "react";
import PropTypes from "prop-types";
import {
  ActionRendererContainer,
  ActionRendererDeleteProfileIconContainer,
  ActionRendererResendInviteIconContainer,
} from "./ActionRenderer.styled";
import {
  closeLoadingModal,
  closeModal,
  toggleSetLoadingModal,
  toggleSetUserModal,
} from "../../../../../store/actions/modal/modalActions";
import i18n from "../../../../../i18n";
import {
  fetchReinviteUser,
  fetchDeleteInviteUser,
} from "../../../../../store/actions/user/userActions";
import Tooltip from "../../../../Tooltip/Tooltip";
import { makeToastMessage } from "../../../../../store/utils/makeToastMessage";
import { APPLICATION_TOAST_CONTAINER_ID } from "../../../../../constants/toastConstants";
import DeleteIcon from "../../../../Icon/Icons/DeleteIcon";
import InviteIcon from "../../../../Icon/Icons/InviteIcon";

const ActionRenderer = (props) => {
  const showSuccessToastMessage = (title, description) => {
    makeToastMessage(
      {
        title: props?.value.t(title, {
          typeOfData: props?.value.t("users.typeOfData"),
        }),
        description: props?.value.t(description, {
          name: props?.value.firstName + " " + props?.value.lastName,
          typeOfData: props?.value.t("users.typeOfDataPlural").toLowerCase(),
        }),
      },
      {
        containerId: APPLICATION_TOAST_CONTAINER_ID,
      }
    );
  };

  const handleApiResponseSuccess = (title, description) => {
    props?.dispatch(closeLoadingModal());
    showSuccessToastMessage(title, description);
    props?.value?.tableRef?.current?.forceRefetch?.();
  };

  const handleApiResponseSuccessResend = () => {
    handleApiResponseSuccess(
      "toast.success.resendTitle",
      "toast.success.resendDescription"
    );
  };
  const handleApiResponseSuccessDelete = () => {
    handleApiResponseSuccess(
      "toast.success.deletedTitle",
      "toast.success.deletedDescription"
    );
  };

  const handleDeleteInvitedUser = () => {
    props?.dispatch(closeModal());
    props?.dispatch(toggleSetLoadingModal({ rank: 1 }));
    props?.dispatch(
      fetchDeleteInviteUser({
        userId: props?.value?.userId,
        handleApiResponseSuccess: handleApiResponseSuccessDelete,
      })
    );
  };

  const handleDelete = () => {
    props?.dispatch(
      toggleSetUserModal({
        title: i18n.t("users.modal.delete.title"),
        description: i18n.t("users.modal.delete.description"),
        buttonTitle: i18n.t("common.delete"),
        onSubmit: handleDeleteInvitedUser,
        rank: 1,
      })
    );
  };

  const handleResend = () => {
    props?.dispatch(
      fetchReinviteUser({
        userId: props?.value?.userId,
        handleApiResponseSuccess: handleApiResponseSuccessResend,
      })
    );
  };

  return (
    <ActionRendererContainer $mobile={props?.isMobile}>
      <Tooltip title={i18n.t("users.reinviteUser")}>
        <ActionRendererResendInviteIconContainer onClick={handleResend}>
          <InviteIcon />
        </ActionRendererResendInviteIconContainer>
      </Tooltip>
      <Tooltip title={i18n.t("users.deleteUser")}>
        <ActionRendererDeleteProfileIconContainer onClick={handleDelete}>
          <DeleteIcon />
        </ActionRendererDeleteProfileIconContainer>
      </Tooltip>
    </ActionRendererContainer>
  );
};

ActionRenderer.propTypes = {
  children: PropTypes.node,
  dispatch: PropTypes.func,
  value: PropTypes.object,
  rendererProps: PropTypes.any,
  isMobile: PropTypes.bool,
};

export default ActionRenderer;
