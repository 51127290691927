import ActionRenderer from "../../../components/Findings/NewFinding/FindingSettings/FindingStaffs/ActionRenderer/ActionRenderer";
import UserRenderer from "../../../components/Users/UsersTable/ActiveUsersTable/Renderers/UserRenderer/UserRenderer";

export default {
  stuffs: {
    id: 0,
    i18key: "stuffs.table.stuffs.columnTitle",
    renderer: UserRenderer,
    backendProperty: "stuffs",
    property: "stuffs",
    disableSort: true,
    style: {
      padding: "6px",
    },
    mobileStyle: {
      padding: 0,
      paddingLeft: "6px",
      paddingRight: "80px",
      flex: 1,
    },
  },
  staffRole: {
    id: 3,
    i18key: "stuffs.table.attribute.columnTitle",
    renderer: UserRenderer,
    backendProperty: "staffRole",
    property: "staffRole",
    disableSort: true,
    style: {
      padding: "6px",
    },
    mobileStyle: {
      padding: 0,
      paddingLeft: "6px",
      paddingRight: "80px",
      flex: 1,
      marginBottom: "-4px"
    },
  },
  header: {
    id: 2,
    i18key: "standards.table.caption.columnTitle",
    renderer: UserRenderer,
    disableSort: true,
    mobile: true,
  },
  actions: {
    id: 1,
    i18key: "",
    disableSort: true,
    renderer: ActionRenderer,
    mobileStyle: {
      padding: 0,
      paddingLeft: "6px",
      marginTop: "-4px"
    },
    style: {
      padding: "6px 24px 6px 6px",
      justifyContent: "center",
      textAlign: "center",
      width: "80px",
      minWidth: "0",
    },
  },
};
