import LastChangesRenderer from "../../components/Clients/ClientsTable/Renderers/LastChangesRenderer/LastChangesRenderer";
import ActionRenderer from "../../components/MeasuringInstruments/MeasuringInstrumentsTable/Renderers/ActionRenderer/ActionRenderer";
import BenchmarkingRenderer from "../../components/MeasuringInstruments/MeasuringInstrumentsTable/Renderers/BenchmarkingRenderer/BenchmarkingRenderer";
import RedirectModalRenderer from "../../components/MeasuringInstruments/MeasuringInstrumentsTable/Renderers/RedirectModalRenderer/RedirectModalRenderer";
import FindingTypesRenderer from "../../components/Standards/StandardsTable/Renderers/FindingTypesRenderer/FindingTypesRenderer";

export default {
  name: {
    id: 0,
    i18key: "measuringInstruments.table.name.columnTitle",
    renderer: RedirectModalRenderer,
    redirect: true,
    style: {
      minWidth: "300px",
    },
    styleMobile: {
      minWidth: "0px !important",
    },
    setMaxHeight: true,
  },
  factoryNumber: {
    id: 1,
    i18key: "measuringInstruments.table.factoryNumber.columnTitle",
    style: {
      paddingRight: "24px",
      width: "%100",
      minWidth: "300px",
    },
    styleMobile: {
      minWidth: "0px !important",
    },
    backendProperty: "serialNumber",
    maxWidth: true,
    setMaxHeight: true,
  },
  manufacturer: {
    id: 2,
    i18key: "measuringInstruments.table.manufacturer.columnTitle",
    style: {
      paddingRight: "24px",
      width: "100%",
      minWidth: "300px",
    },
    styleMobile: {
      minWidth: "0px !important",
    },
    maxWidth: true,
    setMaxHeight: true,
  },
  benchmarking: {
    id: 3,
    i18key: "measuringInstruments.table.benchmarking.columnTitle",
    renderer: BenchmarkingRenderer,
    style: {
      width: "100%",
      paddingRight: "24px",
      minWidth: "300px",
    },
    styleMobile: {
      minWidth: "0px !important",
    },
    backendProperty: "validUntil",
    setMaxHeight: true,
  },
  findingTypes: {
    id: 4,
    i18key: "measuringInstruments.table.findingTypes.columnTitle",
    renderer: FindingTypesRenderer,
    backendProperty: "findingTypes",
    style: {
      paddingRight: "24px",
      width: "100%",
      minWidth: "300px",
    },
    styleMobile: {
      minWidth: "0px !important",
    },
    disableSort: true,
    setMaxHeight: true,
  },
  lastChanges: {
    id: 5,
    i18key: "measuringInstruments.table.lastChanges.columnTitle",
    renderer: LastChangesRenderer,
    style: {
      width: "100%",
      textAlign: "center",
      justifyContent: "center",
      minWidth: "300px",
    },
    styleMobile: {
      minWidth: "0px !important",
    },
    backendProperty: "updatedAtUtc",
    setMaxHeight: true,
  },
  actions: {
    id: 5,
    i18key: "",
    renderer: ActionRenderer,
    disableSort: true,
    style: {
      paddingRight: "24px",
      justifyContent: "center",
      textAlign: "center",
      minWidth: "135px",
      maxWidth: "135px",
    },
    setMaxHeight: true,
  },
  header: {
    id: 8,
    width: "100%",
    i18key: "measuringInstruments.table.name.columnTitle",
    mobile: true,
    disableSort: true,
    style: {},
  },
};
