import { Box } from "@mui/material";
import styled from "styled-components";
import Icon from "../../../../Icon/Icon";
import EditIcon from "../../../../Icon/Icons/EditIcon";
import ExportIcon from "../../../../Icon/Icons/ExportIcon";
import MailIcon from "../../../../Icon/Icons/MailIcon";
import UndoIcon from "../../../../Icon/Icons/UndoIcon";
import Link from "../../../../Link/Link";

export const ActionRendererContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  /* gap: 17px; */
  @media (max-width: 600px) {
    position: absolute;
    top: 25px;
    right: 33px;
  }
`;
export const ActionRendererExportIconContainer = styled(Icon)`
  cursor: pointer;
`;
export const ActionRendererEditIconContainer = styled(Link)`
  cursor: pointer;
`;
export const ActionRendererUndoIconContainer = styled(Icon)`
  cursor: pointer;
`;
export const ActionRendererMailIconContainer = styled(Icon)`
  cursor: pointer;
`;
export const ActionRendererExportIcon = styled(ExportIcon)`
  width: 20px;
  height: 20px;
  margin: 0 10px;
`;
export const ActionRendererEditIcon = styled(EditIcon)`
  width: 20px;
  height: 20px;
  margin: 0 0 0 10px;
`;
export const ActionRendererUndoIcon = styled(UndoIcon)`
  width: 20px;
  height: 20px;
  margin: 0 10px;
`;
export const ActionRendererMailIcon = styled(MailIcon)`
  width: 20px;
  height: 20px;
  margin: 0 10px;
`;
