import React from "react";
// import PropTypes from 'prop-types'
import { ReactComponent as EditIconSvg } from "../../../assets/images/svg/edit.svg";

const EditIcon = (props) => {
  return <EditIconSvg {...props} />;
};

EditIcon.propTypes = {};

export default EditIcon;
