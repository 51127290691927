import React from "react";
import { useTranslation } from "react-i18next";

import Title from "../Typography/Title/Title";
import { DocumentsContentContainer } from "./DocumentsContent.styled";
import DocumentsTable from "./DocumentsTable/DocumentsTable";

const DocumentsContent = () => {
  const { t } = useTranslation();

  return (
    <DocumentsContentContainer>
      <Title>{t("documents.title")}</Title>
      <DocumentsTable />
    </DocumentsContentContainer>
  );
};

export default DocumentsContent;
