import { Box } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../../../../themes";
import { hexToRGB } from "../../../../../../util/helpers/colorHelper";
import Paging from "../../../../../Paging/Paging";

export const FindingScanningWizzardContainer = styled(Box)`
  width: 100%;
  border: 1px solid ${selectedTheme.colors.tableBorder};
  border-radius: 6px;
  box-shadow: 0px 1px 2px ${hexToRGB(selectedTheme.colors.shadowColor, 0.05)};
  padding: 16px;
  /* margin-top: 25px; */
  @media (max-width: 600px) {
    padding-bottom: 0;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
`;
export const FindingScanningQuestionContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  width: 100%;
  padding: 32px 16px;
  @media (max-width: 600px) {
    padding: 0;
  }
`;

export const QuestionsPaging = styled(Paging)`
    gap: 40px;
`
