import { Box, Typography } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../themes";

export const LoaderContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  justify-content: center;
  align-items: center;
  position: ${(props) => (props?.$isStatic ? "static" : "fixed")};
  padding: 0;
  margin: 0;
  top: 0;
  left: 0;
`;

export const LoaderLabel = styled(Typography)`
  font-family: ${selectedTheme.fonts.mainText};
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  margin-top: 10px;
  color: ${selectedTheme.colors.loader};
`;
