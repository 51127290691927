import React from "react";
import PropTypes from "prop-types";
import { Backdrop } from "@mui/material";
// import { alpha } from "@mui/system";

const BackdropComponent = ({
  position = "fixed",
  isLoading,
  handleClose,
  rank = 0,
}) => (
  <Backdrop
    sx={{
      // 'fixed' takes whole page, 'absolute' takes whole space of the parent element which needs to have 'relative' position
      position,
      //   zIndex: ({ zIndex }) => zIndex.drawer + 1,
      zIndex: 151 + rank,
    }}
    open={isLoading}
    onClick={handleClose}
    style={{
      backgroundColor: "rgba(0,0,0,0.3)",
    }}
  >
    {/* <CircularProgress /> */}
  </Backdrop>
);

BackdropComponent.propTypes = {
  position: PropTypes.oneOf(["fixed", "absolute"]),
  isLoading: PropTypes.bool.isRequired,
  rank: PropTypes.number,
  handleClose: PropTypes.func,
};

export default BackdropComponent;
