import React from "react";
import PropTypes from "prop-types";
import AutocompleteField from "../../../Field/AutocompleteField/AutocompleteField";
import {
  LANGUAGES,
  findLanguageByCulture,
} from "../../../../constants/languageConstants";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

const LanguageField = (props) => {
  const { t } = useTranslation();
  const formik = props?.formik;
  const languages = useMemo(
    () => LANGUAGES,
    //   .map?.((singleLng) => ({ ...singleLng, id: singleLng.culture })),
    //   .map((singleLng) => ({
    //     label: singleLng.text,
    //     id: singleLng.culture,
    //   })),
    []
  );
  const handleChange = (lng) =>
    formik?.setFieldValue?.("languageCultureName", lng.culture);
  return (
    <>
      <AutocompleteField
        options={languages}
        title={t("common.language")}
        disableClearable
        optionProps={{ style: { gap: "16px" } }}
        onChange={handleChange}
        dropdownLabel={["text"]}
        helperText={formik?.touched?.languageCultureName && formik?.errors?.languageCultureName}
        required={!formik?.values?.languageCultureName}
        value={findLanguageByCulture(formik?.values?.languageCultureName)}
        error={formik?.touched?.languageCultureName && formik?.errors?.languageCultureName}
      />
    </>
  );
};

LanguageField.propTypes = {
  children: PropTypes.node,
  formik: PropTypes.object,
};

export default LanguageField;
