import { applyMiddleware, compose, createStore } from "redux";
import createSagaMiddleware from "redux-saga";
import rootReducer from "./reducers";
import rootSaga from "./saga";
import loadingMiddleware from "./middleware/loadingMiddleware";
// import requestStatusMiddleware from "./middleware/requestStatusMiddleware";
import internalServerErrorMiddleware from "./middleware/internalServerErrorMiddleware";
import accessTokensMiddleware from "./middleware/accessTokensMiddleware";
import persistStore from "redux-persist/es/persistStore";
import internetConnectionMiddleware from "./middleware/internetConnectionMiddleware";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const sagaMiddleware = createSagaMiddleware();
export const store = createStore(
  rootReducer,
  composeEnhancers(
    applyMiddleware(
      internetConnectionMiddleware,
      loadingMiddleware,
      // requestStatusMiddleware,
      internalServerErrorMiddleware,
      accessTokensMiddleware,
      sagaMiddleware,
    )
  )
);

export const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);

