import {
  createClearType,
  createErrorType,
  createFetchType,
  createSetType,
  createSuccessType,
} from "../actionHelpers";

export const SET_DOCUMENT_TYPE = createSetType("SET_DOCUMENT_TYPE");
export const SET_DOCUMENT_TYPE_ERROR = "SET_DOCUMENT_TYPE_ERROR";

export const GET_DOCUMENT_TYPES_SCOPE = "GET_DOCUMENT_TYPES_SCOPE";
export const GET_DOCUMENT_TYPES_FETCH = createFetchType(GET_DOCUMENT_TYPES_SCOPE);
export const GET_DOCUMENT_TYPES_SUCCESS = createSuccessType(GET_DOCUMENT_TYPES_SCOPE);
export const GET_DOCUMENT_TYPES_ERROR = createErrorType(GET_DOCUMENT_TYPES_SCOPE);
export const GET_DOCUMENT_TYPES_CLEAR = createClearType(GET_DOCUMENT_TYPES_SCOPE);

export const SINGLE_DOCUMENT_TYPE_SCOPE = "SINGLE_DOCUMENT_TYPE_SCOPE";
export const SINGLE_DOCUMENT_TYPE_FETCH = createFetchType(SINGLE_DOCUMENT_TYPE_SCOPE);
export const SINGLE_DOCUMENT_TYPE_SUCCESS = createSuccessType(SINGLE_DOCUMENT_TYPE_SCOPE);
export const SINGLE_DOCUMENT_TYPE_ERROR = createErrorType(SINGLE_DOCUMENT_TYPE_SCOPE);
export const SINGLE_DOCUMENT_TYPE_SET = createSetType(SINGLE_DOCUMENT_TYPE_SCOPE);

const CREATE_DOCUMENT_TYPE_SCOPE = "CREATE_DOCUMENT_TYPE_SCOPE";
export const CREATE_DOCUMENT_TYPE_FETCH = createFetchType(CREATE_DOCUMENT_TYPE_SCOPE);
export const CREATE_DOCUMENT_TYPE_SUCCESS = createSuccessType(CREATE_DOCUMENT_TYPE_SCOPE);
export const CREATE_DOCUMENT_TYPE_ERROR = createErrorType(CREATE_DOCUMENT_TYPE_SCOPE);

const EDIT_DOCUMENT_TYPE_SCOPE = "EDIT_DOCUMENT_TYPE_SCOPE";
export const EDIT_DOCUMENT_TYPE_FETCH = createFetchType(EDIT_DOCUMENT_TYPE_SCOPE);
export const EDIT_DOCUMENT_TYPE_SUCCESS = createSuccessType(EDIT_DOCUMENT_TYPE_SCOPE);
export const EDIT_DOCUMENT_TYPE_ERROR = createErrorType(EDIT_DOCUMENT_TYPE_SCOPE);

const DELETE_DOCUMENT_TYPE_SCOPE = "DELETE_DOCUMENT_TYPE_SCOPE";
export const DELETE_DOCUMENT_TYPE_FETCH = createFetchType(DELETE_DOCUMENT_TYPE_SCOPE);
export const DELETE_DOCUMENT_TYPE_SUCCESS = createSuccessType(DELETE_DOCUMENT_TYPE_SCOPE);
export const DELETE_DOCUMENT_TYPE_ERROR = createErrorType(DELETE_DOCUMENT_TYPE_SCOPE);

export const SET_DOCUMENT_TYPES = createSetType("SET_DOCUMENT_TYPES");
export const SET_TOTAL_DOCUMENT_TYPES = createSetType("SET_TOTAL_DOCUMENT_TYPES");
export const CLEAR_DOCUMENT_TYPES = createClearType("CLEAR_DOCUMENT_TYPES");
