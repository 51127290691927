import {
    deleteRequest,
    getRequest,
    postRequest,
    putRequest,
    replaceInUrl,
  } from ".";
  import apiEndpoints from "./apiEndpoints";
  
  export const attemptFetchFindingTypes = (payload) =>
    getRequest(apiEndpoints.findingTypes.getFindingTypes, payload);
  
  export const attemptCreateFindingType = (payload) =>
    postRequest(apiEndpoints.findingTypes.createFindingType, payload);
  
  export const attemptFetchSingleFindingType = (payload) =>
    getRequest(
      replaceInUrl(apiEndpoints.findingTypes.getSingleFindingType, {
        findingTypeId: payload?.findingTypeId,
      })
    );
  
  export const attemptEditSingleFindingType = (payload) =>
    putRequest(
      replaceInUrl(apiEndpoints.findingTypes.editFindingType, {
        findingTypeId: payload?.findingTypeId,
      }),
      payload?.data
    );
  
  export const attemptDeleteFindingType = (payload) =>
    deleteRequest(
      replaceInUrl(apiEndpoints.findingTypes.deleteFindingType, {
        findingTypeId: payload?.findingTypeId,
      })
    );
  