import React, { useRef } from "react";
import PropTypes from "prop-types";
import Table from "../../Table/Table";

const EntityCustomTable = (props) => {
  const tableRef = useRef(null);

  return (
    <Table
      ref={tableRef}
      tableColumns={props?.tableColumns}
      hideHeader
      hidePaging
      custom
      customData={props?.data}
      mapDataFunction={props?.mapDataFunction}
    />
  );
};

EntityCustomTable.propTypes = {
  children: PropTypes.node,
  tableColumns: PropTypes.array,
  mapDataFunction: PropTypes.func,
  data: PropTypes.array,
};
EntityCustomTable.defaultProps = {
  data: [],
};

export default EntityCustomTable;
