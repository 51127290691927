import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { UserCompaniesNewPageContentContainer } from "./UserCompaniesNewPageContent.styled";
import NewUserForm from "../../../Users/NewUser/NewUserForm";
import { useTranslation } from "react-i18next";
import {
  addUser,
  fetchCreateUser,
} from "../../../../store/actions/user/userActions";
import { useRouteMatch, useLocation } from "react-router-dom";
import history from "../../../../store/utils/history";
import { replaceInRoute } from "../../../../util/helpers/routeHelpers";
import { COMPANIES_EDIT_PAGE } from "../../../../constants/pages";
import { useSelector } from "react-redux";
import { selectUsersData } from "../../../../store/selectors/usersSelectors";

const UserCompaniesNewPageContent = () => {
  const { t } = useTranslation();
  const routeMatch = useRouteMatch();
  const location = useLocation();
  const users = useSelector(selectUsersData);
  const [callbackFunction, setCallbackFunction] = useState();
  const handleRedirect = () => {
    history.replace({
      pathname: replaceInRoute(COMPANIES_EDIT_PAGE, {
        companyId: routeMatch?.params?.companyId,
      }),
      state: {
        ...location?.state,
        isInUsers: true,
      },
    });
  };
  useEffect(() => {
    if (callbackFunction) {
      callbackFunction();
      setCallbackFunction();
    }
  }, [users]);
  const dispatchFunction = (dispatchProps) => {
    if (!location?.state?.wasCompanyCreated) {
      setCallbackFunction(dispatchProps?.handleApiResponseSuccess);
      return addUser({
        ...dispatchProps?.userData,
        firstName: dispatchProps?.userData?.firstname,
        lastName: dispatchProps?.userData?.lastname,
        updatedAtUtc: new Date().toISOString(),
        updatedByUser: { firstName: "SuperAdmin", lastName: "SuperAdmin" },
        id: Math.random(),
      });
    }
    return fetchCreateUser({
      ...dispatchProps,
      companyId: routeMatch?.params?.companyId,
    });
  };
  return (
    <UserCompaniesNewPageContentContainer>
      <NewUserForm
        customRedirect={handleRedirect}
        dispatchFunction={dispatchFunction}
        title={t("users.newUser")}
        submitText={t("common.save")}
      />
    </UserCompaniesNewPageContentContainer>
  );
};

UserCompaniesNewPageContent.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  isEditing: PropTypes.bool,
  companyId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default UserCompaniesNewPageContent;
