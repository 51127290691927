import React from "react";
import PropTypes from "prop-types";
import { UserRendererContainer } from "./UserRenderer.styled";
import Tooltip from "../../../../../Tooltip/Tooltip";

const UserRenderer = (props) => {
  let redirectHref = props?.redirectHref;
  if (props?.redirectHrefFunction)
    redirectHref = props?.redirectHrefFunction(props?.dataId);

  return (
    <Tooltip title={props?.value}>
      <UserRendererContainer
        to={redirectHref}
        onClick={props?.redirectFunction}
        $mobile={props?.isMobile}
        $smallerText={props?.smallerText}
      >
        {props?.value}
      </UserRendererContainer>
    </Tooltip>
  );
};

UserRenderer.propTypes = {
  value: PropTypes.children,
  redirect: PropTypes.bool,
  redirectFunction: PropTypes.func,
  redirectHrefFunction: PropTypes.func,
  dataId: PropTypes.number || PropTypes.string,
  smallerText: PropTypes.bool,
  isMobile: PropTypes.bool,
  redirectHref: PropTypes.string,
};

export default UserRenderer;
