import ActionRenderer from "../../../components/Findings/NewFinding/FindingSettings/FindingAttendees/ActionRenderer/ActionRenderer";
import UserRenderer from "../../../components/Users/UsersTable/ActiveUsersTable/Renderers/UserRenderer/UserRenderer";

export default {
  name: {
    id: 0,
    i18key: "findings.findingAttendees.nameColumnTitle",
    renderer: UserRenderer,
    disableSort: true,
    backendProperty: "name",
    mobileStyle: {
      padding: 0,
      paddingLeft: "6px",
      paddingRight: "80px",
      flex: 1,
    },
    style: {
      padding: "6px",
    },
  },
  title: {
    id: 3,
    i18key: "findings.findingAttendees.titleColumnTitle",
    renderer: UserRenderer,
    disableSort: true,
    backendProperty: "title",
    style: {
      padding: "6px",
    },
    mobileStyle: {
      padding: 0,
      paddingLeft: "6px",
      paddingRight: "80px",
      flex: 1,
      marginBottom: "-4px",
    },
  },
  header: {
    id: 2,
    i18key: "standards.table.caption.columnTitle",
    renderer: UserRenderer,
    disableSort: true,
    mobile: true,
  },
  actions: {
    id: 1,
    i18key: "",
    disableSort: true,
    renderer: ActionRenderer,
    mobileStyle: {
      padding: 0,
      paddingLeft: "6px",
      marginTop: "-4px",
    },
    style: {
      padding: "6px 24px 6px 6px",
      justifyContent: "center",
      textAlign: "center",
      width: "80px",
      minWidth: "0",
    },
  },
};
