import React from "react";
import PropTypes from "prop-types";
import { FormControlLabel, Switch as SwitchMUI } from "@mui/material";
import { styled } from "@mui/material/styles";
import selectedTheme from "../../themes";

const CustomSwitch = styled((props) => (
  <SwitchMUI
    focusVisibleClassName=".Mui-focusVisible"
    disableRipple
    {...props}
  />
))(({ theme }) => ({
  width: 57,
  height: 26,
  padding: 0,
  marginRight: 16,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(30px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor:
          theme.palette.mode === "dark"
            ? `${selectedTheme.colors.white}`
            : `${selectedTheme.colors.orange}`,
        opacity: 1,
        border: 0,
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor:
      theme.palette.mode === "light"
        ? `${selectedTheme.colors.gray}`
        : `${selectedTheme.colors.tableBorder}`,
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const Switch = (props) => {
  return (
    <FormControlLabel
      control={
        <CustomSwitch
          checked={props?.checked}
          onChange={props?.onChange}
          disabled={props?.disabled}
          {...props?.switchProps}
        />
      }
      label={props?.label}
      labelPlacement="end"
      {...props?.controlProps}
      {...props}
    />
  );
};

Switch.propTypes = {
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  switchProps: PropTypes.object,
  controlProps: PropTypes.object,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node,
  ]),
};

export default Switch;
