import { GET_CLIENTS_SCOPE } from "../actions/clients/clientsActionConstants";
import { FINDINGS_SCOPE } from "../actions/findings/findingsActionConstants";
import { MEASURING_INSTRUMENTS_SCOPE } from "../actions/measuringInstruments/measuringInstrumentsActionConstants";
import { SCANNING_OBJECTS_SCOPE } from "../actions/scanningObjects/scanningObjectsActionConstants";
import { STANDARDS_SCOPE } from "../actions/standards/standardsActionConstants";
import { GET_STUFFS_SCOPE } from "../actions/stuffs/stuffsActionConstants";
import { GET_USERS_SCOPE } from "../actions/user/userActionConstants";

export const tableFetchActions = [
  GET_CLIENTS_SCOPE,
  GET_STUFFS_SCOPE,
  GET_USERS_SCOPE,
  STANDARDS_SCOPE,
  MEASURING_INSTRUMENTS_SCOPE,
  SCANNING_OBJECTS_SCOPE,
  FINDINGS_SCOPE,
];
