import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  useMemo,
} from "react";
import PropTypes from "prop-types";
import {
  $getTableColumnIndexFromTableCellNode,
  $getTableNodeFromLexicalNodeOrThrow,
  $getTableRowIndexFromTableCellNode,
  $isTableCellNode,
  $isTableRowNode,
  getCellFromTarget,
} from "@lexical/table";
import {
  $getNearestNodeFromDOMNode,
  $getSelection,
  COMMAND_PRIORITY_HIGH,
  DEPRECATED_$isGridSelection,
  SELECTION_CHANGE_COMMAND,
} from "lexical";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { createPortal } from "react-dom";
import {
  TableCellResizerContainer,
  TableCellResizerIcon,
} from "./TableCellResizer.styled";

const MIN_ROW_HEIGHT = 33;
const MIN_COLUMN_WIDTH = 50;

const TableCellResizer = () => {
  const [editor] = useLexicalComposerContext();
  const targetRef = useRef(null);
  const resizerRef = useRef(null);
  const tableRectRef = useRef(null);

  const mouseStartPosRef = useRef(null);
  const [mouseCurrentPos, updateMouseCurrentPos] = useState(null);

  const [activeCell, updateActiveCell] = useState(null);
  const [isSelectingGrid, updateIsSelectingGrid] = useState(false);
  const [draggingDirection, updateDraggingDirection] = useState(null);

  useEffect(() => {
    return editor.registerCommand(
      SELECTION_CHANGE_COMMAND,
      () => {
        const selection = $getSelection();
        const isGridSelection = DEPRECATED_$isGridSelection(selection);

        if (isSelectingGrid !== isGridSelection) {
          updateIsSelectingGrid(isGridSelection);
        }

        return false;
      },
      COMMAND_PRIORITY_HIGH
    );
  });

  const resetState = useCallback(() => {
    updateActiveCell(null);
    targetRef.current = null;
    updateDraggingDirection(null);
    mouseStartPosRef.current = null;
    tableRectRef.current = null;
  }, []);

  useEffect(() => {
    const onMouseMove = (event) => {
      setTimeout(() => {
        const target = event.target;

        if (draggingDirection) {
          updateMouseCurrentPos({
            x: event.clientX,
            y: event.clientY,
          });
          return;
        }

        if (resizerRef.current && resizerRef.current.contains(target)) {
          return;
        }

        if (targetRef.current !== target) {
          targetRef.current = target;
          const cell = getCellFromTarget(target);

          if (cell && activeCell !== cell) {
            editor.update(() => {
              const tableCellNode = $getNearestNodeFromDOMNode(
                cell.elem,
                editor.getEditorState()
              );
              if (!tableCellNode) {
                return;
              }

              const tableNode =
                $getTableNodeFromLexicalNodeOrThrow(tableCellNode);
              const tableElement = editor.getElementByKey(tableNode.getKey());

              if (!tableElement) {
                return;
              }

              targetRef.current = target;
              tableRectRef.current = tableElement.getBoundingClientRect();
              updateActiveCell(cell);
            });
          } else if (cell == null) {
            resetState();
          }
        }
      }, 0);
    };

    document.addEventListener("mousemove", onMouseMove);

    return () => {
      document.removeEventListener("mousemove", onMouseMove);
    };
  }, [activeCell, draggingDirection, editor, resetState]);

  const isHeightChanging = (direction) => {
    if (direction === "bottom") return true;
    return false;
  };

  const updateRowHeight = useCallback(
    (newHeight) => {
      if (!activeCell) {
        console.error("TableCellResizer: Expected active cell.");
        return;
      }

      editor.update(() => {
        const tableCellNode = $getNearestNodeFromDOMNode(activeCell.elem);
        if (!$isTableCellNode(tableCellNode)) {
          console.error("TableCellResizer: Table cell node not found.");
          return;
        }

        const tableNode = $getTableNodeFromLexicalNodeOrThrow(tableCellNode);

        const tableRowIndex = $getTableRowIndexFromTableCellNode(tableCellNode);

        const tableRows = tableNode.getChildren();

        if (tableRowIndex >= tableRows.length || tableRowIndex < 0) {
          console.error("Expected table cell to be inside of table row.");
          return;
        }

        const tableRow = tableRows[tableRowIndex];

        if (!$isTableRowNode(tableRow)) {
          console.error("Expected table row");
          return;
        }

        tableRow.setHeight(newHeight);
      });
    },
    [activeCell, editor]
  );

  const updateColumnWidth = useCallback(
    (newWidth) => {
      if (!activeCell) {
        console.error("TableCellResizer: Expected active cell.");
        return;
      }
      editor.update(() => {
        const tableCellNode = $getNearestNodeFromDOMNode(activeCell.elem);
        if (!$isTableCellNode(tableCellNode)) {
          console.error("TableCellResizer: Table cell node not found.");
          return;
        }

        const tableNode = $getTableNodeFromLexicalNodeOrThrow(tableCellNode);

        const tableColumnIndex =
          $getTableColumnIndexFromTableCellNode(tableCellNode);

        const tableRows = tableNode.getChildren();

        for (let r = 0; r < tableRows.length; r++) {
          const tableRow = tableRows[r];

          if (!$isTableRowNode(tableRow)) {
            console.error("Expected table row");
            return;
          }

          const tableCells = tableRow.getChildren();

          if (tableColumnIndex >= tableCells.length || tableColumnIndex < 0) {
            console.error("Expected table cell to be inside of table row.");
            return;
          }

          const tableCell = tableCells[tableColumnIndex];

          if (!$isTableCellNode(tableCell)) {
            console.error("Expected table cell");
            return;
          }

          tableCell.setWidth(newWidth);
        }
      });
    },
    [activeCell, editor]
  );

  const toggleResize = useCallback(
    (direction) => (event) => {
      event.preventDefault();
      event.stopPropagation();

      if (!activeCell) {
        console.error("TableCellResizer: Expected active cell.");
        return;
      }

      if (draggingDirection === direction && mouseStartPosRef.current) {
        const { x, y } = mouseStartPosRef.current;

        if (activeCell === null) {
          return;
        }

        const { height, width } = activeCell.elem.getBoundingClientRect();

        if (isHeightChanging(direction)) {
          const heightChange = Math.abs(event.clientY - y);

          const isShrinking = direction === "bottom" && y > event.clientY;

          updateRowHeight(
            Math.max(
              isShrinking ? height - heightChange : heightChange + height,
              MIN_ROW_HEIGHT
            )
          );
        } else {
          const widthChange = Math.abs(event.clientX - x);

          const isShrinking = direction === "right" && x > event.clientX;

          updateColumnWidth(
            Math.max(
              isShrinking ? width - widthChange : widthChange + width,
              MIN_COLUMN_WIDTH
            )
          );
        }

        resetState();
      } else {
        mouseStartPosRef.current = {
          x: event.clientX,
          y: event.clientY,
        };
        updateMouseCurrentPos(mouseStartPosRef.current);
        updateDraggingDirection(direction);
      }
    },
    [
      activeCell,
      draggingDirection,
      resetState,
      updateColumnWidth,
      updateRowHeight,
    ]
  );

  const resizerStyles = useMemo(() => {
    if (activeCell) {
      const { height, width, top, left } =
        activeCell.elem.getBoundingClientRect();

      const styles = {
        bottom: {
          backgroundColor: "none",
          cursor: "row-resize",
          height: "10px",
          left: `${window.pageXOffset + left}px`,
          top: `${window.pageYOffset + top + height}px`,
          width: `${width}px`,
        },
        right: {
          backgroundColor: "none",
          cursor: "col-resize",
          height: `${height}px`,
          left: `${window.pageXOffset + left + width}px`,
          top: `${window.pageYOffset + top}px`,
          width: "10px",
        },
      };

      const tableRect = tableRectRef.current;

      if (draggingDirection && mouseCurrentPos && tableRect) {
        if (isHeightChanging(draggingDirection)) {
          styles[draggingDirection].left = `${
            window.pageXOffset + tableRect.left
          }px`;
          styles[draggingDirection].top = `${
            window.pageYOffset + mouseCurrentPos.y
          }px`;
          styles[draggingDirection].height = "3px";
          styles[draggingDirection].width = `${tableRect.width}px`;
        } else {
          styles[draggingDirection].top = `${
            window.pageYOffset + tableRect.top
          }px`;
          styles[draggingDirection].left = `${
            window.pageXOffset + mouseCurrentPos.x
          }px`;
          styles[draggingDirection].width = "3px";
          styles[draggingDirection].height = `${tableRect.height}px`;
        }

        styles[draggingDirection].backgroundColor = "#adf";
      }

      return styles;
    }

    return {
      bottom: null,
      left: null,
      right: null,
      top: null,
    };
  }, [activeCell, draggingDirection, mouseCurrentPos]);

  return createPortal(
    <TableCellResizerContainer ref={resizerRef}>
      {activeCell != null && !isSelectingGrid && (
        <>
          <TableCellResizerIcon
            style={resizerStyles.right || undefined}
            onMouseDown={toggleResize("right")}
            onMouseUp={toggleResize("right")}
          />
          <TableCellResizerIcon
            style={resizerStyles.bottom || undefined}
            onMouseDown={toggleResize("bottom")}
            onMouseUp={toggleResize("bottom")}
          />
        </>
      )}
    </TableCellResizerContainer>,
    document.body
  );
};

TableCellResizer.propTypes = {
  editor: PropTypes.any,
};

export default TableCellResizer;
