import { Box } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../themes";

export const LicenseModalContainer = styled(Box)`
  padding: 32px;
  position: absolute;
  background: white;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  z-index: ${(props) => "15" + props?.rank || "151"};
  width: 460px;
  @media (max-width: 600px) {
    width: 100%;
  }
`;

export const SubmitButton = styled(Box)`
  padding: 10px 24px;
  cursor: pointer;
  width: 50%;
  height: 48px;
  border-radius: 6px;
  background: ${selectedTheme.colors.saveButtonBackground};
  border: 1px solid ${selectedTheme.colors.inputBorder};
  font-family: ${selectedTheme.fonts.mainText};
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  font-feature-settings: "salt" on;
  color: white;
`;

export const CancelButton = styled(Box)`
  padding: 10px 24px;
  cursor: pointer;
  width: 50%;
  height: 48px;
  background: white;
  border: 1px solid ${selectedTheme.colors.inputBorder};
  font-family: ${selectedTheme.fonts.mainText};
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  font-feature-settings: "salt" on;
  color: ${selectedTheme.colors.tableColumnText};
  border-radius: 6px;
`;
