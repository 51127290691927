import React from "react";
// import PropTypes from 'prop-types'
import { ReactComponent as FileUploadIconSvg } from "../../../assets/images/svg/file-upload.svg";

const FileUploadIcon = (props) => {
  return <FileUploadIconSvg {...props} />;
};

FileUploadIcon.propTypes = {};

export default FileUploadIcon;
