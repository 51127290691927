import { useFormik } from "formik";
import PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { batch, useDispatch, useSelector } from "react-redux";
import { Prompt, useHistory, useLocation } from "react-router-dom";
import { COMPANIES_EDIT_PAGE } from "../../constants/pages";
import settingsTabsConstants, {
  settingsTabsAsArray,
} from "../../constants/settingsTabsConstants";
import { APPLICATION_TOAST_CONTAINER_ID } from "../../constants/toastConstants";
import useAuth from "../../hooks/useAuth";
import useIsMobile from "../../hooks/useIsMobile";
import i18n from "../../i18n";
import { settingsInitialValues } from "../../initialValues/settingsInitialValues";
import { SINGLE_COMPANY_SCOPE } from "../../store/actions/companies/companiesActionConstants";
import {
  fetchActivateCompany,
  fetchCreateCompany,
  fetchDeactivateCompany,
  fetchEditCompany,
  fetchMyCompany,
  fetchSingleCompany,
  setCompanyFromFormik,
  setCompanySavingDisabled,
  setIsCompanyActivated,
} from "../../store/actions/companies/companiesActions";
import {
  closeLoadingModal,
  closeModal,
  toggleConfirmModal,
  toggleSetLoadingModal,
} from "../../store/actions/modal/modalActions";
import {
  selectCompanySavingDislabed,
  selectMyCompanyId,
  selectSingleCompany,
} from "../../store/selectors/companiesSelector";
import { selectIsLoadingByActionType } from "../../store/selectors/loadingSelectors";
import { makeToastMessage } from "../../store/utils/makeToastMessage";
import { replaceInRoute } from "../../util/helpers/routeHelpers";
import settingsValidations from "../../validations/settingsValidations";
import CircularLoader from "../Loader/CircularLoader/CircularLoader";
import PageTitle from "../PageTitle/PageTitle";
import TabChooser from "../TabChooser/TabChooser";
import { AdminUserSettings } from "./AdminUserSettings/AdminUserSettings";
import ArchivingDetails from "./ArchivingDetails/ArchivingDetails";
import EmailInformation from "./EmailInformation/EmailInformation";
import GeneralSettings from "./GeneralSettings/GeneralSettings";
import LicensesContent from "./Licenses/LicensesContent";
import {
  MailAndArchiveContainer,
  SettingsContentContainer,
  TitleButtonsContainer,
} from "./SettingsContent.styled";
import { useRef } from "react";
import ActionButtons from "../PageTitle/ActionButtons/ActionButtons";

const SettingsContent = (props) => {
  const history = useHistory();
  const [selectedTab, setSelectedTab] = useState(
    settingsTabsConstants().general.id
  );
  const [googleDriveFile, setGoogleDriveFile] = useState(null);
  const [isSavingDisabled, setIsSavingDisabled] = useState(false);
  const isSavingDisabledRedux = useSelector(selectCompanySavingDislabed);
  const dispatch = useDispatch();
  const { isMobile } = useIsMobile();
  const { isSuperAdmin, isLoggedIn } = useAuth();
  const location = useLocation();
  const companyData = useSelector(selectSingleCompany);
  const myCompanyId = useSelector(selectMyCompanyId);
  const { t } = useTranslation();
  const isLoading = useSelector(
    selectIsLoadingByActionType(SINGLE_COMPANY_SCOPE)
  );
  const isClosing = useRef(false);

  const handleSubmit = (values) => {
    dispatch(toggleSetLoadingModal({ rank: 1 }));
    setIsSavingDisabled(true);
    if (props?.isEditing) {
      dispatch(
        fetchEditCompany({
          companyId: props?.companyId,
          companyData: values,
          handleApiResponseSuccess,
          handleApiResponseError,
        })
      );
    } else {
      dispatch(
        fetchCreateCompany({
          companyData: { ...values },
          handleApiResponseSuccess: handleApiResponseSuccessCreate,
          handleApiResponseError,
        })
      );
    }
  };

  const formik = useFormik({
    initialValues: settingsInitialValues(companyData),
    validationSchema: settingsValidations,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  const tabsConstants = useMemo(
    () => settingsTabsConstants(isSuperAdmin, props?.isEditing),
    [isSuperAdmin, props?.isEditing]
  );
  useEffect(() => {
    if (isClosing.current) return;
    if (location?.state?.isInUsers) {
      setIsSavingDisabled(isSavingDisabledRedux);
      if (!isSavingDisabledRedux) {
        formik?.setFieldValue("dirty", true);
      }
      history?.replace({
        state: {
          isInUsers: false,
        },
      });
      setSelectedTab(tabsConstants?.user?.id);
      return;
    }
    if (
      isSavingDisabled !== (!formik?.dirty && googleDriveFile === null) &&
      !location?.state?.isInUsers
    )
      setIsSavingDisabled(!formik?.dirty && googleDriveFile === null);
  }, [
    formik,
    googleDriveFile,
    location?.state,
    tabsConstants,
    isSavingDisabledRedux,
  ]);

  useEffect(() => {
    if (props?.isEditing)
      dispatch(
        fetchSingleCompany({
          companyId: props?.companyId,
          handleApiResponseSuccess: dispatch(closeLoadingModal()),
        })
      );
  }, [props?.companyId, props?.isEditing]);

  const handleApiResponseSuccess = () => {
    dispatch(closeLoadingModal());
    dispatch(fetchSingleCompany({ companyId: props?.companyId }));
    if (String(props?.companyId) === String(myCompanyId))
      dispatch(fetchMyCompany());
    makeToastMessage({
      title: t("companySettings.companyChangedSuccess"),
    });
    formik?.resetForm();
  };
  const handleApiResponseSuccessActivateToggle = (isActivating) => {
    dispatch(closeModal());
    dispatch(setIsCompanyActivated(isActivating));
    let titleKey = "companies.toast.deactivated.title";
    let descriptionKey = "companies.toast.deactivated.description";
    if (isActivating) {
      titleKey = "companies.toast.activated.title";
      descriptionKey = "companies.toast.activated.description";
    }
    makeToastMessage(
      {
        title: t(titleKey, {
          typeOfData: t("companies.typeOfData"),
        }),
        description: t(descriptionKey, {
          typeOfData: t("companies.typeOfDataPlural").toLowerCase(),
        }),
      },
      {
        containerId: APPLICATION_TOAST_CONTAINER_ID,
      }
    );
  };

  const handleDeactivateCompany = () => {
    dispatch(toggleSetLoadingModal(true));
    dispatch(
      fetchDeactivateCompany({
        companyId: props?.companyId,
        handleApiResponseSuccess: () =>
          handleApiResponseSuccessActivateToggle(false),
        handleApiResponseError: () => dispatch(closeModal()),
      })
    );
  };
  const handleActivateCompany = () => {
    dispatch(toggleSetLoadingModal(true));
    dispatch(
      fetchActivateCompany({
        companyId: props?.companyId,
        handleApiResponseSuccess: () =>
          handleApiResponseSuccessActivateToggle(true),
        handleApiResponseError: () => dispatch(closeModal()),
      })
    );
  };

  const handleApiResponseSuccessCreate = (company) => {
    history?.replace(
      replaceInRoute(COMPANIES_EDIT_PAGE, { companyId: company?.id })
    );
    makeToastMessage({
      title: t("companySettings.companyCreatedSuccess"),
    });
  };

  const handleApiResponseError = () => {
    dispatch(closeLoadingModal());
  };

  const tabsConstantsAsArray = useMemo(
    () => settingsTabsAsArray(isSuperAdmin, props?.isEditing),
    [isSuperAdmin, props?.isEditing]
  );

  const handleChangeTab = (newTab) => {
    setSelectedTab(newTab);
  };

  const onFileChange = (file) => {
    setGoogleDriveFile(file);
  };

  const handleRedirectToNewUser = () => {
    setTimeout(() => {
      batch(() => {
        dispatch(setCompanyFromFormik(formik?.values));
        dispatch(setCompanySavingDisabled(isSavingDisabled));
      });
    }, 0);
  };

  if (isLoading) return <CircularLoader />;

  return (
    <>
      <Prompt
        when={!isSavingDisabled && isLoggedIn}
        message={(location) => {
          if (
            location?.pathname?.includes("new-user") ||
            location?.pathname?.includes("login") ||
            (location?.pathname?.includes("companies") &&
              location?.pathname?.length > 10)
          ) {
            return true;
          }
          setIsSavingDisabled(true);
          isClosing.current = true;
          dispatch(
            toggleConfirmModal({
              preventCloseModal: true,
              title: t("companySettings.confirmModal.title"),
              description: (
                <Trans i18nKey={"companySettings.confirmModal.description"} />
              ),
              cancelButtonText: t("companySettings.confirmModal.cancel"),
              confirmButtonText: t("companySettings.confirmModal.save"),
              onConfirm: () => {
                formik?.submitForm();
                dispatch(closeModal());
                history?.replace(location);
              },
              onCancel: () => {
                dispatch(closeModal());
                formik?.resetForm();
                history?.replace(location);
              },
            })
          );

          return false;
        }}
      />
      <SettingsContentContainer>
        <TitleButtonsContainer>
          <PageTitle
            title={props?.isEditing ? companyData?.name : props?.title}
            isEditing={props?.isEditing}
            disableSaveButton={isSavingDisabled}
            onSubmitClick={formik?.handleSubmit}
            showBlock={isSuperAdmin && companyData?.deactivatedAtUtc == null}
            blockTooltip="companies.tooltip.deactivate"
            onBlockClick={handleDeactivateCompany}
            showUnblock={isSuperAdmin && companyData?.deactivatedAtUtc != null}
            unblockTooltip="companies.tooltip.activate"
            onUnblockClick={handleActivateCompany}
          />
        </TitleButtonsContainer>
        <TabChooser
          tabs={tabsConstantsAsArray}
          selectedTab={selectedTab}
          handleChangeTab={handleChangeTab}
        />

        {/* Selected tab content */}
        {/* ****** */}
        {selectedTab === tabsConstants?.general?.id && (
          <GeneralSettings formik={formik} />
        )}
        {selectedTab === tabsConstants?.mail?.id && (
          <MailAndArchiveContainer>
            <EmailInformation formik={formik} />
            <ArchivingDetails formik={formik} onFileChange={onFileChange} />
          </MailAndArchiveContainer>
        )}
        {selectedTab === tabsConstants?.user?.id && (
          <AdminUserSettings
            handleRedirectToNewUser={handleRedirectToNewUser}
            companyId={props?.companyId}
            isEditing={props?.isEditing}
          />
        )}
        {selectedTab === tabsConstants?.licenses?.id && <LicensesContent />}
        {/* ****** */}

        {/* Buttons */}
        {/* ****** */}
        {isMobile && (
          <ActionButtons
            handleClickSubmitButton={formik?.handleSubmit}
            disabledSaveButton={isSavingDisabled}
          />
        )}
        {/* ****** */}
      </SettingsContentContainer>
    </>
  );
};

SettingsContent.propTypes = {
  companyId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  title: PropTypes.string,
  isEditing: PropTypes.bool,
};

SettingsContent.defaultProps = {
  title: i18n.t("companySettings.title"),
};

export default SettingsContent;
