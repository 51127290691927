import {
    SINGLE_COMMON_TEMPLATE_SET,
    COMMON_TEMPLATES_CLEAR,
    COMMON_TEMPLATES_SET,
    COMMON_TEMPLATES_SET_TOTAL,
    COMMON_TEMPLATES_SET_ERROR_MESSAGE,
    COMMON_TEMPLATES_CREATE_ERROR,
    COMMON_TEMPLATES_ERROR,
    COMMON_TEMPLATES_EDIT_ERROR,
    //SINGLE_COMMON_TEMPLATE_SUCCESS,
    SINGLE_COMMON_TEMPLATE_ERROR,
    COMMON_TEMPLATES_DELETE_ERROR,
    PREVIEW_COMMON_TEMPLATES_SET,
    SET_LANGUAGES_ERROR,
    SET_LANGUAGES,
    SET_LANGUAGES_TOTAL

  } from "../../actions/commonTemplates/commonTemplatesActionConstants";
  import createReducer from "../../utils/createReducer";
  
  const initialState = {
    commonTemplates: {
      loaded: false,
      data: [],
    },
    languagesCultural:{
      loaded: false,
      data: [],
    },
    totalCommonTemplates: 0,
    totalLanguages:0,
    singleCommonTemplate: {},
    errorMessage: "",
    preview:""
  };
  
  export default createReducer(
    {
      [COMMON_TEMPLATES_SET]: setCommonTemplates,
      [COMMON_TEMPLATES_SET_TOTAL]: setCommonTemplatesTotal,
      [COMMON_TEMPLATES_CLEAR]: clearCommonTemplates,
      [SINGLE_COMMON_TEMPLATE_SET]: setSingleCommonTemplate,
      [COMMON_TEMPLATES_SET_ERROR_MESSAGE]: setErrorMessage,
      [COMMON_TEMPLATES_CREATE_ERROR]: setErrorMessage,
      [COMMON_TEMPLATES_DELETE_ERROR]: setErrorMessage,
      [COMMON_TEMPLATES_ERROR]: setErrorMessage,
      [COMMON_TEMPLATES_EDIT_ERROR]: setErrorMessage,
      [SINGLE_COMMON_TEMPLATE_ERROR]: setErrorMessage,
      [PREVIEW_COMMON_TEMPLATES_SET]: setCommonTemplatePreview,
      [COMMON_TEMPLATES_SET_ERROR_MESSAGE]: setErrorMessage,
      [SET_LANGUAGES_ERROR]: setLanguagesErrorMessage,
      [SET_LANGUAGES]: setLanguages,
      [SET_LANGUAGES_TOTAL]: setLanguagesTotal,
    },
    initialState
  );

  function setLanguages(state, { payload }) {
    return {
      ...state,
      languagesCultural: payload,
    };
  }

  function setLanguagesErrorMessage(state, { payload }) {
    return {
      ...state,
      errorMessage: payload,
    };
  }

  function setLanguagesTotal(state, { payload }) {
    return {
      ...state,
      totalLanguages: payload,
    };
  }
  function setCommonTemplatePreview(state, { payload }) {
    return {
      ...state,
      preview: payload,
    };
  }
  function setSingleCommonTemplate(state, { payload }) {
    return {
      ...state,
      singleCommonTemplate: payload,
      errorMessage: "",
    };
  }
  function setCommonTemplates(state, { payload }) {
    return {
      ...state,
      commonTemplates: payload,
    };
  }
  function setCommonTemplatesTotal(state, { payload }) {
    return {
      ...state,
      totalCommonTemplates: payload,
    };
  }
  function clearCommonTemplates(state) {
    return {
      ...state,
      commonTemplates: initialState.commonTemplates,
      totalCommonTemplates: initialState.totalCommonTemplates,
    };
  }
  function setErrorMessage(state, { payload }) {
    return {
      ...state,
      errorMessage: payload,
    };
  }
  