import React from "react";
import PropTypes from "prop-types";
import { TemplateQuestionsContainer } from "./TemplateQuestions.styled";
import TemplateQuestionCard from "../../../../Cards/TemplateQuestionCard/TemplateQuestionCard";
import AddButton from "../../../../Buttons/AddButton/AddButton";
import i18n from "../../../../../i18n";
import useIsMobile from "../../../../../hooks/useIsMobile";

const TemplateQuestions = (props) => {
  const { isMobile } = useIsMobile();
  const handleMoveQuestion = (firstIndex, secondIndex) => {
    if (secondIndex < 0) return;
    if (secondIndex >= props?.questions?.length) return;
    props?.handleMoveQuestion(firstIndex, secondIndex);
  };
  const handleMoveQuestionOnDragAndDrop = (firstIndex, secondIndex) => {
    props?.handleMoveQuestionOnDragAndDrop(firstIndex, secondIndex);
  };
  const handleEditQuestion = (singleQuestion, index) => {
    props?.handleStartEditingQuestion({ ...singleQuestion, index });
  };
  const handleDeleteQuestion = (singleQuestion) => {
    props?.handleDeleteQuestion(singleQuestion);
  };

  return (
    <TemplateQuestionsContainer>
      {!props?.isReadOnly && (
        <AddButton
          style={{
            backgroundColor: "black",
          }}
          $hideTitle={props?.questions.length > 0}
          title={i18n.t("scanningObjects.addFirstItem")}
          onClick={props?.handleStartAddingNewQuestion}
        />
      )}
      {props?.questions?.map((singleQuestion, index) => (
        <TemplateQuestionCard
          ordinalNumber={index + 1}
          key={index}
          handleChangeQuestionIndexes={props?.handleChangeQuestionIndexes}
          isRequired={singleQuestion?.isRequired}
          hasResearchPassed={singleQuestion?.hasResearchPassed}
          questionType={singleQuestion?.questionType}
          title={singleQuestion?.questionName}
          onBelowArrowClick={() => handleMoveQuestion(index, index - 1)}
          onAboveArrowClick={() => handleMoveQuestion(index, index + 1)}
          handleMoveQuestion={handleMoveQuestionOnDragAndDrop}
          handleDeleteQuestion={() => handleDeleteQuestion(singleQuestion)}
          handleEditQuestion={() => handleEditQuestion(singleQuestion, index)}
          isReadOnly={props?.isReadOnly}
          isMobile={isMobile}
          chosenSectionId={props?.chosenSectionId}
        />
      ))}
    </TemplateQuestionsContainer>
  );
};

TemplateQuestions.propTypes = {
  children: PropTypes.node,
  questions: PropTypes.array,
  handleMoveQuestion: PropTypes.func,
  handleStartEditingQuestion: PropTypes.func,
  handleDeleteQuestion: PropTypes.func,
  handleStartAddingNewQuestion: PropTypes.func,
  handleChangeQuestionIndexes: PropTypes.func,
  isReadOnly: PropTypes.bool,
  handleMoveQuestionOnDragAndDrop: PropTypes.func,
  chosenSectionId: PropTypes.number,
};

export default TemplateQuestions;
