import React from "react";
import PropTypes from "prop-types";
import { RedirectModalRendererContainer } from "./RedirectModalRenderer.styled";
import Tooltip from "../../../../Tooltip/Tooltip";

const RedirectModalRenderer = (props) => {
  return (
    <Tooltip title={props?.value}>
      <RedirectModalRendererContainer
        onClick={() => {
          if (props?.redirect)
            props?.redirectFunction?.({
              id: props?.dataId,
              prefilledData: props?.prefilledData,
            });
        }}
        $mobile={props?.isMobile}
        $smallerText={props?.smallerText}
      >
        {props?.value}
      </RedirectModalRendererContainer>
    </Tooltip>
  );
};

RedirectModalRenderer.propTypes = {
  value: PropTypes.children,
  redirect: PropTypes.bool,
  redirectFunction: PropTypes.func,
  dataId: PropTypes.number || PropTypes.string,
  prefilledData: PropTypes.object,
  smallerText: PropTypes.bool,
  isMobile: PropTypes.bool,
};

export default RedirectModalRenderer;
