import {
  CLEAR_FINDING_TYPES,
  CREATE_FINDING_TYPE_ERROR,
  CREATE_FINDING_TYPE_FETCH,
  CREATE_FINDING_TYPE_SUCCESS,
  DELETE_FINDING_TYPE_ERROR,
  DELETE_FINDING_TYPE_FETCH,
  DELETE_FINDING_TYPE_SUCCESS,
  EDIT_FINDING_TYPE_ERROR,
  EDIT_FINDING_TYPE_FETCH,
  EDIT_FINDING_TYPE_SUCCESS,
  GET_FINDING_TYPES_CLEAR,
  GET_FINDING_TYPES_ERROR,
  GET_FINDING_TYPES_FETCH,
  GET_FINDING_TYPES_SUCCESS,
  SET_FINDING_TYPE,
  SET_FINDING_TYPES,
  SET_FINDING_TYPE_ERROR,
  SET_TOTAL_FINDING_TYPES,
  SINGLE_FINDING_TYPE_ERROR,
  SINGLE_FINDING_TYPE_FETCH,
  SINGLE_FINDING_TYPE_SET,
  SINGLE_FINDING_TYPE_SUCCESS,
} from "./findingTypesActionConstants";

export const setFindingType = (payload) => ({
  type: SET_FINDING_TYPE,
  payload,
});

export const setFindingTypeError = (payload) => ({
  type: SET_FINDING_TYPE_ERROR,
  payload,
});

export const fetchFindingTypes = (payload) => ({
  type: GET_FINDING_TYPES_FETCH,
  payload,
});
export const fetchFindingTypesSuccess = (payload) => ({
  type: GET_FINDING_TYPES_SUCCESS,
  payload,
});
export const fetchFindingTypesError = (payload) => ({
  type: GET_FINDING_TYPES_ERROR,
  payload,
});

export const fetchFindingTypeClear = () => ({
  type: GET_FINDING_TYPES_CLEAR,
});

export const fetchCreateFindingType = (payload) => ({
  type: CREATE_FINDING_TYPE_FETCH,
  payload,
});
export const fetchCreateFindingTypeSuccess = (payload) => ({
  type: CREATE_FINDING_TYPE_SUCCESS,
  payload,
});
export const fetchCreateFindingTypeError = (payload) => ({
  type: CREATE_FINDING_TYPE_ERROR,
  payload,
});

export const fetchEditFindingType = (payload) => ({
  type: EDIT_FINDING_TYPE_FETCH,
  payload,
});
export const fetchEditFindingTypeSuccess = (payload) => ({
  type: EDIT_FINDING_TYPE_SUCCESS,
  payload,
});
export const fetchEditFindingTypeError = (payload) => ({
  type: EDIT_FINDING_TYPE_ERROR,
  payload,
});

export const fetchDeleteFindingType = (payload) => ({
  type: DELETE_FINDING_TYPE_FETCH,
  payload,
});
export const fetchDeleteFindingTypeSuccess = (payload) => ({
  type: DELETE_FINDING_TYPE_SUCCESS,
  payload,
});
export const fetchDeleteFindingTypeError = (payload) => ({
  type: DELETE_FINDING_TYPE_ERROR,
  payload,
});

export const fetchSingleFindingType = (payload) => ({
  type: SINGLE_FINDING_TYPE_FETCH,
  payload,
});
export const fetchSingleFindingTypeSuccess = (payload) => ({
  type: SINGLE_FINDING_TYPE_SUCCESS,
  payload,
});
export const fetchSingleFindingTypeError = (payload) => ({
  type: SINGLE_FINDING_TYPE_ERROR,
  payload,
});

export const setSingleFindingType = (payload) => ({
  type: SINGLE_FINDING_TYPE_SET,
  payload,
});
export const setFindingTypes = (payload) => ({
  type: SET_FINDING_TYPES,
  payload,
});
export const setTotalFindingTypes = (payload) => ({
  type: SET_TOTAL_FINDING_TYPES,
  payload,
});
export const clearFindingTypes = () => ({
  type: CLEAR_FINDING_TYPES,
});
