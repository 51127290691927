import React from "react";
// import PropTypes from 'prop-types'
import { ReactComponent as ProfileIconSvg } from "../../../assets/images/svg/user-edit.svg";

const ProfileIcon = (props) => {
  return <ProfileIconSvg {...props} />;
};

ProfileIcon.propTypes = {};

export default ProfileIcon;
