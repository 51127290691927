import * as Yup from "yup";
import { setPasswordFormikConstants } from "../initialValues/setPasswordInitialValues";

export default () =>
  Yup.object().shape({
    [setPasswordFormikConstants.password]: Yup.string().required(
      "changePassword.blankPasswordError"
    ),
    [setPasswordFormikConstants.confirmPassword]: Yup.string()
      .oneOf(
        [Yup.ref(setPasswordFormikConstants.password), null],
        "changePassword.differentPasswordsError"
      )
      .required("changePassword.blankPasswordError")
      .matches(
        /^(?=.{6,})/, //eslint-disable-line
        "changePassword.passwordLength"
      ),
  });
