import React from "react";
import PropTypes from "prop-types";
import {
  ActionRendererContainer,
  ActionRendererDeleteMeasuringInstrumentIconContainer,
  ActionRendererEditMeasuringInstrumentIconContainer,
} from "./ActionRenderer.styled";
import EditIcon from "../../../../Icon/Icons/EditIcon";
import {
  closeLoadingModal,
  closeModal,
  toggleSetDeleteModal,
  toggleSetLoadingModal,
  toggleSetMeasuringInstrumentModal,
} from "../../../../../store/actions/modal/modalActions";
import i18n from "../../../../../i18n";
import { fetchDeleteMeasuringInstrument } from "../../../../../store/actions/measuringInstruments/measuringInstrumentsActions";
import { makeToastMessage } from "../../../../../store/utils/makeToastMessage";
import { APPLICATION_TOAST_CONTAINER_ID } from "../../../../../constants/toastConstants";
import DeleteIcon from "../../../../Icon/Icons/DeleteIcon";
import Tooltip from "../../../../Tooltip/Tooltip";
import WithPermissions from "../../../../WithPermissions/WithPermissions";
import { MANAGER_ROLE } from "../../../../../constants/rolesConstants";

const ActionRenderer = (props) => {
  const handleEditMeasuringInstrument = () => {
    props?.dispatch(
      toggleSetMeasuringInstrumentModal({
        title: i18n.t("measuringInstruments.modal.editInstrument"),
        prefilledData: {
          name: props?.value?.name,
          factoryNumber: props?.value?.factoryNumber,
          manufacturer: props?.value?.manufacturer,
          calibrationCertificateUrl: props?.value?.calibrationCertificateUrl,
          benchmarking: {
            name: props?.value?.benchmarking,
            date: props?.value?.benchmarkDate,
          },
          findingTypes: props?.value?.findingTypes,
        },
        isEditing: true,
        rank: 0,
        measuringInstrumentId: props?.value?.measuringInstrumentId,
        refetchTable: props?.value?.refetchTable,
        tableRef: props?.value?.tableRef,
      })
    );
  };

  const handleApiResponseError = () => {
    props?.dispatch(closeLoadingModal());
  };

  const handleApiResponseSuccessDelete = () => {
    props?.dispatch(closeModal());
    makeToastMessage(
      {
        title: i18n.t("toast.success.deletedTitle", {
          typeOfData: i18n.t("measuringInstruments.typeOfData"),
        }),
        description: i18n.t("toast.success.deletedDescription", {
          name: props?.value?.name,
          typeOfData: i18n
            .t("measuringInstruments.typeOfDataPlural")
            .toLowerCase(),
        }),
      },
      {
        containerId: APPLICATION_TOAST_CONTAINER_ID,
      }
    );
    props?.value?.tableRef?.current?.forceRefetch?.();
  };

  const handleDeleteInstrument = () => {
    props?.dispatch(toggleSetLoadingModal({ rank: 3 }));
    props?.dispatch(
      fetchDeleteMeasuringInstrument({
        measuringInstrumentId: props?.value?.measuringInstrumentId,
        handleApiResponseSuccess: handleApiResponseSuccessDelete,
        handleApiResponseError,
      })
    );
  };

  const handleDelete = () => {
    props?.dispatch?.(
      toggleSetDeleteModal({
        title: i18n.t("measuringInstruments.modal.delete.title"),
        description: {
          first: i18n.t("measuringInstruments.modal.delete.description.1"),
          second: i18n.t("measuringInstruments.modal.delete.description.2"),
        },
        onSubmit: handleDeleteInstrument,
        rank: 1,
      })
    );
  };
  return (
    <ActionRendererContainer>
      <Tooltip
        title={i18n.t("measuringInstruments.tooltip.changeMeasuringInstrument")}
      >
        <ActionRendererEditMeasuringInstrumentIconContainer
          onClick={handleEditMeasuringInstrument}
        >
          <EditIcon />
        </ActionRendererEditMeasuringInstrumentIconContainer>
      </Tooltip>
      <WithPermissions roles={[MANAGER_ROLE]}>
        <Tooltip title={i18n.t("measuringInstruments.deleteInstrument")}>
          <ActionRendererDeleteMeasuringInstrumentIconContainer
            onClick={handleDelete}
          >
            <DeleteIcon />
          </ActionRendererDeleteMeasuringInstrumentIconContainer>
        </Tooltip>
      </WithPermissions>
    </ActionRendererContainer>
  );
};

ActionRenderer.propTypes = {
  children: PropTypes.node,
  dispatch: PropTypes.func,
  value: PropTypes.object,
  rendererProps: PropTypes.any,
  refetchTable: PropTypes.func,
};

export default ActionRenderer;
