import React from "react";
import PropTypes from "prop-types";
import { SectionContainer, SectionTitle } from "./Section.styled";
import { useTranslation } from "react-i18next";

const Section = (props) => {
  const {t} = useTranslation();
  return (
    <SectionContainer>
      <SectionTitle>{t(props?.title)}</SectionTitle>
      {props?.children}
    </SectionContainer>
  );
};

Section.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
};

export default Section;
