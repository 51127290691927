import createReducer from "../../utils/createReducer";
import {
  CLEAR_LOGIN_USER_ERROR,
  LOGIN_USER_ERROR,
  LOGIN_USER_SUCCESS,
  RESET_LOGIN_STATE,
  UPDATE_USER_JWT_TOKEN,
  GENERATE_TOKEN_SUCCESS,
  GENERATE_TOKEN_ERROR,
  UPDATE_REFRESH_TOKEN,
  SET_USER_ROLES,
  CLEAR_USER_ROLES,
  SET_MINE_COMPANY_ID,
  SET_MY_PROFILE_ID,
} from '../../actions/login/loginActionConstants';

const initialState = {
  email: "",
  token: {
    RefreshToken: "",
    JwtToken: "",
  },
  errorMessage: "",
  roles: [],
  userId: "",
  companyId: 0,
};

export default createReducer(
  {
    [LOGIN_USER_SUCCESS]: setUser,
    [UPDATE_USER_JWT_TOKEN]: setUserJwtToken,
    [UPDATE_REFRESH_TOKEN]: setRefreshToken,
    [RESET_LOGIN_STATE]: resetLoginState,
    [LOGIN_USER_ERROR]: setError,
    [CLEAR_LOGIN_USER_ERROR]: clearLoginErrors,
    [GENERATE_TOKEN_SUCCESS]: generateToken,
    [GENERATE_TOKEN_ERROR]: generateTokenError,
    [SET_USER_ROLES]: setUserRoles,
    [CLEAR_USER_ROLES]: clearUserRoles,
    [SET_MINE_COMPANY_ID]: setMineCompanyId,
    [SET_MY_PROFILE_ID]: setMyProfileId,
  },
  initialState
);

function setUser(state, action) {
  return {
    ...state,
    token: action.payload,
  };
}

function setRefreshToken(state, { payload }) {
  return {
    ...state,
    token: {
      ...state.token,
      RefreshToken: payload,
    },
  };
}

function setUserJwtToken(state, action) {
  return {
    ...state,
    token: {
      ...state.token,
      JwtToken: action.payload,
    },
  };
}

function setError(state, action) {
  return {
    ...state,
    errorMessage: action.payload,
  };
}

function resetLoginState() {
  return initialState;
}

function clearLoginErrors(state) {
  return {
    ...state,
    errorMessage: "",
  };
}

function generateToken(state, action) {
  return {
    ...state,
    token: action.payload,
  };
}

function generateTokenError(state, action) {
  return {
    ...state,
    errorMessage: action.payload,
  };
}
function setUserRoles(state, action) {
  return {
    ...state,
    roles: action.payload,
  };
}
function setMineCompanyId(state, action) {
  return {
    ...state,
    companyId: action.payload
  }
}
function clearUserRoles(state) {
  return {
    ...state,
    roles: initialState.roles,
  };
}
function setMyProfileId(state, action) {
  return {
    ...state,
    userId: action.payload,
  };
}
