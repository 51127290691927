import { createSelector } from "reselect";

const findingTypesSelector = (state) => state.findingTypes;

export const selectFindingTypes = createSelector(
  findingTypesSelector,
  (state) => state.findingTypes
);
export const selectTotalFindingTypes = createSelector(
  findingTypesSelector,
  (state) => state.totalFindingTypes
);
export const selectSingleFindingType = createSelector(
  findingTypesSelector,
  (state) => state.singleFindingType
);
export const selectFindingTypesData = createSelector(
  findingTypesSelector,
  (state) => state.findingTypes.data
);
