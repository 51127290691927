import DeleteRenderer from "../../../components/TemplateEditor/ActiveTemplateEditor/TemplateMeasuringInstrumentsEditor/TemplateMeasuringInstrumentsTable/Renderers/DeleteRenderer";
import UserRenderer from "../../../components/Users/UsersTable/ActiveUsersTable/Renderers/UserRenderer/UserRenderer";

export default {
  caption: {
    id: 0,
    i18key: "standards.table.caption.columnTitle",
    renderer: UserRenderer,
    style: {
      flex: 1,
      padding: "6px",
    },
    disableSort: true,
    backendProperty: "caption",
  },
  header: {
    id: 2,
    i18key: "standards.table.caption.columnTitle",
    renderer: UserRenderer,
    disableSort: true,
    mobile: true,
  },
  delete: {
    id: 1,
    i18key: "",
    disableSort: true,
    renderer: DeleteRenderer,
    style: {
      padding: "6px 24px 6px 6px",
      justifyContent: "center",
      textAlign: "center",
      width: "80px",
      minWidth: "0",
    },
  },
};
