import React from "react";
import PropTypes from "prop-types";
import { AdminUserSettingsContainer } from "./AdminUserSettings.styled";
import CompanyUsersTable from "../../Companies/NewCompany/CompanyUsersTable/CompanyUsersTable";

export const AdminUserSettings = (props) => {
  return (
    <AdminUserSettingsContainer>
      <CompanyUsersTable
        handleClickAddButton={props?.handleRedirectToNewUser}
        companyId={props?.companyId}
        isEditing={props?.isEditing}
      />
    </AdminUserSettingsContainer>
  );
};

AdminUserSettings.propTypes = {
  formik: PropTypes.object,
  handleRedirectToNewUser: PropTypes.func,
  isEditing: PropTypes.bool,
  companyId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
