import { Box } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../themes";
import { hexToRGB } from "../../../util/helpers/colorHelper";
import Title from "../../Typography/Title/Title";

export const ActiveTemplateEditorContainer = styled(Box)`
  border: 1px solid ${selectedTheme.colors.tableBorder};
  box-shadow: 0px 1px 2px ${hexToRGB(selectedTheme.colors.shadowColor, 0.05)};
  border-radius: 6px;
  padding: 22px 24px;
`;
export const TableTitle = styled(Title)`
  font-size: 24px;
`;

