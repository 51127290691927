import * as Yup from "yup";
import { LANGUAGES } from "../constants/languageConstants";

export default () =>
  Yup.object().shape({
    companyName: Yup.string()
      .trim()
      .required("companySettings.companyNameRequired"),
    companyPIB: Yup.string()
      .trim()
      .required("companySettings.PIBRequred")
      .min(9, "companySettings.PIBnoOfCharacters")
      .max(9, "companySettings.PIBnoOfCharacters"),
    identificationNumber: Yup.string()
      .trim()
      .required("companySettings.idNumberRequred")
      .min(8, "companySettings.idNumberNoOfCharacters")
      .max(8, "companySettings.idNumberNoOfCharacters"),
    bankAccountNumber: Yup.string(),
    description: Yup.string(),
    phoneNumber: Yup.string(),
    address: Yup.string(),
    companyDirector: Yup.string(),
    email: Yup.string().email("clients.emailFormat"),
    distributionEmails: Yup.array().of(
      Yup.string().email("clients.emailFormat")
    ),
    userName: Yup.string()
      .email("clients.emailFormat")
      .when("automaticallySendEmails", {
        is: true,
        then: Yup.string().required("emailSettings.userNameRequired"),
      }),
    password: Yup.string().when("automaticallySendEmails", {
      is: true,
      then: Yup.string().when("hasEmailPassword", {
        is: false,
        then: Yup.string().required("emailSettings.passwordRequired"),
      }),
    }),
    host: Yup.string().when("automaticallySendEmails", {
      is: true,
      then: Yup.string().required("emailSettings.hostRequired"),
    }),
    port: Yup.string().when("automaticallySendEmails", {
      is: true,
      then: Yup.string().required("emailSettings.portRequired"),
    }),
    enableSSl: Yup.bool().when("automaticallySendEmails", {
      is: true,
      then: Yup.bool()
        .required("emailSettings.sslRequired")
        .oneOf([true], "emailSettings.sslRequired"),
    }),
    automaticallySendEmails: Yup.bool(),
    automaticallyUploadToDrive: Yup.bool(),
    driveFolderId: Yup.string().when("automaticallyUploadToDrive", {
      is: true,
      then: Yup.string().required(
        "companySettings.archive.googleDrive.driveIdRequried"
      ),
    }),
    hasCredentialsFile: Yup.bool().when("automaticallyUploadToDrive", {
      is: true,
      then: Yup.bool()
        .required("companySettings.archive.googleDrive.fileRequired")
        .oneOf([true], "companySettings.archive.googleDrive.fileRequired"),
    }),
    hasEmailPassword: Yup.bool(),
    languageCultureName: Yup.string()
      .required("companySettings.languageRequired")
      .oneOf(LANGUAGES.map((singleLng) => singleLng.culture)),
  });
