import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { documentFormikConstants } from "../../../../initialValues/documentInitialValues";
import { selectDocumentTypesData } from "../../../../store/selectors/documentTypesSelectors";
import AutocompleteField from "../../../Field/AutocompleteField/AutocompleteField";

const DocumentTypeField = (props) => {
  const formik = props?.formik;
  const { t } = useTranslation();
  const documentTypes = useSelector(selectDocumentTypesData);

  const value = useMemo(() => {
    let documentTypeToReturn = documentTypes.find(
      (documentType) =>
        documentType?.id ==
        formik?.values?.[documentFormikConstants.documentTypeId]
    );
    return documentTypeToReturn;
  }, [formik?.values?.[documentFormikConstants.documentTypeId], documentTypes]);

  const handleChange = (value) => {
    formik?.setFieldValue?.(documentFormikConstants.documentTypeId, value?.id);
  };

  return (
    <AutocompleteField
      options={documentTypes}
      label={t("documents.newPage.form.documentType.placeholder")}
      disabled={props?.disabled}
      title={t("documents.newPage.form.documentType.title")}
      required={
        formik?.values?.[documentFormikConstants.documentTypeId] == null
      }
      removeMaxWidth
      onChange={handleChange}
      dropdownLabel={["caption"]}
      value={value}
      error={
        formik?.touched?.[documentFormikConstants.documentTypeId] &&
        formik?.errors?.[documentFormikConstants.documentTypeId] &&
        t(formik?.errors?.[documentFormikConstants.documentTypeId])
      }
    />
  );
};

DocumentTypeField.propTypes = {
  formik: PropTypes.object,
  disabled: PropTypes.bool,
  stateId: PropTypes.number,
};

export default DocumentTypeField;
