import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import {
  PrimaryButtonContainer,
  PrimaryButtonContent,
  RefreshIcon,
} from "./PrimaryButton.styled";

const PrimaryButton = forwardRef((props, ref) => {
  return (
    <PrimaryButtonContainer className={props?.className} ref={ref}>
      <PrimaryButtonContent
        variant={props?.variant}
        type={props?.type}
        onClick={props?.onClick}
        disabled={props?.disabled}
      >
        {props?.icon && <RefreshIcon />}
        {props?.children}
      </PrimaryButtonContent>
    </PrimaryButtonContainer>
  );
});

PrimaryButton.displayName = "PrimaryButton";

PrimaryButton.propTypes = {
  children: PropTypes.node,
  save: PropTypes.bool,
  icon: PropTypes.bool,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.any,
  variant: PropTypes.any,
};

export default PrimaryButton;
