import styled, { css } from "styled-components";
import { Box, Typography } from "@mui/material";
import selectedTheme from "../../themes";
import { hexToRGB } from "../../util/helpers/colorHelper";
import FileUploadIcon from "../Icon/Icons/FileUploadIcon";
import TickCircledIcon from "../Icon/Icons/TickCircledIcon";

export const FileJsonPickerContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 30px;

  max-width: 370px;
`;

export const FileJsonPickerLabel = styled(Typography)`
  font-family: ${selectedTheme.fonts.mainText};
  font-size: 14px;
  font-weight: 600;
  color: ${selectedTheme.colors.labelTextColor};
  ${(props) =>
    props?.$required &&
    css`
      &::after {
        content: " *";
        color: ${selectedTheme.colors.errorColor};
      }
    `}
`;

export const FileJsonPickerDescriptionText = styled(Box)`
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  font-feature-settings: "salt" on;
  font-family: ${selectedTheme.fonts.mainText};
`;

export const FileJsonPickerContentContainer = styled(Box)`
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: center;
  height: 78px;
  ${(props) =>
    props?.$hasFile
      ? `
    border: 1px solid ${selectedTheme.colors.toastSuccess};
  `
      : `
  border: 1px dashed ${selectedTheme.colors.inputBorder};
  `};
  border-radius: 8px;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  ${(props) =>
    props?.$disabled &&
    css`
      background-color: ${selectedTheme.colors.disabledFieldBackground};
      cursor: initial;
    `}
`;

export const UploadIcon = styled(FileUploadIcon)``;

export const GreenTickIcon = styled(TickCircledIcon)`
  width: 30px;
  height: 30px;
  & path {
    stroke: ${selectedTheme.colors.toastSuccess};
  }
`;

export const AddFile = styled.input`
  display: none;
`;

export const FileUploadedContainer = styled(Box)`
  width: 64px;
  height: 64px;
  overflow: hidden;
`;

export const Tooltip = styled(Box)`
  background-color: ${hexToRGB(selectedTheme.colors.white, 0.5)};
  width: 100px;
  height: 100px;
  position: absolute;
  left: 0;
  top: 0;
`;

export const FileJsonPickerDescription = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  & * {
    display: inline;
    font-family: ${selectedTheme.fonts.mainText};
    font-size: 12px;
    text-align: center;
    & p {
      color: ${selectedTheme.colors.tableLink};
    }
  }
`;
